import Web3 from 'web3'

export const coinGeckoListOfTokens = [
  {
    chainId: 1,
    address: '0x34950ff2b487d9e5282c5ab342d08a2f712eb79f',
    name: 'Efforce',
    symbol: 'WOZX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13310/thumb/rZ6Oe3dm_400x400.jpg?1607331889'
  },
  {
    chainId: 1,
    address: '0x68350d30d9f58c81aaaa41929f1bfc52fff4ea49',
    name: 'Rapidz',
    symbol: 'RPZX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7663/thumb/rapidzpay-logo.jpg?1549038850'
  },
  {
    chainId: 1,
    address: '0xbbdce056cbe561ee004094c75099172e7e3b34df',
    name: 'BeNative',
    symbol: 'BNV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9552/thumb/246x0w.jpg?1568836957'
  },
  {
    chainId: 1,
    address: '0x22f098f08c4eda4be4ad6b4ba59866f3e98cef92',
    name: 'Force For Fast',
    symbol: 'FFF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11718/thumb/fff2.PNG?1593073288'
  },
  {
    chainId: 1,
    address: '0x40c6f861a08f97dfbc3c0931485bff4921975a56',
    name: 'HGH Token',
    symbol: 'HGH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10045/thumb/Screenshot_2019-12-02_at_6.43.54_AM.png?1575240248'
  },
  {
    chainId: 1,
    address: '0x9f5f3cfd7a32700c93f971637407ff17b91c7342',
    name: 'Scry info',
    symbol: 'DDD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2569/thumb/Scry.info.jpg?1547036695'
  },
  {
    chainId: 1,
    address: '0xd945d2031b4c63c0e363304fb771f709b502dc0a',
    name: 'BountyMarketCap',
    symbol: 'BMC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13472/thumb/bmc-logo-200.png?1608852915'
  },
  {
    chainId: 1,
    address: '0x7995ab36bb307afa6a683c24a25d90dc1ea83566',
    name: 'HitChain',
    symbol: 'HIT',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/5555/thumb/hitchain.png?1547041361'
  },
  {
    chainId: 1,
    address: '0x1c5b760f133220855340003b43cc9113ec494823',
    name: 'Cointorox',
    symbol: 'OROX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7572/thumb/Cointorox.png?1559635132'
  },
  {
    chainId: 1,
    address: '0xc8645f0d6a622e2a9a66c1db9d33b1a46b4462dc',
    name: 'Mykonos Coin',
    symbol: 'MYK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13974/thumb/Untitled-design-10-removebg-preview.png?1613427313'
  },
  {
    chainId: 1,
    address: '0xfef3bef71a5eb97e097039038776fd967ae5b106',
    name: 'YFMoonshot',
    symbol: 'YFMS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12496/thumb/yfmoonshot_logo.jpg?1600266768'
  },
  {
    chainId: 1,
    address: '0x3ee6e46f52c475c52040b934745501f2a0cea252',
    name: 'DSCVR Finance',
    symbol: 'DSCVR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15131/thumb/dscvr.PNG?1619766992'
  },
  {
    chainId: 1,
    address: '0x4527a3b4a8a150403090a99b87effc96f2195047',
    name: 'P2P solutions found',
    symbol: 'P2PS',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/2798/thumb/p2ps.png?1547036973'
  },
  {
    chainId: 1,
    address: '0x4270bb238f6dd8b1c3ca01f96ca65b2647c06d3c',
    name: 'Fortuna',
    symbol: 'FOTA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2689/thumb/fortuna.png?1547483931'
  },
  {
    chainId: 1,
    address: '0xed7fa212e100dfb3b13b834233e4b680332a3420',
    name: 'Street Cred',
    symbol: 'CRED',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12680/thumb/cred_logo.png?1601611472'
  },
  {
    chainId: 1,
    address: '0x784561b89a160990f46de6db19571ca1b5f14bce',
    name: 'Most Protocol',
    symbol: 'MOST',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/12162/thumb/most_protocol_logo.png?1597728245'
  },
  {
    chainId: 1,
    address: '0x630d98424efe0ea27fb1b3ab7741907dffeaad78',
    name: 'PEAKDEFI',
    symbol: 'PEAK',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9626/thumb/PEAKDEFI_Logo_250x250.png?1603094772'
  },
  {
    chainId: 1,
    address: '0x83eb94cb563146a42fe0a8b3d051f2387a7fb81f',
    name: 'Cipher Core Token',
    symbol: 'CIPHC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11524/thumb/CipherCoreLogo_L.jpg?1590681179'
  },
  {
    chainId: 1,
    address: '0x042f972ac93404f0fcbe4e3a0729f0b395232106',
    name: 'XCoinPay',
    symbol: 'DYX',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9258/thumb/zyJi7L2o_400x400.jpg?1565650541'
  },
  // {
  //   chainId: 1,
  //   address: '0x111111111117dc0aa78b770fa6a738034120c302',
  //   name: '1inch',
  //   symbol: '1INCH',
  //   decimals: 18,
  //   logoURI: 'https://assets.coingecko.com/coins/images/13469/thumb/1inch-token.png?1608803028'
  // },
  {
    chainId: 1,
    address: '0xf25c91c87e0b1fd9b4064af0f427157aab0193a7',
    name: 'BASIC',
    symbol: 'BASIC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11050/thumb/unTgJN6U_400x400.jpg?1587540882'
  },
  {
    chainId: 1,
    address: '0x2f34dd3d46855277eee79a1d724c2249f770054b',
    name: 'GoForIt Walk Win',
    symbol: 'GOI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9564/thumb/E5q4JAYW_400x400.jpg?1568849486'
  },
  {
    chainId: 1,
    address: '0x41933422dc4a1cb8c822e06f12f7b52fa5e7e094',
    name: 'SOGE',
    symbol: 'SOGE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14690/thumb/photo_2021-04-16_12-35-09.jpg?1620027241'
  },
  {
    chainId: 1,
    address: '0xabc1280a0187a2020cc675437aed400185f86db6',
    name: 'Smart Application C',
    symbol: 'SAC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5515/thumb/smart-application-chain.png?1548609520'
  },
  {
    chainId: 1,
    address: '0x8400d94a5cb0fa0d041a3788e395285d61c9ee5e',
    name: 'Unibright',
    symbol: 'UBT',
    decimals: 8,
    logoURI:
      'https://assets.coingecko.com/coins/images/2707/thumb/UnibrightLogo_colorful_500x500_preview.png?1547036916'
  },
  {
    chainId: 1,
    address: '0xa91ac63d040deb1b7a5e4d4134ad23eb0ba07e14',
    name: 'Bella Protocol',
    symbol: 'BEL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12478/thumb/Bella.png?1602230054'
  },
  {
    chainId: 1,
    address: '0x610c67be018a5c5bdc70acd8dc19688a11421073',
    name: 'Hype Finance',
    symbol: 'HYPE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13193/thumb/hype_finance_logo.png?1606109896'
  },
  {
    chainId: 1,
    address: '0x02fdd6866333d8cd8b1ca022d382080698060bc2',
    name: '6ix9ine Chain',
    symbol: '69C',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11260/thumb/6ix9ineChain.png?1589789641'
  },
  {
    chainId: 1,
    address: '0xbf0f3ccb8fa385a287106fba22e6bb722f94d686',
    name: 'Zytara Dollar',
    symbol: 'ZUSD',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/13957/thumb/zusd.png?1613353410'
  },
  {
    chainId: 1,
    address: '0x426567f78e74577f8a6233b635970eb729631e05',
    name: 'Staker Token',
    symbol: 'STR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5122/thumb/staker-logo.png?1547040513'
  },
  {
    chainId: 1,
    address: '0xfb559ce67ff522ec0b9ba7f5dc9dc7ef6c139803',
    name: 'Probit Token',
    symbol: 'PROB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6765/thumb/ProBit-Exchange-logo.png?1547043029'
  },
  {
    chainId: 1,
    address: '0x2fcee080ceb4ff95fcdc8a03052cd85e51ecc69c',
    name: 'AlgOil',
    symbol: 'AGOL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14850/thumb/cache_956492647.png?1618796513'
  },
  {
    chainId: 1,
    address: '0x165440036ce972c5f8ebef667086707e48b2623e',
    name: 'UniGraph',
    symbol: 'GRAPH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12197/thumb/unigraph_logo.png?1597992376'
  },
  {
    chainId: 1,
    address: '0xa64bd6c70cb9051f6a9ba1f163fdc07e0dfb5f84',
    name: 'Aave LINK v1',
    symbol: 'ALINK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11729/thumb/aLINK.png?1593084323'
  },
  {
    chainId: 1,
    address: '0x3d0293f06daf4311b482564330d57c8db6c10893',
    name: 'Y Coin',
    symbol: 'YCO',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/14128/thumb/BIG-POTAT-200x200-removebg-preview.png?1614576159'
  },
  {
    chainId: 1,
    address: '0x9e547061a345015869d26c7b6ee4ab5b63424441',
    name: 'Cryptocart',
    symbol: 'CC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15210/thumb/cc.PNG?1620107415'
  },
  {
    chainId: 1,
    address: '0x37f74e99794853777a10ea1dc08a64c86958f06a',
    name: 'D Community',
    symbol: 'DILI',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/9485/thumb/BYf_d6kekNWOY34VLGPvf7RcVj8SeRi7P65Nk0W7ji1jir_qSLfssffnNW0ZzQSWzhhsNRkMCL7qcX4CCaMTbgKb0D7b5yNeStKVMm2deZd9cONMuItgoKUXxIYkHElfk1PW2uiNZbg6a2K0OHtZTvkt1xo8YVKuthskXaryOkM6Ax-MXQlHOfbjwCcnQQjHxAucufrCZJfDgrY.jpg?1571685571'
  },
  {
    chainId: 1,
    address: '0x956f47f50a910163d8bf957cf5846d573e7f87ca',
    name: 'Fei Protocol',
    symbol: 'FEI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14570/thumb/ZqsF51Re_400x400.png?1617082206'
  },
  {
    chainId: 1,
    address: '0x5aaefe84e0fb3dd1f0fcff6fa7468124986b91bd',
    name: 'Evedo',
    symbol: 'EVED',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7721/thumb/Variations-09.png?1549979992'
  },
  {
    chainId: 1,
    address: '0xabe9b2e4bbd5a8c718752c41254ed81aae7d98bf',
    name: 'P2P',
    symbol: 'P2P',
    decimals: 10,
    logoURI: 'https://assets.coingecko.com/coins/images/12486/thumb/p2p.png?1600207324'
  },
  {
    chainId: 1,
    address: '0x38f7cd43662d1cff4cc3c2c4b749f7cfed1d1db3',
    name: 'Neeva Defi',
    symbol: 'NVA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12919/thumb/NEEVA-TOKEN-1-1.png?1603667387'
  },
  {
    chainId: 1,
    address: '0x93ecd2ecdfb91ab2fee28a8779a6adfe2851cda6',
    name: 'LoanBurst',
    symbol: 'LBURST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12093/thumb/oKczM17b_400x400.jpg?1597273304'
  },
  {
    chainId: 1,
    address: '0xf3a2ace8e48751c965ea0a1d064303aca53842b9',
    name: 'HXY Money',
    symbol: 'HXY',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11225/thumb/hexmoneygradientsmall.png?1595305947'
  },
  {
    chainId: 1,
    address: '0xaf80951201a0eff85a0fd3adf4c7043db856d3e6',
    name: 'Mobilian Coin',
    symbol: 'MBN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12472/thumb/TqhkduvK_400x400.jpg?1600122610'
  },
  {
    chainId: 1,
    address: '0x244c5276ea5bb927575417156038d7381b44ab2c',
    name: 'Bridge Finance',
    symbol: 'BFR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13337/thumb/bridge_finance_logo.png?1607574570'
  },
  {
    chainId: 1,
    address: '0xd49ff13661451313ca1553fd6954bd1d9b6e02b9',
    name: 'Electrify Asia',
    symbol: 'ELEC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3415/thumb/d45b1d82743c749d05697da200179874.jpg?1547038096'
  },
  {
    chainId: 1,
    address: '0x135093731f61dd5cbfd7744751bf3ced3aaa69b1',
    name: 'HNT Chain',
    symbol: 'HNTC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9757/thumb/4162ad27bffddd40bede8468d161919c.png?1571437573'
  },
  {
    chainId: 1,
    address: '0x78175901e9b04090bf3b3d3cb7f91ca986fb1af6',
    name: 'Antique Zombie Shar',
    symbol: 'ZOMB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12617/thumb/cryptopunks-zomb.png?1602398280'
  },
  {
    chainId: 1,
    address: '0x681ecc5a0bfd18c308a1138ff607f818bac5e417',
    name: 'Luckstar',
    symbol: 'LST',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/7804/thumb/49211002_377935006115216_8098246624284770304_n.png?1550735229'
  },
  {
    chainId: 1,
    address: '0xa09ff006c652496e72d648cef2f4ee6777efdf6f',
    name: 'deCraft Finance',
    symbol: 'CRAFT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12775/thumb/decraft_logo.jpg?1602486383'
  },
  {
    chainId: 1,
    address: '0x957c30ab0426e0c93cd8241e2c60392d08c6ac8e',
    name: 'Modum',
    symbol: 'MOD',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/1040/thumb/modum.png?1548085261'
  },
  {
    chainId: 1,
    address: '0xa36e59c08c9f251a6b7a9eb6be6e32fd6157acd0',
    name: 'Previse',
    symbol: 'PRVS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13977/thumb/previse-logo-200.png?1613437063'
  },
  {
    chainId: 1,
    address: '0x59af0356cdebd1fa23ae5dadff9170bbfc31278c',
    name: 'Two Prime FF1 Token',
    symbol: 'FF1',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11008/thumb/IlJLc6NC_400x400.jpg?1587103393'
  },
  {
    chainId: 1,
    address: '0xbc5991ccd8caceba01edc44c2bb9832712c29cab',
    name: 'Unagii USD Coin',
    symbol: 'UUSDC',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/13781/thumb/uUSDC.png?1611729843'
  },
  {
    chainId: 1,
    address: '0x1fc52f1abade452dd4674477d4711951700b3d27',
    name: 'Noku',
    symbol: 'NOKU',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1577/thumb/noku.png?1548329133'
  },
  {
    chainId: 1,
    address: '0x426fc8be95573230f6e6bc4af91873f0c67b21b4',
    name: 'BlackPearl Token',
    symbol: 'BPLC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8931/thumb/EJIpComQ_400x400.png?1584653141'
  },
  {
    chainId: 1,
    address: '0x326caf6980d4e9161cfb3c55f195b3d825c266d4',
    name: 'BullionsChain',
    symbol: 'BLC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12674/thumb/transparent1.png?1601523923'
  },
  {
    chainId: 1,
    address: '0xb35e9ad7e9ebf731e04c73845f1bf75e55828700',
    name: 'BitRewards',
    symbol: 'XBRT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3514/thumb/DUu2c0KVAAUOvql_%281%29.jpg?1547038299'
  },
  {
    chainId: 1,
    address: '0x4bcea5e4d0f6ed53cf45e7a28febb2d3621d7438',
    name: 'Modex',
    symbol: 'MODEX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2851/thumb/LhWIeAg.png?1602566568'
  },
  {
    chainId: 1,
    address: '0xa7925aa2a6e4575ab0c74d169f3bc3e03d4c319a',
    name: 'Better Money',
    symbol: 'BETTER',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/12838/thumb/BETTER_MONEY_MASK_ICON.png?1602899651'
  },
  {
    chainId: 1,
    address: '0x70126bd012e39038792366c9115bb64407d5cbe1',
    name: 'EVA Coin',
    symbol: 'EVA',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/8294/thumb/-v8LzbDt_400x400.jpg?1557284982'
  },
  {
    chainId: 1,
    address: '0x7d14b842630cbc2530cb288109e5719e0c4d67d7',
    name: 'Innovation Blockcha',
    symbol: 'IBP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12903/thumb/ibp.?1603347704'
  },
  {
    chainId: 1,
    address: '0x06f3cdabae564b0546529b4dd8fef1bcd4235753',
    name: 'TilWiki',
    symbol: 'TLW',
    decimals: 8,
    logoURI:
      'https://assets.coingecko.com/coins/images/12915/thumb/89706561_1111490899211097_8975783115158978560_n.jpg?1603441976'
  },
  {
    chainId: 1,
    address: '0xac9bb427953ac7fddc562adca86cf42d988047fd',
    name: 'Scatter cx',
    symbol: 'STT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10422/thumb/scatter.png?1580876890'
  },
  {
    chainId: 1,
    address: '0x14d9444f6b9d55caba5d73f15bea947695c11c82',
    name: 'LeoPard Coin',
    symbol: 'LP',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/10795/thumb/lp.PNG?1583722336'
  },
  {
    chainId: 1,
    address: '0x5b322514ff727253292637d9054301600c2c81e8',
    name: 'DAD',
    symbol: 'DAD',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/9833/thumb/q_Vt0ajV_400x400.jpg?1572475136'
  },
  {
    chainId: 1,
    address: '0xc39835d32428728cbde6903f84c76750976c0323',
    name: 'BTC On Chain Beta P',
    symbol: 'BOCBP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11459/thumb/sw_btc_onchain_beta_set.png?1590141831'
  },
  {
    chainId: 1,
    address: '0x06890d4c65a4cb75be73d7ccb4a8ee7962819e81',
    name: 'xKNCb',
    symbol: 'XKNCB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13996/thumb/Token-4.png?1613546873'
  },
  {
    chainId: 1,
    address: '0x79c75e2e8720b39e258f41c37cc4f309e0b0ff80',
    name: 'Phantasma',
    symbol: 'SOUL',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/4130/thumb/phantasma.png?1548331035'
  },
  {
    chainId: 1,
    address: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
    name: 'Uniswap',
    symbol: 'UNI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12504/thumb/uniswap-uni.png?1600306604'
  },
  {
    chainId: 1,
    address: '0x627e2ee3dbda546e168eaaff25a2c5212e4a95a0',
    name: 'Inverse Bitcoin Vol',
    symbol: 'IBVOL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11057/thumb/download_%2810%29.png?1587642128'
  },
  {
    chainId: 1,
    address: '0xdd039990bd551ce7437d3bf54d155220b7988b71',
    name: 'Degens',
    symbol: 'DEGENS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14526/thumb/degens_logo.png?1616740013'
  },
  {
    chainId: 1,
    address: '0x41b3f18c6384dc9a39c33afeca60d9b8e61eaa9f',
    name: 'Noah Decentralized ',
    symbol: 'NOAHP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3442/thumb/FvG3XweD.png?1610437675'
  },
  {
    chainId: 1,
    address: '0x57700244b20f84799a31c6c96dadff373ca9d6c5',
    name: 'TrustDAO',
    symbol: 'TRUST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11923/thumb/trustwhite.png?1596704613'
  },
  {
    chainId: 1,
    address: '0xa14ea0e11121e6e951e87c66afe460a00bcd6a16',
    name: 'IdleDAI  Risk Adjus',
    symbol: 'IDLEDAISAFE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11929/thumb/idledai-safe.png?1596263241'
  },
  {
    chainId: 1,
    address: '0x95ba34760ac3d7fbe98ee8b2ab33b4f1a6d18878',
    name: 'DeCash',
    symbol: 'DESH',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/11772/thumb/Logo_Blanc_%28DESH%29_V3_200x200_rond.png?1597752785'
  },
  {
    chainId: 1,
    address: '0xe95990825aab1a7f0af4cc648f76a3bcc99f25b2',
    name: 'Zenswap Network Tok',
    symbol: 'ZNT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6452/thumb/3CGDn4e.png?1547042648'
  },
  {
    chainId: 1,
    address: '0x0f00c8dd21da51bc6a6ac07f491a7dbe69746f16',
    name: 'Clap Clap Token',
    symbol: 'CCT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7153/thumb/mLA2V_IB_400x400.jpg?1547043664'
  },
  {
    chainId: 1,
    address: '0x28fac5334c9f7262b3a3fe707e250e01053e07b5',
    name: 'IdleUSDT  Risk Adju',
    symbol: 'IDLEUSDTSAFE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11931/thumb/idleusdt-safe.png?1596263270'
  },
  {
    chainId: 1,
    address: '0x6baa91cd8aa07431760ef2eedfedcef662a6b8b3',
    name: '3X Short Exchange T',
    symbol: 'EXCHBEAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10331/thumb/683JEXMN_400x400_%281%29.png?1578052093'
  },
  {
    chainId: 1,
    address: '0x28a06c02287e657ec3f8e151a13c36a1d43814b0',
    name: 'BondApp tit Governa',
    symbol: 'BAG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14724/thumb/BAG2x.png?1618568573'
  },
  {
    chainId: 1,
    address: '0x4ac00f287f36a6aad655281fe1ca6798c9cb727b',
    name: 'GazeCoin',
    symbol: 'GZE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2035/thumb/gazecoin.png?1547742556'
  },
  {
    chainId: 1,
    address: '0xdf5e0e81dff6faf3a7e52ba697820c5e32d806a8',
    name: 'LP yCurve',
    symbol: 'YCURVE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11858/thumb/yCrv.png?1595203628'
  },
  {
    chainId: 1,
    address: '0x625ae63000f46200499120b906716420bd059240',
    name: 'Aave SUSD v1',
    symbol: 'ASUSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11723/thumb/aSUSD.png?1593082612'
  },
  {
    chainId: 1,
    address: '0xd13c7342e1ef687c5ad21b27c2b65d772cab5c8c',
    name: 'Ultra',
    symbol: 'UOS',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/4480/thumb/Ultra.png?1563356418'
  },
  {
    chainId: 1,
    address: '0x98a90499b62ae48e151a66b0f647570b5a473b1c',
    name: 'ZAC Finance',
    symbol: 'ZAC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12692/thumb/zaclogo200.png?1601693830'
  },
  {
    chainId: 1,
    address: '0x1f8f123bf24849443a56ed9fc42b9265b7f3a39a',
    name: 'UniTopia Token',
    symbol: 'UTO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11922/thumb/logo200.png?1596101623'
  },
  {
    chainId: 1,
    address: '0xf85ef57fcdb36d628d063fa663e61e44d35ae661',
    name: 'eToro Pound Sterlin',
    symbol: 'GBPX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10524/thumb/Untitled_Diagram-3.png?1580511312'
  },
  {
    chainId: 1,
    address: '0xec0d77a58528a218cbf41fa6e1585c8d7a085868',
    name: 'oneETH',
    symbol: 'ONEETH',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15090/thumb/logo_200_eth.png?1619665764'
  },
  {
    chainId: 1,
    address: '0xf52cdcd458bf455aed77751743180ec4a595fd3f',
    name: 'IdleSUSD  Yield ',
    symbol: 'IDLESUSDYIELD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11934/thumb/idlesusdv3mmaxyield_32.png?1596263703'
  },
  {
    chainId: 1,
    address: '0xbc6da0fe9ad5f3b0d58160288917aa56653660e9',
    name: 'Alchemix USD',
    symbol: 'ALUSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14114/thumb/Alchemix_USD.png?1614410406'
  },
  {
    chainId: 1,
    address: '0x82a77710495a35549d2add797412b4a4497d33ef',
    name: 'Dogz',
    symbol: 'DOGZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7544/thumb/dogz.png?1604655282'
  },
  {
    chainId: 1,
    address: '0x85ca6710d0f1d511d130f6935edda88acbd921bd',
    name: 'Pledgecamp',
    symbol: 'PLG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5120/thumb/pledgecamop.png?1550204115'
  },
  {
    chainId: 1,
    address: '0xa31108e5bab5494560db34c95492658af239357c',
    name: 'Dacsee',
    symbol: 'DACS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4834/thumb/dacsee-token-logo.png?1547040217'
  },
  {
    chainId: 1,
    address: '0x8c81121b15197fa0eeaee1dc75533419dcfd3151',
    name: 'IdleWBTC  Best Yiel',
    symbol: 'IDLEWBTCYIELD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11936/thumb/idlewbtcv3maxyield_32.png?1596263736'
  },
  {
    chainId: 1,
    address: '0x6aeb95f06cda84ca345c2de0f3b7f96923a44f4c',
    name: 'Rentberry',
    symbol: 'BERRY',
    decimals: 14,
    logoURI: 'https://assets.coingecko.com/coins/images/1787/thumb/rentberry.png?1548608046'
  },
  {
    chainId: 1,
    address: '0x6f3009663470475f0749a6b76195375f95495fcb',
    name: 'Hatch DAO',
    symbol: 'HATCH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12526/thumb/hatch-dao.jpg?1600480488'
  },
  {
    chainId: 1,
    address: '0x1571ed0bed4d987fe2b498ddbae7dfa19519f651',
    name: 'iFARM',
    symbol: 'IFARM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14472/thumb/ifarm.png?1616389133'
  },
  {
    chainId: 1,
    address: '0xd780ae2bf04cd96e577d3d014762f831d97129d0',
    name: 'Envion',
    symbol: 'EVN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1547/thumb/envion.jpg?1547035704'
  },
  {
    chainId: 1,
    address: '0x6c3f90f043a72fa612cbac8115ee7e52bde6e490',
    name: 'LP 3pool Curve',
    symbol: '3CRV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12972/thumb/3pool_128.png?1603948039'
  },
  {
    chainId: 1,
    address: '0x24dcc881e7dd730546834452f21872d5cb4b5293',
    name: 'Data Transaction To',
    symbol: 'XD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3460/thumb/xd_01.png?1560142295'
  },
  {
    chainId: 1,
    address: '0x55b54d8fb1640d1321d5164590e7b020ba43def2',
    name: '1X Short XRP Token',
    symbol: 'XRPHEDGE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12059/thumb/683JEXMN_400x400.png?1596705201'
  },
  {
    chainId: 1,
    address: '0xe5867608b51a2c9c78b9587355cc093140a49b0a',
    name: 'Speed Mining Servic',
    symbol: 'SMS',
    decimals: 3,
    logoURI: 'https://assets.coingecko.com/coins/images/1807/thumb/speed-mining-service.png?1548610827'
  },
  {
    chainId: 1,
    address: '0xc3dd23a0a854b4f9ae80670f528094e9eb607ccb',
    name: 'Trendering',
    symbol: 'TRND',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11941/thumb/trnd-ico-200.png?1605147194'
  },
  {
    chainId: 1,
    address: '0x321c2fe4446c7c963dc41dd58879af648838f98d',
    name: 'Cryptex Finance',
    symbol: 'CTX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14932/thumb/glossy_icon_-_C200px.png?1619073171'
  },
  {
    chainId: 1,
    address: '0xbcdfe338d55c061c084d81fd793ded00a27f226d',
    name: 'Decentralized Machi',
    symbol: 'DML',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3297/thumb/decentralized-machine-learning.png?1547037880'
  },
  {
    chainId: 1,
    address: '0x9d91be44c06d373a8a226e1f3b146956083803eb',
    name: 'Aave KNC v1',
    symbol: 'AKNC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11727/thumb/aKNC.png?1593083924'
  },
  {
    chainId: 1,
    address: '0x21cf09bc065082478dcc9ccb5fd215a978dc8d86',
    name: 'Jem',
    symbol: 'JEM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13044/thumb/itchiro-defi.png?1605892179'
  },
  {
    chainId: 1,
    address: '0xc528c28fec0a90c083328bc45f587ee215760a0f',
    name: 'Endor Protocol Toke',
    symbol: 'EDR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3683/thumb/0b805574-ef0d-41dc-b518-8d6148bf4716.png?1547038680'
  },
  {
    chainId: 1,
    address: '0x9d24364b97270961b2948734afe8d58832efd43a',
    name: 'Yefam Finance',
    symbol: 'FAM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12359/thumb/FAM200.png?1600333720'
  },
  {
    chainId: 1,
    address: '0x7b0f66fa5cf5cc28280c1e7051af881e06579362',
    name: 'YFarmLand Token',
    symbol: 'YFARMER',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12652/thumb/yfarmer.png?1601435163'
  },
  {
    chainId: 1,
    address: '0xe2db94e8d4e4144c336e45668a792d17d48a482c',
    name: 'Nuvo Cash',
    symbol: 'NUVO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11422/thumb/nuvo.png?1590109082'
  },
  {
    chainId: 1,
    address: '0xc3c221fe28c33814c28c822b631fd76047ef1a63',
    name: 'Millimeter',
    symbol: 'MM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12941/thumb/mm%EB%A1%9C%EA%B3%A0-2.png?1603765868'
  },
  {
    chainId: 1,
    address: '0x123151402076fc819b7564510989e475c9cd93ca',
    name: 'Wrapped DGLD',
    symbol: 'WDGLD',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13301/thumb/wrappeddgld_32.png?1607310693'
  },
  {
    chainId: 1,
    address: '0x7a82c573b378ceea29772afb93891f0d0afa93b7',
    name: 'Wizard',
    symbol: 'WIZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12942/thumb/WIZ.png?1614827482'
  },
  {
    chainId: 1,
    address: '0x861b2456ac1a6ab5fb5c72aa456091f23ddec1cc',
    name: 'Vaultz',
    symbol: 'VAULTZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13488/thumb/vaultz_logo.jpg?1609112163'
  },
  {
    chainId: 1,
    address: '0x78f225869c08d478c34e5f645d07a87d3fe8eb78',
    name: 'PieDAO DEFI Large C',
    symbol: 'DEFI+L',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12731/thumb/DeFi_L.png?1602070128'
  },
  {
    chainId: 1,
    address: '0x10be9a8dae441d276a5027936c3aaded2d82bc15',
    name: 'UniMex Network',
    symbol: 'UMX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13615/thumb/Unimex-Brandmark-RGB-Small_200x200.png?1612758290'
  },
  {
    chainId: 1,
    address: '0xf911a7ec46a2c6fa49193212fe4a2a9b95851c27',
    name: 'Antiample',
    symbol: 'XAMP',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/11925/thumb/antiample.png?1596168983'
  },
  {
    chainId: 1,
    address: '0x3ac7a71b97183e3db7722c75eaa8df2c1a0badfc',
    name: 'Unicly Waifu Collec',
    symbol: 'UWAIFU',
    decimals: 24,
    logoURI: 'https://assets.coingecko.com/coins/images/15218/thumb/y1ano8ch_400x400.jpg?1620116826'
  },
  {
    chainId: 1,
    address: '0x54b8c98268da0055971652a95f2bfd3a9349a38c',
    name: 'Printer Finance',
    symbol: 'PRINT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12497/thumb/moneyprinter_anm.png?1600499835'
  },
  {
    chainId: 1,
    address: '0x720c2c93f5f9a6b82226e84095558b10f399b0fa',
    name: 'Gric Coin',
    symbol: 'GC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7144/thumb/gric.png?1576037693'
  },
  {
    chainId: 1,
    address: '0x0d438f3b5175bebc262bf23753c1e53d03432bde',
    name: 'Wrapped NXM',
    symbol: 'WNXM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11890/thumb/wrapped-nexus-mutual.jpg?1595811559'
  },
  {
    chainId: 1,
    address: '0xd559f20296ff4895da39b5bd9add54b442596a61',
    name: 'FintruX',
    symbol: 'FTX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1928/thumb/token-500x500.png?1547036223'
  },
  {
    chainId: 1,
    address: '0xa8b919680258d369114910511cc87595aec0be6d',
    name: 'LUKSO Token',
    symbol: 'LYXE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11423/thumb/1_QAHTciwVhD7SqVmfRW70Pw.png?1590110612'
  },
  {
    chainId: 1,
    address: '0x5046e860ff274fb8c66106b0ffb8155849fb0787',
    name: 'JavaScript Token',
    symbol: 'JS',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/1061/thumb/js.png?1511578998'
  },
  {
    chainId: 1,
    address: '0x36ac219f90f5a6a3c77f2a7b660e3cc701f68e25',
    name: 'CoinMetro',
    symbol: 'XCM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3125/thumb/xcm.png?1547037518'
  },
  {
    chainId: 1,
    address: '0xd3deff001ef67e39212f4973b617c2e684fa436c',
    name: '484 Fund',
    symbol: 'ERROR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15071/thumb/error.png?1619623078'
  },
  {
    chainId: 1,
    address: '0x5cb888182fbffdb62c08fb4b5a343914f00fdfee',
    name: 'Moneybrain BiPS',
    symbol: 'BIPS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15556/thumb/bips_logo_200x200_Trans.png?1621204965'
  },
  {
    chainId: 1,
    address: '0x25e1474170c4c0aa64fa98123bdc8db49d7802fa',
    name: 'Bidao',
    symbol: 'BID',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12596/thumb/bidao.png?1600996485'
  },
  {
    chainId: 1,
    address: '0x21d5678a62dfe63a47062469ebb2fac2817d8832',
    name: 'YOLOCash',
    symbol: 'YLC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/4368/thumb/received_2320303067998561.png?1547039717'
  },
  {
    chainId: 1,
    address: '0x50eb346fc29a80d97563a50146c3fcf9423b5538',
    name: 'Skull Candy Shards',
    symbol: 'CANDY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12934/thumb/Skull_Candy.png?1603719579'
  },
  {
    chainId: 1,
    address: '0x59f1b6d0f5105cf025a3de58858a9363f4fef084',
    name: 'Auscoin',
    symbol: 'AUSC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6081/thumb/auscoin-logo.png?1547042059'
  },
  {
    chainId: 1,
    address: '0xe692c8d72bd4ac7764090d54842a305546dd1de5',
    name: 'ANY Blocknet',
    symbol: 'ABLOCK',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/14869/thumb/blocknet.jpg?1618816800'
  },
  {
    chainId: 1,
    address: '0x86225481747c774b24c7c3bac4c1b7382f787c7f',
    name: 'WIIX Coin',
    symbol: 'WXC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10386/thumb/a2a4Vr1__400x400.jpg?1578783194'
  },
  {
    chainId: 1,
    address: '0x91a7769afcf796a3c8fff879ed80b10dd76d3ccd',
    name: 'SG20',
    symbol: 'SG20',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14427/thumb/T16tIw3n_400x400.jpg?1616031599'
  },
  {
    chainId: 1,
    address: '0x9f284e1337a815fe77d2ff4ae46544645b20c5ff',
    name: 'Darwinia Commitment',
    symbol: 'KTON',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11895/thumb/KTON.png?1615271813'
  },
  {
    chainId: 1,
    address: '0x9b39a0b97319a9bd5fed217c1db7b030453bac91',
    name: 'TigerCash',
    symbol: 'TCH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4956/thumb/tigercash-logo.png?1547040378'
  },
  {
    chainId: 1,
    address: '0x6beb418fc6e1958204ac8baddcf109b8e9694966',
    name: 'Linker Coin',
    symbol: 'LNC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3885/thumb/linker-coin.png?1547975979'
  },
  {
    chainId: 1,
    address: '0xbdcfbf5c4d91abc0bc9709c7286d00063c0e6f22',
    name: 'PeerGuess',
    symbol: 'GUESS',
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/1743/thumb/guess.png?1547036022'
  },
  {
    chainId: 1,
    address: '0xd23ac27148af6a2f339bd82d0e3cff380b5093de',
    name: 'Siren',
    symbol: 'SI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13955/thumb/siren_logo.png?1613310803'
  },
  {
    chainId: 1,
    address: '0xea004e8fa3701b8e58e41b78d50996e0f7176cbd',
    name: 'yffc finance',
    symbol: 'YFFC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12439/thumb/yffc.png?1599868672'
  },
  {
    chainId: 1,
    address: '0x6c8c6b02e7b2be14d4fa6022dfd6d75921d90e4e',
    name: 'cBAT',
    symbol: 'CBAT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10645/thumb/cBAT1.JPG?1581390910'
  },
  {
    chainId: 1,
    address: '0xa6d5c720a9af5a405dfb6b9f44fc44fab5d4a58d',
    name: 'Ezystayz',
    symbol: 'EZY',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9062/thumb/5521.png?1603333090'
  },
  {
    chainId: 1,
    address: '0xd8e2b153e94daec5fe657a49ff59bb68fa67f126',
    name: 'FRED Energy  ERC 20',
    symbol: 'FREDX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14628/thumb/AcD80M3u_400x400.jpg?1617348984'
  },
  {
    chainId: 1,
    address: '0x400b1d8a7dd8c471026b2c8cbe1062b27d120538',
    name: 'Limestone Network',
    symbol: 'LIMEX',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11907/thumb/nw1FE_f4_400x400.png?1596074376'
  },
  {
    chainId: 1,
    address: '0xf6bf74a97d78f2242376769ef1e79885cf1f0c1c',
    name: 'KAASO',
    symbol: 'KAASO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9657/thumb/KAASO.png?1570436692'
  },
  {
    chainId: 1,
    address: '0xb4742e2013f96850a5cef850a3bb74cf63b9a5d5',
    name: 'EANTO',
    symbol: 'EAN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11120/thumb/BKL0LdS7_400x400.png?1588730490'
  },
  {
    chainId: 1,
    address: '0x0327112423f3a68efdf1fcf402f6c5cb9f7c33fd',
    name: 'PieDAO BTC  ',
    symbol: 'BTC++',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10959/thumb/BTC__.png?1586499443'
  },
  {
    chainId: 1,
    address: '0xd38aeb759891882e78e957c80656572503d8c1b1',
    name: 'sCRV',
    symbol: 'SCRV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14954/thumb/sCRV.png?1619129512'
  },
  {
    chainId: 1,
    address: '0xacaca5b8805636608e14c64b0bfffc2deb2c6cec',
    name: 'ROM Token',
    symbol: 'ROM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7358/thumb/ROMToken-logo.png?1547275950'
  },
  {
    chainId: 1,
    address: '0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e',
    name: 'yearn finance',
    symbol: 'YFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11849/thumb/yfi-192x192.png?1598325330'
  },
  {
    chainId: 1,
    address: '0xa8b6d0bd067ce5b2e4e9e225b2e0ff5eb74ded8a',
    name: 'OBIC',
    symbol: 'OBIC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12401/thumb/png-300x300.png?1599559020'
  },
  {
    chainId: 1,
    address: '0x87f5e8c3425218837f3cb67db941af0c01323e56',
    name: 'BitCoin One',
    symbol: 'BTCONE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5413/thumb/b-one.png?1574907888'
  },
  {
    chainId: 1,
    address: '0xf65b5c5104c4fafd4b709d9d60a185eae063276c',
    name: 'Truebit Protocol',
    symbol: 'TRU',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15053/thumb/truebit_logo.jpg?1619577133'
  },
  {
    chainId: 1,
    address: '0x06a01a4d579479dd5d884ebf61a31727a3d8d442',
    name: 'SmartKey',
    symbol: 'SKEY',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13425/thumb/smart-key_sign-256.png?1608531133'
  },
  {
    chainId: 1,
    address: '0xa58a4f5c4bb043d2cc1e170613b74e767c94189b',
    name: 'UTU Coin',
    symbol: 'UTU',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12831/thumb/Aa5nmbkJ_400x400.png?1602884636'
  },
  {
    chainId: 1,
    address: '0x9ea463ec4ce9e9e5bc9cfd0187c4ac3a70dd951d',
    name: 'ETH 20 Day MA Cross',
    symbol: 'ETH20SMACO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10616/thumb/eth-20-day-moving-average-set.png?1580971906'
  },
  {
    chainId: 1,
    address: '0x3d1ba9be9f66b8ee101911bc36d3fb562eac2244',
    name: 'Rivetz',
    symbol: 'RVT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/957/thumb/rivetz.png?1547034826'
  },
  {
    chainId: 1,
    address: '0x281f5b914b0d589f8193cd5e711c6920874e00c8',
    name: 'MBM Token',
    symbol: 'MBM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10515/thumb/MBM_token.png?1580422618'
  },
  {
    chainId: 1,
    address: '0xc719d010b63e5bbf2c0551872cd5316ed26acd83',
    name: 'Etherisc DIP Token',
    symbol: 'DIP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4586/thumb/dip.png?1547039863'
  },
  {
    chainId: 1,
    address: '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
    name: 'Polygon',
    symbol: 'MATIC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4713/thumb/matic___polygon.jpg?1612939050'
  },
  {
    chainId: 1,
    address: '0xb4efd85c19999d84251304bda99e90b92300bd93',
    name: 'Rocket Pool',
    symbol: 'RPL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2090/thumb/rocket.png?1563781948'
  },
  {
    chainId: 1,
    address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    name: 'USD Coin',
    symbol: 'USDC',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389'
  },
  {
    chainId: 1,
    address: '0x28dee01d53fed0edf5f6e310bf8ef9311513ae40',
    name: 'BlitzPredict',
    symbol: 'XBP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2458/thumb/BlitzPredict.jpg?1547701183'
  },
  {
    chainId: 1,
    address: '0x4fbb350052bca5417566f188eb2ebce5b19bc964',
    name: 'RigoBlock',
    symbol: 'GRG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1532/thumb/Symbol-RigoblockRGB.png?1547035682'
  },
  {
    chainId: 1,
    address: '0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b',
    name: 'Crypto com Coin',
    symbol: 'CRO',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/7310/thumb/cypto.png?1547043960'
  },
  {
    chainId: 1,
    address: '0xe120c1ecbfdfea7f0a8f0ee30063491e8c26fedf',
    name: 'Suretly',
    symbol: 'SUR',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/914/thumb/suretly.png?1547034752'
  },
  {
    chainId: 1,
    address: '0x7ff4169a6b5122b664c51c95727d87750ec07c84',
    name: 'Tenset',
    symbol: '10SET',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14629/thumb/10set.png?1617353812'
  },
  {
    chainId: 1,
    address: '0x16448014a29484b82e3a5a6cf254e5c563a28929',
    name: 'The APIS',
    symbol: 'API',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14055/thumb/API.jpg?1614054286'
  },
  {
    chainId: 1,
    address: '0xe5caef4af8780e59df925470b050fb23c43ca68c',
    name: 'Ferrum Network',
    symbol: 'FRM',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/8251/thumb/frm.png?1563777564'
  },
  {
    chainId: 1,
    address: '0xd0929d411954c47438dc1d871dd6081f5c5e149c',
    name: 'Refereum',
    symbol: 'RFR',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/2102/thumb/refereum.png?1548608001'
  },
  {
    chainId: 1,
    address: '0x5cf04716ba20127f1e2297addcf4b5035000c9eb',
    name: 'NKN',
    symbol: 'NKN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3375/thumb/nkn.png?1548329212'
  },
  {
    chainId: 1,
    address: '0x5bb29c33c4a3c29f56f8aca40b4db91d8a5fe2c5',
    name: 'One Share',
    symbol: 'ONS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13531/thumb/bss.a1671c75.png?1609452258'
  },
  {
    chainId: 1,
    address: '0x9b639486f4a40c1a7a6728114f2413973f5fa4c6',
    name: 'Electronic Move Pay',
    symbol: 'EMP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9338/thumb/26.png?1566387741'
  },
  {
    chainId: 1,
    address: '0x10a34bbe9b3c5ad536ca23d5eefa81ca448e92ff',
    name: 'DSYS',
    symbol: 'DSYS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10478/thumb/4PvxXDdP_400x400.jpg?1579844065'
  },
  {
    chainId: 1,
    address: '0x2e68dfb3f50ea302c88f8db74096d57565d9970a',
    name: 'Amino Network',
    symbol: 'AMIO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8691/thumb/-wSIJXMr_400x400.jpg?1560235867'
  },
  {
    chainId: 1,
    address: '0x44564d0bd94343f72e3c8a0d22308b7fa71db0bb',
    name: 'BasketDAO',
    symbol: 'BASK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14636/thumb/BASK_200_CIRCLE.png?1617494952'
  },
  {
    chainId: 1,
    address: '0xead7f3ae4e0bb0d8785852cc37cc9d0b5e75c06a',
    name: '3X Long EOS Token',
    symbol: 'EOSBULL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10236/thumb/683JEXMN_400x400_%281%29.png?1576632680'
  },
  {
    chainId: 1,
    address: '0x841fb148863454a3b3570f515414759be9091465',
    name: 'Shih Tzu',
    symbol: 'SHIH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15309/thumb/shit.PNG?1620374274'
  },
  {
    chainId: 1,
    address: '0x747f564d258612ec5c4e24742c5fd4110bcbe46b',
    name: 'Nodeseeds',
    symbol: 'NDS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14658/thumb/n1EiZLt.png?1618374330'
  },
  {
    chainId: 1,
    address: '0x8db90e3e7d04c875a51997092f9178fcac9defdb',
    name: 'Portal',
    symbol: 'PORTAL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6626/thumb/Nljbrphf_400x400.jpg?1547042827'
  },
  {
    chainId: 1,
    address: '0xdd690d8824c00c84d64606ffb12640e932c1af56',
    name: 'Tavittcoin',
    symbol: 'TAVITT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/5155/thumb/tavitt-logo.png?1547040566'
  },
  {
    chainId: 1,
    address: '0xaf5db2cc35ef9bba3cfa5f8faeedcf9b4e2f286f',
    name: 'Voltium',
    symbol: 'VLTM',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/15349/thumb/voltm.PNG?1620625153'
  },
  {
    chainId: 1,
    address: '0x9d5e6b92ba3f75589943372df82dbd3a8a802e80',
    name: 'FINPLE',
    symbol: 'FPT',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/10260/thumb/aH4x0_6B6qaFjtyw9xn9HSyp1MwOpXOu8d-Anaxd0h9LyfPDIiq9EQ9dwKMx3fRQctuRbXG9RNVxtT58qcGmhvr9D-zl2B0NMIL2ywbcWUcLlgVSft0T8u0GGhsCHx6_3J_yUS4zqjJkmhrcC5Tm3e3ahBvRTFsz1jH4ptUt9niucuh2K9hprP_XKuVR1EXEjUa5dT47ZjWqjmk.jpg?1576795549'
  },
  {
    chainId: 1,
    address: '0xea5f88e54d982cbb0c441cde4e79bc305e5b43bc',
    name: 'PARETO Rewards',
    symbol: 'PARETO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2783/thumb/pareto.png?1547036965'
  },
  {
    chainId: 1,
    address: '0xac8ea871e2d5f4be618905f36f73c760f8cfdc8e',
    name: 'BTC Network Demand ',
    symbol: 'BYTE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10929/thumb/bytetree_set_1.png?1585894918'
  },
  {
    chainId: 1,
    address: '0xe0b7e882c194881c690924cb46154b8241f9145e',
    name: 'Cofinex',
    symbol: 'CNX',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/10834/thumb/abstract-logo-template-for-a-corporation-1531l-2833_%282%29.png?1592292069'
  },
  {
    chainId: 1,
    address: '0xf7413489c474ca4399eee604716c72879eea3615',
    name: 'APYSwap',
    symbol: 'APYS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14163/thumb/971k1DeM_400x400.png?1614725664'
  },
  {
    chainId: 1,
    address: '0x29502fe4d233ef0b45c3647101fa1252ce0634bd',
    name: 'Froge Finance',
    symbol: 'FROGE',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14775/thumb/-p8cz7Bk_400x400.png?1618371907'
  },
  {
    chainId: 1,
    address: '0x048eb9b9c08e2df45644cf0db1fcb3e866c401ad',
    name: 'Live Swap Coin',
    symbol: 'DSC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15287/thumb/lsc.PNG?1620356318'
  },
  {
    chainId: 1,
    address: '0x38c6a68304cdefb9bec48bbfaaba5c5b47818bb2',
    name: 'High Performance Bl',
    symbol: 'HPB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2363/thumb/HPB_Logo_2020_-_Colour.jpg?1596097580'
  },
  {
    chainId: 1,
    address: '0xf961a1fa7c781ecd23689fe1d0b7f3b6cbb2f972',
    name: 'Wrapped Moon Cats',
    symbol: 'MCAT20',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14387/thumb/MCAT20.png?1615803341'
  },
  {
    chainId: 1,
    address: '0x63f88a2298a5c4aee3c216aa6d926b184a4b2437',
    name: 'GameCredits',
    symbol: 'GAME',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/193/thumb/XlQmXoU.png?1595304945'
  },
  {
    chainId: 1,
    address: '0x48be867b240d2ffaff69e0746130f2c027d8d3d2',
    name: 'Elevate',
    symbol: 'ELE',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13802/thumb/elevate-logo.png?1611980666'
  },
  {
    chainId: 1,
    address: '0x79ba92dda26fce15e1e9af47d5cfdfd2a093e000',
    name: 'SERGS',
    symbol: 'SERGS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13031/thumb/sergs_logo.png?1604476848'
  },
  {
    chainId: 1,
    address: '0x0f775ad69e3c93d599d3315a130bd82a0cdda397',
    name: 'apeUSD LINK Synthet',
    symbol: 'APEUSD-LINK-DEC21',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14396/thumb/apeusd.jpg?1615905248'
  },
  {
    chainId: 1,
    address: '0x06576eb3b212d605b797dc15523d9dc9f4f66db4',
    name: 'The Crypto Propheci',
    symbol: 'TCP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15054/thumb/tcp.PNG?1619579195'
  },
  {
    chainId: 1,
    address: '0x5c0bc243fb13632c4d247f4f0bc27f2f58982c39',
    name: 'ORYX',
    symbol: 'ORYX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12217/thumb/orx200.png?1598238697'
  },
  {
    chainId: 1,
    address: '0x8aa2af3df13bb6f0cb922bd34446d65244733ad0',
    name: 'Libra',
    symbol: 'LC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13575/thumb/Untitled-design-7-removebg-preview-1.png?1609887720'
  },
  {
    chainId: 1,
    address: '0x5978708d6cce1cc9640eed47422d64c91bbd5171',
    name: 'LOLTOKEN',
    symbol: 'LOL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8551/thumb/logo100.png?1596534732'
  },
  {
    chainId: 1,
    address: '0xad7ca17e23f13982796d27d1e6406366def6ee5f',
    name: 'rHEGIC2',
    symbol: 'RHEGIC2',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13969/thumb/ezgif-4-b5306690cb32.jpg?1613385300'
  },
  {
    chainId: 1,
    address: '0x3e65e1eefde5ea7ccfc9a9a1634abe90f32262f8',
    name: 'BaaSid',
    symbol: 'BAAS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5463/thumb/baasid.png?1547041199'
  },
  {
    chainId: 1,
    address: '0x31024a4c3e9aeeb256b825790f5cb7ac645e7cd5',
    name: 'Xiotri',
    symbol: 'XIOT',
    decimals: 3,
    logoURI: 'https://assets.coingecko.com/coins/images/12182/thumb/xiot_logo_512x512.png?1601775223'
  },
  {
    chainId: 1,
    address: '0x010d14d36c3ea6570d240ae3ac9d660398f7c48e',
    name: 'Cenfura Token',
    symbol: 'XCF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10987/thumb/fq0jtSNG_400x400.png?1586913905'
  },
  {
    chainId: 1,
    address: '0xf2cee90309418353a57717eca26c4f8754f0d84e',
    name: 'BitcoinBrand',
    symbol: 'BTCB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6607/thumb/y76xcDSr_400x400.jpg?1547042811'
  },
  {
    chainId: 1,
    address: '0x14f0a12a43c36c49d4b403dd6e1a9b8222be456c',
    name: 'VINX COIN',
    symbol: 'VXC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11786/thumb/vinx.png?1594005658'
  },
  {
    chainId: 1,
    address: '0xde7d85157d9714eadf595045cc12ca4a5f3e2adb',
    name: 'STP Network',
    symbol: 'STPT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8713/thumb/STP.png?1560262664'
  },
  {
    chainId: 1,
    address: '0xa2c1e04aca801da92fa95af161040d37f103d69d',
    name: 'CoinAnalyst',
    symbol: 'COY',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/4671/thumb/CA-CoinAnalyst-Icon_only-%28unapplicable%29-Default_old.png?1619746045'
  },
  {
    chainId: 1,
    address: '0x946112efab61c3636cbd52de2e1392d7a75a6f01',
    name: 'Hydro',
    symbol: 'HYDRO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3678/thumb/Hydro-Blu-512.png?1547038669'
  },
  {
    chainId: 1,
    address: '0x06b179e292f080871825bed5d722162fd96b4c95',
    name: '10x gg',
    symbol: 'XGG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14142/thumb/coingecko_%281%29.png?1614650659'
  },
  {
    chainId: 1,
    address: '0x30647a72dc82d7fbb1123ea74716ab8a317eac19',
    name: 'imUSD',
    symbol: 'IMUSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13696/thumb/imUSD.png?1610971385'
  },
  {
    chainId: 1,
    address: '0x35872fea6a4843facbcdbce99e3b69596a3680b8',
    name: '1337',
    symbol: '1337',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/13348/thumb/logo.png?1607791847'
  },
  {
    chainId: 1,
    address: '0x5c3a228510d246b78a3765c20221cbf3082b44a4',
    name: 'Storiqa',
    symbol: 'STQ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1929/thumb/storiqa.png?1548611108'
  },
  {
    chainId: 1,
    address: '0x3431f91b3a388115f00c5ba9fdb899851d005fb5',
    name: 'GeroWallet',
    symbol: 'GERO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15602/thumb/PdAQXY8t_400x400.jpg?1621307751'
  },
  {
    chainId: 1,
    address: '0xdbdb4d16eda451d0503b854cf79d55697f90c8df',
    name: 'Alchemix',
    symbol: 'ALCX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14113/thumb/Alchemix.png?1614409874'
  },
  {
    chainId: 1,
    address: '0xc691bc298a304d591ad9b352c7a8d216de9f2ced',
    name: 'Polaris Share',
    symbol: 'POLA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13817/thumb/tHQrxdYQ_400x400.png?1612051098'
  },
  {
    chainId: 1,
    address: '0x8d2bffcbb19ff14a698c424fbcdcfd17aab9b905',
    name: 'Unicly CryptoPunks ',
    symbol: 'UPUNK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14905/thumb/cryptopunk7804.png?1618978888'
  },
  {
    chainId: 1,
    address: '0x6653c0d21507573cc39ead1e609d74d5e0ca16e2',
    name: 'ReFork',
    symbol: 'EFK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12267/thumb/ppYvWW7.png?1598617719'
  },
  {
    chainId: 1,
    address: '0xd8d887b5611a5b3e90bf764085d9858031d2be67',
    name: 'UCOS Token',
    symbol: 'UCOS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14343/thumb/ucos200.png?1615503284'
  },
  {
    chainId: 1,
    address: '0x07ef9e82721ac16809d24dafbe1792ce01654db4',
    name: 'Chimpion',
    symbol: 'BNANA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7522/thumb/banana-token-logo.jpg?1548167777'
  },
  {
    chainId: 1,
    address: '0xf013e0ea26cb386b3021783a3201bf2652778f93',
    name: 'ARTISTA',
    symbol: 'ARTS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9733/thumb/arts.PNG?1571306798'
  },
  {
    chainId: 1,
    address: '0xab7aaf9e485a3bc885985184abe9fc6aba727bd6',
    name: 'MANY',
    symbol: 'MANY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12577/thumb/MANY_logo_NoBanksNearby.png?1601347315'
  },
  {
    chainId: 1,
    address: '0x4946fcea7c692606e8908002e55a582af44ac121',
    name: 'FOAM',
    symbol: 'FOAM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3345/thumb/K51lJQc.png?1547037959'
  },
  {
    chainId: 1,
    address: '0x601938988f0fdd937373ea185c33751462b1d194',
    name: 'Etherpay',
    symbol: 'ETHPY',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/12417/thumb/Captura-de-Tela-2020-09-09-a-s-13-54-20.png?1599692074'
  },
  {
    chainId: 1,
    address: '0x7e7e112a68d8d2e221e11047a72ffc1065c38e1a',
    name: 'Badger Sett Digg',
    symbol: 'BDIGG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14503/thumb/DIGG.png?1616560407'
  },
  {
    chainId: 1,
    address: '0xc8c424b91d8ce0137bab4b832b7f7d154156ba6c',
    name: 'apM Coin',
    symbol: 'APM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10069/thumb/PvDCvh5h_400x400.png?1575460334'
  },
  {
    chainId: 1,
    address: '0x58b6a8a3302369daec383334672404ee733ab239',
    name: 'Livepeer',
    symbol: 'LPT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7137/thumb/logo-circle-green.png?1619593365'
  },
  {
    chainId: 1,
    address: '0xcdb7ecfd3403eef3882c65b761ef9b5054890a47',
    name: 'Hurify',
    symbol: 'HUR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2705/thumb/Hurify.jpg?1547036915'
  },
  {
    chainId: 1,
    address: '0x1864ce27e9f7517047933caae530674e8c70b8a7',
    name: 'Pibble',
    symbol: 'PIB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2972/thumb/pibble.jpg?1551683103'
  },
  {
    chainId: 1,
    address: '0x55648de19836338549130b1af587f16bea46f66b',
    name: 'Pebbles',
    symbol: 'PBL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1224/thumb/publica.png?1548607428'
  },
  {
    chainId: 1,
    address: '0xf6ec87dfe1ed3a7256cc0c38e3c8139103e9af3b',
    name: 'Gene',
    symbol: 'GENE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14145/thumb/logo.a60a0c80_%281%29.png?1614653629'
  },
  {
    chainId: 1,
    address: '0x47eb79217f42f92dbd741add1b1a6783a2c873cf',
    name: 'Bast',
    symbol: 'BAST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12289/thumb/bast_logo.png?1598865533'
  },
  {
    chainId: 1,
    address: '0xee9801669c6138e84bd50deb500827b776777d28',
    name: 'O3 Swap',
    symbol: 'O3',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15460/thumb/o3.png?1620904316'
  },
  {
    chainId: 1,
    address: '0xd4ca5c2aff1eefb0bea9e9eab16f88db2990c183',
    name: 'XRP Classic',
    symbol: 'XRPC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/7259/thumb/xrpc.png?1572011410'
  },
  {
    chainId: 1,
    address: '0x2bf91c18cd4ae9c2f2858ef9fe518180f7b5096d',
    name: 'KIWI Token',
    symbol: 'KIWI',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10799/thumb/kiwi_256.png?1583736957'
  },
  {
    chainId: 1,
    address: '0x15d4c048f83bd7e37d49ea4c83a07267ec4203da',
    name: 'Gala',
    symbol: 'GALA',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12493/thumb/GALA-COINGECKO.png?1600233435'
  },
  {
    chainId: 1,
    address: '0x14dfa5cfaafe89d81d7bf3df4e11eaeda0416618',
    name: 'Cops Finance',
    symbol: 'COPS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14984/thumb/inQIYqOm_400x400.jpg?1619250680'
  },
  {
    chainId: 1,
    address: '0x67b66c99d3eb37fa76aa3ed1ff33e8e39f0b9c7a',
    name: 'Interest Bearing ET',
    symbol: 'IBETH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13131/thumb/7675.png?1605535879'
  },
  {
    chainId: 1,
    address: '0xc12d099be31567add4e4e4d0d45691c3f58f5663',
    name: 'Auctus',
    symbol: 'AUC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2165/thumb/Auc_Discord_Avatar1.png?1618983355'
  },
  {
    chainId: 1,
    address: '0x5f435cd858f71e42a3db4282231d1ed25ba07aed',
    name: 'Star Shib',
    symbol: 'STARSB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15419/thumb/100-1.png?1620775159'
  },
  {
    chainId: 1,
    address: '0x8515cd0f00ad81996d24b9a9c35121a3b759d6cd',
    name: 'BlockBurn',
    symbol: 'BURN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8851/thumb/blockburn.JPG?1582774870'
  },
  {
    chainId: 1,
    address: '0x96c645d3d3706f793ef52c19bbace441900ed47d',
    name: 'Mt Pelerin Shares',
    symbol: 'MPS',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/11471/thumb/MPS.png?1590319120'
  },
  {
    chainId: 1,
    address: '0x27c1ba4f85b8dc1c150157816623a6ce80b7f187',
    name: '3X Long XRP Token',
    symbol: 'XRPBULL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10211/thumb/683JEXMN_400x400_%281%29.png?1576535641'
  },
  {
    chainId: 1,
    address: '0x298d492e8c1d909d3f63bc4a36c66c64acb3d695',
    name: 'PolkaBridge',
    symbol: 'PBR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13744/thumb/symbol-whitebg200x200.png?1611377553'
  },
  {
    chainId: 1,
    address: '0x047686fb287e7263a23873dea66b4501015a2226',
    name: 'Blockchain Cuties U',
    symbol: 'CUTE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8328/thumb/bnLvIEl1_400x400.jpg?1557533240'
  },
  {
    chainId: 1,
    address: '0x704de5696df237c5b9ba0de9ba7e0c63da8ea0df',
    name: 'xAAVEb',
    symbol: 'XAAVEB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13237/thumb/xaave.png?1606630133'
  },
  {
    chainId: 1,
    address: '0x2c31b10ca416b82cec4c5e93c615ca851213d48d',
    name: 'Force DAO',
    symbol: 'FORCE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15055/thumb/Force200TransPNG.png?1619618982'
  },
  {
    chainId: 1,
    address: '0x6781a0f84c7e9e846dcb84a9a5bd49333067b104',
    name: 'Zap',
    symbol: 'ZAP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2180/thumb/zap.png?1547036476'
  },
  {
    chainId: 1,
    address: '0x4ba6ddd7b89ed838fed25d208d4f644106e34279',
    name: 'Vether',
    symbol: 'VETH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11375/thumb/vether-flat-ico-200-200.png?1618545881'
  },
  {
    chainId: 1,
    address: '0x2aa4a3e8bb72be68a31c9c3c98ca7bec723c6222',
    name: 'Bispex',
    symbol: 'BPX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10004/thumb/bispex.png?1574667756'
  },
  {
    chainId: 1,
    address: '0x85eee30c52b0b379b046fb0f85f4f3dc3009afec',
    name: 'Keep Network',
    symbol: 'KEEP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3373/thumb/IuNzUb5b_400x400.jpg?1589526336'
  },
  {
    chainId: 1,
    address: '0x89020f0d5c5af4f3407eb5fe185416c457b0e93e',
    name: 'Edenchain',
    symbol: 'EDN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3292/thumb/Eden.png?1574126935'
  },
  {
    chainId: 1,
    address: '0xb705268213d593b8fd88d3fdeff93aff5cbdcfae',
    name: 'IDEX',
    symbol: 'IDEX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2565/thumb/49046004.png?1557813562'
  },
  {
    chainId: 1,
    address: '0xffe02ee4c69edf1b340fcad64fbd6b37a7b9e265',
    name: 'NANJCOIN',
    symbol: 'NANJ',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/3424/thumb/FDGC.png?1547038112'
  },
  {
    chainId: 1,
    address: '0xef9cd7882c067686691b6ff49e650b43afbbcc6b',
    name: 'FinNexus',
    symbol: 'FNX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11488/thumb/finnexus_gecko.png?1591260971'
  },
  {
    chainId: 1,
    address: '0xf8ad7dfe656188a23e89da09506adf7ad9290d5d',
    name: 'Blocery',
    symbol: 'BLY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12095/thumb/Blocery_logo_200x200_.png?1597046107'
  },
  {
    chainId: 1,
    address: '0x995de3d961b40ec6cdee0009059d48768ccbdd48',
    name: 'Union Fair Coin',
    symbol: 'UFC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11750/thumb/maiOmI3b_400x400.png?1593417703'
  },
  {
    chainId: 1,
    address: '0x152687bc4a7fcc89049cf119f9ac3e5acf2ee7ef',
    name: 'DeltaHub Community',
    symbol: 'DHC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12656/thumb/DHC_Transparent_200x200.png?1601440150'
  },
  {
    chainId: 1,
    address: '0x49e90537d5ef6778fd000d1f05be20134f9f6dc6',
    name: 'ODDO coin',
    symbol: 'ODC',
    decimals: 8,
    logoURI:
      'https://assets.coingecko.com/coins/images/8596/thumb/5-C8-F6331-50-EF-4-CC2-94-EB-7-C4-FB160-FC7-B.jpg?1559547160'
  },
  {
    chainId: 1,
    address: '0x88d59ba796fdf639ded3b5e720988d59fdb71eb8',
    name: 'Payship',
    symbol: 'PSHP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12789/thumb/pshp_logo.png?1602566222'
  },
  {
    chainId: 1,
    address: '0xa3e059c0b01f07f211c85bf7b4f1d907afb011df',
    name: 'EthereumMax',
    symbol: 'EMAX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15540/thumb/emax.PNG?1621156255'
  },
  {
    chainId: 1,
    address: '0xf7b098298f7c69fc14610bf71d5e02c60792894c',
    name: 'Guppy',
    symbol: 'GUP',
    decimals: 3,
    logoURI: 'https://assets.coingecko.com/coins/images/683/thumb/matchpool.png?1547034437'
  },
  {
    chainId: 1,
    address: '0x9b20dabcec77f6289113e61893f7beefaeb1990a',
    name: 'FairGame',
    symbol: 'FAIR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2456/thumb/fairgame.png?1547036592'
  },
  {
    chainId: 1,
    address: '0xc19216eea17b2f4dd677f1024cda59c7d142f189',
    name: 'ETH Long Only Alpha',
    symbol: 'ELOAP',
    decimals: 18
  },
  {
    chainId: 1,
    address: '0x4d807509aece24c0fa5a102b6a3b059ec6e14392',
    name: 'Menlo One',
    symbol: 'ONE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3947/thumb/menlo-one.png?1547038971'
  },
  {
    chainId: 1,
    address: '0x8e87f1811de0025d2335174dbc7338a43df6d7cc',
    name: 'Virtual Goods Token',
    symbol: 'VGO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8889/thumb/1QdEK96k_400x400.png?1562574531'
  },
  {
    chainId: 1,
    address: '0x1014613e2b3cbc4d575054d4982e580d9b99d7b1',
    name: 'BitCapitalVendor',
    symbol: 'BCV',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/5326/thumb/hqQw2WGE_400x400.jpg?1547040893'
  },
  {
    chainId: 1,
    address: '0x05ec93c0365baaeabf7aeffb0972ea7ecdd39cf1',
    name: 'Aave BAT',
    symbol: 'ABAT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14246/thumb/aBAT.1fe436f7_-_Copy.png?1615528663'
  },
  {
    chainId: 1,
    address: '0xd1632efa392925089785b43410c529f8959a8d9a',
    name: 'Real Land',
    symbol: 'RLD',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/1882/thumb/rld.png?1606535580'
  },
  {
    chainId: 1,
    address: '0x05462671c05adc39a6521fa60d5e9443e9e9d2b9',
    name: 'OLD USDf',
    symbol: 'USDF',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14492/thumb/usdf.png?1616487790'
  },
  {
    chainId: 1,
    address: '0x3c03b4ec9477809072ff9cc9292c9b25d4a8e6c6',
    name: 'Polkacover',
    symbol: 'CVR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13720/thumb/Polkacover_logo.png?1611147519'
  },
  {
    chainId: 1,
    address: '0xa29a8e360c0cba25a3719763fde43a1ee570271d',
    name: 'OILage',
    symbol: 'OIL',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11232/thumb/download_%2812%29.png?1589767119'
  },
  {
    chainId: 1,
    address: '0x1b40183efb4dd766f11bda7a7c3ad8982e998421',
    name: 'Vesper Finance',
    symbol: 'VSP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13527/thumb/vesper_logo.jpg?1609399927'
  },
  {
    chainId: 1,
    address: '0xb9e7f8568e08d5659f5d29c4997173d84cdf2607',
    name: 'Swarm City',
    symbol: 'SWT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/621/thumb/swarm-city.png?1548611426'
  },
  {
    chainId: 1,
    address: '0x8a63be90f095f6777be3ed25d9fc7cd2a63ddb30',
    name: 'LINK ETH Long Only ',
    symbol: 'LELOAP',
    decimals: 18
  },
  {
    chainId: 1,
    address: '0x4691937a7508860f876c9c0a2a617e7d9e945d4b',
    name: 'Wootrade Network',
    symbol: 'WOO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12921/thumb/w2UiemF__400x400.jpg?1603670367'
  },
  {
    chainId: 1,
    address: '0xbe3c393fb670f0a29c3f3e660ffb113200e36676',
    name: 'Digital Antares Dol',
    symbol: 'DANT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13869/thumb/dant.png?1612435811'
  },
  {
    chainId: 1,
    address: '0x8ab7404063ec4dbcfd4598215992dc3f8ec853d7',
    name: 'Akropolis',
    symbol: 'AKRO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3328/thumb/Akropolis.png?1547037929'
  },
  {
    chainId: 1,
    address: '0x11a2ab94ade17e96197c78f9d5f057332a19a0b9',
    name: 'Orbicular',
    symbol: 'ORBI',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/12201/thumb/orbicular_logo.png?1598005710'
  },
  {
    chainId: 1,
    address: '0x0e5f00da8aaef196a719d045db89b5da8f371b32',
    name: 'Connectome',
    symbol: 'CNTM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8528/thumb/200_200_CNTM-LOGO-07.png?1600751947'
  },
  {
    chainId: 1,
    address: '0xc1fa06e8596c3fa98ccd2113c38b6b60b6efa00d',
    name: 'Erotica',
    symbol: 'EROTICA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15510/thumb/PF1HT6l.png?1621101445'
  },
  {
    chainId: 1,
    address: '0xc3e2de0b661cf58f66bde8e896905399ded58af5',
    name: 'Matrexcoin',
    symbol: 'MAC',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/6579/thumb/l5b5MJXn_400x400.jpg?1557131764'
  },
  {
    chainId: 1,
    address: '0xb62132e35a6c13ee1ee0f84dc5d40bad8d815206',
    name: 'NEXO',
    symbol: 'NEXO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3695/thumb/nexo.png?1548086057'
  },
  {
    chainId: 1,
    address: '0xe66747a101bff2dba3697199dcce5b743b454759',
    name: 'GateToken',
    symbol: 'GT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8183/thumb/gt.png?1556085624'
  },
  {
    chainId: 1,
    address: '0xbed4ab0019ff361d83ddeb74883dac8a70f5ea1e',
    name: 'MerchDAO',
    symbol: 'MRCH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14540/thumb/logo_256x256.png?1616891356'
  },
  {
    chainId: 1,
    address: '0x7cf74238d48ce383db00a1efff8da99303552cb0',
    name: 'i9 Coin',
    symbol: 'I9C',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12399/thumb/Vvm9V6YM_400x400.png?1599556271'
  },
  {
    chainId: 1,
    address: '0x21ca39943e91d704678f5d00b6616650f066fd63',
    name: 'Mirrored Tesla',
    symbol: 'MTSLA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13644/thumb/mirror_logo_transparent.png?1611565294'
  },
  {
    chainId: 1,
    address: '0xae9cbe6ebf72a51c9fcea3830485614486318fd4',
    name: 'Newtonium',
    symbol: 'NEWTON',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12273/thumb/newton.jpg?1598689904'
  },
  {
    chainId: 1,
    address: '0x40c836982788dca47d11024b1fa3e01fd4661766',
    name: 'BTCNEXT Coin',
    symbol: 'BNX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8779/thumb/bnx_big.png?1561111777'
  },
  {
    chainId: 1,
    address: '0xc626c9e2247c8a0c863ad6daa97ed939e12786de',
    name: 'Digital Wallet',
    symbol: 'DWC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9752/thumb/2P9yXbw7_400x400.jpg?1571385231'
  },
  {
    chainId: 1,
    address: '0x00ad22ab1006fc282674887aff1114e5ad14077d',
    name: 'PRESTO',
    symbol: 'PRSTX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10503/thumb/prst.PNG?1580250791'
  },
  {
    chainId: 1,
    address: '0x3c81d482172cc273c3b91dd9d8eb212023d00521',
    name: 'Prophecy',
    symbol: 'PRY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13658/thumb/prophecy_256x256.png?1610592382'
  },
  {
    chainId: 1,
    address: '0x253444bd9ecf11e5516d6d00974e91c9f0857ccb',
    name: 'ETH BTC Long Only A',
    symbol: 'EBLOAP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11004/thumb/sw_ethbtc_set.png?1587089906'
  },
  {
    chainId: 1,
    address: '0x9d409a0a012cfba9b15f6d4b36ac57a46966ab9a',
    name: 'Yearn Compounding v',
    symbol: 'YVBOOST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15152/thumb/yvBOOST.png?1619978216'
  },
  {
    chainId: 1,
    address: '0xf3db5fa2c66b7af3eb0c0b782510816cbe4813b8',
    name: 'Everex',
    symbol: 'EVX',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/997/thumb/everex.png?1548125695'
  },
  {
    chainId: 1,
    address: '0x744d70fdbe2ba4cf95131626614a1763df805b9e',
    name: 'Status',
    symbol: 'SNT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/779/thumb/status.png?1548610778'
  },
  {
    chainId: 1,
    address: '0x6c28aef8977c9b773996d0e8376d2ee379446f2f',
    name: 'Quickswap',
    symbol: 'QUICK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13970/thumb/1_pOU6pBMEmiL-ZJVb0CYRjQ.png?1613386659'
  },
  {
    chainId: 1,
    address: '0x68c85b9f78f30a0df5ac5723e4e700037f185415',
    name: 'AiWork',
    symbol: 'AWO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15373/thumb/aiwork.PNG?1620691299'
  },
  {
    chainId: 1,
    address: '0x6b175474e89094c44da98b954eedeac495271d0f',
    name: 'Dai',
    symbol: 'DAI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9956/thumb/dai-multi-collateral-mcd.png?1574218774'
  },
  {
    chainId: 1,
    address: '0xd85a6ae55a7f33b0ee113c234d2ee308edeaf7fd',
    name: 'Cobak Token',
    symbol: 'CBK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13459/thumb/cbk-128-128.png?1608760936'
  },
  {
    chainId: 1,
    address: '0x5dbcf33d8c2e976c6b560249878e6f1491bca25c',
    name: 'yUSD',
    symbol: 'YVAULT-LP-YCURVE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12210/thumb/yUSD.png?1600166557'
  },
  {
    chainId: 1,
    address: '0xf8d1254fc324d2e75a5a37f5bd4ca34a20ef460d',
    name: 'Advertisingcoin',
    symbol: 'ADVC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/8663/thumb/mB6sbPrt_400x400.png?1560158327'
  },
  {
    chainId: 1,
    address: '0x8207c1ffc5b6804f6024322ccf34f29c3541ae26',
    name: 'Origin Protocol',
    symbol: 'OGN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3296/thumb/op.jpg?1547037878'
  },
  {
    chainId: 1,
    address: '0xcd254568ebf88f088e40f456db9e17731243cb25',
    name: 'YFOS finance',
    symbol: 'YFOS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12707/thumb/wHQeaUF.jpg?1601888512'
  },
  {
    chainId: 1,
    address: '0x1d350417d9787e000cc1b95d70e9536dcd91f373',
    name: 'Mirrored iShares Go',
    symbol: 'MIAU',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13649/thumb/mirror_logo_transparent.png?1611565655'
  },
  {
    chainId: 1,
    address: '0x5580ab97f226c324c671746a1787524aef42e415',
    name: 'JustLiquidity',
    symbol: 'JUL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12378/thumb/JUL256_256.png?1599445678'
  },
  {
    chainId: 1,
    address: '0xc541b907478d5cd334c0cbfcb9603b6dac6e9ee3',
    name: 'CNYQ Stablecoin by ',
    symbol: 'CNYQ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13272/thumb/USDQ_logo.png?1606886052'
  },
  {
    chainId: 1,
    address: '0x4534492034a2cd3eab34c8f357cd139c95b09f52',
    name: 'Marshal Lion Group ',
    symbol: 'MLGC',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/9873/thumb/UI_KObqV_400x400.jpg?1572908129'
  },
  {
    chainId: 1,
    address: '0xd5147bc8e386d91cc5dbe72099dac6c9b99276f5',
    name: 'renFIL',
    symbol: 'RENFIL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13375/thumb/renFIL.png?1608014942'
  },
  {
    chainId: 1,
    address: '0x00aba6fe5557de1a1d565658cbddddf7c710a1eb',
    name: 'EasyFi V2',
    symbol: 'EZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12742/thumb/skiXdJLe_400x400.png?1602630380'
  },
  {
    chainId: 1,
    address: '0x04bc0ab673d88ae9dbc9da2380cb6b79c4bca9ae',
    name: 'yBUSD',
    symbol: 'YBUSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14864/thumb/iearnbusd_32.png?1618813287'
  },
  {
    chainId: 1,
    address: '0xfdb615d6a15f929ddabc6b83a4f1cf9d361b064e',
    name: 'Divert Finance',
    symbol: 'DEVE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13067/thumb/DVMZDhP.png?1606785893'
  },
  {
    chainId: 1,
    address: '0x213c53c96a01a89e6dcc5683cf16473203e17513',
    name: 'Defi Shopping Stake',
    symbol: 'DSS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12602/thumb/DSS.png?1601002204'
  },
  {
    chainId: 1,
    address: '0xfc82bb4ba86045af6f327323a46e80412b91b27d',
    name: 'Prometeus',
    symbol: 'PROM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8825/thumb/G2LY-Dg_.png?1591698270'
  },
  {
    chainId: 1,
    address: '0x1796ae0b0fa4862485106a0de9b654efe301d0b2',
    name: 'Polkamon',
    symbol: 'PMON',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14604/thumb/polkamon.png?1617238350'
  },
  {
    chainId: 1,
    address: '0xa2b0fde6d710e201d0d608e924a484d1a5fed57c',
    name: 'sXRP',
    symbol: 'SXRP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10455/thumb/sXRP.png?1616150916'
  },
  {
    chainId: 1,
    address: '0xa91464abd4625a23ab719e3f0fce84dadd54e546',
    name: 'Inoovi',
    symbol: 'IVI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10372/thumb/Bvp7U25U_400x400.jpg?1578674638'
  },
  {
    chainId: 1,
    address: '0x79c5a1ae586322a07bfb60be36e1b31ce8c84a1e',
    name: 'Freight Trust Netwo',
    symbol: 'EDI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11074/thumb/e6YLf6kD_400x400.jpg?1587970897'
  },
  {
    chainId: 1,
    address: '0xd2946be786f35c3cc402c29b323647abda799071',
    name: 'VikkyToken',
    symbol: 'VIKKY',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/5278/thumb/vikkytoken.png?1548760050'
  },
  {
    chainId: 1,
    address: '0xc28e931814725bbeb9e670676fabbcb694fe7df2',
    name: 'Quadrant Protocol',
    symbol: 'EQUAD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4462/thumb/equad.png?1547039783'
  },
  {
    chainId: 1,
    address: '0x58959e0c71080434f237bd42d07cd84b74cef438',
    name: 'Tesra',
    symbol: 'TSR',
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/9676/thumb/tsr.PNG?1570601008'
  },
  {
    chainId: 1,
    address: '0x922105fad8153f516bcfb829f56dc097a0e1d705',
    name: 'Yee',
    symbol: 'YEE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2576/thumb/YeeCo-logo.png?1598519113'
  },
  {
    chainId: 1,
    address: '0xa8ebb13b9895e52ab7d815ce7f63591402581624',
    name: 'ESCX Token',
    symbol: 'ESCX',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9723/thumb/200X200.png?1571264548'
  },
  {
    chainId: 1,
    address: '0x72adadb447784dd7ab1f472467750fc485e4cb2d',
    name: 'Worldcore',
    symbol: 'WRC',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/1358/thumb/worldcore.png?1548761189'
  },
  {
    chainId: 1,
    address: '0xf7a219fffeade6cd98789da5642b687f743270eb',
    name: 'Rhea Protocol',
    symbol: 'RHEA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10298/thumb/_nJt0yOT_400x400.png?1577656875'
  },
  {
    chainId: 1,
    address: '0x1fe24f25b1cf609b9c4e7e12d802e3640dfa5e43',
    name: 'Chain Guardians',
    symbol: 'CGG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14326/thumb/cgg_logo.png?1615429976'
  },
  {
    chainId: 1,
    address: '0xada86b1b313d1d5267e3fc0bb303f0a2b66d0ea7',
    name: 'Covesting',
    symbol: 'COV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1950/thumb/covesting.png?1547036237'
  },
  {
    chainId: 1,
    address: '0x4dc3643dbc642b72c158e7f3d2ff232df61cb6ce',
    name: 'Ambrosus',
    symbol: 'AMB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1041/thumb/_nSEjidJ_400x400.jpg?1593158410'
  },
  {
    chainId: 1,
    address: '0x88c8cf3a212c0369698d13fe98fcb76620389841',
    name: 'sEOS',
    symbol: 'SEOS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11626/thumb/sEOS.png?1616150113'
  },
  {
    chainId: 1,
    address: '0xe865a04fb0d565107eaf9049ef22c27dd0c4bbef',
    name: 'BestPick Coin',
    symbol: 'BPC',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/9458/thumb/54520830_409845563083406_4756767827547914240_n.png?1567564924'
  },
  {
    chainId: 1,
    address: '0x14ddda446688b73161aa1382f4e4343353af6fc8',
    name: 'FXPay',
    symbol: 'FXP',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/4928/thumb/hiaLKeu4_400x400.jpg?1547040356'
  },
  {
    chainId: 1,
    address: '0x9af4f26941677c706cfecf6d3379ff01bb85d5ab',
    name: 'DomRaider',
    symbol: 'DRT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/1030/thumb/domraider.png?1547223934'
  },
  {
    chainId: 1,
    address: '0xd3fb5cabd07c85395667f83d20b080642bde66c7',
    name: 'Ammbr',
    symbol: 'AMR',
    decimals: 16,
    logoURI: 'https://assets.coingecko.com/coins/images/2100/thumb/ammbr.png?1547036418'
  },
  {
    chainId: 1,
    address: '0x9a005c9a89bd72a4bd27721e7a09a3c11d2b03c4',
    name: 'CoinStarter',
    symbol: 'STC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1759/thumb/ll6FVzPA_400x400.jpg?1564997523'
  },
  {
    chainId: 1,
    address: '0x0e2ec54fc0b509f445631bf4b91ab8168230c752',
    name: 'LINKUSD',
    symbol: 'LINKUSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12386/thumb/ARC-LOGO.png?1599529783'
  },
  {
    chainId: 1,
    address: '0x728f30fa2f100742c7949d1961804fa8e0b1387d',
    name: 'GamerCoin',
    symbol: 'GHX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14714/thumb/ghx_icon.png?1618819440'
  },
  {
    chainId: 1,
    address: '0x3e522d144814bd6149c1f3e0c6cd19d0941372ac',
    name: 'Goldblock',
    symbol: 'GBK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10792/thumb/goldblock.PNG?1585523631'
  },
  {
    chainId: 1,
    address: '0x8762db106b2c2a0bccb3a80d1ed41273552616e8',
    name: 'Reserve Rights Toke',
    symbol: 'RSR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8365/thumb/Reserve_Rights.png?1557737411'
  },
  {
    chainId: 1,
    address: '0x86642d169db9f57a02c65052049cbbbfb3e3b08c',
    name: 'dRAY',
    symbol: 'DRAY',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/12421/thumb/draylogo_200x200.png?1599727930'
  },
  {
    chainId: 1,
    address: '0x69beab403438253f13b6e92db91f7fb849258263',
    name: 'Neurotoken',
    symbol: 'NTK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3028/thumb/neurotoken.png?1547037334'
  },
  {
    chainId: 1,
    address: '0xde4c5a791913838027a2185709e98c5c6027ea63',
    name: 'General Attention C',
    symbol: 'XAC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9203/thumb/-LF1XcKS_400x400.png?1565131564'
  },
  {
    chainId: 1,
    address: '0x8315472bae77f9a2b856a67eb0796480aafcd51c',
    name: 'MMAON',
    symbol: 'MMAON',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14645/thumb/MMAON.png?1618295603'
  },
  {
    chainId: 1,
    address: '0xacfa209fb73bf3dd5bbfb1101b9bc999c49062a5',
    name: 'Blockchain Certifie',
    symbol: 'BCDT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2014/thumb/evidenz-512.png?1594871754'
  },
  {
    chainId: 1,
    address: '0xf18432ef894ef4b2a5726f933718f5a8cf9ff831',
    name: 'BioCrypt',
    symbol: 'BIO',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/6457/thumb/200x200logo.png?1547042660'
  },
  {
    chainId: 1,
    address: '0xca3ea3061d638e02113aa960340c98343b5acd62',
    name: 'Bittwatt',
    symbol: 'BWT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4777/thumb/bittwatt.png?1547274101'
  },
  {
    chainId: 1,
    address: '0x2b04dadd412f7281d3ccabfb8425fd9c9e841588',
    name: 'BittUp',
    symbol: 'BTU',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/14669/thumb/bittup_logo.png?1617673585'
  },
  {
    chainId: 1,
    address: '0x89d3c0249307ae570a316030764d12f53bb191fd',
    name: 'Xiglute Coin',
    symbol: 'XGC',
    decimals: 14,
    logoURI: 'https://assets.coingecko.com/coins/images/15240/thumb/256x256_%282%29.png?1620196016'
  },
  {
    chainId: 1,
    address: '0x687bfc3e73f6af55f0ccca8450114d107e781a0e',
    name: 'QChi',
    symbol: 'QCH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5392/thumb/qchi.png?1548607478'
  },
  {
    chainId: 1,
    address: '0x9992ec3cf6a55b00978cddf2b27bc6882d88d1ec',
    name: 'Polymath Network',
    symbol: 'POLY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2784/thumb/inKkF01.png?1605007034'
  },
  {
    chainId: 1,
    address: '0xeabacd844a196d7faf3ce596edebf9900341b420',
    name: 'sCEX',
    symbol: 'SCEX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11833/thumb/sCEX.png?1616149820'
  },
  {
    chainId: 1,
    address: '0xfff3ada5a2555a2b59bff4f44dfad90146cce8cb',
    name: 'DEXTER',
    symbol: 'DXR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8526/thumb/3705.png?1559103622'
  },
  {
    chainId: 1,
    address: '0xfe3a06a947a036eff9f9e8ec25b385ff4e853c38',
    name: 'Trust Union',
    symbol: 'TUT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7684/thumb/tut.png?1549534394'
  },
  {
    chainId: 1,
    address: '0xbce7bd79558dda90b261506768f265c5543a9f90',
    name: 'TKN Token',
    symbol: 'TKNT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12808/thumb/1_jLY3Tj0kuBTjZGHFCqkTGA.png?1602713481'
  },
  {
    chainId: 1,
    address: '0xbbe761ea1447a20b75aa485b7bcad4837415d7d7',
    name: 'Global Crypto Allia',
    symbol: 'CALL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10276/thumb/gca-favicon.png?1576969530'
  },
  {
    chainId: 1,
    address: '0x3e8ffc8c3cb0db3081df85dec91b63abbbe99f71',
    name: 'Mixsome',
    symbol: 'SOME',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15005/thumb/mixsome.PNG?1619412351'
  },
  {
    chainId: 1,
    address: '0xf2cdf38e24738ba379ffa38d47bc88a941df5627',
    name: 'Ally',
    symbol: 'ALY',
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/9619/thumb/BTf_sTXi_400x400.jpg?1569854715'
  },
  {
    chainId: 1,
    address: '0xe3818504c1b32bf1557b16c238b2e01fd3149c17',
    name: 'Pillar',
    symbol: 'PLR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/809/thumb/PLR_Token.png?1595237935'
  },
  {
    chainId: 1,
    address: '0x59fec83ec709c893aedd1a144cf1828eb04127cd',
    name: 'pxGOLD Synthetic GO',
    symbol: 'PXGOLD-MAY2021',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14018/thumb/PXGOLD.png?1613710831'
  },
  {
    chainId: 1,
    address: '0xd27d76a1ba55ce5c0291ccd04febbe793d22ebf4',
    name: 'BenePit',
    symbol: 'BNP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6740/thumb/TEmrEC3Z_400x400.png?1570484295'
  },
  {
    chainId: 1,
    address: '0x26ea744e5b887e5205727f55dfbe8685e3b21951',
    name: 'yUSDC  BUSD pool ',
    symbol: 'YUSDC',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/14866/thumb/iearnusdc_32.png?1618814197'
  },
  {
    chainId: 1,
    address: '0xea4931bfcf3260da6dbf0550e27f5c214e3c268b',
    name: 'MozoX',
    symbol: 'MOZOX',
    decimals: 2,
    logoURI:
      'https://assets.coingecko.com/coins/images/10798/thumb/Wd7sb8F7VxkzGIkjbIHd1KAaguILwTMnafTK5-aJSWnOylO0D9otRGafiDdS9WBzxKUvqbLeguGq5iTdNdYJp3QJvGI-l_AsXscfjJGiTmn4Bw4G5bi_902OtUeKyQwvGEukbFnNpeoDyCyQ3tAW5-37sIjjj9WcgL3u3gLgdDSne6h0aRG7sBXl1AuYMQb9W_JnnplkyBoA37ZrWMfZc.jpg?1583724469'
  },
  {
    chainId: 1,
    address: '0x19b58d95929586ad750893caad43e77aa6e8ce9e',
    name: 'CelCoin',
    symbol: 'CELC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/6078/thumb/celcoin-logo.png?1547042055'
  },
  {
    chainId: 1,
    address: '0x26e43759551333e57f073bb0772f50329a957b30',
    name: 'DegenVC',
    symbol: 'DGVC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12208/thumb/degen_vc_logo.png?1598186601'
  },
  {
    chainId: 1,
    address: '0x374cb8c27130e2c9e04f44303f3c8351b9de61c1',
    name: 'Bao Finance',
    symbol: 'BAO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13547/thumb/BaoLogo.png?1615342483'
  },
  {
    chainId: 1,
    address: '0x5aaa2182459377b6ca18b10712f9f602140764af',
    name: 'Elevation Token',
    symbol: 'EVT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10446/thumb/coin.jpg?1579732717'
  },
  {
    chainId: 1,
    address: '0x9f9c8ec3534c3ce16f928381372bfbfbfb9f4d24',
    name: 'GraphLinq Protocol',
    symbol: 'GLQ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14474/thumb/graphlinq_logo.jpg?1616397109'
  },
  {
    chainId: 1,
    address: '0x3a880652f47bfaa771908c07dd8673a787daed3a',
    name: 'DerivaDAO',
    symbol: 'DDX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13453/thumb/ddx_logo.png?1608741641'
  },
  {
    chainId: 1,
    address: '0x5c4ac68aac56ebe098d621cd8ce9f43270aaa355',
    name: 'bXIOT',
    symbol: 'BXIOT',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/12466/thumb/bxiot_logo_512x512.png?1601775151'
  },
  {
    chainId: 1,
    address: '0xe75ad3aab14e4b0df8c5da4286608dabb21bd864',
    name: 'Acute Angle Cloud',
    symbol: 'AAC',
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/2577/thumb/acute-angle-cloud.png?1547036708'
  },
  {
    chainId: 1,
    address: '0x555d051538c7a13712f1f590fa6b4c176ca4529f',
    name: 'iOWN Token',
    symbol: 'IOWN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8270/thumb/iOWN-Lion-Face.png?1585695262'
  },
  {
    chainId: 1,
    address: '0xac3211a5025414af2866ff09c23fc18bc97e79b1',
    name: 'Dovu',
    symbol: 'DOV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1072/thumb/Dovu_Earth_72x72_leaf_blue.png?1615259361'
  },
  {
    chainId: 1,
    address: '0xa7be196d92629620154291e4b90c056517489904',
    name: 'NexFin',
    symbol: 'NEX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13596/thumb/200x200_icon.png?1610000937'
  },
  {
    chainId: 1,
    address: '0x4639cd8cd52ec1cf2e496a606ce28d8afb1c792f',
    name: 'CBDAO',
    symbol: 'BREE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12096/thumb/cbdao_logo.jpg?1597059848'
  },
  {
    chainId: 1,
    address: '0x72108a8cc3254813c6be2f1b77be53e185abfdd9',
    name: 'Era Swap Token',
    symbol: 'ES',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6031/thumb/era_swap.PNG?1570920926'
  },
  {
    chainId: 1,
    address: '0xb6f43025b29196af2dddd69b0a58afba079cd600',
    name: 'Intelligent Investm',
    symbol: 'IIC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4307/thumb/intelligent-investment-chain.png?1547974303'
  },
  {
    chainId: 1,
    address: '0x0d2bb9d68dd4451a09ec94c05e20bd395022bd8e',
    name: 'CRYPTOBUCKS',
    symbol: 'CBUCKS',
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/10299/thumb/cbucks.png?1584504612'
  },
  {
    chainId: 1,
    address: '0x8642a849d0dcb7a15a974794668adcfbe4794b56',
    name: 'Prosper',
    symbol: 'PROS',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/13668/thumb/heD6cg22l3sF5VgPh4G1xC6lnKEWXJif-jbaqUpv8CDP6jbWaqn9UjBdkXWNrw1CewaQOxb8zXRdNeNJWWiUDjfsEl_d7E3bPLg4cFoilQF5TGKHfWyJlnpm3UYc9ytvRvOjxOevMuiu8-lusnNoOcwgsJpMkYWHqe322GAxLt0_30kFMVAcjEDUrOlkK6hUYi0m9P433mvNlOm.jpg?1610671732'
  },
  {
    chainId: 1,
    address: '0x87de305311d5788e8da38d19bb427645b09cb4e5',
    name: 'Verox',
    symbol: 'VRX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13669/thumb/IMG-20210115-000024.png?1610675319'
  },
  {
    chainId: 1,
    address: '0x9a794dc1939f1d78fa48613b89b8f9d0a20da00e',
    name: 'Arbidex',
    symbol: 'ABX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5541/thumb/arbidex.png?1547041334'
  },
  {
    chainId: 1,
    address: '0x6628606c321faf52b7230a57b26c01b19aa68e82',
    name: 'BitHash Token',
    symbol: 'BT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13224/thumb/bt.png?1606358296'
  },
  {
    chainId: 1,
    address: '0x878cf148ccbb50426043a9affe54ba408221c7fa',
    name: 'Crypto Kombat',
    symbol: 'KOMBAT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/15147/thumb/Kombat-token-logo-200.jpg?1619859575'
  },
  {
    chainId: 1,
    address: '0xbca3c97837a39099ec3082df97e28ce91be14472',
    name: 'DUST Token',
    symbol: 'DUST',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11162/thumb/DUST.png?1589280496'
  },
  {
    chainId: 1,
    address: '0x22c8ecf727c23422f47093b562ec53c139805301',
    name: 'RealToken16200 Full',
    symbol: 'REALTOKEN-16200-FUL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11914/thumb/RealT_Logo.png?1596076660'
  },
  {
    chainId: 1,
    address: '0x8606a8f28e1e2fd50b9074d65c01548b1f040b32',
    name: 'Cryptrust',
    symbol: 'CTRT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/6110/thumb/cryptrust.png?1547042119'
  },
  {
    chainId: 1,
    address: '0x056fd409e1d7a124bd7017459dfea2f387b6d5cd',
    name: 'Gemini Dollar',
    symbol: 'GUSD',
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/5992/thumb/gemini-dollar-gusd.png?1536745278'
  },
  {
    chainId: 1,
    address: '0xf34960d9d60be18cc1d5afc1a6f012a723a28811',
    name: 'KuCoin Token',
    symbol: 'KCS',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/1047/thumb/sa9z79.png?1610678720'
  },
  {
    chainId: 1,
    address: '0xeda6efe5556e134ef52f2f858aa1e81c84cda84b',
    name: 'Capital finance',
    symbol: 'CAP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13351/thumb/Untitled-design-4-removebg-preview-2.png?1607811346'
  },
  {
    chainId: 1,
    address: '0x19fffd124cd9089e21026d10da97f8cd6b442bff',
    name: 'Zuflo Coin',
    symbol: 'ZFL',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10350/thumb/yFAD7NNd_400x400.jpg?1578783333'
  },
  {
    chainId: 1,
    address: '0x042afd3869a47e2d5d42cc787d5c9e19df32185f',
    name: 'Hotpot Base Token',
    symbol: 'POT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12580/thumb/hotpot.f49fb832.png?1600916677'
  },
  {
    chainId: 1,
    address: '0x4cf89ca06ad997bc732dc876ed2a7f26a9e7f361',
    name: 'Mysterium',
    symbol: 'MYST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/757/thumb/mysterium.png?1547034503'
  },
  {
    chainId: 1,
    address: '0xbdcd5fafe4336a844233ebb0aff845d6b2100899',
    name: 'Uze Tokens',
    symbol: 'UZE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14299/thumb/uze-exchange-nobackground-200x200px.png?1615337168'
  },
  {
    chainId: 1,
    address: '0xce5114d7fa8361f0c088ee26fa3a5446c4a1f50b',
    name: 'Blue Whale',
    symbol: 'BWX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3332/thumb/blue-whale-logo-web-transparent-blue.png?1547037935'
  },
  {
    chainId: 1,
    address: '0x4b4701f3f827e1331fb22ff8e2beac24b17eb055',
    name: 'DistX',
    symbol: 'DISTX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12291/thumb/DISTX-icon.png?1598885812'
  },
  {
    chainId: 1,
    address: '0xa4bbe66f151b22b167127c770016b15ff97dd35c',
    name: 'Umbria Network',
    symbol: 'UMBR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14712/thumb/RX7VJg6.png?1617891954'
  },
  {
    chainId: 1,
    address: '0x081f67afa0ccf8c7b17540767bbe95df2ba8d97f',
    name: 'CoinEx Token',
    symbol: 'CET',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4817/thumb/coinex-token.png?1547040183'
  },
  {
    chainId: 1,
    address: '0x223fb5c14c00cfb70cf56bb63c2eef2d74fe1a78',
    name: '3X Short Dragon Ind',
    symbol: 'DRGNBEAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10256/thumb/683JEXMN_400x400_%281%29.png?1576677145'
  },
  {
    chainId: 1,
    address: '0xeb029507d3e043dd6c87f2917c4e82b902c35618',
    name: 'sCOMP',
    symbol: 'SCOMP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15732/thumb/scomp.JPG?1621671410'
  },
  {
    chainId: 1,
    address: '0x5f02cf3c7ada49dfc4a3645fc85c8ae86808dd9b',
    name: 'COST COIN ',
    symbol: 'AKM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11705/thumb/akm.PNG?1592984686'
  },
  {
    chainId: 1,
    address: '0x8abf3a95862619a55fa00cb3e4eedbe113ff468c',
    name: 'Money Printer Go Br',
    symbol: 'BRRR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10904/thumb/ethbtc_adl_2h.png?1585731253'
  },
  {
    chainId: 1,
    address: '0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c',
    name: 'Bancor Network Toke',
    symbol: 'BNT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/736/thumb/bancor.png?1547034477'
  },
  {
    chainId: 1,
    address: '0x919d3a363776b1ceec9352610c82dfaf80edc32d',
    name: 'GoldFund',
    symbol: 'GFUN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6616/thumb/Goldfun_ICO-logo.png?1547042817'
  },
  {
    chainId: 1,
    address: '0xf6317dd9b04097a9e7b016cd23dcaa7cfe19d9c6',
    name: 'TOPBTC Token',
    symbol: 'TOPB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6252/thumb/2xgmN7FE_400x400.jpg?1547042288'
  },
  {
    chainId: 1,
    address: '0xe28b3b32b6c345a34ff64674606124dd5aceca30',
    name: 'Injective Protocol',
    symbol: 'INJ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12882/thumb/Injective_Icon.png?1613669548'
  },
  {
    chainId: 1,
    address: '0x817e2addceaa4907623666a7800b1553ca21192d',
    name: 'Unbox Art',
    symbol: 'UBA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14915/thumb/unbox.art_logo_B_W_200.png?1618997265'
  },
  {
    chainId: 1,
    address: '0x8bf92cad232f72a7c61eb42e9185e8d0ea470f6b',
    name: 'SMPL Foundation',
    symbol: 'SMPL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12991/thumb/smpl.jpg?1604208000'
  },
  {
    chainId: 1,
    address: '0x970b596ea3cb9864397f799902f0a579cdc3377b',
    name: 'Morph Tracker',
    symbol: 'MORT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13610/thumb/0PAc0nMs_400x400.png?1611734232'
  },
  {
    chainId: 1,
    address: '0x6810e776880c02933d47db1b9fc05908e5386b96',
    name: 'Gnosis',
    symbol: 'GNO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/662/thumb/logo_square_simple_300px.png?1609402668'
  },
  {
    chainId: 1,
    address: '0xc4a86561cb0b7ea1214904f26e6d50fd357c7986',
    name: 'Charg Coin',
    symbol: 'CHG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2159/thumb/charg-coin.png?1547036459'
  },
  {
    chainId: 1,
    address: '0xa7a5c1058194af8f00c187adb7fcc0c95f1c6c2d',
    name: 'SPACE iZ',
    symbol: 'SPIZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12213/thumb/aEXTI0vf_400x400.jpg?1598236662'
  },
  {
    chainId: 1,
    address: '0xef0fda1d4bd73ddc2f93a4e46e2e5adbc2d668f4',
    name: 'ETH 20 Day MA Cross',
    symbol: 'ETHMACOAPY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10601/thumb/eth-20-day-moving-average-ctoken-set.png?1580968662'
  },
  {
    chainId: 1,
    address: '0xdcbcffeee34eb128336fd45ed62fe8f420c8857d',
    name: 'Davies',
    symbol: 'DVS',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/5668/thumb/logodaviesred.png?1559916938'
  },
  {
    chainId: 1,
    address: '0xa8580f3363684d76055bdc6660caefe8709744e1',
    name: 'Folder Protocol',
    symbol: 'FOL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13755/thumb/folderlabs_logo.png?1611560881'
  },
  {
    chainId: 1,
    address: '0x0ce6d5a093d4166237c7a9ff8e0553b0293214a1',
    name: 'Decenturion',
    symbol: 'DCNT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6195/thumb/X5_20dt1_400x400.jpg?1547042224'
  },
  {
    chainId: 1,
    address: '0x6c22b815904165f3599f0a4a092d458966bd8024',
    name: 'Bit Public Talent N',
    symbol: 'BPTN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8412/thumb/7f346702db390a289f5770f008563173.png?1558077057'
  },
  {
    chainId: 1,
    address: '0x3108ccfd96816f9e663baa0e8c5951d229e8c6da',
    name: 'Dark Build v1',
    symbol: 'DARK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12716/thumb/DARK-circle_token_200px.png?1614850894'
  },
  {
    chainId: 1,
    address: '0x58379bf685ea2e251ae63d3ee26269c7c3848a17',
    name: 'Distributed Energy ',
    symbol: 'DEC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9095/thumb/T2g217wc_400x400.jpg?1564131646'
  },
  {
    chainId: 1,
    address: '0x687174f8c49ceb7729d925c3a961507ea4ac7b28',
    name: 'Gatcoin',
    symbol: 'GAT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2566/thumb/gatcoin.png?1547036691'
  },
  {
    chainId: 1,
    address: '0xb26631c6dda06ad89b93c71400d25692de89c068',
    name: 'Minds',
    symbol: 'MINDS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11517/thumb/Minds.png?1590580465'
  },
  {
    chainId: 1,
    address: '0xeb9951021698b42e4399f9cbb6267aa35f82d59d',
    name: 'Lif',
    symbol: 'LIF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1760/thumb/WT_LOGO_%281%29.png?1597282318'
  },
  {
    chainId: 1,
    address: '0xebd9d99a3982d547c5bb4db7e3b1f9f14b67eb83',
    name: 'Everest',
    symbol: 'ID',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5209/thumb/logo.png?1610866164'
  },
  {
    chainId: 1,
    address: '0x40284109c3309a7c3439111bfd93bf5e0fbb706c',
    name: 'MOTIV Protocol',
    symbol: 'MOV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11869/thumb/jvftnBL-_400x400.jpg?1595498693'
  },
  {
    chainId: 1,
    address: '0x74db83feba1574fec860413eb509d1ddfb1b730b',
    name: 'Future Cash Digital',
    symbol: 'FCD',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/13085/thumb/4abcb18740eb7eef72f8bca62a45640f_340x280.png?1604985657'
  },
  {
    chainId: 1,
    address: '0x03c780cd554598592b97b7256ddaad759945b125',
    name: 'Biotron',
    symbol: 'BTRN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4038/thumb/biotron.png?1547039163'
  },
  {
    chainId: 1,
    address: '0x3758e00b100876c854636ef8db61988931bb8025',
    name: 'Uniqly',
    symbol: 'UNIQ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14808/thumb/Hnet-com-image.png?1618538742'
  },
  {
    chainId: 1,
    address: '0xc173af61aa4bab0a5296deff512973b8540d9d1b',
    name: 'CPT',
    symbol: 'CPT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12285/thumb/cpt.PNG?1598760661'
  },
  {
    chainId: 1,
    address: '0x519083fc539f23131c3b7046992584592772d12a',
    name: 'Yearn Finance Value',
    symbol: 'YFIV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13159/thumb/download_%285%29.png?1605770239'
  },
  {
    chainId: 1,
    address: '0xe481f2311c774564d517d015e678c2736a25ddd3',
    name: 'DefHold',
    symbol: 'DEFO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13168/thumb/defhold_logo.png?1605849148'
  },
  {
    chainId: 1,
    address: '0xcee1d3c3a02267e37e6b373060f79d5d7b9e1669',
    name: 'yffi finance',
    symbol: 'YFFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11940/thumb/yffi-finance.jpg?1596289302'
  },
  {
    chainId: 1,
    address: '0x75d669c53142302c8826d16b5689bffad50a7e18',
    name: 'Smilecoin',
    symbol: 'SEC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15344/thumb/smile_coin.PNG?1620617696'
  },
  {
    chainId: 1,
    address: '0xaea46a60368a7bd060eec7df8cba43b7ef41ad85',
    name: 'Fetch ai',
    symbol: 'FET',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5681/thumb/Fetch.jpg?1572098136'
  },
  {
    chainId: 1,
    address: '0x7e03521b9da891ca3f79a8728e2eaeb24886c5f9',
    name: '3X Long Matic Token',
    symbol: 'MATICBULL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10167/thumb/683JEXMN_400x400_%281%29.png?1576505037'
  },
  {
    chainId: 1,
    address: '0xc0f9bd5fa5698b6505f643900ffa515ea5df54a9',
    name: 'Donut',
    symbol: 'DONUT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7538/thumb/Donut.png?1548234345'
  },
  {
    chainId: 1,
    address: '0xa10740ff9ff6852eac84cdcff9184e1d6d27c057',
    name: 'Wrapped Gen 0 Crypt',
    symbol: 'WG0',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12870/thumb/wg0_logo.png?1603176705'
  },
  {
    chainId: 1,
    address: '0x96b52b5bf8d902252d0714a1bd2651a785fd2660',
    name: 'EtherBone',
    symbol: 'ETHBN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11596/thumb/ETHBN.png?1615803426'
  },
  {
    chainId: 1,
    address: '0x3b7f247f21bf3a07088c2d3423f64233d4b069f7',
    name: 'DYNAMITE Token',
    symbol: 'DYNMT',
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/8951/thumb/dynamite_logo.jpg?1598851224'
  },
  {
    chainId: 1,
    address: '0x65d9bc970aa9b2413027fa339f7f179b3f3f2604',
    name: 'QIAN Governance Tok',
    symbol: 'KUN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13177/thumb/kun_logo.png?1605923919'
  },
  {
    chainId: 1,
    address: '0x82866b4a71ba9d930fe338c386b6a45a7133eb36',
    name: 'Qcore Finance',
    symbol: 'QCORE',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/12809/thumb/logo_%281%29_%281%29.png?1602714432'
  },
  {
    chainId: 1,
    address: '0x63e634330a20150dbb61b15648bc73855d6ccf07',
    name: 'Blocklancer',
    symbol: 'LNC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1540/thumb/blocklancer.png?1547351104'
  },
  {
    chainId: 1,
    address: '0x738865301a9b7dd80dc3666dd48cf034ec42bdda',
    name: 'Agoras Token',
    symbol: 'AGRS',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/399/thumb/Agoras.png?1614680577'
  },
  {
    chainId: 1,
    address: '0x8f1135ea4f8946949441716d66e5390c5a990df0',
    name: 'MesChain',
    symbol: 'MES',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9719/thumb/TtPovcoX_400x400.jpg?1571263455'
  },
  {
    chainId: 1,
    address: '0xf1f955016ecbcd7321c7266bccfb96c68ea5e49b',
    name: 'Rally',
    symbol: 'RLY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12843/thumb/image.png?1611212077'
  },
  {
    chainId: 1,
    address: '0x2d8e1dd483008c6843b9cf644bad7fb25bf52b84',
    name: 'Digital Coin',
    symbol: 'DCB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13333/thumb/oUJRUJr.png?1607563567'
  },
  {
    chainId: 1,
    address: '0x667102bd3413bfeaa3dffb48fa8288819e480a88',
    name: 'Tokenize Xchange',
    symbol: 'TKX',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/4984/thumb/Tokenize.png?1561602968'
  },
  {
    chainId: 1,
    address: '0xcec38306558a31cdbb2a9d6285947c5b44a24f3e',
    name: 'Fantasy Sports',
    symbol: 'DFS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6062/thumb/fantasy_sports.png?1567508873'
  },
  {
    chainId: 1,
    address: '0x58002a6b6e659a16de9f02f529b10536e307b0d9',
    name: 'Crypto Holding Fran',
    symbol: 'CHFT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11184/thumb/chft.png?1589523711'
  },
  {
    chainId: 1,
    address: '0x8e5610ab5e39d26828167640ea29823fe1dd5843',
    name: 'KanadeCoin',
    symbol: 'KNDC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/5245/thumb/kanadecoin.png?1547975028'
  },
  {
    chainId: 1,
    address: '0x0e3ef895c59e7db27214ab5bbf56347ce115a3f4',
    name: 'Relayer Network',
    symbol: 'RLR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13523/thumb/rlr_logo.jpg?1609385448'
  },
  {
    chainId: 1,
    address: '0xd51e852630debc24e9e1041a03d80a0107f8ef0c',
    name: 'ORIUM',
    symbol: 'ORM',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/7247/thumb/K0VVDQwT_400x400.jpg?1547043848'
  },
  {
    chainId: 1,
    address: '0x89ab32156e46f46d02ade3fecbe5fc4243b9aaed',
    name: 'pNetwork',
    symbol: 'PNT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11659/thumb/pNetwork.png?1592411134'
  },
  {
    chainId: 1,
    address: '0x002f0b1a71c5730cf2f4da1970a889207bdb6d0d',
    name: 'YD BTC MAR21',
    symbol: 'YD-BTC-MAR21',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13482/thumb/uma_logo.jpg?1608999110'
  },
  {
    chainId: 1,
    address: '0xe26668cc7ce5239304b5af8f54b4bd57d11084d2',
    name: 'DAY',
    symbol: 'DAY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7988/thumb/hZeJKwg6_400x400.jpg?1552893496'
  },
  {
    chainId: 1,
    address: '0x1776e1f26f98b1a5df9cd347953a26dd3cb46671',
    name: 'Numeraire',
    symbol: 'NMR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/752/thumb/numeraire.png?1592538976'
  },
  {
    chainId: 1,
    address: '0x7a3d5d49d64e57dbd6fbb21df7202bd3ee7a2253',
    name: 'Tornado Core',
    symbol: 'TCORE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13412/thumb/tcore.jpeg?1609238107'
  },
  {
    chainId: 1,
    address: '0xbbbbca6a901c926f240b89eacb641d8aec7aeafd',
    name: 'Loopring',
    symbol: 'LRC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/913/thumb/LRC.png?1572852344'
  },
  {
    chainId: 1,
    address: '0x4ecb692b0fedecd7b486b4c99044392784877e8c',
    name: 'Cherry',
    symbol: 'CHERRY',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/12125/thumb/clubcherry-2-scaled-uai-516x516.jpg?1597463169'
  },
  {
    chainId: 1,
    address: '0xc4e15973e6ff2a35cc804c2cf9d2a1b817a8b40f',
    name: 'Interest Bearing Bi',
    symbol: 'IBBTC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15500/thumb/ibbtc.png?1621077589'
  },
  {
    chainId: 1,
    address: '0x8b6cda5cc518c904e8844f445e1a7c7d2db0ff16',
    name: 'SF Capital',
    symbol: 'SFCP',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/6239/thumb/36952079_1621155091328297_7456989147790573568_n.jpg?1547042274'
  },
  {
    chainId: 1,
    address: '0x412d397ddca07d753e3e0c61e367fb1b474b3e7d',
    name: '8X8 Protocol',
    symbol: 'EXE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9466/thumb/8x8_symbol_512x.png?1574320199'
  },
  {
    chainId: 1,
    address: '0x7a9716685f852ee268feb86dffa562d214cc13db',
    name: 'FANBI TOKEN',
    symbol: 'FBT',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/9828/thumb/logo1_%281%29.png?1572472211'
  },
  {
    chainId: 1,
    address: '0x38a2fdc11f526ddd5a607c1f251c065f40fbf2f7',
    name: 'PhoenixDAO',
    symbol: 'PHNX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11523/thumb/Token_Icon.png?1618447147'
  },
  {
    chainId: 1,
    address: '0x50bc2ecc0bfdf5666640048038c1aba7b7525683',
    name: 'carVertical',
    symbol: 'CV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2098/thumb/carvertical.png?1547738903'
  },
  {
    chainId: 1,
    address: '0xed36482c7f8e5850e91ac0cf6bf2130a1aa2df92',
    name: 'Holdtowin',
    symbol: '7ADD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13213/thumb/logo256_%281%29.png?1606276280'
  },
  {
    chainId: 1,
    address: '0x4e15361fd6b4bb609fa63c81a2be19d873717870',
    name: 'Fantom',
    symbol: 'FTM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4001/thumb/Fantom.png?1558015016'
  },
  {
    chainId: 1,
    address: '0x0cec1a9154ff802e7934fc916ed7ca50bde6844e',
    name: 'PoolTogether',
    symbol: 'POOL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14003/thumb/PoolTogether.png?1613585632'
  },
  {
    chainId: 1,
    address: '0x3afa1902b1f8a802abc18e5ad982d1bcd34afe22',
    name: 'GrEarn',
    symbol: 'GST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6193/thumb/GVlSl0Oi_400x400.jpg?1547042222'
  },
  {
    chainId: 1,
    address: '0x3c6da7763caa0e4b684bbc733f04a8ec08af3762',
    name: 'MODEL X coin',
    symbol: 'MODX',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/6603/thumb/LOGO-MODX.png?1547042808'
  },
  {
    chainId: 1,
    address: '0x8b98df4dff429e64e9a56fc6eebe2380c6c3409c',
    name: 'Si14Bet',
    symbol: 'SI14',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/14973/thumb/logo-si14.png?1619213479'
  },
  {
    chainId: 1,
    address: '0x94fc5934cf5970e944a67de806eeb5a4b493c6e6',
    name: '3X Short XRP Token',
    symbol: 'XRPBEAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10210/thumb/683JEXMN_400x400_%281%29.png?1576535444'
  },
  {
    chainId: 1,
    address: '0xfdc3d57eb7839ca68a2fad7a93799c8e8afa61b7',
    name: '1X Short Algorand T',
    symbol: 'ALGOHEDGE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11987/thumb/683JEXMN_400x400.png?1596687107'
  },
  {
    chainId: 1,
    address: '0xbdfa65533074b0b23ebc18c7190be79fa74b30c2',
    name: 'Zloadr',
    symbol: 'ZDR',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/1885/thumb/Zloadr.png?1547036193'
  },
  {
    chainId: 1,
    address: '0x0f4ca92660efad97a9a70cb0fe969c755439772c',
    name: 'Leverj',
    symbol: 'LEV',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/2548/thumb/leverj.png?1547975721'
  },
  {
    chainId: 1,
    address: '0x5732046a883704404f284ce41ffadd5b007fd668',
    name: 'Bluzelle',
    symbol: 'BLZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2848/thumb/bluzelle.png?1547351698'
  },
  {
    chainId: 1,
    address: '0x1460a58096d80a50a2f1f956dda497611fa4f165',
    name: 'WeOwn',
    symbol: 'CHX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1943/thumb/ZlGXwUDv_400x400.jpg?1571388675'
  },
  {
    chainId: 1,
    address: '0xd4c435f5b09f855c3317c8524cb1f586e42795fa',
    name: 'Cindicator',
    symbol: 'CND',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1006/thumb/cindicator.png?1547034913'
  },
  {
    chainId: 1,
    address: '0xf433089366899d83a9f26a773d59ec7ecf30355e',
    name: 'Metal',
    symbol: 'MTL',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/763/thumb/Metal.png?1592195010'
  },
  {
    chainId: 1,
    address: '0x73c9275c3a2dd84b5741fd59aebf102c91eb033f',
    name: 'Bitball Treasure',
    symbol: 'BTRS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7273/thumb/photo_2021-04-06_22.35.48.jpeg?1617719769'
  },
  {
    chainId: 1,
    address: '0x88665a7556e1b3c939d6661248116886845249a8',
    name: 'Linkflow',
    symbol: 'LF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14473/thumb/Linkflow_logo_symbol_200X200.png?1616396086'
  },
  {
    chainId: 1,
    address: '0x931ad0628aa11791c26ff4d41ce23e40c31c5e4e',
    name: 'Pegasus',
    symbol: 'PGS',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9646/thumb/pgs.PNG?1570179224'
  },
  {
    chainId: 1,
    address: '0xf8b358b3397a8ea5464f8cc753645d42e14b79ea',
    name: 'Airbloc',
    symbol: 'ABL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4393/thumb/airbloc-protocol-logo.png?1547039734'
  },
  {
    chainId: 1,
    address: '0x1695936d6a953df699c38ca21c2140d497c08bd9',
    name: 'SynLev',
    symbol: 'SYN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12841/thumb/synlev_logo.jpg?1602945400'
  },
  {
    chainId: 1,
    address: '0xfc98e825a2264d890f9a1e68ed50e1526abccacd',
    name: 'Moss Carbon Credit',
    symbol: 'MCO2',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14414/thumb/ENtxnThA_400x400.jpg?1615948522'
  },
  {
    chainId: 1,
    address: '0xd794dd1cada4cf79c9eebaab8327a1b0507ef7d4',
    name: 'Hyve',
    symbol: 'HYVE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13072/thumb/MKHXNbf.png?1604899269'
  },
  {
    chainId: 1,
    address: '0x481de76d5ab31e28a33b0ea1c1063adcb5b1769a',
    name: 'B26',
    symbol: 'B26',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/14404/thumb/VA6RVny9FP2RRc70QVVPSjXasSRx7SLTLrvyZTQghAX7tAFUJ3f1ekCpb3daNETOClQmZ-8NQxnuX6-Evvjazs2AXbbpcL8toNO2pdoDt8zo09GiZxapdFCUJNxzor43QgsXUtmaecOTpgfoJrwUU4GGb7DSqZncr0Z8VJX9wXaiu8kPKsGlP6ZJZ6d-nGwkaW7elAg_oSpsdzG.jpg?1615896479'
  },
  {
    chainId: 1,
    address: '0x72955ecff76e48f2c8abcce11d54e5734d6f3657',
    name: 'TrustVerse',
    symbol: 'TRV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8793/thumb/oeKdpVxv_400x400.jpg?1561427099'
  },
  {
    chainId: 1,
    address: '0xb15ae165000c8d7b69d2a82e425e110668c73ad5',
    name: 'LinkBased',
    symbol: 'LBD',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13506/thumb/linkbased_logo.jpg?1609224548'
  },
  {
    chainId: 1,
    address: '0x9a24b8e8a6d4563c575a707b1275381119298e60',
    name: 'EVNY Token',
    symbol: 'EVNY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14555/thumb/envy.jpg?1616999935'
  },
  {
    chainId: 1,
    address: '0x12e51e77daaa58aa0e9247db7510ea4b46f9bead',
    name: 'Aave YFI',
    symbol: 'AYFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12482/thumb/aYFI.png?1600188076'
  },
  {
    chainId: 1,
    address: '0x8a2279d4a90b6fe1c4b30fa660cc9f926797baa2',
    name: 'Chromia',
    symbol: 'CHR',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/5000/thumb/Chromia.png?1559038018'
  },
  {
    chainId: 1,
    address: '0x014a543f767b3b06e31a811b0a75483ee8dfd72d',
    name: 'BonezYard',
    symbol: 'BNZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12810/thumb/bnz_icon.png?1602716875'
  },
  {
    chainId: 1,
    address: '0xb67718b98d52318240c52e71a898335da4a28c42',
    name: 'Innovative Bioresea',
    symbol: 'INNBC',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/4043/thumb/INNBC.png?1582800857'
  },
  {
    chainId: 1,
    address: '0x0ebb614204e47c09b6c3feb9aaecad8ee060e23e',
    name: 'Cryptopay',
    symbol: 'CPAY',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/2216/thumb/cryptopay.png?1547036499'
  },
  {
    chainId: 1,
    address: '0x250a3500f48666561386832f1f1f1019b89a2699',
    name: 'SAFE2',
    symbol: 'SAFE2',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12608/thumb/safe2.jpg?1601259102'
  },
  {
    chainId: 1,
    address: '0xbac7a1798350cdf2dbfe0c210c2c9861223f4b31',
    name: 'Moneynet',
    symbol: 'MNC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5634/thumb/YVgOxCAD_400x400.jpg?1547041513'
  },
  {
    chainId: 1,
    address: '0x8a845fc339ceb022a695281554890429a34df120',
    name: 'MangoChain',
    symbol: 'MGP',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/11140/thumb/JJLGKTqL8jZunfdW5-gyQYxZBeCoHoLzoXfpaEwVYARssAaJoObmOxIRqz0f1oMRIbiMWP0Jfq1aM99-_aEI3fqoMvpUy2sD-d5ZGZAR3gxeY26OQrom9MmaY7GmONjoLebGcdA3r0IVlLxKhtcAO2XJrirbnXO6RxsJbImGPLlkE7c8KxH4Y1zjnAMqHi7xgv7PnV5iwpEiIUa.jpg?1588910984'
  },
  {
    chainId: 1,
    address: '0xc8cac7672f4669685817cf332a33eb249f085475',
    name: 'LivenPay',
    symbol: 'LVN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9554/thumb/8PgKjhJn_400x400.jpg?1568837435'
  },
  {
    chainId: 1,
    address: '0x5150956e082c748ca837a5dfa0a7c10ca4697f9c',
    name: 'Zeedex',
    symbol: 'ZDEX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12412/thumb/Untitled-design-4.png?1599647173'
  },
  {
    chainId: 1,
    address: '0x662b67d00a13faf93254714dd601f5ed49ef2f51',
    name: 'Orbit Chain',
    symbol: 'ORC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9782/thumb/-p1Br7oh_400x400.png?1571716593'
  },
  {
    chainId: 1,
    address: '0x940a2db1b7008b6c776d4faaca729d6d4a4aa551',
    name: 'DUSK Network',
    symbol: 'DUSK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5217/thumb/D_ticker_purple_on_circle_%282%29.png?1563781659'
  },
  {
    chainId: 1,
    address: '0xf8e06e4e4a80287fdca5b02dccecaa9d0954840f',
    name: 'Truegame',
    symbol: 'TGAME',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2974/thumb/tgame.png?1518620605'
  },
  {
    chainId: 1,
    address: '0x86ed939b500e121c0c5f493f399084db596dad20',
    name: 'SpaceChain  ERC 20 ',
    symbol: 'SPC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6659/thumb/Spacechain.jpg?1547042861'
  },
  {
    chainId: 1,
    address: '0x7869c4a1a3f6f8684fbcc422a21ad7abe3167834',
    name: 'Pivot Token',
    symbol: 'PVT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8977/thumb/j5ovdTKP_400x400.png?1563405624'
  },
  {
    chainId: 1,
    address: '0x39ad22c916f42af5f67371d6f2fb0dab42321a89',
    name: 'OSINA',
    symbol: 'OSINA',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/12977/thumb/OSINA_Logo.png?1604019955'
  },
  {
    chainId: 1,
    address: '0x3137619705b5fc22a3048989f983905e456b59ab',
    name: 'Everus',
    symbol: 'EVR',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/1027/thumb/everus.png?1547395108'
  },
  {
    chainId: 1,
    address: '0x4846239fdf4d4c1aeb26729fa064b0205aca90e1',
    name: 'True Seigniorage Do',
    symbol: 'TSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13555/thumb/2dc3a1b1-41fd-4d9c-ba1d-8114635efd09.jpg?1609754836'
  },
  {
    chainId: 1,
    address: '0xa150db9b1fa65b44799d4dd949d922c0a33ee606',
    name: 'Digital Reserve Cur',
    symbol: 'DRC',
    decimals: 0,
    logoURI:
      'https://assets.coingecko.com/coins/images/12802/thumb/DRC-Digital-Reserve-Currency-Coingecko.png?1603355646'
  },
  {
    chainId: 1,
    address: '0x9ea20fbfaa44efbc60c6728fcdba17f01b7e04fe',
    name: 'Torex',
    symbol: 'TOR',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11570/thumb/logo_%285%29.png?1591227185'
  },
  {
    chainId: 1,
    address: '0x6b3760facdeb7f798cd13c0af2e4cd9ddc085122',
    name: 'Oviex',
    symbol: 'OVI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15235/thumb/oviex-token-logo.png?1620192449'
  },
  {
    chainId: 1,
    address: '0x2dcd9b1a7ed408ff48bd4918a1f9c0535dc54ead',
    name: 'WeSing Coin',
    symbol: 'WSC',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/7645/thumb/wesingcoin.png?1548923241'
  },
  {
    chainId: 1,
    address: '0xc1d204d77861def49b6e769347a883b15ec397ff',
    name: 'PayperEx',
    symbol: 'PAX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1601/thumb/pax.png?1547035800'
  },
  {
    chainId: 1,
    address: '0x89fb927240750c1b15d4743cd58440fc5f14a11c',
    name: 'Attila',
    symbol: 'ATT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11337/thumb/LOGO_%2874%29.png?1589942265'
  },
  {
    chainId: 1,
    address: '0xf0939011a9bb95c3b791f0cb546377ed2693a574',
    name: '0 exchange',
    symbol: 'ZERO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13706/thumb/0.exchange_%28logo%29.jpg?1617070530'
  },
  {
    chainId: 1,
    address: '0xb97d5cf2864fb0d08b34a484ff48d5492b2324a0',
    name: 'Klondike Finance v1',
    symbol: 'KLON',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13787/thumb/klondike.jpg?1611759069'
  },
  {
    chainId: 1,
    address: '0x02f3a1819851d127bcd6f468253d7a498567eee0',
    name: 'Gasify',
    symbol: 'GSFY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14100/thumb/logo.png?1614305756'
  },
  {
    chainId: 1,
    address: '0x89e3ac6dd69c15e9223be7649025d6f68dab1d6a',
    name: 'Evan',
    symbol: 'EVAN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12078/thumb/8NERB6Pc_400x400.jpg?1597273971'
  },
  {
    chainId: 1,
    address: '0x3989f36540052668c340a53aaeacb46d6cf0dd6c',
    name: 'Valireum',
    symbol: 'VLM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11174/thumb/logo256x256.png?1589447700'
  },
  {
    chainId: 1,
    address: '0x61bc1f530ac6193d73af1e1a6a14cb44b9c3f915',
    name: 'Pajama Finance',
    symbol: 'PJM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12669/thumb/pajama200.png?1601501914'
  },
  {
    chainId: 1,
    address: '0x90f802c7e8fb5d40b0de583e34c065a3bd2020d8',
    name: 'YD ETH MAR21',
    symbol: 'YD-ETH-MAR21',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13481/thumb/uma_logo.jpg?1608998764'
  },
  {
    chainId: 1,
    address: '0x33d0568941c0c64ff7e0fb4fba0b11bd37deed9f',
    name: 'RAMP',
    symbol: 'RAMP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12837/thumb/RAMP-Logo-v2-1000pxsq.png?1617952606'
  },
  {
    chainId: 1,
    address: '0x1d96fd43ee07aa79f8fd003cbdf404fb5ce41ad2',
    name: 'Qawalla Token',
    symbol: 'QWLA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15242/thumb/qawalla-token-logo-200.png?1620203833'
  },
  {
    chainId: 1,
    address: '0x725c263e32c72ddc3a19bea12c5a0479a81ee688',
    name: 'Bridge Mutual',
    symbol: 'BMI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13808/thumb/bmi_logo.png?1612009598'
  },
  {
    chainId: 1,
    address: '0x78a52e12c7b63d05c12f9608307587cf654ec3d0',
    name: 'Crypto Village Acce',
    symbol: 'CVA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10071/thumb/logo_tondo_%281%29.png?1575499784'
  },
  {
    chainId: 1,
    address: '0x36232b1328e49a043434e71c02c0dc2be278e975',
    name: 'Xaviera Tech',
    symbol: 'XTS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8074/thumb/qebuQymn_400x400.jpg?1554191840'
  },
  {
    chainId: 1,
    address: '0xfc05987bd2be489accf0f509e44b0145d68240f7',
    name: 'Essentia',
    symbol: 'ESS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2483/thumb/Essentia-token.jpg?1547036604'
  },
  {
    chainId: 1,
    address: '0x6c103d85c15107dce19f5a75fc746227e610aabd',
    name: 'Universal Euro',
    symbol: 'UPEUR',
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/11172/thumb/1_hxXrtjdwUeSTn67wA8nHQw.png?1589440779'
  },
  {
    chainId: 1,
    address: '0x77b1465b0e01ba085e515324e30fee6555c623ea',
    name: 'Set of Sets Trailbl',
    symbol: 'MQSS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11781/thumb/sets_of_sets_trailblazer_fund.png?1593933100'
  },
  {
    chainId: 1,
    address: '0xfe76be9cec465ed3219a9972c21655d57d21aec6',
    name: 'PalletOneToken',
    symbol: 'PTN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6669/thumb/palletone.png?1548330460'
  },
  {
    chainId: 1,
    address: '0x87f5f9ebe40786d49d35e1b5997b07ccaa8adbff',
    name: 'Rebased',
    symbol: 'REB2',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/12153/thumb/reb.png?1608516711'
  },
  {
    chainId: 1,
    address: '0x2c4e8f2d746113d0696ce89b35f0d8bf88e0aeca',
    name: 'OST',
    symbol: 'OST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1367/thumb/ost.jpg?1547035393'
  },
  {
    chainId: 1,
    address: '0x946551dd05c5abd7cc808927480225ce36d8c475',
    name: 'One',
    symbol: 'ONE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4960/thumb/Screenshot_39.png?1561103318'
  },
  {
    chainId: 1,
    address: '0x0538a9b4f4dcb0cb01a7fa34e17c0ac947c22553',
    name: 'OptionRoom Governan',
    symbol: 'COURT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15467/thumb/court.png?1620915482'
  },
  {
    chainId: 1,
    address: '0xd0660cd418a64a1d44e9214ad8e459324d8157f1',
    name: 'Woofy',
    symbol: 'WOOFY',
    decimals: 12,
    logoURI: 'https://assets.coingecko.com/coins/images/15410/thumb/Woofy_128x128.png?1620768346'
  },
  {
    chainId: 1,
    address: '0x564f45b6bb68aded8b660a0d8a0a948dd6d6e4e8',
    name: 'Aladiex',
    symbol: 'ALA',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12312/thumb/logo_%2894%29.png?1599083659'
  },
  {
    chainId: 1,
    address: '0xb09ad98524780228d2df4f34aa665d9dbb9999e4',
    name: 'Tradcoin',
    symbol: 'TRAD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6924/thumb/tmTPJCkZ_400x400.jpg?1547043296'
  },
  {
    chainId: 1,
    address: '0x7865af71cf0b288b4e7f654f4f7851eb46a2b7f8',
    name: 'Sentivate',
    symbol: 'SNTVT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7383/thumb/2x9veCp.png?1598409975'
  },
  {
    chainId: 1,
    address: '0x5a7e3c07604eb515c16b36cd51906a65f021f609',
    name: 'sNFLX',
    symbol: 'SNFLX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14950/thumb/sNFLX.png?1619129222'
  },
  {
    chainId: 1,
    address: '0x186a33d4dbcd700086a26188dcb74e69be463665',
    name: '7ELEVEN',
    symbol: '7E',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/8324/thumb/icon4.png?1557529360'
  },
  {
    chainId: 1,
    address: '0x13339fd07934cd674269726edf3b5ccee9dd93de',
    name: 'Curio',
    symbol: 'CUR',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/10314/thumb/is8-HSAQ99o3KejDDwfnqnzW_tOHbqsEPHQlYL_UEVDeVfKhbMe871CfCrEo_BYAeC1MtEFUGcd1aZ2YtJopCQKr5tEbz9dyLmBw7nJGuOgWE4fGa4Bsui2bt8yMSZQt6meB2hAbZ1VPUf6J5pgVPslRkH3C7pSsapnpZslVi0eD7U8wb7CucXp6xuI3T0rsBQaBbHtftdoUrz8d0WiYLcwpflI6A1dVOlCXUIk9llfTuTJE.jpg?1577834182'
  },
  {
    chainId: 1,
    address: '0x103c3a209da59d3e7c4a89307e66521e081cfdf0',
    name: 'Genesis Vision',
    symbol: 'GVT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1173/thumb/Genesis-vision.png?1558045005'
  },
  {
    chainId: 1,
    address: '0x24d8c2163d6b13a6b8770b794d00c98cb4e0cbca',
    name: 'O OCEAN MAR22',
    symbol: 'O-OCEAN-MAR22',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14860/thumb/Or0WdShi_400x400.jpg?1618806215'
  },
  {
    chainId: 1,
    address: '0x7c974104df9dd7fb91205ab3d66d15aff1049de8',
    name: 'Wrapped USD',
    symbol: 'WUSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14691/thumb/StableLogo.png?1617808927'
  },
  {
    chainId: 1,
    address: '0xb5a4ac5b04e777230ba3381195eff6a60c3934f2',
    name: 'inSure DeFi',
    symbol: 'SURE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10354/thumb/logo-grey-circle.png?1614910406'
  },
  {
    chainId: 1,
    address: '0x05385abd2a95a8cd9b696f738aed73f1afbc12e0',
    name: 'Fompound',
    symbol: 'FOMP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13325/thumb/logo_%2829%29.png?1607482203'
  },
  {
    chainId: 1,
    address: '0xae8488e75493b89a0e1488bf91542208c416f486',
    name: 'Buzcoin',
    symbol: 'BUZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5219/thumb/buzcoin-logo.png?1547040688'
  },
  {
    chainId: 1,
    address: '0x674c6ad92fd080e4004b2312b45f796a192d27a0',
    name: 'Neutrino USD',
    symbol: 'USDN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10117/thumb/78GWcZu.png?1600845716'
  },
  {
    chainId: 1,
    address: '0x26a79bd709a7ef5e5f747b8d8f83326ea044d8cc',
    name: 'BankSocial',
    symbol: 'BSOCIAL',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/15738/thumb/banksocial_small.png?1621685752'
  },
  {
    chainId: 1,
    address: '0xaaca86b876ca011844b5798eca7a67591a9743c8',
    name: '0x nodes',
    symbol: 'BIOS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15600/thumb/BIOS_01.png?1621737736'
  },
  {
    chainId: 1,
    address: '0x5c743a35e903f6c584514ec617acee0611cf44f3',
    name: 'Experty',
    symbol: 'EXY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1788/thumb/exy.png?1547036088'
  },
  {
    chainId: 1,
    address: '0x2466ba59e474c46639ceb4eb9b7248638443560d',
    name: 'Capitalsharetoken',
    symbol: 'CSTO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9331/thumb/officallogo.png?1566291539'
  },
  {
    chainId: 1,
    address: '0x86696431d6aca9bae5ce6536ecf5d437f2e6dba2',
    name: 'SoftChain',
    symbol: 'SCC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6613/thumb/FgUyfr6K_400x400.jpg?1547042814'
  },
  {
    chainId: 1,
    address: '0x368c5290b13caa10284db58b4ad4f3e9ee8bf4c9',
    name: 'Kineko',
    symbol: 'KKO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15366/thumb/Kineko.jpeg?1620721965'
  },
  {
    chainId: 1,
    address: '0x1beef31946fbbb40b877a72e4ae04a8d1a5cee06',
    name: 'Parachute',
    symbol: 'PAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7590/thumb/Parachute_Logo.png?1560918207'
  },
  {
    chainId: 1,
    address: '0xd36932143f6ebdedd872d5fb0651f4b72fd15a84',
    name: 'Mirrored Apple',
    symbol: 'MAAPL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13514/thumb/mirror_logo_transparent.png?1611564758'
  },
  {
    chainId: 1,
    address: '0x14da230d6726c50f759bc1838717f8ce6373509c',
    name: 'Kambria',
    symbol: 'KAT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4784/thumb/kambria.png?1547040127'
  },
  {
    chainId: 1,
    address: '0x947938339bf61c84669e303bc39c794d65a525d0',
    name: 'FME',
    symbol: 'FME',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12897/thumb/FME_token.png?1603336122'
  },
  {
    chainId: 1,
    address: '0x9f801c1f02af03cc240546dadef8e56cd46ea2e9',
    name: 'Vaiot',
    symbol: 'VAI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13981/thumb/VAIOT_logo.png?1613456546'
  },
  {
    chainId: 1,
    address: '0x1f3f9d3068568f8040775be2e8c03c103c61f3af',
    name: 'Archer DAO Governan',
    symbol: 'ARCH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13188/thumb/archer_logo.png?1606097487'
  },
  {
    chainId: 1,
    address: '0x7866e48c74cbfb8183cd1a929cd9b95a7a5cb4f4',
    name: 'DexKit',
    symbol: 'KIT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13187/thumb/dexkit_logo.png?1606093850'
  },
  {
    chainId: 1,
    address: '0xa5959e9412d27041194c3c3bcbe855face2864f7',
    name: 'UniDexGas',
    symbol: 'UNDG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13740/thumb/undg_logo_200_.png?1611305562'
  },
  {
    chainId: 1,
    address: '0x0ace32f6e87ac1457a5385f8eb0208f37263b415',
    name: 'Habitat',
    symbol: 'HBT',
    decimals: 10,
    logoURI: 'https://assets.coingecko.com/coins/images/14456/thumb/0xHabitatLogo.png?1616469047'
  },
  {
    chainId: 1,
    address: '0xa957045a12d270e2ee0dca9a3340c340e05d4670',
    name: 'AIDUS Token',
    symbol: 'AIDUS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7432/thumb/LKOt-PQ6_400x400.jpg?1571193797'
  },
  {
    chainId: 1,
    address: '0xf05a9382a4c3f29e2784502754293d88b835109c',
    name: 'Imbrex',
    symbol: 'REX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/885/thumb/Imbrex_Token.jpg?1547034710'
  },
  {
    chainId: 1,
    address: '0x2f3e054d233c93c59140c0905227c7c607c70cbb',
    name: 'CoomCoin',
    symbol: 'COOM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12205/thumb/coom.jpg?1598081856'
  },
  {
    chainId: 1,
    address: '0xa9d2927d3a04309e008b6af6e2e282ae2952e7fd',
    name: 'Zipper Network',
    symbol: 'ZIP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4524/thumb/zip_token_logo.png?1547039822'
  },
  {
    chainId: 1,
    address: '0xefd720c94659f2ccb767809347245f917a145ed8',
    name: 'Quoxent',
    symbol: 'QUO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1084/thumb/Quoxent_Logo_Final_-_NO_TEXT.png?1581412910'
  },
  {
    chainId: 1,
    address: '0xb089db4cebbf0618b295d9defc7feb00f56da033',
    name: 'BYZBIT',
    symbol: 'BYT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9997/thumb/byzbit.png?1574653301'
  },
  {
    chainId: 1,
    address: '0xd9ec3ff1f8be459bb9369b4e79e9ebcf7141c093',
    name: 'KardiaChain',
    symbol: 'KAI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7942/thumb/KardiaChain.png?1591631223'
  },
  {
    chainId: 1,
    address: '0x2e65e12b5f0fd1d58738c6f38da7d57f5f183d1c',
    name: 'Tepleton',
    symbol: 'TEP',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9622/thumb/tep.PNG?1569856834'
  },
  {
    chainId: 1,
    address: '0x297e4e5e59ad72b1b0a2fd446929e76117be0e0a',
    name: 'Smart Valor',
    symbol: 'VALOR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7231/thumb/smart_valor.png?1555925772'
  },
  {
    chainId: 1,
    address: '0x1494ca1f11d487c2bbe4543e90080aeba4ba3c2b',
    name: 'DeFiPulse Index',
    symbol: 'DPI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12465/thumb/defi_pulse_index_set.png?1600051053'
  },
  {
    chainId: 1,
    address: '0xc59cb23295e2deeb66bd090acb6b02be8d30a11f',
    name: 'Kublaicoin',
    symbol: 'KUB',
    decimals: 10,
    logoURI: 'https://assets.coingecko.com/coins/images/11342/thumb/logo_%2876%29.png?1589946883'
  },
  {
    chainId: 1,
    address: '0xf83d7ff2e4b43ebad2fa534e621e31076f4d254c',
    name: 'Hyper Credit Networ',
    symbol: 'HPAY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13536/thumb/256-256.png?1609541754'
  },
  {
    chainId: 1,
    address: '0x13b1e6b036ac0a1495f876313f92a0706cc10871',
    name: 'ShipItPro',
    symbol: 'SHPP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14687/thumb/photo_2020-12-28_16-37-33.jpg?1617785737'
  },
  {
    chainId: 1,
    address: '0x177d39ac676ed1c67a2b268ad7f1e58826e5b0af',
    name: 'Blox',
    symbol: 'CDT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1231/thumb/Blox_Staking_Logo_2.png?1609117544'
  },
  {
    chainId: 1,
    address: '0x19f4a2f8e21915376f1429c26a3a9b9b1db5ff5a',
    name: 'Chad Link Set',
    symbol: 'CHADLINK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11556/thumb/chad-link-set.png?1591072904'
  },
  {
    chainId: 1,
    address: '0xc944e90c64b2c07662a292be6244bdf05cda44a7',
    name: 'The Graph',
    symbol: 'GRT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13397/thumb/Graph_Token.png?1608145566'
  },
  {
    chainId: 1,
    address: '0xa5a2af22eac6f050227d844b108c2b2a011fd329',
    name: 'Tunnel Protocol',
    symbol: 'TNI',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13598/thumb/tni_logo.png?1610006256'
  },
  {
    chainId: 1,
    address: '0x22222c03318440305ac3e8a7820563d6a9fd777f',
    name: 'Clover',
    symbol: 'CLV',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/12888/thumb/clover_logo.png?1603274615'
  },
  {
    chainId: 1,
    address: '0xcba3eae7f55d0f423af43cc85e67ab0fbf87b61c',
    name: 'Shyft Network',
    symbol: 'SHFT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14534/thumb/TOKEN-Gradient.png?1616768352'
  },
  {
    chainId: 1,
    address: '0x8d717ab5eac1016b64c2a7fd04720fd2d27d1b86',
    name: 'BitcoinVend',
    symbol: 'BCVT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14162/thumb/BCVT-200x200_transparent.png?1614724192'
  },
  {
    chainId: 1,
    address: '0xae17f4f5ca32f77ea8e3786db7c0b2fe877ac176',
    name: 'Basis Coin Cash',
    symbol: 'BCC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13535/thumb/yiu47vtN_400x400.jpg?1609541472'
  },
  {
    chainId: 1,
    address: '0xf947b0824c3995787efc899017a36bc9f281265e',
    name: 'Lotoblock',
    symbol: 'LOTO',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/6611/thumb/xkKemBDV_400x400.jpg?1547042813'
  },
  {
    chainId: 1,
    address: '0x7cc61e3ae6360e923e9296c802382ec7c9dd3652',
    name: 'SUN',
    symbol: 'SUN',
    decimals: 8,
    logoURI:
      'https://assets.coingecko.com/coins/images/7420/thumb/a55e64a9e3eb68cfcd708f956e37ec5c_320x260.png?1547200256'
  },
  {
    chainId: 1,
    address: '0x34612903db071e888a4dadcaa416d3ee263a87b9',
    name: 'Items',
    symbol: 'ARTE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11720/thumb/Arte.png?1607332372'
  },
  {
    chainId: 1,
    address: '0x388fd8a5145d6ef85aae14d494f93df9d1c7c00c',
    name: 'BitcoinRegular',
    symbol: 'BTRL',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/7321/thumb/6N4N8s9l_400x400.jpg?1547043978'
  },
  {
    chainId: 1,
    address: '0x301c755ba0fca00b1923768fffb3df7f4e63af31',
    name: 'Global Digital Cont',
    symbol: 'GDC',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/10122/thumb/Vp4L1x4Hu4HEuEPUl0gSocDaVifkXlCqSd8A72ml_Q0uwEc9uBWBTwjS43_NP8eiiZVveJDIQXguWUXYk3MLZiFOER-8CGO120I0NGU_L8aTJ8c50a-O_AbkY-4nUkPYhUTNgf3rkcqkXnKecixQwLfkJG2XTCLwNvo_ox05x0_hU1Z__FOT4O7AeU-PC6skc85NCVS68cnZjxcUBOY9K.jpg?1576124937'
  },
  {
    chainId: 1,
    address: '0xc75f15ada581219c95485c578e124df3985e4ce0',
    name: 'zzz finance',
    symbol: 'ZZZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12123/thumb/zzz_finance_logo.jpg?1597306287'
  },
  {
    chainId: 1,
    address: '0x614857c755739354d68ae0abd53849cf45d6a41d',
    name: 'ETH 26 Day EMA Cros',
    symbol: 'ETH26EMACO',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/10613/thumb/eth-26-day-exponential-moving-average-set.png?1580971494'
  },
  {
    chainId: 1,
    address: '0x2af5d2ad76741191d15dfe7bf6ac92d4bd912ca3',
    name: 'LEO Token',
    symbol: 'LEO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8418/thumb/leo-token.png?1558326215'
  },
  {
    chainId: 1,
    address: '0x8832e23b1135f78ad08a044c2550489eea1e1098',
    name: '2 2 4 4 8',
    symbol: '2248',
    decimals: 8,
    logoURI:
      'https://assets.coingecko.com/coins/images/10819/thumb/HtQcwU10942usBfy4OozDQBy4KKgKg38YUnoN5hzQhKEg-09JGinUkFu3CnpnFB03wptG3HKBqrl-_1OsFyGaWRBrImXomWvnAChKrrkjcBM2Pb-OISlmSfRCIwpIAm-dVhN55ruCw9pMZQeZR5ZDMHZ5RozDFYum0CvnIYqurITcOD0sBmLNp9e0NbbqVHwEyf81wqWGhUOdB8b2h1Z-.jpg?1584078091'
  },
  {
    chainId: 1,
    address: '0xdb80734b094a3f964dedfd10e8946753ae0ac04c',
    name: 'Keep Calm and Hodl',
    symbol: 'KCH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13355/thumb/200_%282%29.png?1607812469'
  },
  {
    chainId: 1,
    address: '0x456ae45c0ce901e2e7c99c0718031cec0a7a59ff',
    name: 'Vision Network',
    symbol: 'VSN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9029/thumb/photo_2020-08-14_20-34-55.jpg?1606133699'
  },
  {
    chainId: 1,
    address: '0x6dea81c8171d0ba574754ef6f8b412f2ed88c54d',
    name: 'Liquity',
    symbol: 'LQTY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14665/thumb/200-lqty-icon.png?1617631180'
  },
  {
    chainId: 1,
    address: '0xea097a2b1db00627b2fa17460ad260c016016977',
    name: 'Upfiring',
    symbol: 'UFR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1170/thumb/upfiring.png?1548759693'
  },
  {
    chainId: 1,
    address: '0xed79e6dd91324f6af138f01967bd24233d642a24',
    name: 'King Maker Coin',
    symbol: 'KMC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11515/thumb/logo_%2878%29.png?1590535611'
  },
  {
    chainId: 1,
    address: '0x468ab3b1f63a1c14b361bc367c3cc92277588da1',
    name: 'Yeld Finance',
    symbol: 'YELD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12389/thumb/yeld_logo.png?1599537337'
  },
  {
    chainId: 1,
    address: '0xcbcc0f036ed4788f63fc0fee32873d6a7487b908',
    name: 'Humaniq',
    symbol: 'HMQ',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/647/thumb/humaniq.jpg?1547034375'
  },
  {
    chainId: 1,
    address: '0x70460c3bb9abcc0aa51f922c00d37816d6ede4d7',
    name: 'BooBanker Research ',
    symbol: 'BBRA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13280/thumb/bbra_logo.png?1606906062'
  },
  {
    chainId: 1,
    address: '0x9cf7e61853ea30a41b02169391b393b901eac457',
    name: 'sAMZN',
    symbol: 'SAMZN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14948/thumb/sAMZN.png?1619129008'
  },
  {
    chainId: 1,
    address: '0x3ff426ca07a9f589a80f18cccc759e84cf06f0eb',
    name: 'Employment Coin',
    symbol: 'EC2',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13773/thumb/logoNav.png?1611699039'
  },
  {
    chainId: 1,
    address: '0x06404399e748cd83f25ab163711f9f4d61cfd0e6',
    name: 'FunKeyPay',
    symbol: 'FNK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9380/thumb/k1uthBN9_400x400.jpg?1566774921'
  },
  {
    chainId: 1,
    address: '0x7b123f53421b1bf8533339bfbdc7c98aa94163db',
    name: 'dfohub',
    symbol: 'BUIDL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11131/thumb/buidllogo.png?1599577041'
  },
  {
    chainId: 1,
    address: '0xb4c9abc8a74bd2e0e0b7ac5ece30792e65d86c59',
    name: 'LiteGold',
    symbol: 'LTG',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/6792/thumb/O4v8ll_f_400x400.jpg?1547043077'
  },
  {
    chainId: 1,
    address: '0xe4815ae53b124e7263f08dcdbbb757d41ed658c6',
    name: 'ZKSwap',
    symbol: 'ZKS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13585/thumb/hfmvDpha_400x400.jpg?1609922062'
  },
  {
    chainId: 1,
    address: '0x408e41876cccdc0f92210600ef50372656052a38',
    name: 'REN',
    symbol: 'REN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3139/thumb/REN.png?1589985807'
  },
  {
    chainId: 1,
    address: '0xea54c81fe0f72de8e86b6dc78a9271aa3925e3b5',
    name: 'Bgogo Token',
    symbol: 'BGG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5066/thumb/BGG.png?1547040472'
  },
  {
    chainId: 1,
    address: '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f',
    name: 'Synthetix Network T',
    symbol: 'SNX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3406/thumb/SNX.png?1598631139'
  },
  {
    chainId: 1,
    address: '0x7458fd786b2fe8cd801c0381f88b61c5071a006f',
    name: 'LOA Protocol',
    symbol: 'LOA',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/11164/thumb/1a67ac81c1803ee172b7ce8805b5da3d-full.jpg?1589411408'
  },
  {
    chainId: 1,
    address: '0xd38de88687172bde440755b5237987e4a87c23a7',
    name: 'AEN Smart Token',
    symbol: 'AENS',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/15163/thumb/x58-DmSw.png?1620007469'
  },
  {
    chainId: 1,
    address: '0x78a2a1029e3168b49d3a276c787050ff5106dcf2',
    name: 'EZOOW',
    symbol: 'EZW',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6720/thumb/ezoow.png?1541392542'
  },
  {
    chainId: 1,
    address: '0x932d447274dcffb4aea4f0944d3c804e88056416',
    name: 'Lemon Bet',
    symbol: 'LBET',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11374/thumb/lbet.png?1590037291'
  },
  {
    chainId: 1,
    address: '0xb566e883555aebf5b1db211070b530ab00a4b18a',
    name: 'DCTDAO',
    symbol: 'DCTD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14626/thumb/dctdao.jpg?1617321308'
  },
  {
    chainId: 1,
    address: '0xd0658324074d6249a51876438916f7c423075451',
    name: 'Yearn Land',
    symbol: 'YLAND',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12673/thumb/SXMzvraK_400x400.jpg?1601522909'
  },
  {
    chainId: 1,
    address: '0x7c3e3bdcec89a3f706c9a02797ec427ffa596787',
    name: 'StakeHouse Batch',
    symbol: 'SHB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15638/thumb/stakehouse-dp.png?1621422288'
  },
  {
    chainId: 1,
    address: '0x54a9ed327f2614316914c3f3a782a77d0aa47aee',
    name: 'Connect',
    symbol: 'CNCT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5487/thumb/Connect.png?1547041229'
  },
  {
    chainId: 1,
    address: '0x8c7424c3000942e5a93de4a01ce2ec86c06333cb',
    name: 'ROCK3T',
    symbol: 'R3T',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13890/thumb/rock3t_logo.jpg?1612520793'
  },
  {
    chainId: 1,
    address: '0xe50006781f435ae43096288334afd9ae5ba50065',
    name: 'Corgi Inu',
    symbol: 'CORGI',
    decimals: 10,
    logoURI: 'https://assets.coingecko.com/coins/images/15646/thumb/mWum355Y_400x400.jpg?1621422930'
  },
  {
    chainId: 1,
    address: '0xfcf8eda095e37a41e002e266daad7efc1579bc0a',
    name: 'FLEX Coin',
    symbol: 'FLEX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9108/thumb/CoinFlex.png?1579227316'
  },
  {
    chainId: 1,
    address: '0xf6832ea221ebfdc2363729721a146e6745354b14',
    name: 'FRMx Token',
    symbol: 'FRMX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13082/thumb/New_Project_%2862%29.png?1609811248'
  },
  {
    chainId: 1,
    address: '0xb6ee9668771a79be7967ee29a63d4184f8097143',
    name: 'CargoX',
    symbol: 'CXO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2580/thumb/cargox.png?1547738832'
  },
  {
    chainId: 1,
    address: '0x5f7fa1a0ae94b5dd6bb6bd1708b5f3af01b57908',
    name: 'YFIKing Finance',
    symbol: 'YFIKING',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12395/thumb/Kinglog_200px.png?1599550956'
  },
  {
    chainId: 1,
    address: '0x69692d3345010a207b759a7d1af6fc7f38b35c5e',
    name: 'CHADS VC',
    symbol: 'CHADS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12455/thumb/Chad_VC.png?1599940044'
  },
  {
    chainId: 1,
    address: '0xc03841b5135600312707d39eb2af0d2ad5d51a91',
    name: 'BasketCoin',
    symbol: 'BSKT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14766/thumb/BasketCoin_logo_light-250x250_wbg.png?1618306694'
  },
  {
    chainId: 1,
    address: '0x793e2602a8396468f3ce6e34c1b6c6fd6d985bad',
    name: ' ICK Mask',
    symbol: 'ICK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11522/thumb/ICK.png?1590622642'
  },
  {
    chainId: 1,
    address: '0xc0eb85285d83217cd7c891702bcbc0fc401e2d9d',
    name: 'Hiveterminal token',
    symbol: 'HVN',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/899/thumb/Hiveterminal_token.jpg?1547034726'
  },
  {
    chainId: 1,
    address: '0xacb53386b1c8015ae9352c8482d10c0d4a03c38a',
    name: 'CREATIVE MEDIA INIT',
    symbol: 'CMID',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9570/thumb/J8ItPp1i_400x400.jpg?1568893606'
  },
  {
    chainId: 1,
    address: '0x5a4b14aea23a605abc463f04a6b8aaf52dd3e7c6',
    name: 'HeartBout Pay',
    symbol: 'HP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10348/thumb/5156.png?1580251362'
  },
  {
    chainId: 1,
    address: '0x419c4db4b9e25d6db2ad9691ccb832c8d9fda05e',
    name: 'Dragonchain',
    symbol: 'DRGN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1289/thumb/dragonchain.png?1547957761'
  },
  {
    chainId: 1,
    address: '0x395c47a421c254ae42253764a7f56e0ee0cddac5',
    name: 'RealToken 20200 Les',
    symbol: 'REALTOKEN-20200-LES',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11910/thumb/RealT_Logo-2.png?1596075958'
  },
  {
    chainId: 1,
    address: '0x4730fb1463a6f1f44aeb45f6c5c422427f37f4d0',
    name: '4thpillar technolog',
    symbol: 'FOUR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3434/thumb/four-ticker-2021-256x256.png?1617702287'
  },
  {
    chainId: 1,
    address: '0xc71e20e54adfc415f79bf0a8f11122917920050e',
    name: 'Storichain Token',
    symbol: 'TORI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9551/thumb/Tcp1SO9L_400x400.jpg?1568836675'
  },
  {
    chainId: 1,
    address: '0x8185bc4757572da2a610f887561c32298f1a5748',
    name: 'Aluna',
    symbol: 'ALN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14379/thumb/ALN-token-logo-200px.png?1615778759'
  },
  {
    chainId: 1,
    address: '0x245ef47d4d0505ecf3ac463f4d81f41ade8f1fd1',
    name: 'Nuggets',
    symbol: 'NUG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1543/thumb/nuggets.png?1548329505'
  },
  {
    chainId: 1,
    address: '0x15f5f5f29a819bf7b4b80bf55352e1e42707c94e',
    name: 'Die',
    symbol: 'DIE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13136/thumb/gR-removebg-preview.png?1605580337'
  },
  {
    chainId: 1,
    address: '0x04fa0d235c4abf4bcf4787af4cf447de572ef828',
    name: 'UMA',
    symbol: 'UMA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10951/thumb/UMA.png?1586307916'
  },
  {
    chainId: 1,
    address: '0xf485c5e679238f9304d986bb2fc28fe3379200e5',
    name: 'ugChain',
    symbol: 'UGC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2557/thumb/ugchain.png?1548759478'
  },
  {
    chainId: 1,
    address: '0xd7d05bda4bf5876ba1254b3eaaf8b47d2f5676eb',
    name: 'STABLE ASSET',
    symbol: 'STA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12313/thumb/stable_200x200.png?1599083938'
  },
  {
    chainId: 1,
    address: '0x166f1a7ecae00bd43876a25b10a63c575e05c0e7',
    name: 'VERA',
    symbol: 'VERA',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/9766/thumb/hn9LRFGrVzyuPJrDIrVeCdUN2IfxIwWDk6mM5U9cGm_b0oPyENjOrXzTJ7vwHE65PIzM_H4nGlcHJHPBV5qHoQ8cfwRSud89RJHUngAGMnYIoLZ-UzEk9HuspfOmNu2Finm8RZW-UIM1_Jykw3Cj743iNPqjsqNxkuazLsa0h76oIwf61Sn3h9-IFiwgVLXyfRVuCO0bTY6q_aLJLwzE-P1OALNJfpLSVY52ygRlDGMZS1qj.jpg?1575358608'
  },
  {
    chainId: 1,
    address: '0x44086035439e676c02d411880fccb9837ce37c57',
    name: 'unified Stable Doll',
    symbol: 'USD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12259/thumb/Uniswap_State_Dollar.png?1598550804'
  },
  {
    chainId: 1,
    address: '0x054f76beed60ab6dbeb23502178c52d6c5debe40',
    name: 'DeFiner',
    symbol: 'FIN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12780/thumb/PdaW8Lb.png?1602500407'
  },
  {
    chainId: 1,
    address: '0xb9782532fa7062a6f73df1ce71d75c0e16046ebc',
    name: 'YFI Paprika',
    symbol: 'YFIP',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12842/thumb/yfipaprika.png?1602990259'
  },
  {
    chainId: 1,
    address: '0x4526dc4ac8f692535ed9bf235a3a20a2b9ff3328',
    name: 'Essek Tov',
    symbol: 'ETO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11706/thumb/eto.png?1592986122'
  },
  {
    chainId: 1,
    address: '0x04a020325024f130988782bd5276e53595e8d16e',
    name: 'Herbalist Token',
    symbol: 'HERB',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/6892/thumb/herbalist-token-twitter.png?1547043247'
  },
  {
    chainId: 1,
    address: '0xdc9ac3c20d1ed0b540df9b1fedc10039df13f99c',
    name: 'UTRUST',
    symbol: 'UTK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1824/thumb/300x300_logo.png?1570520533'
  },
  {
    chainId: 1,
    address: '0xf59ae934f6fe444afc309586cc60a84a0f89aaea',
    name: 'Polkadex',
    symbol: 'PDEX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14833/thumb/dIze5Ztl_400x400.jpg?1618610724'
  },
  {
    chainId: 1,
    address: '0x239dc02a28a0774738463e06245544a72745d5c5',
    name: 'Hanzo Inu',
    symbol: 'HNZO',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15486/thumb/hanzo.PNG?1621038959'
  },
  {
    chainId: 1,
    address: '0x035df12e0f3ac6671126525f1015e47d79dfeddf',
    name: '0xMonero',
    symbol: '0XMR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11035/thumb/0xmnr.PNG?1587357680'
  },
  {
    chainId: 1,
    address: '0x6bff2fe249601ed0db3a87424a2e923118bb0312',
    name: 'Fyooz',
    symbol: 'FYZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12202/thumb/fyooz.png?1598017956'
  },
  {
    chainId: 1,
    address: '0x84bb947fcedba6b9c7dcead42df07e113bb03007',
    name: 'Stater',
    symbol: 'STR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14476/thumb/stater.png?1616403854'
  },
  {
    chainId: 1,
    address: '0x4fb721ef3bf99e0f2c193847afa296b9257d3c30',
    name: 'Tokenplace',
    symbol: 'TOK',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/15779/thumb/output-onlinepngtools_%283%29.png?1621837855'
  },
  {
    chainId: 1,
    address: '0x8424c5ac326834b404742de0067bcb654e86be30',
    name: 'Sad Cat Token',
    symbol: 'SCAT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14390/thumb/sadcat_icon.png?1615967385'
  },
  {
    chainId: 1,
    address: '0x7e291890b01e5181f7ecc98d79ffbe12ad23df9e',
    name: 'Unifty',
    symbol: 'NIF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13726/thumb/NIF.png?1612320636'
  },
  {
    chainId: 1,
    address: '0xf4e447c390167e5a17a95ab2475b71d4ea785200',
    name: 'ALL BEST ICO',
    symbol: 'ALLBI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8400/thumb/ALL_BEST_ICO.png?1557986224'
  },
  {
    chainId: 1,
    address: '0x92b914f1ddcbb1d117a718e83c9ed7eb32fc44d1',
    name: 'Enkronos',
    symbol: 'ENK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4214/thumb/enkronos.jpg?1553680821'
  },
  {
    chainId: 1,
    address: '0xaea5e11e22e447fa9837738a0cd2848857748adf',
    name: 'Social Finance',
    symbol: 'SOFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12296/thumb/logo-transparent.png?1598931704'
  },
  {
    chainId: 1,
    address: '0x73a9fb46e228628f8f9bb9004eca4f4f529d3998',
    name: 'Wrapped LEO',
    symbol: 'WLEO',
    decimals: 3,
    logoURI: 'https://assets.coingecko.com/coins/images/12626/thumb/4XfO3w3.png?1601286769'
  },
  {
    chainId: 1,
    address: '0x037a54aab062628c9bbae1fdb1583c195585fe41',
    name: 'LCX',
    symbol: 'LCX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9985/thumb/zRPSu_0o_400x400.jpg?1574327008'
  },
  {
    chainId: 1,
    address: '0xa9c44135b3a87e0688c41cf8c27939a22dd437c9',
    name: 'BooBank',
    symbol: 'BOOB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12894/thumb/boobank.PNG?1604043315'
  },
  {
    chainId: 1,
    address: '0x56687cf29ac9751ce2a4e764680b6ad7e668942e',
    name: 'FlynnJamm',
    symbol: 'JAMM',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/11943/thumb/jamm.png?1602491065'
  },
  {
    chainId: 1,
    address: '0x8daebade922df735c38c80c7ebd708af50815faa',
    name: 'tBTC',
    symbol: 'TBTC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11224/thumb/tBTC.png?1589620754'
  },
  {
    chainId: 1,
    address: '0xaa9d866666c2a3748d6b23ff69e63e52f08d9ab4',
    name: 'Fundamenta',
    symbol: 'FMTA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12983/thumb/fundamenta.png?1604065939'
  },
  {
    chainId: 1,
    address: '0xfa05a73ffe78ef8f1a739473e462c54bae6567d9',
    name: 'Lunyr',
    symbol: 'LUN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/735/thumb/lunyr.png?1547976492'
  },
  {
    chainId: 1,
    address: '0xde201daec04ba73166d9917fdf08e1728e270f06',
    name: 'MOJI Experience Poi',
    symbol: 'MEXP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12571/thumb/mexp_logo.png?1600842788'
  },
  {
    chainId: 1,
    address: '0x47140a767a861f7a1f3b0dd22a2f463421c28814',
    name: 'All me',
    symbol: 'ME',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5497/thumb/all.me-logo.jpg?1547041239'
  },
  {
    chainId: 1,
    address: '0x0789dbae94fb18e5789b8e4489bcb7a1adb58622',
    name: 'FISCO Coin',
    symbol: 'FSCC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/6507/thumb/AnyConv.com__fscc_icon_fin.png?1608779052'
  },
  {
    chainId: 1,
    address: '0x11b0a8c0fa626627601ed518c3538a39d92d609e',
    name: 'Generation of Yield',
    symbol: 'YGY',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/12798/thumb/ygycg.png?1602643175'
  },
  {
    chainId: 1,
    address: '0xe0bdfe2ce51f44556309665d59818ccb541ff067',
    name: 'Crypto Puzzles',
    symbol: 'CPTE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15216/thumb/3_Black___Yellow_2x.png?1620114026'
  },
  {
    chainId: 1,
    address: '0x5547136b913b68881596275ace01e9a589c5b16b',
    name: 'BITCOINHEDGE',
    symbol: 'BTCHG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11617/thumb/BTCHG_logo.png?1600765418'
  },
  {
    chainId: 1,
    address: '0x80dc468671316e50d4e9023d3db38d3105c1c146',
    name: 'xAAVEa',
    symbol: 'XAAVEA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13236/thumb/xAAVEa.png?1606629962'
  },
  {
    chainId: 1,
    address: '0xfef3884b603c33ef8ed4183346e093a173c94da6',
    name: 'MetaMorph',
    symbol: 'METM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3929/thumb/metamorph.png?1548084814'
  },
  {
    chainId: 1,
    address: '0xfbaf48e57cab46f4c2e03edb90a421d9fc6c7cbf',
    name: 'MIKS COIN',
    symbol: 'MIKS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12187/thumb/UBshwvwJ_400x400.jpg?1597962460'
  },
  {
    chainId: 1,
    address: '0xec0a0915a7c3443862b678b0d4721c7ab133fdcf',
    name: 'Wrapped Origin Axie',
    symbol: 'WOA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12732/thumb/WOA_logo.png?1602116474'
  },
  {
    chainId: 1,
    address: '0x0955a73d014f0693ac7b53cfe77706dab02b3ef9',
    name: 'Lady Luck',
    symbol: 'LUCK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15342/thumb/luck.PNG?1620615526'
  },
  {
    chainId: 1,
    address: '0x0db8d8b76bc361bacbb72e2c491e06085a97ab31',
    name: 'IQeon',
    symbol: 'IQN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1736/thumb/IQN_logo_64x64.png?1547036010'
  },
  {
    chainId: 1,
    address: '0xea1f346faf023f974eb5adaf088bbcdf02d761f4',
    name: 'Blocktix',
    symbol: 'TIX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1233/thumb/blocktix.png?1547351347'
  },
  {
    chainId: 1,
    address: '0xd4a80224350528b7c0ae8c325a0b5778d92fd86e',
    name: 'Crypto Bits',
    symbol: 'CBS3',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15737/thumb/logo_-_2021-05-22T200127.142.png?1621684895'
  },
  {
    chainId: 1,
    address: '0x66a0f676479cee1d7373f3dc2e2952778bff5bd6',
    name: 'Wise',
    symbol: 'WISE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13552/thumb/WISE-logo-1600x1280.png?1609727947'
  },
  {
    chainId: 1,
    address: '0x5fd1ffa1d817e2bc1e594081a9f883a8707e959c',
    name: 'CMGCoin',
    symbol: 'CMG',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11158/thumb/CMGCOIN-200x200-1.png?1589246034'
  },
  {
    chainId: 1,
    address: '0xfef4185594457050cc9c23980d301908fe057bb1',
    name: 'VIDT Datalink',
    symbol: 'VIDT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6115/thumb/V-ID_blockchain_-logo.png?1547042127'
  },
  {
    chainId: 1,
    address: '0xa35fc5019c4dc509394bd4d74591a0bf8852c195',
    name: 'BTC ETH 75  25  Wei',
    symbol: 'BTCETH7525',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10593/thumb/btc_buy_and_hold.png?1580963533'
  },
  {
    chainId: 1,
    address: '0xc6bf2a2a43ca360bb0ec6770f57f77cdde64bb3f',
    name: 'UnityDAO',
    symbol: 'UTY',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12553/thumb/UTY_LOGO.png?1600734985'
  },
  {
    chainId: 1,
    address: '0xcff20ce22e71ecf2ea89c86ecbd4a3cf513768c7',
    name: 'Makes',
    symbol: 'MKS',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/13318/thumb/MKS200.png?1607412065'
  },
  {
    chainId: 1,
    address: '0x56d811088235f11c8920698a204a5010a788f4b3',
    name: 'bZx Protocol',
    symbol: 'BZRX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11811/thumb/bzrx.png?1594563172'
  },
  {
    chainId: 1,
    address: '0xa209ba34c01a2713a4453a656630cc9de8a362bc',
    name: '3X Short Chainlink ',
    symbol: 'LINKBEAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10233/thumb/683JEXMN_400x400_%281%29.png?1576618846'
  },
  {
    chainId: 1,
    address: '0xf4b5470523ccd314c6b9da041076e7d79e0df267',
    name: 'BlockBank',
    symbol: 'BBANK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15081/thumb/O0EZIVAu_400x400.jpg?1619656539'
  },
  {
    chainId: 1,
    address: '0x6c37bf4f042712c978a73e3fd56d1f5738dd7c43',
    name: 'Elementeum',
    symbol: 'ELET',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2670/thumb/ELET.png?1558594342'
  },
  {
    chainId: 1,
    address: '0xba8ea15b647f54d9ff849670fcaacf35df21a457',
    name: 'Intelligent Ratio S',
    symbol: 'INTRATIO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10900/thumb/the_intelligent_ratio_set.png?1585730413'
  },
  {
    chainId: 1,
    address: '0xa9a8377287ea9c6b8b4249dd502e75d34148fc5b',
    name: 'Stargaze Protocol',
    symbol: 'STGZ',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13339/thumb/stgz_protocol_logo.jpg?1607580880'
  },
  {
    chainId: 1,
    address: '0xbb1fa4fdeb3459733bf67ebc6f893003fa976a82',
    name: 'Pangea Arbitration ',
    symbol: 'XPAT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2070/thumb/Pangea-Arbitration-Token-Logo.png?1547036374'
  },
  {
    chainId: 1,
    address: '0x697ef32b4a3f5a4c39de1cb7563f24ca7bfc5947',
    name: 'Insula',
    symbol: 'ISLA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10884/thumb/isla.PNG?1585522028'
  },
  {
    chainId: 1,
    address: '0x6f919d67967a97ea36195a2346d9244e60fe0ddb',
    name: 'Blockcloud',
    symbol: 'BLOC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4572/thumb/Xd1i27EM_400x400.jpg?1547039854'
  },
  {
    chainId: 1,
    address: '0x4a220e6096b25eadb88358cb44068a3248254675',
    name: 'Quant',
    symbol: 'QNT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3370/thumb/5ZOu7brX_400x400.jpg?1612437252'
  },
  {
    chainId: 1,
    address: '0x842c2b97772b4528589ed243f6bda3381ef0261f',
    name: 'DGL Coin',
    symbol: 'DGL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14015/thumb/dgllogo.png?1613698745'
  },
  {
    chainId: 1,
    address: '0x4290563c2d7c255b5eec87f2d3bd10389f991d68',
    name: 'UnlimitedIP',
    symbol: 'UIP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2642/thumb/unlimitedip.png?1547036818'
  },
  {
    chainId: 1,
    address: '0x6d16cf3ec5f763d4d99cb0b0b110eefd93b11b56',
    name: 'Synth sOIL',
    symbol: 'SOIL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13837/thumb/sOIL.png?1616150562'
  },
  {
    chainId: 1,
    address: '0x64e65d352f6a2949463b3a7595911b61bbafc63e',
    name: 'Khipu Token',
    symbol: 'KIP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9751/thumb/npjrHlXC_400x400.jpg?1571380858'
  },
  {
    chainId: 1,
    address: '0x87d73e916d7057945c9bcd8cdd94e42a6f47f776',
    name: 'NFTX',
    symbol: 'NFTX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13574/thumb/NFTX_%28Real%29.jpg?1613449530'
  },
  {
    chainId: 1,
    address: '0x5e74c9036fb86bd7ecdcb084a0673efc32ea31cb',
    name: 'sETH',
    symbol: 'SETH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8843/thumb/sETH.png?1616150207'
  },
  {
    chainId: 1,
    address: '0x053e5ba7cb9669dcc2feb2d0e1d3d4a0ad6aae39',
    name: '3X Short OKB Token',
    symbol: 'OKBBEAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10241/thumb/683JEXMN_400x400_%281%29.png?1576633732'
  },
  {
    chainId: 1,
    address: '0xd9a6803f41a006cbf389f21e55d7a6079dfe8df3',
    name: 'NovaDeFi',
    symbol: 'NMT',
    decimals: 19,
    logoURI: 'https://assets.coingecko.com/coins/images/12752/thumb/nova_defi_logo.png?1602241365'
  },
  {
    chainId: 1,
    address: '0xe95a203b1a91a908f9b9ce46459d101078c2c3cb',
    name: 'ankrETH',
    symbol: 'AETH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13403/thumb/ankr.png?1608764406'
  },
  {
    chainId: 1,
    address: '0xcc80c051057b774cd75067dc48f8987c4eb97a5e',
    name: 'Nectar',
    symbol: 'NEC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3559/thumb/NecLogoLoad.png?1592795924'
  },
  {
    chainId: 1,
    address: '0xc813ea5e3b48bebeedb796ab42a30c5599b01740',
    name: 'Autonio',
    symbol: 'NIOX',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/1122/thumb/NewLogo.png?1597298450'
  },
  {
    chainId: 1,
    address: '0xb879da8b24c9b8685de8526cf492e954f165d74b',
    name: 'MovieBloc',
    symbol: 'MBL',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/8460/thumb/H5IOlyrzJPJn9v-DGVYMo9TRM7A-XmNFSyY57bLIhhoO0WVXskWYl7hvBvq6zl7S5Keip_jN9mQRyi4CccGi8vFpoARK1DFGiugOy_u6wqepC_StItYo-EWUEMyCJNYiQRCuNcsn04BAl1Pggp9Gwtbqle7_BZTkvxNVb8YTqgsHJAqCeCyF6O4uVEKUVPaWFZpvTx60TpEbIDhK2.jpg?1558690328'
  },
  {
    chainId: 1,
    address: '0x5d4d57cd06fa7fe99e26fdc481b468f77f05073c',
    name: 'Netkoin',
    symbol: 'NTK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5162/thumb/NTK.png?1606878538'
  },
  {
    chainId: 1,
    address: '0x9f195617fa8fbad9540c5d113a99a0a0172aaedc',
    name: 'Niobium Coin',
    symbol: 'NBC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4095/thumb/logo-niobium.png?1547039273'
  },
  {
    chainId: 1,
    address: '0x8f3470a7388c05ee4e7af3d01d8c722b0ff52374',
    name: 'Veritaseum',
    symbol: 'VERI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/695/thumb/veritaseum.png?1547034460'
  },
  {
    chainId: 1,
    address: '0xc477d038d5420c6a9e0b031712f61c5120090de9',
    name: 'Boson Protocol',
    symbol: 'BOSON',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14710/thumb/boson_logo.png?1617882472'
  },
  {
    chainId: 1,
    address: '0x877c7deb5eb1fc5faad30c71e3a6e39dc8b1519f',
    name: 'HeartBout',
    symbol: 'HB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2037/thumb/hbc.png?1547036326'
  },
  {
    chainId: 1,
    address: '0x0000000000b3f879cb30fe243b4dfee438691c04',
    name: 'GasToken',
    symbol: 'GST2',
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/10779/thumb/gas.png?1583466756'
  },
  {
    chainId: 1,
    address: '0x8c15ef5b4b21951d50e53e4fbda8298ffad25057',
    name: 'Function X',
    symbol: 'FX',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/8186/thumb/47271330_590071468072434_707260356350705664_n.jpg?1556096683'
  },
  {
    chainId: 1,
    address: '0x2467aa6b5a2351416fd4c3def8462d841feeecec',
    name: 'qiibee',
    symbol: 'QBX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2365/thumb/qbx-icon-dark.png?1554082393'
  },
  {
    chainId: 1,
    address: '0x0e58ed58e150dba5fd8e5d4a49f54c7e1e880124',
    name: 'Relite Finance',
    symbol: 'RELI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14906/thumb/xPZRuspj_400x400.jpg?1618983798'
  },
  {
    chainId: 1,
    address: '0x2d4de3c744d43cf77cb12399921faf0d78b7415b',
    name: 'Boldman Capital',
    symbol: 'BOLD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6832/thumb/boldman-unicorn-logo-dark.png?1547043143'
  },
  {
    chainId: 1,
    address: '0x8290333cef9e6d528dd5618fb97a76f268f3edd4',
    name: 'Ankr',
    symbol: 'ANKR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4324/thumb/U85xTl2.png?1608111978'
  },
  {
    chainId: 1,
    address: '0x558a069a3a1a1e72398607b9e3577fce1c67ea63',
    name: 'JPYQ Stablecoin by ',
    symbol: 'JPYQ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13271/thumb/1MMbEc4a.png?1606884873'
  },
  {
    chainId: 1,
    address: '0xb3dd5dce850dca7519e74a943568b69f958df52c',
    name: 'UniversalEnergyChai',
    symbol: 'UENC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8301/thumb/uenc.png?1557317299'
  },
  {
    chainId: 1,
    address: '0x47bc01597798dcd7506dcca36ac4302fc93a8cfb',
    name: 'Crowd Machine',
    symbol: 'CMCT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/2967/thumb/crowd-machine.png?1547037212'
  },
  {
    chainId: 1,
    address: '0x16b1eb8b8e9058800bf0ba3684f805a6711a1d2c',
    name: 'Reflector Finance',
    symbol: 'RFCTR',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13402/thumb/aRFCTR_PROFILE_LOGO.png?1612494768'
  },
  {
    chainId: 1,
    address: '0xba50933c268f567bdc86e1ac131be072c6b0b71a',
    name: 'ARPA Chain',
    symbol: 'ARPA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8506/thumb/9u0a23XY_400x400.jpg?1559027357'
  },
  {
    chainId: 1,
    address: '0xb422e605fbd765b80d2c4b5d8196c2f94144438b',
    name: '3X Short Litecoin T',
    symbol: 'LTCBEAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10223/thumb/683JEXMN_400x400_%281%29.png?1576588627'
  },
  {
    chainId: 1,
    address: '0x5e3845a1d78db544613edbe43dc1ea497266d3b8',
    name: 'LNX Protocol',
    symbol: 'LNX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8776/thumb/Linix.png?1561103529'
  },
  {
    chainId: 1,
    address: '0x3f2d8861e8ca9a7649d211dbaa3f3d998c6a254a',
    name: 'Bullers Coin',
    symbol: 'BLCC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12582/thumb/Untitled-design-7.png?1600925931'
  },
  {
    chainId: 1,
    address: '0x0f71b8de197a1c84d31de0f1fa7926c365f052b3',
    name: 'Arcona',
    symbol: 'ARCONA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4312/thumb/9xfrZX3q_400x400.jpg?1551073749'
  },
  {
    chainId: 1,
    address: '0x08711d3b02c8758f2fb3ab4e80228418a7f8e39c',
    name: 'Edgeless',
    symbol: 'EDG',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/635/thumb/edgeless.png?1547958143'
  },
  {
    chainId: 1,
    address: '0xc00e94cb662c3520282e6f5717214004a7f26888',
    name: 'Compound',
    symbol: 'COMP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10775/thumb/COMP.png?1592625425'
  },
  {
    chainId: 1,
    address: '0x193408ca0576b73156ed42a2ea7d6fd3f6507162',
    name: 'Infinity Esaham',
    symbol: 'INFS',
    decimals: 1,
    logoURI: 'https://assets.coingecko.com/coins/images/10716/thumb/WBUD9cx.png?1582524776'
  },
  {
    chainId: 1,
    address: '0x187d1018e8ef879be4194d6ed7590987463ead85',
    name: 'FUZE Token',
    symbol: 'FUZE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8941/thumb/logo-fuze-fix-big.png?1563117524'
  },
  {
    chainId: 1,
    address: '0xf5832512cfda8083e5b2dd0aa7c1b9265c03ba1f',
    name: 'Zugacoin',
    symbol: 'SZC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13283/thumb/logo_%2826%29.png?1606960000'
  },
  {
    chainId: 1,
    address: '0x3839d8ba312751aa0248fed6a8bacb84308e20ed',
    name: 'Bezop',
    symbol: 'BEZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1992/thumb/bez.png?1547036264'
  },
  {
    chainId: 1,
    address: '0x43afc9058a3debf37eadf99138e449ce8a480a8a',
    name: 'STAMP',
    symbol: 'STAMP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10971/thumb/coin_%281%29.png?1586742472'
  },
  {
    chainId: 1,
    address: '0xb81d70802a816b5dacba06d708b5acf19dcd436d',
    name: 'Dextoken Governance',
    symbol: 'DEXG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12223/thumb/dextoken-logo-v2_200.png?1598408669'
  },
  {
    chainId: 1,
    address: '0x69e8b9528cabda89fe846c67675b5d73d463a916',
    name: 'OPEN Governance Tok',
    symbol: 'OPEN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13233/thumb/opendao_logo.png?1606575207'
  },
  {
    chainId: 1,
    address: '0x72a56f7bbee939a8c441c891e1754bb795ee0b21',
    name: 'Crown Finance',
    symbol: 'CRWN',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13791/thumb/crown256.png?1611785271'
  },
  {
    chainId: 1,
    address: '0x0fd3822072ad001aac1c90a09d9506f097f24458',
    name: 'NOA PLAY',
    symbol: 'NOA',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15723/thumb/XHFjmBTx_400x400.png?1621635644'
  },
  {
    chainId: 1,
    address: '0x74c287ad5328daca276c6a1c1f149415b12c148d',
    name: 'Pazzy',
    symbol: 'PAZZY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13949/thumb/copred.png?1613205149'
  },
  {
    chainId: 1,
    address: '0x0ae055097c6d159879521c384f1d2123d1f195e6',
    name: 'xDAI Stake',
    symbol: 'STAKE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11061/thumb/xdai.png?1587714165'
  },
  {
    chainId: 1,
    address: '0xff56cc6b1e6ded347aa0b7676c85ab0b3d08b0fa',
    name: 'Orbs',
    symbol: 'ORBS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4630/thumb/Orbs.jpg?1547039896'
  },
  {
    chainId: 1,
    address: '0x30680ac0a8a993088223925265fd7a76beb87e7f',
    name: 'ARAW Token',
    symbol: 'ARAW',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4281/thumb/araw.png?1547039642'
  },
  {
    chainId: 1,
    address: '0x68481f2c02be3786987ac2bc3327171c5d05f9bd',
    name: 'Based Loans Ownersh',
    symbol: 'BLO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14982/thumb/based-loans-logo-200.png?1619246801'
  },
  {
    chainId: 1,
    address: '0xd6d3608f2d770d0a8d0da62d7afe21ea1da86d9c',
    name: 'AmericanHorror Fina',
    symbol: 'AHF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12647/thumb/KBakm0K.jpg?1601386376'
  },
  {
    chainId: 1,
    address: '0x01fa555c97d7958fa6f771f3bbd5ccd508f81e22',
    name: 'Civil',
    symbol: 'CVL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6374/thumb/civil.png?1547042477'
  },
  {
    chainId: 1,
    address: '0x5d1b1019d0afa5e6cc047b9e78081d44cc579fc4',
    name: 'yfrb Finance',
    symbol: 'YFRB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12431/thumb/yfrb.png?1599786507'
  },
  {
    chainId: 1,
    address: '0xdf574c24545e5ffecb9a659c229253d4111d87e1',
    name: 'HUSD',
    symbol: 'HUSD',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9567/thumb/HUSD.jpg?1568889385'
  },
  {
    chainId: 1,
    address: '0xf06ddacf71e2992e2122a1a0168c6967afdf63ce',
    name: 'uUSDrBTC Synthetic ',
    symbol: 'UUSDRBTC-DEC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12585/thumb/uma_logo.jpg?1600937989'
  },
  {
    chainId: 1,
    address: '0xb3e2cb7cccfe139f8ff84013823bf22da6b6390a',
    name: 'Iconic Token',
    symbol: 'ICNQ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7830/thumb/2_Iconic_Holding_icon.png?1593396172'
  },
  {
    chainId: 1,
    address: '0xdbf5c7d8ac5007667617a15db2c1b1d616c9d302',
    name: 'FlexETH BTC Set',
    symbol: 'FLEXETHBTC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10581/thumb/flex_eth_btc.png?1580960245'
  },
  {
    chainId: 1,
    address: '0x1bc9f31c327ce04b6fa9d56fd84c14cc0b0a4f47',
    name: 'Hygenercoin',
    symbol: 'HG',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/8817/thumb/60762471_431874227611586_5376521021266329600_n.png?1561551785'
  },
  {
    chainId: 1,
    address: '0xc1322d8ae3b0e2e437e0ae36388d0cfd2c02f1c9',
    name: 'DAO PlayMarket 2 0',
    symbol: 'PMT',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/1514/thumb/pmt.png?1547035648'
  },
  {
    chainId: 1,
    address: '0x5136c98a80811c3f46bdda8b5c4555cfd9f812f0',
    name: 'indaHash',
    symbol: 'IDH',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/1909/thumb/indahash.png?1547973902'
  },
  {
    chainId: 1,
    address: '0x1cf4592ebffd730c7dc92c1bdffdfc3b9efcf29a',
    name: 'Waves',
    symbol: 'WAVES',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/425/thumb/waves.png?1548386117'
  },
  {
    chainId: 1,
    address: '0xb0e99627bc29adef1178f16117bf495351e81997',
    name: 'Dex Trade Coin',
    symbol: 'DXC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15025/thumb/R1A63oDx_400x400.jpg?1619489389'
  },
  {
    chainId: 1,
    address: '0x12d102f06da35cc0111eb58017fd2cd28537d0e1',
    name: 'Vox Finance',
    symbol: 'VOX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12880/thumb/BSensIa.png?1603261093'
  },
  {
    chainId: 1,
    address: '0x3597bfd533a99c9aa083587b074434e61eb0a258',
    name: 'Dent',
    symbol: 'DENT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/1152/thumb/gLCEA2G.png?1604543239'
  },
  {
    chainId: 1,
    address: '0x0c37bcf456bc661c14d596683325623076d7e283',
    name: 'Aeron',
    symbol: 'ARNX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1124/thumb/ARNX-token-logo-256x256.png?1602652111'
  },
  {
    chainId: 1,
    address: '0xf136d7b0b7ae5b86d21e7b78dfa95375a7360f19',
    name: 'Toshimon',
    symbol: 'TOSHI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14019/thumb/9E1K1PIZ_400x400.png?1613716582'
  },
  {
    chainId: 1,
    address: '0xf30547ff2df1f1cbe5c8dd758b3dd098c856e38f',
    name: 'RAI Finance',
    symbol: 'RAI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14686/thumb/RAI_Finance.jpg?1617778423'
  },
  {
    chainId: 1,
    address: '0x25901f2a5a4bb0aaabe2cdb24e0e15a0d49b015d',
    name: 'Bitfex',
    symbol: 'BFX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9616/thumb/CBc5dTJ.png?1569853341'
  },
  {
    chainId: 1,
    address: '0x0a4b2d4b48a63088e0897a3f147ba37f81a27722',
    name: 'CuraDAI',
    symbol: 'CURA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11540/thumb/61919000.png?1590983686'
  },
  {
    chainId: 1,
    address: '0x90d702f071d2af33032943137ad0ab4280705817',
    name: 'YFFS Finance',
    symbol: 'YFFS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12309/thumb/KijHtdcg_400x400.jpg?1599041092'
  },
  {
    chainId: 1,
    address: '0x708aa4e8aaeaad6074dd09cc4e5c52a70452eb39',
    name: 'Bitcoffeen',
    symbol: 'BFF',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10735/thumb/Iz2489xQ_400x400.png?1582701365'
  },
  {
    chainId: 1,
    address: '0xa44e5137293e855b1b7bc7e2c6f8cd796ffcb037',
    name: 'Sentinel  OLD ',
    symbol: 'DVPN',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/3625/thumb/download_%287%29.png?1547038545'
  },
  {
    chainId: 1,
    address: '0x08d32b0da63e2c3bcf8019c9c5d849d7a9d791e6',
    name: 'Dentacoin',
    symbol: 'DCN',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/850/thumb/dentacoin.png?1547034647'
  },
  {
    chainId: 1,
    address: '0x37e8789bb9996cac9156cd5f5fd32599e6b91289',
    name: 'AidCoin',
    symbol: 'AID',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2144/thumb/aid.png?1547036449'
  },
  {
    chainId: 1,
    address: '0x0f7f961648ae6db43c75663ac7e5414eb79b5704',
    name: 'Blockzero Labs',
    symbol: 'XIO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10029/thumb/blockzero.jpg?1611110205'
  },
  {
    chainId: 1,
    address: '0xac00797df10e825589d8b53e715393be4e617459',
    name: 'Bubble Network',
    symbol: 'BBL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13443/thumb/bubble_network_logo.png?1608612666'
  },
  {
    chainId: 1,
    address: '0x31274db8b609df99e5988ee527071643b5160fc3',
    name: 'Business Credit Sub',
    symbol: 'BCS',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/9638/thumb/iq6AaVX8F_EvdI7nkgGPKL4P1ON70Zpu4JoIYpXK5RTMtGKATJVC0HAwWtddy2Yiw6fNkj7ElLSRkziKEHYsjvLdCZzWelJEzbToEKHgOejWXlHFsc-Ssqz3iiHenhYI8QzUhqYTp-rKa7DoCKPASlN2cO1JMFMAzqCPwHpYToIfYP4_KsVeE1u-FcHsVNNSQ9Zy5nbl7MnLb8O0G.jpg?1570077863'
  },
  {
    chainId: 1,
    address: '0x1640bd2898eee4c36f369836a067dea8725ac0cc',
    name: 'DeFiato',
    symbol: 'DFO',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13386/thumb/Defiato.png?1608085873'
  },
  {
    chainId: 1,
    address: '0x4c25bdf026ea05f32713f00f73ca55857fbf6342',
    name: 'Font',
    symbol: 'FONT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14085/thumb/font.png?1614183855'
  },
  {
    chainId: 1,
    address: '0x0ef3b2024ae079e6dbc2b37435ce30d2731f0101',
    name: 'UNIFI DeFi',
    symbol: 'UNIFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12533/thumb/unifi_defi_logo.jpg?1600531278'
  },
  {
    chainId: 1,
    address: '0xac0c8da4a4748d8d821a0973d00b157aa78c473d',
    name: 'YFIONE',
    symbol: 'YFO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13670/thumb/256.png?1610676054'
  },
  {
    chainId: 1,
    address: '0x491c9a23db85623eed455a8efdd6aba9b911c5df',
    name: 'Hero Node Token',
    symbol: 'HER',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4131/thumb/Webp.net-resizeimage_%2824%29.jpg?1547039357'
  },
  {
    chainId: 1,
    address: '0xed04915c23f00a313a544955524eb7dbd823143d',
    name: 'Alchemy Pay',
    symbol: 'ACH',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12390/thumb/ACH_%281%29.png?1599691266'
  },
  {
    chainId: 1,
    address: '0x47fd85128312ee72aa0e0382a531a8f848b8b2cb',
    name: 'Gallery Finance',
    symbol: 'GLF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12857/thumb/U5VMHQ5q_400x400.jpg?1603090892'
  },
  {
    chainId: 1,
    address: '0xfdbc1adc26f0f8f8606a5d63b7d3a3cd21c22b23',
    name: '1World',
    symbol: '1WO',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/3443/thumb/unnamed.png?1547038151'
  },
  {
    chainId: 1,
    address: '0x03042482d64577a7bdb282260e2ea4c8a89c064b',
    name: 'Centaur',
    symbol: 'CNTR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12743/thumb/logo_%2898%29.png?1602630445'
  },
  {
    chainId: 1,
    address: '0x429d83bb0dcb8cdd5311e34680adc8b12070a07f',
    name: 'PlatonCoin',
    symbol: 'PLTC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7178/thumb/PLTC.png?1616126045'
  },
  {
    chainId: 1,
    address: '0x6d1dc3928604b00180bb570bdae94b9698d33b79',
    name: 'UnitedCrowd',
    symbol: 'UCT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14956/thumb/eUvRU9wm.png?1619142511'
  },
  {
    chainId: 1,
    address: '0x755eb14d2feff2939eb3026f5cad9d03775b9ff4',
    name: 'BunnyToken',
    symbol: 'BUNNY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3185/thumb/bunnytoken.png?1547564097'
  },
  {
    chainId: 1,
    address: '0x5df94780f00140fe72d239d0d261f7797e3fbd1b',
    name: 'QChi Chain',
    symbol: 'QHC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12642/thumb/logo.png?1601370470'
  },
  {
    chainId: 1,
    address: '0x010c282118aa76174ce5952572ba715cf60a0c9b',
    name: 'VINX COIN STO',
    symbol: 'VINX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11177/thumb/SM-VINX-STO-COIN-LOGO.png?1589508101'
  },
  {
    chainId: 1,
    address: '0x49849c98ae39fff122806c06791fa73784fb3675',
    name: 'LP renBTC Curve',
    symbol: 'RENBTCCURVE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11957/thumb/Curvefi_renCrv_32.png?1596435739'
  },
  {
    chainId: 1,
    address: '0x1c1c14a6b5074905ce5d367b0a7e098b58ebfd47',
    name: 'FIDEX Exchange',
    symbol: 'FEX',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/6843/thumb/1LC9qeLf_400x400.jpg?1547043159'
  },
  {
    chainId: 1,
    address: '0xbae5f2d8a1299e5c4963eaff3312399253f27ccb',
    name: 'Soar',
    symbol: 'SOAR',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13718/thumb/soar_logo-e1610311708668.png?1611125359'
  },
  {
    chainId: 1,
    address: '0xe0b9a2c3e9f40cf74b2c7f591b2b0cca055c3112',
    name: 'Genesis Shards',
    symbol: 'GS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14784/thumb/gs.png?1618408218'
  },
  {
    chainId: 1,
    address: '0xc08512927d12348f6620a698105e1baac6ecd911',
    name: 'GYEN',
    symbol: 'GYEN',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/14191/thumb/icon_gyen_200_200.png?1614843343'
  },
  {
    chainId: 1,
    address: '0xf9c36c7ad7fa0f0862589c919830268d1a2581a1',
    name: 'BOA',
    symbol: 'BOA',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/12251/thumb/5f4336749313bc77f88e3927_the-ouroboros-or-uroborus-english-school-p-500.jpeg?1598515550'
  },
  {
    chainId: 1,
    address: '0x538a151dd910c1d1227719bd400d6c4f99ea06d0',
    name: 'Cryptochrome',
    symbol: 'CHM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13028/thumb/cryptochrome_logo.png?1604461218'
  },
  {
    chainId: 1,
    address: '0x8af17a6396c8f315f6b6dbc6aa686c85f9b3e554',
    name: '3X Long Tezos Token',
    symbol: 'XTZBULL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10254/thumb/683JEXMN_400x400_%281%29.png?1576675670'
  },
  {
    chainId: 1,
    address: '0x584bc13c7d411c00c01a62e8019472de68768430',
    name: 'Hegic',
    symbol: 'HEGIC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12454/thumb/Hegic.png?1599938210'
  },
  {
    chainId: 1,
    address: '0x20c36f062a31865bed8a5b1e512d9a1a20aa333a',
    name: 'DefiDollar DAO',
    symbol: 'DFD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12959/thumb/DFD.jpg?1604415975'
  },
  {
    chainId: 1,
    address: '0x9f4f1c679fc8479cd71482a8d8c88dc60c1403f9',
    name: 'Double Ace',
    symbol: 'DAA',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13806/thumb/doubleace-logo.png?1612001264'
  },
  {
    chainId: 1,
    address: '0xea26c4ac16d4a5a106820bc8aee85fd0b7b2b664',
    name: 'QuarkChain',
    symbol: 'QKC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3849/thumb/quarkchain.png?1548387524'
  },
  {
    chainId: 1,
    address: '0x607f4c5bb672230e8672085532f7e901544a7375',
    name: 'iExec RLC',
    symbol: 'RLC',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/646/thumb/pL1VuXm.png?1604543202'
  },
  {
    chainId: 1,
    address: '0x2e2e0a28f6585e895dd646a363bae29b77b88a31',
    name: 'Volume Network',
    symbol: 'VOL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8803/thumb/SexNcqS.png?1561521344'
  },
  {
    chainId: 1,
    address: '0x9e77d5a1251b6f7d456722a6eac6d2d5980bd891',
    name: 'BROTHER',
    symbol: 'BRAT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/921/thumb/coinbrat.jpg?1547034765'
  },
  {
    chainId: 1,
    address: '0x4a22a69e45ab29f9f7276b0267797474daf1f27c',
    name: 'SUNI',
    symbol: 'SUNI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14857/thumb/200_%282%29.png?1618803559'
  },
  {
    chainId: 1,
    address: '0x808507121b80c02388fad14726482e061b8da827',
    name: 'Pendle',
    symbol: 'PENDLE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15069/thumb/Pendle.png?1619611216'
  },
  {
    chainId: 1,
    address: '0x0e7f79e89ba8c4a13431129fb2db0d4f444b5b9a',
    name: 'Xank',
    symbol: 'XANK',
    decimals: 16,
    logoURI: 'https://assets.coingecko.com/coins/images/11599/thumb/9zAYweVj_400x400.png?1591671435'
  },
  {
    chainId: 1,
    address: '0xf4134146af2d511dd5ea8cdb1c4ac88c57d60404',
    name: 'SunContract',
    symbol: 'SNC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1166/thumb/suncontract.png?1548611174'
  },
  {
    chainId: 1,
    address: '0x0000000000085d4780b73119b644ae5ecd22b376',
    name: 'TrueUSD',
    symbol: 'TUSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3449/thumb/tusd.png?1618395665'
  },
  {
    chainId: 1,
    address: '0x5a80b50a0420a2507e33508a93e0aa037fc90636',
    name: 'Dessfi',
    symbol: 'DESS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14764/thumb/TgpqYcAO_400x400.png?1618285579'
  },
  {
    chainId: 1,
    address: '0xc71d8baaa436aa7e42da1f40bec48f11ab3c9e4a',
    name: 'iEthereum',
    symbol: 'IETH',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/1065/thumb/ieth.png?1600765203'
  },
  {
    chainId: 1,
    address: '0x53f64be99da00fec224eaf9f8ce2012149d2fc88',
    name: 'Nice',
    symbol: 'NICE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12844/thumb/nice-200px.png?1603031077'
  },
  {
    chainId: 1,
    address: '0x57b946008913b82e4df85f501cbaed910e58d26c',
    name: 'Marlin',
    symbol: 'POND',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8903/thumb/Marlin.png?1608584519'
  },
  {
    chainId: 1,
    address: '0xc275865a6cce78398e94cb2af29fa0d787b7f7eb',
    name: 'RiseCoin Token',
    symbol: 'RSCT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4469/thumb/rsct.png?1547039788'
  },
  {
    chainId: 1,
    address: '0x35dd2ebf20746c6e658fac75cd80d4722fae62f6',
    name: 'CryptoBet',
    symbol: 'CBET',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11543/thumb/TvJUqCso_400x400.jpg?1591066399'
  },
  {
    chainId: 1,
    address: '0x4f9254c83eb525f9fcf346490bbb3ed28a81c667',
    name: 'Celer Network',
    symbol: 'CELR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4379/thumb/Celr.png?1554705437'
  },
  {
    chainId: 1,
    address: '0xb2279b6769cfba691416f00609b16244c0cf4b20',
    name: 'Waifu Token',
    symbol: 'WAIF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12100/thumb/Small-Waifu_token.png?1597120029'
  },
  {
    chainId: 1,
    address: '0x1b957dc4aefeed3b4a2351a6a6d5cbfbba0cecfa',
    name: 'HOQU',
    symbol: 'HQX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1793/thumb/hqx.png?1547036096'
  },
  {
    chainId: 1,
    address: '0xffffffff2ba8f66d4e51811c5190992176930278',
    name: 'Furucombo',
    symbol: 'COMBO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13629/thumb/COMBO_token_ol.png?1610701537'
  },
  {
    chainId: 1,
    address: '0xd9af2d11d788da0097076f4eb21bd1c5533743d9',
    name: 'Legal Block',
    symbol: 'LBK',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/9524/thumb/62783498_2399147633483417_3919658167318872064_n.jpg?1568605802'
  },
  {
    chainId: 1,
    address: '0x42726d074bba68ccc15200442b72afa2d495a783',
    name: 'Isiklar Coin',
    symbol: 'ISIKC',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/10992/thumb/logo_%2866%29.png?1586940186'
  },
  {
    chainId: 1,
    address: '0x309013d55fb0e8c17363bcc79f25d92f711a5802',
    name: 'Soft Bitcoin',
    symbol: 'SBTC',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/12094/thumb/soft_bitcoin_logo.jpg?1597043537'
  },
  {
    chainId: 1,
    address: '0x4a73e60adbb8575500ffc6aaea6128954011c8af',
    name: 'Doch Coin',
    symbol: 'DCH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11598/thumb/dzRvyTsj_400x400_%281%29.jpg?1591668799'
  },
  {
    chainId: 1,
    address: '0x3fa729b4548becbad4eab6ef18413470e6d5324c',
    name: 'Mover',
    symbol: 'MOVE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13719/thumb/o0KIvs7T_400x400.jpg?1617672818'
  },
  {
    chainId: 1,
    address: '0x5913d0f34615923552ee913dbe809f9f348e706e',
    name: 'BMJ Master Nodes',
    symbol: 'BMJ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12601/thumb/200.png?1601001633'
  },
  {
    chainId: 1,
    address: '0x4d2ee5dae46c86da2ff521f7657dad98834f97b8',
    name: 'Pepemon Pepeballs',
    symbol: 'PPBLZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12823/thumb/200pepebball-BIG.png?1603330304'
  },
  {
    chainId: 1,
    address: '0xeca82185adce47f39c684352b0439f030f860318',
    name: 'Perlin',
    symbol: 'PERL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4682/thumb/PerlinX-Icon-1500px.png?1598633609'
  },
  {
    chainId: 1,
    address: '0xe74dc43867e0cbeb208f1a012fc60dcbbf0e3044',
    name: 'DeFIRE',
    symbol: 'CWAP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15722/thumb/defire.PNG?1621635373'
  },
  {
    chainId: 1,
    address: '0x4f3afec4e5a3f2a6a1a411def7d7dfe50ee057bf',
    name: 'Digix Gold',
    symbol: 'DGX',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/4171/thumb/DGX_Token.png?1547039436'
  },
  {
    chainId: 1,
    address: '0x2341dd0a96a0dab62aa1efb93d59ff7f3bdb8932',
    name: 'ProStarter',
    symbol: 'PROT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15259/thumb/rpot.PNG?1620269970'
  },
  {
    chainId: 1,
    address: '0x429881672b9ae42b8eba0e26cd9c73711b891ca5',
    name: 'Pickle Finance',
    symbol: 'PICKLE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12435/thumb/pickle_finance_logo.jpg?1599817746'
  },
  {
    chainId: 1,
    address: '0x1017b147b05942ead495e2ad6d606ef3c94b8fd0',
    name: 'Vanilla',
    symbol: 'VNL',
    decimals: 12,
    logoURI: 'https://assets.coingecko.com/coins/images/14679/thumb/vnl.png?1617714590'
  },
  {
    chainId: 1,
    address: '0xbbc2045d335cb224228f1850b29173d9d7d7b989',
    name: 'GoHelpFund',
    symbol: 'HELP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4339/thumb/go-help-fund-token-logo.png?1547039693'
  },
  {
    chainId: 1,
    address: '0x196f4727526ea7fb1e17b2071b3d8eaa38486988',
    name: 'Reserve',
    symbol: 'RSV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10952/thumb/Reserve.png?1586372277'
  },
  {
    chainId: 1,
    address: '0xa7fc5d2453e3f68af0cc1b78bcfee94a1b293650',
    name: 'Spiking',
    symbol: 'SPIKE',
    decimals: 10,
    logoURI:
      'https://assets.coingecko.com/coins/images/8764/thumb/20992879_1955586251324612_252149629126870098_n.png?1560915322'
  },
  {
    chainId: 1,
    address: '0x4c11249814f11b9346808179cf06e71ac328c1b5',
    name: 'Oraichain Token',
    symbol: 'ORAI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12931/thumb/2000x2000_azfsy0.png?1603696770'
  },
  {
    chainId: 1,
    address: '0x72dd4b6bd852a3aa172be4d6c5a6dbec588cf131',
    name: 'NAGA',
    symbol: 'NGC',
    decimals: 19,
    logoURI: 'https://assets.coingecko.com/coins/images/1439/thumb/naga.jpg?1547035515'
  },
  {
    chainId: 1,
    address: '0xa0246c9032bc3a600820415ae600c6388619a14d',
    name: 'Harvest Finance',
    symbol: 'FARM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12304/thumb/Harvest.png?1613016180'
  },
  {
    chainId: 1,
    address: '0x14da7b27b2e0fedefe0a664118b0c9bc68e2e9af',
    name: 'Blockchain Cuties U',
    symbol: 'BCUG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14425/thumb/bcug_logo.png?1616022820'
  },
  {
    chainId: 1,
    address: '0x21686f8ce003a95c99acd297e302faacf742f7d4',
    name: 'Wrapped Conceal',
    symbol: 'WCCX',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/13039/thumb/wccx_logo.png?1604566677'
  },
  {
    chainId: 1,
    address: '0xf151980e7a781481709e8195744bf2399fb3cba4',
    name: 'Freeway Token',
    symbol: 'FWT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13012/thumb/A-200px.png?1604381296'
  },
  {
    chainId: 1,
    address: '0x765baefcb5418fa9f7dddacb1ccc07bd0e890e4e',
    name: 'Meteorite Network',
    symbol: 'METEOR',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14889/thumb/Meteor-token-200.png?1618900489'
  },
  {
    chainId: 1,
    address: '0x08e411220e47e3fc43bfb832186aba95108f2861',
    name: 'Eclipseum',
    symbol: 'ECL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13418/thumb/eclipseum_logo.png?1608429994'
  },
  {
    chainId: 1,
    address: '0x17e6616c45d267bc20a9892b58a01621c592b72d',
    name: 'Ethereum Message Se',
    symbol: 'EMS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9783/thumb/ems.png?1578974895'
  },
  {
    chainId: 1,
    address: '0x86e44543164d9b97b14ef7f6f3ab7ba670cab346',
    name: 'QUINADS',
    symbol: 'QUIN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7041/thumb/quinads-logo.png?1547043484'
  },
  {
    chainId: 1,
    address: '0x3479b0acf875405d7853f44142fe06470a40f6cc',
    name: 'Value USD',
    symbol: 'VUSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13887/thumb/logo.png?1612864004'
  },
  {
    chainId: 1,
    address: '0xfe3e6a25e6b192a42a44ecddcd13796471735acf',
    name: 'Reef Finance',
    symbol: 'REEF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13504/thumb/Group_10572.png?1610534130'
  },
  {
    chainId: 1,
    address: '0x22602469d704bffb0936c7a7cfcd18f7aa269375',
    name: 'sETC',
    symbol: 'SETC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11804/thumb/sETC.png?1616150166'
  },
  {
    chainId: 1,
    address: '0xc21dbee65d62770953035f0434c532d578a666c9',
    name: 'CryptoEnergy',
    symbol: 'CNRG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10101/thumb/5NQC2enL_400x400.jpg?1575927862'
  },
  {
    chainId: 1,
    address: '0xfa6de2697d59e88ed7fc4dfe5a33dac43565ea41',
    name: 'DEFI Top 5 Index',
    symbol: 'DEFI5',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13691/thumb/thGDKHo.png?1610959947'
  },
  {
    chainId: 1,
    address: '0x99fe3b1391503a1bc1788051347a1324bff41452',
    name: 'SportX',
    symbol: 'SX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13779/thumb/sportx.png?1611725183'
  },
  {
    chainId: 1,
    address: '0x2aeccb42482cc64e087b6d2e5da39f5a7a7001f8',
    name: 'Ruler Protocol',
    symbol: 'RULER',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14183/thumb/ruler_token.png?1614821796'
  },
  {
    chainId: 1,
    address: '0xf824402747e5142d04892fdc27c73e6460e80080',
    name: 'Unicly ArtBlocks Co',
    symbol: 'UARTB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15672/thumb/uartblocks.jpeg?1621497079'
  },
  {
    chainId: 1,
    address: '0xed0439eacf4c4965ae4613d77a5c2efe10e5f183',
    name: 'Niftyx Protocol',
    symbol: 'SHROOM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12341/thumb/niftyx_logo.jpg?1617784430'
  },
  {
    chainId: 1,
    address: '0x4a9f00de5d8a244944313faee23849ff725e680d',
    name: 'Science Chain',
    symbol: 'SCC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10053/thumb/Science_Chain.png?1575354140'
  },
  {
    chainId: 1,
    address: '0x30d862bbbef3b75f700d6ba7d323b95708eaafaa',
    name: 'Funder One',
    symbol: 'FUNDX',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/13611/thumb/d6fa6b8fdedb00a6b3935f3a55662796-full.png?1610284736'
  },
  {
    chainId: 1,
    address: '0x8694ee05b45c9fe1058ce532de8dbcf1d84a4154',
    name: 'Treasure Financial ',
    symbol: 'TFC',
    decimals: 5,
    logoURI:
      'https://assets.coingecko.com/coins/images/7227/thumb/33781678_148334189202523_3882412846583119872_o.jpg?1547043812'
  },
  {
    chainId: 1,
    address: '0x3218a02f8f8b5c3894ce30eb255f10bcba13e654',
    name: 'MegaCryptoPolis',
    symbol: 'MEGA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12971/thumb/mcp_icon_200.png?1603943441'
  },
  {
    chainId: 1,
    address: '0x59a921db27dd6d4d974745b7ffc5c33932653442',
    name: 'Mirrored Google',
    symbol: 'MGOOGL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13641/thumb/mirror_logo_transparent.png?1611565240'
  },
  {
    chainId: 1,
    address: '0xf3281c539716a08c754ec4c8f2b4cee0fab64bb9',
    name: 'Markaccy',
    symbol: 'MKCY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12522/thumb/e2eLPzTF_400x400.png?1600499534'
  },
  {
    chainId: 1,
    address: '0xf1d32952e2fbb1a91e620b0fd7fbc8a8879a47f3',
    name: '3X Short Dogecoin T',
    symbol: 'DOGEBEAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10448/thumb/683JEXMN_400x400.png?1579561604'
  },
  {
    chainId: 1,
    address: '0x834ce7ad163ab3be0c5fd4e0a81e67ac8f51e00c',
    name: 'Polkainsure Finance',
    symbol: 'PIS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13544/thumb/Logo_Polkainsure___Final-200x200-01.png?1609686092'
  },
  {
    chainId: 1,
    address: '0xc3771d47e2ab5a519e2917e61e23078d0c05ed7f',
    name: 'Gather',
    symbol: 'GTH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12458/thumb/Gather-Logo-Working-File.png?1599981686'
  },
  {
    chainId: 1,
    address: '0x5d285f735998f36631f678ff41fb56a10a4d0429',
    name: 'MixMarvel',
    symbol: 'MIX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8222/thumb/8878caf93b1e3b6cfb3b414bda3b5250.png?1613945432'
  },
  {
    chainId: 1,
    address: '0x8bb95734f5011088fd228c8060b3e02ca53e3c0d',
    name: 'Candy Box',
    symbol: 'CANDYBOX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9594/thumb/tIVXxlNp_400x400.jpg?1569279181'
  },
  {
    chainId: 1,
    address: '0x09fe5f0236f0ea5d930197dce254d77b04128075',
    name: 'Wrapped CryptoKitti',
    symbol: 'WCK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8797/thumb/WCK.png?1561705836'
  },
  {
    chainId: 1,
    address: '0xf4cd3d3fda8d7fd6c5a500203e38640a70bf9577',
    name: 'YfDAI finance',
    symbol: 'YF-DAI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12385/thumb/yfdai.png?1621236430'
  },
  {
    chainId: 1,
    address: '0xaa4e3edb11afa93c41db59842b29de64b72e355b',
    name: 'Marginswap',
    symbol: 'MFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13899/thumb/marginswap_logo.png?1612756590'
  },
  {
    chainId: 1,
    address: '0x88d39566dae88dc838652d9898f0aa6a8ff2819a',
    name: 'HypeBurn',
    symbol: 'HBURN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13179/thumb/hypeburn_logo_coingecko_200px.png?1605968369'
  },
  {
    chainId: 1,
    address: '0x0000000000004946c0e9f43f4dee607b0ef1fa1c',
    name: 'Chi Gastoken',
    symbol: 'CHI',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/11583/thumb/chi.png?1591331659'
  },
  {
    chainId: 1,
    address: '0x7c5a0ce9267ed19b22f8cae653f198e3e8daf098',
    name: 'Santiment Network T',
    symbol: 'SAN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/797/thumb/santiment-network-token.png?1547034571'
  },
  {
    chainId: 1,
    address: '0x82bdfb4c6f488fc47700cef12c448a2f13f8ff4f',
    name: 'SealBlock Token',
    symbol: 'SKT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8520/thumb/YEf7izTR_400x400.jpg?1559100937'
  },
  {
    chainId: 1,
    address: '0x336492a0601cc85e08c14d390bf07d960328aaf4',
    name: 'Blueshare Token',
    symbol: 'BST1',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13071/thumb/blueshare-token-removebg-preview.png?1604897237'
  },
  {
    chainId: 1,
    address: '0x48e234d2ddcb32d780971c0df7fdde25bba192de',
    name: 'NEWLAND',
    symbol: 'NLD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9138/thumb/drNv_nJs_400x400.png?1564630866'
  },
  {
    chainId: 1,
    address: '0x8275ebf521dc217aa79c88132017a5bcef001dd9',
    name: 'Jewel',
    symbol: 'JWL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6996/thumb/jewelpay_token.jpg?1547043400'
  },
  {
    chainId: 1,
    address: '0xb4d930279552397bba2ee473229f89ec245bc365',
    name: 'MahaDAO',
    symbol: 'MAHA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13404/thumb/mahadao.jpg?1608373356'
  },
  {
    chainId: 1,
    address: '0x72ba699f0f3c29d0f886c264ec7350533a32b3d5',
    name: 'Medican Coin',
    symbol: 'MCAN',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13625/thumb/mcan_logo_RGB_v1.png?1610413624'
  },
  {
    chainId: 1,
    address: '0x74fb9da15d4f9a34d8c825798da0fa5c400dade1',
    name: 'CORD Finance  old ',
    symbol: 'CORD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13221/thumb/cord-transparent-square-256.png?1611322978'
  },
  {
    chainId: 1,
    address: '0xca76baa777d749de63ca044853d22d56bc70bb47',
    name: 'Fiscus FYI',
    symbol: 'FFYI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12663/thumb/FFYI.png?1601450423'
  },
  {
    chainId: 1,
    address: '0x491604c0fdf08347dd1fa4ee062a822a5dd06b5d',
    name: 'Cartesi',
    symbol: 'CTSI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11038/thumb/cartesi.png?1592288021'
  },
  {
    chainId: 1,
    address: '0x93b1e78a3e652cd2e71c4a767595b77282344932',
    name: 'BITO Coin',
    symbol: 'BITO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7796/thumb/bitopro_28.png?1550628495'
  },
  {
    chainId: 1,
    address: '0x683239a4cab49642c6e025cf81d283f9c87bc07d',
    name: 'Unicly MoonCats Col',
    symbol: 'UMOON',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15674/thumb/MoonCats.jpg?1621498102'
  },
  {
    chainId: 1,
    address: '0x3516415161c478df10adbb8bb884cc83fbd5f11a',
    name: 'AlphaDex',
    symbol: 'DEX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12949/thumb/AlphaDex.png?1603779030'
  },
  {
    chainId: 1,
    address: '0x5515950f7bf8d6acdf4ae98c33bf996bd0ed6f6f',
    name: 'FinanceX token',
    symbol: 'FNX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6014/thumb/financex-logo.jpg?1552033180'
  },
  {
    chainId: 1,
    address: '0x2c537e5624e4af88a7ae4060c022609376c8d0eb',
    name: 'BiLira',
    symbol: 'TRYB',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/10119/thumb/v1bIhvRr_400x400.png?1576359242'
  },
  {
    chainId: 1,
    address: '0xb5a52519426ec6d88784cc80e621062498306734',
    name: 'Cash Per Scan',
    symbol: 'CPS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10551/thumb/cps.PNG?1580853560'
  },
  {
    chainId: 1,
    address: '0xcaaa93712bdac37f736c323c93d4d5fdefcc31cc',
    name: 'CryptalDash',
    symbol: 'CRD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2859/thumb/cryptaldash.png?1547037030'
  },
  {
    chainId: 1,
    address: '0x3ea50b7ef6a7eaf7e966e2cb72b519c16557497c',
    name: 'Rocket Bunny',
    symbol: 'BUNNY',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14350/thumb/n8TgW06A_400x400.jpg?1615514406'
  },
  {
    chainId: 1,
    address: '0x0bb217e40f8a5cb79adf04e1aab60e5abd0dfc1e',
    name: 'SWFTCOIN',
    symbol: 'SWFTC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/2346/thumb/SWFTCoin.jpg?1618392022'
  },
  {
    chainId: 1,
    address: '0xc12ecee46ed65d970ee5c899fcc7ae133aff9b03',
    name: 'Try Finance',
    symbol: 'TRY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13865/thumb/try-logo.png?1612420128'
  },
  {
    chainId: 1,
    address: '0xbf8fb919a8bbf28e590852aef2d284494ebc0657',
    name: 'ABCC Token',
    symbol: 'AT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5132/thumb/abcc-token.png?1547040523'
  },
  {
    chainId: 1,
    address: '0x76960dccd5a1fe799f7c29be9f19ceb4627aeb2f',
    name: 'Red',
    symbol: 'RED',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4523/thumb/red.png?1548607994'
  },
  {
    chainId: 1,
    address: '0x0d5bb28972e0b2d63732f558b4af265aa5407467',
    name: '7Chain',
    symbol: 'VII',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/10733/thumb/Xvp5w6kB_400x400.jpg?1582698538'
  },
  {
    chainId: 1,
    address: '0x59321ace77c8087ff8cb9f94c8384807e4fd8a3c',
    name: 'Xeniumx',
    symbol: 'XEMX',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/11094/thumb/AmoNWiZ_vx17KF3g6q5RQRaAYrXBXXWjRH5sU2m-1JYt3rJ4c88yo-KS9qKh43lfN2QkuS6AVuYAxcpQQSHQMjqwh-6HsdCyF-yi0xH9Dio5E6UdvNlh0fAMe-RSzp6x2UN6RhAorfUp_wMDDDnHSANWR9iOmVKtemiBLwpUyF-NbY3xdhW5hHAfbARvdnGODq14ujlFu0MJuLi.jpg?1588212665'
  },
  {
    chainId: 1,
    address: '0xbc396689893d065f41bc2c6ecbee5e0085233447',
    name: 'Perpetual Protocol',
    symbol: 'PERP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12381/thumb/token_2000x2000.png?1615791204'
  },
  {
    chainId: 1,
    address: '0xea38eaa3c86c8f9b751533ba2e562deb9acded40',
    name: 'Etherparty',
    symbol: 'FUEL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1082/thumb/etherparty.png?1547394573'
  },
  {
    chainId: 1,
    address: '0xc666081073e8dff8d3d1c2292a29ae1a2153ec09',
    name: 'Digitex Token',
    symbol: 'DGTX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2188/thumb/DGTX.png?1616385887'
  },
  {
    chainId: 1,
    address: '0x2fd61567c29e7adb4ca17e60e1f4a3fcfe68acb8',
    name: 'SymVerse',
    symbol: 'SYM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9449/thumb/Picture1.png?1567551375'
  },
  {
    chainId: 1,
    address: '0x82fdedfb7635441aa5a92791d001fa7388da8025',
    name: 'Digital Ticks',
    symbol: 'DTX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4898/thumb/digital-ticks-logo.jpg?1533010074'
  },
  {
    chainId: 1,
    address: '0x3f84c4184b35c488f7fe4a12469610c9b1cb03c9',
    name: 'PoolStake',
    symbol: 'PSK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13285/thumb/psk_logo.png?1606984935'
  },
  {
    chainId: 1,
    address: '0x9469d013805bffb7d3debe5e7839237e535ec483',
    name: 'Darwinia Network Na',
    symbol: 'RING',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9443/thumb/RING.png?1615271827'
  },
  {
    chainId: 1,
    address: '0x20398ad62bb2d930646d45a6d4292baa0b860c1f',
    name: 'Flashstake',
    symbol: 'FLASH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13533/thumb/FLASH.png?1615182275'
  },
  {
    chainId: 1,
    address: '0x514910771af9ca656af840dff83e8264ecf986ca',
    name: 'Chainlink',
    symbol: 'LINK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/877/thumb/chainlink-new-logo.png?1547034700'
  },
  {
    chainId: 1,
    address: '0xa1454f9c704af96636f3a7532b9a04c411f85680',
    name: 'BallSwap',
    symbol: 'BSP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14050/thumb/BSP.png?1614397327'
  },
  {
    chainId: 1,
    address: '0x26e75307fc0c021472feb8f727839531f112f317',
    name: 'CRYPTO20',
    symbol: 'C20',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2097/thumb/c20.png?1547036413'
  },
  {
    chainId: 1,
    address: '0x1443e7c1cce72662545d94779120c59251447e91',
    name: 'Molten',
    symbol: 'MOL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13156/thumb/256.png?1605931524'
  },
  {
    chainId: 1,
    address: '0x3db6ba6ab6f95efed1a6e794cad492faaabf294d',
    name: 'LTO Network',
    symbol: 'LTO',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/6068/thumb/LTO_Network.png?1567443682'
  },
  {
    chainId: 1,
    address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    name: 'Wrapped Bitcoin',
    symbol: 'WBTC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744'
  },
  {
    chainId: 1,
    address: '0x36a28c7c9b3dea22f07f4df67833cbe764feeeb4',
    name: 'Cryptonits',
    symbol: 'CRT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13392/thumb/logo-200x200.png?1608100148'
  },
  {
    chainId: 1,
    address: '0x485d17a6f1b8780392d53d64751824253011a260',
    name: 'chrono tech',
    symbol: 'TIME',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/604/thumb/TIMEsymbol200x200.png?1572093177'
  },
  {
    chainId: 1,
    address: '0xac2385e183d9301dd5e2bb08da932cbf9800dc9c',
    name: 'Netkoin Liquid',
    symbol: 'LIQUID',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7910/thumb/netkoin-liquid-logo-600x400.png?1551755315'
  },
  {
    chainId: 1,
    address: '0x139d9397274bb9e2c29a9aa8aa0b5874d30d62e3',
    name: 'BoutsPro',
    symbol: 'BOUTS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4296/thumb/boutspro-ico-logo-300x300.jpg?1527072201'
  },
  {
    chainId: 1,
    address: '0x61b2d3ea9f1c6b387c985c73d40e8fbfb284e5c7',
    name: 'RoboCalls',
    symbol: 'RC20',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7935/thumb/ms-icon-310x310.png?1552009545'
  },
  {
    chainId: 1,
    address: '0xd4f6f9ae14399fd5eb8dfc7725f0094a1a7f5d80',
    name: 'Bitsten Token',
    symbol: 'BST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7335/thumb/bitsten.png?1589629209'
  },
  {
    chainId: 1,
    address: '0x4f27053f32eda8af84956437bc00e5ffa7003287',
    name: 'Thrive',
    symbol: 'THRT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2130/thumb/thrt.png?1547036439'
  },
  {
    chainId: 1,
    address: '0x7c82a76db0166b0e153a66b1a4c331970b2b0ee2',
    name: 'Dobuy',
    symbol: 'DBY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10690/thumb/dobuy.png?1582109170'
  },
  {
    chainId: 1,
    address: '0x4efe8665e564bf454ccf5c90ee16817f7485d5cf',
    name: 'BlackDragon Token',
    symbol: 'BDT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13426/thumb/Black-Dragon-Black.png?1608515220'
  },
  {
    chainId: 1,
    address: '0x4f5fa8f2d12e5eb780f6082dd656c565c48e0f24',
    name: 'Gourmet Galaxy',
    symbol: 'GUM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13846/thumb/gum.png?1612320864'
  },
  {
    chainId: 1,
    address: '0x0602121722642936150f12ea1e07d62a8bb2aa71',
    name: 'Boboo Token',
    symbol: 'BOBT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13123/thumb/2jfWUjx5_400x400.png?1605494729'
  },
  {
    chainId: 1,
    address: '0xf94b5c5651c888d928439ab6514b93944eee6f48',
    name: 'YIELD App',
    symbol: 'YLD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13365/thumb/Icon_Blue.png?1607917500'
  },
  {
    chainId: 1,
    address: '0xeed736b2b809550d89a941c2005de93588c628e2',
    name: 'ETHPlus',
    symbol: 'ETHP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11768/thumb/ethplus.png?1593664706'
  },
  {
    chainId: 1,
    address: '0xbcca60bb61934080951369a648fb03df4f96263c',
    name: 'Aave USDC',
    symbol: 'AUSDC',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/14318/thumb/aUSDC.e260d492.png?1615527797'
  },
  {
    chainId: 1,
    address: '0x54ad74edeab48e09ccc43ee324f2603071dad72b',
    name: 'DTOP Token',
    symbol: 'DTOP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11146/thumb/Untitled.png?1588928222'
  },
  {
    chainId: 1,
    address: '0x92e187a03b6cd19cb6af293ba17f2745fd2357d5',
    name: 'Unit Protocol New',
    symbol: 'DUCK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13359/thumb/unit_telegram.png?1607878022'
  },
  {
    chainId: 1,
    address: '0x989ac4c1fc5ab2b8c86924c6253aaf1ee68e9ce9',
    name: 'Prime Finance',
    symbol: 'PFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13580/thumb/hasanah.jpg?1609915358'
  },
  {
    chainId: 1,
    address: '0xa19a40fbd7375431fab013a4b08f00871b9a2791',
    name: 'Swagg Network',
    symbol: 'SWAGG',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/12126/thumb/swagg_logo.png?1597376071'
  },
  {
    chainId: 1,
    address: '0x56ed2f7dac19243df100bac10364c56df20cb1e9',
    name: 'Brapper Token',
    symbol: 'BRAP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10962/thumb/photo_2019-09-12_01-04-13-400x400.jpg?1586506435'
  },
  {
    chainId: 1,
    address: '0x5b3f693efd5710106eb2eac839368364acb5a70f',
    name: 'Relayer Network  OL',
    symbol: 'RLR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13306/thumb/relayer_logo.jpg?1607327132'
  },
  {
    chainId: 1,
    address: '0x81c9151de0c8bafcd325a57e3db5a5df1cebf79c',
    name: 'Datum',
    symbol: 'DAT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1353/thumb/datum.png?1547035378'
  },
  {
    chainId: 1,
    address: '0xddf7fd345d54ff4b40079579d4c4670415dbfd0a',
    name: 'SocialGood',
    symbol: 'SG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3948/thumb/logo_200.png?1596589686'
  },
  {
    chainId: 1,
    address: '0x428dc22668e6f3468273634067e5545ed5417a3e',
    name: 'MiraQle',
    symbol: 'MQL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12278/thumb/2VTVSgXn_400x400.jpg?1598738364'
  },
  {
    chainId: 1,
    address: '0x4f4f0ef7978737ce928bff395529161b44e27ad9',
    name: 'Your Finance Decent',
    symbol: 'YFD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13225/thumb/YFD2.png?1613362452'
  },
  {
    chainId: 1,
    address: '0x910dfc18d6ea3d6a7124a6f8b5458f281060fa4c',
    name: 'X8X Token',
    symbol: 'X8X',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1912/thumb/x8x.png?1547036213'
  },
  {
    chainId: 1,
    address: '0x1fcdce58959f536621d76f5b7ffb955baa5a672f',
    name: 'ForTube',
    symbol: 'FOR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8242/thumb/for.png?1606195375'
  },
  {
    chainId: 1,
    address: '0x95b3497bbcccc46a8f45f5cf54b0878b39f8d96c',
    name: 'UniDex',
    symbol: 'UNIDX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13178/thumb/unidex_logo.png?1605925219'
  },
  {
    chainId: 1,
    address: '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9',
    name: 'Aave',
    symbol: 'AAVE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12645/thumb/AAVE.png?1601374110'
  },
  {
    chainId: 1,
    address: '0xd778e4f5450ede47289fef874a37b79db77c4cf1',
    name: 'YFIA',
    symbol: 'YFIA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12846/thumb/Untitled-design-11.png?1603061697'
  },
  {
    chainId: 1,
    address: '0x5ca381bbfb58f0092df149bd3d243b08b9a8386e',
    name: 'MXC',
    symbol: 'MXC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4604/thumb/MXC-app-icon10242x.png?1597628240'
  },
  {
    chainId: 1,
    address: '0x2367012ab9c3da91290f71590d5ce217721eefe4',
    name: 'xSNXa',
    symbol: 'XSNXA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12700/thumb/xsnx.png?1601861236'
  },
  {
    chainId: 1,
    address: '0x9e3319636e2126e3c0bc9e3134aec5e1508a46c7',
    name: 'Universa',
    symbol: 'UTNP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2981/thumb/universa.png?1547037236'
  },
  {
    chainId: 1,
    address: '0x3ac2ab91ddf57e2385089202ca221c360ced0062',
    name: 'SwapShip',
    symbol: 'SWSH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12593/thumb/SwapShip.png?1600975182'
  },
  {
    chainId: 1,
    address: '0x7fc3ec3574d408f3b59cd88709bacb42575ebf2b',
    name: 'POP',
    symbol: 'POP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14598/thumb/pop.png?1617195542'
  },
  {
    chainId: 1,
    address: '0x8f87ec6aad3b2a8c44f1298a1af56169b8e574cf',
    name: 'LYNC Network',
    symbol: 'LYNC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12717/thumb/coingecko_200x200.png?1604203317'
  },
  {
    chainId: 1,
    address: '0x69b148395ce0015c13e36bffbad63f49ef874e03',
    name: 'DATA',
    symbol: 'DTA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2604/thumb/data.png?1547036749'
  },
  {
    chainId: 1,
    address: '0xf0fac7104aac544e4a7ce1a55adf2b5a25c65bd1',
    name: 'Pamp Network',
    symbol: 'PAMP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11546/thumb/pMqJaqDK_400x400.jpg?1595199126'
  },
  {
    chainId: 1,
    address: '0x9d1555d8cb3c846bb4f7d5b1b1080872c3166676',
    name: 'Mirrored iShares Si',
    symbol: 'MSLV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13642/thumb/mirror_logo_transparent.png?1611565255'
  },
  {
    chainId: 1,
    address: '0x3f382dbd960e3a9bbceae22651e88158d2791550',
    name: 'Aavegotchi',
    symbol: 'GHST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12467/thumb/ghst_200.png?1600750321'
  },
  {
    chainId: 1,
    address: '0xc949fc82a15964fb5b97e5cf8f9ffed139086821',
    name: 'PGPay',
    symbol: 'PGPAY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6351/thumb/pgpay-logo.jpg?1582515723'
  },
  {
    chainId: 1,
    address: '0x967da4048cd07ab37855c090aaf366e4ce1b9f48',
    name: 'Ocean Protocol',
    symbol: 'OCEAN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3687/thumb/ocean-protocol-logo.jpg?1547038686'
  },
  {
    chainId: 1,
    address: '0x6e13a9e4ae3d0678e511fb6d2ad531fcf0e247bf',
    name: '3X Long Bitcoin SV ',
    symbol: 'BSVBULL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10267/thumb/683JEXMN_400x400_%281%29.png?1576834731'
  },
  {
    chainId: 1,
    address: '0xc014186cf1ba36032aaec7f96088f09eb3934347',
    name: 'WeCoOwn',
    symbol: 'WCX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14497/thumb/WCX-Token-Logo.png?1616555141'
  },
  {
    chainId: 1,
    address: '0xa8c8cfb141a3bb59fea1e2ea6b79b5ecbcd7b6ca',
    name: 'Syntropy',
    symbol: 'NOIA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3269/thumb/Component_1.png?1608275724'
  },
  {
    chainId: 1,
    address: '0xc14103c2141e842e228fbac594579e798616ce7a',
    name: 'sLTC',
    symbol: 'SLTC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10458/thumb/sLTC.png?1616150389'
  },
  {
    chainId: 1,
    address: '0x02f2d4a04e6e01ace88bd2cd632875543b2ef577',
    name: 'PKG Token',
    symbol: 'PKG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5422/thumb/pkg-token.png?1548331357'
  },
  {
    chainId: 1,
    address: '0x0d8775f648430679a709e98d2b0cb6250d2887ef',
    name: 'Basic Attention Tok',
    symbol: 'BAT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/677/thumb/basic-attention-token.png?1547034427'
  },
  {
    chainId: 1,
    address: '0x4a42d2c580f83dce404acad18dab26db11a1750e',
    name: 'Relex',
    symbol: 'RLX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1799/thumb/relex.jpg?1547036103'
  },
  {
    chainId: 1,
    address: '0xf70a642bd387f94380ffb90451c2c81d4eb82cbc',
    name: 'Starbase',
    symbol: 'STAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1449/thumb/starbase.png?1548610771'
  },
  {
    chainId: 1,
    address: '0x19d97d8fa813ee2f51ad4b4e04ea08baf4dffc28',
    name: 'Badger Sett Badger',
    symbol: 'BBADGER',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14502/thumb/BADGER_DAO.png?1616559959'
  },
  {
    chainId: 1,
    address: '0x8ffe40a3d0f80c0ce6b203d5cdc1a6a86d9acaea',
    name: 'IG Gold',
    symbol: 'IGG',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/7697/thumb/N7aEdYrY_400x400.png?1561587437'
  },
  {
    chainId: 1,
    address: '0xc58467b855401ef3ff8fda9216f236e29f0d6277',
    name: 'Gasgains',
    symbol: 'GASG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13795/thumb/gasgains_logo.png?1611809086'
  },
  {
    chainId: 1,
    address: '0x661ab0ed68000491d98c796146bcf28c20d7c559',
    name: 'Shadows',
    symbol: 'DOWS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14160/thumb/C3E49eZx_400x400.jpg?1614689301'
  },
  {
    chainId: 1,
    address: '0x0168703872fa06741ecaa9dff7803168e83f7ae0',
    name: 'Mirocana',
    symbol: 'MIRO',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/1651/thumb/mirocana.jpg?1547035896'
  },
  {
    chainId: 1,
    address: '0x49706a576bb823cde3180c930f9947d59e2ded4d',
    name: 'Punk Attr 4',
    symbol: 'PUNK-ATTR-4',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13998/thumb/icon-punk-attr-4-40.png?1613565027'
  },
  {
    chainId: 1,
    address: '0xc5c43ad81e6c76eaa1f2dd92cb6a11ff6b6d09ea',
    name: 'Shiba Cosmos',
    symbol: 'SHIBCO',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15509/thumb/zkJObpDk_400x400.jpg?1621098100'
  },
  {
    chainId: 1,
    address: '0x33840024177a7daca3468912363bed8b425015c5',
    name: 'Ethbox Token',
    symbol: 'EBOX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14528/thumb/ethbox.PNG?1616743175'
  },
  {
    chainId: 1,
    address: '0xd533a949740bb3306d119cc777fa900ba034cd52',
    name: 'Curve DAO Token',
    symbol: 'CRV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12124/thumb/Curve.png?1597369484'
  },
  {
    chainId: 1,
    address: '0x6006fc2a849fedaba8330ce36f5133de01f96189',
    name: 'Spaceswap SHAKE',
    symbol: 'SHAKE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12765/thumb/shake_logo.jpg?1602470135'
  },
  {
    chainId: 1,
    address: '0xc80c5e40220172b36adee2c951f26f2a577810c5',
    name: 'Bankera',
    symbol: 'BNK',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/1949/thumb/bnk.png?1547036236'
  },
  {
    chainId: 1,
    address: '0x28d7f432d24ba6020d1cbd4f28bedc5a82f24320',
    name: 'Tercet Network',
    symbol: 'TCNX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7319/thumb/180Logo.jpg?1555665267'
  },
  {
    chainId: 1,
    address: '0x0352557b007a4aae1511c114409b932f06f9e2f4',
    name: 'sRUNE',
    symbol: 'SRUNE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14953/thumb/sRUNE.png?1619129376'
  },
  {
    chainId: 1,
    address: '0x1ece1739dae08253ae582c404511b37355b42c84',
    name: 'PICA',
    symbol: 'PICA',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/14364/thumb/pica_logo.png?1615694871'
  },
  {
    chainId: 1,
    address: '0xa5b46ff9a887180c8fb2d97146398ddfc5fef1cd',
    name: 'SuperSkyNet',
    symbol: 'SSN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8944/thumb/p3dTwXMK_400x400.jpg?1563153164'
  },
  {
    chainId: 1,
    address: '0x4086692d53262b2be0b13909d804f0491ff6ec3e',
    name: 'Yield Farming Known',
    symbol: 'YFKA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12459/thumb/5f5cfce7b7b794f5e1e4c6b5_logo200.jpg?1599982854'
  },
  {
    chainId: 1,
    address: '0x2409d6059e2a8130c099e49f3cb418fd6c3d9aff',
    name: 'BTC Fund Active Tra',
    symbol: 'BTCFUND',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11675/thumb/btc_fund_active_trading_set.png?1592573498'
  },
  {
    chainId: 1,
    address: '0x68496ee825dafe1cf66d4083f776b9eaab31e447',
    name: 'ErcauX',
    symbol: 'RAUX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12687/thumb/raux_logo.jpeg?1601633375'
  },
  {
    chainId: 1,
    address: '0xdbddf072d7aae7b9288e31a4eebe6c54e3a143b1',
    name: 'Crowny Token',
    symbol: 'CRWNY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14958/thumb/crowny-icon-rounded_2x.png?1619147225'
  },
  {
    chainId: 1,
    address: '0x9ba60ba98413a60db4c651d4afe5c937bbd8044b',
    name: 'Yearn Lazy Ape',
    symbol: 'YLA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14447/thumb/yla.png?1616128042'
  },
  {
    chainId: 1,
    address: '0x3136ef851592acf49ca4c825131e364170fa32b3',
    name: 'CoinFi',
    symbol: 'COFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1709/thumb/coinfi.png?1547035969'
  },
  {
    chainId: 1,
    address: '0x5dbe296f97b23c4a6aa6183d73e574d02ba5c719',
    name: 'Level Up Coin',
    symbol: 'LUC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1772/thumb/luc.png?1547036065'
  },
  {
    chainId: 1,
    address: '0x88bd6efe33bc82860278c044efa33364c6285032',
    name: 'PegShares',
    symbol: 'PEGS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13675/thumb/pegshare.png?1610705105'
  },
  {
    chainId: 1,
    address: '0x81705082ef9f0d660f07be80093d46d826d48b25',
    name: 'Globalvillage Ecosy',
    symbol: 'GVE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5288/thumb/globalvillage-ecosystem.png?1548127334'
  },
  {
    chainId: 1,
    address: '0xdcd85914b8ae28c1e62f1c488e1d968d5aaffe2b',
    name: 'TOP Network',
    symbol: 'TOP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7058/thumb/topnetwork-logo.png?1547043514'
  },
  {
    chainId: 1,
    address: '0xed91879919b71bb6905f23af0a68d231ecf87b14',
    name: 'DMM  Governance',
    symbol: 'DMG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11677/thumb/dmm.png?1592674690'
  },
  {
    chainId: 1,
    address: '0xade00c28244d5ce17d72e40330b1c318cd12b7c3',
    name: 'AdEx',
    symbol: 'ADX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/847/thumb/adex.png?1547034643'
  },
  {
    chainId: 1,
    address: '0x307d45afbb7e84f82ef3d251a6bb0f00edf632e4',
    name: 'PLANET',
    symbol: 'PLA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9279/thumb/LOGO_BLACK.png?1565731302'
  },
  {
    chainId: 1,
    address: '0x5da3e93fab0580bd7a532a741ac5f886376eff46',
    name: 'Gneiss',
    symbol: 'GNEISS',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13350/thumb/gneiss.png?1607811014'
  },
  {
    chainId: 1,
    address: '0xd98f75b1a3261dab9eed4956c93f33749027a964',
    name: 'ShareToken',
    symbol: 'SHR',
    decimals: 2,
    logoURI:
      'https://assets.coingecko.com/coins/images/3609/thumb/74586729_2443914875881351_2785018663453851648_n.png?1574898410'
  },
  {
    chainId: 1,
    address: '0xc0ea83113038987d974fe667831a36e442e661e7',
    name: 'Libfx',
    symbol: 'LIBFX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12225/thumb/libfx.png?1598306252'
  },
  {
    chainId: 1,
    address: '0xdf1338fbafe7af1789151627b886781ba556ef9a',
    name: 'Kuende',
    symbol: 'KUE',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/7453/thumb/29178480_930308773794230_7348989862965411840_n.png?1547540936'
  },
  {
    chainId: 1,
    address: '0xcf8f9555d55ce45a3a33a81d6ef99a2a2e71dee2',
    name: 'CBI Index 7',
    symbol: 'CBIX7',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9978/thumb/CBIX7.png?1574320790'
  },
  {
    chainId: 1,
    address: '0x622cd54deb2bb7a051515192417109bcf3fe098f',
    name: 'IPChain',
    symbol: 'IPC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/2572/thumb/ipchain.png?1547036700'
  },
  {
    chainId: 1,
    address: '0xe41d2489571d322189246dafa5ebde1f4699f498',
    name: '0x',
    symbol: 'ZRX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/863/thumb/0x.png?1547034672'
  },
  {
    chainId: 1,
    address: '0x196c81385bc536467433014042788eb707703934',
    name: 'CryptoTask',
    symbol: 'CTASK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13965/thumb/cryptotask_logo.png?1613372242'
  },
  {
    chainId: 1,
    address: '0x5845cd0205b5d43af695412a79cf7c1aeddb060f',
    name: 'ShardingDAO',
    symbol: 'SHD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14700/thumb/I1QLkPTq_400x400.png?1617836174'
  },
  {
    chainId: 1,
    address: '0x41a03e41ef555392c9f0ad60f4f61e263078bf10',
    name: 'Upper Dollar',
    symbol: 'USDU',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12332/thumb/UPPER-moeda-dollar.png?1599114824'
  },
  {
    chainId: 1,
    address: '0xbc194e6f748a222754c3e8b9946922c09e7d4e91',
    name: 'Lever Network',
    symbol: 'LEV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15323/thumb/lever.PNG?1620513101'
  },
  {
    chainId: 1,
    address: '0xc05d14442a510de4d3d71a3d316585aa0ce32b50',
    name: 'LINA',
    symbol: 'LINA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5412/thumb/linalogo.png?1551083963'
  },
  {
    chainId: 1,
    address: '0x9a0587eae7ef64b2b38a10442a44cfa43edd7d2a',
    name: 'Welltrado',
    symbol: 'WTL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3612/thumb/welltrado.png?1548760605'
  },
  {
    chainId: 1,
    address: '0x2f5e2c9002c058c063d21a06b6cabb50950130c8',
    name: '3X Short Ethereum T',
    symbol: 'ETHBEAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10172/thumb/683JEXMN_400x400_%281%29.png?1576506124'
  },
  {
    chainId: 1,
    address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    name: 'Tether',
    symbol: 'USDT',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/325/thumb/Tether-logo.png?1598003707'
  },
  {
    chainId: 1,
    address: '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce',
    name: 'Shiba Inu',
    symbol: 'SHIB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11939/thumb/SHIBLOGO.png?1600752116'
  },
  {
    chainId: 1,
    address: '0x957b28f93b0e01557e21e6c564ab26ddc2d18ec5',
    name: 'NemoCoin',
    symbol: 'NEMO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8683/thumb/KwqZ31Zm_400x400.png?1560225786'
  },
  {
    chainId: 1,
    address: '0xe452e6ea2ddeb012e20db73bf5d3863a3ac8d77a',
    name: 'Wrapped CELO',
    symbol: 'WCELO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13561/thumb/WCELO-Icon.jpg?1609819515'
  },
  {
    chainId: 1,
    address: '0xd0c64d6c0e9aa53fffd8b80313e035f7b83083f3',
    name: '1X Short Litecoin T',
    symbol: 'LTCHEDGE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10230/thumb/683JEXMN_400x400_%281%29.png?1576618594'
  },
  {
    chainId: 1,
    address: '0xfa5047c9c78b8877af97bdcb85db743fd7313d4a',
    name: 'KeeperDAO',
    symbol: 'ROOK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13005/thumb/keeper_dao_logo.jpg?1604316506'
  },
  {
    chainId: 1,
    address: '0xd1766a85b0d6f81185782dc07f15326d63c3cbaa',
    name: 'TokenTuber',
    symbol: 'TUBER',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9617/thumb/Wlk5tQQe_400x400.png?1569853753'
  },
  {
    chainId: 1,
    address: '0x63b4f3e3fa4e438698ce330e365e831f7ccd1ef4',
    name: 'CyberFi',
    symbol: 'CFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13112/thumb/cyberfi_logo.jpeg?1605283367'
  },
  {
    chainId: 1,
    address: '0x27ffed7e5926fb2795fc85aaab558243f280a8a2',
    name: 'Punk Female',
    symbol: 'PUNK-FEMALE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13997/thumb/icon-punk-female-40.png?1613564774'
  },
  {
    chainId: 1,
    address: '0x486a72811ae65c4c814ba929d6da35497d21296f',
    name: 'Galaxy Wallet',
    symbol: 'GC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8535/thumb/1500x500.jpg?1596643294'
  },
  {
    chainId: 1,
    address: '0xb2c822a1b923e06dbd193d2cfc7ad15388ea09dd',
    name: 'Vampire Protocol',
    symbol: 'VAMP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12630/thumb/vampire.jpeg?1601335717'
  },
  {
    chainId: 1,
    address: '0xc1ad269f10bf36d6972ee30827051b59d0d2456b',
    name: 'Answer Governance',
    symbol: 'AGOV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14383/thumb/AgoV_Logo1.png?1615787687'
  },
  {
    chainId: 1,
    address: '0x3a1bda28adb5b0a812a7cf10a1950c920f79bcd3',
    name: 'Gameflip',
    symbol: 'FLP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2173/thumb/flp.png?1547036470'
  },
  {
    chainId: 1,
    address: '0xfc1e690f61efd961294b3e1ce3313fbd8aa4f85d',
    name: 'Aave DAI v1',
    symbol: 'ADAI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10843/thumb/aDAI.png?1584698791'
  },
  {
    chainId: 1,
    address: '0x75231f58b43240c9718dd58b4967c5114342a86c',
    name: 'OKB',
    symbol: 'OKB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4463/thumb/okb_token.png?1548386209'
  },
  {
    chainId: 1,
    address: '0x9ba00d6856a4edf4665bca2c2309936572473b7e',
    name: 'Aave USDC v1',
    symbol: 'AUSDC',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/11674/thumb/aUSDC.png?1592546449'
  },
  {
    chainId: 1,
    address: '0xf5238462e7235c7b62811567e63dd17d12c2eaa0',
    name: 'CACHE Gold',
    symbol: 'CGT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11601/thumb/cache-gold-icon-200x200.png?1591755874'
  },
  {
    chainId: 1,
    address: '0x7703c35cffdc5cda8d27aa3df2f9ba6964544b6e',
    name: 'Pylon Network',
    symbol: 'PYLNT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2228/thumb/pylon.png?1547036508'
  },
  {
    chainId: 1,
    address: '0xae7ab96520de3a18e5e111b5eaab095312d7fe84',
    name: 'Lido Staked Ether',
    symbol: 'STETH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13442/thumb/steth_logo.png?1608607546'
  },
  {
    chainId: 1,
    address: '0x960efd63ae895f165d874e6cc62501fd0e7dc50a',
    name: '3X Short Stellar To',
    symbol: 'XLMBEAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13335/thumb/assets_coingecko_com-ftt.jpg?1607567062'
  },
  {
    chainId: 1,
    address: '0xa4cf2afd3b165975afffbf7e487cdd40c894ab6b',
    name: 'Shibaken Finance',
    symbol: 'SHIBAKEN',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/15413/thumb/shibaken-logo-new.png?1620772865'
  },
  {
    chainId: 1,
    address: '0x9f8f7ea504588a58b8b24b832b5d25a4aeb4706f',
    name: 'Celeum',
    symbol: 'CLX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10031/thumb/celeum.png?1578022943'
  },
  {
    chainId: 1,
    address: '0x4f76e85d067e219779a863ff18577846b3152f1f',
    name: 'KuboCoin',
    symbol: 'KUBO',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/6895/thumb/Presentatie_LOGO.png?1578975206'
  },
  {
    chainId: 1,
    address: '0xa1d65e8fb6e87b60feccbc582f7f97804b725521',
    name: 'DXdao',
    symbol: 'DXD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11148/thumb/dxdao.png?1607999331'
  },
  {
    chainId: 1,
    address: '0xd20bcbd56d9d551cac10a6bc2a83635bfb72f3f4',
    name: 'FUNDChains',
    symbol: 'FUND',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/6079/thumb/fundchains-logo.png?1547042057'
  },
  {
    chainId: 1,
    address: '0x668dbf100635f593a3847c0bdaf21f0a09380188',
    name: 'BNSD Finance',
    symbol: 'BNSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12368/thumb/bnsd.png?1599358388'
  },
  {
    chainId: 1,
    address: '0x6468e79a80c0eab0f9a2b574c8d5bc374af59414',
    name: 'e Radix',
    symbol: 'EXRD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13145/thumb/exrd_logo.png?1605662677'
  },
  {
    chainId: 1,
    address: '0x0f1ed66c251bcb52ecf7e67ac64bb72482048adb',
    name: 'Seer',
    symbol: 'SEER',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4824/thumb/seer.png?1548608916'
  },
  {
    chainId: 1,
    address: '0xff0e5e014cf97e0615cb50f6f39da6388e2fae6e',
    name: 'Origo',
    symbol: 'OGO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4377/thumb/Origo_Logo_png.png?1580430001'
  },
  {
    chainId: 1,
    address: '0x26c8afbbfe1ebaca03c2bb082e69d0476bffe099',
    name: 'Cellframe',
    symbol: 'CELL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14465/thumb/cellframe_logo.png?1616333567'
  },
  {
    chainId: 1,
    address: '0xaa7fb1c8ce6f18d4fd4aabb61a2193d4d441c54f',
    name: 'ShitCoin',
    symbol: 'SHIT',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/9472/thumb/ShitCoin_200x200.png?1567723695'
  },
  {
    chainId: 1,
    address: '0x667088b212ce3d06a1b553a7221e1fd19000d9af',
    name: 'Wings',
    symbol: 'WINGS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/648/thumb/wings.png?1548760631'
  },
  {
    chainId: 1,
    address: '0x8e12bd795d2608f90440c84dddd2d985240696d5',
    name: 'Isalcoin',
    symbol: 'ISAL',
    decimals: 7,
    logoURI: 'https://assets.coingecko.com/coins/images/13633/thumb/Untitled-design-6-removebg-preview-3.png?1610497846'
  },
  {
    chainId: 1,
    address: '0x1ad606adde97c0c28bd6ac85554176bc55783c01',
    name: 'Moonday Finance',
    symbol: 'MOONDAY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12890/thumb/qJWRxsz.jpeg?1603327151'
  },
  {
    chainId: 1,
    address: '0x8d75959f1e61ec2571aa72798237101f084de63a',
    name: 'Substratum',
    symbol: 'SUB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/950/thumb/substratum.png?1548611133'
  },
  {
    chainId: 1,
    address: '0xcfd6ae8bf13f42de14867351eaff7a8a3b9fbbe7',
    name: 'Sinergia',
    symbol: 'SNG',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/2156/thumb/sng.png?1547036457'
  },
  {
    chainId: 1,
    address: '0xe33f363351186e185470f35f6deb94aba44347d8',
    name: 'Basid Coin',
    symbol: 'BASID',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12566/thumb/basid-logo.png?1600827668'
  },
  {
    chainId: 1,
    address: '0x68a3637ba6e75c0f66b61a42639c4e9fcd3d4824',
    name: 'MoonSwap',
    symbol: 'MOON',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12441/thumb/moon.jpg?1599880968'
  },
  {
    chainId: 1,
    address: '0xd9c2d319cd7e6177336b0a9c93c21cb48d84fb54',
    name: 'HAPI',
    symbol: 'HAPI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14298/thumb/R9i2HjAL_400x400.jpg?1615332438'
  },
  {
    chainId: 1,
    address: '0xd46df541148932690b81092f600f35208afd4325',
    name: 'Prism Network',
    symbol: 'PRISM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15137/thumb/logo-prism-network.png?1619771407'
  },
  {
    chainId: 1,
    address: '0x96184d9c811ea0624fc30c80233b1d749b9e485b',
    name: 'Dapp com',
    symbol: 'DAPPT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8282/thumb/dappt_logo.png?1593074788'
  },
  {
    chainId: 1,
    address: '0x159a1dfae19057de57dfffcbb3da1ae784678965',
    name: 'Reflex',
    symbol: 'RFX',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12745/thumb/2MKGMRCT_400x400.png?1602194456'
  },
  {
    chainId: 1,
    address: '0x2a1174d1cd4348cb1eaec3f00310908ca289e5be',
    name: 'Exgold',
    symbol: 'EXG',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/14068/thumb/-HgHqzsy_400x400.png?1614137624'
  },
  {
    chainId: 1,
    address: '0x05bbe7240de66f6480c9aeda77c1376b13393f83',
    name: 'Xeno Token',
    symbol: 'XNO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13830/thumb/XNO_logo_200x200.png?1612168285'
  },
  {
    chainId: 1,
    address: '0xc28e27870558cf22add83540d2126da2e4b464c2',
    name: 'Sashimi',
    symbol: 'SASHIMI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12427/thumb/SashimiSwap-200x200.png?1601347413'
  },
  {
    chainId: 1,
    address: '0x94e0bab2f6ab1f19f4750e42d7349f2740513ad5',
    name: 'Unicly',
    symbol: 'UNIC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14720/thumb/Unicly.png?1621132059'
  },
  {
    chainId: 1,
    address: '0xf938424f7210f31df2aee3011291b658f872e91e',
    name: 'Visor',
    symbol: 'VISR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14381/thumb/visor_logo.png?1615782828'
  },
  {
    chainId: 1,
    address: '0x126c121f99e1e211df2e5f8de2d96fa36647c855',
    name: 'DEGEN Index',
    symbol: 'DEGEN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14143/thumb/alpha_logo.png?1614651244'
  },
  {
    chainId: 1,
    address: '0xb83cd8d39462b761bb0092437d38b37812dd80a2',
    name: 'Golden Ratio Token',
    symbol: 'GRT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11022/thumb/golden_ratio_token.png?1592811112'
  },
  {
    chainId: 1,
    address: '0xfe69bc0920fb63c5924cfc322dc4a5cc23d9afed',
    name: 'LYZE',
    symbol: 'LZE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7514/thumb/bO6XPX1N_400x400.jpg?1548126342'
  },
  {
    chainId: 1,
    address: '0x04abeda201850ac0124161f037efd70c74ddc74c',
    name: 'Nest Protocol',
    symbol: 'NEST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11284/thumb/52954052.png?1589868539'
  },
  {
    chainId: 1,
    address: '0x08ad83d779bdf2bbe1ad9cc0f78aa0d24ab97802',
    name: 'Robonomics Web Serv',
    symbol: 'RWS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11885/thumb/rws_logo.png?1595745253'
  },
  {
    chainId: 1,
    address: '0x73104e9d3da91e410a6c211068f7bffabbbd3e26',
    name: 'ETH Moonshot X Set',
    symbol: 'ETHMOONX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10583/thumb/eth_moonshot_x.png?1580960848'
  },
  {
    chainId: 1,
    address: '0x432bf73443909c33b545efed536a5246c9a722ca',
    name: 'Poverty Eradication',
    symbol: 'PEC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8519/thumb/pec_2.jpg?1559100388'
  },
  {
    chainId: 1,
    address: '0xc37e8a31ba2d110c12f09f0239954a68b00bc599',
    name: 'EUB Chain',
    symbol: 'EUBC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/6547/thumb/j2Lj7uGH_400x400.jpg?1547042762'
  },
  {
    chainId: 1,
    address: '0x9ad03c34aab604a9e0fde41dbf8e383e11c416c4',
    name: 'Guarded Ether',
    symbol: 'GETH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14001/thumb/guarda-shield-logo.png?1613603915'
  },
  {
    chainId: 1,
    address: '0x983f6d60db79ea8ca4eb9968c6aff8cfa04b3c63',
    name: 'SONM',
    symbol: 'SNM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/861/thumb/sonm.png?1548609871'
  },
  {
    chainId: 1,
    address: '0xa4ef4b0b23c1fc81d3f9ecf93510e64f58a4a016',
    name: '1MillionNFTs',
    symbol: '1MIL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14990/thumb/4kQ8hRnU_400x400.jpg?1619334818'
  },
  {
    chainId: 1,
    address: '0xd0df51cec800d1f8045722377f6faceba8d15a4d',
    name: 'The Hash Speed',
    symbol: 'THS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12184/thumb/1_4x.png?1597960402'
  },
  {
    chainId: 1,
    address: '0x04ab43d32d0172c76f5287b6619f0aa50af89303',
    name: 'Unilock Network',
    symbol: 'UNL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13381/thumb/unilock_logo.png?1608074688'
  },
  {
    chainId: 1,
    address: '0x61d24aabb3e5e800d8f3d3d43dcbd66ae6cab51e',
    name: 'Rabbit Coin',
    symbol: 'BRB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8614/thumb/312c7330-ffbb-4a29-9cd0-7336170bb8f9.png?1559628544'
  },
  {
    chainId: 1,
    address: '0x0a913bead80f321e7ac35285ee10d9d922659cb7',
    name: 'DOS Network',
    symbol: 'DOS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7991/thumb/DOS.png?1552900889'
  },
  {
    chainId: 1,
    address: '0x36f3fd68e7325a35eb768f1aedaae9ea0689d723',
    name: 'Empty Set Dollar',
    symbol: 'ESD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12440/thumb/esd_logo_circle.png?1603676421'
  },
  {
    chainId: 1,
    address: '0x9aab071b4129b083b01cb5a0cb513ce7eca26fa5',
    name: 'HUNT',
    symbol: 'HUNT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7989/thumb/HUNT.png?1571024256'
  },
  {
    chainId: 1,
    address: '0xc350e846e2c57f9eece90febc253d14c8080871b',
    name: 'SRH',
    symbol: 'SRH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2051/thumb/srcoin.png?1548610836'
  },
  {
    chainId: 1,
    address: '0x00d8318e44780edeefcf3020a5448f636788883c',
    name: 'dAppstore',
    symbol: 'DAPPX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15780/thumb/large-logo.png?1621841062'
  },
  {
    chainId: 1,
    address: '0x8ba009cad493c7646e31d69428ab9a54f47b3779',
    name: 'VirgoX Token',
    symbol: 'VXT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12510/thumb/VIrgoX_logo.png?1600354936'
  },
  {
    chainId: 1,
    address: '0xf028adee51533b1b47beaa890feb54a457f51e89',
    name: 'BMCHAIN token',
    symbol: 'BMT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7419/thumb/bchain_logo.jpg?1547199925'
  },
  {
    chainId: 1,
    address: '0x457a57e931cba4dff5ce8500cc0dff18356df041',
    name: 'Phantom Token',
    symbol: 'PHTF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13844/thumb/44286840.png?1612312790'
  },
  {
    chainId: 1,
    address: '0xec7d3e835da3f6118079fa9a236b267d044fd7ca',
    name: 'Crypto Rewards Stud',
    symbol: 'CRS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13252/thumb/Circle_%28JPG%29.jpg?1612947294'
  },
  {
    chainId: 1,
    address: '0x305de070488c8469dfac957226c9c900c4bfba22',
    name: 'More Coin',
    symbol: 'MORE',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/742/thumb/1722.png?1620080666'
  },
  {
    chainId: 1,
    address: '0x3166c570935a7d8554c8f4ea792ff965d2efe1f2',
    name: 'Q DAO Governance to',
    symbol: 'QDAO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8599/thumb/QDAO_logo_white_black.png?1562131656'
  },
  {
    chainId: 1,
    address: '0xd487892bb4c57edbe7ab401d9fe801c8fe6473f5',
    name: 'Uhive',
    symbol: 'HVE2',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3811/thumb/NEW_Uhive_Logo.png?1616500156'
  },
  {
    chainId: 1,
    address: '0xfcfc434ee5bff924222e084a8876eee74ea7cfba',
    name: 'Rebasing Liquidity',
    symbol: 'DELTARLP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14583/thumb/delta_financial.jpg?1617150170'
  },
  {
    chainId: 1,
    address: '0x02eca910cb3a7d43ebc7e8028652ed5c6b70259b',
    name: 'Pteria',
    symbol: 'PTERIA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12989/thumb/Pteria.png?1604105704'
  },
  {
    chainId: 1,
    address: '0xba7b2c094c1a4757f9534a37d296a3bed7f544dc',
    name: 'HLand Token',
    symbol: 'HLAND',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13385/thumb/HLAND_LOGO.png?1608085636'
  },
  {
    chainId: 1,
    address: '0x9f549ebfd4974cd4ed4a1550d40394b44a7382aa',
    name: 'LinkCoin Token',
    symbol: 'LKN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6098/thumb/vWhhedXQ.png?1547042094'
  },
  {
    chainId: 1,
    address: '0x83cee9e086a77e492ee0bb93c2b0437ad6fdeccc',
    name: 'Goldmint',
    symbol: 'MNTP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1944/thumb/goldmint.png?1547743002'
  },
  {
    chainId: 1,
    address: '0xe64509f0bf07ce2d29a7ef19a8a9bc065477c1b4',
    name: 'PiplCoin',
    symbol: 'PIPL',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/1017/thumb/piplcoin.png?1548331081'
  },
  {
    chainId: 1,
    address: '0x9669890e48f330acd88b78d63e1a6b3482652cd9',
    name: 'Bincentive',
    symbol: 'BCNT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9661/thumb/XfDF7Gqv_400x400.jpg?1570484117'
  },
  {
    chainId: 1,
    address: '0x89dcff5fd892f2bfc8b75dba12804b651f769579',
    name: 'Staker DAO',
    symbol: 'STKR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14535/thumb/stakerdao_logo.png?1616812877'
  },
  {
    chainId: 1,
    address: '0xa7f976c360ebbed4465c2855684d1aae5271efa9',
    name: 'TrueFlip',
    symbol: 'TFL',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/940/thumb/trueflip.png?1548759136'
  },
  {
    chainId: 1,
    address: '0xc11551bb497875050b69a2fdccc20a53a9a70263',
    name: 'TenXCoin',
    symbol: 'TXC',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/7518/thumb/38759388_305248313385249_2251789437044260864_n.png?1548146715'
  },
  {
    chainId: 1,
    address: '0x7bd6a4e7db3a34c485a8dd02b30b6565e3bbc633',
    name: 'KOK Coin',
    symbol: 'KOK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10502/thumb/logo_navy.png?1580250344'
  },
  {
    chainId: 1,
    address: '0xdc5864ede28bd4405aa04d93e05a0531797d9d59',
    name: 'Falcon Project',
    symbol: 'FNT',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/11579/thumb/falcon_ava_black.png?1591317863'
  },
  {
    chainId: 1,
    address: '0x3cc5eb07e0e1227613f1df58f38b549823d11cb9',
    name: 'Ethereum eRush',
    symbol: 'EER',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/10695/thumb/0x6f371ca338bbddd0baf719e1d5d0797cce20774f.png?1582153688'
  },
  {
    chainId: 1,
    address: '0x87f14e9460cecb789f1b125b2e3e353ff8ed6fcd',
    name: 'Bytus',
    symbol: 'BYTS',
    decimals: 3,
    logoURI: 'https://assets.coingecko.com/coins/images/7763/thumb/bytus.png?1588560119'
  },
  {
    chainId: 1,
    address: '0x5319e86f0e41a06e49eb37046b8c11d78bcad68c',
    name: 'Zelwin',
    symbol: 'ZLW',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11547/thumb/5614.png?1590991128'
  },
  {
    chainId: 1,
    address: '0xf6923f7d96fc22c4b8010a865e41cf7edfb6379c',
    name: 'IOOX System',
    symbol: 'IOOX',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11767/thumb/R7PBzPuN_400x400.jpg?1593662861'
  },
  {
    chainId: 1,
    address: '0xebf4ca5319f406602eeff68da16261f1216011b5',
    name: 'Yobit Token',
    symbol: 'YO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9231/thumb/ybx_logo.gif?1565306320'
  },
  {
    chainId: 1,
    address: '0x0bc61dded5f6710c637cf8288eb6058766ce1921',
    name: 'Coinsuper Ecosystem',
    symbol: 'CEN',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/5215/thumb/coinsuper-ecosystem-network-token-logo.png?1547040682'
  },
  {
    chainId: 1,
    address: '0x78b039921e84e726eb72e7b1212bb35504c645ca',
    name: 'Sether',
    symbol: 'SETH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2101/thumb/sether.png?1548609212'
  },
  {
    chainId: 1,
    address: '0x7dc59729b0adf4ae34721a1e06ef82a19e690b04',
    name: 'BTC Alpha Token',
    symbol: 'BAC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/8631/thumb/l.NkwdggrR1hkSqghZ.png?1559791553'
  },
  {
    chainId: 1,
    address: '0x957891c11616d3e0b0a76a76fb42724c382e0ef3',
    name: 'Collateral Pay',
    symbol: 'COLL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14682/thumb/cpay.jpg?1617767452'
  },
  {
    chainId: 1,
    address: '0xf6117cc92d7247f605f11d4c942f0feda3399cb5',
    name: 'Multicoin',
    symbol: 'MTCN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3072/thumb/Group-42.71-1.png?1557815880'
  },
  {
    chainId: 1,
    address: '0xc6c6224cf32f5b0850ddf740b47cd1ed31abead4',
    name: 'KUPP',
    symbol: 'KUPP',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/14705/thumb/kupp_icon.png?1617859709'
  },
  {
    chainId: 1,
    address: '0xc56b13ebbcffa67cfb7979b900b736b3fb480d78',
    name: 'Social Activity Tok',
    symbol: 'SAT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/2586/thumb/sat.png?1547036721'
  },
  {
    chainId: 1,
    address: '0x3aada3e213abf8529606924d8d1c55cbdc70bf74',
    name: 'XMON',
    symbol: 'XMON',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14008/thumb/xmon_logo.png?1613615094'
  },
  {
    chainId: 1,
    address: '0xe60b3fcbd8f400a38476adeb01fcac861ccd2e42',
    name: 'Connect Mining Toke',
    symbol: 'XCMG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12160/thumb/dSwIPorh_400x400.png?1597704191'
  },
  {
    chainId: 1,
    address: '0xe0b9bcd54bf8a730ea5d3f1ffce0885e911a502c',
    name: 'ZUM TOKEN',
    symbol: 'ZUM',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9721/thumb/zum256x256.png?1571264005'
  },
  {
    chainId: 1,
    address: '0x7c9d8fb3bde3d9ea6e89170618c2dc3d16695d36',
    name: 'WhiteRockCasino',
    symbol: 'WRC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11962/thumb/cwSeVyY.png?1605338879'
  },
  {
    chainId: 1,
    address: '0xa02120696c7b8fe16c09c749e4598819b2b0e915',
    name: 'Wirex',
    symbol: 'WXT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8835/thumb/Wirex.jpg?1561970529'
  },
  {
    chainId: 1,
    address: '0x1e3a2446c729d34373b87fd2c9cbb39a93198658',
    name: 'DeFi Nation Signals',
    symbol: 'DSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11898/thumb/iLgw2f82_400x400.png?1596078737'
  },
  {
    chainId: 1,
    address: '0x608f006b6813f97097372d0d31fb0f11d1ca3e4e',
    name: 'CryptoAds Marketpla',
    symbol: 'CRAD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3761/thumb/crad.png?1547038854'
  },
  {
    chainId: 1,
    address: '0x2cf2f4e07ecc54740293df6d6fb4150d725a919f',
    name: 'Winsor Token',
    symbol: 'WST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10827/thumb/Untitled-3-01_i8l0qn.jpg?1584340349'
  },
  {
    chainId: 1,
    address: '0x46760d2bf2f4dd5405646d9b2ce7b723efe74a48',
    name: 'Penny For Bit',
    symbol: 'PFB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15741/thumb/PFB-token-logo-200.png?1621688472'
  },
  {
    chainId: 1,
    address: '0x00006100f7090010005f1bd7ae6122c3c2cf0090',
    name: 'TrueAUD',
    symbol: 'TAUD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10146/thumb/TAUD.png?1576466892'
  },
  {
    chainId: 1,
    address: '0x7afb39837fd244a651e4f0c5660b4037214d4adf',
    name: 'Soda Token',
    symbol: 'SODA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12546/thumb/soda.acd4d701.png?1600671234'
  },
  {
    chainId: 1,
    address: '0xb1ec548f296270bc96b8a1b3b3c8f3f04b494215',
    name: 'Foresight',
    symbol: 'FORS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12374/thumb/foresight_logo.jpg?1599389915'
  },
  {
    chainId: 1,
    address: '0x7a3c45f34ea5df6fe5f6af710ec8a04d388a71d1',
    name: 'Pando',
    symbol: 'PANDO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14150/thumb/pando_logo.png?1614667045'
  },
  {
    chainId: 1,
    address: '0xe6404a4472e5222b440f8fafb795553046000841',
    name: 'BTC Long Only Alpha',
    symbol: 'BLOAP',
    decimals: 18
  },
  {
    chainId: 1,
    address: '0xfca59cd816ab1ead66534d82bc21e7515ce441cf',
    name: 'Rarible',
    symbol: 'RARI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11845/thumb/Rari.png?1594946953'
  },
  {
    chainId: 1,
    address: '0x0557df767419296474c3f551bb0a0ed4c2dd3380',
    name: 'Universal Gold',
    symbol: 'UPXAU',
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/13534/thumb/UPXAU-1.png?1609540908'
  },
  {
    chainId: 1,
    address: '0xcf9c692f7e62af3c571d4173fd4abf9a3e5330d0',
    name: 'Onigiri',
    symbol: 'ONIGIRI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12699/thumb/onigiri-coingecko.png?1601857724'
  },
  {
    chainId: 1,
    address: '0xf411903cbc70a74d22900a5de66a2dda66507255',
    name: 'Verasity',
    symbol: 'VRA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14025/thumb/VRA.jpg?1613797653'
  },
  {
    chainId: 1,
    address: '0x999967e2ec8a74b7c8e9db19e039d920b31d39d0',
    name: 'Ties DB',
    symbol: 'TIE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1142/thumb/ties-network.jpg?1547035148'
  },
  {
    chainId: 1,
    address: '0xf921ae2dac5fa128dc0f6168bf153ea0943d2d43',
    name: 'Fire Protocol',
    symbol: 'FIRE',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13495/thumb/fire.jpg?1609165121'
  },
  {
    chainId: 1,
    address: '0x3fd2e747cea0e8a78f1827ea2ffd3334628a600b',
    name: 'Banklife',
    symbol: 'LIB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9937/thumb/BBcbNG1w_400x400.jpg?1573594562'
  },
  {
    chainId: 1,
    address: '0x07de533cbc72395c32eb8981ddccd2cc6a6e1c4e',
    name: 'Technology Innovati',
    symbol: 'TIP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13681/thumb/KRW_TIP.png?1610835212'
  },
  {
    chainId: 1,
    address: '0xdd6c68bb32462e01705011a4e2ad1a60740f217f',
    name: 'Hubii Network',
    symbol: 'HBT',
    decimals: 15,
    logoURI: 'https://assets.coingecko.com/coins/images/994/thumb/hubii-network.png?1547744064'
  },
  {
    chainId: 1,
    address: '0x221657776846890989a759ba2973e427dff5c9bb',
    name: 'Augur',
    symbol: 'REP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/309/thumb/REP.png?1596339859'
  },
  {
    chainId: 1,
    address: '0xd2dda223b2617cb616c1580db421e4cfae6a8a85',
    name: 'Bondly',
    symbol: 'BONDLY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13322/thumb/logomark.png?1607474416'
  },
  {
    chainId: 1,
    address: '0xacbed9726ffd232b59d3ca86a0f5c856e2abef29',
    name: 'Debunk',
    symbol: 'DBNK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13930/thumb/77926469.jpg?1612908627'
  },
  {
    chainId: 1,
    address: '0xbdbc2a5b32f3a5141acd18c39883066e4dab9774',
    name: 'Emirex Token',
    symbol: 'EMRX',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9507/thumb/download.png?1568589320'
  },
  {
    chainId: 1,
    address: '0x85eba557c06c348395fd49e35d860f58a4f7c95a',
    name: 'H3X',
    symbol: 'H3X',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11554/thumb/Ps3O6GY.png?1591058696'
  },
  {
    chainId: 1,
    address: '0xc12d1c73ee7dc3615ba4e37e4abfdbddfa38907e',
    name: 'KickToken',
    symbol: 'KICK',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/981/thumb/kick.png?1568643013'
  },
  {
    chainId: 1,
    address: '0x26fb86579e371c7aedc461b2ddef0a8628c93d3b',
    name: 'BORA',
    symbol: 'BORA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7646/thumb/LDqRyofe_400x400.jpg?1548926877'
  },
  {
    chainId: 1,
    address: '0xedd8da5c20eb014e550008df3304213dde5e29f0',
    name: 'Mars Network',
    symbol: 'MARS',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12828/thumb/Mars-logo-2.jpg?1602832489'
  },
  {
    chainId: 1,
    address: '0xd35ce4fd815522ff52243eb587020d557617f4db',
    name: 'DFE Finance',
    symbol: 'DFE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13682/thumb/200x200DFE.png?1610835715'
  },
  {
    chainId: 1,
    address: '0x5a705745373a780814c379ef17810630d529efe0',
    name: 'Project Senpai',
    symbol: 'SENPAI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14702/thumb/mrWvpa_J_400x400.jpg?1617849859'
  },
  {
    chainId: 1,
    address: '0x8b83116e05f722554e1089b9850e731ee20dd692',
    name: 'ZCNOX Coin',
    symbol: 'ZCNOX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10857/thumb/QP1B-kMg_400x400_%281%29.jpg?1585523407'
  },
  {
    chainId: 1,
    address: '0xa8b12cc90abf65191532a12bb5394a714a46d358',
    name: 'pBTC35A',
    symbol: 'PBTC35A',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13653/thumb/pBTC35A.png?1610574940'
  },
  {
    chainId: 1,
    address: '0x0488401c3f535193fa8df029d9ffe615a06e74e6',
    name: 'SparkPoint',
    symbol: 'SRK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8371/thumb/SRK.png?1614675526'
  },
  {
    chainId: 1,
    address: '0x7de91b204c1c737bcee6f000aaa6569cf7061cb7',
    name: 'Robonomics Network',
    symbol: 'XRT',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/7024/thumb/Robonomics-Network-logo.png?1547043451'
  },
  {
    chainId: 1,
    address: '0x2c82c73d5b34aa015989462b2948cd616a37641f',
    name: 'Spectre ai Utility ',
    symbol: 'SXUT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2551/thumb/spectre-utility.png?1547036668'
  },
  {
    chainId: 1,
    address: '0x5dced3c2fab61e21b25177c6050d3f166f696110',
    name: 'Tea Token',
    symbol: 'TEA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14426/thumb/teachain-logo.png?1616031044'
  },
  {
    chainId: 1,
    address: '0x4c10bd19688b906665fbd53415f279f34b44ece7',
    name: 'YUI Finance',
    symbol: 'YUI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13153/thumb/YUI.png?1605751023'
  },
  {
    chainId: 1,
    address: '0x8317b216d7c3f9a5b8401e4b6814d13a7be390ec',
    name: 'EXNCE',
    symbol: 'XNC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9683/thumb/logo_icon.png?1570684903'
  },
  {
    chainId: 1,
    address: '0x27f610bf36eca0939093343ac28b1534a721dbb4',
    name: 'WandX',
    symbol: 'WAND',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2219/thumb/wandx.png?1548760294'
  },
  {
    chainId: 1,
    address: '0xf93340b1a3adf7eedcaec25fae8171d4b736e89f',
    name: 'pxUSD Synthetic USD',
    symbol: 'PXUSD-MAR2021',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12936/thumb/perlin.png?1603720296'
  },
  {
    chainId: 1,
    address: '0xf552b656022c218c26dad43ad88881fc04116f76',
    name: 'MORK',
    symbol: 'MORK',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/12183/thumb/mork-logo.png?1597941710'
  },
  {
    chainId: 1,
    address: '0x9fc8f0ca1668e87294941b7f627e9c15ea06b459',
    name: 'True PNL',
    symbol: 'PNL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15282/thumb/pnl.jpeg?1621860320'
  },
  {
    chainId: 1,
    address: '0x1f8a626883d7724dbd59ef51cbd4bf1cf2016d13',
    name: 'Jigstack',
    symbol: 'STAK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14978/thumb/jigstack.PNG?1619216498'
  },
  {
    chainId: 1,
    address: '0xd9a8cfe21c232d485065cb62a96866799d4645f7',
    name: 'FingerPrint',
    symbol: 'FGP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5454/thumb/fingerprint.png?1561602642'
  },
  {
    chainId: 1,
    address: '0x61266424b904d65ceb2945a1413ac322185187d5',
    name: 'YFIDapp',
    symbol: 'YFID',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13309/thumb/Untitled-design-9-removebg-preview.png?1607328419'
  },
  {
    chainId: 1,
    address: '0x675bbc7514013e2073db7a919f6e4cbef576de37',
    name: 'Coldstack',
    symbol: 'CLS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15499/thumb/logo_200x200.png?1621072403'
  },
  {
    chainId: 1,
    address: '0x8f041a3940a5e6fb580075c3774e15fcfa0e1618',
    name: 'oneWING',
    symbol: 'ONEWING',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15103/thumb/logo_200_wing.png?1619731848'
  },
  {
    chainId: 1,
    address: '0xcbe7142f5c16755d8683ba329efa1abf7b54482d',
    name: 'MedicalVeda',
    symbol: 'MVEDA',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13069/thumb/medicalveda_new_logo_final_%281%29.png?1604894690'
  },
  {
    chainId: 1,
    address: '0x2578a20a07e8761d91d0961d3ea92e14510885aa',
    name: 'COVIR',
    symbol: 'CVR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12319/thumb/Auxy2Y7.png?1599093847'
  },
  {
    chainId: 1,
    address: '0x2186ecb39f1b765ba7d78f1c43c2e9d7fc0c1eca',
    name: 'My Crypto Play',
    symbol: 'MCP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13570/thumb/Ysv6EyvR_400x400.jpg?1609845061'
  },
  {
    chainId: 1,
    address: '0x6ec8a24cabdc339a06a172f8223ea557055adaa5',
    name: 'Genaro Network',
    symbol: 'GNX',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/1361/thumb/genaro-network.png?1547035387'
  },
  {
    chainId: 1,
    address: '0xdde12a12a6f67156e0da672be05c374e1b0a3e57',
    name: 'JOYSO',
    symbol: 'JOY',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/2820/thumb/joy.png?1547036990'
  },
  {
    chainId: 1,
    address: '0xfa2562da1bba7b954f26c74725df51fb62646313',
    name: 'ASSY Index',
    symbol: 'ASSY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13747/thumb/assy.png?1611425796'
  },
  {
    chainId: 1,
    address: '0xdef1da03061ddd2a5ef6c59220c135dec623116d',
    name: 'Lepricon',
    symbol: 'L3P',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14436/thumb/yZmVlPJP_400x400.jpg?1616053077'
  },
  {
    chainId: 1,
    address: '0x2a9bdcff37ab68b95a53435adfd8892e86084f93',
    name: 'Alpha Quark Token',
    symbol: 'AQT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12872/thumb/alpha_quark_logo.png?1605169527'
  },
  {
    chainId: 1,
    address: '0xf6fd82dedbbe0ffadb5e1ecc2a283ab52b9ed2b0',
    name: 'Etheal',
    symbol: 'HEAL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3267/thumb/heal.png?1547037807'
  },
  {
    chainId: 1,
    address: '0x467bccd9d29f223bce8043b84e8c8b282827790f',
    name: 'Telcoin',
    symbol: 'TEL',
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/1899/thumb/tel.png?1547036203'
  },
  {
    chainId: 1,
    address: '0x4cf488387f035ff08c371515562cba712f9015d4',
    name: 'WePower',
    symbol: 'WPR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1951/thumb/wpr.png?1547036237'
  },
  {
    chainId: 1,
    address: '0x1e41a55030e0d0794abfb6dced22e6c7d18d8247',
    name: 'Android chain',
    symbol: 'ADC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7656/thumb/logo_cn.png?1549011953'
  },
  {
    chainId: 1,
    address: '0xb62d18dea74045e822352ce4b3ee77319dc5ff2f',
    name: 'EventChain',
    symbol: 'EVC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1268/thumb/eventchain.png?1548125671'
  },
  {
    chainId: 1,
    address: '0xf0c6521b1f8ad9c33a99aaf056f6c6247a3862ba',
    name: 'ETH limiteD',
    symbol: 'ELD',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/13413/thumb/Untitled-design-6-removebg-preview_%281%29.png?1608275516'
  },
  {
    chainId: 1,
    address: '0x27201232579491ce9b116ac6f37d354cc723a2f3',
    name: 'MESEFA',
    symbol: 'SEFA',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10977/thumb/cPEdYbm1_400x400.png?1586758998'
  },
  {
    chainId: 1,
    address: '0xdacd69347de42babfaecd09dc88958378780fb62',
    name: 'Atari',
    symbol: 'ATRI',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/12992/thumb/atari.png?1604212345'
  },
  {
    chainId: 1,
    address: '0x7671904eed7f10808b664fc30bb8693fd7237abf',
    name: 'Bitberry Token',
    symbol: 'BBR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13673/thumb/1_AdMyBccrRv0e6rhW7UKJSw.png?1610681228'
  },
  {
    chainId: 1,
    address: '0x423f3da166470030a86fa76280b2d688fce1f722',
    name: 'Aquila Protocol',
    symbol: 'AUX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12087/thumb/bfb90fWG_400x400.jpg?1597016683'
  },
  {
    chainId: 1,
    address: '0xf16e81dce15b08f326220742020379b855b87df9',
    name: 'Popsicle Finance',
    symbol: 'ICE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14586/thumb/ice.png?1617188825'
  },
  {
    chainId: 1,
    address: '0x3593d125a4f7849a1b059e64f4517a86dd60c95d',
    name: 'MANTRA DAO',
    symbol: 'OM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12151/thumb/OM_3D_whtbg.png?1598332353'
  },
  {
    chainId: 1,
    address: '0x217ddead61a42369a266f1fb754eb5d3ebadc88a',
    name: 'Don key',
    symbol: 'DON',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15482/thumb/donkey_logo.jpg?1621012824'
  },
  {
    chainId: 1,
    address: '0x130914e1b240a7f4c5d460b7d3a2fd3846b576fa',
    name: 'Aureus Nummus Gold',
    symbol: 'ANG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12134/thumb/AureusNummusGold-logo.png?1597462266'
  },
  {
    chainId: 1,
    address: '0x6913ccabbc337f0ea7b4109dd8200d61c704d332',
    name: 'Asac Coin',
    symbol: 'ASAC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10738/thumb/NiGQ7aKr_400x400.jpg?1582778597'
  },
  {
    chainId: 1,
    address: '0x786448439d9401e0a8427acf7ca66a5114eb2368',
    name: 'Dipper',
    symbol: 'DIP',
    decimals: 8,
    logoURI:
      'https://assets.coingecko.com/coins/images/13498/thumb/200x200-format%C4%B1-arka-fon-yok_%281%29.png?1609194317'
  },
  {
    chainId: 1,
    address: '0xeb2c0e11af20fb1c41c6e7abe5ad214e48738514',
    name: 'Sinelock',
    symbol: 'SINE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13984/thumb/q3rzuFnH_400x400.jpg?1613463180'
  },
  {
    chainId: 1,
    address: '0xfa14fa6958401314851a17d6c5360ca29f74b57b',
    name: 'Saito',
    symbol: 'SAITO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14750/thumb/QXqiVfCy_400x400.png?1618126310'
  },
  {
    chainId: 1,
    address: '0xa2b4c0af19cc16a6cfacce81f192b024d625817d',
    name: 'Kishu Inu',
    symbol: 'KISHU',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14890/thumb/photo_2021-04-19_07-25-38.jpg?1618906377'
  },
  {
    chainId: 1,
    address: '0x35a79fceb867ee3392ed0c8dedd8dc2f6124c9cd',
    name: 'SPIDER ECOLOGY',
    symbol: 'ESPI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10075/thumb/logo_%2853%29.png?1575501034'
  },
  {
    chainId: 1,
    address: '0xef7f1aae6f60de9f353dc170a35b8f7c7814e32b',
    name: 'AZ Fundchain',
    symbol: 'AZT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7461/thumb/aPOa3zi6_400x400.jpg?1567983821'
  },
  {
    chainId: 1,
    address: '0x846c66cf71c43f80403b51fe3906b3599d63336f',
    name: 'PumaPay',
    symbol: 'PMA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2307/thumb/pumapay.png?1548607436'
  },
  {
    chainId: 1,
    address: '0x50d1c9771902476076ecfc8b2a83ad6b9355a4c9',
    name: 'FTX Token',
    symbol: 'FTT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9026/thumb/F.png?1609051564'
  },
  {
    chainId: 1,
    address: '0x74159651a992952e2bf340d7628459aa4593fc05',
    name: 'Tenet',
    symbol: 'TEN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13545/thumb/iMqC3F_p_400x400.png?1609711856'
  },
  {
    chainId: 1,
    address: '0x850aab69f0e0171a9a49db8be3e71351c8247df4',
    name: 'Konomi Network',
    symbol: 'KONO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14406/thumb/konomi.jpg?1615907763'
  },
  {
    chainId: 1,
    address: '0xbe9375c6a420d2eeb258962efb95551a5b722803',
    name: 'StormX',
    symbol: 'STMX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1369/thumb/StormX.png?1603113002'
  },
  {
    chainId: 1,
    address: '0xe6410569602124506658ff992f258616ea2d4a3d',
    name: 'Katana Finance',
    symbol: 'KATANA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12473/thumb/AyHMMbK.jpeg?1600124081'
  },
  {
    chainId: 1,
    address: '0xb020ed54651831878e5c967e0953a900786178f9',
    name: 'Baz Token',
    symbol: 'BAZT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9581/thumb/baz.png?1576038883'
  },
  {
    chainId: 1,
    address: '0x55296f69f40ea6d20e478533c15a6b08b654e758',
    name: 'XYO Network',
    symbol: 'XYO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4519/thumb/XYO_Network-logo.png?1547039819'
  },
  {
    chainId: 1,
    address: '0xf0acf8949e705e0ebb6cb42c2164b0b986454223',
    name: 'Barter',
    symbol: 'BRTR',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13401/thumb/BRTR.png?1612843022'
  },
  {
    chainId: 1,
    address: '0x4a7adcb083fe5e3d6b58edc3d260e2e61668e7a2',
    name: 'Trade Butler Bot',
    symbol: 'TBB',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/13462/thumb/L9km5LC5ZKpTey0UKcTMt9xbXw_Q0Nq8F119_QjJlqLHvuxLK6vb_VjxHtXYczia0DHXHSxhtCFUCVyMBBxJNw_-tkS3FTpoeEFs7EHuxrxs7b2hV_se2JzisurH7YQmRjXIq3wG6Va6zv90ug_uRGeuk-VoAfck7rBdnoUCGL-Xfmz7AySAn6SUVToUCtwObez36TEADBc7AR9.jpg?1608766426'
  },
  {
    chainId: 1,
    address: '0xc969e16e63ff31ad4bcac3095c616644e6912d79',
    name: 'Seed Venture',
    symbol: 'SEED',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10860/thumb/Seed.png?1585204998'
  },
  {
    chainId: 1,
    address: '0x996229d0c6a485c7f4b52e092eaa907cb2def5c6',
    name: 'BuckHath Coin',
    symbol: 'BHIG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7821/thumb/logstex.PNG?1585121237'
  },
  {
    chainId: 1,
    address: '0x362bc847a3a9637d3af6624eec853618a43ed7d2',
    name: 'PARSIQ',
    symbol: 'PRQ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11973/thumb/DsNgK0O.png?1596590280'
  },
  {
    chainId: 1,
    address: '0xaaaebe6fe48e54f431b0c390cfaf0b017d09d42d',
    name: 'Celsius Network',
    symbol: 'CEL',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/3263/thumb/CEL_logo.png?1609598753'
  },
  {
    chainId: 1,
    address: '0x6c5024cd4f8a59110119c56f8933403a539555eb',
    name: 'Aave SUSD',
    symbol: 'ASUSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14266/thumb/aSUSD.9b00ffc6.png?1615528241'
  },
  {
    chainId: 1,
    address: '0x2f4eb47a1b1f4488c71fc10e39a4aa56af33dd49',
    name: 'UNCL',
    symbol: 'UNCL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13102/thumb/uncl_logo.png?1605230945'
  },
  {
    chainId: 1,
    address: '0x6e1a58e7e9e801f32bb82462636a8a2e7b65e036',
    name: 'LCG',
    symbol: 'LCG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12851/thumb/LCG_token.jpg?1603083545'
  },
  {
    chainId: 1,
    address: '0x70a6d0d1561ba98711e935a76b1c167c612978a2',
    name: 'Dragonfly Protocol',
    symbol: 'DFLY',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13416/thumb/dfly_logo.png?1608341835'
  },
  {
    chainId: 1,
    address: '0x12c5e73ddb44cd70225669b9f6f0d9de5455bc31',
    name: 'Idoneus Token',
    symbol: 'IDON',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11758/thumb/q063ApWE.png?1593571348'
  },
  {
    chainId: 1,
    address: '0xec67005c4e498ec7f55e092bd1d35cbc47c91892',
    name: 'Enzyme',
    symbol: 'MLN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/605/thumb/Enzyme_Icon_Secondary.png?1611576629'
  },
  {
    chainId: 1,
    address: '0xa6eb54102f20095679882db4c84e72e65ab782a4',
    name: 'Magnachain',
    symbol: 'MGC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/5938/thumb/Magnachain-Logo-150x150.jpg?1547041878'
  },
  {
    chainId: 1,
    address: '0xdd974d5c2e2928dea5f71b9825b8b646686bd200',
    name: 'Kyber Network Cryst',
    symbol: 'KNCL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/947/thumb/logo-kncl.png?1618984814'
  },
  {
    chainId: 1,
    address: '0x579cea1889991f68acc35ff5c3dd0621ff29b0c9',
    name: 'Everipedia',
    symbol: 'IQ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5010/thumb/everipedia.png?1561614871'
  },
  {
    chainId: 1,
    address: '0x0557e0d15aec0b9026dd17aa874fdf7d182a2ceb',
    name: 'CFX Quantum',
    symbol: 'CFXQ',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/15031/thumb/CFXQ_TOKEN_LOGO_200x200.png?1619500058'
  },
  {
    chainId: 1,
    address: '0xdd94842c15abfe4c9bafe4222ade02896beb064c',
    name: 'W Green Pay',
    symbol: 'WGP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4419/thumb/wgreenpay-01-400x300.png?1547039752'
  },
  {
    chainId: 1,
    address: '0xac6df26a590f08dcc95d5a4705ae8abbc88509ef',
    name: 'Aave ENJ',
    symbol: 'AENJ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14251/thumb/aENJ.ed76293b.png?1615528574'
  },
  {
    chainId: 1,
    address: '0x9903a4cd589da8e434f264deafc406836418578e',
    name: 'Harrison First',
    symbol: 'FIRST',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/12196/thumb/cc2016f6-0c74-4a95-b89b-b1684c7b9426.png?1597991823'
  },
  {
    chainId: 1,
    address: '0x79650799e7899a802cb96c0bc33a6a8d4ce4936c',
    name: 'AICHAIN',
    symbol: 'AIT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2525/thumb/aichain.png?1547036638'
  },
  {
    chainId: 1,
    address: '0x27ed129c298c5df130364083f491e2920e5a2f29',
    name: 'Upper Pound',
    symbol: 'GBPU',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12357/thumb/UPPER-moeda-pound.png?1599261441'
  },
  {
    chainId: 1,
    address: '0xcbe430927370e95b4b10cfc702c6017ec7abefc3',
    name: 'YD ETH JUN21',
    symbol: 'YD-ETH-JUN21',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14499/thumb/UMA_square_red_logo.png?1616557912'
  },
  {
    chainId: 1,
    address: '0xb8e2e2101ed11e9138803cd3e06e16dd19910647',
    name: 'ArdCoin',
    symbol: 'ARDX',
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/9432/thumb/bu6rbqr.png?1567398000'
  },
  {
    chainId: 1,
    address: '0x1b073382e63411e3bcffe90ac1b9a43fefa1ec6f',
    name: 'Bitpanda Ecosystem ',
    symbol: 'BEST',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/8738/thumb/BEST-Coin-Logo.png?1619079782'
  },
  {
    chainId: 1,
    address: '0xc77d7e0dd7b2a01b990e866feb21d031f1418c2e',
    name: 'Littlesesame',
    symbol: 'LSC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8893/thumb/5yIumMGs_400x400.png?1562579316'
  },
  {
    chainId: 1,
    address: '0x14468ff6b324f1c5a869e62b9c442846e7d0baf1',
    name: 'Maya Coin',
    symbol: 'MAYA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9325/thumb/coinlogo.png?1566266922'
  },
  {
    chainId: 1,
    address: '0x423b5f62b328d0d6d44870f4eee316befa0b2df5',
    name: 'GoNetwork',
    symbol: 'GOT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3291/thumb/gonetwork.png?1547743011'
  },
  {
    chainId: 1,
    address: '0xbbc2ae13b23d715c30720f079fcd9b4a74093505',
    name: 'Ethernity Chain',
    symbol: 'ERN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14238/thumb/ethernity_logo.png?1615189750'
  },
  {
    chainId: 1,
    address: '0x27702a26126e0b3702af63ee09ac4d1a084ef628',
    name: 'Aleph im',
    symbol: 'ALEPH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11676/thumb/Monochram-aleph.png?1608483725'
  },
  {
    chainId: 1,
    address: '0x172f20402afc807c8a5566bceed00831adb938ca',
    name: 'Golder Coin',
    symbol: 'GLDR',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/7455/thumb/46132863_341736356408664_5909841120593969152_n.png?1547542371'
  },
  {
    chainId: 1,
    address: '0x12fd19dac0fab61bed5e0f09091b470c452d4d61',
    name: 'Echoin',
    symbol: 'EC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9354/thumb/L32_KzNQ_400x400.jpg?1566513096'
  },
  {
    chainId: 1,
    address: '0xe0955f26515d22e347b17669993fcefcc73c3a0a',
    name: 'Stacker Ventures',
    symbol: 'STACK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14218/thumb/stack.jpg?1615000614'
  },
  {
    chainId: 1,
    address: '0x4aac461c86abfa71e9d00d9a2cde8d74e4e1aeea',
    name: 'ZINC',
    symbol: 'ZINC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5237/thumb/zinc-logo.jpg?1547040722'
  },
  {
    chainId: 1,
    address: '0x51bb9c623226ce781f4a54fc8f4a530a47142b6b',
    name: 'Peet DeFi',
    symbol: 'PTE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13100/thumb/peetdefi_logo.png?1605148557'
  },
  {
    chainId: 1,
    address: '0x265ba42daf2d20f3f358a7361d9f69cb4e28f0e6',
    name: 'Unibomb',
    symbol: 'UBOMB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11722/thumb/aLjLmGNT_400x400.png?1596603288'
  },
  {
    chainId: 1,
    address: '0x5fbdb42bb048c685c990a37f2c87fe087c586655',
    name: 'Xenon',
    symbol: 'XEN',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11105/thumb/DyNZKe79_400x400.jpg?1588568617'
  },
  {
    chainId: 1,
    address: '0x36d10c6800d569bb8c4fe284a05ffe3b752f972c',
    name: 'WeShow Token',
    symbol: 'WET',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5332/thumb/weshow-token-logo.jpg?1547040904'
  },
  {
    chainId: 1,
    address: '0x2858ed0225e9aa0638ccd9b552bee2712426fa24',
    name: 'Crypto Global Bank',
    symbol: 'CGB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8766/thumb/s8UlL0o.png?1560935402'
  },
  {
    chainId: 1,
    address: '0x888888877a18532b78d259577d00057054c50dd8',
    name: 'Universal Dollar',
    symbol: 'U8D',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13766/thumb/Gn8x1TZN_400x400.jpg?1611662713'
  },
  {
    chainId: 1,
    address: '0x7697b462a7c4ff5f8b55bdbc2f4076c2af9cf51a',
    name: 'Sarcophagus',
    symbol: 'SARCO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15091/thumb/sarco.PNG?1619667499'
  },
  {
    chainId: 1,
    address: '0x814e0908b12a99fecf5bc101bb5d0b8b5cdf7d26',
    name: 'Measurable Data Tok',
    symbol: 'MDT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2441/thumb/mdt_logo.png?1569813574'
  },
  {
    chainId: 1,
    address: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
    name: 'Sushi',
    symbol: 'SUSHI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12271/thumb/512x512_Logo_no_chop.png?1606986688'
  },
  {
    chainId: 1,
    address: '0x4c1e085d8c2d2a8377834d0d7b38f12cc5b86898',
    name: 'SilkChain',
    symbol: 'SILK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3810/thumb/2.png?1561537249'
  },
  {
    chainId: 1,
    address: '0x57c09a8de0b0f471f8567609777addffb5c46a08',
    name: 'Bitex Global XBX Co',
    symbol: 'XBX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4249/thumb/lI08UUfi_400x400.jpg?1547203502'
  },
  {
    chainId: 1,
    address: '0xa52383b665b91dce42dd4b6d1e0fb37d3effe489',
    name: 'MASTER USD',
    symbol: 'MUSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10063/thumb/wVgiR00.png?1575423238'
  },
  {
    chainId: 1,
    address: '0xe2fe5e7e206e7b46cad6a5146320e5b4b9a18e97',
    name: 'Bitcomo',
    symbol: 'BM',
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/4127/thumb/Webp.net-resizeimage_%2828%29.png?1547039349'
  },
  {
    chainId: 1,
    address: '0x094f00cb5e31ab6164e3cacb654e8d6c2b3b471c',
    name: 'ProSwap',
    symbol: 'PROS',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/15203/thumb/logo-icon_200x200-02.jpg?1620372742'
  },
  {
    chainId: 1,
    address: '0xe2da716381d7e0032cecaa5046b34223fc3f218d',
    name: 'Carbon Utility Toke',
    symbol: 'CUT',
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/14487/thumb/CUT_Logo_%281%29.png?1616478995'
  },
  {
    chainId: 1,
    address: '0x2509ee05b8df07ec75046e24bbf1cfcdb8b2a183',
    name: 'Amaten',
    symbol: 'AMA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11010/thumb/0_%283%29.png?1587104344'
  },
  {
    chainId: 1,
    address: '0xdb13fb60d3fddabc41743d9d603ce3f42c17d057',
    name: 'Baby Token',
    symbol: 'BABY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13294/thumb/logo_%2828%29.png?1607067692'
  },
  {
    chainId: 1,
    address: '0x3893b9422cd5d70a81edeffe3d5a1c6a978310bb',
    name: 'Mithril',
    symbol: 'MITH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3484/thumb/mithril.png?1548085086'
  },
  {
    chainId: 1,
    address: '0x73ee6d7e6b203125add89320e9f343d65ec7c39a',
    name: 'Axioms',
    symbol: 'AXI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12562/thumb/axioms_logo.png?1600772415'
  },
  {
    chainId: 1,
    address: '0xee9e5eff401ee921b138490d00ca8d1f13f67a72',
    name: 'Asian Fintech',
    symbol: 'AFIN',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/7519/thumb/afin.png?1558430973'
  },
  {
    chainId: 1,
    address: '0xf3b3cad094b89392fce5fafd40bc03b80f2bc624',
    name: 'Patron',
    symbol: 'PAT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3169/thumb/patron.png?1548330790'
  },
  {
    chainId: 1,
    address: '0x871baed4088b863fd6407159f3672d70cd34837d',
    name: '3X Long Ethereum To',
    symbol: 'ETHBULL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10163/thumb/683JEXMN_400x400_%281%29.png?1576504568'
  },
  {
    chainId: 1,
    address: '0xb34ab2f65c6e4f764ffe740ab83f982021faed6d',
    name: 'Basis Gold',
    symbol: 'BSG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13663/thumb/pErVlwLE_400x400.png?1610601302'
  },
  {
    chainId: 1,
    address: '0x71e5fb8793b5a2fb0c4918930180f8b36500cbb8',
    name: 'Electric Token',
    symbol: 'ETR',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10421/thumb/Electric_Token.png?1579171352'
  },
  {
    chainId: 1,
    address: '0x65032604dab202aff9adf89300cdb4bd0d059f55',
    name: 'SOBA Token',
    symbol: 'SOBA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14673/thumb/soba-logo-black.png?1617680379'
  },
  {
    chainId: 1,
    address: '0x8fc9b6354e839ab1c8b31f4afa53607092b8c2e5',
    name: 'ECOSC',
    symbol: 'ECU',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10989/thumb/ecosc-logo.png?1586914826'
  },
  {
    chainId: 1,
    address: '0xea6412fb370e8d1605e6aeeaa21ad07c3c7e9f24',
    name: 'Mushroom',
    symbol: 'MUSH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14054/thumb/logo.jpeg?1614049972'
  },
  {
    chainId: 1,
    address: '0xd7394087e1dbbe477fe4f1cf373b9ac9459565ff',
    name: 'RealTract',
    symbol: 'RET',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/5955/thumb/200-01.png?1547041895'
  },
  {
    chainId: 1,
    address: '0x01b23286ff60a543ec29366ae8d6b6274ca20541',
    name: 'Brother Music Platf',
    symbol: 'BMP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13207/thumb/BMP_logo.png?1606199707'
  },
  {
    chainId: 1,
    address: '0xb4fbed161bebcb37afb1cb4a6f7ca18b977ccb25',
    name: 'Dogeswap',
    symbol: 'DOGES',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12763/thumb/20200926-220107.png?1602455597'
  },
  {
    chainId: 1,
    address: '0x60a995cebcd44ca566ae22a9666ed28c67b598a1',
    name: 'Hardcore Finance',
    symbol: 'HCORE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13941/thumb/hcore_logo.jpg?1613059806'
  },
  {
    chainId: 1,
    address: '0x5bc7e5f0ab8b2e10d2d0a3f21739fce62459aef3',
    name: 'Hut34 Entropy',
    symbol: 'ENTRP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5197/thumb/hut.png?1575869079'
  },
  {
    chainId: 1,
    address: '0xe54f9e6ab80ebc28515af8b8233c1aee6506a15e',
    name: 'Spaghetti',
    symbol: 'PASTA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12170/thumb/pasta_logo.png?1597803191'
  },
  {
    chainId: 1,
    address: '0xd4fa1460f537bb9085d22c7bccb5dd450ef28e3a',
    name: 'Populous',
    symbol: 'PPT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/753/thumb/populous.png?1548331905'
  },
  {
    chainId: 1,
    address: '0x594207c791afd06a8d087d84d99d1da53ccbd45f',
    name: 'Buzzshow',
    symbol: 'GLDY',
    decimals: 3,
    logoURI: 'https://assets.coingecko.com/coins/images/2585/thumb/lLY2uKBE_400x400.jpg?1583447824'
  },
  {
    chainId: 1,
    address: '0x99963ee76c886fc43d5063428ff8f926e8a50985',
    name: 'INVIZION',
    symbol: 'NVZN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12152/thumb/Untitled-design-4-removebg-preview.png?1597700571'
  },
  {
    chainId: 1,
    address: '0x01c8857057326b8f64dcb5cba6d802dcd132946e',
    name: 'ShareAt',
    symbol: 'XAT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12902/thumb/shareat.PNG?1603346956'
  },
  {
    chainId: 1,
    address: '0x865ec58b06bf6305b886793aa20a2da31d034e68',
    name: 'Mossland',
    symbol: 'MOC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2374/thumb/moc.png?1547036557'
  },
  {
    chainId: 1,
    address: '0x1680cfdad75da2bb56ded4f36bb9423c86ffa7b7',
    name: 'Web Token Pay',
    symbol: 'WTP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8720/thumb/logo_%2828%29.png?1560397140'
  },
  {
    chainId: 1,
    address: '0x5e3346444010135322268a4630d2ed5f8d09446c',
    name: 'LockTrip',
    symbol: 'LOC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1357/thumb/LOC_Transparent_New.png?1579491094'
  },
  {
    chainId: 1,
    address: '0x9501bfc48897dceeadf73113ef635d2ff7ee4b97',
    name: 'easyMine',
    symbol: 'EMT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1749/thumb/emt.png?1547036034'
  },
  {
    chainId: 1,
    address: '0x5a143f78bb66294ff37c47b5164584475b932bab',
    name: 'Yearn Hold Finance',
    symbol: 'YHFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13124/thumb/photo-2020-11-09-21-22-47.jpg?1605495059'
  },
  {
    chainId: 1,
    address: '0x69a95185ee2a045cdc4bcd1b1df10710395e4e23',
    name: 'Poolz Finance',
    symbol: 'POOLZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13679/thumb/poolz_logo.png?1610806091'
  },
  {
    chainId: 1,
    address: '0x1966d718a565566e8e202792658d7b5ff4ece469',
    name: 'nDEX',
    symbol: 'NDX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5576/thumb/nDEX-logo.png?1547041405'
  },
  {
    chainId: 1,
    address: '0x43de1145cd22f0a9cc99e51c205e6e81161df6b9',
    name: '3X Long Cardano Tok',
    symbol: 'ADABULL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10203/thumb/683JEXMN_400x400_%281%29.png?1576534440'
  },
  {
    chainId: 1,
    address: '0x539e890f31f8e3fc63d5a2f5756a785c20f5c85d',
    name: 'Golden Bridge Coin',
    symbol: 'GBC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13855/thumb/Untitled-design-7.png?1612403188'
  },
  {
    chainId: 1,
    address: '0x1df6f1bb7454e5e4ba3bca882d3148fbf9b5697a',
    name: 'Yfscience',
    symbol: 'YFSI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12723/thumb/Yfscience_logo.png?1602038268'
  },
  {
    chainId: 1,
    address: '0xfd8971d5e8e1740ce2d0a84095fca4de729d0c16',
    name: 'Zilla',
    symbol: 'ZLA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2805/thumb/zilla.png?1548761449'
  },
  {
    chainId: 1,
    address: '0xedfbd6c48c3ddff5612ade14b45bb19f916809ba',
    name: 'pulltherug finance',
    symbol: 'RUGZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12749/thumb/rugz_logo.png?1602218634'
  },
  {
    chainId: 1,
    address: '0xfe33ae95a9f0da8a845af33516edc240dcd711d6',
    name: 'sDASH',
    symbol: 'SDASH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11805/thumb/sDASH.png?1616149895'
  },
  {
    chainId: 1,
    address: '0x0e0989b1f9b8a38983c2ba8053269ca62ec9b195',
    name: 'Po et',
    symbol: 'POE',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/910/thumb/poet.png?1548331583'
  },
  {
    chainId: 1,
    address: '0x944eee930933be5e23b690c8589021ec8619a301',
    name: 'Munch Token',
    symbol: 'MUNCH',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14804/thumb/logo_-_2021-04-16T082627.266.png?1618532828'
  },
  {
    chainId: 1,
    address: '0xe0bdaafd0aab238c55d68ad54e616305d4a21772',
    name: 'Refract',
    symbol: 'RFR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13394/thumb/refract_logo.png?1608108926'
  },
  {
    chainId: 1,
    address: '0x466912baa9430a4a460b141ee8c580d817441449',
    name: 'BLOCKMAX',
    symbol: 'OCB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12315/thumb/200x200-01.png?1599086761'
  },
  {
    chainId: 1,
    address: '0xc5bddf9843308380375a611c18b50fb9341f502a',
    name: 'veCRV DAO yVault',
    symbol: 'YVE-CRVDAO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13065/thumb/yearn_veCRV.png?1612862859'
  },
  {
    chainId: 1,
    address: '0xcc4ae94372da236e9b113132e0c46c68704246b9',
    name: 'Tagcoin ERC 20',
    symbol: 'TAG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14169/thumb/TAG.png?1614756882'
  },
  {
    chainId: 1,
    address: '0x35f6b052c598d933d69a4eec4d04c73a191fe6c2',
    name: 'Aave SNX',
    symbol: 'ASNX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14265/thumb/aSNX.3238f3e7.png?1615528264'
  },
  {
    chainId: 1,
    address: '0x3a3a65aab0dd2a17e3f1947ba16138cd37d08c04',
    name: 'Aave ETH v1',
    symbol: 'AETH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11619/thumb/aETH_2x.png?1591976597'
  },
  {
    chainId: 1,
    address: '0x028171bca77440897b824ca71d1c56cac55b68a3',
    name: 'Aave DAI',
    symbol: 'ADAI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14242/thumb/aDAI.84b6c41f.png?1615528749'
  },
  {
    chainId: 1,
    address: '0xaf8a215e81faea7c180ce22b72483525121813bd',
    name: 'Engine',
    symbol: 'EGCC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4531/thumb/2852.png?1547039827'
  },
  {
    chainId: 1,
    address: '0x2001f2a0cf801ecfda622f6c28fb6e10d803d969',
    name: 'CoinLoan',
    symbol: 'CLT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/1713/thumb/blue-logo-transparent-200x200.png?1595912819'
  },
  {
    chainId: 1,
    address: '0x60c24407d01782c2175d32fe7c8921ed732371d1',
    name: 'LemoChain',
    symbol: 'LEMO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3108/thumb/lemochain.png?1547975597'
  },
  {
    chainId: 1,
    address: '0x0f51bb10119727a7e5ea3538074fb341f56b09ad',
    name: 'DAO Maker',
    symbol: 'DAO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13915/thumb/4.png?1612838831'
  },
  {
    chainId: 1,
    address: '0x1c79ab32c66acaa1e9e81952b8aaa581b43e54e7',
    name: 'TEAM',
    symbol: 'TEAM',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/2665/thumb/team.png?1516812418'
  },
  {
    chainId: 1,
    address: '0x8798249c2e607446efb7ad49ec89dd1865ff4272',
    name: 'xSUSHI',
    symbol: 'XSUSHI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13725/thumb/xsushi.png?1612538526'
  },
  {
    chainId: 1,
    address: '0x57ab1ec28d129707052df4df418d58a2d46d5f51',
    name: 'sUSD',
    symbol: 'SUSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5013/thumb/sUSD.png?1616150765'
  },
  {
    chainId: 1,
    address: '0xb753428af26e81097e7fd17f40c88aaa3e04902c',
    name: 'saffron finance',
    symbol: 'SFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13117/thumb/sfi_red_250px.png?1606020144'
  },
  {
    chainId: 1,
    address: '0x7297862b9670ff015192799cc849726c88bf1d77',
    name: 'SkyMap',
    symbol: 'SKYM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5266/thumb/soar_earth.png?1547040769'
  },
  {
    chainId: 1,
    address: '0x5d60d8d7ef6d37e16ebabc324de3be57f135e0bc',
    name: 'MyBit Token',
    symbol: 'MYB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1240/thumb/mybit.png?1547035264'
  },
  {
    chainId: 1,
    address: '0x0e69d0a2bbb30abcb7e5cfea0e4fde19c00a8d47',
    name: 'Carlive Chain',
    symbol: 'IOV',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/5339/thumb/carlive-chain.png?1547738870'
  },
  {
    chainId: 1,
    address: '0x43567eb78638a55bbe51e9f9fb5b2d7ad1f125aa',
    name: 'Hackspace Capital',
    symbol: 'HAC',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/2336/thumb/hac.png?1547036543'
  },
  {
    chainId: 1,
    address: '0x5c84bc60a796534bfec3439af0e6db616a966335',
    name: 'Bone',
    symbol: 'BONE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11860/thumb/BONE.png?1615803509'
  },
  {
    chainId: 1,
    address: '0x328c4c80bc7aca0834db37e6600a6c49e12da4de',
    name: 'Aave SNX v1',
    symbol: 'ASNX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11733/thumb/aSNX.png?1593085047'
  },
  {
    chainId: 1,
    address: '0xad4f86a25bbc20ffb751f2fac312a0b4d8f88c64',
    name: 'OptionRoom',
    symbol: 'ROOM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13889/thumb/option_room_logo.png?1612518313'
  },
  {
    chainId: 1,
    address: '0x986ee2b944c42d017f52af21c4c69b84dbea35d8',
    name: 'BitMart Token',
    symbol: 'BMX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5236/thumb/bitmart-token.png?1548835017'
  },
  {
    chainId: 1,
    address: '0xa8e7ad77c60ee6f30bac54e2e7c0617bd7b5a03e',
    name: 'zLOT',
    symbol: 'ZLOT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13073/thumb/z-LOT-logo-transparent.png?1604900416'
  },
  {
    chainId: 1,
    address: '0x692eb773e0b5b7a79efac5a015c8b36a2577f65c',
    name: 'swiss finance',
    symbol: 'SWISS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13253/thumb/SWISS.png?1606724844'
  },
  {
    chainId: 1,
    address: '0x44197a4c44d6a059297caf6be4f7e172bd56caaf',
    name: 'Eltcoin',
    symbol: 'ELTCOIN',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/1118/thumb/eltcoin.png?1547958349'
  },
  {
    chainId: 1,
    address: '0x53066cddbc0099eb6c96785d9b3df2aaeede5da3',
    name: 'Penta Network Token',
    symbol: 'PNT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4175/thumb/pentanetwork.png?1548330852'
  },
  {
    chainId: 1,
    address: '0xed30dd7e50edf3581ad970efc5d9379ce2614adb',
    name: 'ARC Governance  Old',
    symbol: 'ARCX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14459/thumb/arcx_logo.jpg?1616221058'
  },
  {
    chainId: 1,
    address: '0x9214ec02cb71cba0ada6896b8da260736a67ab10',
    name: 'Real Estate Asset L',
    symbol: 'REAL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/993/thumb/real.png?1548607834'
  },
  {
    chainId: 1,
    address: '0xb1f871ae9462f1b2c6826e88a7827e76f86751d4',
    name: 'GNY',
    symbol: 'GNY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5300/thumb/icon_gny.jpg?1601926183'
  },
  {
    chainId: 1,
    address: '0xdf59c8ba19b4d1437d80836b45f1319d9a429eed',
    name: 'IZIChain',
    symbol: 'IZI',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/6405/thumb/logo-icon-200x200.png?1547617296'
  },
  {
    chainId: 1,
    address: '0xa15c7ebe1f07caf6bff097d8a589fb8ac49ae5b3',
    name: 'Pundi X  OLD ',
    symbol: 'NPXS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2170/thumb/pundi-x.png?1548386366'
  },
  {
    chainId: 1,
    address: '0xf45b14ddabf0f0e275e215b94dd24ae013a27f12',
    name: 'sXTZ',
    symbol: 'SXTZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8863/thumb/sXTZ.png?1616150951'
  },
  {
    chainId: 1,
    address: '0x0198f46f520f33cd4329bd4be380a25a90536cd5',
    name: 'PlayChip',
    symbol: 'PLA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4567/thumb/pla.png?1547039851'
  },
  {
    chainId: 1,
    address: '0xc151ca64d66ea44ee4be9d47c3ce7e031b2fccb7',
    name: 'YFET',
    symbol: 'YFET',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12689/thumb/Logo-Coin-YFET-1-768x768.png?1601674080'
  },
  {
    chainId: 1,
    address: '0x626e8036deb333b408be468f951bdb42433cbf18',
    name: 'AIOZ Network',
    symbol: 'AIOZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14631/thumb/aioz_logo.png?1617413126'
  },
  {
    chainId: 1,
    address: '0x0aee8703d34dd9ae107386d3eff22ae75dd616d1',
    name: 'Tranche Finance',
    symbol: 'SLICE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14119/thumb/photo_2021-03-01_18-31-45.jpg?1614694699'
  },
  {
    chainId: 1,
    address: '0xc2992b2c22238f296c2f429ee2f7afb462ed1750',
    name: 'iXTZ',
    symbol: 'IXTZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9063/thumb/iXTZ.png?1616151738'
  },
  {
    chainId: 1,
    address: '0x3ed3b47dd13ec9a98b44e6204a523e766b225811',
    name: 'Aave USDT',
    symbol: 'AUSDT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14243/thumb/aUSDT.78f5faae.png?1615528400'
  },
  {
    chainId: 1,
    address: '0xf34842d05a1c888ca02769a633df37177415c2f8',
    name: 'IdleUSDT  Yield ',
    symbol: 'IDLEUSDTYIELD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11933/thumb/idleusdtv3maxyield_32.png?1596263688'
  },
  {
    chainId: 1,
    address: '0x419d0d8bdd9af5e606ae2232ed285aff190e711b',
    name: 'FUNToken',
    symbol: 'FUN',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/761/thumb/funfair.png?1592404368'
  },
  {
    chainId: 1,
    address: '0x14d10003807ac60d07bb0ba82caeac8d2087c157',
    name: 'iDeFi',
    symbol: 'IDEFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11835/thumb/iDEFI.png?1616151297'
  },
  {
    chainId: 1,
    address: '0x1341a2257fa7b770420ef70616f888056f90926c',
    name: 'Zoo Token',
    symbol: 'ZOOT',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15629/thumb/gyzERsO.png?1621394316'
  },
  {
    chainId: 1,
    address: '0xb9871cb10738eada636432e86fc0cb920dc3de24',
    name: 'PRIA',
    symbol: 'PRIA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12905/thumb/pria-200x.png?1603357286'
  },
  {
    chainId: 1,
    address: '0xcc8fa225d80b9c7d42f96e9570156c65d6caaa25',
    name: 'Smooth Love Potion',
    symbol: 'SLP',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/10366/thumb/SLP.png?1578640057'
  },
  {
    chainId: 1,
    address: '0x9ccbd05d4d25c745d49f5e6bf17e09113eb4c769',
    name: 'Decentralized Crypt',
    symbol: 'DCTO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7040/thumb/dcto-logo.png?1547043482'
  },
  {
    chainId: 1,
    address: '0x6226e00bcac68b0fe55583b90a1d727c14fab77f',
    name: 'MultiVAC',
    symbol: 'MTV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4937/thumb/MultiVAC.png?1558598585'
  },
  {
    chainId: 1,
    address: '0x6944d3e38973c4831da24e954fbd790c7e688bdd',
    name: 'IZE',
    symbol: 'IZE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11031/thumb/logo.png?1587294254'
  },
  {
    chainId: 1,
    address: '0xca208bfd69ae6d2667f1fcbe681bae12767c0078',
    name: 'HOMIHELP',
    symbol: 'HOMI',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/11080/thumb/Homihelp.png?1589626747'
  },
  {
    chainId: 1,
    address: '0xba358b6f5b4c0215650444b8c30d870b55050d2d',
    name: 'Hub Token',
    symbol: 'HUB',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/13345/thumb/Hub-Logo-Transparent-BG-200x200_%281%29.png?1607661813'
  },
  {
    chainId: 1,
    address: '0xc67d5cfb933c4a1c6d2ca4cbdae2ed9f017ee9e5',
    name: 'LIPCHAIN',
    symbol: 'LIPS',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/4787/thumb/lipchain.PNG?1580856182'
  },
  {
    chainId: 1,
    address: '0x3004cf8b4e28d60f4e305df25a57cd5faf37b8d5',
    name: 'BSYS',
    symbol: 'BSYS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11532/thumb/4PvxXDdP_400x400_%281%29.jpg?1590720588'
  },
  {
    chainId: 1,
    address: '0x61cdb66e56fad942a7b5ce3f419ffe9375e31075',
    name: 'RAIN Network',
    symbol: 'RAIN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11699/thumb/v4Bpj2k.png?1592963188'
  },
  {
    chainId: 1,
    address: '0xa117000000f279d81a1d3cc75430faa017fa5a2e',
    name: 'Aragon',
    symbol: 'ANT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/681/thumb/JelZ58cv_400x400.png?1601449653'
  },
  {
    chainId: 1,
    address: '0x1bf7fd22709733ccd7c45ab27dd02c7ec8e50078',
    name: 'Quiztok',
    symbol: 'QTCON',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8208/thumb/QTCON.png?1587543372'
  },
  {
    chainId: 1,
    address: '0x4ad0b81f92b16624bbcf46fc0030cfbbf8d02376',
    name: 'Unagii Dai',
    symbol: 'UDAI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13782/thumb/uDAI.png?1611730113'
  },
  {
    chainId: 1,
    address: '0xc96c1609a1a45ccc667b2b7fa6508e29617f7b69',
    name: '2gether',
    symbol: '2GT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14516/thumb/m329Ic5i_400x400.jpg?1616647761'
  },
  {
    chainId: 1,
    address: '0xeb4c2781e4eba804ce9a9803c67d0893436bb27d',
    name: 'renBTC',
    symbol: 'RENBTC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11370/thumb/renBTC.png?1589985711'
  },
  {
    chainId: 1,
    address: '0x2ec95b8eda549b79a1248335a39d299d00ed314c',
    name: 'Fatcoin',
    symbol: 'FAT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7109/thumb/SV2Xb94q_400x400.jpg?1547043605'
  },
  {
    chainId: 1,
    address: '0x84cd68c3e470ecee4b8b6212efcb8c6bcb38da1d',
    name: 'Doki Doki Chainbind',
    symbol: 'BND',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15690/thumb/chainbinders_logo_250px.png?1621565106'
  },
  {
    chainId: 1,
    address: '0x688ff43c3c19e4714f0beb76df8ee394207ab411',
    name: 'CitiOS',
    symbol: 'R2R',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10670/thumb/5204.png?1581542842'
  },
  {
    chainId: 1,
    address: '0x4ddc2d193948926d02f9b1fe9e1daa0718270ed5',
    name: 'cETH',
    symbol: 'CETH',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10643/thumb/ceth2.JPG?1581389598'
  },
  {
    chainId: 1,
    address: '0xbdbb0ee6144544ec814d417b0ad41f16fc8b858e',
    name: 'BitKAM',
    symbol: 'KAM',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10310/thumb/D-MAj4JR_400x400.jpg?1577829013'
  },
  {
    chainId: 1,
    address: '0xa5e412ba6fca1e07b15defcaa4236ff7b5a7f086',
    name: 'Crypto Bank',
    symbol: 'CBANK',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/13473/thumb/HHeUVDBF5clKgE4vW_dyCtcTZqWDRw_vDe1MA3lIJWI4wEZW2BwF5dHFbm_ZpJNTWf85sZxCBVl2R7u29kGg37bE8XkGh3CneW6SkVwbnQEe0r2WR6yy6THxMvzIMiicWza82-NyjMitEo84_2E_C5LCiBcdTPFrjNQiMs1DTSJuaRTNB81KRsdcXNW0p2j7PH8O7Xk3QrWVjPe.jpg?1608853514'
  },
  {
    chainId: 1,
    address: '0xe2ee1ac57b2e5564522b2de064a47b3f98b0e9c9',
    name: 'Whalesburg',
    symbol: 'WBT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3365/thumb/Whalesburg.jpg?1547037997'
  },
  {
    chainId: 1,
    address: '0x8a9c67fee641579deba04928c4bc45f66e26343a',
    name: 'Jarvis Reward Token',
    symbol: 'JRT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10390/thumb/cfeii0y.png?1578868949'
  },
  {
    chainId: 1,
    address: '0x62359ed7505efc61ff1d56fef82158ccaffa23d7',
    name: 'cVault finance',
    symbol: 'CORE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12635/thumb/cvault.finance_logo.png?1601353499'
  },
  {
    chainId: 1,
    address: '0x6556d2ec4d96da39cf75cbe50d58fae90079800a',
    name: 'Lukki Operating Tok',
    symbol: 'LOT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8463/thumb/HxHSPaeb_400x400.png?1566858024'
  },
  {
    chainId: 1,
    address: '0x3ebb4a4e91ad83be51f8d596533818b246f4bee1',
    name: 'Signata',
    symbol: 'SATA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14704/thumb/logo.png?1617853256'
  },
  {
    chainId: 1,
    address: '0xa72b32f11c49f3f9aa14125c4c89a9c60c390eb4',
    name: 'ZG Blockchain Token',
    symbol: 'ZGT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9922/thumb/ABUIABAEGAAg2eSz5wUo0PGJ7QYwmAU4bA.png?1573461439'
  },
  {
    chainId: 1,
    address: '0x20910e5b5f087f6439dfcb0dda4e27d1014ac2b8',
    name: 'BananaTok',
    symbol: 'BNA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10289/thumb/sMCxdYBa_400x400.jpg?1577013480'
  },
  {
    chainId: 1,
    address: '0x622f2962ae78e8686ecc1e30cf2f9a6e5ac35626',
    name: 'Wrapped Polis',
    symbol: 'POLIS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13377/thumb/polispay_logo.jpg?1608027141'
  },
  {
    chainId: 1,
    address: '0x798a9055a98913835bbfb45a0bbc209438dcfd97',
    name: 'New Year Bull',
    symbol: 'NYB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12327/thumb/bull.jpg?1599102873'
  },
  {
    chainId: 1,
    address: '0x76c5449f4950f6338a393f53cda8b53b0cd3ca3a',
    name: 'BT Finance',
    symbol: 'BT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13824/thumb/BT-logo.324f553c.png?1612152632'
  },
  {
    chainId: 1,
    address: '0xfc4b8ed459e00e5400be803a9bb3954234fd50e3',
    name: 'Aave WBTC v1',
    symbol: 'AWBTC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11734/thumb/aWBTC.png?1593087741'
  },
  {
    chainId: 1,
    address: '0x5dc74029509752f4ed9a609c2bb52216275e4c1d',
    name: 'Game City',
    symbol: 'GMCI',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/7121/thumb/gamecity.jpg?1612234592'
  },
  {
    chainId: 1,
    address: '0x2dca19e944453e46d9130950ca135461b3bc0c30',
    name: 'EYES Protocol',
    symbol: 'EYES',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9734/thumb/pTaAZUI6_400x400.jpg?1571308667'
  },
  {
    chainId: 1,
    address: '0xb0dfd28d3cf7a5897c694904ace292539242f858',
    name: 'Lotto',
    symbol: 'LOTTO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13822/thumb/Lotto-Logo256x256.png?1612150421'
  },
  {
    chainId: 1,
    address: '0x1ccaa0f2a7210d76e1fdec740d5f323e2e1b1672',
    name: 'Faceter',
    symbol: 'FACE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4383/thumb/faceter-logo.png?1547039727'
  },
  {
    chainId: 1,
    address: '0x3affcca64c2a6f4e3b6bd9c64cd2c969efd1ecbe',
    name: 'DSLA Protocol',
    symbol: 'DSLA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6694/thumb/dsla_logo-squared_200x200.png?1569571063'
  },
  {
    chainId: 1,
    address: '0x15eabb7500e44b7fdb6e4051ca8deca430cf9fb8',
    name: 'Dexfin',
    symbol: 'DXF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13660/thumb/dxf-symbol.png?1610599440'
  },
  {
    chainId: 1,
    address: '0xb5b8f5616fe42d5ceca3e87f3fddbdd8f496d760',
    name: 'ZPER',
    symbol: 'ZPR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5293/thumb/zper.png?1547040816'
  },
  {
    chainId: 1,
    address: '0x5cf501e64786444e025c5b24025f98399538ea5d',
    name: 'Galaxy Pool Coin',
    symbol: 'GPO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10498/thumb/c9fg8dtz_400x400.png?1580249130'
  },
  {
    chainId: 1,
    address: '0x3543638ed4a9006e4840b105944271bcea15605d',
    name: 'U Network',
    symbol: 'UUU',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3877/thumb/2645.png?1547038930'
  },
  {
    chainId: 1,
    address: '0x382f0160c24f5c515a19f155bac14d479433a407',
    name: 'KleeKai',
    symbol: 'KLEE',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15548/thumb/klee.PNG?1621200125'
  },
  {
    chainId: 1,
    address: '0x6c5ba91642f10282b576d91922ae6448c9d52f4e',
    name: 'Phala Network',
    symbol: 'PHA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12451/thumb/phala.png?1600061318'
  },
  {
    chainId: 1,
    address: '0x9388f54fa978aa9e24395a8b69033304eccea4df',
    name: 'BITSCOIN',
    symbol: 'BTCX',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/11181/thumb/vBaA50a.png?1589512223'
  },
  {
    chainId: 1,
    address: '0xcbd380c2d84deafed09f79863705353505764f26',
    name: 'Emojis Farm',
    symbol: 'EMOJI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13341/thumb/emojis_farm.png?1607588447'
  },
  {
    chainId: 1,
    address: '0x2ecb95eb932dfbbb71545f4d23ca303700ac855f',
    name: 'TBCC Wallet',
    symbol: 'TBCC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13353/thumb/Tbcc_logo.png?1607811795'
  },
  {
    chainId: 1,
    address: '0x4f56221252d117f35e2f6ab937a3f77cad38934d',
    name: 'CryptoCricketClub',
    symbol: '3CS',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/11685/thumb/crypto-cricket-club-logo-e1592305032921.png?1592793917'
  },
  {
    chainId: 1,
    address: '0xb3bace433288645114fe8e8aa91f87659cbf665b',
    name: 'Wallet Plus X',
    symbol: 'WPX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10302/thumb/WPX_LOGO_200X200.png?1577693149'
  },
  {
    chainId: 1,
    address: '0x84f7c44b6fed1080f647e354d552595be2cc602f',
    name: 'Bigbom',
    symbol: 'BBO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3795/thumb/bigbom-logo.png?1547038875'
  },
  {
    chainId: 1,
    address: '0x9f599410d207f3d2828a8712e5e543ac2e040382',
    name: 'Tapcoin',
    symbol: 'TTT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1580/thumb/tapcoin.png?1547035760'
  },
  {
    chainId: 1,
    address: '0x2ef52ed7de8c5ce03a4ef0efbe9b7450f2d7edc9',
    name: 'Revain',
    symbol: 'REV',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/1107/thumb/256x256.png?1587018647'
  },
  {
    chainId: 1,
    address: '0xabc430136a4de71c9998242de8c1b4b97d2b9045',
    name: 'Veros',
    symbol: 'VRS',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/539/thumb/vrs_logo.png?1583913100'
  },
  {
    chainId: 1,
    address: '0x3505f494c3f0fed0b594e01fa41dd3967645ca39',
    name: 'Swarm Network',
    symbol: 'SWM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/197/thumb/swarm.jpg?1547033949'
  },
  {
    chainId: 1,
    address: '0x02e3083a51e8632e571fbd6a62ac396c10c653ba',
    name: 'Epluscoin',
    symbol: 'EPLUS',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/4741/thumb/epluscoin-logo.png?1547040052'
  },
  {
    chainId: 1,
    address: '0xad3e3fc59dff318beceaab7d00eb4f68b1ecf195',
    name: 'Wrapped Celo Dollar',
    symbol: 'WCUSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13562/thumb/WCUSD-Icon.jpg?1609819805'
  },
  {
    chainId: 1,
    address: '0x0f5d2fb29fb7d3cfee444a200298f468908cc942',
    name: 'Decentraland',
    symbol: 'MANA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/878/thumb/decentraland-mana.png?1550108745'
  },
  {
    chainId: 1,
    address: '0x0275e1001e293c46cfe158b3702aade0b99f88a5',
    name: 'Oiler',
    symbol: 'OIL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15249/thumb/oiler.png?1620237607'
  },
  {
    chainId: 1,
    address: '0x7de2d123042994737105802d2abd0a10a7bde276',
    name: 'MEXC Token',
    symbol: 'MEXC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9156/thumb/Q0WvFsBt_400x400.jpg?1564736309'
  },
  {
    chainId: 1,
    address: '0x6b74dd5d01f8320081247f5cf1f7a48324700db6',
    name: 'UBU',
    symbol: 'UBU',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10686/thumb/NFGYEIaA_400x400_%281%29.png?1586763969'
  },
  {
    chainId: 1,
    address: '0xbc16da9df0a22f01a16bc0620a27e7d6d6488550',
    name: 'Percent',
    symbol: 'PCT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12505/thumb/1*T5u1FDg9LLpvHifwr4WCwQ.png?1600310998'
  },
  {
    chainId: 1,
    address: '0xdb7eab9ba6be88b869f738f6deeba96d49fe13fd',
    name: 'Boom Token',
    symbol: 'BOOM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9004/thumb/fxIdfHb4_400x400.png?1563577165'
  },
  {
    chainId: 1,
    address: '0x9fadea1aff842d407893e21dbd0e2017b4c287b6',
    name: 'PGF500',
    symbol: 'PGF7T',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8987/thumb/pgf500-logo.png?1563488429'
  },
  {
    chainId: 1,
    address: '0x3472a5a71965499acd81997a54bba8d852c6e53d',
    name: 'Badger DAO',
    symbol: 'BADGER',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13287/thumb/badger_dao_logo.jpg?1607054976'
  },
  {
    chainId: 1,
    address: '0xd5dc8921a5c58fb0eba6db6b40eab40283dc3c01',
    name: 'Decentralized Data ',
    symbol: 'DDAM',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/9943/thumb/ddam.PNG?1573638444'
  },
  {
    chainId: 1,
    address: '0x64c5572e7a100af9901c148d75d72c619a7f1e9d',
    name: 'UniCrapToken xyz',
    symbol: 'UNICRAP',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/12172/thumb/GfCR6sqZMaJmDBJ80CQBSSxzm2fe1Y0Cd87I9ZgwUU32Zr70LZDGA7ue_2aisyeXuEhweh4fQaRYg1KRbQzuZVrnDJota1LsNLgcjWj23eYTAdo8bI79hg6oxwVC-FPi58jxlqKO6e-5G-ICeqzUbW-LPQjSeG0esscG9a5y_9R64p4rMTHCqudAO01tLmBrYfIUn9bEyK-pgicSGY0.jpg?1597804376'
  },
  {
    chainId: 1,
    address: '0x190fb342aa6a15eb82903323ae78066ff8616746',
    name: 'Umbrella Coin',
    symbol: 'UMC',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/1700/thumb/umbrellacoin.jpg?1547035958'
  },
  {
    chainId: 1,
    address: '0x6c2adc2073994fb2ccc5032cc2906fa221e9b391',
    name: 'Delphy',
    symbol: 'DPY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1133/thumb/delphy.png?1547035133'
  },
  {
    chainId: 1,
    address: '0xd6a55c63865affd67e2fb9f284f87b7a9e5ff3bd',
    name: 'Switch',
    symbol: 'ESH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8608/thumb/Cco9sLN.png?1603676332'
  },
  {
    chainId: 1,
    address: '0xd9016a907dc0ecfa3ca425ab20b6b785b42f2373',
    name: 'GAMEE',
    symbol: 'GMEE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14716/thumb/gmee-200x200.png?1621827468'
  },
  {
    chainId: 1,
    address: '0xd241d7b5cb0ef9fc79d9e4eb9e21f5e209f52f7d',
    name: 'Hoo Token',
    symbol: 'HOO',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12968/thumb/Hoo_Logo_1.png?1603939029'
  },
  {
    chainId: 1,
    address: '0x7bef710a5759d197ec0bf621c3df802c2d60d848',
    name: 'Splyt',
    symbol: 'SHOPX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14600/thumb/Splyt_Logo.png?1617196503'
  },
  {
    chainId: 1,
    address: '0x86367c0e517622dacdab379f2de389c3c9524345',
    name: 'Universal US Dollar',
    symbol: 'UPUSD',
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/11170/thumb/yS35aK0t_400x400_%282%29.jpg?1589449387'
  },
  {
    chainId: 1,
    address: '0x412e5a36bde71aa2c38e1c0e26baaf7f2f0bc24a',
    name: 'PegsUSD',
    symbol: 'PUSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13676/thumb/pegusd.png?1610705447'
  },
  {
    chainId: 1,
    address: '0x6fd016ccc4611f7bab1dd3267334cb0216ef47f9',
    name: 'RealToken 8342 Scha',
    symbol: 'REALTOKEN-8342-SCHA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11916/thumb/8342.png?1596077402'
  },
  {
    chainId: 1,
    address: '0x65fc94d99cb301c5630c485d312e6ff5edde13d0',
    name: 'MVP',
    symbol: 'MVP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13150/thumb/mvp_token_logo.png?1605688252'
  },
  {
    chainId: 1,
    address: '0x6e742e29395cf5736c358538f0f1372ab3dfe731',
    name: 'TAMA EGG NiftyGotch',
    symbol: 'TME',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13229/thumb/photo_2021-01-25_09-16-29.jpg?1611537425'
  },
  {
    chainId: 1,
    address: '0x4e085036a1b732cbe4ffb1c12ddfdd87e7c3664d',
    name: 'Predictz',
    symbol: 'PRDZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13376/thumb/Predictz_Transperant.png?1608021631'
  },
  {
    chainId: 1,
    address: '0x11eef04c884e24d9b7b4760e7476d06ddf797f36',
    name: 'MX Token',
    symbol: 'MX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8545/thumb/TII1YIdv_400x400.png?1559180170'
  },
  {
    chainId: 1,
    address: '0x1500205f50bf3fd976466d0662905c9ff254fc9c',
    name: 'BitBoost',
    symbol: 'BBT',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/1194/thumb/bitboost.jpg?1547035224'
  },
  {
    chainId: 1,
    address: '0xe64b47931f28f89cc7a0c6965ecf89eadb4975f5',
    name: 'Ludos Protocol',
    symbol: 'LUD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5118/thumb/Ludos_Protocol.jpg?1557216263'
  },
  {
    chainId: 1,
    address: '0x0e9b56d2233ea2b5883861754435f9c51dbca141',
    name: 'Rare Pepe',
    symbol: 'RPEPE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12575/thumb/rare_pepe_logo.png?1600856124'
  },
  {
    chainId: 1,
    address: '0x35a735b7d1d811887966656855f870c05fd0a86d',
    name: 'Thorncoin',
    symbol: 'THRN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6300/thumb/_VaSXQpe_400x400.jpg?1547042348'
  },
  {
    chainId: 1,
    address: '0x37e1160184f7dd29f00b78c050bf13224780b0b0',
    name: 'Yuan Chain Coin',
    symbol: 'YCC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/5328/thumb/G5gCEo-J_400x400.jpg?1547040897'
  },
  {
    chainId: 1,
    address: '0xf04a8ac553fcedb5ba99a64799155826c136b0be',
    name: 'Flixxo',
    symbol: 'FLIXX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1261/thumb/flixxo.png?1547483809'
  },
  {
    chainId: 1,
    address: '0x66fd97a78d8854fec445cd1c80a07896b0b4851f',
    name: 'Lunch Money',
    symbol: 'LMY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4149/thumb/IMG_2073.png?1612754752'
  },
  {
    chainId: 1,
    address: '0x5d48f293baed247a2d0189058ba37aa238bd4725',
    name: 'NeuroChain',
    symbol: 'NCC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3367/thumb/neurochain.png?1548085917'
  },
  {
    chainId: 1,
    address: '0xd957e08ac5421e2c28510586b57d095e5094836a',
    name: 'VorteX Network',
    symbol: 'VTX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10543/thumb/256x256.png?1607154365'
  },
  {
    chainId: 1,
    address: '0x5d21ef5f25a985380b65c8e943a0082feda0db84',
    name: 'Ethereum Cash',
    symbol: 'ECASH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1010/thumb/ethereumcash.png?1510040235'
  },
  {
    chainId: 1,
    address: '0x4b86e0295e7d32433ffa6411b82b4f4e56a581e1',
    name: 'Dogira',
    symbol: 'DOGIRA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14634/thumb/Dogira.png?1617434151'
  },
  {
    chainId: 1,
    address: '0x4a621d9f1b19296d1c0f87637b3a8d4978e9bf82',
    name: 'CyberFM',
    symbol: 'CYFM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5476/thumb/cyberfm.png?1547041216'
  },
  {
    chainId: 1,
    address: '0x3e1d5a855ad9d948373ae68e4fe1f094612b1322',
    name: 'HyperQuant',
    symbol: 'HQT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4410/thumb/hqt.png?1547039746'
  },
  {
    chainId: 1,
    address: '0xf5dce57282a584d2746faf1593d3121fcac444dc',
    name: 'cSAI',
    symbol: 'CSAI',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10148/thumb/cSAI.png?1576467788'
  },
  {
    chainId: 1,
    address: '0x6b33f15360cedbfb8f60539ec828ef52910aca9b',
    name: 'xINCHb',
    symbol: 'XINCHB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13800/thumb/xINCH-2.png?1611978606'
  },
  {
    chainId: 1,
    address: '0xe7cd2c56d3f01f9f8ae542ba5b2f00cf2cf994b1',
    name: 'UCX FOUNDATION',
    symbol: 'UCX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12130/thumb/UCX_Color_Logo256.jpg?1597459240'
  },
  {
    chainId: 1,
    address: '0xa12a00e73e4e7174acc50a1c073e36aa0c9cb305',
    name: 'Swaap Stablecoin',
    symbol: 'SAP',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/14012/thumb/swaap.png?1613722677'
  },
  {
    chainId: 1,
    address: '0xe4cfe9eaa8cdb0942a80b7bc68fd8ab0f6d44903',
    name: 'Xend Finance',
    symbol: 'XEND',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14496/thumb/WeChat_Image_20210325163206.png?1616661216'
  },
  {
    chainId: 1,
    address: '0x44e28f2acc84c36373badcd681749d38e01e2cc4',
    name: 'vSpaceX',
    symbol: 'VSPACEX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14525/thumb/itovault_logo_%281%29.png?1616739254'
  },
  {
    chainId: 1,
    address: '0x1378ec93ab2b07ba5a0eaef19cf354a33f64b9fd',
    name: 'Yearn Finance Diamo',
    symbol: 'YFDT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12824/thumb/yfdt_logo.png?1602814305'
  },
  {
    chainId: 1,
    address: '0xc166f976ce9926a3205b145af104eb0e4b38b5c0',
    name: 'LINK ETH Growth Alp',
    symbol: 'LEGA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11457/thumb/alphachain_link_eth_growth_alpha_set.png?1590141817'
  },
  {
    chainId: 1,
    address: '0xa15690e9205de386ce849889831c1668c300c1ad',
    name: 'pETH18C',
    symbol: 'PETH18C',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14646/thumb/peth8a.png?1617489575'
  },
  {
    chainId: 1,
    address: '0x8281ee37f164c0e26e6b6f87e7695baac256df07',
    name: 'Degen Arts',
    symbol: 'DAC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14994/thumb/DAC-logo-200.png?1619390738'
  },
  {
    chainId: 1,
    address: '0xa9d232cc381715ae791417b624d7c4509d2c28db',
    name: 'Basis Gold Share',
    symbol: 'BSGS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13672/thumb/bsgs_logo.png?1610680124'
  },
  {
    chainId: 1,
    address: '0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0',
    name: 'Frax Share',
    symbol: 'FXS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13423/thumb/frax_share.png?1608478989'
  },
  {
    chainId: 1,
    address: '0x72f020f8f3e8fd9382705723cd26380f8d0c66bb',
    name: 'PlotX',
    symbol: 'PLOT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12795/thumb/PlotX.png?1611109969'
  },
  {
    chainId: 1,
    address: '0x48c1b2f3efa85fbafb2ab951bf4ba860a08cdbb7',
    name: 'ShowHand',
    symbol: 'HAND',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/5554/thumb/showhand.png?1548609179'
  },
  {
    chainId: 1,
    address: '0x3a1c1d1c06be03cddc4d3332f7c20e1b37c97ce9',
    name: 'Vybe',
    symbol: 'VYBE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12438/thumb/22k5gGG.jpg?1609924524'
  },
  {
    chainId: 1,
    address: '0x9695e0114e12c0d3a3636fab5a18e6b737529023',
    name: 'Dfyn Network',
    symbol: 'DFYN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15368/thumb/SgqhfWz4_400x400_%281%29.jpg?1620666919'
  },
  {
    chainId: 1,
    address: '0x3a4f40631a4f906c2bad353ed06de7a5d3fcb430',
    name: 'PlayDapp',
    symbol: 'PLA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14316/thumb/54023228.png?1615366911'
  },
  {
    chainId: 1,
    address: '0xff744f2315c9d61d825b581c973576055c3da07e',
    name: 'HPLUS',
    symbol: 'HPLUS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13859/thumb/HPLUS.jpg?1612409678'
  },
  {
    chainId: 1,
    address: '0xe4f356ecce6fbda81ecdea2e38527e59422861c2',
    name: 'BitStash',
    symbol: 'STASH',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/7674/thumb/bitstash.png?1555301765'
  },
  {
    chainId: 1,
    address: '0x69275ac5477f3a9dc051180bc559140bc647f8e9',
    name: 'Apple Finance',
    symbol: 'APLP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13559/thumb/8vx3u6M4_400x400.jpg?1609807070'
  },
  {
    chainId: 1,
    address: '0xfb444c1f2b718ddfc385cb8fd9f2d1d776b24668',
    name: 'Elamachain',
    symbol: 'ELAMA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5650/thumb/Elamachain.png?1584505477'
  },
  {
    chainId: 1,
    address: '0xd341d1680eeee3255b8c4c75bcce7eb57f144dae',
    name: 'SoMee Social',
    symbol: 'ONG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1286/thumb/So_Mee_logo_icons_11.png?1581305902'
  },
  {
    chainId: 1,
    address: '0x71f85b2e46976bd21302b64329868fd15eb0d127',
    name: 'Axion',
    symbol: 'AXN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13110/thumb/AXN.png?1613016807'
  },
  {
    chainId: 1,
    address: '0xdaf88906ac1de12ba2b1d2f7bfc94e9638ac40c4',
    name: 'EpiK Protocol',
    symbol: 'EPK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15188/thumb/epk.PNG?1620078400'
  },
  {
    chainId: 1,
    address: '0xbf52f2ab39e26e0951d2a02b49b7702abe30406a',
    name: 'ODEM',
    symbol: 'ODE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3752/thumb/odem.png?1548329893'
  },
  {
    chainId: 1,
    address: '0x9a0242b7a33dacbe40edb927834f96eb39f8fbcb',
    name: 'BABB',
    symbol: 'BAX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2168/thumb/babb.png?1547036466'
  },
  {
    chainId: 1,
    address: '0xde25486ccb4588ce5d9fb188fb6af72e768a466a',
    name: 'Contraction Dynamic',
    symbol: 'CDSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14937/thumb/T5QFuTF2_400x400.png?1619080314'
  },
  {
    chainId: 1,
    address: '0xd2d6158683aee4cc838067727209a0aaf4359de3',
    name: 'Bounty0x',
    symbol: 'BNTY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1444/thumb/bounty0x.png?1547562674'
  },
  {
    chainId: 1,
    address: '0x1a231e75538a931c395785ef5d1a5581ec622b0e',
    name: 'Zoom Protocol',
    symbol: 'ZOM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12468/thumb/zoom_protocol_logo.jpeg?1600098680'
  },
  {
    chainId: 1,
    address: '0xc67b12049c2d0cf6e476bc64c7f82fc6c63cffc5',
    name: 'Globe Derivative Ex',
    symbol: 'GDT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/15335/thumb/gdt.PNG?1620597146'
  },
  {
    chainId: 1,
    address: '0xdea67845a51e24461d5fed8084e69b426af3d5db',
    name: 'HodlTree',
    symbol: 'HTRE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12511/thumb/htre.jpg?1600373697'
  },
  {
    chainId: 1,
    address: '0x1c153badb7e54abcdcb65f0a09fcd6f10de36aa3',
    name: 'TMC',
    symbol: 'TMC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12643/thumb/tmc_logo_white_bg.png?1601371056'
  },
  {
    chainId: 1,
    address: '0x7ba19b7f7d106a9a1e0985397b94f38eee0b555e',
    name: 'Wixlar',
    symbol: 'WIX',
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/6306/thumb/JtT8UEU.png?1547042361'
  },
  {
    chainId: 1,
    address: '0xd16c79c8a39d44b2f3eb45d2019cd6a42b03e2a9',
    name: 'uUSDwETH Synthetic ',
    symbol: 'UUSDWETH-DEC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12584/thumb/uma_logo.jpg?1600937972'
  },
  {
    chainId: 1,
    address: '0xafd870f32ce54efdbf677466b612bf8ad164454b',
    name: 'iBNB',
    symbol: 'IBNB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8854/thumb/iBNB.png?1562132846'
  },
  {
    chainId: 1,
    address: '0x539efe69bcdd21a83efd9122571a64cc25e0282b',
    name: 'Blue Protocol',
    symbol: 'BLUE',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/1036/thumb/blue-protocol.jpg?1547034969'
  },
  {
    chainId: 1,
    address: '0x300a902513815028e97fc79e92082ce6a98d3b74',
    name: 'Ethersocks',
    symbol: 'SOX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14943/thumb/LOGO-for-CMC-V1.png?1619429911'
  },
  {
    chainId: 1,
    address: '0xf6abff616043c2da572573dcc583b656297b30e7',
    name: 'Custody Token',
    symbol: 'CUST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8992/thumb/Lud1DxGT_400x400.jpg?1563503751'
  },
  {
    chainId: 1,
    address: '0x88df592f8eb5d7bd38bfef7deb0fbc02cf3778a0',
    name: 'Tellor',
    symbol: 'TRB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9644/thumb/Blk_icon_current.png?1584980686'
  },
  {
    chainId: 1,
    address: '0x48cf0e2eca22eae0ad33fee16a5cb6e62207a8ab',
    name: 'YTHO Online',
    symbol: 'YTHO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13130/thumb/photo_2020-10-06_11-28-28_%282%29.jpg?1609205097'
  },
  {
    chainId: 1,
    address: '0x9d86b1b2554ec410eccffbf111a6994910111340',
    name: 'Open Platform',
    symbol: 'OPEN',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/2953/thumb/open-platform.png?1548330231'
  },
  {
    chainId: 1,
    address: '0xe1afe1fd76fd88f78cbf599ea1846231b8ba3b6b',
    name: 'sDEFI',
    symbol: 'SDEFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11832/thumb/sDEFI.png?1616150059'
  },
  {
    chainId: 1,
    address: '0xba11d00c5f74255f56a5e366f4f77f5a186d7f55',
    name: 'Band Protocol',
    symbol: 'BAND',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9545/thumb/band-protocol.png?1568730326'
  },
  {
    chainId: 1,
    address: '0x68a118ef45063051eac49c7e647ce5ace48a68a5',
    name: 'Based Money',
    symbol: 'BASED',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12115/thumb/Based.png?1597261198'
  },
  {
    chainId: 1,
    address: '0xf8e386eda857484f5a12e4b5daa9984e06e73705',
    name: 'Indorse',
    symbol: 'IND',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/848/thumb/indorse_token.png?1547034644'
  },
  {
    chainId: 1,
    address: '0xd3cdc4e75750dc1e59f8342200742b6b29490e70',
    name: 'Decurian',
    symbol: 'ECU',
    decimals: 3,
    logoURI: 'https://assets.coingecko.com/coins/images/10988/thumb/ECU-LOGO-200x200.png?1586914495'
  },
  {
    chainId: 1,
    address: '0xfaa965d80d956de59808974e33ada054f7696de5',
    name: 'BTour Chain',
    symbol: 'BTOUR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13141/thumb/BTour_Icon.png?1605595523'
  },
  {
    chainId: 1,
    address: '0x3506424f91fd33084466f402d5d97f05f8e3b4af',
    name: 'Chiliz',
    symbol: 'CHZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8834/thumb/Chiliz.png?1561970540'
  },
  {
    chainId: 1,
    address: '0x92416e32042c9e67b0771a1b00bcdf92ecb64950',
    name: 'Bulldog Token',
    symbol: 'BDOG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14843/thumb/New_Logo_200x200.png?1618648540'
  },
  {
    chainId: 1,
    address: '0x03ec7bb59be036870ef696a2abf124f496d6735a',
    name: 'Sancoj',
    symbol: 'SANC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13217/thumb/sanc.jpg?1606287002'
  },
  {
    chainId: 1,
    address: '0xf8f237d074f637d777bcd2a4712bde793f94272b',
    name: 'ERC223',
    symbol: 'ERC223',
    decimals: 10,
    logoURI: 'https://assets.coingecko.com/coins/images/10946/thumb/20200328_235557.png?1586222500'
  },
  {
    chainId: 1,
    address: '0x686c650dbcfeaa75d09b883621ad810f5952bd5d',
    name: 'AAX Token',
    symbol: 'AAB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11073/thumb/GluwoJk__400x400.jpg?1587969347'
  },
  {
    chainId: 1,
    address: '0x5273063725a43a323300c502478c22fbb4e92c2d',
    name: 'My Identity Coin',
    symbol: 'MYID',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15063/thumb/MYID-LOGO.png?1619594142'
  },
  {
    chainId: 1,
    address: '0x68d57c9a1c35f63e2c83ee8e49a64e9d70528d25',
    name: 'Sirin Labs Token',
    symbol: 'SRN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1872/thumb/sirin-labs-token.png?1548609584'
  },
  {
    chainId: 1,
    address: '0x581911b360b6eb3a14ef295a83a91dc2bce2d6f7',
    name: 'MileVerse',
    symbol: 'MVC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13146/thumb/kXSdwuxD_400x400.jpg?1605668418'
  },
  {
    chainId: 1,
    address: '0x1f6deadcb526c4710cf941872b86dcdfbbbd9211',
    name: 'Ruletka',
    symbol: 'RTK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11102/thumb/rtk-logo.png?1595212217'
  },
  {
    chainId: 1,
    address: '0x84ca8bc7997272c7cfb4d0cd3d55cd942b3c9419',
    name: 'DIA',
    symbol: 'DIA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11955/thumb/DIA-icon-colour_%281%29.png?1596423488'
  },
  {
    chainId: 1,
    address: '0x26db5439f651caf491a87d48799da81f191bdb6b',
    name: 'Casino Betting Coin',
    symbol: 'CBC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/2477/thumb/Elj3BV73_400x400.jpg?1605753220'
  },
  {
    chainId: 1,
    address: '0x5cf9242493be1411b93d064ca2e468961bbb5924',
    name: 'Empty Set Gold',
    symbol: 'ESG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13604/thumb/esg.jpg?1610252594'
  },
  {
    chainId: 1,
    address: '0xf38011f9153acffaca3fbfc42ddfa766c980d967',
    name: 'Pluto',
    symbol: 'PLUT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13349/thumb/pluto_logo.png?1607810584'
  },
  {
    chainId: 1,
    address: '0x38c87aa89b2b8cd9b95b736e1fa7b612ea972169',
    name: 'AMO Coin',
    symbol: 'AMO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4009/thumb/amo-logomark_black.png?1547039106'
  },
  {
    chainId: 1,
    address: '0xb7ba8461664de526a3ae44189727dfc768625902',
    name: 'YMPL',
    symbol: 'YMPL',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/12194/thumb/xm0vpJqS_400x400.jpg?1597984439'
  },
  {
    chainId: 1,
    address: '0xc9859fccc876e6b4b3c749c5d29ea04f48acb74f',
    name: 'Ino Coin',
    symbol: 'INO',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/4096/thumb/inologo.jpg?1547039275'
  },
  {
    chainId: 1,
    address: '0xc4199fb6ffdb30a829614beca030f9042f1c3992',
    name: 'snglsDAO Governance',
    symbol: 'SGT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11823/thumb/SGT-icon.png?1594681863'
  },
  {
    chainId: 1,
    address: '0x155ff1a85f440ee0a382ea949f24ce4e0b751c65',
    name: 'Behodler',
    symbol: 'EYE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12804/thumb/behodler_200_P.png?1614331645'
  },
  {
    chainId: 1,
    address: '0x4b742b5bdb1d252907ae7f399a891d4a178dbc24',
    name: 'B ONE PAYMENT',
    symbol: 'B1P',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10865/thumb/68rvfmWK_400x400.jpg?1585522355'
  },
  {
    chainId: 1,
    address: '0xe1329ebf8b719881549909d689987f746a8931d1',
    name: 'Refine Medium',
    symbol: 'XRM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8173/thumb/RefineMedium.png?1574851594'
  },
  {
    chainId: 1,
    address: '0xba2184520a1cc49a6159c57e61e1844e085615b6',
    name: 'HelloGold',
    symbol: 'HGT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/1005/thumb/hellogold.png?1547743862'
  },
  {
    chainId: 1,
    address: '0x0222be1f1b8413b2d7d76ebfc9e0285c1300692f',
    name: 'Glox Finance',
    symbol: 'GLOX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13312/thumb/glox_finance.png?1607395418'
  },
  {
    chainId: 1,
    address: '0x833e4c02c47b7e38f5b9a80b26eb07d23d1961f4',
    name: 'The Bitcoin Family',
    symbol: 'FAMILY',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/13776/thumb/the_bitcoin_family.png?1611712529'
  },
  {
    chainId: 1,
    address: '0x2369686fc9fb6e1fdc46541891568c2f341906ef',
    name: 'Drakoin',
    symbol: 'DRK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13094/thumb/drakoinlogo200.png?1605076487'
  },
  {
    chainId: 1,
    address: '0x16f812be7fff02caf662b85d5d58a5da6572d4df',
    name: 'United Traders Toke',
    symbol: 'UTT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/1741/thumb/utt.png?1547036018'
  },
  {
    chainId: 1,
    address: '0x1712aad2c773ee04bdc9114b32163c058321cd85',
    name: 'LimitSwap',
    symbol: 'LIMIT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12760/thumb/limit_swap_logo.png?1602347106'
  },
  {
    chainId: 1,
    address: '0x033e223870f766644f7f7a4b7dc2e91573707d06',
    name: 'Zin',
    symbol: 'ZIN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13250/thumb/coingecko_logo.png?1606716375'
  },
  {
    chainId: 1,
    address: '0xbb0e17ef65f82ab018d8edd776e8dd940327b28b',
    name: 'Axie Infinity',
    symbol: 'AXS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13029/thumb/axie_infinity_logo.png?1604471082'
  },
  {
    chainId: 1,
    address: '0xcf282ba0bc91d2aa6e775bcfd90da6b7912f1b1a',
    name: 'Yearn Ethereum Fina',
    symbol: 'YEFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13501/thumb/yefi-logo.png?1609195663'
  },
  {
    chainId: 1,
    address: '0x0fbe9cc858d7ad6e246fe9d01aed22abd2a66f0b',
    name: 'Danat Coin',
    symbol: 'DNC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13170/thumb/1528717728file.png?1605855085'
  },
  {
    chainId: 1,
    address: '0x5f778ec4b31a506c1dfd8b06f131e9b451a61d39',
    name: 'UDAP',
    symbol: 'UPX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5806/thumb/UDAP-logo.png?1547041676'
  },
  {
    chainId: 1,
    address: '0x40821cd074dfecb1524286923bc69315075b5c89',
    name: 'Quai Dao',
    symbol: 'QUAI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13815/thumb/77559276.png?1612050148'
  },
  {
    chainId: 1,
    address: '0xde4ee8057785a7e8e800db58f9784845a5c2cbd6',
    name: 'DeXe',
    symbol: 'DEXE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12713/thumb/logo_%2814%29.png?1601952779'
  },
  {
    chainId: 1,
    address: '0x901fe080ee18383bf5494049538f1bca155f4d0b',
    name: 'TrusMarketHub Token',
    symbol: 'TMH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11018/thumb/DT_LuskN_400x400.jpg?1587127687'
  },
  {
    chainId: 1,
    address: '0x0c7e25e15e9f6818fa2770107b3ba565470bc8c5',
    name: 'Decentralized Bitco',
    symbol: 'DBTC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13862/thumb/dbtc.png?1612416910'
  },
  {
    chainId: 1,
    address: '0x95daaab98046846bf4b2853e23cba236fa394a31',
    name: 'EthermonToken',
    symbol: 'EMONT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/7272/thumb/colorfull_with_word_250x250.png?1580111776'
  },
  {
    chainId: 1,
    address: '0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c',
    name: 'Enjin Coin',
    symbol: 'ENJ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1102/thumb/enjin-coin-logo.png?1547035078'
  },
  {
    chainId: 1,
    address: '0xc6d603a9df53d1542552058c382bf115aace70c7',
    name: 'Tourist Review Toke',
    symbol: 'TRET',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/7083/thumb/3XF19Ze6_400x400.jpg?1547043555'
  },
  {
    chainId: 1,
    address: '0x4981553e8ccf6df916b36a2d6b6f8fc567628a51',
    name: 'Betnomi',
    symbol: 'BNI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13329/thumb/betnomi.png?1607503078'
  },
  {
    chainId: 1,
    address: '0xa6b9d7e3d76cf23549293fb22c488e0ea591a44e',
    name: 'uGAS JUN21 Token Ex',
    symbol: 'UGAS-JUN21',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14439/thumb/uGAS_Logo_200x200.png?1616064129'
  },
  {
    chainId: 1,
    address: '0xeb9a4b185816c354db92db09cc3b50be60b901b6',
    name: 'Origin Sport',
    symbol: 'ORS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4646/thumb/origin-sport-logo.png?1547039912'
  },
  {
    chainId: 1,
    address: '0x675e7d927af7e6d0082e0153dc3485b687a6f0ad',
    name: 'Creed Finance',
    symbol: 'CREED',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12437/thumb/creed_finance_logo.jpg?1599840364'
  },
  {
    chainId: 1,
    address: '0x7b2df125567815ac9b57da04b620f50bc93b320c',
    name: 'Archetypal Network',
    symbol: 'ACTP',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/5946/thumb/logo_%284%29.png?1547041885'
  },
  {
    chainId: 1,
    address: '0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359',
    name: 'Sai',
    symbol: 'SAI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1442/thumb/dai.png?1547035520'
  },
  {
    chainId: 1,
    address: '0xebdf9a7ae0009b958c6d09501eb9ac1dafeb31ab',
    name: 'REDi',
    symbol: 'REDI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6329/thumb/token_blue.png?1566515068'
  },
  {
    chainId: 1,
    address: '0xd46ba6d942050d489dbd938a2c909a5d5039a161',
    name: 'Ampleforth',
    symbol: 'AMPL',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/4708/thumb/Ampleforth.png?1561684250'
  },
  {
    chainId: 1,
    address: '0x3db1678170418d1014012f855e2dda492f35c289',
    name: 'ISHOP PLUS',
    symbol: 'ISP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10740/thumb/ISP-LOGO200x200.jpg?1582778989'
  },
  {
    chainId: 1,
    address: '0xa8262eb913fccea4c3f77fc95b8b4043b384cfbb',
    name: 'Krypton Galaxy Coin',
    symbol: 'KGC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9152/thumb/krypton.PNG?1564733517'
  },
  {
    chainId: 1,
    address: '0x734c90044a0ba31b3f2e640c10dc5d3540499bfd',
    name: 'TradeStars',
    symbol: 'TSX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15229/thumb/WsO9siKG_400x400.png?1620167669'
  },
  {
    chainId: 1,
    address: '0x1c5f43710a1776b0ea7191b7ead75d4b98d69858',
    name: 'Pool of Stake',
    symbol: 'PSK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3494/thumb/Logo_square.png?1547038247'
  },
  {
    chainId: 1,
    address: '0x66e5d4063219a54a8244078affb49e23982d9640',
    name: 'UZURAS',
    symbol: 'UZZ',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/7306/thumb/uzura.png?1572096519'
  },
  {
    chainId: 1,
    address: '0xa960d2ba7000d58773e7fa5754dec3bb40a069d5',
    name: 'One DEX',
    symbol: 'ODEX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7712/thumb/WzsJ6pIr_400x400.jpg?1549940214'
  },
  {
    chainId: 1,
    address: '0x94939d55000b31b7808904a80aa7bab05ef59ed6',
    name: 'Jiaozi',
    symbol: 'JIAOZI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12442/thumb/JiaoziFarm.png?1599888576'
  },
  {
    chainId: 1,
    address: '0xb55732a15b3dc5d6e8d4adf40634f37470da71fa',
    name: 'ECPN Token',
    symbol: 'ECPN',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9312/thumb/favicon-196x196.png?1566117837'
  },
  {
    chainId: 1,
    address: '0x8eef5a82e6aa222a60f009ac18c24ee12dbf4b41',
    name: 'Tixl',
    symbol: 'TXL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12432/thumb/Tixl-Logo-200-transparent.png?1610248504'
  },
  {
    chainId: 1,
    address: '0xe49214e4c92dc9bcb3b56c1309afe0d626dd730e',
    name: 'SynchroLife',
    symbol: 'SYC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1469/thumb/ud643DVg.png?1621828021'
  },
  {
    chainId: 1,
    address: '0x06141f60ee56c8ecc869f46568e2cb1e66baaf41',
    name: '24 Genesis Mooncats',
    symbol: 'GMC24',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15438/thumb/1_SGrC_o6GxAZm1t3eljkPsg.png?1620803094'
  },
  {
    chainId: 1,
    address: '0xcd39b5434a0a92cf47d1f567a7df84be356814f0',
    name: 's1INCH',
    symbol: 'S1INCH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14952/thumb/s1INCH.png?1619129330'
  },
  {
    chainId: 1,
    address: '0xca694eb79ef355ea0999485d211e68f39ae98493',
    name: 'Traceability Chain',
    symbol: 'TAC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/5813/thumb/traceability.png?1547041683'
  },
  {
    chainId: 1,
    address: '0xc7e43a1c8e118aa2965f5eabe0e718d83db7a63c',
    name: 'ZCore Token',
    symbol: 'ZCRT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11113/thumb/ZCRT.png?1590979772'
  },
  {
    chainId: 1,
    address: '0x95e40e065afb3059dcabe4aaf404c1f92756603a',
    name: 'King DAG',
    symbol: 'KDAG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10809/thumb/3xcLUorv_400x400.jpg?1591000563'
  },
  {
    chainId: 1,
    address: '0xacd8f2523a4613eee78904354187c81bb05ae2b8',
    name: 'Stand Cash',
    symbol: 'SAC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13542/thumb/sac.jpg?1609648101'
  },
  {
    chainId: 1,
    address: '0x3d61e677944204cd1002202912a2b7a43a8e2823',
    name: 'New USDf',
    symbol: 'USDF',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14871/thumb/K-wEfxTZ_400x400.jpg?1618817701'
  },
  {
    chainId: 1,
    address: '0xd3c00772b24d997a812249ca637a921e81357701',
    name: 'Wild Crypto',
    symbol: 'WILD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/992/thumb/wild-crypto.png?1548760618'
  },
  {
    chainId: 1,
    address: '0x930ed81ad809603baf727117385d01f04354612e',
    name: 'Solarite',
    symbol: 'SOLARITE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12430/thumb/solarite_logo.jpg?1599785957'
  },
  {
    chainId: 1,
    address: '0xc4cb5793bd58bad06bf51fb37717b86b02cbe8a4',
    name: 'PROXI DeFi',
    symbol: 'CREDIT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12212/thumb/_credit.png?1598235420'
  },
  {
    chainId: 1,
    address: '0x3936ad01cf109a36489d93cabda11cf062fd3d48',
    name: 'Coil',
    symbol: 'COIL',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/12234/thumb/Coil_-_Logo_open_%28256x256%29_tp.png?1598519979'
  },
  {
    chainId: 1,
    address: '0x8af22fbdefe01b4dc7960ec04ec73e8502f4a6b0',
    name: 'Biokkoin',
    symbol: 'BKKG',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13125/thumb/7F-imH-r_400x400.png?1605496322'
  },
  {
    chainId: 1,
    address: '0xd26a9c3437f7d121098c8c05c7413f5cc70bb070',
    name: 'Azuma Coin',
    symbol: 'AZUM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10483/thumb/WDncHJ5.png?1580161760'
  },
  {
    chainId: 1,
    address: '0xb9eefc4b0d472a44be93970254df4f4016569d27',
    name: 'DigitalBits',
    symbol: 'XDB',
    decimals: 7,
    logoURI: 'https://assets.coingecko.com/coins/images/8089/thumb/digitalbits-logo.jpg?1554454902'
  },
  {
    chainId: 1,
    address: '0xe9ed705842bd7219a963e452c41be85ce5cff884',
    name: 'JoyTube Token',
    symbol: 'JTT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10259/thumb/joytube-logo.jpeg?1576795283'
  },
  {
    chainId: 1,
    address: '0x668c50b1c7f46effbe3f242687071d7908aab00a',
    name: 'CoShi Inu',
    symbol: 'COSHI',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15219/thumb/200.jpg?1620119215'
  },
  {
    chainId: 1,
    address: '0x2d39ec4da54329d28d230b4973f5aa27886c3aee',
    name: 'NFX Coin',
    symbol: 'NFXC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10694/thumb/nfx.png?1604656044'
  },
  {
    chainId: 1,
    address: '0x79cdfa04e3c4eb58c4f49dae78b322e5b0d38788',
    name: 'Truefeedback Token',
    symbol: 'TFB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8842/thumb/5rd7a55q_400x400.png?1562902557'
  },
  {
    chainId: 1,
    address: '0x0766e79a6fd74469733e8330b3b461c0320ff059',
    name: 'ExchangeN',
    symbol: 'EXN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1016/thumb/exchangen.png?1547034929'
  },
  {
    chainId: 1,
    address: '0x6c972b70c533e2e045f333ee28b9ffb8d717be69',
    name: 'FoundryDAO Logistic',
    symbol: 'FRY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12348/thumb/fry-icon.png?1614064283'
  },
  {
    chainId: 1,
    address: '0xa17de0ab0a97bc5e56fa8b39ebfc81cc3f1f349e',
    name: 'DefiKing',
    symbol: 'DFK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12405/thumb/dfklogo.png?1599603745'
  },
  {
    chainId: 1,
    address: '0xd1ef9a7310d0806855c672288ef5a1bab62cef33',
    name: 'BELIEVER',
    symbol: 'BLVR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11662/thumb/belevier_logo.png?1592432917'
  },
  {
    chainId: 1,
    address: '0x70fadbe1f2cccbaf98ac88fdcf94a0509a48e46d',
    name: 'Green Light',
    symbol: 'GL',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11015/thumb/greenlight.PNG?1587114464'
  },
  {
    chainId: 1,
    address: '0x0f17bc9a994b87b5225cfb6a2cd4d667adb4f20b',
    name: 'Jarvis Synthetic Eu',
    symbol: 'JEUR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15725/thumb/logo_-_2021-05-22T063621.432.png?1621636593'
  },
  {
    chainId: 1,
    address: '0xccbf21ba6ef00802ab06637896b799f7101f54a2',
    name: 'Budbo',
    symbol: 'BUBO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1779/thumb/IMG_7712.png?1594016157'
  },
  {
    chainId: 1,
    address: '0xd18a8abed9274edbeace4b12d86a8633283435da',
    name: 'UnoSwap',
    symbol: 'UNOS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13194/thumb/slazzer-edit-image.png?1606115031'
  },
  {
    chainId: 1,
    address: '0xb2dbf14d0b47ed3ba02bdb7c954e05a72deb7544',
    name: 'MobiFi',
    symbol: 'MOFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14697/thumb/thumbnail.png?1618978255'
  },
  {
    chainId: 1,
    address: '0x2688213fedd489762a281a67ae4f2295d8e17ecc',
    name: 'FUD finance',
    symbol: 'FUD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12727/thumb/fud_finance_logo.png?1602055414'
  },
  {
    chainId: 1,
    address: '0x0abdace70d3790235af448c88547603b945604ea',
    name: 'district0x',
    symbol: 'DNT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/849/thumb/district0x.png?1547223762'
  },
  {
    chainId: 1,
    address: '0xf80d589b3dbe130c270a69f1a69d050f268786df',
    name: 'Datamine',
    symbol: 'DAM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11695/thumb/qxsFH8W.png?1592880463'
  },
  {
    chainId: 1,
    address: '0x6ee0f7bb50a54ab5253da0667b0dc2ee526c30a8',
    name: 'Aave BUSD v1',
    symbol: 'ABUSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11736/thumb/aBUSD.png?1593085489'
  },
  {
    chainId: 1,
    address: '0xa2085073878152ac3090ea13d1e41bd69e60dc99',
    name: 'Escoin Token',
    symbol: 'ELG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13566/thumb/escoin-200.png?1609833886'
  },
  {
    chainId: 1,
    address: '0x9570ec7ab05d61877ff7eb180f837c7c079c4844',
    name: 'Gaps Chain',
    symbol: 'GAP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9462/thumb/vvXw-NQ6_400x400.jpg?1569317066'
  },
  {
    chainId: 1,
    address: '0xd26114cd6ee289accf82350c8d8487fedb8a0c07',
    name: 'OMG Network',
    symbol: 'OMG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/776/thumb/OMG_Network.jpg?1591167168'
  },
  {
    chainId: 1,
    address: '0x2e2f3246b6c65ccc4239c9ee556ec143a7e5de2c',
    name: 'Yfi mobi',
    symbol: 'YFIM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13063/thumb/yfim.jpg?1604848218'
  },
  {
    chainId: 1,
    address: '0x8b1f49491477e0fb46a29fef53f1ea320d13c349',
    name: 'MicroMoney',
    symbol: 'AMM',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/1356/thumb/micromoney.png?1548085045'
  },
  {
    chainId: 1,
    address: '0xf0be50ed0620e0ba60ca7fc968ed14762e0a5dd3',
    name: 'Cowboy Finance',
    symbol: 'COW',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/12228/thumb/cowboy.png?1598309446'
  },
  {
    chainId: 1,
    address: '0x73f2651ea38d48b3b808ffddbd398fd600ab5ba7',
    name: 'Dukascoin',
    symbol: 'DUK+',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/14374/thumb/dukascoin_200x200_silver.png?1615774652'
  },
  {
    chainId: 1,
    address: '0x6876eba317272fe221c67405c5e8eb3b24535547',
    name: 'MicroTuber',
    symbol: 'MCT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15489/thumb/mct.PNG?1621040638'
  },
  {
    chainId: 1,
    address: '0xfb5a551374b656c6e39787b1d3a03feab7f3a98e',
    name: 'ThingsOperatingSyst',
    symbol: 'TOS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5282/thumb/tos-logo.png?1547040796'
  },
  {
    chainId: 1,
    address: '0x952b65d976e8669c4ce92a17cce5b2586912adb5',
    name: 'Etrade',
    symbol: 'ETT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9111/thumb/NkGLvEO2_400x400.jpg?1564435046'
  },
  {
    chainId: 1,
    address: '0x70a63225bcadacc4430919f0c1a4f0f5fcffbaac',
    name: 'VEY',
    symbol: 'VEY',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/7282/thumb/dkDh4z0a_400x400.jpg?1547043914'
  },
  {
    chainId: 1,
    address: '0xd387f0e62e3f123a54ae486056a5d859affed0c8',
    name: 'Fyeth finance',
    symbol: 'YETH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13357/thumb/Untitled-design-4-1.png?1607813289'
  },
  {
    chainId: 1,
    address: '0x31fdd1c6607f47c14a2821f599211c67ac20fa96',
    name: 'Burency',
    symbol: 'BUY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12216/thumb/Ddphl9BD.png?1598238121'
  },
  {
    chainId: 1,
    address: '0xff2b3353c3015e9f1fbf95b9bda23f58aa7ce007',
    name: 'BitScreener',
    symbol: 'BITX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4241/thumb/bitx.png?1547039570'
  },
  {
    chainId: 1,
    address: '0xcb696c86917175dfb4f0037ddc4f2e877a9f081a',
    name: 'MoonDayPlus',
    symbol: 'MD+',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14464/thumb/md__logo.jpg?1616303384'
  },
  {
    chainId: 1,
    address: '0x9afb950948c2370975fb91a441f36fdc02737cd4',
    name: 'Huobi Fil',
    symbol: 'HFIL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14109/thumb/HFIL.png?1614334127'
  },
  {
    chainId: 1,
    address: '0x03806ce5ef69bd9780edfb04c29da1f23db96294',
    name: 'Tesla Token',
    symbol: 'TSL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1222/thumb/energo.png?1547035249'
  },
  {
    chainId: 1,
    address: '0x767fe9edc9e0df98e07454847909b5e959d7ca0e',
    name: 'Illuvium',
    symbol: 'ILV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14468/thumb/ILV.JPG?1617182121'
  },
  {
    chainId: 1,
    address: '0x765f0c16d1ddc279295c1a7c24b0883f62d33f75',
    name: 'DaTa eXchange Token',
    symbol: 'DTX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2920/thumb/dtx.png?1578973961'
  },
  {
    chainId: 1,
    address: '0x3d1be3fef769399cce7e504e85324d622f23cf85',
    name: 'Tulip Seed',
    symbol: 'STLP',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13108/thumb/tulip_logo.jpg?1605258713'
  },
  {
    chainId: 1,
    address: '0xd36e9f8f194a47b10af16c7656a68eba1dfe88e4',
    name: 'Atlas Network',
    symbol: 'ATLS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5040/thumb/274ea226-5c88-4e30-a144-5e321e799ed7.jpg?1547040456'
  },
  {
    chainId: 1,
    address: '0x1614f18fc94f47967a3fbe5ffcd46d4e7da3d787',
    name: 'PAID Network',
    symbol: 'PAID',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13761/thumb/PAID.png?1612493556'
  },
  {
    chainId: 1,
    address: '0x7841b2a48d1f6e78acec359fed6d874eb8a0f63c',
    name: 'KERMAN',
    symbol: 'KERMAN',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/11536/thumb/Kerman.png?1590776066'
  },
  {
    chainId: 1,
    address: '0x1062fdf250b44697216d07e41df93824519f47aa',
    name: 'Cryptolandy',
    symbol: 'CRYPL',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11046/thumb/crypl.png?1587526012'
  },
  {
    chainId: 1,
    address: '0xc5bbae50781be1669306b9e001eff57a2957b09d',
    name: 'Gifto',
    symbol: 'GTO',
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/1359/thumb/gifto.png?1547742697'
  },
  {
    chainId: 1,
    address: '0x14d1c83df4decee9deb14ee851f109f0101a6631',
    name: 'Volts Finance',
    symbol: 'VOLTS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13115/thumb/volts_logo.png?1605367400'
  },
  {
    chainId: 1,
    address: '0xae746520ffdb15d0505e32f1d6e9a2b4ab866572',
    name: 'MorCrypto Coin',
    symbol: 'MOR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10830/thumb/Fs2GJFC__400x400.jpg?1584394834'
  },
  {
    chainId: 1,
    address: '0x7e442206da059905050ba02be63cbb85c559eb04',
    name: 'French ICO Coin',
    symbol: 'FICO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12528/thumb/Icon-French-ICO.png?1600495093'
  },
  {
    chainId: 1,
    address: '0xba100000625a3754423978a60c9317c58a424e3d',
    name: 'Balancer',
    symbol: 'BAL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11683/thumb/Balancer.png?1592792958'
  },
  {
    chainId: 1,
    address: '0xe35f19e4457a114a951781aaf421ec5266ef25fe',
    name: 'Vidiachange',
    symbol: 'VIDA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14346/thumb/vidia.png?1615504632'
  },
  {
    chainId: 1,
    address: '0xb9464ef80880c5aea54c7324c0b8dd6ca6d05a90',
    name: 'LOCK Token',
    symbol: 'LOCK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12429/thumb/sherlock.jpg?1599780187'
  },
  {
    chainId: 1,
    address: '0x542156d51d10db5accb99f9db7e7c91b74e80a2c',
    name: 'ETH LINK Price Acti',
    symbol: 'LINKETHPA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10924/thumb/ethlink_price_action_candlestick_set.png?1585893962'
  },
  {
    chainId: 1,
    address: '0x4575f41308ec1483f3d399aa9a2826d74da13deb',
    name: 'Orchid Protocol',
    symbol: 'OXT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3916/thumb/download_%285%29.png?1576624060'
  },
  {
    chainId: 1,
    address: '0x0142c3b2fc51819b5af5dfc4aa52df9722790851',
    name: 'Paycent',
    symbol: 'PYN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2008/thumb/paycentos-logo-maroon.png?1547036284'
  },
  {
    chainId: 1,
    address: '0x38acefad338b870373fb8c810fe705569e1c7225',
    name: 'Yearn4 Finance',
    symbol: 'YF4',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12683/thumb/yearn4logo.png?1601621780'
  },
  {
    chainId: 1,
    address: '0x0fe156436f203b114c6c562cb1a2a81aa2801090',
    name: 'SKINCHAIN',
    symbol: 'SKC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10878/thumb/8ZtRME2h_400x400.jpg?1585472542'
  },
  {
    chainId: 1,
    address: '0x813b428af3920226e059b68a62e4c04933d4ea7a',
    name: 'Decentralized Asset',
    symbol: 'DATP',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/6565/thumb/Decentralized_Asset_Trading_Platform.jpg?1547042776'
  },
  {
    chainId: 1,
    address: '0x84679bc467dc6c2c40ab04538813aff3796351f1',
    name: 'Chonk',
    symbol: 'CHONK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12899/thumb/a2LHjXZ.jpeg?1603418225'
  },
  {
    chainId: 1,
    address: '0xff603f43946a3a28df5e6a73172555d8c8b02386',
    name: 'OneRoot Network',
    symbol: 'RNT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2520/thumb/OneRootNetwork.PNG?1558014608'
  },
  {
    chainId: 1,
    address: '0x15a664416e42766a6cc0a1221d9c088548a6e731',
    name: 'WEBN token',
    symbol: 'WEBN',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/6612/thumb/200x200.png?1547042814'
  },
  {
    chainId: 1,
    address: '0x6243d8cea23066d098a15582d81a598b4e8391f4',
    name: 'Reflexer Ungovernan',
    symbol: 'FLX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14123/thumb/EAfYdwgd_400x400.jpg?1614564508'
  },
  {
    chainId: 1,
    address: '0x79c71d3436f39ce382d0f58f1b011d88100b9d91',
    name: 'Xeonbit Token',
    symbol: 'XNS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8744/thumb/200x200_icon_darkbg.png?1560826732'
  },
  {
    chainId: 1,
    address: '0xfc0d6cf33e38bce7ca7d89c0e292274031b7157a',
    name: 'Netvrk',
    symbol: 'NTVRK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15721/thumb/logo-h.png?1621635084'
  },
  {
    chainId: 1,
    address: '0x93e01899c10532d76c0e864537a1d26433dbbddb',
    name: 'ETH RSI 60 40 Cross',
    symbol: 'ETHRSI6040',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10609/thumb/eth-rsi-40-60-set.png?1580970994'
  },
  {
    chainId: 1,
    address: '0xe138fda441fc31b36171122397a8a11d6cd2c479',
    name: 'Global Trust Coin',
    symbol: 'GTC',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/7752/thumb/gtib.png?1550222444'
  },
  {
    chainId: 1,
    address: '0xfa3118b34522580c35ae27f6cf52da1dbb756288',
    name: 'Linkeye',
    symbol: 'LET',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/1814/thumb/linkeye.png?1547975964'
  },
  {
    chainId: 1,
    address: '0xb688a7b1472e2427c338b975d77e12389ecf2558',
    name: 'INBOX TOKEN',
    symbol: 'INBOX',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/7052/thumb/v96_5YY8_400x400.jpg?1547043504'
  },
  {
    chainId: 1,
    address: '0x06147110022b768ba8f99a8f385df11a151a9cc8',
    name: 'ACE',
    symbol: 'ACE',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/1672/thumb/ace-token-logo.jpg?1547035926'
  },
  {
    chainId: 1,
    address: '0x8d80de8a78198396329dfa769ad54d24bf90e7aa',
    name: 'Nami Trade',
    symbol: 'NAC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3073/thumb/nami.trade.png?1547037422'
  },
  {
    chainId: 1,
    address: '0x4a64515e5e1d1073e83f30cb97bed20400b66e10',
    name: 'Wrapped Zcash',
    symbol: 'WZEC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13239/thumb/WZEC-icon.png?1606630700'
  },
  {
    chainId: 1,
    address: '0x564393b8d6deaea8f3d739a3f6d9b441d8ee6198',
    name: 'AMIX',
    symbol: 'AMIX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12154/thumb/amix_logo.png?1597701271'
  },
  {
    chainId: 1,
    address: '0x4fd5b9b5dcc9a5d5931d007ba4ae573e760d9b64',
    name: 'Morality',
    symbol: 'MO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12961/thumb/SRy1GCaK_400x400.png?1603853062'
  },
  {
    chainId: 1,
    address: '0x261efcdd24cea98652b9700800a13dfbca4103ff',
    name: 'sXAU',
    symbol: 'SXAU',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11559/thumb/sXAU.png?1616150843'
  },
  {
    chainId: 1,
    address: '0xef6344de1fcfc5f48c30234c16c1389e8cdc572c',
    name: 'EncrypGen',
    symbol: 'DNA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1215/thumb/encrypgen.png?1547393601'
  },
  {
    chainId: 1,
    address: '0x8d6b0fcdc476dac3529f6f8cada214612892b347',
    name: 'Vallix',
    symbol: 'VLX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10244/thumb/_7vpZd-g_400x400.png?1576634488'
  },
  {
    chainId: 1,
    address: '0xd2be3722b17b616c51ed9b8944a227d1ce579c24',
    name: 'Dtube Coin',
    symbol: 'DTUBE',
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/13126/thumb/dtube_logo.png?1605500467'
  },
  {
    chainId: 1,
    address: '0x5befbb272290dd5b8521d4a938f6c4757742c430',
    name: 'Xfinance',
    symbol: 'XFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12449/thumb/go.png?1599904281'
  },
  {
    chainId: 1,
    address: '0x86d1d12523b65203851c571fcc029bf90903fb6d',
    name: 'Bintex Futures',
    symbol: 'BNTX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12117/thumb/Bintexfutures_Icon_Logo.png?1597272672'
  },
  {
    chainId: 1,
    address: '0x604026696fdb3c6720ae3049c46d59ac604dea0a',
    name: 'eXciting Japan Coin',
    symbol: 'XJP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11963/thumb/XJP_LOGO.png?1596453963'
  },
  {
    chainId: 1,
    address: '0x0fd10b9899882a6f2fcb5c371e17e70fdee00c38',
    name: 'Pundi X',
    symbol: 'PUNDIX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14571/thumb/vDyefsXq_400x400.jpg?1617085003'
  },
  {
    chainId: 1,
    address: '0xe4fa3c576c31696322e8d7165c5965d5a1f6a1a5',
    name: 'GamyFi Token',
    symbol: 'GFX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14559/thumb/circle-cropped_%281%29.png?1617008124'
  },
  {
    chainId: 1,
    address: '0xd536bbd5414a8c2beed82a63737b9327d2fa35a6',
    name: 'Aitheon',
    symbol: 'ACU',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3008/thumb/5NGo-O_T_400x400.png?1561446178'
  },
  {
    chainId: 1,
    address: '0xc761d1ccb38a94703675d2cdb15f7f1b3dcff7b7',
    name: 'Hiz Finance',
    symbol: 'HIZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12974/thumb/73051179.png?1603959145'
  },
  {
    chainId: 1,
    address: '0xe53ec727dbdeb9e2d5456c3be40cff031ab40a55',
    name: 'SuperFarm',
    symbol: 'SUPER',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14040/thumb/6YPdWn6.png?1613975899'
  },
  {
    chainId: 1,
    address: '0x6767615a97a8204298bbac44c57ef1a615eed244',
    name: 'Cribnb',
    symbol: 'CRB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15775/thumb/11111.png?1621835123'
  },
  {
    chainId: 1,
    address: '0xdc7836bc6c84c51275365e337fd147db2dc7b3c3',
    name: 'RRSpace',
    symbol: 'RRC',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/7536/thumb/36516552_137244937169359_1773558825933078528_n.jpg?1548233442'
  },
  {
    chainId: 1,
    address: '0x7e6509ac567e7de3bc702e7caf3b56245b50d1a7',
    name: 'Swace',
    symbol: 'SWACE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3727/thumb/swace-symbol.png?1564553792'
  },
  {
    chainId: 1,
    address: '0x66bad545596fb17a0b4ebdc003a85def10e8f6ae',
    name: 'Wiki Token',
    symbol: 'WIKI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5414/thumb/wiki-token-logo.jpg?1547041078'
  },
  {
    chainId: 1,
    address: '0xc0f1728d9513efc316d0e93a0758c992f88b0809',
    name: 'SWTCoin',
    symbol: 'SWAT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/5985/thumb/swtcoin.jpg?1547041927'
  },
  {
    chainId: 1,
    address: '0x4de2573e27e648607b50e1cfff921a33e4a34405',
    name: 'Lendroid Support To',
    symbol: 'LST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3485/thumb/lst-icon.png?1606992361'
  },
  {
    chainId: 1,
    address: '0xb8baa0e4287890a5f79863ab62b7f175cecbd433',
    name: 'Swerve',
    symbol: 'SWRV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12361/thumb/swerve.png?1599278316'
  },
  {
    chainId: 1,
    address: '0x39aa39c021dfbae8fac545936693ac917d5e7563',
    name: 'cUSDC',
    symbol: 'CUSDC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9442/thumb/Compound_USDC.png?1567581577'
  },
  {
    chainId: 1,
    address: '0x9d5963ba32e877871dff3e2e697283dc64066271',
    name: 'Edcoin',
    symbol: 'EDC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13872/thumb/EDCOIN-1.png?1612447671'
  },
  {
    chainId: 1,
    address: '0xcc12abe4ff81c9378d670de1b57f8e0dd228d77a',
    name: 'Aave REN',
    symbol: 'AREN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14249/thumb/aREN.382be386.png?1615528633'
  },
  {
    chainId: 1,
    address: '0xb393cd041afc1aca4c380c663793fe2c7b8a0212',
    name: 'NanTrade',
    symbol: 'NAN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11049/thumb/logo_%2871%29.png?1587537446'
  },
  {
    chainId: 1,
    address: '0x160b1e5aabfd70b2fc40af815014925d71ceed7e',
    name: 'StakedFIRO',
    symbol: 'STFIRO',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12985/thumb/stFIRO_high_res.png?1606234476'
  },
  {
    chainId: 1,
    address: '0x9bbc9286cdf6eefebf21df19bac71c6bdd7759d4',
    name: 'QUEENBEE',
    symbol: 'QBZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10772/thumb/1nIgm-Ut_400x400.jpg?1583377902'
  },
  {
    chainId: 1,
    address: '0x7c5ff719a6c76fe643e9ecd0f11f146a2de05f14',
    name: 'Ganesha Token',
    symbol: 'GNSH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15214/thumb/D0JX7d1.png?1620110966'
  },
  {
    chainId: 1,
    address: '0xe1a178b681bd05964d3e3ed33ae731577d9d96dd',
    name: 'BOX Token',
    symbol: 'BOX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6511/thumb/box-token.png?1547563043'
  },
  {
    chainId: 1,
    address: '0x6fa0952355607dfb2d399138b7fe10eb90f245e4',
    name: 'Clash Token',
    symbol: 'SCT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13805/thumb/SZKnadlg_400x400.png?1612001081'
  },
  {
    chainId: 1,
    address: '0xec0bf0e934d092d31e769e8c9722ffbaa582db3b',
    name: 'Shikokuaido',
    symbol: 'SHOKK',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15437/thumb/icon.jpg?1620801547'
  },
  {
    chainId: 1,
    address: '0x7c8155909cd385f120a56ef90728dd50f9ccbe52',
    name: 'Nahmii',
    symbol: 'NII',
    decimals: 15,
    logoURI: 'https://assets.coingecko.com/coins/images/9786/thumb/nahmii-sm_icon-full-color.png?1608513773'
  },
  {
    chainId: 1,
    address: '0xffc16ab7d366273466c705f03cfeaca8dd964f89',
    name: 'Kansas City Chiefs ',
    symbol: 'CHIEFS',
    decimals: 15,
    logoURI: 'https://assets.coingecko.com/coins/images/13885/thumb/chiefs_logo.png?1612497638'
  },
  {
    chainId: 1,
    address: '0xe8ed08a581777f112654e28de507e11613da0379',
    name: 'Yearn Finance Cente',
    symbol: 'YFC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13199/thumb/yfc_logo.png?1606186020'
  },
  {
    chainId: 1,
    address: '0x00fc270c9cc13e878ab5363d00354bebf6f05c15',
    name: 'VNX Exchange',
    symbol: 'VNXLU',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9945/thumb/vnx.PNG?1573639467'
  },
  {
    chainId: 1,
    address: '0x7ace84ac394e41f1c01711ed134d9bbcfa770ce7',
    name: 'Diagon',
    symbol: 'DGN',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/12518/thumb/BR90HY-d_400x400.jpg?1600382926'
  },
  {
    chainId: 1,
    address: '0xb5fe099475d3030dde498c3bb6f3854f762a48ad',
    name: 'Fnk com',
    symbol: 'FNK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13483/thumb/fnk.png?1609039834'
  },
  {
    chainId: 1,
    address: '0x3335f16af9008bfd32f1ee6c2be5d4f84fa0b9da',
    name: '3X Long Dragon Inde',
    symbol: 'DRGNBULL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10197/thumb/683JEXMN_400x400_%281%29.png?1576533729'
  },
  {
    chainId: 1,
    address: '0x5ca9a71b1d01849c0a95490cc00559717fcf0d1d',
    name: 'Aeternity',
    symbol: 'AE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1091/thumb/aeternity.png?1547035060'
  },
  {
    chainId: 1,
    address: '0x35de3eccaccb02e627062b5d63aa941b137288fe',
    name: 'Value Set Dollar',
    symbol: 'VSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14321/thumb/7AAErx1U_400x400.jpg?1615425566'
  },
  {
    chainId: 1,
    address: '0xbd2949f67dcdc549c6ebe98696449fa79d988a9f',
    name: 'Meter Governance ma',
    symbol: 'EMTRG',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/12175/thumb/Dark-blue-icon-transparent-vector-white-icon200x200.png?1597819237'
  },
  {
    chainId: 1,
    address: '0xe48972fcd82a274411c01834e2f031d4377fa2c0',
    name: '2key network',
    symbol: '2KEY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6090/thumb/2key.png?1591591830'
  },
  {
    chainId: 1,
    address: '0x0e1fe60bc4ac0e3102343752ae7e49d01d444c0b',
    name: 'Havens Nook',
    symbol: 'HXN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14876/thumb/havennook.PNG?1618881827'
  },
  {
    chainId: 1,
    address: '0xa393473d64d2f9f026b60b6df7859a689715d092',
    name: 'Lattice Token',
    symbol: 'LTX',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13050/thumb/Lattice.jpg?1613976295'
  },
  {
    chainId: 1,
    address: '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2',
    name: 'Maker',
    symbol: 'MKR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1364/thumb/Mark_Maker.png?1585191826'
  },
  {
    chainId: 1,
    address: '0xe4e822c0d5b329e8bb637972467d2e313824efa0',
    name: 'Dfinance',
    symbol: 'XFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14002/thumb/7223.png?1613579385'
  },
  {
    chainId: 1,
    address: '0x53884b61963351c283118a8e1fc05ba464a11959',
    name: 'Monnos',
    symbol: 'MNS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11640/thumb/logo_%2880%29.png?1592274153'
  },
  {
    chainId: 1,
    address: '0x1a5f9352af8af974bfc03399e3767df6370d82e4',
    name: 'OWL',
    symbol: 'OWL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11149/thumb/gnosis-owl_32.png?1589057849'
  },
  {
    chainId: 1,
    address: '0x4fabb145d64652a948d72533023f6e7a623c7c53',
    name: 'Binance USD',
    symbol: 'BUSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9576/thumb/BUSD.png?1568947766'
  },
  {
    chainId: 1,
    address: '0x4ca0654f4fc1025cf1a17b7459c20ac0479522ad',
    name: 'Rigel Finance',
    symbol: 'RIGEL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13674/thumb/logo-200x200_%282%29.png?1610682780'
  },
  {
    chainId: 1,
    address: '0xb1e93236ab6073fdac58ada5564897177d4bcc43',
    name: 'Seele',
    symbol: 'SEELE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3294/thumb/seele.png?1548608910'
  },
  {
    chainId: 1,
    address: '0xed42cedcadbfbcaa3e6f411b09567c2c0b5ad28f',
    name: 'RealToken 9336 Patt',
    symbol: 'REALTOKEN-9336-PATT',
    decimals: 18
  },
  {
    chainId: 1,
    address: '0x24a6a37576377f63f194caa5f518a60f45b42921',
    name: 'Float Protocol',
    symbol: 'BANK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14116/thumb/FLOAT-Bank_LOGO-reduced_01.png?1616573606'
  },
  {
    chainId: 1,
    address: '0x3c9d6c1c73b31c837832c72e04d3152f051fc1a9',
    name: 'BoringDAO',
    symbol: 'BOR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12917/thumb/bor_logo.png?1603607502'
  },
  {
    chainId: 1,
    address: '0x1e71034c89dd191accb27dc35f18a3d8b6f91311',
    name: 'Free Tool Box',
    symbol: 'FTB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12188/thumb/logo-full-white.png?1597962794'
  },
  {
    chainId: 1,
    address: '0xcb8d1260f9c92a3a545d409466280ffdd7af7042',
    name: 'NFT Protocol',
    symbol: 'NFT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12174/thumb/nftprotocol_32.png?1597818115'
  },
  {
    chainId: 1,
    address: '0x8f5e78888f8933ed676a62bb6b58a4b279abe408',
    name: 'stakedXYM',
    symbol: 'STXYM',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/14787/thumb/stXYM.png?1618436747'
  },
  {
    chainId: 1,
    address: '0x80fb784b7ed66730e8b1dbd9820afd29931aab03',
    name: 'Aave  OLD ',
    symbol: 'LEND',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1365/thumb/ethlend.png?1547394586'
  },
  {
    chainId: 1,
    address: '0x490e3f4af13e1616ec97a8c6600c1061a8d0253e',
    name: 'Terran Coin',
    symbol: 'TRR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15351/thumb/TERRAN-500px.png?1620626066'
  },
  {
    chainId: 1,
    address: '0x10ba8c420e912bf07bedac03aa6908720db04e0c',
    name: 'Raise Token',
    symbol: 'RAISE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4411/thumb/Raise.png?1590671180'
  },
  {
    chainId: 1,
    address: '0x282417b21236ac01a3a3d7ba304ed8d284f48b4d',
    name: 'Super CoinView Toke',
    symbol: 'SCV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8840/thumb/Hpio6etr_400x400.jpg?1562036775'
  },
  {
    chainId: 1,
    address: '0xa86a0da9d05d0771955df05b44ca120661af16de',
    name: 'OTCBTC Token',
    symbol: 'OTB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5246/thumb/otcbtc-token.jpg?1547040734'
  },
  {
    chainId: 1,
    address: '0x236ecfb32c2b496f942c86d43b8ca4f6bd931e30',
    name: 'Morph',
    symbol: 'MORC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13609/thumb/0PAc0nMs_400x400.png?1611734234'
  },
  {
    chainId: 1,
    address: '0x24efe6b87bf1bfe9ea2ccb5a9d0a959c7172b364',
    name: 'Global AEX Token',
    symbol: 'GAT',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/7008/thumb/a3b65903bffb6e47b225bdd70c635701.png?1601426171'
  },
  {
    chainId: 1,
    address: '0x8ae4bf2c33a8e667de34b54938b0ccd03eb8cc06',
    name: 'Patientory',
    symbol: 'PTOY',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/746/thumb/patientory.png?1548330777'
  },
  {
    chainId: 1,
    address: '0x3c45b24359fb0e107a4eaa56bd0f2ce66c99a0e5',
    name: 'Apple Network',
    symbol: 'ANK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10872/thumb/ANK.png?1585456588'
  },
  {
    chainId: 1,
    address: '0x7a41e0517a5eca4fdbc7fbeba4d4c47b9ff6dc63',
    name: 'Zeusshield',
    symbol: 'ZSC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1009/thumb/zeusshield.png?1548761440'
  },
  {
    chainId: 1,
    address: '0x7b69d465c0f9fb22affae56aa86149973e9b0966',
    name: 'Protocol Finance',
    symbol: 'PFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13810/thumb/17RgoN2.png?1612047312'
  },
  {
    chainId: 1,
    address: '0x5abaff0b83f81dc061c590aadcba013c69237fd7',
    name: 'Jade Currency',
    symbol: 'JADE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10072/thumb/EPUUkhYJ_400x400.jpg?1575500164'
  },
  {
    chainId: 1,
    address: '0x5635ddeabf9cdda686995fe90beb5411831563fc',
    name: 'TravelNote',
    symbol: 'TVNT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/7051/thumb/omwKldFY_400x400.jpg?1547043503'
  },
  {
    chainId: 1,
    address: '0xee3b9b531f4c564c70e14b7b3bb7d516f33513ff',
    name: 'DeFi Omega',
    symbol: 'DFIO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12191/thumb/defi_omega_logo.png?1597978243'
  },
  {
    chainId: 1,
    address: '0x0bead9a1bcc1b84d06e3f2df67e3549fd55ab054',
    name: 'EURxb',
    symbol: 'EURXB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14300/thumb/eurxb.png?1615341210'
  },
  {
    chainId: 1,
    address: '0xeaf61fc150cd5c3bea75744e830d916e60ea5a9f',
    name: 'Typerium',
    symbol: 'TYPE',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/4019/thumb/type.png?1547039124'
  },
  {
    chainId: 1,
    address: '0x88d50b466be55222019d71f9e8fae17f5f45fca1',
    name: 'Cryptaur',
    symbol: 'CPT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/2481/thumb/n3w6Z9xW_400x400.jpg?1548224365'
  },
  {
    chainId: 1,
    address: '0xa1248c718d52752b2cc257eeb0eba900408daeb8',
    name: 'SWYFT',
    symbol: 'SWYFTT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10672/thumb/SWYFT_Logo_250x250.png?1582280902'
  },
  {
    chainId: 1,
    address: '0x2ab05b915c30093679165bcdba9c26d8cd8bee99',
    name: 'BitCherry',
    symbol: 'BCHC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11655/thumb/1JhE8tWnG6kmVCr.png?1592356873'
  },
  {
    chainId: 1,
    address: '0x4599836c212cd988eaccc54c820ee9261cdaac71',
    name: 'Cryptid',
    symbol: 'CID',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11692/thumb/yWrCHzW.png?1592799534'
  },
  {
    chainId: 1,
    address: '0x59d4ccc94a9c4c3d3b4ba2aa343a9bdf95145dd1',
    name: 'QUSD Stablecoin',
    symbol: 'QUSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13176/thumb/qusd_logo.png?1605922605'
  },
  {
    chainId: 1,
    address: '0xb16d3ed603d62b125c6bd45519eda40829549489',
    name: 'Insureum',
    symbol: 'ISR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4888/thumb/insureum.png?1547974141'
  },
  {
    chainId: 1,
    address: '0x3be6e7bf2cd8e1a0a95597e72ca6d3709bbeff76',
    name: 'Demeter Chain',
    symbol: 'DMTC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9882/thumb/7QhVSU2R_400x400.jpg?1572941462'
  },
  {
    chainId: 1,
    address: '0xd7f5cabdf696d7d1bf384d7688926a4bdb092c67',
    name: 'DRC Mobility',
    symbol: 'DRC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13457/thumb/drc_symbol.png?1608759323'
  },
  {
    chainId: 1,
    address: '0x049399a6b048d52971f7d122ae21a1532722285f',
    name: 'Fire Lotto',
    symbol: 'FLOT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2201/thumb/fire-lotto.png?1548126078'
  },
  {
    chainId: 1,
    address: '0x804e26c4eff0bb196b805bdfb5b29ab828cf0b1f',
    name: 'Whale Coin',
    symbol: 'WHALE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10743/thumb/whalecoin200.png?1582835568'
  },
  {
    chainId: 1,
    address: '0xa8c2af8c403704a31f32f0aa70a43e63afcd54d0',
    name: 'Klondike Bond',
    symbol: 'KBOND',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13788/thumb/klondike.jpg?1611759321'
  },
  {
    chainId: 1,
    address: '0x1a3564852d8ede7c8249805e71718bd7aa93dd6d',
    name: 'X power Chain',
    symbol: 'XPO',
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/9735/thumb/r4ahvyuo_400x400.jpg?1571309103'
  },
  {
    chainId: 1,
    address: '0x7d29a64504629172a429e64183d6673b9dacbfce',
    name: 'Vectorspace AI',
    symbol: 'VXV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2063/thumb/vectorspace-ai-logo.jpeg?1547036362'
  },
  {
    chainId: 1,
    address: '0x4cc19356f2d37338b9802aa8e8fc58b0373296e7',
    name: 'SelfKey',
    symbol: 'KEY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2034/thumb/selfkey.png?1548608934'
  },
  {
    chainId: 1,
    address: '0x0947b0e6d821378805c9598291385ce7c791a6b2',
    name: 'Lendingblock',
    symbol: 'LND',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2988/thumb/lnd.png?1518768584'
  },
  {
    chainId: 1,
    address: '0x021576770cb3729716ccfb687afdb4c6bf720cb6',
    name: 'Unlimited FiscusFYI',
    symbol: 'UFFYI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12666/thumb/uFFYI.png?1601463557'
  },
  {
    chainId: 1,
    address: '0x003e0af2916e598fa5ea5cb2da4edfda9aed9fde',
    name: 'Basis Dollar',
    symbol: 'BSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13409/thumb/bdollar_logo.png?1608263890'
  },
  {
    chainId: 1,
    address: '0x8752bf7ad53d25a4165b9370f2becc22dd8ae838',
    name: 'BlockNoteX',
    symbol: 'BNOX',
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/11066/thumb/bnox-color-h256.png?1587777583'
  },
  {
    chainId: 1,
    address: '0x99ea4db9ee77acd40b119bd1dc4e33e1c070b80d',
    name: 'Quantstamp',
    symbol: 'QSP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1219/thumb/0_E0kZjb4dG4hUnoDD_.png?1604815917'
  },
  {
    chainId: 1,
    address: '0x57e83505827788c9f92bcfd398a51a7b0c83dd8e',
    name: 'ChainLink Trading S',
    symbol: 'CTS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11779/thumb/chainlink_trading_set.png?1593932710'
  },
  {
    chainId: 1,
    address: '0x436f0f3a982074c4a05084485d421466a994fe53',
    name: 'Rate3',
    symbol: 'RTE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3342/thumb/rate3-logo.png?1547037953'
  },
  {
    chainId: 1,
    address: '0x7be5901f679bde8202a123c84c19bbce2cf3449b',
    name: 'Fiola',
    symbol: 'FLA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13010/thumb/Untitled-design-4-removebg-preview-1.png?1604373860'
  },
  {
    chainId: 1,
    address: '0x1051a014e4b3f2bd08e5a7e52522f0f71628162b',
    name: 'GOTOGODS',
    symbol: 'OGODS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9996/thumb/gotogods.png?1574683061'
  },
  {
    chainId: 1,
    address: '0xdac4ae188ace3c8985765edc6c9b4739d4845ddc',
    name: 'InterValue',
    symbol: 'INVE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4756/thumb/intervalue-logo.png?1547040078'
  },
  {
    chainId: 1,
    address: '0xd6bd97a26232ba02172ff86b055d5d7be789335b',
    name: 'Ormeus Cash',
    symbol: 'OMC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11798/thumb/Vooo8SX.png?1594359387'
  },
  {
    chainId: 1,
    address: '0x60571e95e12c78cba5223042692908f0649435a5',
    name: 'PLAAS FARMERS TOKEN',
    symbol: 'PLAAS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11541/thumb/Logo_%289%29.png?1590984188'
  },
  {
    chainId: 1,
    address: '0xfdb15e5e6799be72798b1ccfaecbf186bf73a0c4',
    name: 'NitroEX',
    symbol: 'NTX',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13976/thumb/ntx-icon.png?1613435774'
  },
  {
    chainId: 1,
    address: '0x6d2c508fc4a588a41713ff59212f85489291d244',
    name: 'Bloomzed Loyalty Cl',
    symbol: 'BLCT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10810/thumb/esRodCO5_400x400.jpg?1583906706'
  },
  {
    chainId: 1,
    address: '0x136fae4333ea36a24bb751e2d505d6ca4fd9f00b',
    name: 'ETH RSI 60 40 Yield',
    symbol: 'ETHRSIAPY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10602/thumb/eth-rsi-40-60-ctoken-set.png?1580970094'
  },
  {
    chainId: 1,
    address: '0xc760721eb65aa6b0a634df6a008887c48813ff63',
    name: 'Cryptorg Token',
    symbol: 'CTG',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11474/thumb/crystal_200.png?1590450209'
  },
  {
    chainId: 1,
    address: '0x701c244b988a513c945973defa05de933b23fe1d',
    name: 'OAX',
    symbol: 'OAX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/853/thumb/OAXlogo.png?1598686792'
  },
  {
    chainId: 1,
    address: '0x0a50c93c762fdd6e56d86215c24aaad43ab629aa',
    name: 'LGO Token',
    symbol: 'LGO',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/2353/thumb/2_JNnfVRPMBuA1hwnRubH72A.png?1595311622'
  },
  {
    chainId: 1,
    address: '0x66af49ebaeefed6f0f43df48142341391f3f25c1',
    name: 'Mangu',
    symbol: 'MNGUZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13018/thumb/6SrauWkt_200x200.jpg?1604389900'
  },
  {
    chainId: 1,
    address: '0xe7d324b2677440608fb871981b220eca062c3fbf',
    name: 'Bullswap Protocol',
    symbol: 'BVL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13195/thumb/4HPwA1D2_400x400.png?1606115706'
  },
  {
    chainId: 1,
    address: '0x6353eadf8d1d4421002332bb9074222b14d54881',
    name: 'Payfair',
    symbol: 'PFR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1290/thumb/payfair.png?1548330817'
  },
  {
    chainId: 1,
    address: '0x970b9bb2c0444f5e81e9d0efb84c8ccdcdcaf84d',
    name: 'Fuse',
    symbol: 'FUSE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10347/thumb/vUXKHEe.png?1601523640'
  },
  {
    chainId: 1,
    address: '0x8f0921f30555624143d427b340b1156914882c10',
    name: 'FlypMe',
    symbol: 'FYP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1098/thumb/flypme.png?1548126453'
  },
  {
    chainId: 1,
    address: '0x09617f6fd6cf8a71278ec86e23bbab29c04353a7',
    name: 'Shardus',
    symbol: 'ULT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8383/thumb/final_logo_photoshop.png?1557890272'
  },
  {
    chainId: 1,
    address: '0x579353231f3540b218239774422962c64a3693e7',
    name: 'Bitcratic Revenue',
    symbol: 'BCTR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12537/thumb/BCTR.png?1600557934'
  },
  {
    chainId: 1,
    address: '0x7240ac91f01233baaf8b064248e80feaa5912ba3',
    name: 'OctoFi',
    symbol: 'OCTO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12594/thumb/octofi-256x256-radius-22percent.png?1610679969'
  },
  {
    chainId: 1,
    address: '0x2396fbc0e2e3ae4b7206ebdb5706e2a5920349cb',
    name: 'Color Platform',
    symbol: 'CLR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4941/thumb/color.png?1569464438'
  },
  {
    chainId: 1,
    address: '0x461733c17b0755ca5649b6db08b3e213fcf22546',
    name: 'ATN',
    symbol: 'ATN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2542/thumb/atn.png?1547036658'
  },
  {
    chainId: 1,
    address: '0x6086b52cab4522b4b0e8af9c3b2c5b8994c36ba6',
    name: 'ShuttleOne',
    symbol: 'SZO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14063/thumb/EMQvEJSY_400x400.png?1614077678'
  },
  {
    chainId: 1,
    address: '0xc222e5b89309fab5faf55a3b3bd9082be834916c',
    name: 'Bitcoiva',
    symbol: 'BCA',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/13016/thumb/Untitled-design-6-removebg-preview.png?1604389289'
  },
  {
    chainId: 1,
    address: '0x4c6719bf85903d18c295da44216f862b01b36f43',
    name: 'AlloHash',
    symbol: 'ALH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2897/thumb/Allohash-Logo-v03_2_%281%29.png?1616119279'
  },
  {
    chainId: 1,
    address: '0x39eae99e685906ff1c11a962a743440d0a1a6e09',
    name: 'Holyheld  OLD ',
    symbol: 'HOLY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12747/thumb/veqfbl.png?1602211222'
  },
  {
    chainId: 1,
    address: '0x0ff6ffcfda92c53f615a4a75d982f399c989366b',
    name: 'UniLayer',
    symbol: 'LAYER',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12164/thumb/Unilayer.jpg?1597779313'
  },
  {
    chainId: 1,
    address: '0x072c46f392e729c1f0d92a307c2c6dba06b5d078',
    name: 'Snoop Doge',
    symbol: 'SNOGE',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15752/thumb/snoop_doge.PNG?1621814306'
  },
  {
    chainId: 1,
    address: '0xe6c3502997f97f9bde34cb165fbce191065e068f',
    name: 'Klondike BTC',
    symbol: 'KBTC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13789/thumb/klondike.jpg?1611759492'
  },
  {
    chainId: 1,
    address: '0x649ebf73043ffcc70a59855ecd8a568fd996415a',
    name: 'YFIII',
    symbol: 'YFIII',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13030/thumb/LOGO-200-X200.png?1604474135'
  },
  {
    chainId: 1,
    address: '0x2822f6d1b2f41f93f33d937bc7d84a8dfa4f4c21',
    name: 'Poseidon Network',
    symbol: 'QQQ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8442/thumb/LNBLzKy2_400x400.jpg?1558562627'
  },
  {
    chainId: 1,
    address: '0x16587cf43f044aba0165ffa00acf412631194e4b',
    name: 'Simracer Coin',
    symbol: 'SRC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13027/thumb/SimRacer-500px.png?1604456794'
  },
  {
    chainId: 1,
    address: '0x1cceed434a7a64d1d8f9bb719fe0d29cbba9f120',
    name: 'Arix',
    symbol: 'ARIX',
    decimals: 3,
    logoURI: 'https://assets.coingecko.com/coins/images/13485/thumb/logo_-_2020-12-27T231657.316.png?1609082279'
  },
  {
    chainId: 1,
    address: '0x7ed621d37e30214d5e197454f08b1c5c9558517a',
    name: 'DEVA Token',
    symbol: 'DEVA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13845/thumb/200x200_%2810%29.png?1612313594'
  },
  {
    chainId: 1,
    address: '0x9ff58f4ffb29fa2266ab25e75e2a8b3503311656',
    name: 'Aave WBTC',
    symbol: 'AWBTC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14244/thumb/aWBTC.41453c2a.png?1615528721'
  },
  {
    chainId: 1,
    address: '0x884181554dfa9e578d36379919c05c25dc4a15bb',
    name: 'Gene Source Code To',
    symbol: 'GENE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5283/thumb/Gene_Source_Code_Token_Logo.jpg?1547040798'
  },
  {
    chainId: 1,
    address: '0x49d09cda1deb8a1680f1270c5ed15218fc4b18f0',
    name: 'OVCODE',
    symbol: 'OVC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7651/thumb/logo_%284%29.png?1549002728'
  },
  {
    chainId: 1,
    address: '0xb8243b4eeca27a4191e879760b88fe2270561796',
    name: 'ETHUSD ADL 4H Set',
    symbol: 'ETHUSDADL4',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10587/thumb/ethusd_adl_4h.png?1580962139'
  },
  {
    chainId: 1,
    address: '0x1337def18c680af1f9f45cbcab6309562975b1dd',
    name: 'Armor NXM',
    symbol: 'ARNXM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13753/thumb/1_otmQ1sN0MgsT4idRsPsu3w.png?1611556043'
  },
  {
    chainId: 1,
    address: '0xa93d5cfaa41193b13321c035b4bdd2b534172762',
    name: 'Dream Swap',
    symbol: 'DREAM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13033/thumb/dream_32.png?1604503082'
  },
  {
    chainId: 1,
    address: '0xa0bed124a09ac2bd941b10349d8d224fe3c955eb',
    name: 'DePay',
    symbol: 'DEPAY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13183/thumb/favicon.png?1608102012'
  },
  {
    chainId: 1,
    address: '0x9cd39da8f25ec50cf2ee260e464ac23ea23f6bb0',
    name: 'Toshify finance',
    symbol: 'YFT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12574/thumb/QskdLwuY_400x400.png?1600899144'
  },
  {
    chainId: 1,
    address: '0xe7c9c188138f7d70945d420d75f8ca7d8ab9c700',
    name: 'Basis Dollar Share',
    symbol: 'BSDS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13411/thumb/bshare.png?1608267551'
  },
  {
    chainId: 1,
    address: '0x43244c686a014c49d3d5b8c4b20b4e3fab0cbda7',
    name: 'kxUSD',
    symbol: 'KXUSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14788/thumb/kxUSD.png?1618458104'
  },
  {
    chainId: 1,
    address: '0xc19b6a4ac7c7cc24459f08984bbd09664af17bd1',
    name: 'SENSO',
    symbol: 'SENSO',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/11064/thumb/senso.png?1587776458'
  },
  {
    chainId: 1,
    address: '0x1ed7ae1f0e2fa4276dd7ddc786334a3df81d50c0',
    name: 'FSBT API',
    symbol: 'FSBT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1635/thumb/fsbt.PNG?1547035857'
  },
  {
    chainId: 1,
    address: '0xeccf15a4b5976a1365baed5297058b4ca42777c0',
    name: 'Nosturis',
    symbol: 'NTRS',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/9990/thumb/47010860_203643113858754_2966343352238211072_n.jpg?1574373395'
  },
  {
    chainId: 1,
    address: '0x9e78b8274e1d6a76a0dbbf90418894df27cbceb5',
    name: 'Covenants',
    symbol: 'UNIFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12258/thumb/Unifi.png?1598548933'
  },
  {
    chainId: 1,
    address: '0x506de580ecdba535eb0a7e61d3fa3dd8f7c8b6b9',
    name: 'BCHNrBTC Synthetic ',
    symbol: 'BCHNRBTC-JAN-2021',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13362/thumb/uma_logo.jpg?1607916944'
  },
  {
    chainId: 1,
    address: '0x654eebac62240e6c56bab5f6adf7cfa74a894510',
    name: 'Zelda Spring Nuts C',
    symbol: 'ZELDASPRINGNUTSCASH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13579/thumb/zelda_spring_nuts_cash.png?1609914642'
  },
  {
    chainId: 1,
    address: '0xbf776e4fca664d791c4ee3a71e2722990e003283',
    name: 'Smoothy',
    symbol: 'SMTY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15039/thumb/dDxKgwPN_400x400.jpg?1619507030'
  },
  {
    chainId: 1,
    address: '0xf6c0aa7ebfe9992200c67e5388e4f42da49e1783',
    name: 'Psyche',
    symbol: 'USD1',
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/13661/thumb/Psyche-logo-256.png?1610600682'
  },
  {
    chainId: 1,
    address: '0x889efb523cc39590b8483eb9491890ac71407f64',
    name: 'Moon Juice',
    symbol: 'JUICE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12564/thumb/-TMHqn9S_400x400.jpg?1600899021'
  },
  {
    chainId: 1,
    address: '0x78c292d1445e6b9558bf42e8bc369271ded062ea',
    name: 'CyberMusic',
    symbol: 'CYMT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/6039/thumb/cybermusic.png?1547791125'
  },
  {
    chainId: 1,
    address: '0xd33d0eb1c0c0295c3a2409a9101dd511823bf217',
    name: 'Unique Fans',
    symbol: 'FANS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14715/thumb/fans_200X200.png?1617922576'
  },
  {
    chainId: 1,
    address: '0x7a647637e75d34a7798ba06bd1adf5c084bb5dd5',
    name: 'Bestay',
    symbol: 'BSY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12166/thumb/photo-2019-07-04-17-03-29.jpg?1597787199'
  },
  {
    chainId: 1,
    address: '0x3a9fff453d50d4ac52a6890647b823379ba36b9e',
    name: 'Shuffle Monster',
    symbol: 'SHUF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9222/thumb/shuf.png?1568038008'
  },
  {
    chainId: 1,
    address: '0x6863be0e7cf7ce860a574760e9020d519a8bdc47',
    name: 'On Live',
    symbol: 'ONL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3234/thumb/onl.png?1547037740'
  },
  {
    chainId: 1,
    address: '0x465e07d6028830124be2e4aa551fbe12805db0f5',
    name: 'Wrapped XMR by BTSE',
    symbol: 'WXMR',
    decimals: 18
  },
  {
    chainId: 1,
    address: '0x0f8794f66c7170c4f9163a8498371a747114f6c4',
    name: 'Flama',
    symbol: 'FMA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11587/thumb/Flama.png?1591498092'
  },
  {
    chainId: 1,
    address: '0xd6875274b000462f59e9327cbde2cef637914569',
    name: 'QUSD',
    symbol: 'QUSD',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/7709/thumb/wUl7vtZG_400x400.jpg?1550021050'
  },
  {
    chainId: 1,
    address: '0xf48e200eaf9906362bb1442fca31e0835773b8b4',
    name: 'sAUD',
    symbol: 'SAUD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13665/thumb/sAUD.png?1610609254'
  },
  {
    chainId: 1,
    address: '0xde5ea375ffbdc8b25a80fe13d631e8ba0ab4bb02',
    name: 'Gera Coin',
    symbol: 'GERA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13686/thumb/GeraCoin_Logo-icon-1000px.png?1610919942'
  },
  {
    chainId: 1,
    address: '0x54318a379935d545eb8e474a191e11faac5a46e8',
    name: 'KKCOIN',
    symbol: 'KK',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/7707/thumb/bQuF7J1M_400x400.jpg?1549878802'
  },
  {
    chainId: 1,
    address: '0xb8a5dba52fe8a0dd737bf15ea5043cea30c7e30b',
    name: 'AFRICUNIA BANK',
    symbol: 'AFCASH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7449/thumb/afcash.jpg?1592393180'
  },
  {
    chainId: 1,
    address: '0x6e5a43db10b04701385a34afb670e404bc7ea597',
    name: 'RAKON',
    symbol: 'RKN',
    decimals: 12,
    logoURI:
      'https://assets.coingecko.com/coins/images/8852/thumb/C9KBDYOzWFsO2uCmalxKg2luhpyyNAwANif2HYsTUVgmE0uUv1R5-as2wDyyzGGUwXQ1JLBPVWBXBLxuTfxJaDc7anwthzRz88WkHJY6jqQ3TjQ8PahXr7zPXNeY3bROXhyo7m6BwqPAfbWnj32C--OsErHB1DzDezp8YVR2vRL_hz0bRltRuH8pvAOVaeSr3MbE_BIPaV-UNSmfpBDPWIv1xq5Z42xZ_t9Z6vZVi9rAcaDp.jpg?1577831830'
  },
  {
    chainId: 1,
    address: '0xf73fc4b74a4cc6f9ea203a9d5bbff4ffce3a4c48',
    name: 'Phillionex',
    symbol: 'PHN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9827/thumb/AdU9pq2b_400x400.jpg?1572471686'
  },
  {
    chainId: 1,
    address: '0x035bfe6057e15ea692c0dfdcab3bb41a64dd2ad4',
    name: 'Universal Liquidity',
    symbol: 'ULU',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12376/thumb/ulu_finance_logo.ico?1599444401'
  },
  {
    chainId: 1,
    address: '0xcb17cd357c7acd594717d899ecb9df540f633f27',
    name: 'CoinDeal Token',
    symbol: 'CDL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9960/thumb/2ZP5JaIL_400x400.jpg?1574233581'
  },
  {
    chainId: 1,
    address: '0xa6c040045d962e4b8efa00954c7d23ccd0a2b8ad',
    name: 'ETH BTC 75  25  Wei',
    symbol: 'ETHBTC7525',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10595/thumb/eth_buy_and_hold.png?1580964016'
  },
  {
    chainId: 1,
    address: '0xe3a64a3c4216b83255b53ec7ea078b13f21a7dad',
    name: 'DeFi Gold',
    symbol: 'DFGL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12883/thumb/defi_gold.jpg?1603281766'
  },
  {
    chainId: 1,
    address: '0xb3f83a3be59e71876659c5cecc6a3c4d690d258e',
    name: 'Zelda Summer Nuts C',
    symbol: 'ZELDASUMMERNUTSCASH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13578/thumb/zelda_summer_nuts_cash.png?1609905119'
  },
  {
    chainId: 1,
    address: '0xa69f7a10df90c4d6710588bc18ad9bf08081f545',
    name: 'Cexlt',
    symbol: 'CLT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13171/thumb/clt_logo.png?1605855281'
  },
  {
    chainId: 1,
    address: '0xb683d83a532e2cb7dfa5275eed3698436371cc9f',
    name: 'BTU Protocol',
    symbol: 'BTU',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3697/thumb/btuprotocol.jpeg?1549597021'
  },
  {
    chainId: 1,
    address: '0x2579bb08387f0de7ab135edd6c2a985a3f577b6b',
    name: 'Sports Betting Mark',
    symbol: 'SBX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12104/thumb/HOTavatar.png?1599272463'
  },
  {
    chainId: 1,
    address: '0x7be00ed6796b21656732e8f739fc1b8f1c53da0d',
    name: 'ACDX Exchange Token',
    symbol: 'ACXT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13293/thumb/ACDX_Original_05.png?1607064933'
  },
  {
    chainId: 1,
    address: '0xb9eceb9f717852ad0d936b46155cb0c0f43cbe8e',
    name: 'Ubiner',
    symbol: 'UBIN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12190/thumb/ubin.PNG?1597965582'
  },
  {
    chainId: 1,
    address: '0x2ecb13a8c458c379c4d9a7259e202de03c8f3d19',
    name: 'Block chain com',
    symbol: 'BC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5298/thumb/block-chain-com.png?1547350894'
  },
  {
    chainId: 1,
    address: '0x24692791bc444c5cd0b81e3cbcaba4b04acd1f3b',
    name: 'Unikoin Gold',
    symbol: 'UKG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1120/thumb/unikoin-gold.png?1548759441'
  },
  {
    chainId: 1,
    address: '0x30bcd71b8d21fe830e493b30e90befba29de9114',
    name: 'Penguin Party Fish',
    symbol: 'FISH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14359/thumb/Screen-Shot-2021-03-10-at-1-05-40-AM.png?1615629402'
  },
  {
    chainId: 1,
    address: '0xb1e9157c2fdcc5a856c8da8b2d89b6c32b3c1229',
    name: 'Zenfuse',
    symbol: 'ZEFU',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12796/thumb/zenfuse.jpg?1602640333'
  },
  {
    chainId: 1,
    address: '0x7b3296198f8a548edf89bdb16864da8f37b7d9cb',
    name: 'GoldeNugget',
    symbol: 'GNTO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9974/thumb/gn.png?1574319320'
  },
  {
    chainId: 1,
    address: '0x6c93c838d362656430d81e150cdbe9b69ba8efc4',
    name: 'NFT Rehab',
    symbol: 'REHAB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14292/thumb/NFT_Rehab.png?1615282970'
  },
  {
    chainId: 1,
    address: '0x46f4e420c75401494a39b70653f4bbb88ad2d728',
    name: 'WenBurn',
    symbol: 'WENB',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/12557/thumb/moonOnFire.jpg?1600746273'
  },
  {
    chainId: 1,
    address: '0x2731d151cbdf84a8a4c6d9d0bae74012db51e428',
    name: 'IFToken',
    symbol: 'IFT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14049/thumb/ift_200x200.jpg?1614046057'
  },
  {
    chainId: 1,
    address: '0x375a08ce3a460f20bbafd282be1e3579a2c31f41',
    name: 'Thaler Group Compan',
    symbol: 'TGCO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10742/thumb/thaler_logo_png_200x200.png?1582834531'
  },
  {
    chainId: 1,
    address: '0xf7920b0768ecb20a123fac32311d07d193381d6f',
    name: 'Time New Bank',
    symbol: 'TNB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1265/thumb/time-new-bank.png?1547035290'
  },
  {
    chainId: 1,
    address: '0x13ca8eb6405cfbe2eae5d00207651002083fbc9d',
    name: 'CryptoWater',
    symbol: 'C2O',
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/12621/thumb/CryptoWater-logo-coin_metal-blue-200.png?1601281931'
  },
  {
    chainId: 1,
    address: '0x722f2f3eac7e9597c73a593f7cf3de33fbfc3308',
    name: 'CoinUs',
    symbol: 'CNUS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7205/thumb/coinus.png?1547043774'
  },
  {
    chainId: 1,
    address: '0x09a3ecafa817268f77be1283176b946c4ff2e608',
    name: 'Mirror Protocol',
    symbol: 'MIR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13295/thumb/mirror_logo_transparent.png?1611554658'
  },
  {
    chainId: 1,
    address: '0x1ceb5cb57c4d4e2b2433641b95dd330a33185a44',
    name: 'Keep3rV1',
    symbol: 'KP3R',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12966/thumb/kp3r_logo.jpg?1607057458'
  },
  {
    chainId: 1,
    address: '0xaf20b44c1c651d1d29cfb916ee2a0630b828eb7a',
    name: 'YYFI Protocol',
    symbol: 'YYFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12960/thumb/20201027_093510.png?1603850588'
  },
  {
    chainId: 1,
    address: '0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f',
    name: 'Must',
    symbol: 'MUST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13688/thumb/must_logo.png?1610949645'
  },
  {
    chainId: 1,
    address: '0x9506d37f70eb4c3d79c398d326c871abbf10521d',
    name: 'Media Licensing Tok',
    symbol: 'MLT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15659/thumb/milc_200x200.png?1621511031'
  },
  {
    chainId: 1,
    address: '0x86c2752f8fe2c6679a942c8ee6c785c28f42cd55',
    name: 'BitMinutes',
    symbol: 'BMT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4144/thumb/IBrHqd09_400x400.jpg?1576040901'
  },
  {
    chainId: 1,
    address: '0x6febd6be8fa45be6a5eeb61a17c82d33b9addd41',
    name: 'IDL Token',
    symbol: 'IDL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13499/thumb/IDL_Logo.png?1609194848'
  },
  {
    chainId: 1,
    address: '0xcf9fbffec9e0e5bbc62e79bf1965f5db76955661',
    name: 'MeshBox',
    symbol: 'MESH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6200/thumb/DPuEPJQ6_400x400.jpg?1547042229'
  },
  {
    chainId: 1,
    address: '0xddce093888c8dc636677cd29bc6c68d35cfdac33',
    name: 'Paper',
    symbol: 'PAPER',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14369/thumb/paper.png?1621827513'
  },
  {
    chainId: 1,
    address: '0x81995ff7aee5c780192b47e0b42a7a86692d1415',
    name: 'Speedcash',
    symbol: 'SCS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2929/thumb/scs_logo_200x200.png?1606439865'
  },
  {
    chainId: 1,
    address: '0x98c36c0e953463bd5146c8783ce081ce1d187acf',
    name: 'Polyient Games Unit',
    symbol: 'PGU',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13777/thumb/gap1KZNe_400x400.jpg?1611716610'
  },
  {
    chainId: 1,
    address: '0x2f85e502a988af76f7ee6d83b7db8d6c0a823bf9',
    name: 'LatiumX',
    symbol: 'LATX',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/3500/thumb/latium.jpg?1547038261'
  },
  {
    chainId: 1,
    address: '0x3fd8f39a962efda04956981c31ab89fab5fb8bc8',
    name: 'Rotharium',
    symbol: 'RTH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2030/thumb/L7kpRMda_400x400.jpg?1547036314'
  },
  {
    chainId: 1,
    address: '0x47dac6bd80f024575a6d367af5ba8e89202a09fc',
    name: 'OXO Farm',
    symbol: 'OXO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15107/thumb/OXO200.png?1619734005'
  },
  {
    chainId: 1,
    address: '0x0b38210ea11411557c13457d4da7dc6ea731b88a',
    name: 'API3',
    symbol: 'API3',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13256/thumb/api3.jpg?1606751424'
  },
  {
    chainId: 1,
    address: '0x1122b6a0e00dce0563082b6e2953f3a943855c1f',
    name: 'Centrality',
    symbol: 'CENNZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3408/thumb/logo.PNG?1547038083'
  },
  {
    chainId: 1,
    address: '0x26cb3641aaa43911f1d4cb2ce544eb652aac7c47',
    name: 'Crystal Token',
    symbol: 'CYL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6419/thumb/crystal-token.png?1547042585'
  },
  {
    chainId: 1,
    address: '0x6c6ee5e31d828de241282b9606c8e98ea48526e2',
    name: 'Holo',
    symbol: 'HOT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3348/thumb/Holologo_Profile.png?1547037966'
  },
  {
    chainId: 1,
    address: '0x15334dcb171e8b65d6650321581dca83be870115',
    name: 'Wrapped BIND',
    symbol: 'WBIND',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13055/thumb/o1w2cBW.png?1604720921'
  },
  {
    chainId: 1,
    address: '0x46d473a0b3eeec9f55fade641bc576d5bc0b2246',
    name: 'SurfExUtilityToken',
    symbol: 'SURF',
    decimals: 8,
    logoURI:
      'https://assets.coingecko.com/coins/images/10783/thumb/200x200-logo-blu-grey-bkg-4-e1583512409629.png?1583539501'
  },
  {
    chainId: 1,
    address: '0x30b1efb052205e6ca3c4888c3c50c5b339cc0602',
    name: 'Cargo Gems',
    symbol: 'GEM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12818/thumb/gems_logo.jpg?1602743920'
  },
  {
    chainId: 1,
    address: '0xc40af1e4fecfa05ce6bab79dcd8b373d2e436c4e',
    name: 'Hokkaidu Inu',
    symbol: 'HOKK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14985/thumb/wnTVZtfc_400x400.jpg?1619251560'
  },
  {
    chainId: 1,
    address: '0x2ccbff3a042c68716ed2a2cb0c544a9f1d1935e1',
    name: 'DMarket',
    symbol: 'DMT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/1602/thumb/dmarket.png?1547223830'
  },
  {
    chainId: 1,
    address: '0x68a9d92fe19399feebed6a9a0980a7ea7638074c',
    name: 'Iqoniq',
    symbol: 'IQQ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14672/thumb/IQONIQ-Cointransparant.png?1617678461'
  },
  {
    chainId: 1,
    address: '0x1673a63aa0047294d75954226f3f2f98de77b16f',
    name: 'GENES Chain',
    symbol: 'GENES',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8857/thumb/logo256_%281%29.png?1562205433'
  },
  {
    chainId: 1,
    address: '0xa829f97373069ee5d23175e4105df8fd49238be7',
    name: 'Opennity',
    symbol: 'OPNN',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/9358/thumb/53672059_554715805021197_5765693264996859904_n.jpg?1566526789'
  },
  {
    chainId: 1,
    address: '0x35e78b3982e87ecfd5b3f3265b601c046cdbe232',
    name: 'SideShift Token',
    symbol: 'XAI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15719/thumb/sideshift-icon_3x.png?1621595183'
  },
  {
    chainId: 1,
    address: '0x6936f1dcd8c2e3a69469311a130858174f9f0a15',
    name: 'Shiba Swim',
    symbol: 'SSWIM',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15591/thumb/BaAw4CKm_400x400.jpg?1621292396'
  },
  {
    chainId: 1,
    address: '0xfad45e47083e4607302aa43c65fb3106f1cd7607',
    name: 'Hoge Finance',
    symbol: 'HOGE',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14360/thumb/hoge.jpg?1615641604'
  },
  {
    chainId: 1,
    address: '0x59e7b5db9be0bdd26fa048d39e01fee456ab674e',
    name: 'Yearn Finance Bit2',
    symbol: 'YFB2',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13064/thumb/yfb2_logo.png?1604884273'
  },
  {
    chainId: 1,
    address: '0x38d1b0d157529bd5d936719a8a5f8379afb24faa',
    name: 'DataKYC',
    symbol: 'DKYC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7123/thumb/1btu7Lp__400x400.jpg?1547043621'
  },
  {
    chainId: 1,
    address: '0xffe510a92434a0df346c5e72a3494b043cf249eb',
    name: 'LUX BIO EXCHANGE CO',
    symbol: 'LBXC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9482/thumb/sam7Fy3A_400x400.jpg?1568000741'
  },
  {
    chainId: 1,
    address: '0xea319e87cf06203dae107dd8e5672175e3ee976c',
    name: 'Surf Finance',
    symbol: 'SURF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12982/thumb/surf_200x200.png?1604050261'
  },
  {
    chainId: 1,
    address: '0xcce63fd31e9053c110c74cebc37c8e358a6aa5bd',
    name: 'Bitriver',
    symbol: 'BTR',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/14946/thumb/1552652626371.png?1619128181'
  },
  {
    chainId: 1,
    address: '0x916885426255235da7a0bd90447986c00675f9ec',
    name: 'GoalTime N',
    symbol: 'GTX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10705/thumb/GTX_200x200.png?1607580745'
  },
  {
    chainId: 1,
    address: '0xab9c92a9337a1494c6d545e48187fa37144403c8',
    name: 'Punk Attr 5',
    symbol: 'PUNK-ATTR-5',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13999/thumb/icon-punk-attr-5-40.png?1613604003'
  },
  {
    chainId: 1,
    address: '0x86b300ef935284a99fa5d148a9a6ccc5103b21a8',
    name: 'Crypto Dash',
    symbol: 'CDASH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10409/thumb/logo.png?1578990007'
  },
  {
    chainId: 1,
    address: '0xa8006e3ac1bd94e54e3136b8e5dd75db0163e6f4',
    name: 'EveryonesCrypto',
    symbol: 'EOC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13081/thumb/logo_blue.png?1604976930'
  },
  {
    chainId: 1,
    address: '0x19fd4c760a7d4a38aee9f226035cbc9fdf434ffe',
    name: 'CVP Token',
    symbol: 'CVP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12275/thumb/yte0zGJf_400x400.jpg?1598736232'
  },
  {
    chainId: 1,
    address: '0x2b2b0559081c41e962777b5049632fdb30f7e652',
    name: 'BitDefi',
    symbol: 'BFI',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13076/thumb/bitdefi256x256.png?1604907555'
  },
  {
    chainId: 1,
    address: '0xac51066d7bec65dc4589368da368b212745d63e8',
    name: 'My Neighbor Alice',
    symbol: 'ALICE',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/14375/thumb/alice_logo.jpg?1615782968'
  },
  {
    chainId: 1,
    address: '0xad6a626ae2b43dcb1b39430ce496d2fa0365ba9c',
    name: 'PieDAO DEFI Small C',
    symbol: 'DEFI+S',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12701/thumb/DefiS.png?1601862595'
  },
  {
    chainId: 1,
    address: '0x1e18821e69b9faa8e6e75dffe54e7e25754beda0',
    name: 'KIMCHI finance',
    symbol: 'KIMCHI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12302/thumb/VBa2Z60o_400x400.png?1598982471'
  },
  {
    chainId: 1,
    address: '0xfbb6b34dd77274a06ea2e5462a5e0b9e23ce478e',
    name: 'apeUSD UNI Syntheti',
    symbol: 'APEUSD-UNI-DEC21',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14398/thumb/apeusd.jpg?1615905285'
  },
  {
    chainId: 1,
    address: '0x5e3002dff591c5e75bb9dedae268049742e6b13a',
    name: 'Tutor s Diary',
    symbol: 'TUDA',
    decimals: 8,
    logoURI:
      'https://assets.coingecko.com/coins/images/7846/thumb/dbOq7_JEa8Vzq1ljCHJygOrLjk-GmQj3qo3XuwFxa3-vKb9kbe4sFi7msqJE5eRie0WMbcPf29qS_nmNGfI_96lYJZlssCwlk-mDlBoC-LbJNxEBpMFN1gBxZLHzoHtbOn5Yk9BnKhRaz6rKA8QbMWcvXHWjK7hAqOBCqnkHdpQ_iGXHJcMkyWZJ0sMbDR4Lbi9wfrJ2vEurY45q3.jpg?1551149529'
  },
  {
    chainId: 1,
    address: '0x4c38d0e726b6c86f64c1b281348e725973542043',
    name: 'Stand Share',
    symbol: 'SAS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13543/thumb/sac.jpg?1609648278'
  },
  {
    chainId: 1,
    address: '0x2942e3b38e33123965bfbc21e802be943a76bbc6',
    name: 'EHash',
    symbol: 'EHASH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14180/thumb/ehash.png?1614820416'
  },
  {
    chainId: 1,
    address: '0xcfb72ed3647cc8e7fa52e4f121ecdabefc305e7f',
    name: 'Flapp',
    symbol: 'FLAP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13714/thumb/logo_-_2021-01-20T085648.571.png?1611104234'
  },
  {
    chainId: 1,
    address: '0xdf9d4674a430bdcc096a3a403128357ab36844ba',
    name: 'WadzPay Token',
    symbol: 'WTK',
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/13083/thumb/200xWadzToken.png?1604984329'
  },
  {
    chainId: 1,
    address: '0x79126d32a86e6663f3aaac4527732d0701c1ae6c',
    name: 'Dark Matter',
    symbol: 'DMT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14223/thumb/dmt.jpg?1615089067'
  },
  {
    chainId: 1,
    address: '0x6f02055e3541dd74a1abd8692116c22ffafadc5d',
    name: 'The Mart Token',
    symbol: 'TMT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9963/thumb/tmt.png?1585317430'
  },
  {
    chainId: 1,
    address: '0xa0ed4c4acbf07c03365d6bbe28150a819aff700f',
    name: 'BITIFEX',
    symbol: 'BITX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7141/thumb/logo_%2833%29.png?1547043643'
  },
  {
    chainId: 1,
    address: '0x87b87a7583d8d8f15b58bdd290318386ac8ee174',
    name: 'Digiwill',
    symbol: 'DGW',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13086/thumb/logo_200_%281%29.png?1604986880'
  },
  {
    chainId: 1,
    address: '0x2bba3cf6de6058cc1b4457ce00deb359e2703d7f',
    name: 'HashCoin',
    symbol: 'HSC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4989/thumb/hashfuture-logo.png?1531741876'
  },
  {
    chainId: 1,
    address: '0xc0a25a24cce412e2fb407c08e3785437fee9ad1d',
    name: 'Orient',
    symbol: 'OFT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13354/thumb/40FJ03N.png?1607812101'
  },
  {
    chainId: 1,
    address: '0x9a7a4c141a3bcce4a31e42c1192ac6add35069b4',
    name: 'Momentum',
    symbol: 'XMM',
    decimals: 10,
    logoURI: 'https://assets.coingecko.com/coins/images/12461/thumb/logo-transparent-200.png?1600007183'
  },
  {
    chainId: 1,
    address: '0x35101c731b1548b5e48bb23f99edbc2f5c341935',
    name: 'BlackHoleSwap Compo',
    symbol: 'BHSC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12347/thumb/blackhole.PNG?1599201917'
  },
  {
    chainId: 1,
    address: '0x1829aa045e21e0d59580024a951db48096e01782',
    name: 'FuzeX',
    symbol: 'FXT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3692/thumb/fuzex-token.png?1547038696'
  },
  {
    chainId: 1,
    address: '0x174897edd3ce414084a009d22db31c7b7826400d',
    name: 'JOON',
    symbol: 'JOON',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/12595/thumb/logo.png?1600995905'
  },
  {
    chainId: 1,
    address: '0x4fac0ccd9e2ed9fd462d42b66fb81ba9a1f6f25e',
    name: 'AXiaL',
    symbol: 'AXL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7377/thumb/e0HWV5vs_400x400.jpg?1547044071'
  },
  {
    chainId: 1,
    address: '0x1ebd8d3ca115451b9b6bbaa7ee2f7b0f96e49fd8',
    name: 'ngot',
    symbol: 'NGOT',
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/6834/thumb/ngot_token.jpg?1547043146'
  },
  {
    chainId: 1,
    address: '0x243c56e8e740025ac6b112d7b9af59be8eef6e33',
    name: 'Lukutex',
    symbol: 'LKT',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/14523/thumb/1HuZD81.png?1618976829'
  },
  {
    chainId: 1,
    address: '0x12b306fa98f4cbb8d4457fdff3a0a0a56f07ccdf',
    name: 'Spectre ai Dividend',
    symbol: 'SXDT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2550/thumb/spectre-dividend.png?1547036667'
  },
  {
    chainId: 1,
    address: '0xb7fbff4ce5200215c690cc95855e5d6c5236ee9f',
    name: 'CCSwap',
    symbol: 'CC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15037/thumb/ccswap.PNG?1619505323'
  },
  {
    chainId: 1,
    address: '0xbd4a858139b155219e2c8d10135003fdef720b6b',
    name: 'Saren',
    symbol: 'SAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14779/thumb/68887475.png?1618386459'
  },
  {
    chainId: 1,
    address: '0x11afe7fa792589dd1236257f99ba09f510460ad9',
    name: 'LNKO Token',
    symbol: 'LNKO',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9199/thumb/logo_%289%29.png?1565077907'
  },
  {
    chainId: 1,
    address: '0x8a77e40936bbc27e80e9a3f526368c967869c86d',
    name: 'Merculet',
    symbol: 'MVP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3483/thumb/feature_token.png?1547038223'
  },
  {
    chainId: 1,
    address: '0x083d41d6dd21ee938f0c055ca4fb12268df0efac',
    name: 'GogolCoin',
    symbol: 'GOL',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/14824/thumb/GOL.png?1618559300'
  },
  {
    chainId: 1,
    address: '0x89bd2e7e388fab44ae88bef4e1ad12b4f1e0911c',
    name: 'Peanut',
    symbol: 'NUX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13958/thumb/2sAMZXpO_400x400.jpg?1613353972'
  },
  {
    chainId: 1,
    address: '0x4cc84b41ececc387244512242eec226eb7948a92',
    name: 'Kassia Home',
    symbol: 'KASSIAHOME',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12558/thumb/farmer.4e48cd5b.png?1600899057'
  },
  {
    chainId: 1,
    address: '0x9c664f20c0a00a4949dffca76748c02754c875aa',
    name: 'Yearn Shark Finance',
    symbol: 'YSKF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13614/thumb/IMG-20201203-122807-053.png?1610287099'
  },
  {
    chainId: 1,
    address: '0xf56842af3b56fd72d17cb103f92d027bba912e89',
    name: 'BambooDeFi',
    symbol: 'BAMBOO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13856/thumb/Bamboo-token-logo-200.png?1612404311'
  },
  {
    chainId: 1,
    address: '0x97cb5cc1b2e10cc56dc16ab9179f06dfedbe41a2',
    name: 'MobilinkToken',
    symbol: 'MOLK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2989/thumb/MobilinkToken.png?1547037251'
  },
  {
    chainId: 1,
    address: '0xaba8cac6866b83ae4eec97dd07ed254282f6ad8a',
    name: 'YAM v2',
    symbol: 'YAMV2',
    decimals: 24,
    logoURI: 'https://assets.coingecko.com/coins/images/12179/thumb/YAM-v2.png?1597892396'
  },
  {
    chainId: 1,
    address: '0x9b1b1e109ff130b298cf1d47389c47569f5c2932',
    name: 'Biido',
    symbol: 'BION',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9925/thumb/biido.PNG?1573462230'
  },
  {
    chainId: 1,
    address: '0x0f8c45b896784a1e408526b9300519ef8660209c',
    name: 'XMax',
    symbol: 'XMX',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/4643/thumb/xmx.png?1574682262'
  },
  {
    chainId: 1,
    address: '0x8720c8429b78df262360b0f39917a03f9b894746',
    name: 'Frenzy',
    symbol: 'FZY',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11096/thumb/frenzy-singapore.jpg?1588212527'
  },
  {
    chainId: 1,
    address: '0x70da48f4b7e83c386ef983d4cef4e58c2c09d8ac',
    name: 'Quras Token',
    symbol: 'XQC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10112/thumb/FZFHac2z_400x400.jpg?1575964560'
  },
  {
    chainId: 1,
    address: '0x147e3d644d53adadd18a28201b91e2b98aeb7f94',
    name: 'ElenaUSD',
    symbol: 'USE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15041/thumb/use.168dccf0.png?1619510682'
  },
  {
    chainId: 1,
    address: '0xcf55a7f92d5e0c6683debbc1fc20c0a6e056df13',
    name: 'Zelda Elastic Cash',
    symbol: 'ZELDAELASTICCASH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13581/thumb/zelda_elastic_cash.png?1609915888'
  },
  {
    chainId: 1,
    address: '0xa891cf72aeda692570928efe1a832342e9783cdc',
    name: 'Interfinex Bills',
    symbol: 'IFEX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13346/thumb/U6uYZry.png?1607662020'
  },
  {
    chainId: 1,
    address: '0xeda8b016efa8b1161208cf041cd86972eee0f31e',
    name: 'IHT Real Estate Pro',
    symbol: 'IHT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3158/thumb/iht-real-estate-protocol.png?1547744404'
  },
  {
    chainId: 1,
    address: '0x5af2be193a6abca9c8817001f45744777db30756',
    name: 'Voyager Token',
    symbol: 'VGX',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/794/thumb/Voyager-vgx.png?1575693595'
  },
  {
    chainId: 1,
    address: '0x6369c3dadfc00054a42ba8b2c09c48131dd4aa38',
    name: 'Morpher',
    symbol: 'MPH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12619/thumb/morpher_200_200.png?1601524084'
  },
  {
    chainId: 1,
    address: '0x41bc0913ed789428e107c4ea9ed007815c5a8230',
    name: 'Kompass',
    symbol: 'KOMP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12655/thumb/kompass_symbol.png?1601436438'
  },
  {
    chainId: 1,
    address: '0x1788430620960f9a70e3dc14202a3a35dde1a316',
    name: 'OpenAlexa Protocol',
    symbol: 'OAP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12400/thumb/256x256-OAP.png?1599556701'
  },
  {
    chainId: 1,
    address: '0xbe6c01a67bd0160fe3e731555ad014895b225dfa',
    name: 'Fundum',
    symbol: 'FND',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14959/thumb/Logo-200x200_%283%29.png?1619147633'
  },
  {
    chainId: 1,
    address: '0xee87b220d9b0e762f0643c501fadefd6d9cc5b7e',
    name: 'Dragon Network',
    symbol: 'DGNN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12881/thumb/logo_256x256.png?1603254107'
  },
  {
    chainId: 1,
    address: '0xab55bdef7057b76482914e79f037999f4ebb6bf1',
    name: 'Healing Plus',
    symbol: 'HP',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11762/thumb/tXuolcSb_400x400.png?1593588223'
  },
  {
    chainId: 1,
    address: '0xac0104cca91d167873b8601d2e71eb3d4d8c33e0',
    name: 'Crowns',
    symbol: 'CWS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13835/thumb/crowns_logo.png?1612176905'
  },
  {
    chainId: 1,
    address: '0xaaaf91d9b90df800df4f55c205fd6989c977e73a',
    name: 'Monolith',
    symbol: 'TKN',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/675/thumb/Monolith.png?1566296607'
  },
  {
    chainId: 1,
    address: '0xea7aa1edd21735a5ab05ee3e90869016191e274e',
    name: 'Junca cash',
    symbol: 'JCC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12889/thumb/junca.jpg?1603279160'
  },
  {
    chainId: 1,
    address: '0x31be30217989766215672e88ed449913e05bf0f5',
    name: 'Groovy Finance',
    symbol: 'GVY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13320/thumb/Groovy-finance-Logo-A.png?1607415508'
  },
  {
    chainId: 1,
    address: '0xb8e103b60a33597136ea9511f46b6dbeb643a3a5',
    name: 'SiamBitcoin',
    symbol: 'SBTC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7089/thumb/image-62DF_5C05991C.jpg?1547043573'
  },
  {
    chainId: 1,
    address: '0x68e8a20128e1902c02f533a02ed0cfd8396e3fbc',
    name: 'Aries Financial',
    symbol: 'AFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13433/thumb/aries.png?1608528510'
  },
  {
    chainId: 1,
    address: '0x8e30ea2329d95802fd804f4291220b0e2f579812',
    name: 'Decentralized Vulne',
    symbol: 'DVP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9424/thumb/4520.png?1568598223'
  },
  {
    chainId: 1,
    address: '0x476c5e26a75bd202a9683ffd34359c0cc15be0ff',
    name: 'Serum',
    symbol: 'SRM',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/11970/thumb/serum-logo.png?1597121577'
  },
  {
    chainId: 1,
    address: '0xbc34985b4d345aea933d5cac19f3a86bd1fb398f',
    name: 'ZJLT Distributed Fa',
    symbol: 'ZJLT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7867/thumb/zjlt.jpg?1551254209'
  },
  {
    chainId: 1,
    address: '0x2cd9324ba13b77554592d453e6364086fbba446a',
    name: '502 Bad Gateway Tok',
    symbol: 'Z502',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/9040/thumb/502.jpg?1563872035'
  },
  {
    chainId: 1,
    address: '0x997507cc49fbf0cd6ce5e1ee543218556fafdebc',
    name: 'Bitenium Token',
    symbol: 'BT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14618/thumb/BT-token-logo-200.png?1617262055'
  },
  {
    chainId: 1,
    address: '0x7a2bc711e19ba6aff6ce8246c546e8c4b4944dfd',
    name: 'WAXE',
    symbol: 'WAXE',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13508/thumb/waxe_logo.png?1609232755'
  },
  {
    chainId: 1,
    address: '0xa4eed63db85311e22df4473f87ccfc3dadcfa3e3',
    name: 'Rubic',
    symbol: 'RBC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12629/thumb/200x200.png?1607952509'
  },
  {
    chainId: 1,
    address: '0xdf4df8ee1bd1c9f01e60ee15e4c2f7643b690699',
    name: 'POMA',
    symbol: 'POMAC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6830/thumb/POMALogoMain.png?1547043139'
  },
  {
    chainId: 1,
    address: '0xea610b1153477720748dc13ed378003941d84fab',
    name: 'ALIS',
    symbol: 'ALIS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1003/thumb/alis.png?1547034909'
  },
  {
    chainId: 1,
    address: '0xc28a4162e3cc1838bad6dfaec83d57a0d62c80f3',
    name: 'AnySale',
    symbol: 'SALE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13863/thumb/9le9NWE.png?1612418381'
  },
  {
    chainId: 1,
    address: '0xa1faa113cbe53436df28ff0aee54275c13b40975',
    name: 'Alpha Finance',
    symbol: 'ALPHA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12738/thumb/AlphaToken_256x256.png?1617160876'
  },
  {
    chainId: 1,
    address: '0xaa19961b6b858d9f18a115f25aa1d98abc1fdba8',
    name: 'LocalCoinSwap',
    symbol: 'LCS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3998/thumb/LocalCoinSwap.png?1547039086'
  },
  {
    chainId: 1,
    address: '0x2c36204a0712a2a50e54a62f7c4f01867e78cb53',
    name: 'Taklimakan Network',
    symbol: 'TAN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1963/thumb/takli.png?1566738740'
  },
  {
    chainId: 1,
    address: '0xa7d10ff962eda41f3b037e3af1d8b4037eba4b86',
    name: 'BitherCash',
    symbol: 'BICAS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12896/thumb/BICAS.png?1603335842'
  },
  {
    chainId: 1,
    address: '0xb48e0f69e6a3064f5498d495f77ad83e0874ab28',
    name: 'CXN Network',
    symbol: 'CXN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12622/thumb/Webp.net-resizeimage.png?1601282522'
  },
  {
    chainId: 1,
    address: '0x6bb61215298f296c55b19ad842d3df69021da2ef',
    name: 'Drops Ownership Pow',
    symbol: 'DOP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15696/thumb/Drops.png?1621571328'
  },
  {
    chainId: 1,
    address: '0x5f98805a4e8be255a32880fdec7f6728c6568ba0',
    name: 'Liquity USD',
    symbol: 'LUSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14666/thumb/Group_3.png?1617631327'
  },
  {
    chainId: 1,
    address: '0xc86d054809623432210c107af2e3f619dcfbf652',
    name: 'Sentinel Protocol',
    symbol: 'UPP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3369/thumb/Sentinel_Protocol.jpg?1547700074'
  },
  {
    chainId: 1,
    address: '0xe6be436df1ff96956dfe0b2b77fab84ede30236f',
    name: 'Revelation coin',
    symbol: 'REV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7855/thumb/Db7wCd0.jpg?1551171100'
  },
  {
    chainId: 1,
    address: '0xeb7355c2f217b3485a591332fe13c8c5a76a581d',
    name: 'Jubi Token',
    symbol: 'JT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10994/thumb/Af5MFcVY_400x400.jpg?1586998222'
  },
  {
    chainId: 1,
    address: '0x2f766a83146f7eaee2c635c9f9a291e4b86f4108',
    name: 'Ubiquitous Social N',
    symbol: 'USNS',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/10039/thumb/I8sPo8I22qH5EXEGNKr2uZYZ6LJdCIGrkHDUZ8Myue8gAtfL9e1ouiq6m6lbejHlqL53_bHf-qB2Am7dRS_AdpPBbf1S8DWbIIXewPiqrmt3rarNZlP45fX99lBnxTRG7FrH0JFxXZe6ojunQsNTVlh01WVcGMwe2Q96vLr0C8JridYX_HgEUtzhX2DJl4gUSPk9-bEMG3PaMo7Cu-RP_DwSPOLKreAqKjjW0NjhbvVn-CFM.jpg?1575012815'
  },
  {
    chainId: 1,
    address: '0x305f8157c1f841fbd378f636abf390c5b4c0e330',
    name: 'Bitcoin Galaxy Warp',
    symbol: 'BTCGW',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10359/thumb/BITCOIN-GALAXY-WARP-200x200-logo.png?1578523873'
  },
  {
    chainId: 1,
    address: '0x2e071d2966aa7d8decb1005885ba1977d6038a65',
    name: 'Etheroll',
    symbol: 'DICE',
    decimals: 16,
    logoURI: 'https://assets.coingecko.com/coins/images/1232/thumb/etheroll.png?1548125481'
  },
  {
    chainId: 1,
    address: '0xb119ce94d098c18fe380904c24e358bd887f00be',
    name: 'MACH Project',
    symbol: 'MACH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12898/thumb/Mach_token.png?1603337098'
  },
  {
    chainId: 1,
    address: '0xc88f47067db2e25851317a2fdae73a22c0777c37',
    name: 'oneBTC',
    symbol: 'ONEBTC',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15082/thumb/G9oJy1-R.png?1619660359'
  },
  {
    chainId: 1,
    address: '0x65ccd72c0813ce6f2703593b633202a0f3ca6a0c',
    name: 'Nestree',
    symbol: 'EGG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9362/thumb/nestree.png?1566529061'
  },
  {
    chainId: 1,
    address: '0xc6d19a604fbdb5c2eeb363255fd63c9eea29288e',
    name: 'DarkBundles',
    symbol: 'DBUND',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13749/thumb/dbund_logo.png?1611457781'
  },
  {
    chainId: 1,
    address: '0x7f9a00e03c2e53a3af6031c17a150dbedaaab3dc',
    name: 'Read This Contract',
    symbol: 'RTC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12812/thumb/rtc_logo.png?1602730411'
  },
  {
    chainId: 1,
    address: '0x33384af34b03eaca63fd153f59589f504772b570',
    name: 'onLEXpa Token',
    symbol: 'ONLEXPA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10373/thumb/logo-text.png?1578675009'
  },
  {
    chainId: 1,
    address: '0x8f6a193c8b3c949e1046f1547c3a3f0836944e4b',
    name: 'xINCHa',
    symbol: 'XINCHA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13799/thumb/xINCH.png?1611978456'
  },
  {
    chainId: 1,
    address: '0x84810bcf08744d5862b8181f12d17bfd57d3b078',
    name: 'SharedStake Governa',
    symbol: 'SGT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13948/thumb/sgt-png.png?1615189100'
  },
  {
    chainId: 1,
    address: '0x7777777777697cfeecf846a76326da79cc606517',
    name: 'xSigma',
    symbol: 'SIG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14090/thumb/logo_128.png?1619775781'
  },
  {
    chainId: 1,
    address: '0x84bd083b1c8bf929f39c98bc17cf518f40154f58',
    name: 'Mario Cash Syntheti',
    symbol: 'MARIO-CASH-JAN-2021',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13363/thumb/mario_cash.png?1607912271'
  },
  {
    chainId: 1,
    address: '0xdd1ad9a21ce722c151a836373babe42c868ce9a4',
    name: 'Universal Basic Inc',
    symbol: 'UBI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15269/thumb/ubi.png?1620287853'
  },
  {
    chainId: 1,
    address: '0xba14b245d449965bdbeb630ebe135b569474f5b1',
    name: 'EvaCash',
    symbol: 'EVC',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/7939/thumb/evacash-logo.jpg?1552211584'
  },
  {
    chainId: 1,
    address: '0x389999216860ab8e0175387a0c90e5c52522c945',
    name: 'FEG Token',
    symbol: 'FEG',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13878/thumb/fav200.png?1619597015'
  },
  {
    chainId: 1,
    address: '0x0a425122852ed351946a828b348bfdcda51effd8',
    name: 'EduMetrix Coin',
    symbol: 'EMC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12909/thumb/Coin_PNG.png?1603421187'
  },
  {
    chainId: 1,
    address: '0x67b6d479c7bb412c54e03dca8e1bc6740ce6b99c',
    name: 'Kylin Network',
    symbol: 'KYL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14164/thumb/kylin_logo.png?1615771554'
  },
  {
    chainId: 1,
    address: '0x29239242a83479a4074cb1c9e2a3e6705a4a4455',
    name: 'Tozex',
    symbol: 'TOZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13772/thumb/Logo_toz_200x200.png?1611698823'
  },
  {
    chainId: 1,
    address: '0xaff84e86d72edb971341a6a66eb2da209446fa14',
    name: 'The Currency Analyt',
    symbol: 'TCAT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7421/thumb/TCA_geko.png?1547201193'
  },
  {
    chainId: 1,
    address: '0x6ff1bfa14a57594a5874b37ff6ac5efbd9f9599a',
    name: 'TotemFi',
    symbol: 'TOTM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14680/thumb/TOTM.png?1617721704'
  },
  {
    chainId: 1,
    address: '0x05fcc72cfb4150abae415c885f7a433ff523296f',
    name: 'YOKcoin',
    symbol: 'YOK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12425/thumb/YOKcoin_200x200.png?1599732314'
  },
  {
    chainId: 1,
    address: '0x7777770f8a6632ff043c8833310e245eba9209e6',
    name: 'Tokens of Babel',
    symbol: 'TOB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12144/thumb/tokens_of_babel_logo.jpg?1597566356'
  },
  {
    chainId: 1,
    address: '0xc237868a9c5729bdf3173dddacaa336a0a5bb6e0',
    name: 'Wagerr',
    symbol: 'WGR',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/759/thumb/syGKmAT.png?1619597241'
  },
  {
    chainId: 1,
    address: '0xcd1cb16a67937ff8af5d726e2681010ce1e9891a',
    name: 'Themis',
    symbol: 'MIS',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13478/thumb/3uZAPv2CbXF5txM.png?1608947522'
  },
  {
    chainId: 1,
    address: '0xbf70a33a13fbe8d0106df321da0cf654d2e9ab50',
    name: 'ETH BTC RSI Ratio T',
    symbol: 'ETHBTCRSI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10605/thumb/ethbtc-rsi-ratio-set.png?1580970413'
  },
  {
    chainId: 1,
    address: '0x87c4bd3038176301e81e6682ce51a6fdaefabd0c',
    name: 'Pofid Dao',
    symbol: 'PFID',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12462/thumb/62735485.png?1600033798'
  },
  {
    chainId: 1,
    address: '0x672a1ad4f667fb18a333af13667aa0af1f5b5bdd',
    name: 'Verify',
    symbol: 'CRED',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1349/thumb/verify.png?1548759755'
  },
  {
    chainId: 1,
    address: '0x8e870d67f660d95d5be530380d0ec0bd388289e1',
    name: 'Paxos Standard',
    symbol: 'PAX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6013/thumb/paxos_standard.png?1548386291'
  },
  {
    chainId: 1,
    address: '0x7f927f984177323c4ac49e6b1d398e40cd1a78f6',
    name: 'Yield Coin',
    symbol: 'YLD',
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/2958/thumb/yieldcoin.png?1547037197'
  },
  {
    chainId: 1,
    address: '0xa105c740bc012a43a342ab4a0ef40143452c8e89',
    name: 'Litbinex Coin',
    symbol: 'LTB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10090/thumb/logolitbinex.png?1579753908'
  },
  {
    chainId: 1,
    address: '0x49184e6dae8c8ecd89d8bdc1b950c597b8167c90',
    name: 'LIBERTAS',
    symbol: 'LIBERTAS',
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/11928/thumb/logo200x200_%281%29.png?1596409240'
  },
  {
    chainId: 1,
    address: '0x3a856d4effa670c54585a5d523e96513e148e95d',
    name: 'Trias Token',
    symbol: 'TRIAS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13963/thumb/5ELqtwp__400x400_%281%29.jpg?1613527958'
  },
  {
    chainId: 1,
    address: '0x6ce654ac973d326f89f0685e7459542641410ed9',
    name: 'HubDao',
    symbol: 'HD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12970/thumb/HD_ICON.png?1603941647'
  },
  {
    chainId: 1,
    address: '0x4ff7c227e1e7bd59351de8635381c379750a8040',
    name: 'Hype',
    symbol: 'HYPE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13494/thumb/N9aAdTFH_400x400.png?1609153363'
  },
  {
    chainId: 1,
    address: '0x901f8679a6ef435d533732f5ea49bb82d568be99',
    name: 'Trebit Network',
    symbol: 'TRB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14572/thumb/20210330_044456.png?1617084399'
  },
  {
    chainId: 1,
    address: '0x990f341946a3fdb507ae7e52d17851b87168017c',
    name: 'Strong',
    symbol: 'STRONG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12092/thumb/STRONG-Token-256x256.png?1597823573'
  },
  {
    chainId: 1,
    address: '0x4b606e9eb2228c70f44453afe5a73e1fea258ce1',
    name: 'pxUSD Synthetic USD',
    symbol: 'PXUSD-MAR2022',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14507/thumb/perlin.png?1616575526'
  },
  {
    chainId: 1,
    address: '0x3f344c88d823f180fb8b44a3c7cfc4edc92dfa35',
    name: 'Definex',
    symbol: 'DSWAP',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/13593/thumb/cjZjhY3w_400x400.png?1609998112'
  },
  {
    chainId: 1,
    address: '0x00a8b738e453ffd858a7edf03bccfe20412f0eb0',
    name: 'AllianceBlock',
    symbol: 'ALBT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12392/thumb/alliance_block_logo.jpg?1599546617'
  },
  {
    chainId: 1,
    address: '0xcf42de80d80edc4a8d56e4e840b5ff0dc84aaa17',
    name: 'Real estate Sales P',
    symbol: 'RSP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7357/thumb/rsSiuGCV_400x400.jpg?1547044037'
  },
  {
    chainId: 1,
    address: '0x5a666c7d92e5fa7edcb6390e4efd6d0cdd69cf37',
    name: 'Unmarshal',
    symbol: 'MARSH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14554/thumb/img_circle_256x256.png?1616997706'
  },
  {
    chainId: 1,
    address: '0x77777feddddffc19ff86db637967013e6c6a116c',
    name: 'Tornado Cash',
    symbol: 'TORN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13496/thumb/ZINt8NSB_400x400.jpg?1609193407'
  },
  {
    chainId: 1,
    address: '0x96b0bf939d9460095c15251f71fda11e41dcbddb',
    name: 'Sharpay',
    symbol: 'S',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2386/thumb/sharpay.png?1547036564'
  },
  {
    chainId: 1,
    address: '0x32163c2d2d313c73279e87398e7f84bb9f537e9d',
    name: 'etor',
    symbol: 'ETOR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7897/thumb/O0gpmBh.jpg?1551660695'
  },
  {
    chainId: 1,
    address: '0xe13559cf6edf84bd04bf679e251f285000b9305e',
    name: 'TMC NiftyGotchi',
    symbol: 'TMC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13627/thumb/tmc.png?1610434378'
  },
  {
    chainId: 1,
    address: '0x737fa0372c8d001904ae6acaf0552d4015f9c947',
    name: 'MEDIBIT',
    symbol: 'MEDIBIT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6606/thumb/medibit.png?1547978632'
  },
  {
    chainId: 1,
    address: '0xc28d4341ad8224e1a424558074ef0b4515f424d5',
    name: 'Datbit',
    symbol: 'DBT',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/7643/thumb/ZPzfoX06_400x400.jpg?1548917195'
  },
  {
    chainId: 1,
    address: '0xf5d0fefaab749d8b14c27f0de60cc6e9e7f848d1',
    name: 'YFARM Token',
    symbol: 'YFARM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12475/thumb/yffs.jpg?1600136951'
  },
  {
    chainId: 1,
    address: '0x2a7f709ee001069771ceb6d42e85035f7d18e736',
    name: 'OWL Token',
    symbol: 'OWL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12720/thumb/logo-transbg_200x200.png?1602024124'
  },
  {
    chainId: 1,
    address: '0xbff89386d062c6040ed1955e180c87dd00bb71af',
    name: 'Hedge Tech Governan',
    symbol: 'HTG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14505/thumb/hgt_logo.png?1616569312'
  },
  {
    chainId: 1,
    address: '0x00c83aecc790e8a4453e5dd3b0b4b3680501a7a7',
    name: 'SKALE',
    symbol: 'SKL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13245/thumb/SKALE_token_300x300.png?1606789574'
  },
  {
    chainId: 1,
    address: '0xb8366948b4a3f07bcbf14eb1739daa42a26b07c4',
    name: 'VALOBIT',
    symbol: 'VBIT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12394/thumb/Untitled-design-3.png?1599550715'
  },
  {
    chainId: 1,
    address: '0x4da9b813057d04baef4e5800e36083717b4a0341',
    name: 'Aave TUSD v1',
    symbol: 'ATUSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11724/thumb/aTUSD.png?1593082700'
  },
  {
    chainId: 1,
    address: '0x83e6f1e41cdd28eaceb20cb649155049fac3d5aa',
    name: 'Polkastarter',
    symbol: 'POLS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12648/thumb/polkastarter.png?1609813702'
  },
  {
    chainId: 1,
    address: '0xc1965d7d18f37062b18ab3d5d1fe7f69873b30dd',
    name: 'Coinzo Token',
    symbol: 'CNZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7530/thumb/lKas8L1U_400x400.jpg?1548229971'
  },
  {
    chainId: 1,
    address: '0x7c81542ed859a2061538fee22b6544a235b9557d',
    name: 'Combo',
    symbol: 'COMB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13053/thumb/7zYe50X.png?1604703767'
  },
  {
    chainId: 1,
    address: '0x2f109021afe75b949429fe30523ee7c0d5b27207',
    name: 'OccamFi',
    symbol: 'OCC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14801/thumb/occfi.PNG?1618531140'
  },
  {
    chainId: 1,
    address: '0x038a68ff68c393373ec894015816e33ad41bd564',
    name: 'Glitch Protocol',
    symbol: 'GLCH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13712/thumb/glitch_logo.jpeg?1611100011'
  },
  {
    chainId: 1,
    address: '0x8e9a29e7ed21db7c5b2e1cd75e676da0236dfb45',
    name: 'Minter Hub',
    symbol: 'HUB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14639/thumb/Nvoj_6Mu_400x400_%281%29.jpg?1617484760'
  },
  {
    chainId: 1,
    address: '0x7bd198b9107496fd5cc3d7655af52f43a8edbc4c',
    name: 'oneVBTC',
    symbol: 'ONEVBTC',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15104/thumb/logo_200_vBTC.png?1619732117'
  },
  {
    chainId: 1,
    address: '0x5f2ec9cf1ec1c0e2c880b6584921e812a4225395',
    name: 'Bitcoin Unicorn',
    symbol: 'BTCUI',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/3676/thumb/btcui.png?1547038666'
  },
  {
    chainId: 1,
    address: '0xf344490abd414dafa97f34c8ca501b0aa5bf8873',
    name: 'Webcoin',
    symbol: 'WEB',
    decimals: 1,
    logoURI: 'https://assets.coingecko.com/coins/images/3779/thumb/webcoin-logo.png?1547038866'
  },
  {
    chainId: 1,
    address: '0x5c872500c00565505f3624ab435c222e558e9ff8',
    name: 'CoTrader',
    symbol: 'COT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4205/thumb/logo_black.png?1547039508'
  },
  {
    chainId: 1,
    address: '0x888888848b652b3e3a0f34c96e00eec0f3a23f72',
    name: 'Alien Worlds',
    symbol: 'TLM',
    decimals: 4,
    logoURI:
      'https://assets.coingecko.com/coins/images/14676/thumb/kY-C4o7RThfWrDQsLCAG4q4clZhBDDfJQVhWUEKxXAzyQYMj4Jmq1zmFwpRqxhAJFPOa0AsW_PTSshoPuMnXNwq3rU7Imp15QimXTjlXMx0nC088mt1rIwRs75GnLLugWjSllxgzvQ9YrP4tBgclK4_rb17hjnusGj_c0u2fx0AvVokjSNB-v2poTj0xT9BZRCbzRE3-lF1.jpg?1617700061'
  },
  {
    chainId: 1,
    address: '0x9746953f5b1324a78132895cfd263f417b0faae3',
    name: 'ValueCyberToken',
    symbol: 'VCT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5276/thumb/valuecybertoken-logo.jpg?1547040788'
  },
  {
    chainId: 1,
    address: '0xeed0c8d2da6d243329a6f4a8c2ac61a59ecbfa02',
    name: 'Mithril Cash',
    symbol: 'MIC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13515/thumb/photo_2020-12-30_11-05-59.jpg?1609298399'
  },
  {
    chainId: 1,
    address: '0x50ec35d1e18d439f02fa895746fc3e1bef311780',
    name: 'Epanus',
    symbol: 'EPS',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/12295/thumb/SBqy8NapjZHtkIb4gkQrAXfxFI-j5G4mvfTxCVb2b1HuNGVv0m_6JTq4Ika8BOSHgbWBm0zKSH1dSohftNOaMI00Nb9hW_q5wnMoMi78BIbqTmf810zAHJPpXlFuMhZJ1Q1pUIjyKhb476mFwn5fys_EIF_26GhEE_n9TgAuyMI71hnYDCguzD-ZA7SsMoq.jpg?1598924999'
  },
  {
    chainId: 1,
    address: '0x670f9d9a26d3d42030794ff035d35a67aa092ead',
    name: 'XBullion',
    symbol: 'GOLD',
    decimals: 8,
    logoURI:
      'https://assets.coingecko.com/coins/images/15658/thumb/WhatsApp_Image_2021-05-17_at_2.24.16_PM.jpeg?1621484245'
  },
  {
    chainId: 1,
    address: '0xabafa52d3d5a2c18a4c1ae24480d22b831fc0413',
    name: 'Future Of Finance F',
    symbol: 'FFF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15761/thumb/xg1NFl0.png?1621825451'
  },
  {
    chainId: 1,
    address: '0xeeaa34af95b034bada4baf565063132c765b1fa5',
    name: 'OLCF',
    symbol: 'OLCF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12939/thumb/2_5237799779538307093.png?1603838173'
  },
  {
    chainId: 1,
    address: '0xc4c2614e694cf534d407ee49f8e44d125e4681c4',
    name: 'Chain Games',
    symbol: 'CHAIN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12257/thumb/chain-logo-centered-500x500.png?1599617244'
  },
  {
    chainId: 1,
    address: '0x4893a1938df49f4178c37e4e965cc8a1b3882173',
    name: 'Baroin',
    symbol: 'BRI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12940/thumb/baroin200.png?1603757378'
  },
  {
    chainId: 1,
    address: '0xba9d4199fab4f26efe3551d490e3821486f135ba',
    name: 'SwissBorg',
    symbol: 'CHSB',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/2117/thumb/YJUrRy7r_400x400.png?1589794215'
  },
  {
    chainId: 1,
    address: '0x159751323a9e0415dd3d6d42a1212fe9f4a0848c',
    name: 'Insured Finance',
    symbol: 'INFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13727/thumb/logo_%287%29.png?1611210296'
  },
  {
    chainId: 1,
    address: '0xa774ffb4af6b0a91331c084e1aebae6ad535e6f3',
    name: 'flexUSD',
    symbol: 'FLEXUSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13323/thumb/flexUSD_2x.png?1607480702'
  },
  {
    chainId: 1,
    address: '0x0ea984e789302b7b612147e4e4144e64f21425eb',
    name: 'Waletoken',
    symbol: 'WTN',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/6404/thumb/waletoken_2_sosmed_Square.png?1547042539'
  },
  {
    chainId: 1,
    address: '0x47935edfb3cdd358c50f6c0add1cc24662e30f5f',
    name: 'SUP8EME',
    symbol: 'SUP8EME',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/13174/thumb/sup8eme_logo.png?1605864500'
  },
  {
    chainId: 1,
    address: '0x3e991dbec296e00626e58c33b62e53bec9d54636',
    name: 'USDL',
    symbol: 'USDL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12748/thumb/200x200usdl.png?1602214831'
  },
  {
    chainId: 1,
    address: '0xf205d2d65205711b6f6aae3fcb7ebdbc8573f192',
    name: 'Bmining Token',
    symbol: 'BMT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14155/thumb/BMT.jpg?1614673841'
  },
  {
    chainId: 1,
    address: '0x9631483f28b7f5cbf7d435ab249be8f709215bc3',
    name: 'Sperax',
    symbol: 'SPA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12232/thumb/sperax_logo.jpg?1598342904'
  },
  {
    chainId: 1,
    address: '0x7968bc6a03017ea2de509aaa816f163db0f35148',
    name: 'Hedget',
    symbol: 'HGET',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/12453/thumb/Hedget.png?1599944809'
  },
  {
    chainId: 1,
    address: '0x17525e4f4af59fbc29551bc4ece6ab60ed49ce31',
    name: 'PieDAO Yearn Ecosys',
    symbol: 'YPIE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13572/thumb/YPIE.png?1610437730'
  },
  {
    chainId: 1,
    address: '0xecbf566944250dde88322581024e611419715f7a',
    name: 'xBTC',
    symbol: 'XBTC',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/12613/thumb/Y3ZxUNM.png?1601242661'
  },
  {
    chainId: 1,
    address: '0xf5717f5df41ea67ef67dfd3c1d02f9940bcf5d08',
    name: 'SeChain',
    symbol: 'SNN',
    decimals: 3,
    logoURI: 'https://assets.coingecko.com/coins/images/11961/thumb/2AsGMTeI_400x400.jpg?1596453536'
  },
  {
    chainId: 1,
    address: '0x8b8a8a91d7b8ec2e6ab37ed8ffbacee062c6f3c7',
    name: 'ECP  Technology',
    symbol: 'ECP',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/8507/thumb/lhwmJl7R_400x400.png?1574931781'
  },
  {
    chainId: 1,
    address: '0x92ef4ffbfe0df030837b65d7fccfe1abd6549579',
    name: 'Swirge',
    symbol: 'SWG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12879/thumb/swirge_logo.png?1603250039'
  },
  {
    chainId: 1,
    address: '0xcc394f10545aeef24483d2347b32a34a44f20e6f',
    name: 'Vault Guardian Toke',
    symbol: 'VGT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4652/thumb/Vault12.jpg?1547039918'
  },
  {
    chainId: 1,
    address: '0x0fdc5313333533cc0c00c22792bff7383d3055f2',
    name: 'YFPRO Finance',
    symbol: 'YFPRO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12712/thumb/STOV7niY_400x400.png?1602630888'
  },
  {
    chainId: 1,
    address: '0xe17e41acd4caa3cec048837bfd1918b3c4141767',
    name: 'Ace Entertainment',
    symbol: 'ACE',
    decimals: 6,
    logoURI:
      'https://assets.coingecko.com/coins/images/9702/thumb/97706665_104962101231887_1710817986622783488_n.png?1592815301'
  },
  {
    chainId: 1,
    address: '0xef8ba8cba86f81b3108f60186fce9c81b5096d5c',
    name: 'YFII Gold',
    symbol: 'YFIIG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12587/thumb/yfiigold_logo.png?1600937694'
  },
  {
    chainId: 1,
    address: '0x40b92fce37cefa03baf7603e7913c1d34dd1a4ec',
    name: 'YeaFinance',
    symbol: 'YEA',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12705/thumb/logoeth.png?1601877470'
  },
  {
    chainId: 1,
    address: '0xd8912c10681d8b21fd3742244f44658dba12264e',
    name: 'Pluton',
    symbol: 'PLU',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1241/thumb/pluton.png?1548331624'
  },
  {
    chainId: 1,
    address: '0x29917c3b1c2b4779a7b61f08d98b3da0fcf3b6a4',
    name: 'ArgenPeso',
    symbol: 'ARGP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12715/thumb/logo256x256luz.png?1601962117'
  },
  {
    chainId: 1,
    address: '0x4c19596f5aaff459fa38b0f7ed92f11ae6543784',
    name: 'TrueFi',
    symbol: 'TRU',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13180/thumb/truefi_glyph_color.png?1617610941'
  },
  {
    chainId: 1,
    address: '0x4bb3205bf648b7f59ef90dee0f1b62f6116bc7ca',
    name: 'Beyond Finance',
    symbol: 'BYN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14746/thumb/ZJfK0wYy_400x400.jpg?1618078874'
  },
  {
    chainId: 1,
    address: '0xca3fe04c7ee111f0bbb02c328c699226acf9fd33',
    name: 'SEEN',
    symbol: 'SEEN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13052/thumb/seen_logo.png?1604678509'
  },
  {
    chainId: 1,
    address: '0xf6dbe88ba55f1793ff0773c9b1275300f830914f',
    name: 'Asian Dragon',
    symbol: 'AD',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/8100/thumb/zUrjsX2s.png?1554775662'
  },
  {
    chainId: 1,
    address: '0xa1d6df714f91debf4e0802a542e13067f31b8262',
    name: 'RedFOX Labs',
    symbol: 'RFOX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12956/thumb/logo-200.png?1607620623'
  },
  {
    chainId: 1,
    address: '0x918da91ccbc32b7a6a0cc4ecd5987bbab6e31e6d',
    name: 'sTSLA',
    symbol: 'STSLA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14038/thumb/stsla_logo.png?1613961916'
  },
  {
    chainId: 1,
    address: '0xd084b83c305dafd76ae3e1b4e1f1fe2ecccb3988',
    name: 'Terra Virtua Kolect',
    symbol: 'TVK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13330/thumb/CoinGLogo.png?1607507042'
  },
  {
    chainId: 1,
    address: '0xe61fdaf474fac07063f2234fb9e60c1163cfa850',
    name: 'Coin',
    symbol: 'COIN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2394/thumb/coin.png?1606626874'
  },
  {
    chainId: 1,
    address: '0x4618519de4c304f3444ffa7f812dddc2971cc688',
    name: 'Kind Ads Token',
    symbol: 'KIND',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/5302/thumb/kind-ads-token.png?1547975306'
  },
  {
    chainId: 1,
    address: '0x37d6e7f287200c740012747d2a79295caed2db35',
    name: 'ALP Coin',
    symbol: 'ALP',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9725/thumb/Logo_ALP_coin200%D1%85200.png?1572406499'
  },
  {
    chainId: 1,
    address: '0xb4ae194a0dcf1b4080b164c1d775ee06e0817305',
    name: 'Super Saiya jin',
    symbol: 'SSJ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12867/thumb/super_saiyan_jin_logo.jpg?1603168317'
  },
  {
    chainId: 1,
    address: '0xb6c3dc857845a713d3531cea5ac546f6767992f4',
    name: 'Advertise Coin',
    symbol: 'ADCO',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/14344/thumb/advertisecoin200x200.png?1615503478'
  },
  {
    chainId: 1,
    address: '0x875353da48c4f9627c4d0b8b8c37b162fc43ce67',
    name: 'Digipharm',
    symbol: 'DPH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9052/thumb/R4XzLI-a_400x400.jpg?1563910390'
  },
  {
    chainId: 1,
    address: '0x18aaa7115705e8be94bffebde57af9bfc265b998',
    name: 'Audius',
    symbol: 'AUDIO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12913/thumb/AudiusCoinLogo_2x.png?1603425727'
  },
  {
    chainId: 1,
    address: '0xafce9b78d409bf74980cacf610afb851bf02f257',
    name: 'Lift Kitchen  LFBTC',
    symbol: 'LFBTC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15598/thumb/lift.kitchen-logo.ee75a5f4.png?1621299108'
  },
  {
    chainId: 1,
    address: '0x666d875c600aa06ac1cf15641361dec3b00432ef',
    name: 'BTSE Token',
    symbol: 'BTSE',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10807/thumb/BTSE_logo_Square.jpeg?1583965964'
  },
  {
    chainId: 1,
    address: '0x3918c42f14f2eb1168365f911f63e540e5a306b5',
    name: 'Neural Protocol',
    symbol: 'NRP',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/6313/thumb/neural-protocol.png?1548085897'
  },
  {
    chainId: 1,
    address: '0x9b683d81b8334325bcc1802d47de65571993fe89',
    name: 'CoinBene Future Tok',
    symbol: 'CFT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10657/thumb/coinbenefuturet.PNG?1581459701'
  },
  {
    chainId: 1,
    address: '0x99f2b69ee2468c834a634ff50d930ae64e3e500f',
    name: 'Utrin',
    symbol: 'UTRIN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14380/thumb/utrin_logo.png?1615779349'
  },
  {
    chainId: 1,
    address: '0x5166d4ce79b9bf7df477da110c560ce3045aa889',
    name: 'Xdef Finance',
    symbol: 'XDEF2',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13619/thumb/xdef.png?1610330383'
  },
  {
    chainId: 1,
    address: '0x29257908879c5792f1bb25449a7209205434dc3f',
    name: 'Zbank Token',
    symbol: 'ZBK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11820/thumb/zbank2.png?1594681143'
  },
  {
    chainId: 1,
    address: '0xf1a91c7d44768070f711c68f33a7ca25c8d30268',
    name: 'Charli3',
    symbol: 'C3',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15052/thumb/show_image.jpg?1619561192'
  },
  {
    chainId: 1,
    address: '0xda022ca91df99413e8cb0caab4d1cba4e9018bea',
    name: 'JMTIME',
    symbol: 'JMT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7397/thumb/SQA_voBI.png?1555664534'
  },
  {
    chainId: 1,
    address: '0x13b02c8de71680e71f0820c996e4be43c2f57d15',
    name: 'Mirrored Invesco QQ',
    symbol: 'MQQQ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13645/thumb/mirror_logo_transparent.png?1611565327'
  },
  {
    chainId: 1,
    address: '0xd34c9281585866a9fb2af0931adb1fa35ed44039',
    name: 'Be Gaming Coin',
    symbol: 'BGC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13503/thumb/logo_%2897%29.png?1614982763'
  },
  { chainId: 1, address: '0xbd1e7f594600dcaf7f483af55deacec0159a064e', name: 'Qobit', symbol: 'QOB', decimals: 8 },
  {
    chainId: 1,
    address: '0x831168e04047d881968d236249689cc001a6da08',
    name: 'Trodl',
    symbol: 'TRO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14713/thumb/KU_Ul7P5_400x400.jpg?1617921826'
  },
  {
    chainId: 1,
    address: '0x97fb6fc2ad532033af97043b563131c5204f8a35',
    name: 'Plus Coin',
    symbol: 'NPLC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4686/thumb/plus-coin-logo.png?1547039959'
  },
  {
    chainId: 1,
    address: '0x881a7e25d44591c467a37da96adf3c3705e7251b',
    name: 'Elynet Token',
    symbol: 'ELYX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13326/thumb/KakaoTalk_20201208_102026847.png?1607483005'
  },
  {
    chainId: 1,
    address: '0x383518188c0c6d7730d91b2c03a03c837814a899',
    name: 'Olympus',
    symbol: 'OHM',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14483/thumb/olympus.png?1616466814'
  },
  {
    chainId: 1,
    address: '0x6f87d756daf0503d08eb8993686c7fc01dc44fb1',
    name: 'Unitrade',
    symbol: 'TRADE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11982/thumb/unitrade.PNG?1597009487'
  },
  {
    chainId: 1,
    address: '0xaf1250fa68d7decd34fd75de8742bc03b29bd58e',
    name: 'Invictus Hyperion F',
    symbol: 'IHF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3747/thumb/ihp.png?1547038807'
  },
  {
    chainId: 1,
    address: '0xa46f33694f5b31a6ed27eda16ccd466e94c80f1a',
    name: 'Unicly Pak Collecti',
    symbol: 'UPAK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15635/thumb/uPAK.jpg?1621403681'
  },
  {
    chainId: 1,
    address: '0xb37a769b37224449d92aac57de379e1267cd3b00',
    name: 'Cova Unity',
    symbol: 'COVA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4707/thumb/covalent.jpg?1547040005'
  },
  {
    chainId: 1,
    address: '0x0020d80229877b495d2bf3269a4c13f6f1e1b9d3',
    name: 'Dexmex',
    symbol: 'DEXM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13972/thumb/dexmex.png?1613393175'
  },
  {
    chainId: 1,
    address: '0x42891d10b550d0c5c4ac2e17bcd7e2a19c7230f0',
    name: 'Daxhund',
    symbol: 'DXH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14861/thumb/logo_coingecko.png?1618808438'
  },
  {
    chainId: 1,
    address: '0x0258f474786ddfd37abce6df6bbb1dd5dfc4434a',
    name: 'Orion Protocol',
    symbol: 'ORN',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11841/thumb/orion_logo.png?1594943318'
  },
  {
    chainId: 1,
    address: '0x777ba4d2393ea77893322743c7d817efef872204',
    name: 'W3bPUSH',
    symbol: 'W3B',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15263/thumb/logo.674b4d2b.png?1620279693'
  },
  {
    chainId: 1,
    address: '0xd2727e4259bba31955fd582986334aea4fba8c52',
    name: 'BLOOD',
    symbol: 'BLOOD',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10079/thumb/xzJDoHiq_400x400.jpg?1575584817'
  },
  {
    chainId: 1,
    address: '0x006699d34aa3013605d468d2755a2fe59a16b12b',
    name: 'Zild Finance',
    symbol: 'ZILD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15711/thumb/71716773.jpg?1621585408'
  },
  {
    chainId: 1,
    address: '0x1368452bfb5cd127971c8de22c58fbe89d35a6bf',
    name: 'JNTR e',
    symbol: 'JNTR/E',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12829/thumb/jntr_logo.jpg?1602835757'
  },
  {
    chainId: 1,
    address: '0x5218e472cfcfe0b64a064f055b43b4cdc9efd3a6',
    name: 'UnFederalReserve',
    symbol: 'ERSDL',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/12975/thumb/unFederalReserve_LogoArtboard_1_copy_20-64.png?1610087806'
  },
  {
    chainId: 1,
    address: '0x75230d7d86b59213773d47103b96a0e7133c8621',
    name: 'Buildin',
    symbol: 'BIN',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/15122/thumb/bin.PNG?1619754986'
  },
  {
    chainId: 1,
    address: '0x9248c485b0b80f76da451f167a8db30f33c70907',
    name: 'Debase',
    symbol: 'DEBASE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13201/thumb/debase_logo.png?1606190818'
  },
  {
    chainId: 1,
    address: '0x49a2e9be4e06c7106c5708bfcabb9322d0ba33db',
    name: 'MAKCOIN',
    symbol: 'MAK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12364/thumb/2HVPXFu.png?1599343931'
  },
  {
    chainId: 1,
    address: '0x13de0b0c1507d424fad4c6212830a0b2e59587c5',
    name: 'WishChain',
    symbol: 'WISH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9473/thumb/rX_fbFL6_400x400.jpg?1567724441'
  },
  {
    chainId: 1,
    address: '0xbbe460dc4ac73f7c13a2a2feecf9acf6d5083f9b',
    name: 'Wick Finance',
    symbol: 'WICK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15770/thumb/WICK-FINANCE-11-256x256.png?1621834220'
  },
  {
    chainId: 1,
    address: '0xb893a8049f250b57efa8c62d51527a22404d7c9a',
    name: 'American Shiba',
    symbol: 'USHIBA',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15650/thumb/american_shiba.PNG?1621476610'
  },
  {
    chainId: 1,
    address: '0x15c303b84045f67156acf6963954e4247b526717',
    name: 'Gas Cash Back',
    symbol: 'GCBN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13814/thumb/hlogo.e7dd3b85.png?1612049566'
  },
  {
    chainId: 1,
    address: '0x2e6e152d29053b6337e434bc9be17504170f8a5b',
    name: 'Yearn Finance Ecosy',
    symbol: 'YFIEC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12650/thumb/pypIqcG.jpg?1601431822'
  },
  {
    chainId: 1,
    address: '0x43dfc4159d86f3a37a5a4b3d4580b888ad7d4ddd',
    name: 'DODO',
    symbol: 'DODO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12651/thumb/dodo_logo.png?1601433025'
  },
  {
    chainId: 1,
    address: '0x0cf0ee63788a0849fe5297f3407f701e122cc023',
    name: 'Streamr',
    symbol: 'DATA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1115/thumb/streamr.png?1547035101'
  },
  {
    chainId: 1,
    address: '0xe65ee7c03bbb3c950cfd4895c24989afa233ef01',
    name: 'Zynecoin',
    symbol: 'ZYN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7587/thumb/LOGO-HD-Z-de-ZYNECOIN-coinmarket.png?1574652402'
  },
  {
    chainId: 1,
    address: '0xba21ef4c9f433ede00badefcc2754b8e74bd538a',
    name: 'Swapfolio',
    symbol: 'SWFL',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/12263/thumb/swapfolio-token-logo-icon-symbol-256-256.png?1598593097'
  },
  {
    chainId: 1,
    address: '0x05edffbda103d90d5040829a105f687443e0ca3e',
    name: 'Woyager',
    symbol: 'WYX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7404/thumb/PNG-amblem.png?1547085374'
  },
  {
    chainId: 1,
    address: '0xa9859874e1743a32409f75bb11549892138bba1e',
    name: 'iETH',
    symbol: 'IETH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8846/thumb/iETH.png?1616151402'
  },
  {
    chainId: 1,
    address: '0x33c2da7fd5b125e629b3950f3c38d7f721d7b30d',
    name: 'Seal Finance',
    symbol: 'SEAL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12762/thumb/seal.png?1602434721'
  },
  {
    chainId: 1,
    address: '0x3c7b464376db7c9927930cf50eefdea2eff3a66a',
    name: 'USDA',
    symbol: 'USDA',
    decimals: 8,
    logoURI:
      'https://assets.coingecko.com/coins/images/10280/thumb/71706577_106238760785222_2649249116525166592_n.png?1576972115'
  },
  {
    chainId: 1,
    address: '0x5aa485e6b794bcf5f834bf5c7ff43b9b83322764',
    name: 'Mandi Token',
    symbol: 'MANDI',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11747/thumb/DFE10T9.png?1593414801'
  },
  {
    chainId: 1,
    address: '0x1f0f468ee03a6d99cd8a09dd071494a83dc1c0e5',
    name: 'SmartX',
    symbol: 'SAT',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/11788/thumb/FZf8lPa3_400x400.jpg?1594018352'
  },
  {
    chainId: 1,
    address: '0xcadc0acd4b445166f12d2c07eac6e2544fbe2eef',
    name: 'CAD Coin',
    symbol: 'CADC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14149/thumb/cadc_2.png?1614666625'
  },
  {
    chainId: 1,
    address: '0x8f8e787989bc652eea01a6c88a19f0f379bdf4fd',
    name: 'Helex',
    symbol: 'HLX',
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/9658/thumb/hlx-logo.jpeg?1578295677'
  },
  {
    chainId: 1,
    address: '0x2f7b618993cc3848d6c7ed9cdd5e835e4fe22b98',
    name: 'Nami Corporation To',
    symbol: 'NAMI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13729/thumb/nami_logo_256.png?1611224464'
  },
  {
    chainId: 1,
    address: '0x78571accaf24052795f98b11f093b488a2d9eaa4',
    name: 'Rocket Token',
    symbol: 'RCKT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12262/thumb/IAY0DFX4_400x400.jpg?1598584324'
  },
  {
    chainId: 1,
    address: '0x69d2779533a4d2c780639713558b2cc98c46a9b7',
    name: 'VNT Chain',
    symbol: 'VNT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/6897/thumb/images_%282%29.png?1547043254'
  },
  {
    chainId: 1,
    address: '0x0dd83b5013b2ad7094b1a7783d96ae0168f82621',
    name: 'Florafic',
    symbol: 'FIC',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/10661/thumb/djMKIRPg9_amC409dhw27xYWgwMxnFk81HGexyGpj6RCaCYQZp7xjOjw-VpWiSHZp7r9HjtQbzsmNGw7TWR9T9PIQdxgXSJUCuY5FU7Zbt2xxfIhY4oD975-h4nrKEXbHcI6VMvzlbuz3J7o5elU-crDa4OoRns7IQqu02Hzgi-waDWPFR5fPwaLjRKSgq9Alt2bZ4S0CNKqelY.jpg?1581462836'
  },
  {
    chainId: 1,
    address: '0xb1f66997a5760428d3a87d68b90bfe0ae64121cc',
    name: 'Lua Token',
    symbol: 'LUA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12627/thumb/Screenshot_2020-09-28_at_6.24.59_PM.jpg?1601288721'
  },
  {
    chainId: 1,
    address: '0xa6446d655a0c34bc4f05042ee88170d056cbaf45',
    name: 'Caspian',
    symbol: 'CSP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6247/thumb/Caspian-logo.png?1547042282'
  },
  {
    chainId: 1,
    address: '0x5f75112bbb4e1af516fbe3e21528c63da2b6a1a5',
    name: 'Chess Coin',
    symbol: 'CHESS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9998/thumb/Webp.net-resizeimage.png?1595321722'
  },
  {
    chainId: 1,
    address: '0x1fe70be734e473e5721ea57c8b5b01e6caa52686',
    name: 'BitRent',
    symbol: 'RNTB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3499/thumb/photo.png?1547273521'
  },
  {
    chainId: 1,
    address: '0x62a56a4a2ef4d355d34d10fbf837e747504d38d4',
    name: 'Paypex',
    symbol: 'PAYX',
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/1189/thumb/paypex.png?1548330810'
  },
  {
    chainId: 1,
    address: '0xd2adc1c84443ad06f0017adca346bd9b6fc52cab',
    name: 'dFund',
    symbol: 'DFND',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15286/thumb/1zbdX36.png?1620355973'
  },
  {
    chainId: 1,
    address: '0xef31cb88048416e301fee1ea13e7664b887ba7e8',
    name: 'Staked yAxis',
    symbol: 'SYAX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13867/thumb/zzRHMkND_400x400.jpg?1612429884'
  },
  {
    chainId: 1,
    address: '0xe61eecfdba2ad1669cee138f1919d08ced070b83',
    name: 'VGTGToken',
    symbol: 'VGTG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11742/thumb/vgtg_gold_209x209.png?1593142842'
  },
  {
    chainId: 1,
    address: '0x006bea43baa3f7a6f765f14f10a1a1b08334ef45',
    name: 'Stox',
    symbol: 'STX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1230/thumb/stox-token.png?1547035256'
  },
  {
    chainId: 1,
    address: '0x0d9227f9c4ab3972f994fccc6eeba3213c0305c4',
    name: 'SERGS Governance',
    symbol: 'SSL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12790/thumb/1SSL_Ticker_Etherscan_256x256.png?1607928756'
  },
  {
    chainId: 1,
    address: '0x922ac473a3cc241fd3a0049ed14536452d58d73c',
    name: 'Vetri',
    symbol: 'VLD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2476/thumb/vld.png?1547036599'
  },
  {
    chainId: 1,
    address: '0xf4c17bc4979c1dc7b4ca50115358dec58c67fd9d',
    name: 'Omega Protocol Mone',
    symbol: 'OPM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12337/thumb/opm-200px.png?1599136480'
  },
  {
    chainId: 1,
    address: '0x2b67d1a87a8d8b280a23e97bc55095215ee0ec53',
    name: 'Crypto Price Index',
    symbol: 'CPI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12178/thumb/Z7x7eUa.png?1597886884'
  },
  {
    chainId: 1,
    address: '0xbbbbbbb5aa847a2003fbc6b5c16df0bd1e725f61',
    name: 'B Protocol',
    symbol: 'BPRO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15110/thumb/66428641.jpg?1619749844'
  },
  {
    chainId: 1,
    address: '0x2c50ba1ed5e4574c1b613b044bd1876f0b0b87a9',
    name: 'Kids Cash',
    symbol: 'KASH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11467/thumb/kash.png?1590242267'
  },
  {
    chainId: 1,
    address: '0xc697648021870cc250fbcd6ea8e77524f57b45c0',
    name: 'Podo Point',
    symbol: 'POD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13917/thumb/wkJXhZ0M_400x400.png?1612841009'
  },
  {
    chainId: 1,
    address: '0xdefa4e8a7bcba345f687a2f1456f5edd9ce97202',
    name: 'Kyber Network Cryst',
    symbol: 'KNC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14899/thumb/RwdVsGcw_400x400.jpg?1618923851'
  },
  {
    chainId: 1,
    address: '0x9b02dd390a603add5c07f9fd9175b7dabe8d63b7',
    name: 'Shopping io',
    symbol: 'SPI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13537/thumb/vd4KuYIE_400x400.png?1609578616'
  },
  {
    chainId: 1,
    address: '0x0128e4fccf5ef86b030b28f0a8a029a3c5397a94',
    name: 'FlashSwap',
    symbol: 'FSP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12362/thumb/ZKvuJEUR_400x400.jpg?1599299420'
  },
  {
    chainId: 1,
    address: '0xa4e7414fcba1af15203030c6daac630df8f16aea',
    name: 'Meme Cash',
    symbol: 'MCH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13344/thumb/memecash_logo.png?1607654879'
  },
  {
    chainId: 1,
    address: '0x22ef3753e3658e81d5a0d0889ce078178fe66595',
    name: 'SHENG',
    symbol: 'SHENG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11753/thumb/2xA1KJr6_400x400.png?1593420277'
  },
  {
    chainId: 1,
    address: '0x0316eb71485b0ab14103307bf65a021042c6d380',
    name: 'Huobi BTC',
    symbol: 'HBTC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12407/thumb/Unknown-5.png?1599624896'
  },
  {
    chainId: 1,
    address: '0x0435316b3ab4b999856085c98c3b1ab21d85cd4d',
    name: 'DeltaExCoin',
    symbol: 'DLTX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9255/thumb/dltx200px.png?1565648813'
  },
  {
    chainId: 1,
    address: '0x7e8539d1e5cb91d63e46b8e188403b3f262a949b',
    name: 'SOMIDAX',
    symbol: 'SMDX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10899/thumb/IcgJHkM.png?1585698101'
  },
  {
    chainId: 1,
    address: '0x53f0c9f1b6e283a59bcd672e80e2222b97e534cb',
    name: 'X Block',
    symbol: 'IX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8258/thumb/3SP7h2sF_400x400.jpg?1557121522'
  },
  {
    chainId: 1,
    address: '0x7dbbcae15d4db168e01673400d7844870cc1e36f',
    name: 'WOLFY',
    symbol: 'WOLFY',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15742/thumb/Wofly.png?1621761884'
  },
  {
    chainId: 1,
    address: '0xbbe319b73744db9d54f5d29df7d8256b7e43995c',
    name: 'Aragon China',
    symbol: 'ANC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12261/thumb/bbs-panda200.png?1598579736'
  },
  {
    chainId: 1,
    address: '0xe29c5b523590165795bbd7d52369c2895b18841f',
    name: 'Esports com',
    symbol: 'ERT',
    decimals: 19,
    logoURI: 'https://assets.coingecko.com/coins/images/1684/thumb/esports.jpg?1547035941'
  },
  {
    chainId: 1,
    address: '0x961c8c0b1aad0c0b10a51fef6a867e3091bcef17',
    name: 'DeFi Yield Protocol',
    symbol: 'DYP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13480/thumb/dyp_logo.png?1608971751'
  },
  {
    chainId: 1,
    address: '0xa13f0743951b4f6e3e3aa039f682e17279f52bc3',
    name: 'Sentinel Chain',
    symbol: 'SENC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2960/thumb/sentinel-chain.jpg?1547037201'
  },
  {
    chainId: 1,
    address: '0xf41e5fbc2f6aac200dd8619e121ce1f05d150077',
    name: 'CRYCASH',
    symbol: 'CRC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1945/thumb/crycash.png?1547790664'
  },
  {
    chainId: 1,
    address: '0x5322a3556f979ce2180b30e689a9436fddcb1021',
    name: 'yTSLA Finance',
    symbol: 'YTSLA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12547/thumb/YTSLA_%284%29.png?1600740522'
  },
  {
    chainId: 1,
    address: '0xcae72a7a0fd9046cf6b165ca54c9e3a3872109e0',
    name: 'AnRKey X',
    symbol: 'ANRX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13415/thumb/anrkey.jpg?1608311301'
  },
  {
    chainId: 1,
    address: '0x60a16b9efd33bb45c18833aed45ca66045b3b714',
    name: 'i9X Coin',
    symbol: 'I9X',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/14124/thumb/unnamed_%282%29.jpg?1614565794'
  },
  {
    chainId: 1,
    address: '0x927159670c50042109d7c0f4aed0cee89452433e',
    name: 'DGPayment',
    symbol: 'DGP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13291/thumb/dgpay-icon-logo.png?1607062736'
  },
  {
    chainId: 1,
    address: '0xaae3cf9968d26925bdb73ce3864e0084a20f4687',
    name: 'Farmland Protocol',
    symbol: 'FAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13497/thumb/70059912.jpg?1609193763'
  },
  {
    chainId: 1,
    address: '0x9972a0f24194447e73a7e8b6cd26a52e02ddfad5',
    name: 'Thorecash  ERC 20 ',
    symbol: 'TCH',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/4754/thumb/thorecash-logo.png?1547040075'
  },
  {
    chainId: 1,
    address: '0x12dc767728105aa415dd720dfbd0ea1d85841172',
    name: 'ELDORADO TOKEN',
    symbol: 'ERD',
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/9778/thumb/erdcoin.png?1574655368'
  },
  {
    chainId: 1,
    address: '0x56cdbbeec9828962cecb3f1b69517d430295d952',
    name: 'Davecoin',
    symbol: 'DDTG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12195/thumb/davecoin.png?1597991174'
  },
  {
    chainId: 1,
    address: '0x8ce9137d39326ad0cd6491fb5cc0cba0e089b6a9',
    name: 'Swipe',
    symbol: 'SXP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9368/thumb/swipe.png?1566792311'
  },
  {
    chainId: 1,
    address: '0xb66a2131a6b840dd020151f80723caed603efb51',
    name: 'NNB Token',
    symbol: 'NNB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8377/thumb/j7OrK9qU_400x400.jpg?1557822486'
  },
  {
    chainId: 1,
    address: '0x8a6aca71a218301c7081d4e96d64292d3b275ce0',
    name: 'S Finance',
    symbol: 'SFG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12605/thumb/Z7D8B4b.png?1605346184'
  },
  {
    chainId: 1,
    address: '0x0f02e27745e3b6e9e1310d19469e2b5d7b5ec99a',
    name: 'Peculium',
    symbol: 'PCL',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/2211/thumb/pcl.png?1585122309'
  },
  {
    chainId: 1,
    address: '0x81b1bfd6cb9ad42db395c2a27f73d4dcf5777e2d',
    name: 'Rare',
    symbol: 'RARE',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/12868/thumb/rare_logo.png?1603170092'
  },
  {
    chainId: 1,
    address: '0x314bd765cab4774b2e547eb0aa15013e03ff74d2',
    name: 'MONEY PARTY',
    symbol: 'PARTY',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/12097/thumb/MoneyPartyIcon.png?1597103516'
  },
  {
    chainId: 1,
    address: '0x2be5e8c109e2197d077d13a82daead6a9b3433c5',
    name: 'Tokamak Network',
    symbol: 'TON',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12260/thumb/D919x5-s_400x400.png?1598568068'
  },
  {
    chainId: 1,
    address: '0x44e4963f9012e7a7aeee05b7f2caae3419557aea',
    name: 'AI Prediction Ecosy',
    symbol: 'AIPE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8262/thumb/oS7YFh3X_400x400.png?1557125114'
  },
  {
    chainId: 1,
    address: '0xd907daeed4dae963b0e2442e330d1760d752a68e',
    name: 'SEED OF LOVE',
    symbol: 'SEOL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9016/thumb/seed256x256.png?1563691370'
  },
  {
    chainId: 1,
    address: '0x5d3a536e4d6dbd6114cc1ead35777bab948e3643',
    name: 'cDAI',
    symbol: 'CDAI',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9281/thumb/cDAI.png?1576467585'
  },
  {
    chainId: 1,
    address: '0x0371f7b219fff864b437bcfb564810f323fffcca',
    name: 'Bitcurate',
    symbol: 'BTCR',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/10895/thumb/Bitcurate_logo_200x200.png?1585694787'
  },
  {
    chainId: 1,
    address: '0xa0cf46eb152656c7090e769916eb44a138aaa406',
    name: 'Spheroid Universe',
    symbol: 'SPH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11968/thumb/spheroid-sph-icon-400x400.png?1597307957'
  },
  {
    chainId: 1,
    address: '0xe59064a8185ed1fca1d17999621efedfab4425c9',
    name: 'PrimeDAO',
    symbol: 'PRIME',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13165/thumb/PrimeDAO_200x200.png?1605800174'
  },
  {
    chainId: 1,
    address: '0x322124122df407b0d0d902cb713b3714fb2e2e1f',
    name: 'Soft Yearn',
    symbol: 'SYFI',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/12320/thumb/soft_yearn.png?1599094189'
  },
  {
    chainId: 1,
    address: '0xd1e10c37a27d95d95720291b1dc6f12f74c71443',
    name: 'Cosmo Coin',
    symbol: 'COSM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5252/thumb/cosmo-coin.png?1547040742'
  },
  {
    chainId: 1,
    address: '0x0a9d68886a0d7db83a30ec00d62512483e5ad437',
    name: 'Treecle',
    symbol: 'TRCL',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/11670/thumb/Mj4nZSD9_400x400.jpg?1592532649'
  },
  {
    chainId: 1,
    address: '0x8fb6c8a44a4e23fd1f5a936818b39083b4cdc865',
    name: 'BitOnyx Token',
    symbol: 'BTNYX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13684/thumb/token-logo-200px.png?1610860892'
  },
  {
    chainId: 1,
    address: '0xbfc1502ebc37475b940ced8f036b91018a73c8f6',
    name: 'Bidesk',
    symbol: 'BDK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12884/thumb/BDK_token.png?1603264639'
  },
  {
    chainId: 1,
    address: '0x87c00817abe35ed4c093e59043fae488238d2f74',
    name: 'Yoink',
    symbol: 'YNK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12639/thumb/yoin_logo.png?1601368710'
  },
  {
    chainId: 1,
    address: '0x4afadb32b8fdb334cf9f20afb476a06c1f5b111a',
    name: 'TLS Token',
    symbol: 'TLS',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10086/thumb/logo_200x200.png?1583054911'
  },
  {
    chainId: 1,
    address: '0x9eb6be354d88fd88795a04de899a57a77c545590',
    name: 'GameStop Finance',
    symbol: 'GME',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13820/thumb/gamestop_logo.jpg?1612070725'
  },
  {
    chainId: 1,
    address: '0xda23d301761e4e2bf474951f978f6dfb6f3c9f14',
    name: 'Tsuki Inu',
    symbol: 'TKINU',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15681/thumb/tkinu.png?1621508357'
  },
  {
    chainId: 1,
    address: '0x33349b282065b0284d756f0577fb39c158f935e6',
    name: 'Maple',
    symbol: 'MPL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14097/thumb/photo_2021-05-03_14.20.41.jpeg?1620022863'
  },
  {
    chainId: 1,
    address: '0x5acd19b9c91e596b1f062f18e3d02da7ed8d1e50',
    name: 'BTC Lite',
    symbol: 'BTCL',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/3569/thumb/4umbZipg_400x400.jpg?1547038416'
  },
  {
    chainId: 1,
    address: '0x446c9033e7516d820cc9a2ce2d0b7328b579406f',
    name: 'SOLVE',
    symbol: 'SOLVE',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/1768/thumb/Solve.Token_logo_200_200_wiyhout_BG.png?1575869846'
  },
  {
    chainId: 1,
    address: '0xd9bae39c725a1864b1133ad0ef1640d02f79b78c',
    name: 'Touch Social',
    symbol: 'TST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12355/thumb/RhEvWed.png?1599260984'
  },
  {
    chainId: 1,
    address: '0x26548041e3a78fdc60f3cce21977e1f5e46561b7',
    name: 'ZuCoinChain',
    symbol: 'ZCC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10748/thumb/erc20logo.png?1582838761'
  },
  {
    chainId: 1,
    address: '0x3d995510f8d82c2ea341845932b5ddde0bead9a3',
    name: 'uLABS synthetic Gas',
    symbol: 'UGAS-JAN21',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13261/thumb/uma_logo.jpg?1606799190'
  },
  {
    chainId: 1,
    address: '0xffcf5f9feb7e14833331b6687755d1622399316c',
    name: 'ICICB Coin',
    symbol: 'ICICB',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/15001/thumb/ICICB-LOGO-200-X200.png?1619402531'
  },
  {
    chainId: 1,
    address: '0x3810a4ddf41e586fa0dba1463a7951b748cecfca',
    name: 'Menapay',
    symbol: 'MPAY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6360/thumb/Menapay_ico.png?1547042454'
  },
  {
    chainId: 1,
    address: '0x47e820df943170b0e31f9e18ecd5bdd67b77ff1f',
    name: 'PIGX',
    symbol: 'PIGX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9069/thumb/IMG_5547.PNG?1604239318'
  },
  {
    chainId: 1,
    address: '0xaa7a9ca87d3694b5755f213b5d04094b8d0f0a6f',
    name: 'OriginTrail',
    symbol: 'TRAC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1877/thumb/origintrail.jpg?1605694415'
  },
  {
    chainId: 1,
    address: '0x740623d2c797b7d8d1ecb98e9b4afcf99ec31e14',
    name: 'DoYourTip',
    symbol: 'DYT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8936/thumb/image1.png?1578033515'
  },
  {
    chainId: 1,
    address: '0x00000000000045166c45af0fc6e4cf31d9e14b9a',
    name: 'TopBidder',
    symbol: 'BID',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14820/thumb/topbidder_logo_square_256.png?1618556813'
  },
  {
    chainId: 1,
    address: '0xcda2f16c6aa895d533506b426aff827b709c87f5',
    name: 'Fairum',
    symbol: 'FAI',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/13894/thumb/logo-65ce48ea8d2b64b6478a42c0050214e2.png?1612645237'
  },
  {
    chainId: 1,
    address: '0x5247c0db4044fb6f97f32c7e1b48758019a5a912',
    name: 'pxGOLD Synthetic Go',
    symbol: 'PXGOLD-MAR2022',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14430/thumb/PXGOLD.png?1616038262'
  },
  {
    chainId: 1,
    address: '0xb1a30851e3f7d841b231b086479608e17198363a',
    name: 'Homeros',
    symbol: 'HMR',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/11048/thumb/g1jAE5knZSlNUdLN8fEAQFMI4mTXCuNdrjj3i77rWhsIQADp3VWH2BHTkH7-oWwExxJk7Fu545LPeT3gMNzGlCZ63oX3-9sTjiqlSDo3fRLwHmhUgjD_jtKTl1AJ_F_UOOpsTqob9CxiWHXsSTlz0zODxOrjnqpMe4_cPT_C4EskSEVee_oooTasTQ6ONrv85Zcvc8Eeb8cHLsV.jpg?1587532938'
  },
  {
    chainId: 1,
    address: '0x21bfbda47a0b4b5b1248c767ee49f7caa9b23697',
    name: 'Ovr',
    symbol: 'OVR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13429/thumb/ovr_logo.png?1608518911'
  },
  {
    chainId: 1,
    address: '0x537a9095b78517597b5f2058edcd6e1978095909',
    name: 'Design',
    symbol: 'DSGN',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/13204/thumb/design_logo.jpg?1606196569'
  },
  {
    chainId: 1,
    address: '0xbe5b336ef62d1626940363cf34be079e0ab89f20',
    name: 'Bnoincoin',
    symbol: 'BNC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11511/thumb/bnoincoin_cryptocoin-1.png?1590489689'
  },
  {
    chainId: 1,
    address: '0xfbeea1c75e4c4465cb2fccc9c6d6afe984558e20',
    name: 'DuckDaoDime',
    symbol: 'DDIM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12146/thumb/token_DDIM-01.png?1606982032'
  },
  {
    chainId: 1,
    address: '0x6e605c269e0c92e70beeb85486f1fc550f9380bd',
    name: 'Catex Token',
    symbol: 'CATT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6353/thumb/catex.png?1548733307'
  },
  {
    chainId: 1,
    address: '0xbd2f0cd039e0bfcf88901c98c0bfac5ab27566e3',
    name: 'Dynamic Set Dollar',
    symbol: 'DSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13249/thumb/DSD.jpg?1606713628'
  },
  {
    chainId: 1,
    address: '0xd3c89cac4a4283edba6927e2910fd1ebc14fe006',
    name: 'Moona ',
    symbol: 'MOOI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14125/thumb/mooi.png?1617866132'
  },
  {
    chainId: 1,
    address: '0x696c1de4e7f475d5231372c47a627e4cd6ce555a',
    name: 'Impulse By FDR',
    symbol: 'IMPULSE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13871/thumb/Impulse_by_FDR_200x200.png?1612444245'
  },
  {
    chainId: 1,
    address: '0x827d53c8170af52625f414bde00326fc8a085e86',
    name: 'Beaxy',
    symbol: 'BXY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4114/thumb/beaxy.png?1583389826'
  },
  {
    chainId: 1,
    address: '0xd04785c4d8195e4a54d9dec3a9043872875ae9e2',
    name: 'Rotten',
    symbol: 'ROT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12560/thumb/rot_logo.png?1600762626'
  },
  {
    chainId: 1,
    address: '0xed6aad9650815d1647480caa1133043800d31533',
    name: 'TokenSwap',
    symbol: 'TOP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13474/thumb/E5C98xXt_400x400.png?1608854121'
  },
  {
    chainId: 1,
    address: '0xe5feeac09d36b18b3fa757e5cf3f8da6b8e27f4c',
    name: 'NFT Index',
    symbol: 'NFTI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14215/thumb/nfti.png?1614992905'
  },
  {
    chainId: 1,
    address: '0x722f97a435278b7383a1e3c47f41773bebf3232c',
    name: 'UCROWDME',
    symbol: 'UCM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12079/thumb/UKm2qXh.png?1605346168'
  },
  {
    chainId: 1,
    address: '0x8b8d971c8bc37f65a93c4609644fef0590af2fc7',
    name: 'Securypto',
    symbol: 'SCU',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13595/thumb/IjOeviyt_400x400.png?1609999546'
  },
  {
    chainId: 1,
    address: '0x3ca6b185285684e14f925179992387fb4ef90ac7',
    name: 'Cryptocurrency Busi',
    symbol: 'CBT',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/10512/thumb/37403472_2172303016130423_5092603986280710144_n.jpg?1580339145'
  },
  {
    chainId: 1,
    address: '0x903d78ca7d892e4518586d0b64f418bd4ca9a82d',
    name: 'FK Coin',
    symbol: 'FK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9801/thumb/logo.e944e891.png?1571918228'
  },
  {
    chainId: 1,
    address: '0x45804880de22913dafe09f4980848ece6ecbaf78',
    name: 'PAX Gold',
    symbol: 'PAXG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9519/thumb/paxg.PNG?1568542565'
  },
  {
    chainId: 1,
    address: '0x6400b5522f8d448c0803e6245436dd1c81df09ce',
    name: 'Content Value Netwo',
    symbol: 'CVNT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/5158/thumb/cvn.jpg?1547394846'
  },
  {
    chainId: 1,
    address: '0x0563dce613d559a47877ffd1593549fb9d3510d6',
    name: 'SuperBid',
    symbol: 'SUPERBID',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14179/thumb/sign_super_bid.png?1617964829'
  },
  {
    chainId: 1,
    address: '0x0b498ff89709d3838a063f1dfa463091f9801c2b',
    name: 'BTC 2x Flexible Lev',
    symbol: 'BTC2X-FLI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15406/thumb/photo_2021-05-11_22.30.33.jpeg?1620743443'
  },
  {
    chainId: 1,
    address: '0x481578423221a35b9358cfe5400d202d2bdc66f1',
    name: 'Cyber Doge',
    symbol: 'CYBERD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15508/thumb/PdaUeMH1_400x400.png?1621097475'
  },
  {
    chainId: 1,
    address: '0xcdeee767bed58c5325f68500115d4b722b3724ee',
    name: 'Carbon',
    symbol: 'CRBN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13262/thumb/CRBN_Icon_200.png?1606803750'
  },
  {
    chainId: 1,
    address: '0x47da42696a866cdc61a4c809a515500a242909c1',
    name: 'BitRewards Token',
    symbol: 'BIT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2198/thumb/bitrewards.png?1547273593'
  },
  {
    chainId: 1,
    address: '0xdf2c7238198ad8b389666574f2d8bc411a4b7428',
    name: 'Hifi Finance',
    symbol: 'MFT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3293/thumb/hifi_logo.png?1613102742'
  },
  {
    chainId: 1,
    address: '0x74fd51a98a4a1ecbef8cc43be801cce630e260bd',
    name: 'SiaCashCoin',
    symbol: 'SCC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5312/thumb/siacashcoin-logo.png?1547040865'
  },
  {
    chainId: 1,
    address: '0xa117ea1c0c85cef648df2b6f40e50bb5475c228d',
    name: 'Ducato Protocol Tok',
    symbol: 'DUCATO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12550/thumb/70691538.png?1600680832'
  },
  {
    chainId: 1,
    address: '0xa360f2af3f957906468c0fd7526391aed08ae3db',
    name: 'ETH 50 Day MA Cross',
    symbol: 'ETH50SMACO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10615/thumb/eth-50-day-moving-average-set.png?1580971806'
  },
  {
    chainId: 1,
    address: '0x2af72850c504ddd3c1876c66a914caee7ff8a46a',
    name: 'WhaleRoom',
    symbol: 'WHL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14328/thumb/ArEX8tkV_400x400.png?1615432175'
  },
  {
    chainId: 1,
    address: '0x41e5560054824ea6b0732e656e3ad64e20e94e45',
    name: 'Civic',
    symbol: 'CVC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/788/thumb/civic.png?1547034556'
  },
  {
    chainId: 1,
    address: '0xaec2e87e0a235266d9c5adc9deb4b2e29b54d009',
    name: 'SingularDTV',
    symbol: 'SNGLS',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/521/thumb/singulardtv.png?1547034199'
  },
  {
    chainId: 1,
    address: '0xbe038a2fdfec62cf1bed852f141a43005035edcc',
    name: 'INTchain',
    symbol: 'INT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2519/thumb/int-logo-red-transparent_200x200.png?1617251612'
  },
  {
    chainId: 1,
    address: '0x8b79656fc38a04044e495e22fad747126ca305c4',
    name: 'AgaveCoin',
    symbol: 'AGVC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7056/thumb/G4TML4cb_400x400.jpg?1547043511'
  },
  {
    chainId: 1,
    address: '0x0309c98b1bffa350bcb3f9fb9780970ca32a5060',
    name: 'BasketDAO DeFi Inde',
    symbol: 'BDI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14664/thumb/BDI_200.png?1621851285'
  },
  {
    chainId: 1,
    address: '0x5881da4527bcdc44a100f8ba2efc4039243d2c07',
    name: 'Pride',
    symbol: 'LGBTQ',
    decimals: 1,
    logoURI: 'https://assets.coingecko.com/coins/images/9987/thumb/logo.png?1578023118'
  },
  {
    chainId: 1,
    address: '0x865bfd8232778f00cae81315bf75ef1fe6e30cdd',
    name: 'ABLE X Token',
    symbol: 'ABLX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4730/thumb/able.png?1547040033'
  },
  {
    chainId: 1,
    address: '0x556148562d5ddeb72545d7ec4b3ec8edc8f55ba7',
    name: 'Predix Network',
    symbol: 'PRDX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12388/thumb/main-logo.png?1599531749'
  },
  {
    chainId: 1,
    address: '0x43044f861ec040db59a7e324c40507addb673142',
    name: 'Cap',
    symbol: 'CAP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11775/thumb/CAP.png?1594083244'
  },
  {
    chainId: 1,
    address: '0xa8eda9d4aee0eb882f8752c6ba7e16d9233c9ad2',
    name: 'LevelApp',
    symbol: 'LVL',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/5588/thumb/n5DaGBqY_400x400.jpg?1559789019'
  },
  {
    chainId: 1,
    address: '0xc4c7ea4fab34bd9fb9a5e1b1a98df76e26e6407c',
    name: 'COCOS BCX',
    symbol: 'COCOS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4932/thumb/_QPpjoUi_400x400.jpg?1566430520'
  },
  {
    chainId: 1,
    address: '0xf5c0e24aca5217bcbae662871cae1a86873f02db',
    name: 'Alligator   Fractal',
    symbol: 'GATOR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11653/thumb/alligator_fractal_set.png?1592325151'
  },
  {
    chainId: 1,
    address: '0x20b4b41ddaecd180798bd4322ba971fa1a54e512',
    name: 'DHD Coin',
    symbol: 'DHD',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/14617/thumb/2_%283%29.png?1617256439'
  },
  {
    chainId: 1,
    address: '0x5538ac3ce36e73bb851921f2a804b4657b5307bf',
    name: 'Wrapped Shuttleone',
    symbol: 'WSZO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14094/thumb/EMQvEJSY_400x400.png?1614245757'
  },
  {
    chainId: 1,
    address: '0x57c8d5d5b87a1580fdaf996cef674bb0d7f14c98',
    name: 'OdinBrowser',
    symbol: 'ODIN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7737/thumb/ZA9ZUdPO_400x400.jpg?1550110867'
  },
  {
    chainId: 1,
    address: '0xc980be019f2ac10a1c96f964b971a5f44551d318',
    name: 'BitFarmings',
    symbol: 'BFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13524/thumb/gA8yh7wZ_400x400.jpg?1609388994'
  },
  {
    chainId: 1,
    address: '0x84f710bae3316a74fb0fcb01904d2578a4cc6a26',
    name: 'PHILLIPS PAY COIN',
    symbol: 'PPC',
    decimals: 1,
    logoURI: 'https://assets.coingecko.com/coins/images/10311/thumb/ppc.PNG?1588127720'
  },
  {
    chainId: 1,
    address: '0x24d77c210a014b1e123a0878f6c903df74a2317b',
    name: 'Bitfxt Coin',
    symbol: 'BXT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9660/thumb/O7W3wRuP_400x400.jpg?1570482892'
  },
  {
    chainId: 1,
    address: '0xfdfe8b7ab6cf1bd1e3d14538ef40686296c42052',
    name: 'Skraps',
    symbol: 'SKRP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7987/thumb/60EnDfxK_400x400.jpg?1552888803'
  },
  {
    chainId: 1,
    address: '0xaaf37055188feee4869de63464937e683d61b2a1',
    name: 'UChain',
    symbol: 'UCN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4254/thumb/UChain-logo.jpg?1547039592'
  },
  {
    chainId: 1,
    address: '0x8b39b70e39aa811b69365398e0aace9bee238aeb',
    name: 'PolkaFoundry',
    symbol: 'PKF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14422/thumb/M7vmFGbV_400x400.png?1615972784'
  },
  {
    chainId: 1,
    address: '0xc06aec5191be16b94ffc97b6fc01393527367365',
    name: 'BTC ETH Equal Weigh',
    symbol: 'BTCETH5050',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10606/thumb/btc_buy_and_hold.png?1580970605'
  },
  {
    chainId: 1,
    address: '0xa9248f8e40d4b9c3ca8ebd8e07e9bcb942c616d8',
    name: 'ARKE',
    symbol: 'ARKE',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/14017/thumb/15ba0074-67e7-4f57-8358-313805266bf1.png?1613708594'
  },
  {
    chainId: 1,
    address: '0xaf162491c0b21900c01f4cc0f7110238aacdebe7',
    name: 'arcane bear',
    symbol: 'BEAR',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/13421/thumb/arcane_bear_logo.png?1608476062'
  },
  {
    chainId: 1,
    address: '0x1fa21b20222076d7465fb901e5f459289c95f66a',
    name: 'XFII',
    symbol: 'XFII',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13092/thumb/xfii_logo.png?1605065018'
  },
  {
    chainId: 1,
    address: '0xbff0e42eec4223fbd12260f47f3348d29876db42',
    name: 'Xtake',
    symbol: 'XTK',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/12204/thumb/xtake.jpg?1598080058'
  },
  {
    chainId: 1,
    address: '0x002f2264aeec71041ae5739ecf0a2c80c5ea30fa',
    name: 'ForesterX',
    symbol: 'FEX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11983/thumb/fxlogo.png?1596645102'
  },
  {
    chainId: 1,
    address: '0x1df7aa5551e801e280007dc0fc0454e2d06c1a89',
    name: 'BKEX Chain',
    symbol: 'BKK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7734/thumb/logo_%281%29.png?1617965603'
  },
  {
    chainId: 1,
    address: '0x8727c112c712c4a03371ac87a74dd6ab104af768',
    name: 'Jetcoin',
    symbol: 'JET',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2487/thumb/jetcoin.png?1547974820'
  },
  {
    chainId: 1,
    address: '0x08f5a9235b08173b7569f83645d2c7fb55e8ccd8',
    name: 'Tierion',
    symbol: 'TNT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/923/thumb/tierion.png?1547034767'
  },
  {
    chainId: 1,
    address: '0x56b4f8c39e07d4d5d91692acf9d0f6d4d3493763',
    name: 'Trism',
    symbol: 'TRISM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13549/thumb/76106366.png?1609715454'
  },
  {
    chainId: 1,
    address: '0xd8698a985b89650d0a70f99ad2909bd0c0b4b51c',
    name: 'Consentium',
    symbol: 'CSM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3068/thumb/consentium.png?1547037411'
  },
  {
    chainId: 1,
    address: '0x83eea00d838f92dec4d1475697b9f4d3537b56e3',
    name: 'VOISE',
    symbol: 'VOISE',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/749/thumb/voise_single.png?1547034493'
  },
  {
    chainId: 1,
    address: '0xb4058411967d5046f3510943103805be61f0600e',
    name: 'Stonk',
    symbol: 'STONK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14805/thumb/f9YBOaOF_400x400.jpg?1618534520'
  },
  {
    chainId: 1,
    address: '0x825130aa1beef07bdf4f389705321816d05b0d0f',
    name: 'UNII Finance',
    symbol: 'UNII',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12604/thumb/P3KzPgJ9_400x400.jpg?1601013005'
  },
  {
    chainId: 1,
    address: '0xc14830e53aa344e8c14603a91229a0b925b0b262',
    name: 'Populous XBRL Token',
    symbol: 'PXT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/6207/thumb/populous.jpg?1564992440'
  },
  {
    chainId: 1,
    address: '0x406ae253fb0aa898f9912fb192c1e6deb9623a07',
    name: 'TOROCUS Token',
    symbol: 'TOROCUS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9912/thumb/j_bHDge7_400x400.png?1573454465'
  },
  {
    chainId: 1,
    address: '0xf18ade29a225faa555e475ee01f9eb66eb4a3a74',
    name: 'Punk Zombie',
    symbol: 'PUNK-ZOMBIE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14000/thumb/icon-punk-zombies-40.png?1613565553'
  },
  {
    chainId: 1,
    address: '0x6051c1354ccc51b4d561e43b02735deae64768b8',
    name: 'yRise Finance',
    symbol: 'YRISE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12853/thumb/logoyrise-200px.png?1603084410'
  },
  {
    chainId: 1,
    address: '0xfdeaa4ab9fea519afd74df2257a21e5bca0dfd3f',
    name: 'BCAT',
    symbol: 'BCAT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9593/thumb/bcat.png?1569278870'
  },
  {
    chainId: 1,
    address: '0x0cd6c8161f1638485a1a2f5bf1a0127e45913c2f',
    name: '3X Short Tether Tok',
    symbol: 'USDTBEAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10307/thumb/683JEXMN_400x400.png?1577743905'
  },
  {
    chainId: 1,
    address: '0x90d46a9636b973f18186541d1b04ed3621a49cb0',
    name: 'Natmin',
    symbol: 'NAT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5024/thumb/natmin.png?1547040445'
  },
  {
    chainId: 1,
    address: '0x6c5fbc90e4d78f70cc5025db005b39b03914fc0c',
    name: 'Urus Token',
    symbol: 'URUS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14122/thumb/urus.PNG?1614565237'
  },
  {
    chainId: 1,
    address: '0x4946583c5b86e01ccd30c71a05617d06e3e73060',
    name: 'Foresting',
    symbol: 'PTON',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5186/thumb/foresting_pton_token.png?1554102297'
  },
  {
    chainId: 1,
    address: '0xc299004a310303d1c0005cb14c70ccc02863924d',
    name: 'Trinity Protocol',
    symbol: 'TRI',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13379/thumb/trinity_logo.png?1608030983'
  },
  {
    chainId: 1,
    address: '0x7061ee0896ab2c1865078b6c91731f67a89ea6a4',
    name: 'Nitrous Finance',
    symbol: 'NOS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13994/thumb/nos_logo.png?1613541619'
  },
  {
    chainId: 1,
    address: '0xc560984d8c4ced2a0eba5790b33b6f43afeef759',
    name: 'SpritzCoin',
    symbol: 'SPRTZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7174/thumb/RkSyYKm.png?1547043705'
  },
  {
    chainId: 1,
    address: '0xe50365f5d679cb98a1dd62d6f6e58e59321bcddf',
    name: 'LATOKEN',
    symbol: 'LA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1051/thumb/LA_token.png?1605772040'
  },
  {
    chainId: 1,
    address: '0xaf80e6612d9c2e883122e7f2292ee6c34176ad4f',
    name: 'CoinJanitor',
    symbol: 'JAN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3186/thumb/coinjanitor.jpg?1583132603'
  },
  {
    chainId: 1,
    address: '0x056dd20b01799e9c1952c7c9a5ff4409a6110085',
    name: 'WPP Token',
    symbol: 'WPP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8103/thumb/WzdD53fh_400x400.jpg?1554860792'
  },
  {
    chainId: 1,
    address: '0xe912b8ba2513d7e29b7b2e5b14398dbf77503fb4',
    name: 'InventoryClub',
    symbol: 'VNT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14410/thumb/_SWCTiTC_400x400.png?1615946356'
  },
  {
    chainId: 1,
    address: '0xa92e7c82b11d10716ab534051b271d2f6aef7df5',
    name: 'Ara Token',
    symbol: 'ARA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13877/thumb/ARA-Icon-Black.png?1612473492'
  },
  {
    chainId: 1,
    address: '0xb339fca531367067e98d7c4f9303ffeadff7b881',
    name: 'Aludra Network',
    symbol: 'ALD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12098/thumb/20200810_135504.jpg?1597112432'
  },
  {
    chainId: 1,
    address: '0xd8c82fbc4d8ed0644a7ec04cf973e84c6153c1d7',
    name: 'Rizen Coin  Old ',
    symbol: 'RZN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13373/thumb/Rizen_logo_black.png?1620206327'
  },
  {
    chainId: 1,
    address: '0xe8e06a5613dc86d459bc8fb989e173bb8b256072',
    name: 'Feyorra',
    symbol: 'FEY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13689/thumb/1_XiKKk5_400x400.jpg?1610953208'
  },
  {
    chainId: 1,
    address: '0x42d6622dece394b54999fbd73d108123806f6a18',
    name: 'SpankChain',
    symbol: 'SPANK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1226/thumb/spankchain.png?1548610811'
  },
  {
    chainId: 1,
    address: '0xf4eebdd0704021ef2a6bbe993fdf93030cd784b4',
    name: 'iEOS',
    symbol: 'IEOS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11627/thumb/iEOS.png?1616151361'
  },
  {
    chainId: 1,
    address: '0x5e6ffe7b174a50c81ff3f3c54c04fd3c11e20830',
    name: 'DragonVein',
    symbol: 'DVC',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/8263/thumb/LrDC1OmYiqq05_-E3PFLO6oJou6HOvVMFpjEqbYdcvaIPgQp7mF52lpj_rCmQW1FhAoBg5E2Z9o16L3Z7n8SQrMIp1YZNEGHRzc3P0P3jOZnyKvHNuTXQx9q13_kxXG1oMzaN3HfWdUnTMAfKRwghCa7BX67W9pSV-AJkcQ4-5Qy_qlAN28UCUgVnM0k6ZXlNWZo7Vi00CD7657qw.jpg?1557125797'
  },
  {
    chainId: 1,
    address: '0xa4e8c3ec456107ea67d3075bf9e3df3a75823db0',
    name: 'Loom Network  OLD ',
    symbol: 'LOOMOLD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3387/thumb/1_K76UVoLq-FOL7l-_Fag-Qw_2x.png?1547038040'
  },
  {
    chainId: 1,
    address: '0x5d858bcd53e085920620549214a8b27ce2f04670',
    name: 'POP Network',
    symbol: 'POP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7281/thumb/-3r4B_4c_400x400.png?1602285342'
  },
  {
    chainId: 1,
    address: '0x93ed140172ff226dad1f7f3650489b8daa07ae7f',
    name: 'zzz finance v2',
    symbol: 'ZZZV2',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13584/thumb/zzz_finance.jpg?1609919864'
  },
  {
    chainId: 1,
    address: '0xc2d3ae29c8309c14994d02ecd228cf86f3efde77',
    name: 'CurrySwap',
    symbol: 'CURRY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13656/thumb/curry_logo.jpg?1610581982'
  },
  {
    chainId: 1,
    address: '0xecc0f1f860a82ab3b442382d93853c02d6384389',
    name: 'Axis DeFi',
    symbol: 'AXIS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12199/thumb/YeLWZ3V.jpg?1597998424'
  },
  {
    chainId: 1,
    address: '0x0018e66a1dea81fdd767cbb15673119b034b5cf2',
    name: 'BNS Governance',
    symbol: 'BNSG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13227/thumb/0_Ti_U1wOt5Oyir67x.png?1606445104'
  },
  {
    chainId: 1,
    address: '0x47481c1b44f2a1c0135c45aa402ce4f4dde4d30e',
    name: 'Meetple',
    symbol: 'MPT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14323/thumb/Tqlsvhn1_400x400.png?1615427198'
  },
  {
    chainId: 1,
    address: '0x2c974b2d0ba1716e644c1fc59982a89ddd2ff724',
    name: 'Viberate',
    symbol: 'VIB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/983/thumb/Viberate.png?1547034873'
  },
  {
    chainId: 1,
    address: '0x56c64e280ef1262da370d2c5862bb61c6dc3dbb4',
    name: 'Big Coin',
    symbol: 'BIG',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13756/thumb/Logo_-_2021-01-25T173222.900.png?1611567220'
  },
  {
    chainId: 1,
    address: '0x7051620d11042c4335069aaa4f10cd3b4290c681',
    name: 'TCASH',
    symbol: 'TCASH',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/6574/thumb/jiPkVuI7_400x400.jpg?1547042782'
  },
  {
    chainId: 1,
    address: '0x7510d6fac98a6eca2db7c9357619715a7f5049d4',
    name: 'Holistic BTC Set',
    symbol: 'TCAPBTCUSDC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11776/thumb/holistic_btc_set.png?1593931993'
  },
  {
    chainId: 1,
    address: '0x6839ac5ea8082688b706c773d5d2afd7d503fdd4',
    name: 'Next Level',
    symbol: 'NXL',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/15178/thumb/NXL-token-logo-200.png?1620026497'
  },
  {
    chainId: 1,
    address: '0x2f141ce366a2462f02cea3d12cf93e4dca49e4fd',
    name: 'FREE coin',
    symbol: 'FREE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5585/thumb/free-coin.png?1548126559'
  },
  {
    chainId: 1,
    address: '0xc36824905dff2eaaee7ecc09fcc63abc0af5abc5',
    name: 'Basis Bond',
    symbol: 'BAB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13961/thumb/bab_1.png?1613358492'
  },
  {
    chainId: 1,
    address: '0xcc4304a31d09258b0029ea7fe63d032f52e44efe',
    name: 'Trustswap',
    symbol: 'SWAP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11795/thumb/Trustswap.png?1594311216'
  },
  {
    chainId: 1,
    address: '0x3b0e1508fae33ffba8d3ea64851cf98c02f1c61b',
    name: 'KeFi Token',
    symbol: 'KFI',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14979/thumb/Kefi-Logo-256x256-px.png?1619217173'
  },
  {
    chainId: 1,
    address: '0x3a810ff7211b40c4fa76205a14efe161615d0385',
    name: 'AI Network',
    symbol: 'AIN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13211/thumb/AI_Network_Logo_200x200.png?1606205615'
  },
  {
    chainId: 1,
    address: '0x8eb24319393716668d768dcec29356ae9cffe285',
    name: 'SingularityNET',
    symbol: 'AGI',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/2138/thumb/singularitynet.png?1548609559'
  },
  {
    chainId: 1,
    address: '0xff1beda5ca92a83d05323e338d0534410858b6a2',
    name: 'DIVO Token',
    symbol: 'DIVO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9845/thumb/divo.PNG?1572591861'
  },
  {
    chainId: 1,
    address: '0x519c1001d550c0a1dae7d1fc220f7d14c2a521bb',
    name: 'Polkaswap',
    symbol: 'PSWAP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15475/thumb/polkaswap.jpg?1620994547'
  },
  {
    chainId: 1,
    address: '0x491e136ff7ff03e6ab097e54734697bb5802fc1c',
    name: 'Kattana',
    symbol: 'KTN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14739/thumb/c2IqkKWK_400x400.jpg?1618004426'
  },
  {
    chainId: 1,
    address: '0x539f3615c1dbafa0d008d87504667458acbd16fa',
    name: 'Fera',
    symbol: 'FERA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12143/thumb/IMG_20200908_085545_557.jpg?1599563732'
  },
  {
    chainId: 1,
    address: '0xac9ce326e95f51b5005e9fe1dd8085a01f18450c',
    name: 'VeriSafe',
    symbol: 'VSF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7862/thumb/verisafe_logo.png?1563852491'
  },
  {
    chainId: 1,
    address: '0x0aef06dcccc531e581f0440059e6ffcc206039ee',
    name: 'Intelligent Trading',
    symbol: 'ITT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/1064/thumb/itt.jpg?1547035010'
  },
  {
    chainId: 1,
    address: '0x8d5db0c1f0681071cb38a382ae6704588d9da587',
    name: 'Prophet',
    symbol: 'PROPHET',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13209/thumb/prophet_finance.png?1606202225'
  },
  {
    chainId: 1,
    address: '0x514cdb9cd8a2fb2bdcf7a3b8ddd098caf466e548',
    name: 'RedPanda Earth',
    symbol: 'REDPANDA',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15736/thumb/N-52GutZ_400x400.jpg?1621684353'
  },
  {
    chainId: 1,
    address: '0x761d38e5ddf6ccf6cf7c55759d5210750b5d60f3',
    name: 'Dogelon Mars',
    symbol: 'ELON',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14962/thumb/6GxcPRo3_400x400.jpg?1619157413'
  },
  {
    chainId: 1,
    address: '0x3aa5f749d4a6bcf67dac1091ceb69d1f5d86fa53',
    name: 'Deflect',
    symbol: 'DEFLCT',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13369/thumb/deflect_logo.jpg?1609223085'
  },
  {
    chainId: 1,
    address: '0x150b0b96933b75ce27af8b92441f8fb683bf9739',
    name: 'Dragonereum GOLD',
    symbol: 'GOLD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9905/thumb/PO04AL0y_400x400.jpg?1573437136'
  },
  {
    chainId: 1,
    address: '0xc8ba3cf103e5a1658209c366153197ac7fa9c9b1',
    name: 'Difo Network',
    symbol: 'DFN',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/14504/thumb/difo.png?1616566305'
  },
  {
    chainId: 1,
    address: '0x146d8d942048ad517479c9bab1788712af180fde',
    name: 'MIB Coin',
    symbol: 'MIB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5691/thumb/mibcoin.png?1547041559'
  },
  {
    chainId: 1,
    address: '0x04e0af0af1b7f0023c6b12af5a94df59b0e8cf59',
    name: 'Sensitrust',
    symbol: 'SETS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15035/thumb/SETS-token-logo-200.png?1619503990'
  },
  {
    chainId: 1,
    address: '0x94236591125e935f5ac128bb3d5062944c24958c',
    name: 'VegaWallet Token',
    symbol: 'VGW',
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/7355/thumb/cm_sfjC8_400x400.jpg?1547044033'
  },
  {
    chainId: 1,
    address: '0x0f767338244418310342d49b02183715691d988f',
    name: 'Genesis Token',
    symbol: 'GENT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7120/thumb/uUTszdKp_400x400.jpg?1547043617'
  },
  {
    chainId: 1,
    address: '0xc567bca531992352166252ea5121e535432e81ed',
    name: 'Tartarus',
    symbol: 'TAR',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13441/thumb/tartarus_logo.png?1608603011'
  },
  {
    chainId: 1,
    address: '0x94d863173ee77439e4292284ff13fad54b3ba182',
    name: 'Delphi',
    symbol: 'ADEL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12300/thumb/adel_on_white_10x.png?1598967061'
  },
  {
    chainId: 1,
    address: '0xc617d51e3a1f621da8ae67b2f652d6ac02eb8d95',
    name: 'Hoard Token',
    symbol: 'HRD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8386/thumb/Hoard_Token.png?1560841641'
  },
  {
    chainId: 1,
    address: '0x607c794cda77efb21f8848b7910ecf27451ae842',
    name: 'DeFiPie',
    symbol: 'PIE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11956/thumb/98j5E_EG_400x400.png?1596644614'
  },
  {
    chainId: 1,
    address: '0x056354f3ff20743aa4c0da365603871c7000b081',
    name: 'TriumphX',
    symbol: 'TRIX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12270/thumb/TRIX_Logo_transparent.png?1598619314'
  },
  {
    chainId: 1,
    address: '0x6d7917864003a9bb13cbbec8f1cdd4e36ddf6fc8',
    name: 'Semitoken',
    symbol: 'SEMI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12293/thumb/200-200_%EB%A1%9C%EA%B3%A0.jpg?1598922002'
  },
  {
    chainId: 1,
    address: '0x0d6f6a130c3046713cba425f41a65c17205df6b5',
    name: 'Baby Akita',
    symbol: 'BKITA',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15450/thumb/ycDKDtRt_400x400.jpg?1620836652'
  },
  {
    chainId: 1,
    address: '0xb6ff96b8a8d214544ca0dbc9b33f7ad6503efd32',
    name: 'Sync Network',
    symbol: 'SYNC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13231/thumb/sync_network.png?1606468585'
  },
  {
    chainId: 1,
    address: '0xee573a945b01b788b9287ce062a0cfc15be9fd86',
    name: 'Exeedme',
    symbol: 'XED',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13518/thumb/exeedme.png?1610669597'
  },
  {
    chainId: 1,
    address: '0x543ff227f64aa17ea132bf9886cab5db55dcaddf',
    name: 'DAOstack',
    symbol: 'GEN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3479/thumb/gen.png?1547038215'
  },
  {
    chainId: 1,
    address: '0xa0f0546eb5e3ee7e8cfc5da12e5949f3ae622675',
    name: 'Tokoin',
    symbol: 'TOKO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8807/thumb/logo-tokoin_%281%29.png?1619162982'
  },
  {
    chainId: 1,
    address: '0x06f65b8cfcb13a9fe37d836fe9708da38ecb29b2',
    name: 'SAINT FAME  Genesis',
    symbol: 'FAME',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11521/thumb/FAME.png?1590622461'
  },
  {
    chainId: 1,
    address: '0xf12ec0d3dab64ddefbdc96474bde25af3fe1b327',
    name: 'Stacy',
    symbol: 'STACY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12986/thumb/Stacy.png?1604384468'
  },
  {
    chainId: 1,
    address: '0xedeec5691f23e4914cf0183a4196bbeb30d027a0',
    name: 'Wrapped Statera',
    symbol: 'WSTA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13599/thumb/wsta_logo.png?1610011862'
  },
  {
    chainId: 1,
    address: '0xe1bad922f84b198a08292fb600319300ae32471b',
    name: 'Firmachain',
    symbol: 'FCT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9892/thumb/6mHcLurm_400x400.jpg?1573160342'
  },
  {
    chainId: 1,
    address: '0x7f0f118d083d5175ab9d2d34c4c8fa4f43c3f47b',
    name: 'PAMP CC',
    symbol: 'PAMP',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/15745/thumb/coingecko-pamp.png?1621779079'
  },
  {
    chainId: 1,
    address: '0x44f262622248027f8e2a8fb1090c4cf85072392c',
    name: 'Project Inverse',
    symbol: 'XIV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14231/thumb/photo_2021-03-17_11-07-42_%282%29.jpg?1615950934'
  },
  {
    chainId: 1,
    address: '0x993864e43caa7f7f12953ad6feb1d1ca635b875f',
    name: 'SingularityDAO',
    symbol: 'SDAO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15385/thumb/singularitydao_coinmarketcap.png?1620701215'
  },
  {
    chainId: 1,
    address: '0xcb4b17b9fa78bf1fac6d239e5bce291035750b6c',
    name: 'Unicly Beeple Colle',
    symbol: 'UBEEPLE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15224/thumb/uBEEPLE.jpeg?1620136048'
  },
  {
    chainId: 1,
    address: '0xeffea57067e02999fdcd0bb45c0f1071a29472d9',
    name: 'Zantepay',
    symbol: 'ZPAY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7122/thumb/984cSZaC_400x400.jpg?1547043619'
  },
  {
    chainId: 1,
    address: '0x3ef36d53a168c44ed2ec7a98129ca0308bc1ae45',
    name: 'DigiDinar StableTok',
    symbol: 'DDRST',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12835/thumb/ddrst.png?1602883422'
  },
  {
    chainId: 1,
    address: '0x3845badade8e6dff049820680d1f14bd3903a5d0',
    name: 'The Sandbox',
    symbol: 'SAND',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12129/thumb/sandbox_logo.jpg?1597397942'
  },
  {
    chainId: 1,
    address: '0x6d10e0194400a04600881057787eb9e9c063dca0',
    name: 'Proton Project',
    symbol: 'PRTN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14888/thumb/Golden-PRTN-Logo.jpeg?1618899544'
  },
  {
    chainId: 1,
    address: '0xe04491d64eaa464ec8fdf53c7a4c92bf5b2278cd',
    name: 'WORLDPET',
    symbol: 'WPT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11707/thumb/worldpet3.png?1592986614'
  },
  {
    chainId: 1,
    address: '0x30f271c9e86d2b7d00a6376cd96a1cfbd5f0b9b3',
    name: 'Decentr',
    symbol: 'DEC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11816/thumb/Decentr.png?1594637985'
  },
  {
    chainId: 1,
    address: '0x27269b3e45a4d3e79a3d6bfee0c8fb13d0d711a6',
    name: 'iXRP',
    symbol: 'IXRP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10457/thumb/iXRP.png?1616151666'
  },
  {
    chainId: 1,
    address: '0x6ce21e5f5383c95691d243879a86a6025e0870c0',
    name: 'Hintchain',
    symbol: 'HINT',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/9329/thumb/JwEnTYRktjoozNKeI6qtQ0CEuiwNhl_vWNoXvob7OO-ZJ7YsXqK6oUmBjdXy0XRTnLcOywgKyWP-3WPcpelcT2ZQyBYoYEGLXA4Q-n6utwbn43oTkW3Ro6Mm93tr9lC1lrb3E4vPTS17TYRvYDP_6c9KLulnS4y_E4FlU9u6Y2FkoYhFdrV9-XF1CPif17GxyVa9vr45xfgGmqmU5.jpg?1566284372'
  },
  {
    chainId: 1,
    address: '0x943ed852dadb5c3938ecdc6883718df8142de4c8',
    name: 'FansTime',
    symbol: 'FTI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4952/thumb/fanstime.png?1547395397'
  },
  {
    chainId: 1,
    address: '0x53893a4a67d4392ebebdf1a683e98e1c577ab6c1',
    name: 'MicroBloodScience',
    symbol: 'MBS',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/8755/thumb/I8yPI6EUpvrrVPWjBISufHV_of43y5S97JaX_AQ_ZjjrDE6r9XqanEp5m2CrrgOl911Cn87Q36t-E3Cyt9PoZgnf8-ccwiQO8xqnP6GNEB1TtgYL9dd_84j8zOo5sHuqBjWDVYGT03gV_QgfaWcVckH-M7hZ8XYAqBdFqorXf-rj7QNetdXxRxc4DRSSTgT8Ju7Y-5gn8hDncH-cD.jpg?1560842420'
  },
  {
    chainId: 1,
    address: '0xa9080bf7c8e55f2af5c6603243d5865f4f328715',
    name: 'MARKYT',
    symbol: 'MAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9897/thumb/4nVXqSYU_400x400.jpg?1573162305'
  },
  {
    chainId: 1,
    address: '0x917fd2f7378ff479419dcb56c5cbb445fbbf902a',
    name: 'Coinwaycoin',
    symbol: 'CAN',
    decimals: 10,
    logoURI: 'https://assets.coingecko.com/coins/images/12671/thumb/Coingeco.png?1601505278'
  },
  {
    chainId: 1,
    address: '0x469a8ea7683c27d31cd9b0de769151d8a8b66c5a',
    name: 'SpacePenguin',
    symbol: 'PNGN',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15435/thumb/PNGN_LOGO_WHITE_BG.png?1620800225'
  },
  {
    chainId: 1,
    address: '0xa98ed1fd277ead2c00d143cbe1465f59e65a0066',
    name: 'Thx ',
    symbol: 'THX',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/9134/thumb/dtUHCa05GdIeMwiuO1Vdhpxe8f3xbPP_lIwFrnw2ic800CB3fhlk2eOp8hAGg-EUwrCTHwcQQqv3N3ikf5qWOKmbJePX5iK333iXdIlVF4UyAHb7ZsCi8nYBqBCJ7KZ6BwYcdlbguRPth6YSDWL5EBDIJQGmmkWEb-qTX7rnYEqS-9crxEkQJujPBnCVUV1qz87RgHV9KOvWL5HW6twlwJ.jpg?1564611313'
  },
  {
    chainId: 1,
    address: '0xf45f6c8bb3d77ea762175b8f7ca4d251941649fa',
    name: 'Lemond',
    symbol: 'LEMD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14964/thumb/D-smP_i-_400x400.png?1619166250'
  },
  {
    chainId: 1,
    address: '0x2aa5ce395b00cc486159adbdd97c55b535cf2cf9',
    name: 'ElasticDAO',
    symbol: 'EGT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14458/thumb/edao_logo.png?1616213380'
  },
  {
    chainId: 1,
    address: '0x19a2cf2a1b2f76e52e2b0c572bd80a95b4fa8643',
    name: 'Fyooz NFT',
    symbol: 'FYZNFT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13989/thumb/fyznft.png?1613519992'
  },
  {
    chainId: 1,
    address: '0x3c6ff50c9ec362efa359317009428d52115fe643',
    name: 'PeerEx Network',
    symbol: 'PERX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12091/thumb/2AyoUJyQ_400x400.jpg?1597273390'
  },
  {
    chainId: 1,
    address: '0x4824a7b64e3966b0133f4f4ffb1b9d6beb75fff7',
    name: 'TokenClub',
    symbol: 'TCT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2455/thumb/tokenclub.png?1558012103'
  },
  {
    chainId: 1,
    address: '0x723cbfc05e2cfcc71d3d89e770d32801a5eef5ab',
    name: 'Bitcoin Pro',
    symbol: 'BTCP',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/3545/thumb/DSiD9ZhWsAE_8cS.png?1547038353'
  },
  {
    chainId: 1,
    address: '0x350758e4a1256561fd0ee142dc7e0545f561fadc',
    name: 'B2U Coin',
    symbol: 'B2U',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13713/thumb/b2ucoin_logo_200.png?1611103785'
  },
  {
    chainId: 1,
    address: '0x6307b25a665efc992ec1c1bc403c38f3ddd7c661',
    name: 'Global Coin Researc',
    symbol: 'GCR',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/14815/thumb/wun6fV4T_400x400.jpg?1618544214'
  },
  {
    chainId: 1,
    address: '0x4a615bb7166210cce20e6642a6f8fb5d4d044496',
    name: 'NAOS Finance',
    symbol: 'NAOS',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/15144/thumb/QmPKkp44ZJSXfRpRWExdUnct2dohcE3x1XUxgh4ryKEMq7.png?1621146704'
  },
  {
    chainId: 1,
    address: '0x4375e7ad8a01b8ec3ed041399f62d9cd120e0063',
    name: 'BitZ Token',
    symbol: 'BZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4774/thumb/BitZ.?1613974566'
  },
  {
    chainId: 1,
    address: '0x94501b6a153c8973fd1f321fcc8188d40dc5d72d',
    name: 'DigiDinar Token',
    symbol: 'DDRT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10415/thumb/8WbpGRw.png?1579128643'
  },
  {
    chainId: 1,
    address: '0x62947ff43626ea26427be5ae4c7dd03b4f1ca872',
    name: 'Neo Holistic Coin',
    symbol: 'NHC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/14596/thumb/nhc.png?1617173747'
  },
  {
    chainId: 1,
    address: '0x87f56ee356b434187105b40f96b230f5283c0ab4',
    name: 'Pitch',
    symbol: 'PITCH',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/2855/thumb/pitch_token.jpg?1547037026'
  },
  {
    chainId: 1,
    address: '0x12419eea0b053ffea92f9afcd7986a495e2cf0dd',
    name: 'The Nifty ONEz',
    symbol: 'ONEZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14370/thumb/the-nifty-onez.png?1615737408'
  },
  {
    chainId: 1,
    address: '0x57652fc91f522f9eff0b38cdf1d51f5fb5764215',
    name: 'Buddy',
    symbol: 'BUD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4697/thumb/buddy.jpg?1554103368'
  },
  {
    chainId: 1,
    address: '0x6e1a19f235be7ed8e3369ef73b196c07257494de',
    name: 'Wrapped Filecoin',
    symbol: 'WFIL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13238/thumb/WFIL-Icon.png?1606630561'
  },
  {
    chainId: 1,
    address: '0x19810559df63f19cfe88923313250550edadb743',
    name: 'Toast finance',
    symbol: 'HOUSE',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/12287/thumb/icon_%284%29.png?1598825274'
  },
  {
    chainId: 1,
    address: '0xe8d17542dfe79ff4fbd4b850f2d39dc69c4489a2',
    name: 'KiloAmple',
    symbol: 'KMPL',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13449/thumb/kappa_logo.png?1608681544'
  },
  {
    chainId: 1,
    address: '0x3a92bd396aef82af98ebc0aa9030d25a23b11c6b',
    name: 'Tokenbox',
    symbol: 'TBX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2644/thumb/tokenbox.png?1547036822'
  },
  {
    chainId: 1,
    address: '0x87047986e8e4961c11d2edcd94285e3a1331d97b',
    name: 'Yakuza DFO',
    symbol: 'YKZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12413/thumb/UeUSmpx.png?1601970433'
  },
  {
    chainId: 1,
    address: '0x875773784af8135ea0ef43b5a374aad105c5d39e',
    name: 'IDLE',
    symbol: 'IDLE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13286/thumb/token-logo.png?1607004948'
  },
  {
    chainId: 1,
    address: '0xd5930c307d7395ff807f2921f12c5eb82131a789',
    name: 'Bolt',
    symbol: 'BOLT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4489/thumb/bolt.png?1555648257'
  },
  {
    chainId: 1,
    address: '0x83ff572a1757b9e4508cb08f13a79ed162c756c4',
    name: 'Zrocor',
    symbol: 'ZCOR',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/13612/thumb/coin_%282%29.png?1610285699'
  },
  {
    chainId: 1,
    address: '0x2008e3057bd734e10ad13c9eae45ff132abc1722',
    name: 'Zebi',
    symbol: 'ZCO',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/2756/thumb/zebi.png?1548761131'
  },
  {
    chainId: 1,
    address: '0x3abdff32f76b42e7635bdb7e425f0231a5f3ab17',
    name: 'ConnectJob',
    symbol: 'CJT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2207/thumb/connectjob.png?1547739980'
  },
  {
    chainId: 1,
    address: '0xc538143202f3b11382d8606aae90a96b042a19db',
    name: 'Coinsbit Token',
    symbol: 'CNB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10340/thumb/cbicon.png?1578637386'
  },
  {
    chainId: 1,
    address: '0xf99d58e463a2e07e5692127302c20a191861b4d6',
    name: 'Anyswap',
    symbol: 'ANY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12242/thumb/anyswap.jpg?1598443880'
  },
  {
    chainId: 1,
    address: '0x0a8b16b27d5219c8c6b57d5442ce31d81573eee4',
    name: 'Wrapped ATROMG8',
    symbol: 'WAG8',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/15182/thumb/ag8.jpg?1620031331'
  },
  {
    chainId: 1,
    address: '0x1a4743cf1af4c289351390a2b3fe7c13d2f7c235',
    name: 'Castweet',
    symbol: 'CTT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9602/thumb/200x200color.png?1569479415'
  },
  {
    chainId: 1,
    address: '0x8a732bc91c33c167f868e0af7e6f31e0776d0f71',
    name: 'Litecoin Token',
    symbol: 'LTK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7573/thumb/LTK.jpg?1550626784'
  },
  {
    chainId: 1,
    address: '0xec58d3aefc9aaa2e0036fa65f70d569f49d9d1ed',
    name: 'uSTONKS Index Token',
    symbol: 'USTONKS-APR21',
    decimals: 6
  },
  {
    chainId: 1,
    address: '0xd36a0e7b741542208ae0fbb35453c893d0136625',
    name: 'ITO Utility Token',
    symbol: 'IUT',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/8420/thumb/300x300.png?1592800024'
  },
  {
    chainId: 1,
    address: '0x264dc2dedcdcbb897561a57cba5085ca416fb7b4',
    name: 'QunQun',
    symbol: 'QUN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2546/thumb/qunqun.png?1547036662'
  },
  {
    chainId: 1,
    address: '0xec213f83defb583af3a000b1c0ada660b1902a0f',
    name: 'Presearch',
    symbol: 'PRE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1299/thumb/presearch.png?1548331942'
  },
  {
    chainId: 1,
    address: '0xe6d2c3cb986db66818c14c7032db05d1d2a6ee74',
    name: 'FinexboxToken',
    symbol: 'FNB',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/8419/thumb/p1WP-viw_400x400.jpg?1558395663'
  },
  {
    chainId: 1,
    address: '0x3684b581db1f94b721ee0022624329feb16ab653',
    name: 'GUNTHY',
    symbol: 'GUNTHY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7869/thumb/GUNTHY_128.png?1562130711'
  },
  {
    chainId: 1,
    address: '0x515d7e9d75e2b76db60f8a051cd890eba23286bc',
    name: 'Governor DAO',
    symbol: 'GDAO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13140/thumb/GDAOlogo2-bird.png?1605591842'
  },
  {
    chainId: 1,
    address: '0x32a8cd4d04d5f2e5de30ad73ef0a377eca2fdd98',
    name: 'Karaganda Token',
    symbol: 'KRG',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/12611/thumb/117756674_103472948138919_7691283516567887912_n.jpg?1601241618'
  },
  {
    chainId: 1,
    address: '0xd2057d71fe3f5b0dc1e3e7722940e1908fc72078',
    name: 'King Swap',
    symbol: 'KING',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13622/thumb/rsz_king_token_new.png?1610437757'
  },
  {
    chainId: 1,
    address: '0xf8483e2d6560585c02d46bf7b3186bf154a96166',
    name: 'IdeaChain',
    symbol: 'ICH',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11053/thumb/4fJlFzca_400x400.jpg?1587609361'
  },
  {
    chainId: 1,
    address: '0x4afb0aac9b862946837b2444566b8a914d6d0d97',
    name: 'Simian Finance',
    symbol: 'SIFI',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14417/thumb/simian.png?1616735055'
  },
  {
    chainId: 1,
    address: '0x410e731c2970dce3add351064acf5ce9e33fdbf0',
    name: 'ONBUFF',
    symbol: 'ONIT',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/12252/thumb/d4suDHSzEZjDXc0uxFGPZXfDFp0L1tfoEusBx03zB1bvMLLbxa7Yq5naXAOutkirIlLXacFRRebX_hF2Ez69_fIPUfr2LuN0Z_UrXTLYds4aT95C6eHGXsZnezMHKAaOmjLpKto0xPTiYkXExvEvXrqL7O_J1wecb4eJsgwCoVprMLpm89BdKO9IyMmpNsfbW7JJFMiHgL8vq9fPhaeEi.jpg?1598519096'
  },
  {
    chainId: 1,
    address: '0x42edc1c5ff57ff5240c90e2d8dfa269d77d68013',
    name: 'Bitnorm',
    symbol: 'BN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14920/thumb/logo_2.71b730c9.png?1619043921'
  },
  {
    chainId: 1,
    address: '0x4289c043a12392f1027307fb58272d8ebd853912',
    name: 'AiLink Token',
    symbol: 'ALI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5917/thumb/ailink-token.png?1547041855'
  },
  {
    chainId: 1,
    address: '0x46b4a7d906f1a943b7744df23625e63726d79035',
    name: 'CODEO TOKEN',
    symbol: 'CODEO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9890/thumb/codeo.png?1592404070'
  },
  {
    chainId: 1,
    address: '0xec6a5d88bf56fd3f96957ae65916c69f29db35c5',
    name: 'apeUSD AAVE Synthet',
    symbol: 'APEUSD-AAVE-DEC21',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14399/thumb/apeusd.jpg?1615905232'
  },
  {
    chainId: 1,
    address: '0xc7ff1e126cc81e816915ff48c940ed9d4e6d05d6',
    name: 'IjasCoin',
    symbol: 'IJC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14099/thumb/Ijascode-Flat-Logo-2-24-2021.png?1614304358'
  },
  {
    chainId: 1,
    address: '0xad6683b7f3618c44f5ca6040902812dd890dde4d',
    name: 'Tnos Coin',
    symbol: 'TNO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15343/thumb/TNO-token-logo-200.png?1620621861'
  },
  {
    chainId: 1,
    address: '0xf70d160102cf7a22c1e432d6928a9d625db91170',
    name: 'Kuverit',
    symbol: 'KUV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8102/thumb/kzu5xCtG_400x400.jpg?1572471470'
  },
  {
    chainId: 1,
    address: '0x015df42d36bc851c7f15f80bd1d4e8dbf02aed0c',
    name: 'Connect Coin',
    symbol: 'XCON',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6592/thumb/connect_coin.jpg?1547042796'
  },
  {
    chainId: 1,
    address: '0xdb05ea0877a2622883941b939f0bb11d1ac7c400',
    name: 'Opacity',
    symbol: 'OPCT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7237/thumb/Opacity.jpg?1551843524'
  },
  {
    chainId: 1,
    address: '0xe55cc44c0cf9cede2d68f9432cbeeafa6357ed92',
    name: 'Rozeus',
    symbol: 'ROZ',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9917/thumb/vO7f-6gR_400x400.jpg?1573457563'
  },
  {
    chainId: 1,
    address: '0x26a604dffe3ddab3bee816097f81d3c4a2a4cf97',
    name: 'CorionX',
    symbol: 'CORX',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13129/thumb/x_log.png?1605515405'
  },
  {
    chainId: 1,
    address: '0x3e9bc21c9b189c09df3ef1b824798658d5011937',
    name: 'Linear',
    symbol: 'LINA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12509/thumb/linear.jpg?1606884470'
  },
  {
    chainId: 1,
    address: '0x3c72fca8523686fd9e5740b0826fa4bb376e0241',
    name: '300FIT',
    symbol: 'FIT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9690/thumb/6mkyI4Ss_400x400.png?1570832640'
  },
  {
    chainId: 1,
    address: '0x6c929cde908481f3d1d775008791f42b1b89dbb0',
    name: 'Boolean',
    symbol: 'BOOL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9888/thumb/booleanSiteLogo.png?1572943862'
  },
  {
    chainId: 1,
    address: '0x41ab75435668919bb507f871dd01e9762c2d173a',
    name: 'XChain Token',
    symbol: 'NXCT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7473/thumb/rSDrnQ3f_400x400.jpg?1547690137'
  },
  {
    chainId: 1,
    address: '0x9a1997c130f4b2997166975d9aff92797d5134c2',
    name: 'Bond Appetite USD',
    symbol: 'USDAP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14874/thumb/USDap_%281%29.png?1618822197'
  },
  {
    chainId: 1,
    address: '0x78b6174cc8a3848251704b8a5ced7f86e5d8ddf1',
    name: 'Wider Coin',
    symbol: 'WDR',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/15322/thumb/footer_logo_%281%29.png?1620512812'
  },
  {
    chainId: 1,
    address: '0x50987e6be405ebac691f8988304562e5efc3b2ea',
    name: 'Mycro',
    symbol: 'MYO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7035/thumb/130619_MYO_Icon_final.png?1560931928'
  },
  {
    chainId: 1,
    address: '0x16484d73ac08d2355f466d448d2b79d2039f6ebb',
    name: 'FortKnoxster',
    symbol: 'FKX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5050/thumb/ybUmAgmu_400x400.png?1614324389'
  },
  {
    chainId: 1,
    address: '0x2863916c6ebdbbf0c6f02f87b7eb478509299868',
    name: 'SIMBA Storage Token',
    symbol: 'SST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11908/thumb/SIMBA_2.png?1614318625'
  },
  {
    chainId: 1,
    address: '0x5228a22e72ccc52d415ecfd199f99d0665e7733b',
    name: 'pTokens BTC',
    symbol: 'PBTC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10805/thumb/J51iIea.png?1583891599'
  },
  {
    chainId: 1,
    address: '0x4edd66235349e353eb8cb8e40596599644bfe91c',
    name: 'Vanywhere',
    symbol: 'VANY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2701/thumb/vany.png?1547036908'
  },
  {
    chainId: 1,
    address: '0x2a8e1e676ec238d8a992307b495b45b3feaa5e86',
    name: 'Origin Dollar',
    symbol: 'OUSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12589/thumb/ousd-logo-200x200.png?1600943287'
  },
  {
    chainId: 1,
    address: '0x0ccd5dd52dee42b171a623478e5261c1eaae092a',
    name: 'DeFi on MCW',
    symbol: 'DFM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13831/thumb/logo_200x200_%281%29.png?1612168790'
  },
  {
    chainId: 1,
    address: '0x6e98e5401adcb0d76f4debfc3d794b3031f48790',
    name: 'Aurix',
    symbol: 'AUR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14372/thumb/gold-brown-2.png?1615769158'
  },
  {
    chainId: 1,
    address: '0x110fe5f21c1edd4f03a6e0de0d96d0c2eed0b9cc',
    name: 'Tessla Coin',
    symbol: 'TSLA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13045/thumb/Tessla-Icon.png?1604630729'
  },
  {
    chainId: 1,
    address: '0x0c63cae5fcc2ca3dde60a35e50362220651ebec8',
    name: 'stakedXEM',
    symbol: 'STXEM',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13417/thumb/stakedXEM_high_res.png?1608389932'
  },
  {
    chainId: 1,
    address: '0x1eddee3fa21591a9637f88dab9615c33ee636b9d',
    name: 'WeBlock',
    symbol: 'WON',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13226/thumb/won.png?1606371371'
  },
  {
    chainId: 1,
    address: '0xcb39c3502415152b2ec90ff07ee18cc94f681a72',
    name: 'Storeum',
    symbol: 'STO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8942/thumb/stoterum.png?1563423648'
  },
  {
    chainId: 1,
    address: '0xeabb8996ea1662cad2f7fb715127852cd3262ae9',
    name: 'Connect Financial',
    symbol: 'CNFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13592/thumb/Connect_Financial.png?1609993203'
  },
  {
    chainId: 1,
    address: '0x90b426067be0b0ff5de257bc4dd6a4815ea03b5f',
    name: 'Strain',
    symbol: 'STRN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13087/thumb/strain_logo.jpg?1604990516'
  },
  {
    chainId: 1,
    address: '0x2ac22ebc138ff127566f68db600addad7df38d38',
    name: 'Selenium',
    symbol: 'SLC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11169/thumb/jbIJzuZw_400x400.jpg?1589449310'
  },
  {
    chainId: 1,
    address: '0xaeeaa9c0ecd8d50ab7fcd159bddad0f52ce360c2',
    name: 'Plethori',
    symbol: 'PLE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15236/thumb/PLETHORI-LOGO-200X200PX-COINGECKO.png?1620192649'
  },
  {
    chainId: 1,
    address: '0x74303d9d085a0d647ef47f9c0c424c73f3bfa6ca',
    name: 'MIAMI',
    symbol: 'MIAMI',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/7139/thumb/eb6a27241027ae3c5a8fb0bb8752b28b_700x706.png?1547043640'
  },
  {
    chainId: 1,
    address: '0xf970b8e36e23f7fc3fd752eea86f8be8d83375a6',
    name: 'Ripio Credit Networ',
    symbol: 'RCN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1057/thumb/ripio-credit-network.png?1548608361'
  },
  {
    chainId: 1,
    address: '0x5091aed52ad421969254e48d29aa1d1807e1870b',
    name: 'ZOM',
    symbol: 'ZOM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11889/thumb/zom_256px.png?1610965111'
  },
  {
    chainId: 1,
    address: '0x5c62da804298d5972a323c80b539b8e7517a0dde',
    name: 'VENJOCOIN',
    symbol: 'VJC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8088/thumb/vjc-logo.jpg?1554454773'
  },
  {
    chainId: 1,
    address: '0xaf4dce16da2877f8c9e00544c93b62ac40631f16',
    name: 'Monetha',
    symbol: 'MTH',
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/902/thumb/mth-token.png?1617249354'
  },
  {
    chainId: 1,
    address: '0x17b26400621695c2d8c2d8869f6259e82d7544c4',
    name: 'Custom contract net',
    symbol: 'CCN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7225/thumb/pPmmolWS_400x400.jpg?1547043809'
  },
  {
    chainId: 1,
    address: '0x1e797ce986c3cff4472f7d38d5c4aba55dfefe40',
    name: 'BlockCDN',
    symbol: 'BCDN',
    decimals: 15,
    logoURI: 'https://assets.coingecko.com/coins/images/1301/thumb/blockcdn.png?1547350830'
  },
  {
    chainId: 1,
    address: '0x21a870c7fce1bce5d88bdf845ac332c76204a9a0',
    name: 'Refraction',
    symbol: 'REFRACTION',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14794/thumb/refraction.png?1618474332'
  },
  {
    chainId: 1,
    address: '0xa0b207103f764a920b4af9e691f5bd956de14ded',
    name: 'Abitshadow Token',
    symbol: 'ABST',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/7882/thumb/bjRfWjFv_400x400.jpg?1551316417'
  },
  {
    chainId: 1,
    address: '0x2904b9b16652d7d0408eccfa23a19d4a3358230f',
    name: 'Puriever',
    symbol: 'PURE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12600/thumb/200x200_pure_logo.png?1600999358'
  },
  {
    chainId: 1,
    address: '0x40e7705254494a7e61d5b7c86da50225ddc3daae',
    name: 'yplutus',
    symbol: 'YPLT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13358/thumb/ypltblack-01.png?1607826916'
  },
  {
    chainId: 1,
    address: '0xbd356a39bff2cada8e9248532dd879147221cf76',
    name: 'WOM Protocol',
    symbol: 'WOM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4559/thumb/wom_logo_small.png?1572098941'
  },
  {
    chainId: 1,
    address: '0x9196e18bc349b1f64bc08784eae259525329a1ad',
    name: 'Pussy Financial',
    symbol: 'PUSSY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15213/thumb/pussytoken.png?1620110339'
  },
  {
    chainId: 1,
    address: '0xdecade1c6bf2cd9fb89afad73e4a519c867adcf5',
    name: 'Experty Wisdom Toke',
    symbol: 'WIS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13133/thumb/n0MTVBrm_400x400.jpg?1605543934'
  },
  {
    chainId: 1,
    address: '0x35b08722aa26be119c1608029ccbc976ac5c1082',
    name: 'Eminer',
    symbol: 'EM',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9155/thumb/KDbQPKIu_400x400.png?1564735775'
  },
  {
    chainId: 1,
    address: '0x0829d2d5cc09d3d341e813c821b0cfae272d9fb2',
    name: 'Social Rocket',
    symbol: 'ROCKS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12994/thumb/logo-256x256-1.png?1604759401'
  },
  {
    chainId: 1,
    address: '0xf4a81c18816c9b0ab98fac51b36dcb63b0e58fde',
    name: 'YieldWars',
    symbol: 'WAR',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/12633/thumb/121169136_333321361293975_7238588238572942050_n.png?1602551929'
  },
  {
    chainId: 1,
    address: '0x5592c5aa89492ea918d55b804e177b5ca7dccd5a',
    name: 'Wrapped xBTC',
    symbol: 'WXBTC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15688/thumb/XBTC-Logo-6.png?1621564837'
  },
  {
    chainId: 1,
    address: '0x4be40bc9681d0a7c24a99b4c92f85b9053fc2a45',
    name: 'Dify Finance',
    symbol: 'YFIII',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12628/thumb/zNTAjrF.png?1601294851'
  },
  {
    chainId: 1,
    address: '0xb9ffe0b8ee2d1af94202ffed366520300748a4d8',
    name: 'ETH BTC EMA Ratio T',
    symbol: 'ETHBTCEMACO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10607/thumb/ethbtc-ema-ratio-set.png?1580970846'
  },
  {
    chainId: 1,
    address: '0xe36e2d3c7c34281fa3bc737950a68571736880a1',
    name: 'sADA',
    symbol: 'SADA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11622/thumb/sADA.png?1616148250'
  },
  {
    chainId: 1,
    address: '0x2b1fe2cea92436e8c34b7c215af66aaa2932a8b2',
    name: 'Sakhalin Husky',
    symbol: 'SAHU',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15750/thumb/HUSKY_H-1024x683-1.png?1621785464'
  },
  {
    chainId: 1,
    address: '0xd90e69f67203ebe02c917b5128629e77b4cd92dc',
    name: 'One Cash',
    symbol: 'ONC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13529/thumb/onc_logo.png?1609406029'
  },
  {
    chainId: 1,
    address: '0x01cc4151fe5f00efb8df2f90ff833725d3a482a3',
    name: 'SPECTRUM',
    symbol: 'SPT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/7688/thumb/SPT-Logo-200x200.png?1549596154'
  },
  {
    chainId: 1,
    address: '0x05d27cdd23e22ca63e7f9c7c6d1b79ede9c4fcf5',
    name: 'Yearn Finance Passi',
    symbol: 'YFPI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12640/thumb/200.png?1601369185'
  },
  {
    chainId: 1,
    address: '0x2d94aa3e47d9d5024503ca8491fce9a2fb4da198',
    name: 'Bankless DAO',
    symbol: 'BANK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15227/thumb/bankless.jpg?1620141963'
  },
  {
    chainId: 1,
    address: '0xae6eb6f6c0a1694968b9f78a4316319c27b0964b',
    name: 'PETROLEUM',
    symbol: 'OIL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11231/thumb/OIL.png?1593273828'
  },
  {
    chainId: 1,
    address: '0x4689a4e169eb39cc9078c0940e21ff1aa8a39b9c',
    name: 'Proton Token',
    symbol: 'PTT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5248/thumb/proton-token.png?1548332108'
  },
  {
    chainId: 1,
    address: '0x4104b135dbc9609fc1a9490e61369036497660c8',
    name: 'APWine',
    symbol: 'APW',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15597/thumb/ApWine.png?1621340387'
  },
  {
    chainId: 1,
    address: '0xa053c1b7013e77fe0a873ce0ca2bd5d0b7414c41',
    name: 'Kambria Yield Tunin',
    symbol: 'KYTE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14670/thumb/kyte.png?1617676559'
  },
  {
    chainId: 1,
    address: '0x0aacfbec6a24756c20d41914f2caba817c0d8521',
    name: 'YAM',
    symbol: 'YAM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12530/thumb/YAM-icon.png?1600495536'
  },
  {
    chainId: 1,
    address: '0xb59490ab09a0f526cc7305822ac65f2ab12f9723',
    name: 'Litentry',
    symbol: 'LIT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13825/thumb/logo_200x200.png?1612153317'
  },
  {
    chainId: 1,
    address: '0xe748269494e76c1cec3f627bb1e561e607da9161',
    name: 'XELS',
    symbol: 'XELS',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/14729/thumb/Xels_logo_square_%281%29.png?1617957186'
  },
  {
    chainId: 1,
    address: '0xffc63b9146967a1ba33066fb057ee3722221acf0',
    name: 'Alpha Token',
    symbol: 'A',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7968/thumb/alpha-token.jpg?1552883009'
  },
  {
    chainId: 1,
    address: '0x1c48f86ae57291f7686349f12601910bd8d470bb',
    name: 'USDK',
    symbol: 'USDK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8824/thumb/usdk.png?1563418517'
  },
  {
    chainId: 1,
    address: '0x7deb5e830be29f91e298ba5ff1356bb7f8146998',
    name: 'Aave MKR v1',
    symbol: 'AMKR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11731/thumb/aMKR.png?1593084715'
  },
  {
    chainId: 1,
    address: '0xa42f266684ac2ad6ecb00df95b1c76efbb6f136c',
    name: 'Cash Tech',
    symbol: 'CATE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14529/thumb/cash-tech-logo-color.png?1616748020'
  },
  {
    chainId: 1,
    address: '0x58a3520d738b268c2353ecee518a1ad8e28e4ae5',
    name: 'HEIDI',
    symbol: 'HDI',
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/11679/thumb/Untitled-design-4-removebg-preview.png?1592789518'
  },
  {
    chainId: 1,
    address: '0x98a25ba4c3793b9029652cbc1a8875cbe223df13',
    name: 'ETH Momentum Trigge',
    symbol: 'ETHMO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11650/thumb/ethmo.png?1592324820'
  },
  {
    chainId: 1,
    address: '0x20a68f9e34076b2dc15ce726d7eebb83b694702d',
    name: 'DefiVille Island',
    symbol: 'ISLA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14471/thumb/256.png?1616384288'
  },
  {
    chainId: 1,
    address: '0x41ad4093349c8a60de591a3c37dcd184558eaae3',
    name: 'Bitcoin and Company',
    symbol: 'BITN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9909/thumb/BITN-Token-RGB-256x256.png?1573442082'
  },
  {
    chainId: 1,
    address: '0x36905fc93280f52362a1cbab151f25dc46742fb5',
    name: 'Bottos',
    symbol: 'BTO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2048/thumb/bottos.png?1517478551'
  },
  {
    chainId: 1,
    address: '0x435d664f72d6f194ef67d63b5f3936650187b131',
    name: 'Pocket Node',
    symbol: 'NODE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10376/thumb/u1L_YtHS_400x400.jpg?1583377126'
  },
  {
    chainId: 1,
    address: '0xa6272359bc37f61af398071b65c8934aca744d53',
    name: 'GokuMarket Credit',
    symbol: 'GMC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12561/thumb/GMC-favicon-Asset-200x200.png?1601623779'
  },
  {
    chainId: 1,
    address: '0xbf4a9a37ecfc21825011285222c36ab35de51f14',
    name: 'Nyan V2',
    symbol: 'NYAN-2',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13093/thumb/v2-logo.png?1605067493'
  },
  {
    chainId: 1,
    address: '0x810908b285f85af668f6348cd8b26d76b3ec12e1',
    name: 'SwapCoinz',
    symbol: 'SPAZ',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9081/thumb/SPAZ.png?1619594569'
  },
  {
    chainId: 1,
    address: '0xc73c167e7a4ba109e4052f70d5466d0c312a344d',
    name: 'Sanshu Inu',
    symbol: 'SANSHU',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15370/thumb/gyZDIr1e_400x400.jpg?1620665871'
  },
  {
    chainId: 1,
    address: '0xfab5a05c933f1a2463e334e011992e897d56ef0a',
    name: 'DeFi of Thrones',
    symbol: 'DOTX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12265/thumb/logo200x200.jpg?1598599911'
  },
  {
    chainId: 1,
    address: '0x874d4c9b980f1a13dd44cbcdb912e24ef0671ed0',
    name: 'Guider',
    symbol: 'GDR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8425/thumb/mROuNfEF_400x400.png?1571308333'
  },
  {
    chainId: 1,
    address: '0xbd0793332e9fb844a52a205a233ef27a5b34b927',
    name: 'ZB Token',
    symbol: 'ZB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4773/thumb/zb-token-logo.png?1547040106'
  },
  {
    chainId: 1,
    address: '0xd4cb461eace80708078450e465881599d2235f1a',
    name: 'Passive Income',
    symbol: 'PSI',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13695/thumb/logo_psi.jpg?1610965597'
  },
  {
    chainId: 1,
    address: '0xa0afaa285ce85974c3c881256cb7f225e3a1178a',
    name: 'Wrapped CrescoFin',
    symbol: 'WCRES',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13254/thumb/cres_logo.png?1606728821'
  },
  {
    chainId: 1,
    address: '0xad0887734461af8c6033068bde4047dbe84074cc',
    name: 'Arbiswap',
    symbol: 'ASWAP',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12827/thumb/logo_%283%29.png?1602831070'
  },
  {
    chainId: 1,
    address: '0xb7bc7b0a32455f7e7a924f832ca4f0a0ac3b6b88',
    name: 'Warlord Token',
    symbol: 'WLT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12963/thumb/200px.png?1603854513'
  },
  {
    chainId: 1,
    address: '0xc3d088842dcf02c13699f936bb83dfbbc6f721ab',
    name: 'Voucher ETH',
    symbol: 'VETH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14484/thumb/veth_logo_uniswap_200x200.png?1616471309'
  },
  {
    chainId: 1,
    address: '0x3242aebcdcf8de491004b1c98e6595e9827f6c17',
    name: 'Global Utility Smar',
    symbol: 'GUSDT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13479/thumb/gusdt.png?1608948333'
  },
  {
    chainId: 1,
    address: '0x5de7cc4bcbca31c473f6d2f27825cfb09cc0bb16',
    name: 'XBE Token',
    symbol: 'XBE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14480/thumb/xbe.png?1616462685'
  },
  {
    chainId: 1,
    address: '0x945facb997494cc2570096c74b5f66a3507330a1',
    name: 'mStable BTC',
    symbol: 'MBTC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13973/thumb/mbtc.png?1613485921'
  },
  {
    chainId: 1,
    address: '0xd3e8695d2bef061eab38b5ef526c0f714108119c',
    name: 'YFIVE FINANCE',
    symbol: 'YFIVE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12292/thumb/0gW17G6E_400x400.jpg?1598886392'
  },
  {
    chainId: 1,
    address: '0xd31695a1d35e489252ce57b129fd4b1b05e6acac',
    name: 'TOKPIE',
    symbol: 'TKP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3731/thumb/tokpie-200x200.png?1562207865'
  },
  {
    chainId: 1,
    address: '0xc005204856ee7035a13d8d7cdbbdc13027afff90',
    name: 'MoneySwap',
    symbol: 'MSWAP',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/13576/thumb/logo_%281%29.png?1609888424'
  },
  {
    chainId: 1,
    address: '0xc52e23194476b1dd39408cb0b9d935da8e6db3d6',
    name: 'Clever DeFi',
    symbol: 'CLVA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14685/thumb/CLVA.png?1617777204'
  },
  {
    chainId: 1,
    address: '0xd24dff6117936b6ff97108cf26c1dd8865743d87',
    name: 'MEDIUM',
    symbol: 'MDM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9961/thumb/VEEFC4sG.png?1574293042'
  },
  {
    chainId: 1,
    address: '0xcec2387e04f9815bf12670dbf6cf03bba26df25f',
    name: 'YFILEND FINANCE',
    symbol: 'YFILD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12414/thumb/ylend.png?1599684775'
  },
  {
    chainId: 1,
    address: '0xb5943c96b28570d63512dda2069045b304994daf',
    name: 'Tugz',
    symbol: 'TUGZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14650/thumb/200tugz.png?1617511487'
  },
  {
    chainId: 1,
    address: '0x6f259637dcd74c767781e37bc6133cd6a68aa161',
    name: 'Huobi Token',
    symbol: 'HT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2822/thumb/huobi-token-logo.png?1547036992'
  },
  {
    chainId: 1,
    address: '0x241ba672574a78a3a604cdd0a94429a73a84a324',
    name: '4New',
    symbol: 'KWATT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4975/thumb/4new.png?1547040398'
  },
  {
    chainId: 1,
    address: '0x37e808f084101f75783612407e7c3f5f92d8ee3f',
    name: 'RI Token',
    symbol: 'RI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12536/thumb/Ri_logo_512x512.png?1601775196'
  },
  {
    chainId: 1,
    address: '0xc63b8ecce56ab9c46184ec6ab85e4771fea4c8ad',
    name: 'sGOOG',
    symbol: 'SGOOG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14951/thumb/sGOOG.png?1619129282'
  },
  {
    chainId: 1,
    address: '0xd56dac73a4d6766464b38ec6d91eb45ce7457c44',
    name: 'Panvala Pan',
    symbol: 'PAN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9543/thumb/pan-logo.png?1568674599'
  },
  {
    chainId: 1,
    address: '0x4b34c0cbeef271f895d339c5f76322d71a60782b',
    name: 'Yearn Finance Manag',
    symbol: 'YEFIM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12836/thumb/jOVGT0Y.png?1602886889'
  },
  {
    chainId: 1,
    address: '0x3449fc1cd036255ba1eb19d65ff4ba2b8903a69a',
    name: 'Basis Cash',
    symbol: 'BAC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13246/thumb/BAC.png?1613231642'
  },
  {
    chainId: 1,
    address: '0x6b4389afb3e243a65668b7311fa9ef092a8a3b64',
    name: 'Real Coin',
    symbol: 'REAL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14914/thumb/Real-LOGO_fav_Retina.png?1618989895'
  },
  {
    chainId: 1,
    address: '0x69bbc3f8787d573f1bbdd0a5f40c7ba0aee9bcc9',
    name: 'Yup',
    symbol: 'YUP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12322/thumb/yupx.png?1599094638'
  },
  {
    chainId: 1,
    address: '0x2cae31d2ca104a951654456f46168bc9f88fdc65',
    name: 'JUIICE',
    symbol: 'JUI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11951/thumb/aWgJrI7C_400x400.png?1596418994'
  },
  {
    chainId: 1,
    address: '0x5cc00cca0692b9b34af816e5439cdb47d3b63691',
    name: 'WooshCoin',
    symbol: 'XWO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6173/thumb/dvqbA4Hc_400x400_%281%29.jpg?1548040425'
  },
  {
    chainId: 1,
    address: '0x77607588222e01bf892a29abab45796a2047fc7b',
    name: 'Unagii ETH',
    symbol: 'UETH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14624/thumb/uETH.png?1617294107'
  },
  {
    chainId: 1,
    address: '0x7f86c782ec802ac402e0369d2e6d500256f7abc5',
    name: 'RING X PLATFORM',
    symbol: 'RINGX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11818/thumb/RINGX.png?1594680207'
  },
  {
    chainId: 1,
    address: '0x153ed9cc1b792979d2bde0bbf45cc2a7e436a5f9',
    name: 'XOVBank',
    symbol: 'XOV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5396/thumb/xov.jpg?1549857681'
  },
  {
    chainId: 1,
    address: '0x5c5887e55bbe41472acdba5fae989788c6f7ab59',
    name: 'STK Coin',
    symbol: 'STK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9234/thumb/stk-coin-logo-2019.png?1578647443'
  },
  {
    chainId: 1,
    address: '0x340d2bde5eb28c1eed91b2f790723e3b160613b7',
    name: 'BLOCKv',
    symbol: 'VEE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1266/thumb/blockv.png?1547351380'
  },
  {
    chainId: 1,
    address: '0xec5483804e637d45cde22fa0869656b64b5ab1ab',
    name: 'Acent',
    symbol: 'ACE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15211/thumb/acent-logo.png?1620108963'
  },
  {
    chainId: 1,
    address: '0x84cffa78b2fbbeec8c37391d2b12a04d2030845e',
    name: 'Digital Fitness',
    symbol: 'DEFIT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14699/thumb/defit.png?1617835823'
  },
  {
    chainId: 1,
    address: '0xeea9ae787f3a620072d13b2cdc8cabffb9c0ab96',
    name: 'Yearn Secure',
    symbol: 'YSEC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12993/thumb/ysec.png?1604212852'
  },
  {
    chainId: 1,
    address: '0x68aa3f232da9bdc2343465545794ef3eea5209bd',
    name: 'Mothership',
    symbol: 'MSP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/815/thumb/mothership.png?1547034603'
  },
  {
    chainId: 1,
    address: '0xcb94be6f13a1182e4a4b6140cb7bf2025d28e41b',
    name: 'WeTrust',
    symbol: 'TRST',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/645/thumb/wetrust.png?1547034370'
  },
  {
    chainId: 1,
    address: '0x5456bc77dd275c45c3c15f0cf936b763cf57c3b5',
    name: 'Anchor',
    symbol: 'ANCT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9926/thumb/T2BwU9Il_400x400.jpg?1573463847'
  },
  {
    chainId: 1,
    address: '0x37ee79e0b44866876de2fb7f416d0443dd5ae481',
    name: 'Tatcoin',
    symbol: 'TAT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11828/thumb/54098571.png?1594714629'
  },
  {
    chainId: 1,
    address: '0xbc81bf5b3173bccdbe62dba5f5b695522ad63559',
    name: 'Transmute',
    symbol: 'XPB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13743/thumb/lead-03.png?1611376298'
  },
  {
    chainId: 1,
    address: '0x72ca0501427bb8f089c1c4f767cb17d017e803a9',
    name: 'Liquid DeFi',
    symbol: 'LIQ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13107/thumb/liquid_defi_logo.jpg?1605247848'
  },
  {
    chainId: 1,
    address: '0xd64809f5f7d772d9112a6bd379de00a77188199e',
    name: 'Lyfe Silver',
    symbol: 'LSILVER',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14939/thumb/LSILVER-Logo-200.png?1619082811'
  },
  {
    chainId: 1,
    address: '0xbb340a2eaf55c5e67a5a05fe5ceed9b9702d76f4',
    name: 'BolttCoin',
    symbol: 'BOLTT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/3928/thumb/Ticker.jpeg?1551231549'
  },
  {
    chainId: 1,
    address: '0x0954906da0bf32d5479e25f46056d22f08464cab',
    name: 'Index Cooperative',
    symbol: 'INDEX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12729/thumb/c7w3TmXs_400x400.png?1602630788'
  },
  {
    chainId: 1,
    address: '0x3adfc4999f77d04c8341bac5f3a76f58dff5b37a',
    name: 'Privatix',
    symbol: 'PRIX',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/1183/thumb/dZZrRl8.png?1547035211'
  },
  {
    chainId: 1,
    address: '0xaaaaaaaba2ea3daab0a6c05f1b962c78c9836d99',
    name: 'Azbit',
    symbol: 'AZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4504/thumb/azbit.jpg?1566190849'
  },
  {
    chainId: 1,
    address: '0x5f64ab1544d28732f0a24f4713c2c8ec0da089f0',
    name: 'DEXTF',
    symbol: 'DEXTF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12634/thumb/DEXTFiconNEGATIVE-page-001.jpg?1601349042'
  },
  {
    chainId: 1,
    address: '0x28d3e409bb9bc58f1ca6e009f8fc78a1db85e6b7',
    name: 'Gem Exchange And Tr',
    symbol: 'GXT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12226/thumb/gxt_icon_256x256_gradient.png?1598306924'
  },
  {
    chainId: 1,
    address: '0xfb22ced41b1267da411f68c879f4defd0bd4796a',
    name: 'Ziot',
    symbol: 'ZIOT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14546/thumb/1_iSvPXhw4frHgjyMejcmoKQ.png?1616973916'
  },
  {
    chainId: 1,
    address: '0x3cbf23c081faa5419810ce0f6bc1ecb73006d848',
    name: 'Digible',
    symbol: 'DIGI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14960/thumb/RE3Fiua.png?1619148839'
  },
  {
    chainId: 1,
    address: '0xc96df921009b790dffca412375251ed1a2b75c60',
    name: 'Ormeus Coin',
    symbol: 'ORME',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/964/thumb/ORMEUS_logo.png?1606557243'
  },
  {
    chainId: 1,
    address: '0x24810d836f6d60a7ee499622b7103ec769e81e3b',
    name: 'ChunghopToken',
    symbol: 'CHC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8778/thumb/logo-white.png?1561106432'
  },
  {
    chainId: 1,
    address: '0x3cbc780d2934d55a06069e837fabd3e6fc23dab0',
    name: 'DBX',
    symbol: 'DBX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15682/thumb/photo_2021-05-17_22-10-35.jpg?1621549173'
  },
  {
    chainId: 1,
    address: '0xe09216f1d343dd39d6aa732a08036fee48555af0',
    name: 'Contribute',
    symbol: 'TRIB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12576/thumb/New_logo_circle.png?1604214723'
  },
  {
    chainId: 1,
    address: '0x018fb5af9d015af25592a014c4266a84143de7a0',
    name: 'MP3',
    symbol: 'MP3',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13897/thumb/1_sq13-ssNvv2APEFcy8dWJg.png?1612690862'
  },
  {
    chainId: 1,
    address: '0xe154d54890c35634ca525d543ed58c741af7cf7a',
    name: 'MEDIKEY',
    symbol: 'MKEY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8257/thumb/medikey.png?1563782655'
  },
  {
    chainId: 1,
    address: '0x420412e765bfa6d85aaac94b4f7b708c89be2e2b',
    name: 'Brazilian Digital T',
    symbol: 'BRZ',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/8472/thumb/2_vtiD3xwce9knG2COxtSMaQ.png?1597786961'
  },
  {
    chainId: 1,
    address: '0x61c320935da31552b0d0de515ddee964267c4764',
    name: 'Luxurium',
    symbol: 'LXMT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/6108/thumb/Luxurium-logo.jpg?1547042114'
  },
  {
    chainId: 1,
    address: '0xd82bb924a1707950903e2c0a619824024e254cd1',
    name: 'DAOfi',
    symbol: 'DAOFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12782/thumb/logocircle.png?1611944557'
  },
  {
    chainId: 1,
    address: '0xd7b7d3c0bda57723fb54ab95fd8f9ea033af37f2',
    name: 'Pylon Finance',
    symbol: 'PYLON',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12299/thumb/NewPylonLogo.png?1604457455'
  },
  {
    chainId: 1,
    address: '0x584b44853680ee34a0f337b712a8f66d816df151',
    name: 'AI Doctor',
    symbol: 'AIDOC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2449/thumb/aidoc.png?1547036587'
  },
  {
    chainId: 1,
    address: '0x653430560be843c4a3d143d0110e896c2ab8ac0d',
    name: 'Molecular Future',
    symbol: 'MOF',
    decimals: 16,
    logoURI: 'https://assets.coingecko.com/coins/images/2607/thumb/molecular_future.png?1547036754'
  },
  {
    chainId: 1,
    address: '0x449efe48ad7cd423bab056276639f8120cd4f9a3',
    name: 'LibreFreelencer',
    symbol: 'LIBREF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13169/thumb/LibraF-256x256.png?1606084188'
  },
  {
    chainId: 1,
    address: '0x6ab4a7d75b0a42b6bc83e852dab9e121f9c610aa',
    name: 'Elitium',
    symbol: 'EUM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7874/thumb/elitium-coin.png?1551864695'
  },
  {
    chainId: 1,
    address: '0x83869de76b9ad8125e22b857f519f001588c0f62',
    name: 'EXMO Coin',
    symbol: 'EXM',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9154/thumb/exmo_token.png?1579588209'
  },
  {
    chainId: 1,
    address: '0x3b9e094d56103611f0acefdab43182347ba60df4',
    name: 'PANTHEON X',
    symbol: 'XPN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8297/thumb/Hr9wHItp_400x400.jpg?1557307478'
  },
  {
    chainId: 1,
    address: '0xe3c864307b5592404431649de541c259497e2bd1',
    name: 'LoveChain',
    symbol: 'LOV',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13023/thumb/the_love_chain_logo.png?1604398250'
  },
  {
    chainId: 1,
    address: '0x17ac188e09a7890a1844e5e65471fe8b0ccfadf3',
    name: 'Cryptocurrency Top ',
    symbol: 'CC10',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13692/thumb/BRfNDy1.png?1610960481'
  },
  {
    chainId: 1,
    address: '0x595643d83b35df38e29058976c04000acfa31570',
    name: 'OBR',
    symbol: 'OBR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13154/thumb/nftobr.png?1605756268'
  },
  {
    chainId: 1,
    address: '0xc8d674114bac90148d11d3c1d33c61835a0f9dcd',
    name: 'Mirrored Netflix',
    symbol: 'MNFLX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13643/thumb/mirror_logo_transparent.png?1611565277'
  },
  {
    chainId: 1,
    address: '0xe52d53c8c9aa7255f8c2fa9f7093fea7192d2933',
    name: 'YieldX',
    symbol: 'YIELDX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12904/thumb/logo-200x200.png?1603353354'
  },
  {
    chainId: 1,
    address: '0xdb0acc14396d108b3c5574483acb817855c9dc8d',
    name: 'Overline Emblem',
    symbol: 'EMB',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/3335/thumb/czcuIce.png?1621842831'
  },
  {
    chainId: 1,
    address: '0xd42debe4edc92bd5a3fbb4243e1eccf6d63a4a5d',
    name: 'Carboneum',
    symbol: 'C8',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3127/thumb/c8.png?1547037525'
  },
  {
    chainId: 1,
    address: '0x14c926f2290044b647e1bf2072e67b495eff1905',
    name: 'Bethereum',
    symbol: 'BETHER',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2738/thumb/bethereum.png?1547036939'
  },
  {
    chainId: 1,
    address: '0xfec0cf7fe078a500abf15f1284958f22049c2c7e',
    name: 'Maecenas',
    symbol: 'ART',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1025/thumb/maecenas.png?1547034953'
  },
  {
    chainId: 1,
    address: '0xaffcdd96531bcd66faed95fc61e443d08f79efef',
    name: 'Perth Mint Gold Tok',
    symbol: 'PMGT',
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/10730/thumb/pmgt_logo_256x256.png?1582668331'
  },
  {
    chainId: 1,
    address: '0x365542df3c8c9d096c5f0de24a0d8cf33c19c8fd',
    name: 'Hyper Speed Network',
    symbol: 'HSN',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/8858/thumb/tFLHRsU2_400x400.jpg?1569316557'
  },
  {
    chainId: 1,
    address: '0x26b3038a7fc10b36c426846a9086ef87328da702',
    name: 'Yield Farming Token',
    symbol: 'YFT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11981/thumb/photo_2020-08-04_03-25-28.jpg?1596622873'
  },
  {
    chainId: 1,
    address: '0x6ec47a178a9d50d4ec4683003d8324f19ca35382',
    name: 'NDN Link',
    symbol: 'NDN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11553/thumb/5O_jIk2e_400x400.jpg?1591000318'
  },
  {
    chainId: 1,
    address: '0x58c69ed6cd6887c0225d1fccecc055127843c69b',
    name: 'HalalChain',
    symbol: 'HLC',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/2737/thumb/halalchain.png?1547036938'
  },
  {
    chainId: 1,
    address: '0xe09394f8ba642430ed448ca20f342ec7aa1ba2e1',
    name: 'Fesschain',
    symbol: 'FESS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8414/thumb/Zcn3Txzj_400x400.jpg?1590982136'
  },
  {
    chainId: 1,
    address: '0x5adc961d6ac3f7062d2ea45fefb8d8167d44b190',
    name: 'Dether',
    symbol: 'DTH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2231/thumb/dether.png?1547036510'
  },
  {
    chainId: 1,
    address: '0x30cf203b48edaa42c3b4918e955fed26cd012a3f',
    name: 'MetaGame',
    symbol: 'SEED',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13099/thumb/V8phEz8V.png?1612854078'
  },
  {
    chainId: 1,
    address: '0x892a6f9df0147e5f079b0993f486f9aca3c87881',
    name: 'xFund',
    symbol: 'XFUND',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13770/thumb/xfund.png?1611697744'
  },
  {
    chainId: 1,
    address: '0x2d80f5f5328fdcb6eceb7cacf5dd8aedaec94e20',
    name: 'AGA Token',
    symbol: 'AGA',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/12180/thumb/aga-logo.png?1597937396'
  },
  {
    chainId: 1,
    address: '0x1edc9ba729ef6fb017ef9c687b1a37d48b6a166c',
    name: 'Stark Chain',
    symbol: 'STARK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12958/thumb/stark-256_256.png?1603838395'
  },
  {
    chainId: 1,
    address: '0x05a78ffea2b35aa1af438c21cf06668cf118df0a',
    name: 'Migranet',
    symbol: 'MIG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9033/thumb/ymZOjgz5_400x400.jpg?1563790723'
  },
  {
    chainId: 1,
    address: '0xcf81e6fdb8d7a743c3d4c3542a5356ff197ea1c0',
    name: 'Global Gaming',
    symbol: 'GMNG',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12792/thumb/output-onlinepngtools.png_small.png?1602581337'
  },
  {
    chainId: 1,
    address: '0x0000852600ceb001e08e00bc008be620d60031f2',
    name: 'TrueHKD',
    symbol: 'THKD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9465/thumb/THKD.png?1567642964'
  },
  {
    chainId: 1,
    address: '0x924e26fee8e10c20726006cc2bd307a538b0ebe5',
    name: 'BTC RSI Crossover Y',
    symbol: 'BTCRSIAPY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11778/thumb/btc_rsi_apy_set.png?1593932540'
  },
  {
    chainId: 1,
    address: '0xfebc25f4c5fc3e90a7efae0b4d436a77c9e131b3',
    name: 'Cezo',
    symbol: 'CEZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13461/thumb/2_hj7B4Z_400x400.jpg?1608764687'
  },
  {
    chainId: 1,
    address: '0x5479d565e549f3ecdbde4ab836d02d86e0d6a8c7',
    name: 'Lendefi',
    symbol: 'LDFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13764/thumb/icons-08.png?1617767962'
  },
  {
    chainId: 1,
    address: '0xc20464e0c373486d2b3335576e83a218b1618a5e',
    name: 'Datarius Credit',
    symbol: 'DTRC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2794/thumb/Datarius-Logo.png?1547036972'
  },
  {
    chainId: 1,
    address: '0xdb11f6fc8e16c02719e9c2eb3c4c762fee8f5c5b',
    name: 'Nydronia',
    symbol: 'NIA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13738/thumb/IMG-20210121-221955-366.png?1611294905'
  },
  {
    chainId: 1,
    address: '0x8d1ce361eb68e9e05573443c407d4a3bed23b033',
    name: 'PieDAO DEFI  ',
    symbol: 'DEFI++',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13304/thumb/piedao__.png?1607322674'
  },
  {
    chainId: 1,
    address: '0x61bfc979ea8160ede9b862798b7833a97bafa02a',
    name: 'RELEASE',
    symbol: 'REL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5387/thumb/release.jpg?1547041000'
  },
  {
    chainId: 1,
    address: '0x5dd115eb39a0fc5da9022e4bbb8e8679ca066a74',
    name: 'Unilord',
    symbol: 'PEER',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14730/thumb/KWP0UWn4_400x400.jpg?1617957728'
  },
  {
    chainId: 1,
    address: '0xbaa70614c7aafb568a93e62a98d55696bcc85dfe',
    name: 'Unicap Finance',
    symbol: 'UCAP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13300/thumb/unicap256.png?1607308439'
  },
  {
    chainId: 1,
    address: '0x81313f7c5c9c824236c9e4cba3ac4b049986e756',
    name: 'HippoFinance',
    symbol: 'HIPPO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12581/thumb/chef.50555ea1.png?1600922357'
  },
  {
    chainId: 1,
    address: '0xe0c8087ce1a17bdd5d6c12eb52f8d7eff7791987',
    name: 'Linfinity',
    symbol: 'LFC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5364/thumb/Linfinity-logo.jpg?1547040963'
  },
  {
    chainId: 1,
    address: '0xb31c219959e06f9afbeb36b388a4bad13e802725',
    name: 'ONO',
    symbol: 'ONOT',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/7374/thumb/Q4smiijArZodsQ9wFctGCcM5I-czQdVwLd7pieSqKS1dDad_Y6OkypEKX7Ckil16iDz9o5K06r41tI4FSmuyCXhRXdkzPlwwwNkH2Uf6OsQB_NdlJ_UvklPmTb2IRE1FT2wnx1EVBz_t2K1oZNDi2L7rlhOVnxbkm3zpgcy15boOyEvpU-wCgAqrbQ6OLLYZAKOwfRS-NQVJCz2YfM8vGY2y7Zr-QM59t8hpI7cgMpY6lbfT.jpg?1547044066'
  },
  {
    chainId: 1,
    address: '0x7533d63a2558965472398ef473908e1320520ae2',
    name: 'INTEXCOIN',
    symbol: 'INTX',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/11854/thumb/INTX.png?1595167044'
  },
  {
    chainId: 1,
    address: '0xb6c6920327b33f8eec26786c7462c5f4098d47e3',
    name: 'Minty Art',
    symbol: 'MINTY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14152/thumb/p-1MCf0H_400x400.png?1614667489'
  },
  {
    chainId: 1,
    address: '0x270371c58d9d775ed73971dd414656107384f235',
    name: 'BTA Protocol',
    symbol: 'BTAP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14467/thumb/coingecko200x200.png?1616378590'
  },
  {
    chainId: 1,
    address: '0xcafe27178308351a12fffffdeb161d9d730da082',
    name: 'HotDollars Token',
    symbol: 'HDS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8505/thumb/pvGh4E0b_400x400.jpg?1559026304'
  },
  {
    chainId: 1,
    address: '0x1426cc6d52d1b14e2b3b1cb04d57ea42b39c4c7c',
    name: 'Jomon Shiba',
    symbol: 'JSHIBA',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15395/thumb/JSHIBA.png?1620713227'
  },
  {
    chainId: 1,
    address: '0xf9fbe825bfb2bf3e387af0dc18cac8d87f29dea8',
    name: 'Bot Ocean',
    symbol: 'BOTS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13909/thumb/photo_2021-02-09_10-08-17.jpg?1612836512'
  },
  {
    chainId: 1,
    address: '0x47e67ba66b0699500f18a53f94e2b9db3d47437e',
    name: 'PlayGame',
    symbol: 'PXG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7242/thumb/pxg-coin_3x.png?1547043841'
  },
  {
    chainId: 1,
    address: '0x5bdc00b6676579b301b276198db1ea9affb94329',
    name: 'Eterbase Utility To',
    symbol: 'XBASE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5059/thumb/image.png?1597301514'
  },
  {
    chainId: 1,
    address: '0x53c8395465a84955c95159814461466053dedede',
    name: 'DeGate',
    symbol: 'DG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14415/thumb/80035388.png?1615951031'
  },
  {
    chainId: 1,
    address: '0x07597255910a51509ca469568b048f2597e72504',
    name: 'Uptrennd',
    symbol: '1UP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8273/thumb/Uptrennd_Logo.png?1579334846'
  },
  {
    chainId: 1,
    address: '0x469e66e06fec34839e5eb1273ba85a119b8d702f',
    name: 'Degov',
    symbol: 'DEGOV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13215/thumb/degov.png?1606277121'
  },
  {
    chainId: 1,
    address: '0x7bce667ef12023dc5f8577d015a2f09d99a5ef58',
    name: 'Block Duelers',
    symbol: 'BDT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13757/thumb/block_duelers.png?1611567700'
  },
  {
    chainId: 1,
    address: '0x69cf3091c91eb72db05e45c76e58225177dea742',
    name: 'CoinZoom Token',
    symbol: 'ZOOM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15060/thumb/Coinzoom.png?1619590652'
  },
  {
    chainId: 1,
    address: '0x2f6081e3552b1c86ce4479b80062a1dda8ef23e3',
    name: 'Dollars',
    symbol: 'USDX',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/12377/thumb/rCdP56C.png?1599445426'
  },
  {
    chainId: 1,
    address: '0x018d7d179350f1bb9853d04982820e37cce13a92',
    name: 'InMax',
    symbol: 'INX',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/6826/thumb/gDALsvq.png?1555895815'
  },
  {
    chainId: 1,
    address: '0x8806926ab68eb5a7b909dcaf6fdbe5d93271d6e2',
    name: 'Uquid Coin',
    symbol: 'UQC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1341/thumb/uquid-coin.png?1548759712'
  },
  {
    chainId: 1,
    address: '0xf063fe1ab7a291c5d06a86e14730b00bf24cb589',
    name: 'DxSale Network',
    symbol: 'SALE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12250/thumb/dx-light.png?1613965390'
  },
  {
    chainId: 1,
    address: '0x892f5a0b08bb7b1eecccc63ef3916ff201c93664',
    name: 'Bloody Token',
    symbol: 'BLOODY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12969/thumb/bloody-200px.png?1603940552'
  },
  {
    chainId: 1,
    address: '0x920db6c38cf5a2a12554e812d4b3ac2daa8eba4d',
    name: 'Evimeria',
    symbol: 'EVI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6086/thumb/evimeria.png?1548125758'
  },
  {
    chainId: 1,
    address: '0xb64cd4f56043f8d80691433e395d08b1bebdadf0',
    name: 'Meliora',
    symbol: 'MORA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14553/thumb/3vcbfVWS_400x400.png?1616992649'
  },
  {
    chainId: 1,
    address: '0x64a60493d888728cf42616e034a0dfeae38efcf0',
    name: 'OneLedger',
    symbol: 'OLT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3422/thumb/One_Ledger.jpeg?1547038108'
  },
  {
    chainId: 1,
    address: '0xc07a150ecadf2cc352f5586396e344a6b17625eb',
    name: 'Bio Passport',
    symbol: 'BIOT',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14167/thumb/logo_%2895%29.png?1614753428'
  },
  {
    chainId: 1,
    address: '0x32a18b15985a290604dd9b2ebc39a1035b1a6b9c',
    name: 'YFST Protocol',
    symbol: 'YFST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13600/thumb/20210107_214231.png?1610062202'
  },
  {
    chainId: 1,
    address: '0xa0bb0027c28ade4ac628b7f81e7b93ec71b4e020',
    name: 'Rug Proof',
    symbol: 'RPT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13896/thumb/1cu6Bea.png?1612674739'
  },
  {
    chainId: 1,
    address: '0x3fb8d8a28aff053ccf446bc075eecb7a0ef65d0c',
    name: 'StarPlay',
    symbol: 'STPC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9705/thumb/8fp5wsxp_400x400.png?1570920359'
  },
  {
    chainId: 1,
    address: '0x9f58702ef19ebeb76363884362439a8691e3f033',
    name: 'HELIO POWER TOKEN',
    symbol: 'THPT',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/11948/thumb/thpt.png?1601347239'
  },
  {
    chainId: 1,
    address: '0xb0bfb1e2f72511cf8b4d004852e2054d7b9a76e1',
    name: 'Streamix',
    symbol: 'MIXS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13275/thumb/stream.png?1606893790'
  },
  {
    chainId: 1,
    address: '0xe7e4279b80d319ede2889855135a22021baf0907',
    name: 'ZeusNetwork',
    symbol: 'ZEUS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6001/thumb/logo2.png?1547041947'
  },
  {
    chainId: 1,
    address: '0x8b40761142b9aa6dc8964e61d0585995425c3d94',
    name: 'Tripio',
    symbol: 'TRIO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4648/thumb/tripio-logo.jpg?1547039914'
  },
  {
    chainId: 1,
    address: '0xa9fbb83a2689f4ff86339a4b96874d718673b627',
    name: 'FireAnts',
    symbol: 'ANTS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11179/thumb/ants200.png?1589510693'
  },
  {
    chainId: 1,
    address: '0x660e71483785f66133548b10f6926dc332b06e61',
    name: 'Adelphoi',
    symbol: 'ADL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1246/thumb/adelphoi-logo.png?1547035271'
  },
  {
    chainId: 1,
    address: '0x38d58b82cb24a3e0410a7991f255174c9fd8093b',
    name: 'TEAL',
    symbol: 'TEAT',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/13062/thumb/teat_logo.png?1604845769'
  },
  {
    chainId: 1,
    address: '0x2f9b6779c37df5707249eeb3734bbfc94763fbe2',
    name: 'CrowdWiz',
    symbol: 'WIZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1983/thumb/crowdwiz.png?1547740403'
  },
  {
    chainId: 1,
    address: '0x9f978aa425148cdd9223eb175446a877b86727ff',
    name: 'PayYoda',
    symbol: 'YOT',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/13632/thumb/w_72D81R_400x400.png?1610492214'
  },
  {
    chainId: 1,
    address: '0x3d3af44cf092a49280e316f09c8f20ecf97bc933',
    name: 'UCX',
    symbol: 'UCX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10131/thumb/UCX_200200.png?1576186720'
  },
  {
    chainId: 1,
    address: '0x46a97629c9c1f58de6ec18c7f536e7e6d6a6ecde',
    name: 'iDOT',
    symbol: 'IDOT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15733/thumb/idot.JPG?1621671898'
  },
  {
    chainId: 1,
    address: '0x5299d6f7472dcc137d7f3c4bcfbbb514babf341a',
    name: 'sXMR',
    symbol: 'SXMR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11625/thumb/sXMR.png?1616150880'
  },
  {
    chainId: 1,
    address: '0xb0b1685f55843d03739c7d9b0a230f1b7dcf03d5',
    name: 'Lynchpin Token',
    symbol: 'LYN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7090/thumb/logo_%2899%29.png?1607810177'
  },
  {
    chainId: 1,
    address: '0xa6fa6531acdf1f9f96eddd66a0f9481e35c2e42a',
    name: 'CryptoBRL',
    symbol: 'CBRL',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/12321/thumb/CBRL_logo_200x200.png?1599094233'
  },
  {
    chainId: 1,
    address: '0xf45091f25d374bbe956c0bb64bb85e02d07aa741',
    name: 'MNMCoin',
    symbol: 'MNMC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11163/thumb/MNMC.png?1589280919'
  },
  {
    chainId: 1,
    address: '0x179e31fb25e433441a2839389a7b8ec9c4654b7b',
    name: 'SynchroBitcoin',
    symbol: 'SNB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10727/thumb/Logosnb.png?1582667361'
  },
  {
    chainId: 1,
    address: '0x488e0369f9bc5c40c002ea7c1fe4fd01a198801c',
    name: 'Golff',
    symbol: 'GOF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12445/thumb/_x-AmLBv_400x400.jpg?1599902833'
  },
  {
    chainId: 1,
    address: '0x299948bc2ca54a5e814b19849327a6d9a0e7de1b',
    name: 'Glyph Token',
    symbol: 'GLY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14752/thumb/logo_-_2021-04-12T064510.489.png?1618181135'
  },
  {
    chainId: 1,
    address: '0x24ea9c1cfd77a8db3fb707f967309cf013cc1078',
    name: 'Excavo Finance',
    symbol: 'CAVO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13711/thumb/CAVO.png?1611915659'
  },
  {
    chainId: 1,
    address: '0x4a527d8fc13c5203ab24ba0944f4cb14658d1db6',
    name: 'Morpheus Labs',
    symbol: 'MITX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3164/thumb/mitx.png?1604888269'
  },
  {
    chainId: 1,
    address: '0xd44bb6663936cab1310584a277f7daa6943d4904',
    name: 'Winco',
    symbol: 'WCO',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/3628/thumb/Webp.net-resizeimage_%281%29.png?1547038550'
  },
  {
    chainId: 1,
    address: '0xd379700999f4805ce80aa32db46a94df64561108',
    name: 'Dextrust',
    symbol: 'DETS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12235/thumb/logo_dark.png?1598425651'
  },
  {
    chainId: 1,
    address: '0x167e2a574669b0eeb552aaf3da47c728cb348a41',
    name: 'Spartan',
    symbol: '300',
    decimals: 7,
    logoURI: 'https://assets.coingecko.com/coins/images/13371/thumb/spartan300-200x200.png?1607986424'
  },
  {
    chainId: 1,
    address: '0x054bd236b42385c938357112f419dc5943687886',
    name: 'Heavens Gate',
    symbol: 'HATE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12740/thumb/BUQoiaJY_400x400.png?1602630549'
  },
  {
    chainId: 1,
    address: '0x1efb2286bf89f01488c6b2a22b2556c0f45e972b',
    name: 'Moon YFI',
    symbol: 'MYFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12506/thumb/moonyfi_logo.jpg?1600316838'
  },
  {
    chainId: 1,
    address: '0xe531642e9bb5d027e9c20e03284287b97919a9a5',
    name: 'FaithCoin',
    symbol: 'FAITH',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/3648/thumb/Y4u0Cl8.png?1547038612'
  },
  {
    chainId: 1,
    address: '0x3ffffa8f3cc943e43f9f17a83cbb18f4bbb9f4ac',
    name: 'Rewardiqa',
    symbol: 'REW',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11614/thumb/REW_medium.png?1594698840'
  },
  {
    chainId: 1,
    address: '0x03066da434e5264ef0b32f787923f974a5726fdc',
    name: 'Basis Coin Share',
    symbol: 'BCS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13530/thumb/Basiscoin_Share.png?1609406623'
  },
  {
    chainId: 1,
    address: '0x1234567461d3f8db7496581774bd869c83d51c93',
    name: 'BitClave',
    symbol: 'CAT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1585/thumb/bitclave.png?1547035768'
  },
  {
    chainId: 1,
    address: '0xbc46d9961a3932f7d6b64abfdec80c1816c4b835',
    name: 'LITEX',
    symbol: 'LXT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5133/thumb/litex-logo.png?1547040525'
  },
  {
    chainId: 1,
    address: '0xd1afbccc9a2c2187ea544363b986ea0ab6ef08b5',
    name: 'Ethereum Yield',
    symbol: 'ETHY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13191/thumb/rOIuPZM.png?1606101103'
  },
  {
    chainId: 1,
    address: '0x1961b3331969ed52770751fc718ef530838b6dee',
    name: 'BitDegree',
    symbol: 'BDG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1653/thumb/bitdegree.jpg?1547035900'
  },
  {
    chainId: 1,
    address: '0xa883e72c12473ded50a5fbffa60e4000fa5fe3c8',
    name: 'LOAD Network',
    symbol: 'LOAD',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13270/thumb/load_network_logo.png?1606880512'
  },
  {
    chainId: 1,
    address: '0x8971f9fd7196e5cee2c1032b50f656855af7dd26',
    name: 'Lambda',
    symbol: 'LAMB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4703/thumb/Lambda-Logo.png?1547219112'
  },
  {
    chainId: 1,
    address: '0x635d081fd8f6670135d8a3640e2cf78220787d56',
    name: 'Add xyz  NEW ',
    symbol: 'ADD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14351/thumb/Group_618.png?1616556776'
  },
  {
    chainId: 1,
    address: '0xcbc1065255cbc3ab41a6868c22d1f1c573ab89fd',
    name: 'Cream ETH 2',
    symbol: 'CRETH2',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13568/thumb/creth2.png?1609837099'
  },
  {
    chainId: 1,
    address: '0x331a4589516eae384ea5f557853af6af73b9534e',
    name: 'Token CashPay',
    symbol: 'TCP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11616/thumb/559059354_256256.png?1591932551'
  },
  {
    chainId: 1,
    address: '0xcb3df3108635932d912632ef7132d03ecfc39080',
    name: 'Wing Shop',
    symbol: 'WING',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12318/thumb/6584.png?1599087626'
  },
  {
    chainId: 1,
    address: '0x2bdc0d42996017fce214b21607a515da41a9e0c5',
    name: 'SkinCoin',
    symbol: 'SKIN',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/830/thumb/skincoin.png?1547034622'
  },
  {
    chainId: 1,
    address: '0xbcc66ed2ab491e9ae7bf8386541fb17421fa9d35',
    name: 'Skull',
    symbol: 'SKULL',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/10641/thumb/skull.png?1581339740'
  },
  {
    chainId: 1,
    address: '0xa823e6722006afe99e91c30ff5295052fe6b8e32',
    name: 'Neumark',
    symbol: 'NEU',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2218/thumb/neumark.png?1547036501'
  },
  {
    chainId: 1,
    address: '0xa918897bd10d6dee614470c24a061b78b021b3a9',
    name: 'Universal Coin',
    symbol: 'UCOIN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13650/thumb/UCOIN_LOGO-min.png?1610549326'
  },
  {
    chainId: 1,
    address: '0x4954db6391f4feb5468b6b943d4935353596aec9',
    name: 'USDQ',
    symbol: 'USDQ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8300/thumb/favicon-256x256.png?1557315995'
  },
  {
    chainId: 1,
    address: '0xd947b0ceab2a8885866b9a04a06ae99de852a3d4',
    name: 'Trade Token X',
    symbol: 'TIOX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1542/thumb/trade-token-x128.png?1547035698'
  },
  {
    chainId: 1,
    address: '0xd9a1fe8b4d6de4f41c8a1b6ad3b6457916e546b4',
    name: 'Forte Coin',
    symbol: 'FOTC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5841/thumb/fortecoin-logo.jpg?1547041723'
  },
  {
    chainId: 1,
    address: '0x6595b8fd9c920c81500dca94e53cdc712513fb1f',
    name: 'Olyseum',
    symbol: 'OLY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13983/thumb/oly-logo.png?1613461530'
  },
  {
    chainId: 1,
    address: '0x348b7f3106b5da47405332534d06069ff9ce4d1b',
    name: 'Elongate Deluxe',
    symbol: 'ELONGD',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15487/thumb/elongd.PNG?1621039432'
  },
  {
    chainId: 1,
    address: '0x340ef83ec8560892168d4062720f030460468656',
    name: 'Ethereum Meta',
    symbol: 'ETHM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6586/thumb/ethereum-meta.png?1548125409'
  },
  {
    chainId: 1,
    address: '0x737f98ac8ca59f2c68ad658e3c3d8c8963e40a4c',
    name: 'Amon',
    symbol: 'AMN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2369/thumb/amon.png?1547036554'
  },
  {
    chainId: 1,
    address: '0x9cb1aeafcc8a9406632c5b084246ea72f62d37b6',
    name: 'LBK',
    symbol: 'LBK',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9492/thumb/lbk.jpeg?1586144855'
  },
  {
    chainId: 1,
    address: '0x239119c43e3cac84c8a2d45bcba0e46f528e5f77',
    name: 'Dripper',
    symbol: 'DRIP',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13468/thumb/drip-stretch-200.png?1608796810'
  },
  {
    chainId: 1,
    address: '0xe6b75a1960f91bfa7010dec8543685ead67f8cff',
    name: 'Sea Cucumber Chain',
    symbol: 'SCC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8689/thumb/1553565844735_mid.png?1560234469'
  },
  {
    chainId: 1,
    address: '0x197e6bca6bc2f488ec760a6ce46b1399cd2954b0',
    name: 'BitCloud Pro',
    symbol: 'BPRO',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/8413/thumb/V86TNt3F_400x400.jpg?1560421281'
  },
  {
    chainId: 1,
    address: '0xa30c7cdac7d8505f32bb0930ed82b9ba5777b5f3',
    name: 'NUCLUM',
    symbol: 'NLM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11037/thumb/NLM.png?1587395035'
  },
  {
    chainId: 1,
    address: '0x9205c049c231dda51bace0ba569f047e3e1e9979',
    name: 'Latamcash',
    symbol: 'LMCH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10999/thumb/a2RfSSOz_400x400.jpg?1587027551'
  },
  {
    chainId: 1,
    address: '0x14c38e90a593b0bd5b7e9896a8ef4ae0a119d6ae',
    name: 'WAV3',
    symbol: 'WAV3',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13321/thumb/wav3_logo.jpeg?1607426650'
  },
  {
    chainId: 1,
    address: '0xbf2179859fc6d5bee9bf9158632dc51678a4100e',
    name: 'aelf',
    symbol: 'ELF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1371/thumb/aelf-logo.png?1547035397'
  },
  {
    chainId: 1,
    address: '0x06a6fc23e6ec8a2b2aeeefd70d772dc3d6b45010',
    name: 'CryptoProfile',
    symbol: 'CP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7350/thumb/CryptoProfile-logo.png?1547044024'
  },
  {
    chainId: 1,
    address: '0x0f4c00139602ab502bc7c1c0e71d6cb72a9fb0e7',
    name: 'dHEDGE Top Index',
    symbol: 'DTOP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14792/thumb/dtop.jpg?1618469418'
  },
  {
    chainId: 1,
    address: '0xa3d58c4e56fedcae3a7c43a725aee9a71f0ece4e',
    name: 'Metronome',
    symbol: 'MET',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3249/thumb/metronome.png?1548084800'
  },
  {
    chainId: 1,
    address: '0xd714d91a169127e11d8fab3665d72e8b7ef9dbe2',
    name: 'BlackHole Protocol',
    symbol: 'BLACK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15124/thumb/blackhole.PNG?1620094580'
  },
  {
    chainId: 1,
    address: '0xc57d533c50bc22247d49a368880fb49a1caa39f7',
    name: 'PowerTrade Fuel',
    symbol: 'PTF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12590/thumb/powertrade_logo.jpg?1600944549'
  },
  {
    chainId: 1,
    address: '0x1245ef80f4d9e02ed9425375e8f649b9221b31d8',
    name: 'ArbitrageCT',
    symbol: 'ARCT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/2537/thumb/arbitragect.png?1547036651'
  },
  {
    chainId: 1,
    address: '0xf1a355cc5953a5c04130f221b6ccad13c3f82990',
    name: 'Gold And Gold',
    symbol: 'GNG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12241/thumb/logo_%2892%29.png?1598443162'
  },
  {
    chainId: 1,
    address: '0x6aba1623ea906d1164cbb007e764ebde2514a2ba',
    name: 'AAAchain',
    symbol: 'AAA',
    decimals: 10,
    logoURI: 'https://assets.coingecko.com/coins/images/6253/thumb/TVq6wtGj_400x400.jpg?1547042289'
  },
  {
    chainId: 1,
    address: '0xd61b60ccbdaf09c2e036c72734adb3270ed27192',
    name: 'WaterDrop',
    symbol: 'WDP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13185/thumb/QhKzNey.png?1606084418'
  },
  {
    chainId: 1,
    address: '0xe99a894a69d7c2e3c92e61b64c505a6a57d2bc07',
    name: 'Hyperion',
    symbol: 'HYN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7171/thumb/Hyperion.png?1558069078'
  },
  {
    chainId: 1,
    address: '0xf07376f0e3bff57116ed62e5e8e2bb1341e4b4c2',
    name: 'JoorsChain',
    symbol: 'JIC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7128/thumb/H0kIwyNs_400x400.jpg?1547043626'
  },
  {
    chainId: 1,
    address: '0xba069ee53b8b531f3ab117c92ca09a204c9e6285',
    name: 'Plug',
    symbol: 'PLG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7124/thumb/zdj7lS36_400x400.jpg?1547043622'
  },
  {
    chainId: 1,
    address: '0xd6c67b93a7b248df608a653d82a100556144c5da',
    name: 'ExNetwork Token',
    symbol: 'EXNT',
    decimals: 16,
    logoURI: 'https://assets.coingecko.com/coins/images/12328/thumb/exnt_logo.png?1599102916'
  },
  {
    chainId: 1,
    address: '0x5b535edfa75d7cb706044da0171204e1c48d00e8',
    name: '808TA Token',
    symbol: '808TA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9120/thumb/eKmFtFle_400x400.png?1564473061'
  },
  {
    chainId: 1,
    address: '0x7a545ed3863221a974f327199ac22f7f12535f11',
    name: 'Baguette Token',
    symbol: 'BGTT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12456/thumb/baguette_logo.png?1599945036'
  },
  {
    chainId: 1,
    address: '0xe8663a64a96169ff4d95b4299e7ae9a76b905b31',
    name: 'DPRating',
    symbol: 'RATING',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/5383/thumb/dprating.png?1547224023'
  },
  {
    chainId: 1,
    address: '0x3c4bea627039f0b7e7d21e34bb9c9fe962977518',
    name: 'Ubique Chain of Thi',
    symbol: 'UCT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4277/thumb/Webp.net-resizeimage_%2829%29.jpg?1547039634'
  },
  {
    chainId: 1,
    address: '0x5979f50f1d4c08f9a53863c2f39a7b0492c38d0f',
    name: 'pTokens LTC',
    symbol: 'PLTC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12436/thumb/pLTC_logo.png?1599819176'
  },
  {
    chainId: 1,
    address: '0x10bae51262490b4f4af41e12ed52a0e744c1137a',
    name: 'Soft Link',
    symbol: 'SLINK',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/12192/thumb/LogoSLINK.png?1597983753'
  },
  {
    chainId: 1,
    address: '0x4b1e80cac91e2216eeb63e29b957eb91ae9c2be8',
    name: 'Jupiter',
    symbol: 'JUP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10351/thumb/Jupiter-Logo-Def-No-Stroke-256px.png?1613529417'
  },
  {
    chainId: 1,
    address: '0xf6ed276a69270a895d6e419d99dcb5aaa2f3cb4a',
    name: 'SwapAll',
    symbol: 'SAP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13014/thumb/fav.png?1604383601'
  },
  {
    chainId: 1,
    address: '0xbf4a2ddaa16148a9d0fa2093ffac450adb7cd4aa',
    name: 'Ethereum Money',
    symbol: 'ETHMNY',
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/9025/thumb/20200605_131214.png?1597297671'
  },
  {
    chainId: 1,
    address: '0x554ffc77f4251a9fb3c0e3590a6a205f8d4e067d',
    name: 'ZMINE',
    symbol: 'ZMN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2103/thumb/zmn.png?1547036420'
  },
  {
    chainId: 1,
    address: '0x74faab6986560fd1140508e4266d8a7b87274ffd',
    name: 'HyperDAO',
    symbol: 'HDAO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10780/thumb/B7-ppPfE_400x400.png?1583467291'
  },
  {
    chainId: 1,
    address: '0x297d33e17e61c2ddd812389c2105193f8348188a',
    name: 'Strudel Finance',
    symbol: 'TRDL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13854/thumb/1614634281474-TRDL_LOGO_PNG.png?1614671874'
  },
  {
    chainId: 1,
    address: '0x4a16baf414b8e637ed12019fad5dd705735db2e0',
    name: 'QCAD',
    symbol: 'QCAD',
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/12869/thumb/qcad-logo2.a42bce89.png?1603174584'
  },
  {
    chainId: 1,
    address: '0x7b3d36eb606f873a75a6ab68f8c999848b04f935',
    name: 'NFTLootBox',
    symbol: 'LOOT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13414/thumb/nftlootbox.png?1608280539'
  },
  {
    chainId: 1,
    address: '0xb5dbc6d3cf380079df3b27135664b6bcf45d1869',
    name: 'Project SHIVOM',
    symbol: 'OMX',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/3167/thumb/omx.png?1547037607'
  },
  {
    chainId: 1,
    address: '0x8810c63470d38639954c6b41aac545848c46484a',
    name: 'Aditus',
    symbol: 'ADI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1867/thumb/adi.png?1514446309'
  },
  {
    chainId: 1,
    address: '0xba1ed22c69ad00739ee2b4abd70e270be9e87ee2',
    name: 'WebFlix',
    symbol: 'WFX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8559/thumb/dr4OEbWK_400x400.jpg?1559199557'
  },
  {
    chainId: 1,
    address: '0x821144518dfe9e7b44fcf4d0824e15e8390d4637',
    name: 'Atlantis Token',
    symbol: 'ATIS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12112/thumb/atis_token_logo.png?1600097654'
  },
  {
    chainId: 1,
    address: '0xf83301c5cd1ccbb86f466a6b3c53316ed2f8465a',
    name: 'COMSA',
    symbol: 'CMS',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/2500/thumb/comsa-_xem_.png?1547036614'
  },
  {
    chainId: 1,
    address: '0x1efdfc6146cad8909817284ae99325ef1caf623e',
    name: 'AurusDeFi',
    symbol: 'AWX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12684/thumb/AWX-256x256.png?1612840521'
  },
  {
    chainId: 1,
    address: '0x66186008c1050627f979d464eabb258860563dbe',
    name: 'MediShares',
    symbol: 'MDS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1343/thumb/medishares.png?1547978625'
  },
  {
    chainId: 1,
    address: '0x69af81e73a73b40adf4f3d4223cd9b1ece623074',
    name: 'Mask Network',
    symbol: 'MASK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14051/thumb/Mask_Network.jpg?1614050316'
  },
  {
    chainId: 1,
    address: '0xdfbc9050f5b01df53512dcc39b4f2b2bbacd517a',
    name: 'Jobchain',
    symbol: 'JOB',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9352/thumb/Logo_%283%29.png?1566510871'
  },
  {
    chainId: 1,
    address: '0x54e8371c1ec43e58fb53d4ef4ed463c17ba8a6be',
    name: 'ETH 26 EMA Crossove',
    symbol: 'ETHEMAAPY',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/11649/thumb/eth-26-day-exponential-moving-average-set-ctoken.png?1592324217'
  },
  {
    chainId: 1,
    address: '0x94d916873b22c9c1b53695f1c002f78537b9b3b2',
    name: 'AlgoVest',
    symbol: 'AVS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13874/thumb/AVS.png?1612449709'
  },
  {
    chainId: 1,
    address: '0xb97048628db6b661d4c2aa833e95dbe1a905b280',
    name: 'TenX',
    symbol: 'PAY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/775/thumb/TenX-Icon-CircleBlack.png?1553766360'
  },
  {
    chainId: 1,
    address: '0x8716fc5da009d3a208f0178b637a50f4ef42400f',
    name: 'Ultrain',
    symbol: 'UGAS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4684/thumb/ultrain.png?1547039956'
  },
  {
    chainId: 1,
    address: '0xee0f286776639cd363da810daf3e0623f82576b0',
    name: 'Lung Protocol',
    symbol: 'L2P',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11799/thumb/64280570.png?1594359841'
  },
  {
    chainId: 1,
    address: '0x01e0e2e61f554ecaaec0cc933e739ad90f24a86d',
    name: 'Graviton',
    symbol: 'GTON',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15728/thumb/79590209.png?1621637194'
  },
  {
    chainId: 1,
    address: '0x831091da075665168e01898c6dac004a867f1e1b',
    name: 'Gains V2',
    symbol: 'GFARM2',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13703/thumb/gfarm_v2.png?1611035398'
  },
  {
    chainId: 1,
    address: '0xa30189d8255322a2f8b2a77906b000aeb005570c',
    name: 'Buy Sell',
    symbol: 'BSE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13368/thumb/buy_sell_logo.png?1607927143'
  },
  {
    chainId: 1,
    address: '0x171d750d42d661b62c277a6b486adb82348c3eca',
    name: 'Omnitude',
    symbol: 'ECOM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3223/thumb/omnitude-ico-logo.png?1547037716'
  },
  {
    chainId: 1,
    address: '0x3383c5a8969dc413bfddc9656eb80a1408e4ba20',
    name: 'Wrapped ANATHA',
    symbol: 'WANATHA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12690/thumb/CrZ8h9FV_400x400.png?1601678935'
  },
  {
    chainId: 1,
    address: '0x83ad87c988ac0c6277c0c6234cc8108b20bb5d9b',
    name: '3X Long Chainlink T',
    symbol: 'LINKBULL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10235/thumb/683JEXMN_400x400_%281%29.png?1576632547'
  },
  {
    chainId: 1,
    address: '0x54c9ea2e9c9e8ed865db4a4ce6711c2a0d5063ba',
    name: 'BarterTrade',
    symbol: 'BART',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12142/thumb/200x200-transparent.png?1606958206'
  },
  {
    chainId: 1,
    address: '0xd31533e8d0f3df62060e94b3f1318137bb6e3525',
    name: 'sREN',
    symbol: 'SREN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15731/thumb/sREN_final.JPG?1621670563'
  },
  {
    chainId: 1,
    address: '0x0ecdd783dc7bf820614044b51862ed29714d2ba5',
    name: 'Medooza Ecosystem',
    symbol: 'MDZA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13448/thumb/medooza-red-symbol-logo-exchange.png?1608681531'
  },
  {
    chainId: 1,
    address: '0xdfe691f37b6264a90ff507eb359c45d55037951c',
    name: 'Karma DAO',
    symbol: 'KARMA',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/11884/thumb/Karma.png?1597042574'
  },
  {
    chainId: 1,
    address: '0x3c6a7ab47b5f058be0e7c7fe1a4b7925b8aca40e',
    name: 'Cajutel',
    symbol: 'CAJ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7354/thumb/RuHhMsFd_400x400.png?1547044031'
  },
  {
    chainId: 1,
    address: '0x63bf0126c6c4d17bb33c362151759ec21b36537b',
    name: 'Master Coin Point',
    symbol: 'MACPO',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/11711/thumb/resize_62d4c36938d74064b9e76eb99c729e9e.png?1593063371'
  },
  {
    chainId: 1,
    address: '0x87edffde3e14c7a66c9b9724747a1c5696b742e6',
    name: 'SWAG Finance',
    symbol: 'SWAG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12805/thumb/photo_2020-10-14_23.17.02.jpeg?1602688642'
  },
  {
    chainId: 1,
    address: '0x3af5ba94c29a8407785f5f6d90ef5d69a8eb2436',
    name: 'Unagii Wrapped Bitc',
    symbol: 'UWBTC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/14625/thumb/uBTC.png?1617294190'
  },
  {
    chainId: 1,
    address: '0x40395044ac3c0c57051906da938b54bd6557f212',
    name: 'MobileGo',
    symbol: 'MGO',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/768/thumb/mobilego.png?1548085237'
  },
  {
    chainId: 1,
    address: '0xba4cfe5741b357fa371b506e5db0774abfecf8fc',
    name: 'vVSP',
    symbol: 'VVSP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15101/thumb/vvsp.jpeg?1619693189'
  },
  {
    chainId: 1,
    address: '0x25c7b64a93eb1261e130ec21a3e9918caa38b611',
    name: 'Wrapped Virgin Gen ',
    symbol: 'WVG0',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12875/thumb/wvg0.png?1603211534'
  },
  {
    chainId: 1,
    address: '0xcd62b1c403fa761baadfc74c525ce2b51780b184',
    name: 'Aragon Court',
    symbol: 'ANJ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10765/thumb/ANJ.png?1588956187'
  },
  {
    chainId: 1,
    address: '0x287609a15a683640a5bbc4d93d4d5f4ed6bad3a0',
    name: 'PICK',
    symbol: 'PICK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12243/thumb/nZlFKAmk_400x400.jpg?1598444299'
  },
  {
    chainId: 1,
    address: '0x59c033ec65e6b9c501c1ee34fb42f2575da4b517',
    name: 'Betherchip',
    symbol: 'BEC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12447/thumb/icone-bth-preta.jpg?1599903494'
  },
  {
    chainId: 1,
    address: '0x0a9f693fce6f00a51a8e0db4351b5a8078b4242e',
    name: 'Resfinex Token',
    symbol: 'RES',
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/10026/thumb/logo_%281%29.png?1588935633'
  },
  {
    chainId: 1,
    address: '0xd6014ea05bde904448b743833ddf07c3c7837481',
    name: 'iBTC',
    symbol: 'IBTC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8853/thumb/iBTC.png?1616151124'
  },
  {
    chainId: 1,
    address: '0x11003e410ca3fcd220765b3d2f343433a0b2bffd',
    name: 'Farming Bad',
    symbol: 'METH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13933/thumb/meth.png?1612941488'
  },
  {
    chainId: 1,
    address: '0x646cec6ee42d258336165cbbd5deb4af14f0f476',
    name: 'Solar DAO',
    symbol: 'SDAO',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/1625/thumb/solar-dao.jpg?1547035842'
  },
  {
    chainId: 1,
    address: '0xd0f05d3d4e4d1243ac826d8c6171180c58eaa9bc',
    name: 'Value Network Token',
    symbol: 'VNTW',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14726/thumb/vntw.PNG?1617951429'
  },
  {
    chainId: 1,
    address: '0xbe685c5e06866cfb94a4242e3df8f2fa3e7c2b73',
    name: 'Yearn Finance Red M',
    symbol: 'YFRM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12947/thumb/200X200.png?1603778631'
  },
  {
    chainId: 1,
    address: '0x2494a68c1484376fef880b4c24d91f049d29b02a',
    name: 'The Transfer Token',
    symbol: 'TTT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11086/thumb/4z9EtDd.png?1588125114'
  },
  {
    chainId: 1,
    address: '0x0ada190c81b814548ddc2f6adc4a689ce7c1fe73',
    name: 'yAxis',
    symbol: 'YAXIS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12620/thumb/Logo.png?1608310944'
  },
  {
    chainId: 1,
    address: '0x2a69655c22eda32ff48d315bb26ed45f150700b4',
    name: 'Wrapped Tezos',
    symbol: 'WXTZ',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/14763/thumb/wXTZ-token-FullColor.png?1618281765'
  },
  {
    chainId: 1,
    address: '0xf576ff0d7e4c1e8f27dbd50321e95e36a965985f',
    name: 'Apiary Fund Coin',
    symbol: 'AFC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12878/thumb/Untitled-design_%281%29.png?1603248485'
  },
  {
    chainId: 1,
    address: '0x2dbd330bc9b7f3a822a9173ab52172bdddcace2a',
    name: 'YFED Finance',
    symbol: 'YFED',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12736/thumb/yfed_logo.png?1602123697'
  },
  {
    chainId: 1,
    address: '0xb6ed7644c69416d67b522e20bc294a9a9b405b31',
    name: '0xBitcoin',
    symbol: '0XBTC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/4454/thumb/0xbtc.png?1561603765'
  },
  {
    chainId: 1,
    address: '0x106538cc16f938776c7c180186975bca23875287',
    name: 'Basis Share',
    symbol: 'BAS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13251/thumb/BAS.png?1613231139'
  },
  {
    chainId: 1,
    address: '0xccf4429db6322d5c611ee964527d42e5d685dd6a',
    name: 'cWBTC',
    symbol: 'CWBTC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10823/thumb/cwbtc.png?1584331700'
  },
  {
    chainId: 1,
    address: '0xf21661d0d1d76d3ecb8e1b9f1c923dbfffae4097',
    name: 'Realio Network',
    symbol: 'RIO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12206/thumb/0.jpg?1598083003'
  },
  {
    chainId: 1,
    address: '0x41efc0253ee7ea44400abb5f907fdbfdebc82bec',
    name: ' AAPL',
    symbol: 'AAPL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12367/thumb/oF1_9R1K_400x400.jpg?1599345463'
  },
  {
    chainId: 1,
    address: '0x1a23a6bfbadb59fa563008c0fb7cf96dfcf34ea1',
    name: 'CoFiX',
    symbol: 'COFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12781/thumb/dnPnSkfa_400x400.png?1602885321'
  },
  {
    chainId: 1,
    address: '0x56be94d29e1125d2d61d06629c1b251d72c1b3b3',
    name: 'Hustle Token',
    symbol: 'HUSL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10680/thumb/hlhfVHeb_400x400.jpg?1581804351'
  },
  {
    chainId: 1,
    address: '0x50ae0bc7e167c96b46f58063b2a7a27a26ad63bc',
    name: 'DEONEX Token',
    symbol: 'DON',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12236/thumb/deologo-200x200.png?1598436143'
  },
  {
    chainId: 1,
    address: '0x6b4689e4514957699edeb2ee91c947f18e439806',
    name: 'ZeuxCoin',
    symbol: 'ZUC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9034/thumb/HVMZgdBY_400x400.jpg?1563831075'
  },
  {
    chainId: 1,
    address: '0x261638ec8ee8100484130ebd2febfdadc0d8742a',
    name: 'LVX',
    symbol: 'LVX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5269/thumb/New_Level01_Logo_200px.png?1600751532'
  },
  {
    chainId: 1,
    address: '0xdf0041891bda1f911c4243f328f7cf61b37f965b',
    name: 'BOOSTO',
    symbol: 'BST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3448/thumb/Untitled2222.png?1547038159'
  },
  {
    chainId: 1,
    address: '0xcfbf70e33d5163e25b0dad73955c1bd9e8cd8ba2',
    name: 'WinStars Live',
    symbol: 'WNL',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/4178/thumb/HEq48ZDOCvVTVFw8J8qws9les1E27b_8F_k34sVhpwULn3muT66wiQI9HjNNUhGLELkGGSi0daPgxt8YHjbRqHzX1uurAbySQdtr_FW0GRBNN3CEWGU03Vn_z9NdJiGHV193Y_RWPDAGlm69ISIMSdTxT34VycLZTMrgJeWaNXrblBrXpPprJXKDJCKsa_Wt1jofCsygfNLdWQ0j_.jpg?1563768641'
  },
  {
    chainId: 1,
    address: '0x1600c2e08acb830f2a4ee4d34b48594dade48651',
    name: 'Turex',
    symbol: 'TUR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15539/thumb/turex-logo.png?1621155664'
  },
  {
    chainId: 1,
    address: '0x8c680d0de5a9a3f6a99649d0a000225054934742',
    name: 'Atlantic Finance',
    symbol: 'ATFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15256/thumb/atlantic.png?1620268887'
  },
  {
    chainId: 1,
    address: '0xaa99199d1e9644b588796f3215089878440d58e0',
    name: 'Alphr',
    symbol: 'ALPHR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15029/thumb/alphr.jpg?1619495712'
  },
  {
    chainId: 1,
    address: '0xfbbe9b1142c699512545f47937ee6fae0e4b0aa9',
    name: 'EDDASwap',
    symbol: 'EDDA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14368/thumb/edda.png?1615732928'
  },
  {
    chainId: 1,
    address: '0xc434b27736a6882d33094d34792999702860a13c',
    name: 'Savix',
    symbol: 'SVX',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14591/thumb/savix_200px.png?1617160141'
  },
  {
    chainId: 1,
    address: '0xb0a0a070640b450eb136dc377208469ee4f49fbc',
    name: 'Future1Coin',
    symbol: 'F1C',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3113/thumb/future1coin.png?1548126667'
  },
  {
    chainId: 1,
    address: '0xf058501585023d040ea9493134ed72c083553eed',
    name: 'Dymmax',
    symbol: 'DMX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13476/thumb/dmmx.png?1608934713'
  },
  {
    chainId: 1,
    address: '0x6d6506e6f438ede269877a0a720026559110b7d5',
    name: 'BONK Token',
    symbol: 'BONK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11942/thumb/2Updated_2x.png?1598511690'
  },
  {
    chainId: 1,
    address: '0x36b60a425b82483004487abc7adcb0002918fc56',
    name: 'TICOEX Token  Forme',
    symbol: 'TICO',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/7036/thumb/aCjvaXZu_400x400.jpg?1547043475'
  },
  {
    chainId: 1,
    address: '0xa8b61cff52564758a204f841e636265bebc8db9b',
    name: 'Yield Protocol',
    symbol: 'YIELD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14220/thumb/yield.png?1615030969'
  },
  {
    chainId: 1,
    address: '0x3832d2f059e55934220881f831be501d180671a7',
    name: 'renDOGE',
    symbol: 'RENDOGE',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13796/thumb/renDOGE.png?1611897869'
  },
  {
    chainId: 1,
    address: '0xd0345d30fd918d7682398acbcdf139c808998709',
    name: 'Lixir Finance',
    symbol: 'LIX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15333/thumb/lixir.PNG?1620596594'
  },
  {
    chainId: 1,
    address: '0x9b68bfae21df5a510931a262cecf63f41338f264',
    name: 'DecentBet',
    symbol: 'DBET',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1168/thumb/decent-bet.jpg?1547035195'
  },
  {
    chainId: 1,
    address: '0x358aa737e033f34df7c54306960a38d09aabd523',
    name: 'Ares Protocol',
    symbol: 'ARES',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15153/thumb/Ares-logo.png?1620638611'
  },
  {
    chainId: 1,
    address: '0xc11b1268c1a384e55c48c2391d8d480264a3a7f4',
    name: 'cWBTC  Legacy ',
    symbol: 'CWBTC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/15290/thumb/cwbtc.png?1620371929'
  },
  {
    chainId: 1,
    address: '0x9f7229af0c4b9740e207ea283b9094983f78ba04',
    name: 'Tadpole',
    symbol: 'TAD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13134/thumb/9DmF_cs3_400x400.jpg?1605574755'
  },
  {
    chainId: 1,
    address: '0xedf6568618a00c6f0908bf7758a16f76b6e04af9',
    name: 'Arianee',
    symbol: 'ARIA20',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5054/thumb/Aria_Logo_256.png?1610097866'
  },
  {
    chainId: 1,
    address: '0x6226caa1857afbc6dfb6ca66071eb241228031a1',
    name: 'LinkArt',
    symbol: 'LAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8892/thumb/pB4iXZbU_400x400.jpg?1562579001'
  },
  {
    chainId: 1,
    address: '0x2991341d28eaea277785d20e1d878d478c7ba4c7',
    name: 'FM Gallery',
    symbol: 'FMG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14527/thumb/FR44th_I_400x400.jpg?1616742157'
  },
  {
    chainId: 1,
    address: '0x8807e69dc04155af64172cd6f0b4738f8068d0d4',
    name: 'Meta Network',
    symbol: 'META',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14941/thumb/8f2584c2068a45c694665c5215320c47.png?1619083885'
  },
  {
    chainId: 1,
    address: '0xcc0014ccb39f6e86b1be0f17859a783b6722722f',
    name: 'Showcase Token',
    symbol: 'SHO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15084/thumb/icon_%284%29.png?1619662298'
  },
  {
    chainId: 1,
    address: '0xe0b7927c4af23765cb51314a0e0521a9645f0e2a',
    name: 'DigixDAO',
    symbol: 'DGD',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/397/thumb/dgd.png?1547034124'
  },
  {
    chainId: 1,
    address: '0x21cd6af01383651ed10554394c0497f9db5e5dbc',
    name: 'CryptoPing',
    symbol: 'PING',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/770/thumb/Icon-ping-coin-256x256.png?1614585429'
  },
  {
    chainId: 1,
    address: '0xa9ff725189fe00da9c5f27a580dc67fea61e3fb2',
    name: 'Armours',
    symbol: 'ARM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8319/thumb/8e-jHcvB_400x400.jpg?1557478705'
  },
  {
    chainId: 1,
    address: '0xc1215988f2e555ac324d1e2d2a6164f1010ff29f',
    name: 'Nyantereum Internat',
    symbol: 'NYANTE',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13090/thumb/Nyantereum.png?1615216234'
  },
  {
    chainId: 1,
    address: '0xedd7c94fd7b4971b916d15067bc454b9e1bad980',
    name: 'Zippie',
    symbol: 'ZIPT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4302/thumb/zippie.jpg?1547039665'
  },
  {
    chainId: 1,
    address: '0x31c63146a635eb7465e5853020b39713ac356991',
    name: 'Mirrored United Sta',
    symbol: 'MUSO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13640/thumb/mirror_logo_transparent.png?1611564829'
  },
  {
    chainId: 1,
    address: '0xe9541c7ea236332f4d07be73101670f39b27da02',
    name: 'Pureland Project',
    symbol: 'PLD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8686/thumb/logo_%2826%29.png?1560233087'
  },
  {
    chainId: 1,
    address: '0x817bbdbc3e8a1204f3691d14bb44992841e3db35',
    name: 'Cudos',
    symbol: 'CUDOS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13651/thumb/CudosIconTransparent.png?1610631426'
  },
  {
    chainId: 1,
    address: '0x89ee58af4871b474c30001982c3d7439c933c838',
    name: 'yfBeta',
    symbol: 'YFBETA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12325/thumb/yfbeta_logo.jpg?1599096612'
  },
  {
    chainId: 1,
    address: '0x0cae9e4d663793c2a2a0b211c1cf4bbca2b9caa7',
    name: 'Mirrored Amazon',
    symbol: 'MAMZN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13646/thumb/mirror_logo_transparent.png?1611565645'
  },
  {
    chainId: 1,
    address: '0x2fe39f22eac6d3c1c86dd9d143640ebb94609fce',
    name: 'JD Coin',
    symbol: 'JDC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9958/thumb/Cv-2ca6p_400x400.jpg?1575926305'
  },
  {
    chainId: 1,
    address: '0x20bcae16a8ba95d8e8363e265de4ecfc36ec5cd9',
    name: 'HEY BITCOIN',
    symbol: 'HYBN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11886/thumb/47880519.png?1595750258'
  },
  {
    chainId: 1,
    address: '0x8a65ab17324c155fac3e46ad33e9553d9165a252',
    name: 'Silver Coin',
    symbol: 'SCN',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10745/thumb/20200227-210551.png?1582836329'
  },
  {
    chainId: 1,
    address: '0xe4f726adc8e89c6a6017f01eada77865db22da14',
    name: 'PieDAO Balanced Cry',
    symbol: 'BCP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13560/thumb/BCP.png?1609813753'
  },
  {
    chainId: 1,
    address: '0xa2a19165c17b36a5c753a246c1e188489754a693',
    name: 'P2PGO',
    symbol: 'P2PG',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12653/thumb/logo200x200p2pg.png?1601435471'
  },
  {
    chainId: 1,
    address: '0x9d47894f8becb68b9cf3428d256311affe8b068b',
    name: 'Rope',
    symbol: 'ROPE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12567/thumb/Rope_Icon.jpg?1604038203'
  },
  {
    chainId: 1,
    address: '0x4d953cf077c0c95ba090226e59a18fcf97db44ec',
    name: 'Mini',
    symbol: 'MINI',
    decimals: 19,
    logoURI:
      'https://assets.coingecko.com/coins/images/12298/thumb/IrTAVc_GqZ7iQucAa3fNYlh_Cqt3tY9wM_pmzOl5SifscRMpuzrp_dizMzGTiMr_NxDJPCKigBgz8THrzvO_DqT3JLzqZIYeytDBRw3qKI73dljS0BnFaaI2aLadpdCZah4RkhydddLIDDbQlGit77farlQRaq7qEgxdjVe0aqEeh4phE-DWAKi_KS_Yz-fFdDfjWgifVCKkZRBeNSWWQEplxxl.jpg?1598961320'
  },
  {
    chainId: 1,
    address: '0x8d3e855f3f55109d473735ab76f753218400fe96',
    name: 'Bundles',
    symbol: 'BUND',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13219/thumb/bundles_finance_logo.jpg?1606294826'
  },
  {
    chainId: 1,
    address: '0x5d64d850c8368008afb39224e92ad0dceff3cf38',
    name: 'MINDOL',
    symbol: 'MIN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6366/thumb/mindol-logo.png?1547042468'
  },
  {
    chainId: 1,
    address: '0x316b13b951efe25aad1cb565385b23869a7d4c48',
    name: 'ETH 26 EMA Crossove',
    symbol: 'ETHEMAAPY',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/10600/thumb/eth-26-day-exponential-moving-average-set-ctoken.png?1580968470'
  },
  {
    chainId: 1,
    address: '0xcbb20d755abad34cb4a9b5ff6dd081c76769f62e',
    name: 'Cash Global Coin',
    symbol: 'CGC',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/12088/thumb/cgc.PNG?1597017789'
  },
  {
    chainId: 1,
    address: '0x72e203a17add19a3099137c9d7015fd3e2b7dba9',
    name: 'BlockchainPoland',
    symbol: 'BCP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8143/thumb/43Stz1Tw.png?1555551797'
  },
  {
    chainId: 1,
    address: '0x3d3d35bb9bec23b06ca00fe472b50e7a4c692c30',
    name: 'Vidya',
    symbol: 'VIDYA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12219/thumb/VIDYA_TOKEN.png?1598240425'
  },
  {
    chainId: 1,
    address: '0x8e4dbf540bf814c044785218b58c930b20a56be1',
    name: 'Holistic ETH Set',
    symbol: 'TCAPETHDAI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11777/thumb/holistic_eth_set.png?1593932141'
  },
  {
    chainId: 1,
    address: '0xf18af466f8885f9ea93d2b85c47a427cb01bad52',
    name: 'Racing Pigeon Chain',
    symbol: 'RPC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6408/thumb/SzibabnT_400x400.jpg?1550629165'
  },
  {
    chainId: 1,
    address: '0x5bb1632fa0023e1aa76a1ae92b4635c8dba49fa2',
    name: 'GastroAdvisor',
    symbol: 'FORK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6089/thumb/Gastro-logo-black.png?1549082587'
  },
  {
    chainId: 1,
    address: '0xadf8b8050639b6236915f7516d69de714672f0bf',
    name: 'Scanetchain',
    symbol: 'SWC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6876/thumb/scanetchain.jpg?1547043219'
  },
  {
    chainId: 1,
    address: '0xa249de6948022783765fee4850d7b85e43118fcc',
    name: 'Jarvis ',
    symbol: 'JAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8774/thumb/3jmUWB3e_400x400.jpg?1561085148'
  },
  {
    chainId: 1,
    address: '0xff20817765cb7f73d4bde2e66e067e58d11095c2',
    name: 'Amp',
    symbol: 'AMP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12409/thumb/amp-200x200.png?1599625397'
  },
  {
    chainId: 1,
    address: '0x3fa400483487a489ec9b1db29c4129063eec4654',
    name: 'CryptoKek',
    symbol: 'KEK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13513/thumb/Cryptokek-Logo-256px.png?1609292074'
  },
  {
    chainId: 1,
    address: '0x9e7cb236e43c4bd042fe463df6a175d4479ee186',
    name: 'Halving',
    symbol: 'HALV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12085/thumb/Halv-logo-200x200.png?1597009175'
  },
  {
    chainId: 1,
    address: '0xb8ddc930c2bab6c71610a2be639036e829f9c10b',
    name: 'KwhCoin',
    symbol: 'KWH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5045/thumb/kwh-coin-logo.png?1547040460'
  },
  {
    chainId: 1,
    address: '0x1fc5ef0337aea85c5f9198853a6e3a579a7a6987',
    name: 'ReapChain',
    symbol: 'REAP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13109/thumb/REAP.jpg?1605259422'
  },
  {
    chainId: 1,
    address: '0x10994aa2fb8e6ba5d9fb2bc127ff228c4fe6167f',
    name: 'Secure Pad',
    symbol: 'SEPA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14740/thumb/OJmHLHXb_400x400.png?1618004962'
  },
  {
    chainId: 1,
    address: '0x4fe5851c9af07df9e5ad8217afae1ea72737ebda',
    name: 'OpenPredict Token',
    symbol: 'OPT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12200/thumb/9idIjfrY_400x400.jpg?1598020161'
  },
  {
    chainId: 1,
    address: '0x8ef47555856f6ce2e0cd7c36aef4fab317d2e2e2',
    name: 'PayAccept',
    symbol: 'PAYT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12513/thumb/logo.png?1603801944'
  },
  {
    chainId: 1,
    address: '0x9a49f02e128a8e989b443a8f94843c0918bf45e7',
    name: 'Tokok',
    symbol: 'TOK',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/6618/thumb/oV2pAI5N_400x400.jpg?1548259690'
  },
  {
    chainId: 1,
    address: '0x1d9cd2180fd4e9771fca28681034d02390b14e4c',
    name: '1X Short Shitcoin I',
    symbol: 'HEDGESHIT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10450/thumb/683JEXMN_400x400.png?1579561773'
  },
  {
    chainId: 1,
    address: '0x07bac35846e5ed502aa91adf6a9e7aa210f2dcbe',
    name: 'Sifchain',
    symbol: 'EROWAN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14044/thumb/EROWAN.png?1614656300'
  },
  {
    chainId: 1,
    address: '0x86772b1409b61c639eaac9ba0acfbb6e238e5f83',
    name: 'Indexed Finance',
    symbol: 'NDX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13546/thumb/indexed-light.74bb5471.png?1609712728'
  },
  {
    chainId: 1,
    address: '0x9b00e6e8d787b13756eb919786c9745054db64f9',
    name: 'Sienna  ERC 20 ',
    symbol: 'WSIENNA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15420/thumb/sienna.jpeg?1620782072'
  },
  {
    chainId: 1,
    address: '0xcfcecfe2bd2fed07a9145222e8a7ad9cf1ccd22a',
    name: 'Adshares',
    symbol: 'ADS',
    decimals: 11,
    logoURI: 'https://assets.coingecko.com/coins/images/868/thumb/ads_tr_900.png?1549005693'
  },
  {
    chainId: 1,
    address: '0x84fe25f3921f3426395c883707950d0c00367576',
    name: 'Insight Protocol',
    symbol: 'INX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11694/thumb/logo_%EC%B0%90%EB%B8%94%EB%A3%A8.png?1592879247'
  },
  {
    chainId: 1,
    address: '0xcbdf1deb934e945a70b7289bb98e97013777f820',
    name: 'Doge of Woof Street',
    symbol: 'WSDOGE',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15498/thumb/wsdoge.png?1621069303'
  },
  {
    chainId: 1,
    address: '0xb6ca7399b4f9ca56fc27cbff44f4d2e4eef1fc81',
    name: 'Muse',
    symbol: 'MUSE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13230/thumb/muse_logo.png?1606460453'
  },
  {
    chainId: 1,
    address: '0xdf347911910b6c9a4286ba8e2ee5ea4a39eb2134',
    name: 'Bob s Repair',
    symbol: 'BOB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3550/thumb/PNG_BOB_Token_Large.png?1547038375'
  },
  {
    chainId: 1,
    address: '0x09e4bdfb273245063ef5e800d891eff7d04f9b83',
    name: 'ETH Price Action Ca',
    symbol: 'ETHPA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10905/thumb/eth_price_action_candlestick_set.png?1585731504'
  },
  {
    chainId: 1,
    address: '0xa3d93c0616dbc31fef1e112c7665a4ba4ddbf0be',
    name: 'Prime Whiterock Com',
    symbol: 'PWC',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/14228/thumb/156979798_133690605324377_5780181066455556577_o.png?1615169027'
  },
  {
    chainId: 1,
    address: '0x4fbb0b4cd8f960ac3428194f1c94c805d5b35836',
    name: 'BigBang Game',
    symbol: 'BBGC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/7719/thumb/rxpRt7tH_400x400.jpg?1549957251'
  },
  {
    chainId: 1,
    address: '0x58723c7afcd33a2db6ae06c37521725d65f0cc15',
    name: 'BullBearBitcoin Set',
    symbol: 'BBB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10901/thumb/bullbearbitcoin.png?1585730623'
  },
  {
    chainId: 1,
    address: '0x3301ee63fb29f863f2333bd4466acb46cd8323e6',
    name: 'Akita Inu',
    symbol: 'AKITA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14115/thumb/akitadog_copy.png?1614420157'
  },
  {
    chainId: 1,
    address: '0xb64ef51c888972c908cfacf59b47c1afbc0ab8ac',
    name: 'Storj',
    symbol: 'STORJ',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/949/thumb/storj.png?1547034811'
  },
  {
    chainId: 1,
    address: '0xf43b2f981efc5a611a97951ce4fd7d3bd87f4902',
    name: 'BullBearEthereum Se',
    symbol: 'BBE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10902/thumb/bullbearethereum.png?1585730791'
  },
  {
    chainId: 1,
    address: '0xaec7d1069e3a914a3eb50f0bfb1796751f2ce48a',
    name: 'S4FE',
    symbol: 'S4F',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7405/thumb/logo_%284%29.png?1547085640'
  },
  {
    chainId: 1,
    address: '0x48ff53777f747cfb694101222a944de070c15d36',
    name: 'Ether Kingdoms Toke',
    symbol: 'IMP',
    decimals: 7,
    logoURI: 'https://assets.coingecko.com/coins/images/5473/thumb/ether-kingdoms-token.png?1547394504'
  },
  {
    chainId: 1,
    address: '0x1dea979ae76f26071870f824088da78979eb91c8',
    name: 'SPINDLE',
    symbol: 'SPD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3714/thumb/spindle-core-logo.png?1547038741'
  },
  {
    chainId: 1,
    address: '0x1453dbb8a29551ade11d89825ca812e05317eaeb',
    name: 'Tendies',
    symbol: 'TEND',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11924/thumb/aaaaa.jpg?1596645622'
  },
  {
    chainId: 1,
    address: '0x2604fa406be957e542beb89e6754fcde6815e83f',
    name: 'PlayKey',
    symbol: 'PKT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2116/thumb/playkey.png?1548331394'
  },
  {
    chainId: 1,
    address: '0x7c32db0645a259fae61353c1f891151a2e7f8c1e',
    name: 'Potentiam',
    symbol: 'PTM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2349/thumb/ptm.png?1547036546'
  },
  {
    chainId: 1,
    address: '0xb1ca7e6714263a64659a3a89e1c313af30fd660a',
    name: 'ETH Moonshot X Yiel',
    symbol: 'ETHMOONX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10913/thumb/eth_moonshot_x.png?1585890185'
  },
  {
    chainId: 1,
    address: '0x1c7bbadc81e18f7177a95eb1593e5f5f35861b10',
    name: 'Auric Network',
    symbol: 'AUSCM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13015/thumb/auric-1-high-res_icon_300_PNG.png?1604384136'
  },
  {
    chainId: 1,
    address: '0xb1191f691a355b43542bea9b8847bc73e7abb137',
    name: 'Kirobo',
    symbol: 'KIRO',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/12688/thumb/QmScxyKBwqbGJZmp38EwaoRpXbzPkq3tvuMjeuJE1YLZeG.png?1601672684'
  },
  {
    chainId: 1,
    address: '0x260e63d91fccc499606bae3fe945c4ed1cf56a56',
    name: 'MoonTools',
    symbol: 'MOONS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12793/thumb/moontools-token-logo.png?1602588060'
  },
  {
    chainId: 1,
    address: '0x8cb1d155a5a1d5d667611b7710920fd9d1cd727f',
    name: 'Aircoins',
    symbol: 'AIRX',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9201/thumb/Aircoins.png?1591615033'
  },
  {
    chainId: 1,
    address: '0x856c4388c56c2a613c60507a4701af627157fed6',
    name: 'ETH Trending Alpha ',
    symbol: 'ETAS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10925/thumb/alphachain_set_2.png?1585894153'
  },
  {
    chainId: 1,
    address: '0x15822a64c8cb27d7828c45e0aafc3e6c5decd172',
    name: 'Fear   Greed Sentim',
    symbol: 'GREED',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10921/thumb/cryptocat_greed_fear_index_set.png?1585893573'
  },
  {
    chainId: 1,
    address: '0x4aa41bc1649c9c3177ed16caaa11482295fc7441',
    name: 'Xfit',
    symbol: 'XFIT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14904/thumb/xfit_logo.png?1618967174'
  },
  {
    chainId: 1,
    address: '0x3e780920601d61cedb860fe9c4a90c9ea6a35e78',
    name: 'Boosted Finance',
    symbol: 'BOOST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12329/thumb/boosted.jpg?1599105606'
  },
  {
    chainId: 1,
    address: '0x03829f5675f3b51d0f8c2a74417a757625acf22f',
    name: 'YFIBALANCER FINANCE',
    symbol: 'YFIB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12411/thumb/LogoYFIB_200px.png?1599640942'
  },
  {
    chainId: 1,
    address: '0x89c0b027bd7cc2d17854b06f8322e29451192ce3',
    name: 'Intelligent ETH Set',
    symbol: 'INTETH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10923/thumb/the_intelligent_eth_set.png?1585893829'
  },
  {
    chainId: 1,
    address: '0xa57392548087453dec6106e670bbfb849276b358',
    name: 'GemVault Coin',
    symbol: 'GVC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/5243/thumb/gemvault-coin-logo.png?1547040729'
  },
  {
    chainId: 1,
    address: '0xf14922001a2fb8541a433905437ae954419c2439',
    name: 'Direct Insurance To',
    symbol: 'DIT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/4220/thumb/Direct_Insurance_Token.jpg?1547039537'
  },
  {
    chainId: 1,
    address: '0x838d8e11b160dec88fe62bf0f743fb7000941e13',
    name: 'Krios',
    symbol: 'GIG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1657/thumb/GIGLogo256x256.png?1591067062'
  },
  {
    chainId: 1,
    address: '0xa0008f510fe9ee696e7e320c9e5cbf61e27791ee',
    name: 'GAMB',
    symbol: 'GMB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4497/thumb/gmb.png?1547039806'
  },
  {
    chainId: 1,
    address: '0x6a6d430573d3f070aeab97b3a189d698ea130454',
    name: 'Wrapped Bitcoin Dia',
    symbol: 'WBCD',
    decimals: 7,
    logoURI: 'https://assets.coingecko.com/coins/images/13477/thumb/EqEVHYPU0AAVaCk.png?1608947279'
  },
  {
    chainId: 1,
    address: '0xae73b38d1c9a8b274127ec30160a4927c4d71824',
    name: 'STK',
    symbol: 'STK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1663/thumb/stk.png?1548611093'
  },
  {
    chainId: 1,
    address: '0xade7b5f4a421d81ddad8ce86f77a0efe8921e9cc',
    name: 'Diligence',
    symbol: 'IRA',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/8577/thumb/IRA_DILIGENCE_SEMI-FINAL_3.png?1566456622'
  },
  {
    chainId: 1,
    address: '0x6cbedec4f1ac9d874987d2769596544e0d9161ab',
    name: 'DeepCloud AI',
    symbol: 'DEEP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4549/thumb/Deepcloud.png?1564168501'
  },
  {
    chainId: 1,
    address: '0x92ec47df1aa167806dfa4916d9cfb99da6953b8f',
    name: 'Idavoll Network',
    symbol: 'IDV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14229/thumb/71922199.png?1615169236'
  },
  {
    chainId: 1,
    address: '0x171706b7d113c68f2cc68a6ce63b1dbdcdf7b233',
    name: 'Echo Token',
    symbol: 'ECHO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15295/thumb/Coingeck_ECHO_logo-01.png?1620367112'
  },
  {
    chainId: 1,
    address: '0xd22dcf31ca28cf4dce530a0f120e244f4b4539eb',
    name: 'Omniunit',
    symbol: 'OMNIUNIT',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/13838/thumb/ABE15-E31-32-D6-4-D5-E-8-DB5-4448-F5-BB008-A.png?1612219908'
  },
  {
    chainId: 1,
    address: '0xf0bc1ae4ef7ffb126a8347d06ac6f8add770e1ce',
    name: '1Million Token',
    symbol: '1MT',
    decimals: 7,
    logoURI: 'https://assets.coingecko.com/coins/images/8495/thumb/1MTp.png?1586964391'
  },
  {
    chainId: 1,
    address: '0x13572851103bed49ff743af4c4bb5ace88b22e2f',
    name: 'R3FI Finance',
    symbol: 'R3FI',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14036/thumb/r3fi_logo.png?1613952169'
  },
  {
    chainId: 1,
    address: '0xbfd815347d024f449886c171f78fa5b8e6790811',
    name: 'AMPnet',
    symbol: 'AAPX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14303/thumb/AAPX.png?1615356946'
  },
  {
    chainId: 1,
    address: '0x4355fc160f74328f9b383df2ec589bb3dfd82ba0',
    name: 'Opus',
    symbol: 'OPT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/916/thumb/opus.png?1548330180'
  },
  {
    chainId: 1,
    address: '0x3a82d3111ab5faf39d847d46023d9090261a658f',
    name: 'Tycoon',
    symbol: 'TYC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15165/thumb/Tycoon_Logo_%28200x200%29.png?1620008909'
  },
  {
    chainId: 1,
    address: '0x27054b13b1b798b345b591a4d22e6562d47ea75a',
    name: 'AirSwap',
    symbol: 'AST',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/1019/thumb/AST.png?1547034939'
  },
  {
    chainId: 1,
    address: '0x6ba460ab75cd2c56343b3517ffeba60748654d26',
    name: 'UpToken',
    symbol: 'UP',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/3425/thumb/uptoken.png?1548759702'
  },
  {
    chainId: 1,
    address: '0x31b595e7cfdb624d10a3e7a562ed98c3567e3865',
    name: 'StakedZEN',
    symbol: 'STZEN',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/14506/thumb/stZEN-200px.png?1616573926'
  },
  {
    chainId: 1,
    address: '0x0a2d9370cf74da3fd3df5d764e394ca8205c50b6',
    name: 'Save Environment To',
    symbol: 'SET',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4829/thumb/set.png?1547040208'
  },
  {
    chainId: 1,
    address: '0xbe428c3867f05dea2a89fc76a102b544eac7f772',
    name: 'CyberVeinToken',
    symbol: 'CVT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3886/thumb/CyberVein_LOGO.png?1616062326'
  },
  {
    chainId: 1,
    address: '0x147faf8de9d8d8daae129b187f0d02d819126750',
    name: 'GeoDB',
    symbol: 'GEO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11130/thumb/geodb.png?1588941704'
  },
  {
    chainId: 1,
    address: '0x903bef1736cddf2a537176cf3c64579c3867a881',
    name: 'ICHI',
    symbol: 'ICHI',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13119/thumb/ICHI_%28Round%29.jpg?1614308761'
  },
  {
    chainId: 1,
    address: '0xd0d6d6c5fe4a677d343cc433536bb717bae167dd',
    name: 'adToken',
    symbol: 'ADT',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/778/thumb/adtoken.png?1547034537'
  },
  {
    chainId: 1,
    address: '0x062f90480551379791fbe2ed74c1fe69821b30d3',
    name: 'YMAX',
    symbol: 'YMAX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12535/thumb/Jec_HMyy_400x400.png?1600555787'
  },
  {
    chainId: 1,
    address: '0x0417912b3a7af768051765040a55bb0925d4ddcf',
    name: 'Liquidity Dividends',
    symbol: 'LID',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11921/thumb/logo-200.png?1596100933'
  },
  {
    chainId: 1,
    address: '0x9f300b4ac0bf94cad77e7e2d3f850352b8bb264c',
    name: 'Talent Coin',
    symbol: 'TLNT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13771/thumb/TLNT.png?1611698474'
  },
  {
    chainId: 1,
    address: '0x4bae380b5d762d543d426331b8437926443ae9ec',
    name: 'XVIX',
    symbol: 'XVIX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13364/thumb/xvix_logo.png?1607914655'
  },
  {
    chainId: 1,
    address: '0x9ceb84f92a0561fa3cc4132ab9c0b76a59787544',
    name: 'Doki Doki',
    symbol: 'DOKI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12759/thumb/doki_logo.png?1602338064'
  },
  {
    chainId: 1,
    address: '0x1410434b0346f5be678d0fb554e5c7ab620f8f4a',
    name: 'BitKan',
    symbol: 'KAN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4651/thumb/kan-token.png?1547039917'
  },
  {
    chainId: 1,
    address: '0xf2f9a7e93f845b3ce154efbeb64fb9346fcce509',
    name: 'UniPower',
    symbol: 'POWER',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11618/thumb/unipower.png?1591943398'
  },
  {
    chainId: 1,
    address: '0x4b4f5286e0f93e965292b922b9cd1677512f1222',
    name: 'YUNo Finance',
    symbol: 'YUNO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12297/thumb/y.png?1598942444'
  },
  {
    chainId: 1,
    address: '0x91f529e972d6cf43d36abfa91c1118122ff7f16c',
    name: 'Sapchain',
    symbol: 'SAP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9109/thumb/sap.PNG?1564433894'
  },
  {
    chainId: 1,
    address: '0x00ff902d4b2bfdbd0db38887412447c30b565aa0',
    name: 'Tcoin fun',
    symbol: 'TCO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13811/thumb/logo200_%289%29.png?1612047833'
  },
  {
    chainId: 1,
    address: '0x6368e1e18c4c419ddfc608a0bed1ccb87b9250fc',
    name: 'Tap',
    symbol: 'XTP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10291/thumb/0_3SJYkk_400x400.jpg?1577229220'
  },
  {
    chainId: 1,
    address: '0xc56c2b7e71b54d38aab6d52e94a04cbfa8f604fa',
    name: 'ZUSD',
    symbol: 'ZUSD',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/14192/thumb/icon_zusd_200_200.png?1614843297'
  },
  {
    chainId: 1,
    address: '0x13d71cfc90a83cd1cc0e59675c3f4b90d4162a8b',
    name: 'SWIPE Network',
    symbol: 'SWIPE',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9697/thumb/jX_8guyy_400x400_%281%29.jpg?1570914447'
  },
  {
    chainId: 1,
    address: '0xe9fa21e671bcfb04e6868784b89c19d5aa2424ea',
    name: 'EurocoinToken',
    symbol: 'ECTE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7634/thumb/logotoken200x200.png?1550742362'
  },
  {
    chainId: 1,
    address: '0x429ac77f069bff489e2d78f9479e1e933305c528',
    name: 'Rizubot',
    symbol: 'RZB',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/8072/thumb/IMG-20190325-094833-072.jpg?1554254437'
  },
  {
    chainId: 1,
    address: '0xf503bf7a7b0962205668ed041140f4af360304be',
    name: 'Unicly Autoglyphs C',
    symbol: 'UGLYPH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15352/thumb/uGLYPH.png?1620629169'
  },
  {
    chainId: 1,
    address: '0x3209f98bebf0149b769ce26d71f7aea8e435efea',
    name: 'Traxia',
    symbol: 'TMT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3312/thumb/tmt.png?1547037907'
  },
  {
    chainId: 1,
    address: '0x177ba0cac51bfc7ea24bad39d81dcefd59d74faa',
    name: 'KittenFinance',
    symbol: 'KIF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12340/thumb/SnQPkABT_400x400.png?1599173267'
  },
  {
    chainId: 1,
    address: '0x2ab6bb8408ca3199b8fa6c92d5b455f820af03c4',
    name: 'TE FOOD',
    symbol: 'TONE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2325/thumb/tec.png?1547036538'
  },
  {
    chainId: 1,
    address: '0x6a7ef4998eb9d0f706238756949f311a59e05745',
    name: 'Keysians Network',
    symbol: 'KEN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12141/thumb/Keysians_logo.jpg?1597542966'
  },
  {
    chainId: 1,
    address: '0xbdec45952b5e234eddc2981b43eed360826d5087',
    name: 'Mogu',
    symbol: 'MOGX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9687/thumb/UZpzCJc1_400x400.jpg?1570828056'
  },
  {
    chainId: 1,
    address: '0xc8d2ab2a6fdebc25432e54941cb85b55b9f152db',
    name: 'Grap Finance',
    symbol: 'GRAP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12193/thumb/0WBMFrtk_400x400.jpg?1597984167'
  },
  {
    chainId: 1,
    address: '0x93ed3fbe21207ec2e8f2d3c3de6e058cb73bc04d',
    name: 'Kleros',
    symbol: 'PNK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3833/thumb/kleros.png?1547975322'
  },
  {
    chainId: 1,
    address: '0x679131f591b4f369acb8cd8c51e68596806c3916',
    name: 'Trustlines Network',
    symbol: 'TLN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11562/thumb/Trustlines.png?1591152088'
  },
  {
    chainId: 1,
    address: '0x87befc1d367190f2b9cbe9b689e0e5ca658e3b71',
    name: 'GLOBALTRUSTFUND TOK',
    symbol: 'GTF',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11817/thumb/gtf.png?1594679456'
  },
  {
    chainId: 1,
    address: '0xb056c38f6b7dc4064367403e26424cd2c60655e1',
    name: 'CEEK Smart VR Token',
    symbol: 'CEEK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2581/thumb/ceek-smart-vr-token-logo.png?1547036714'
  },
  {
    chainId: 1,
    address: '0xabbbb6447b68ffd6141da77c18c7b5876ed6c5ab',
    name: 'DATx',
    symbol: 'DATX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2942/thumb/datx.png?1547037168'
  },
  {
    chainId: 1,
    address: '0x3b62f3820e0b035cc4ad602dece6d796bc325325',
    name: 'DEUS Finance',
    symbol: 'DEUS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12539/thumb/deus_logo.jpg?1611136731'
  },
  {
    chainId: 1,
    address: '0xf33121a2209609cadc7349acc9c40e41ce21c730',
    name: 'Blockchain Adventur',
    symbol: 'BAG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14863/thumb/3iw7MAi.png?1618810870'
  },
  {
    chainId: 1,
    address: '0x7fc693b16184b6778f4534f5410f06633cb030e0',
    name: 'RELAX Protocol',
    symbol: 'RLX',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/10842/thumb/logo_%2856%29.png?1584653679'
  },
  {
    chainId: 1,
    address: '0xd9d01d4cb824219a8f482a0fad479cb971fd0628',
    name: 'EnterCoin',
    symbol: 'ENTRC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/8163/thumb/entrc-logo200.png?1555901482'
  },
  {
    chainId: 1,
    address: '0xfecba472b2540c5a2d3700b2c9e06f0aa7dc6462',
    name: 'Pub Finance',
    symbol: 'PINT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14016/thumb/PINT.png?1614064578'
  },
  {
    chainId: 1,
    address: '0x33d20575f20c6a1881f8ab08e69f6fbaffaeedf2',
    name: 'BlueWizard',
    symbol: 'WIZ',
    decimals: 12,
    logoURI: 'https://assets.coingecko.com/coins/images/15261/thumb/BW.jpg?1620270880'
  },
  {
    chainId: 1,
    address: '0x151202c9c18e495656f372281f493eb7698961d5',
    name: 'Debitum Network',
    symbol: 'DEB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1648/thumb/debitum_network_token.jpg?1547035890'
  },
  {
    chainId: 1,
    address: '0x0391d2021f89dc339f60fff84546ea23e337750f',
    name: 'BarnBridge',
    symbol: 'BOND',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12811/thumb/barnbridge.jpg?1602728853'
  },
  {
    chainId: 1,
    address: '0x79256db1bdb6259315a1a3d7dd237f69cadfd8fc',
    name: 'Typhoon Cash',
    symbol: 'PHOON',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13730/thumb/photo_2021-01-21_19-05-27.jpg?1611227665'
  },
  {
    chainId: 1,
    address: '0x56e0b2c7694e6e10391e870774daa45cf6583486',
    name: 'DUO Network',
    symbol: 'DUO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4653/thumb/Duo_Network.png?1556593318'
  },
  {
    chainId: 1,
    address: '0xcc6f15be8573cb8243c42d300565566d328213dd',
    name: 'OWN Token',
    symbol: 'OWN',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/10950/thumb/cropped-WhatsApp-Image-2020-04-04-at-9.04.01-PM.jpeg?1586305520'
  },
  {
    chainId: 1,
    address: '0xf293d23bf2cdc05411ca0eddd588eb1977e8dcd4',
    name: 'Sylo',
    symbol: 'SYLO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6430/thumb/SYLO.svg?1589527756'
  },
  {
    chainId: 1,
    address: '0xca5d29b3e74d59ebcdf09111495d86f319886a40',
    name: 'WHEY',
    symbol: 'WHEY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14883/thumb/WHEY_logo_black_0421_v1.png?1618893173'
  },
  {
    chainId: 1,
    address: '0xc15a399c4ea7815fe36857c9e290ee452a5d6b21',
    name: 'BoatPilot',
    symbol: 'NAVY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3252/thumb/navi.png?1547037778'
  },
  {
    chainId: 1,
    address: '0xa47c8bf37f92abed4a126bda807a7b7498661acd',
    name: 'TerraUSD',
    symbol: 'UST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12681/thumb/UST.png?1601612407'
  },
  {
    chainId: 1,
    address: '0xe577e0b200d00ebdecbfc1cd3f7e8e04c70476be',
    name: 'XEuro',
    symbol: 'XEURO',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/9085/thumb/fDuXUMs5_400x400.png?1564085357'
  },
  {
    chainId: 1,
    address: '0x3277dd536471a3cbeb0c9486acad494c95a31e73',
    name: 'CoinHe Token',
    symbol: 'CHT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9239/thumb/coinhe.png?1565769425'
  },
  {
    chainId: 1,
    address: '0xa689dcea8f7ad59fb213be4bc624ba5500458dc6',
    name: 'EURBASE',
    symbol: 'EBASE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9541/thumb/Eurbase_Logo.png?1590024287'
  },
  {
    chainId: 1,
    address: '0x4a6ab9792e9f046c3ab22d8602450de5186be9a7',
    name: 'Polka Ventures',
    symbol: 'POLVEN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14613/thumb/polven.png?1617253185'
  },
  {
    chainId: 1,
    address: '0x2b4200a8d373d484993c37d63ee14aee0096cd12',
    name: 'USDFreeLiquidity',
    symbol: 'USDFL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13697/thumb/usdfl.png?1610980328'
  },
  {
    chainId: 1,
    address: '0x16be21c08eb27953273608629e4397556c561d26',
    name: 'Yearn20Moon Finance',
    symbol: 'YMF20',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13037/thumb/Brand_Identity.png?1605773986'
  },
  {
    chainId: 1,
    address: '0x0cdf9acd87e940837ff21bb40c9fd55f68bba059',
    name: 'Public Mint',
    symbol: 'MINT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14106/thumb/Public_Mint_Icon_200x200.png?1614333027'
  },
  {
    chainId: 1,
    address: '0xcaa05e82bdcba9e25cd1a3bf1afb790c1758943d',
    name: 'Partner',
    symbol: 'PRC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/8589/thumb/imgonline-com-ua-Resize-54JyhhJBRl58.png?1559541814'
  },
  {
    chainId: 1,
    address: '0xfd09911130e6930bf87f2b0554c44f400bd80d3e',
    name: 'EthicHub',
    symbol: 'ETHIX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3031/thumb/Logo_%284%29_%281%29.png?1613249824'
  },
  {
    chainId: 1,
    address: '0x6fc2f1044a3b9bb3e43a43ec8f840843ed753061',
    name: 'Robo Token',
    symbol: 'ROBO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14611/thumb/rlogo200.jpg?1617247446'
  },
  {
    chainId: 1,
    address: '0xb987d48ed8f2c468d52d6405624eadba5e76d723',
    name: 'Stabilize',
    symbol: 'STBZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12753/thumb/icon.png?1608771101'
  },
  {
    chainId: 1,
    address: '0xbcf9dbf8b14ed096b2ba08b7269356197fdd1b5d',
    name: 'Avaluse',
    symbol: 'AVAL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14200/thumb/Avaluse-logo-twitter.png?1614897701'
  },
  {
    chainId: 1,
    address: '0x74603e780545d02c4257e7d2be19c74de7be1952',
    name: 'ETG Finance',
    symbol: 'ETGF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13032/thumb/etgf_logo.png?1604482450'
  },
  {
    chainId: 1,
    address: '0x1715ac0743102bf5cd58efbb6cf2dc2685d967b6',
    name: 'sDOT',
    symbol: 'SDOT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15729/thumb/sdot_final.PNG?1621669683'
  },
  {
    chainId: 1,
    address: '0x31d939dc09a6bb55642fcbf9d56166ebc0bdec9a',
    name: 'Peach Finance',
    symbol: 'PEECH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15376/thumb/PEECH-token-logo-200.png?1620692908'
  },
  {
    chainId: 1,
    address: '0x70e36f6bf80a52b3b46b3af8e106cc0ed743e8e4',
    name: 'cCOMP',
    symbol: 'CCOMP',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12840/thumb/1_z8UrVtod3bme4-J_pXAQQA_2x.png?1602936322'
  },
  {
    chainId: 1,
    address: '0xe3fedaecd47aa8eab6b23227b0ee56f092c967a9',
    name: 'Primas',
    symbol: 'PST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/917/thumb/primas.png?1548331951'
  },
  {
    chainId: 1,
    address: '0x15e4132dcd932e8990e794d1300011a472819cbd',
    name: 'GRPL Finance',
    symbol: 'GRPL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13586/thumb/GRPL.png?1609927724'
  },
  {
    chainId: 1,
    address: '0xf8c17c840549974ec17c6573b3963f1e3046c0eb',
    name: 'Prediqt',
    symbol: 'PQT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14407/thumb/57197945.png?1615936268'
  },
  {
    chainId: 1,
    address: '0xcb2fa15f4ea7c55bf6ef9456a662412b137043e9',
    name: 'Payou Finance',
    symbol: 'PAYOU',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12863/thumb/PAYOU-LOGO.png?1603118094'
  },
  {
    chainId: 1,
    address: '0x73d9e335669462cbdd6aa3adafe9efee86a37fe9',
    name: 'Daiquilibrium',
    symbol: 'DAIQ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13626/thumb/BicSg26r_400x400.png?1610418623'
  },
  {
    chainId: 1,
    address: '0x3833dda0aeb6947b98ce454d89366cba8cc55528',
    name: 'SophiaTX',
    symbol: 'SPHTX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1443/thumb/sophiatx.png?1548609885'
  },
  {
    chainId: 1,
    address: '0xfce94fde7ac091c2f1db00d62f15eeb82b624389',
    name: 'Noah s Ark',
    symbol: 'NOAHARK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13557/thumb/Gc6kz-5a.png?1609769750'
  },
  {
    chainId: 1,
    address: '0x336213e1ddfc69f4701fc3f86f4ef4a160c1159d',
    name: 'iCEX',
    symbol: 'ICEX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11834/thumb/iCEX.png?1616151203'
  },
  {
    chainId: 1,
    address: '0x83d60e7aed59c6829fb251229061a55f35432c4d',
    name: 'Infinito',
    symbol: 'INFT',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/9461/thumb/5TOvk2A.png?1604885818'
  },
  {
    chainId: 1,
    address: '0x034455c8a9882bf44c9704c780a55198e05ba559',
    name: 'Lumos',
    symbol: 'LMS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12938/thumb/6tZdBWW.png?1603723170'
  },
  {
    chainId: 1,
    address: '0xcad2d4c4469ff09ab24d02a63bcedfcd44be0645',
    name: 'Crypto Accept',
    symbol: 'ACPT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12531/thumb/crypto-accept.png?1600497829'
  },
  {
    chainId: 1,
    address: '0xb453f1f2ee776daf2586501361c457db70e1ca0f',
    name: 'AGA Rewards',
    symbol: 'AGAR',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/14207/thumb/AR256.png?1614931200'
  },
  {
    chainId: 1,
    address: '0x3a3547d62e9f9e76f99d51d5ab4f07aae0db2dbb',
    name: 'BITICA COIN',
    symbol: 'BDCC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11857/thumb/bitica_400x400.png?1595168606'
  },
  {
    chainId: 1,
    address: '0x66d28cb58487a7609877550e1a34691810a6b9fc',
    name: 'Koinos',
    symbol: 'KOIN',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13116/thumb/koinos-mark-circle-250px.png?1614870541'
  },
  {
    chainId: 1,
    address: '0x1fc05d480b1ef1175a31123bfdbd36bfee256889',
    name: 'noob finance',
    symbol: 'NOOB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13088/thumb/noob_finance_logo.jpg?1604998432'
  },
  {
    chainId: 1,
    address: '0xf85feea2fdd81d51177f6b8f35f0e6734ce45f5f',
    name: 'CyberMiles',
    symbol: 'CMT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1300/thumb/cybermiles.png?1547035327'
  },
  {
    chainId: 1,
    address: '0x9ea3b5b4ec044b70375236a281986106457b20ef',
    name: 'Delta Financial',
    symbol: 'DELTA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14564/thumb/Delta_logo.png?1619067341'
  },
  {
    chainId: 1,
    address: '0xbab165df9455aa0f2aed1f2565520b91ddadb4c8',
    name: 'EDUCare',
    symbol: 'EKT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/2643/thumb/educare.png?1547036820'
  },
  {
    chainId: 1,
    address: '0xab93df617f51e1e415b5b4f8111f122d6b48e55c',
    name: 'Delta Exchange Toke',
    symbol: 'DETO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14610/thumb/deto-logo.png?1617247295'
  },
  {
    chainId: 1,
    address: '0x6737fe98389ffb356f64ebb726aa1a92390d94fb',
    name: 'Zero Carbon Project',
    symbol: 'ZCC',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/4610/thumb/29597798_1994933117411549_6379526843613393871_n.png?1606409572'
  },
  {
    chainId: 1,
    address: '0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b',
    name: 'Convex Finance',
    symbol: 'CVX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15585/thumb/convex.png?1621256328'
  },
  {
    chainId: 1,
    address: '0x31e92324ef7185c65dd4d154cfa4f6326b489c55',
    name: 'Earn Network',
    symbol: 'EARN',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/14727/thumb/3kpCo1Iw_400x400.png?1617951596'
  },
  {
    chainId: 1,
    address: '0x0af44e2784637218dd1d32a322d44e603a8f0c6a',
    name: 'MATRYX',
    symbol: 'MTX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1495/thumb/matryx.png?1547978542'
  },
  {
    chainId: 1,
    address: '0x7fbec0bb6a7152e77c30d005b5d49cbc08a602c3',
    name: 'disBalancer',
    symbol: 'DDOS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14791/thumb/communityIcon_o2yriheuszk61.png?1618463138'
  },
  {
    chainId: 1,
    address: '0x814f67fa286f7572b041d041b1d99b432c9155ee',
    name: 'Dragon Coin',
    symbol: 'DRG',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/3423/thumb/Dragon_Flat_Logo_2x-1.png?1551267120'
  },
  {
    chainId: 1,
    address: '0x36151737b45017234e9570cf9a1cac97138953c2',
    name: 'Noiz Chain',
    symbol: 'NOIZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3798/thumb/xDxHeYll_400x400.jpg?1563415658'
  },
  {
    chainId: 1,
    address: '0x6251e725cd45fb1af99354035a414a2c0890b929',
    name: 'MixTrust',
    symbol: 'MXT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12148/thumb/MXT_LOGO_200_200.png?1597578983'
  },
  {
    chainId: 1,
    address: '0xfae4ee59cdd86e3be9e8b90b53aa866327d7c090',
    name: 'CPChain',
    symbol: 'CPC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2760/thumb/cpchain.png?1547036953'
  },
  {
    chainId: 1,
    address: '0x648d19d775a8d4bafba09e189090bdcbf8ef31c1',
    name: 'Safari',
    symbol: 'SFR',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12599/thumb/SFR.png?1604658501'
  },
  {
    chainId: 1,
    address: '0xd059c8a4c7f53c4352d933b059349ba492294ac9',
    name: 'Apple Protocol Toke',
    symbol: 'AAPL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13208/thumb/aapl.png?1606200126'
  },
  {
    chainId: 1,
    address: '0xca0e7269600d353f70b14ad118a49575455c0f2f',
    name: 'AMLT Network',
    symbol: 'AMLT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2703/thumb/amlt.png?1563794756'
  },
  {
    chainId: 1,
    address: '0x17d8cbb6bce8cee970a4027d1198f6700a7a6c24',
    name: 'imBTC',
    symbol: 'IMBTC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13988/thumb/imbtc.png?1613487586'
  },
  {
    chainId: 1,
    address: '0xc7743bf0b300ec041e704cc34d4f43050942099e',
    name: 'CAT trade Protocol',
    symbol: 'CATX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12214/thumb/cattrade_protocol_logo.png?1598236877'
  },
  {
    chainId: 1,
    address: '0x2565ae0385659badcada1031db704442e1b69982',
    name: 'Assemble Protocol',
    symbol: 'ASM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11605/thumb/gpvrlkSq_400x400_%281%29.jpg?1591775789'
  },
  {
    chainId: 1,
    address: '0x8be6a6158f6b8a19fe60569c757d16e546c2296d',
    name: 'YFF Finance',
    symbol: 'YFF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12578/thumb/5ymP5emT_400x400.jpg?1600913790'
  },
  {
    chainId: 1,
    address: '0x2730d6fdc86c95a74253beffaa8306b40fedecbb',
    name: 'UNICORN Token',
    symbol: 'UNI',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9383/thumb/54231438.png?1566777314'
  },
  {
    chainId: 1,
    address: '0x24e3794605c84e580eea4972738d633e8a7127c8',
    name: 'Katalyo',
    symbol: 'KTLYO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13347/thumb/katalyo_logo_aqua_256.png?1607762430'
  },
  {
    chainId: 1,
    address: '0x03ab458634910aad20ef5f1c8ee96f1d6ac54919',
    name: 'Rai Reflex Index',
    symbol: 'RAI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14004/thumb/RAI-logo-coin.png?1613592334'
  },
  {
    chainId: 1,
    address: '0x53dfea0a8cc2a2a2e425e1c174bc162999723ea0',
    name: 'Jarvis Synthetic Sw',
    symbol: 'JCHF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15727/thumb/logo_-_2021-05-22T063927.292.png?1621636779'
  },
  {
    chainId: 1,
    address: '0xe469c4473af82217b30cf17b10bcdb6c8c796e75',
    name: 'EXRNchain',
    symbol: 'EXRN',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/1049/thumb/exrn.png?1616039914'
  },
  {
    chainId: 1,
    address: '0x10d88d7495ca381df1391229bdb82d015b9ad17d',
    name: 'Bavala',
    symbol: 'BVA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9957/thumb/Bavala.png?1574095352'
  },
  {
    chainId: 1,
    address: '0xbf494f02ee3fde1f20bee6242bce2d1ed0c15e47',
    name: 'World Token',
    symbol: 'WORLD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13801/thumb/WORLD.png?1612843088'
  },
  {
    chainId: 1,
    address: '0x47c0ad2ae6c0ed4bcf7bc5b380d7205e89436e84',
    name: 'rHegic',
    symbol: 'RHEGIC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13374/thumb/hegic_logo.jpg?1608006964'
  },
  {
    chainId: 1,
    address: '0x21d5a14e625d767ce6b7a167491c2d18e0785fda',
    name: 'Jinbi Token',
    symbol: 'JNB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4349/thumb/image001.jpg?1547039703'
  },
  {
    chainId: 1,
    address: '0x6fb0855c404e09c47c3fbca25f08d4e41f9f062f',
    name: 'Aave ZRX v1',
    symbol: 'AZRX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11735/thumb/aZRX.png?1593085371'
  },
  {
    chainId: 1,
    address: '0x820a8481451e893bc66dce50c84d45617cac3705',
    name: 'Bitcoin True',
    symbol: 'BTCT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11839/thumb/tEGpbQI.png?1594892967'
  },
  {
    chainId: 1,
    address: '0x4185cf99745b2a20727b37ee798193dd4a56cdfa',
    name: 'DEUS Synthetic Coin',
    symbol: 'WCOINBASE-IOU',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13444/thumb/coinbase-black.png?1608629833'
  },
  {
    chainId: 1,
    address: '0xc626d951eff8e421448074bd2ad7805c6d585793',
    name: 'Tiger King',
    symbol: 'TKING',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15605/thumb/tiger_king.PNG?1621314256'
  },
  {
    chainId: 1,
    address: '0xb5ca46cf1da09248126682a7bd72401fd7a6b151',
    name: 'Provoco',
    symbol: 'VOCO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6501/thumb/provoco.jpg?1547042729'
  },
  {
    chainId: 1,
    address: '0x0371a82e4a9d0a4312f3ee2ac9c6958512891372',
    name: 'bitJob',
    symbol: 'STU',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1172/thumb/bitjob.jpg?1547035202'
  },
  {
    chainId: 1,
    address: '0x09843b9137fc5935b7f3832152f9074db5d2d1ee',
    name: 'YFI3 money',
    symbol: 'YFI3',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13142/thumb/yfi3.png?1605596278'
  },
  {
    chainId: 1,
    address: '0x03fb52d4ee633ab0d06c833e32efdd8d388f3e6a',
    name: 'Super Black Hole',
    symbol: 'HOLE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9087/thumb/SdAKFUu.png?1564086285'
  },
  {
    chainId: 1,
    address: '0x9d1233cc46795e94029fda81aaadc1455d510f15',
    name: 'Zero Collateral Dai',
    symbol: 'ZAI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13569/thumb/zai_logo.png?1609844802'
  },
  {
    chainId: 1,
    address: '0x739763a258640919981f9ba610ae65492455be53',
    name: 'Node Runners',
    symbol: 'NDR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13400/thumb/ndr.jpg?1608172954'
  },
  {
    chainId: 1,
    address: '0xf0ee6b27b759c9893ce4f094b49ad28fd15a23e4',
    name: 'Enigma',
    symbol: 'ENG',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/1007/thumb/enigma-logo.png?1547034914'
  },
  {
    chainId: 1,
    address: '0x14409b0fc5c7f87b5dad20754fe22d29a3de8217',
    name: 'PYRO Network',
    symbol: 'PYRO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10413/thumb/ldWtWr6.png?1579127581'
  },
  {
    chainId: 1,
    address: '0x4ff5253e2304e3f5ed6547ac5d9952a62b91e3e8',
    name: 'Stabinol',
    symbol: 'STOL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14166/thumb/stol_icon.png?1614748492'
  },
  {
    chainId: 1,
    address: '0x17c090f9a17e4e5a8ceb23bbe7e7e28e3c4ca196',
    name: 'BitDNS',
    symbol: 'DNS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13590/thumb/T_t9CFf6_400x400.png?1609981076'
  },
  {
    chainId: 1,
    address: '0x6295ab2be04a617747481b292c390bfca592cf28',
    name: 'TokenDesk',
    symbol: 'TDS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1821/thumb/tokendesk.png?1548758709'
  },
  {
    chainId: 1,
    address: '0x98ad9b32dd10f8d8486927d846d4df8baf39abe2',
    name: 'VELO Token',
    symbol: 'VLO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13366/thumb/velo_token_logo.png?1607918558'
  },
  {
    chainId: 1,
    address: '0x905e337c6c8645263d3521205aa37bf4d034e745',
    name: 'Doc com',
    symbol: 'MTC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4177/thumb/Webp.net-resizeimage_%2832%29.png?1547039449'
  },
  {
    chainId: 1,
    address: '0x9c2dc0c3cc2badde84b0025cf4df1c5af288d835',
    name: 'Coreto',
    symbol: 'COR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12856/thumb/COR.png?1620210966'
  },
  {
    chainId: 1,
    address: '0x1735db6ab5baa19ea55d0adceed7bcdc008b3136',
    name: 'UREEQA',
    symbol: 'URQA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14605/thumb/R_O2enOX_400x400.png?1617243310'
  },
  {
    chainId: 1,
    address: '0x1de5e000c41c8d35b9f1f4985c23988f05831057',
    name: 'BonFi',
    symbol: 'BNF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12862/thumb/bonfi_logo.png?1603114422'
  },
  {
    chainId: 1,
    address: '0x60eb57d085c59932d5faa6c6026268a4386927d0',
    name: 'LOCGame',
    symbol: 'LOCG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15175/thumb/LOC_GAME.png?1620025060'
  },
  {
    chainId: 1,
    address: '0x21f15966e07a10554c364b988e91dab01d32794a',
    name: 'SmartMesh',
    symbol: 'SMT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1346/thumb/smartmesh.png?1548609531'
  },
  {
    chainId: 1,
    address: '0xd6cb2adf47655b1babddc214d79257348cbc39a7',
    name: 'Oracle Top 5 Index',
    symbol: 'ORCL5',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14182/thumb/orcl-light-circular.4c2aac77.png?1614821411'
  },
  {
    chainId: 1,
    address: '0xba745513acebcbb977497c569d4f7d340f2a936b',
    name: 'Mainstream For The ',
    symbol: 'MFTU',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5519/thumb/Mainstream_for_the_underground.png?1534426154'
  },
  {
    chainId: 1,
    address: '0xd69f306549e9d96f183b1aeca30b8f4353c2ecc3',
    name: 'MCH Coin',
    symbol: 'MCHC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15399/thumb/MCHC.jpg?1620721307'
  },
  {
    chainId: 1,
    address: '0xb525ecee288b99216cd481c56b6efbdbe9bf90b5',
    name: 'Kuma Inu',
    symbol: 'KUMA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15526/thumb/kuma_inu.PNG?1621128824'
  },
  {
    chainId: 1,
    address: '0x3de46d6b13314ec944100ad4dce8a730b6314315',
    name: 'VelaCoin',
    symbol: 'VELA',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13399/thumb/lg200x200.png?1608171059'
  },
  {
    chainId: 1,
    address: '0xd78e5b24aae1bd03a488af642770e2abb986f9d5',
    name: 'RYI Platinum',
    symbol: 'RYIP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14148/thumb/Untitled-design-11.png?1614666357'
  },
  {
    chainId: 1,
    address: '0xd7c49cee7e9188cca6ad8ff264c1da2e69d4cf3b',
    name: 'Nexus Mutual',
    symbol: 'NXM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11810/thumb/nexus-mutual.jpg?1594547726'
  },
  {
    chainId: 1,
    address: '0xefc1c73a3d8728dc4cf2a18ac5705fe93e5914ac',
    name: 'MetricExchange',
    symbol: 'METRIC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12664/thumb/metric_exchange_logo.png?1601453711'
  },
  {
    chainId: 1,
    address: '0xd07d9fe2d2cc067015e2b4917d24933804f42cfa',
    name: 'Tolar',
    symbol: 'TOL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4487/thumb/tolar.png?1548759060'
  },
  {
    chainId: 1,
    address: '0xb29663aa4e2e81e425294193616c1b102b70a158',
    name: 'Ludena Protocol',
    symbol: 'LDN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13372/thumb/LudenaProtocol_symbol_200x200.png?1607999831'
  },
  {
    chainId: 1,
    address: '0x331fa6c97c64e47475164b9fc8143b533c5ef529',
    name: 'EXMR FDN',
    symbol: 'EXMR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3285/thumb/exmr_transparent.png?1604655926'
  },
  {
    chainId: 1,
    address: '0xfe56e974c1c85e9351325fb2d62963a022ad624f',
    name: 'UnilayerX',
    symbol: 'LAYERX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15015/thumb/LAYERX-logo.png?1619428951'
  },
  {
    chainId: 1,
    address: '0x089a6d83282fb8988a656189f1e7a73fa6c1cac2',
    name: 'Prospectors Gold',
    symbol: 'PGL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/987/thumb/pgl.jpg?1513854091'
  },
  {
    chainId: 1,
    address: '0xa8b0f154a688c22142e361707df64277e0a0be66',
    name: 'Rake Finance',
    symbol: 'RAK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13078/thumb/rake-logo-200x200.png?1604931839'
  },
  {
    chainId: 1,
    address: '0xdcb01cc464238396e213a6fdd933e36796eaff9f',
    name: 'Yield',
    symbol: 'YLD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13336/thumb/yieldcredit-logo-1024.png?1607653444'
  },
  {
    chainId: 1,
    address: '0xac4d22e40bf0b8ef4750a99ed4e935b99a42685e',
    name: 'Aeryus',
    symbol: 'AER',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8626/thumb/aerlogo.png.jpeg?1559773242'
  },
  {
    chainId: 1,
    address: '0x024b6e7dc26f4d5579bdd936f8d7bc31f2339999',
    name: 'Mithril Share',
    symbol: 'MIS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13516/thumb/MIS.png?1609306996'
  },
  {
    chainId: 1,
    address: '0x426ca1ea2406c07d75db9585f22781c096e3d0e0',
    name: 'Minereum',
    symbol: 'MNE',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/702/thumb/mne.png?1587615060'
  },
  {
    chainId: 1,
    address: '0x9040e237c3bf18347bb00957dc22167d0f2b999d',
    name: 'Standard Protocol',
    symbol: 'STND',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15100/thumb/standard.jpeg?1619691869'
  },
  {
    chainId: 1,
    address: '0x45c943973e65d069906b0dc33dc31d1d7d9d09dc',
    name: 'MovieCash',
    symbol: 'MVH',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/14550/thumb/LOGO-CINEMADROM-2.png?1616989406'
  },
  {
    chainId: 1,
    address: '0xec1a718d1a6f8f8d94ecec6fe91465697bb2b88c',
    name: 'Entone',
    symbol: 'ENTONE',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11783/thumb/Logo200_%288%29.png?1594000418'
  },
  {
    chainId: 1,
    address: '0x580c8520deda0a441522aeae0f9f7a5f29629afa',
    name: 'Dawn Protocol',
    symbol: 'DAWN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11555/thumb/dawn_protocol.png?1591060256'
  },
  {
    chainId: 1,
    address: '0x9b5c2be869a19e84bdbcb1386dad83a2ec8dae82',
    name: 'Stream Protocol',
    symbol: 'STPL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13277/thumb/STPL.png?1606897574'
  },
  {
    chainId: 1,
    address: '0x7d4b8cce0591c9044a22ee543533b72e976e36c3',
    name: 'Change',
    symbol: 'CAG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1021/thumb/200x200.png?1610766224'
  },
  {
    chainId: 1,
    address: '0x0e8d6b471e332f140e7d9dbb99e5e3822f728da6',
    name: 'Abyss',
    symbol: 'ABYSS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2105/thumb/NrFmpxs.png?1600318377'
  },
  {
    chainId: 1,
    address: '0xe8272210954ea85de6d2ae739806ab593b5d9c51',
    name: 'Alpha5',
    symbol: 'A5T',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13848/thumb/a5t.jpg?1614652988'
  },
  {
    chainId: 1,
    address: '0x7409856cae628f5d578b285b45669b36e7005283',
    name: 'Jarvis Synthetic Br',
    symbol: 'JGBP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15726/thumb/logo_-_2021-05-22T063537.065.png?1621636572'
  },
  {
    chainId: 1,
    address: '0xbb1f24c0c1554b9990222f036b0aad6ee4caec29',
    name: 'CryptoSoul',
    symbol: 'SOUL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6723/thumb/cryptosoul.png?1547042952'
  },
  {
    chainId: 1,
    address: '0x9b75848172677042269c63365b57b0a51c21d031',
    name: 'Options Market',
    symbol: 'OSM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14562/thumb/OSM_200.png?1617015486'
  },
  {
    chainId: 1,
    address: '0x3080ec2a6960432f179c66d388099a48e82e2047',
    name: 'Popcorn Token',
    symbol: 'CORN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12408/thumb/n425bUN.png?1599625062'
  },
  {
    chainId: 1,
    address: '0x7dbdd9dafdc4c1c03d67925a4f85daa398af32b0',
    name: 'Anchor Neural World',
    symbol: 'ANW',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11104/thumb/logo_scan.png?1588567155'
  },
  {
    chainId: 1,
    address: '0xdcfe18bc46f5a0cd0d3af0c2155d2bcb5ade2fc5',
    name: 'Hue',
    symbol: 'HUE',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/10420/thumb/untitled.png?1579141360'
  },
  {
    chainId: 1,
    address: '0x1c4b7d0e1885bd7667af3378e0c538f74e712006',
    name: 'Playgroundz',
    symbol: 'IOG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6512/thumb/playgroundz.png?1548331384'
  },
  {
    chainId: 1,
    address: '0xf08c68bd5f4194d994fd70726746bf529ee5a617',
    name: 'Thorenext',
    symbol: 'THX',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/6838/thumb/Screenshot-2018-11-12-at-11-58-10-PM.png?1547043151'
  },
  {
    chainId: 1,
    address: '0xa6fb1df483b24eeab569e19447e0e107003b9e15',
    name: 'Earnbase',
    symbol: 'ENB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13164/thumb/YhCEGdB.png?1605775799'
  },
  {
    chainId: 1,
    address: '0x888888435fde8e7d4c54cab67f206e4199454c60',
    name: 'DFX Finance',
    symbol: 'DFX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14091/thumb/DFX.png?1614320944'
  },
  {
    chainId: 1,
    address: '0x8ba6dcc667d3ff64c1a2123ce72ff5f0199e5315',
    name: 'Alex',
    symbol: 'ALEX',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/10972/thumb/ALEX.png?1586742545'
  },
  {
    chainId: 1,
    address: '0x000000000000d0151e748d25b766e77efe2a6c83',
    name: 'XDEFI Governance To',
    symbol: 'XDEX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14485/thumb/logo.png?1616472896'
  },
  {
    chainId: 1,
    address: '0x1da01e84f3d4e6716f274c987ae4bee5dc3c8288',
    name: 'DeFi Bids',
    symbol: 'BID',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12344/thumb/IMG_20200919_115022_477.png?1600739441'
  },
  {
    chainId: 1,
    address: '0xa6714a2e5f0b1bdb97b895b0913b4fcd3a775e4d',
    name: 'PromotionChain',
    symbol: 'PC',
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/6630/thumb/2cs3_gfe_400x400.jpg?1547042831'
  },
  {
    chainId: 1,
    address: '0xf29e46887ffae92f1ff87dfe39713875da541373',
    name: 'UniCrypt  Old ',
    symbol: 'UNC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11782/thumb/200x200_%289%29.png?1593999474'
  },
  {
    chainId: 1,
    address: '0x3a73f6156c4fbc71b8fdf38090a9d99401163644',
    name: 'Lottonation',
    symbol: 'LNT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12448/thumb/lNSc7jtO_400x400_%281%29.jpg?1599903922'
  },
  {
    chainId: 1,
    address: '0xa283aa7cfbb27ef0cfbcb2493dd9f4330e0fd304',
    name: 'MM Token',
    symbol: 'MM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13302/thumb/MM.jpg?1607315862'
  },
  {
    chainId: 1,
    address: '0x16b0a1a87ae8af5c792fabc429c4fe248834842b',
    name: 'Algory',
    symbol: 'ALG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12231/thumb/logo-2.png?1605256312'
  },
  {
    chainId: 1,
    address: '0x63f584fa56e60e4d0fe8802b27c7e6e3b33e007f',
    name: 'ContentBox',
    symbol: 'BOX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4929/thumb/contentbox.png?1547740046'
  },
  {
    chainId: 1,
    address: '0x7d25d9f10cd224ecce0bc824a2ec800db81c01d7',
    name: 'ETHOPT',
    symbol: 'OPT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13424/thumb/KHP6ebV.jpeg?1608514202'
  },
  {
    chainId: 1,
    address: '0x431ad2ff6a9c365805ebad47ee021148d6f7dbe0',
    name: 'dForce Token',
    symbol: 'DF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9709/thumb/xlGxxIjI_400x400.jpg?1571006794'
  },
  {
    chainId: 1,
    address: '0x420ab548b18911717ed7c4ccbf46371ea758458c',
    name: 'NOODLE Finance',
    symbol: 'NOODLE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12311/thumb/noodle.jpg?1599053738'
  },
  {
    chainId: 1,
    address: '0x17ef75aa22dd5f6c2763b8304ab24f40ee54d48a',
    name: 'Revolution Populi',
    symbol: 'RVP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14772/thumb/vsd0Wnc2_400x400.png?1618369912'
  },
  {
    chainId: 1,
    address: '0xdc8af07a7861bedd104b8093ae3e9376fc8596d2',
    name: 'Rocket Vault',
    symbol: 'RVF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14728/thumb/7.png?1618414105'
  },
  {
    chainId: 1,
    address: '0xd27af03cb73a29ee2f37194c70c4ee13b68fe8cb',
    name: 'Freq Set Dollar',
    symbol: 'FSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13603/thumb/fsd.jpg?1610251925'
  },
  {
    chainId: 1,
    address: '0x5eaa69b29f99c84fe5de8200340b4e9b4ab38eac',
    name: 'Raze Network',
    symbol: 'RAZE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14767/thumb/raze_logo.png?1618309669'
  },
  {
    chainId: 1,
    address: '0x48fb253446873234f2febbf9bdeaa72d9d387f94',
    name: 'Bancor Governance T',
    symbol: 'VBNT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14053/thumb/bancorvbnt_32.png?1614048819'
  },
  {
    chainId: 1,
    address: '0xf83bf320a4a3f4bf365c3114b6f19fc3f6b1228c',
    name: 'FXT Token',
    symbol: 'FXT',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/14131/thumb/7rgEwxRT_400x400.jpg?1614588199'
  },
  {
    chainId: 1,
    address: '0xc22b30e4cce6b78aaaadae91e44e73593929a3e9',
    name: 'RAC',
    symbol: 'RAC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12675/thumb/rac_logo_%281%29.jpg?1601526417'
  },
  {
    chainId: 1,
    address: '0x53378825d95281737914a8a2ac0e5a9304ae5ed7',
    name: 'Samurai',
    symbol: 'SAM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12529/thumb/UkWCWt1.png?1600495292'
  },
  {
    chainId: 1,
    address: '0xf9209d900f7ad1dc45376a2caa61c78f6dea53b6',
    name: 'Lift Kitchen',
    symbol: 'LIFT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15601/thumb/lift.kitchen-logo.ee75a5f4.png?1621304520'
  },
  {
    chainId: 1,
    address: '0x4eeea7b48b9c3ac8f70a9c932a8b1e8a5cb624c7',
    name: 'Membrana',
    symbol: 'MBN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5117/thumb/membrana_logo.png?1565238625'
  },
  {
    chainId: 1,
    address: '0x70401dfd142a16dc7031c56e862fc88cb9537ce0',
    name: 'Bird Money',
    symbol: 'BIRD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13260/thumb/favicon-180x180.png?1611546646'
  },
  {
    chainId: 1,
    address: '0x2029017f38128bfefaa6c7b9cdd1b680ce8e5c03',
    name: 'Candy Protocol',
    symbol: 'CAD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14071/thumb/YMXZkwwS_400x400.png?1614149796'
  },
  {
    chainId: 1,
    address: '0xbc86727e770de68b1060c91f6bb6945c73e10388',
    name: 'Ink Protocol',
    symbol: 'XNK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3156/thumb/ink-protocol.png?1547974056'
  },
  {
    chainId: 1,
    address: '0xc242eb8e4e27eae6a2a728a41201152f19595c83',
    name: 'EcoFi',
    symbol: 'ECO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14803/thumb/logo_-_2021-04-16T081409.817.png?1618532056'
  },
  {
    chainId: 1,
    address: '0x42bedd647e387dabec65a7dc3a3babcc68bb664d',
    name: 'BlockMason Link',
    symbol: 'BLINK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8525/thumb/link-by-blockmason.jpg?1559103173'
  },
  {
    chainId: 1,
    address: '0x63120ccd7b415743e8753afd167f5ad4a1732c43',
    name: 'QANplatform',
    symbol: 'QARK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12443/thumb/v2mGPl9.png?1599902263'
  },
  {
    chainId: 1,
    address: '0xeaccb6e0f24d66cf4aa6cbda33971b9231d332a1',
    name: 'Polyient Games Gove',
    symbol: 'PGT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12709/thumb/Polyent.png?1601897060'
  },
  {
    chainId: 1,
    address: '0x6a27348483d59150ae76ef4c0f3622a78b0ca698',
    name: 'BeeKan   Beenews',
    symbol: 'BKBT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5004/thumb/beekan.png?1547040430'
  },
  {
    chainId: 1,
    address: '0x8d8129963291740dddd917ab01af18c7aed4ba58',
    name: 'MineBee',
    symbol: 'MB',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/9455/thumb/BWVFWd2btlmC4o6e6o5oTdChrbJOpr9MaNgOuJirFmkbSiZltqfyzSwFd2efv0FSNf3siXU1B8quEHTBX3VkJ8CNTEN16gEgKDr3RFjS7Fw4QdnnPMUBgKsTDjr_zL2vMw6erjtV_3dZyLSCpGJvF6IjoWdZUVTGBMEqEwGqhGSPsq_Qrdxzo4aIhJdhVtUIhHS9QDIWEmY52DyFZ.jpg?1567562959'
  },
  {
    chainId: 1,
    address: '0xb2f7eb1f2c37645be61d73953035360e768d81e6',
    name: 'Cobinhood',
    symbol: 'COB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/971/thumb/cobinhood.png?1547739572'
  },
  {
    chainId: 1,
    address: '0xfa93660c3f6a848556bb8e265f994160a1f2b289',
    name: 'Community Business ',
    symbol: 'CBT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14701/thumb/CBT-Token-Small.png?1617838284'
  },
  {
    chainId: 1,
    address: '0xdae6f68da3bab6866742a7f4050366f6ac48760d',
    name: 'GUSS ONE',
    symbol: 'GUSS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8509/thumb/gussone_mid.png?1559030931'
  },
  {
    chainId: 1,
    address: '0x3b544e6fcf6c8dce9d8b45a4fdf21c9b02f9fda9',
    name: 'Giftedhands',
    symbol: 'GHD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12540/thumb/K-8uHktJ.png?1600644856'
  },
  {
    chainId: 1,
    address: '0x07d9e49ea402194bf48a8276dafb16e4ed633317',
    name: 'Dalecoin',
    symbol: 'DALC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/932/thumb/dalecoin.png?1547791491'
  },
  {
    chainId: 1,
    address: '0x9af839687f6c94542ac5ece2e317daae355493a1',
    name: 'Hydro Protocol',
    symbol: 'HOT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2570/thumb/Hydro-Protocol.png?1558069424'
  },
  {
    chainId: 1,
    address: '0xc36c65693ea9e207ad35d4ee2fb6c5f6f43bdec8',
    name: 'Eauric',
    symbol: 'EAURIC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13013/thumb/logo_%2820%29.png?1604382710'
  },
  {
    chainId: 1,
    address: '0xd0d7a9f2021958e51d60d6966b7bbed9d1cb22b5',
    name: 'ENEX',
    symbol: 'ENX',
    decimals: 10,
    logoURI: 'https://assets.coingecko.com/coins/images/15070/thumb/ENEXpng200.png?1619621442'
  },
  {
    chainId: 1,
    address: '0xa3c4dc4a9ce2a6b40b57f25f8b50decc2c64dec2',
    name: 'SeedSwap',
    symbol: 'SNFT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15300/thumb/GN7ZskU.png?1620369261'
  },
  {
    chainId: 1,
    address: '0xc82eb6dea0c93edb8b697b89ad1b13d19469d635',
    name: 'rFIS',
    symbol: 'RFIS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14453/thumb/rFIS.png?1616660913'
  },
  {
    chainId: 1,
    address: '0xde1e0ae6101b46520cf66fdc0b1059c5cc3d106c',
    name: 'DeltaChain',
    symbol: 'DELTA',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/5794/thumb/deltachain.png?1547041664'
  },
  {
    chainId: 1,
    address: '0x380291a9a8593b39f123cf39cc1cc47463330b1f',
    name: 'Elite Swap',
    symbol: 'ELT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13715/thumb/logo_-_2021-01-20T105016.992.png?1611111027'
  },
  {
    chainId: 1,
    address: '0x8811e4dd5ec5eb8764b97cc814b1339089717ada',
    name: 'Bithachi',
    symbol: 'BITH',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11765/thumb/bith3.png?1593656342'
  },
  {
    chainId: 1,
    address: '0x34d6a0f5c2f5d0082141fe73d93b9dd00ca7ce11',
    name: 'Golden Token',
    symbol: 'GOLD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8939/thumb/4uf1Sim9_400x400.png?1563116366'
  },
  {
    chainId: 1,
    address: '0x56aa298a19c93c6801fdde870fa63ef75cc0af72',
    name: 'Mirrored Alibaba',
    symbol: 'MBABA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13647/thumb/mirror_logo_transparent.png?1611565672'
  },
  {
    chainId: 1,
    address: '0xa2d77f8353cb2afd709aba4a967257511ecff716',
    name: 'Internet Exchange T',
    symbol: 'INEX',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11017/thumb/ftQxvUbk_400x400.jpg?1587127361'
  },
  {
    chainId: 1,
    address: '0x417ffdbc285dd2c4dc00937798ab901634137caa',
    name: 'BlackFisk',
    symbol: 'BLFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13893/thumb/logo_%2891%29.png?1612561370'
  },
  {
    chainId: 1,
    address: '0xf9fbaefde7112f78fa9bfe813341f0f49f888cb3',
    name: 'DDS Store',
    symbol: 'DDS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13946/thumb/11qbAbzb_400x400.png?1613172588'
  },
  {
    chainId: 1,
    address: '0x9e976f211daea0d652912ab99b0dc21a7fd728e4',
    name: 'MAP Protocol',
    symbol: 'MAP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10085/thumb/MAP_LOGO.png?1597227221'
  },
  {
    chainId: 1,
    address: '0x6ca88cc8d9288f5cad825053b6a1b179b05c76fc',
    name: 'Universal Protocol ',
    symbol: 'UPT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10136/thumb/yS35aK0t_400x400_%281%29.jpg?1576191179'
  },
  {
    chainId: 1,
    address: '0x309627af60f0926daa6041b8279484312f2bf060',
    name: 'USD Bancor',
    symbol: 'USDB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10619/thumb/busd.png?1581026228'
  },
  {
    chainId: 1,
    address: '0x676a32b50e58924effad343f1d4d3c8dd0128889',
    name: '7Plus Coin',
    symbol: 'SV7',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14922/thumb/K-s1DLEA_400x400.jpg?1619059570'
  },
  {
    chainId: 1,
    address: '0x8e1b448ec7adfc7fa35fc2e885678bd323176e34',
    name: 'Egretia',
    symbol: 'EGT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3614/thumb/egretia.png?1547224764'
  },
  {
    chainId: 1,
    address: '0x6bc1f3a1ae56231dbb64d3e82e070857eae86045',
    name: 'Xensor',
    symbol: 'XSR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9224/thumb/Xensor_Logo_%28White__200x200px%29.png?1593570861'
  },
  {
    chainId: 1,
    address: '0xedb0414627e6f1e3f082de65cd4f9c693d78cca9',
    name: 'Mirrored Twitter',
    symbol: 'MTWTR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13639/thumb/mirror_logo_transparent.png?1611564718'
  },
  {
    chainId: 1,
    address: '0x05079687d35b93538cbd59fe5596380cae9054a9',
    name: 'BitSong',
    symbol: 'BTSG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5041/thumb/logo_-_2021-01-10T210801.390.png?1610284134'
  },
  {
    chainId: 1,
    address: '0x20f7a3ddf244dc9299975b4da1c39f8d5d75f05a',
    name: 'Sapien',
    symbol: 'SPN',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/2596/thumb/Sapien_Token_450x450.png?1607560493'
  },
  {
    chainId: 1,
    address: '0xb20043f149817bff5322f1b928e89abfc65a9925',
    name: 'EXRT Network',
    symbol: 'EXRT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/5954/thumb/exrt.png?1616039881'
  },
  {
    chainId: 1,
    address: '0x6fe56c0bcdd471359019fcbc48863d6c3e9d4f41',
    name: 'Props Token',
    symbol: 'PROPS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6735/thumb/photo-2017-10-10-03-32-02.png?1595168186'
  },
  {
    chainId: 1,
    address: '0x017b584acfd16d767541ae9e80cdc702f4527b0b',
    name: 'ASYAGRO',
    symbol: 'ASY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10632/thumb/c0Q4z8HJ_400x400.jpg?1581203764'
  },
  {
    chainId: 1,
    address: '0x73374ea518de7addd4c2b624c0e8b113955ee041',
    name: 'Juggernaut',
    symbol: 'JGN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12761/thumb/juggernaut_logo.png?1602428976'
  },
  {
    chainId: 1,
    address: '0xa9598333b99d14d90bc81cad8af82c4c70625e75',
    name: 'Metis',
    symbol: 'MTS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13218/thumb/1Vd_NuQU_400x400.jpg?1606292031'
  },
  {
    chainId: 1,
    address: '0x44440bd68b5e4b1e0cb810669097e9573175601b',
    name: 'Unicly The Day by A',
    symbol: 'UARC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15677/thumb/uarc.jpg?1621499433'
  },
  {
    chainId: 1,
    address: '0x212dd60d4bf0da8372fe8116474602d429e5735f',
    name: 'Stobox Token',
    symbol: 'STBU',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12637/thumb/logo200x200.png?1601363179'
  },
  {
    chainId: 1,
    address: '0x4ee438be38f8682abb089f2bfea48851c5e71eaf',
    name: 'Cryptonovae',
    symbol: 'YAE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14693/thumb/cryptonovae_cmc_logo.png?1617810908'
  },
  {
    chainId: 1,
    address: '0x30635297e450b930f8693297eba160d9e6c8ebcf',
    name: 'sUniswap',
    symbol: 'SUNI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15730/thumb/sUNI_final.PNG?1621670197'
  },
  {
    chainId: 1,
    address: '0xa211f450ce88deb31d3f12ae3c1ebf6b0e55a5d9',
    name: 'Parsiq Boost',
    symbol: 'PRQBOOST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13144/thumb/boost_logo.png?1605600652'
  },
  {
    chainId: 1,
    address: '0x3f4e02741b155f5ce8d6190d294d4f916125b896',
    name: 'CircleEx',
    symbol: 'CX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14181/thumb/lWlstjZM_400x400.png?1614820963'
  },
  {
    chainId: 1,
    address: '0xfb19c03a02a519a44542343803f3d42578cbc243',
    name: 'CR Coin',
    symbol: 'CRC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11883/thumb/pxXMTqA.png?1595741034'
  },
  {
    chainId: 1,
    address: '0xae1eaae3f627aaca434127644371b67b18444051',
    name: 'Yield Optimization ',
    symbol: 'YOP',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13678/thumb/J7zykPx.jpg?1610802162'
  },
  {
    chainId: 1,
    address: '0xa806b3fed6891136940cf81c4085661500aa2709',
    name: 'Sport and Leisure',
    symbol: 'SNL',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/7762/thumb/745ba052-73b7-4735-a0bf-e75474def055.png?1550456328'
  },
  {
    chainId: 1,
    address: '0x14dd7ebe6cb084cb73ef377e115554d47dc9d61e',
    name: 'ApeHaven',
    symbol: 'APES',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15735/thumb/apes.PNG?1621683822'
  },
  {
    chainId: 1,
    address: '0x1763ad73694d4d64fb71732b068e32ac72a345b1',
    name: 'BEE Coin',
    symbol: 'BEE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10853/thumb/Xg9uPrki_400x400.jpg?1585523253'
  },
  {
    chainId: 1,
    address: '0x64fb96d0395f6bf105f35233911e3df2c5bf4ce8',
    name: 'Elastic Bitcoin',
    symbol: 'XBT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13911/thumb/xbt-200x200.png?1612835246'
  },
  {
    chainId: 1,
    address: '0x226e390751a2e22449d611bac83bd267f2a2caff',
    name: 'STVKE',
    symbol: 'STV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13264/thumb/stvke_network.png?1606813287'
  },
  {
    chainId: 1,
    address: '0x973e52691176d36453868d9d86572788d27041a9',
    name: 'DxChain Token',
    symbol: 'DX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4700/thumb/VdZwy0Pv_400x400.png?1603089728'
  },
  {
    chainId: 1,
    address: '0xfd0df7b58bd53d1dd4835ecd69a703b4b26f7816',
    name: 'Moozicore',
    symbol: 'MZG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2314/thumb/mzi.png?1547036525'
  },
  {
    chainId: 1,
    address: '0x6cfb6df56bbdb00226aeffcdb2cd1fe8da1abda7',
    name: 'Komet',
    symbol: 'KOMET',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13196/thumb/komet_finance_logo.png?1606120745'
  },
  {
    chainId: 1,
    address: '0x72430a612adc007c50e3b6946dbb1bb0fd3101d1',
    name: 'Thingschain',
    symbol: 'TIC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/5320/thumb/thingschain-logo.png?1547040881'
  },
  {
    chainId: 1,
    address: '0xdfe66b14d37c77f4e9b180ceb433d1b164f0281d',
    name: 'StakeHound Staked E',
    symbol: 'STETH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13752/thumb/926MHi5g_400x400.png?1611542247'
  },
  {
    chainId: 1,
    address: '0xcb5f72d37685c3d5ad0bb5f982443bc8fcdf570e',
    name: 'Rootkit',
    symbol: 'ROOT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13151/thumb/rootkit_logo.png?1605712875'
  },
  {
    chainId: 1,
    address: '0x6faa826af0568d1866fca570da79b318ef114dab',
    name: 'B21',
    symbol: 'B21',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3510/thumb/B21_%283%29.png?1608706191'
  },
  {
    chainId: 1,
    address: '0x49e833337ece7afe375e44f4e3e8481029218e5c',
    name: 'Value DeFi',
    symbol: 'VALUE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12525/thumb/value_logo_-_500x500.png?1601478115'
  },
  {
    chainId: 1,
    address: '0x2167fb82309cf76513e83b25123f8b0559d6b48f',
    name: 'CoinLion',
    symbol: 'LION',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1917/thumb/Coinlion_Brandmark_Color.png?1620706193'
  },
  {
    chainId: 1,
    address: '0xb70835d7822ebb9426b56543e391846c107bd32c',
    name: 'Game',
    symbol: 'GTC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2046/thumb/game.png?1547742459'
  },
  {
    chainId: 1,
    address: '0x34bdf48a8f753de4822a6cfb1fee275f9b4d662e',
    name: 'FACTS',
    symbol: 'BKC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8461/thumb/sFNOkmVx_400x400.jpg?1558690624'
  },
  {
    chainId: 1,
    address: '0xea11755ae41d889ceec39a63e6ff75a02bc1c00d',
    name: 'Cortex',
    symbol: 'CTXC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3861/thumb/2638.png?1523930406'
  },
  {
    chainId: 1,
    address: '0xeef9f339514298c6a857efcfc1a762af84438dee',
    name: 'Hermez Network',
    symbol: 'HEZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12826/thumb/hermez_logo.png?1602826556'
  },
  {
    chainId: 1,
    address: '0x14eb60f5f270b059b0c788de0ddc51da86f8a06d',
    name: 'Phantasma Energy',
    symbol: 'KCAL',
    decimals: 10,
    logoURI: 'https://assets.coingecko.com/coins/images/12678/thumb/kcal-logo-coingecko.png?1602063865'
  },
  {
    chainId: 1,
    address: '0xdf6ef343350780bf8c3410bf062e0c015b1dd671',
    name: 'Blackmoon Crypto',
    symbol: 'BMC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/876/thumb/bmc.png?1547034698'
  },
  {
    chainId: 1,
    address: '0x32c868f6318d6334b2250f323d914bc2239e4eee',
    name: 'N3RD Finance',
    symbol: 'N3RDZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13389/thumb/logo.png?1608195840'
  },
  {
    chainId: 1,
    address: '0x10c71515602429c19d53011ea7040b87a4894838',
    name: 'Diamond Platform To',
    symbol: 'DPT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8309/thumb/RSCL14XL_400x400.jpg?1557400596'
  },
  {
    chainId: 1,
    address: '0x6a22e5e94388464181578aa7a6b869e00fe27846',
    name: 'sXAG',
    symbol: 'SXAG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11831/thumb/sXAG.png?1616150809'
  },
  {
    chainId: 1,
    address: '0x6c862f803ff42a97d4a483ab761256ad8c90f4f8',
    name: 'Elis',
    symbol: 'XLS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13613/thumb/Elis-Symbol-Green.png?1610285892'
  },
  {
    chainId: 1,
    address: '0xbc647aad10114b89564c0a7aabe542bd0cf2c5af',
    name: 'IONChain',
    symbol: 'IONC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6572/thumb/3PD7ycbU_400x400.jpg?1547042781'
  },
  {
    chainId: 1,
    address: '0x2baac9330cf9ac479d819195794d79ad0c7616e3',
    name: 'adbank',
    symbol: 'ADB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2806/thumb/adbank.png?1547036978'
  },
  {
    chainId: 1,
    address: '0xf784682c82526e245f50975190ef0fff4e4fc077',
    name: 'INLOCK',
    symbol: 'ILK',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9377/thumb/qlyyGGYI_400x400.jpg?1566774060'
  },
  {
    chainId: 1,
    address: '0x88dafebb769311d7fbbeb9a21431fa026d4100d0',
    name: 'Lux Expression',
    symbol: 'LUX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15720/thumb/lux_logo.jpg?1621605302'
  },
  {
    chainId: 1,
    address: '0x6a8c66cab4f766e5e30b4e9445582094303cc322',
    name: 'Farm Defi',
    symbol: 'PFARM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12957/thumb/PFARM_logo.png?1603837555'
  },
  {
    chainId: 1,
    address: '0x78e29d35573bea6265aedfcb9f45481b717ebfde',
    name: 'LINK Profit Taker S',
    symbol: 'LINKPT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11460/thumb/link_profit_taker_set.png?1590141836'
  },
  {
    chainId: 1,
    address: '0x6e10aacb89a28d6fa0fe68790777fec7e7f01890',
    name: 'SAV3',
    symbol: 'SAV3',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13132/thumb/sav3_logo.png?1605536471'
  },
  {
    chainId: 1,
    address: '0xf2354f740f31704820f6fcfba70b9da065459b62',
    name: 'Istardust',
    symbol: 'ISDT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12568/thumb/istardust.png?1600840530'
  },
  {
    chainId: 1,
    address: '0xd15ecdcf5ea68e3995b2d0527a0ae0a3258302f8',
    name: 'Machi X',
    symbol: 'MCX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11329/thumb/MachiX.png?1589926940'
  },
  {
    chainId: 1,
    address: '0x23352036e911a22cfc692b5e2e196692658aded9',
    name: 'Friendz',
    symbol: 'FDZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3685/thumb/download_%2812%29.png?1547038682'
  },
  {
    chainId: 1,
    address: '0x3930e4ddb4d24ef2f4cb54c1f009a3694b708428',
    name: 'Game Fanz',
    symbol: 'GFN',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/7165/thumb/gfn.png?1549539102'
  },
  {
    chainId: 1,
    address: '0x752efadc0a7e05ad1bcccda22c141d01a75ef1e4',
    name: 'CompliFi',
    symbol: 'COMFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15006/thumb/complifi.PNG?1619413977'
  },
  {
    chainId: 1,
    address: '0xdeeb6091a5adc78fa0332bee5a38a8908b6b566e',
    name: 'Taekwondo Access Cr',
    symbol: 'TAC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15094/thumb/tac.PNG?1619679735'
  },
  {
    chainId: 1,
    address: '0xee059f0ca1507e4e20c689b20cff71b5e924f7bd',
    name: 'Litecoin SV',
    symbol: 'LSV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11100/thumb/1GyOsGG.png?1588749283'
  },
  {
    chainId: 1,
    address: '0x8e57c27761ebbd381b0f9d09bb92ceb51a358abb',
    name: 'extraDNA',
    symbol: 'XDNA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12667/thumb/Logo_coin_black.png?1601463830'
  },
  {
    chainId: 1,
    address: '0x1cf402135d7bd27dc9d21c03ae2d8375bc43e9ec',
    name: 'Zodiac',
    symbol: 'ZDC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12245/thumb/zdc.PNG?1598485224'
  },
  {
    chainId: 1,
    address: '0x706cb9e741cbfee00ad5b3f5acc8bd44d1644a74',
    name: 'YFOX Finance',
    symbol: 'YFOX',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/12702/thumb/Yfox.png?1601865990'
  },
  {
    chainId: 1,
    address: '0xdf49c9f599a0a9049d97cff34d0c30e468987389',
    name: 'SaTT',
    symbol: 'SATT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4070/thumb/satt.png?1547039223'
  },
  {
    chainId: 1,
    address: '0xd82df0abd3f51425eb15ef7580fda55727875f14',
    name: 'DAV Network',
    symbol: 'DAV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2842/thumb/DAV-Network-logo.jpg?1547037011'
  },
  {
    chainId: 1,
    address: '0xcfd069247bd5f01a17f1ca76424c9e424256908d',
    name: 'EthereumSC',
    symbol: 'ETHSC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12167/thumb/avatar01.jpg?1597787790'
  },
  {
    chainId: 1,
    address: '0x884e3902c4d5cfa86de4ace7a96aa91ebc25c0ff',
    name: 'JBOX',
    symbol: 'JBX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3150/thumb/Untitled-design-1-removebg-preview-2.png?1598237569'
  },
  {
    chainId: 1,
    address: '0x923108a439c4e8c2315c4f6521e5ce95b44e9b4c',
    name: 'Devery',
    symbol: 'EVE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2681/thumb/devery.png?1547036885'
  },
  {
    chainId: 1,
    address: '0xe3f4b4a5d91e5cb9435b947f090a319737036312',
    name: 'Popchain',
    symbol: 'PCH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4533/thumb/popchain-logo.png?1547039828'
  },
  {
    chainId: 1,
    address: '0x208bbb6bcea22ef2011789331405347394ebaa51',
    name: '1AI',
    symbol: '1AI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10094/thumb/logo_%2832%29.png?1575922730'
  },
  {
    chainId: 1,
    address: '0x59e9261255644c411afdd00bd89162d09d862e38',
    name: 'ETHA Lend',
    symbol: 'ETHA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14141/thumb/etha_logo200x200.png?1614646986'
  },
  {
    chainId: 1,
    address: '0x7dd9c5cba05e151c895fde1cf355c9a1d5da6429',
    name: 'Golem',
    symbol: 'GLM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/542/thumb/Golem_Submark_Positive_RGB.png?1606392013'
  },
  {
    chainId: 1,
    address: '0x7e6c38d007740931e4b419bf15a68c79a0fb0c66',
    name: 'Unicly Doki Doki Co',
    symbol: 'UDOKI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14900/thumb/uDOKI.jpg?1618935840'
  },
  {
    chainId: 1,
    address: '0x5b26c5d0772e5bbac8b3182ae9a13f9bb2d03765',
    name: 'Education Ecosystem',
    symbol: 'LEDU',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/3451/thumb/LEDU-education-ecosystem.png?1547038165'
  },
  {
    chainId: 1,
    address: '0x2d48d03ff3389cc3546931efdff2799017f3420c',
    name: 'Apexel Natural Nano',
    symbol: 'ANN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10243/thumb/s3TuAv72_400x400.png?1576634197'
  },
  {
    chainId: 1,
    address: '0x2b6ff53fc2493ccd5202d80a6c439741414c5ff2',
    name: 'Tweebaa',
    symbol: 'TWEE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10419/thumb/tweeba.png?1616039461'
  },
  {
    chainId: 1,
    address: '0x7e9e431a0b8c4d532c745b1043c7fa29a48d4fba',
    name: 'eosDAC',
    symbol: 'EOSDAC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3878/thumb/2644.png?1547038931'
  },
  {
    chainId: 1,
    address: '0xe0c8b298db4cffe05d1bea0bb1ba414522b33c1b',
    name: 'Nuco Cloud',
    symbol: 'NCDT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8932/thumb/necocloud.png?1600380803'
  },
  {
    chainId: 1,
    address: '0x1c4481750daa5ff521a2a7490d9981ed46465dbd',
    name: 'Blockmason Credit P',
    symbol: 'BCPT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1022/thumb/mason.jpg?1547034948'
  },
  {
    chainId: 1,
    address: '0xd9485499499d66b175cf5ed54c0a19f1a6bcb61a',
    name: 'Usechain',
    symbol: 'USE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4318/thumb/logo_%281%29.png?1547039678'
  },
  {
    chainId: 1,
    address: '0x0763fdccf1ae541a5961815c0872a8c5bc6de4d7',
    name: 'SUKU',
    symbol: 'SUKU',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11969/thumb/UmfW5S6f_400x400.jpg?1596602238'
  },
  {
    chainId: 1,
    address: '0xde2f7766c8bf14ca67193128535e5c7454f8387c',
    name: 'Metadium',
    symbol: 'META',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5247/thumb/META_Logo_black.png?1548833790'
  },
  {
    chainId: 1,
    address: '0x361887c1d1b73557018c47c8001711168128cf69',
    name: 'Firdaos',
    symbol: 'FDO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13571/thumb/firdous.png?1609845693'
  },
  {
    chainId: 1,
    address: '0x3b3801f0fc76528e42390df701f513fc62cbf154',
    name: 'MONEY CASH MINER',
    symbol: 'MCM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8544/thumb/01.jpg?1559179506'
  },
  {
    chainId: 1,
    address: '0x8713d26637cf49e1b6b4a7ce57106aabc9325343',
    name: 'Content Neutrality ',
    symbol: 'CNN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2787/thumb/cnn_logo_big.png?1547036967'
  },
  {
    chainId: 1,
    address: '0xaff4abdc75f07387401ba9bc0f75ebe4c734b4c9',
    name: 'TheTimesChainCoin',
    symbol: 'TTC',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/9528/thumb/lD-E4KiHMPVbPJGUHBaOQlqOFE7NS0lwUmEyzGqNF5TPAOkizfiSOX7NOTjD4OJnGSYJIIJ7d1CmWS-AigN8Lxz6N3IC-wou1lESlbbu2krKaWlLdSyWrp8SUeji7HtOBM0uoEzNJWCyYgGYuk7pCq3KXZ5ppiH0ZjUVZcZAjJLax3S25_Qlh1RX21LiuWMttjqkD9irV8QQzSzib.jpg?1568613440'
  },
  {
    chainId: 1,
    address: '0x8933ea1ce67b946bdf2436ce860ffbb53ce814d2',
    name: 'LINK ETH RSI Ratio ',
    symbol: 'LINKETHRSI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10927/thumb/linketh_rsi_ratio.png?1585894605'
  },
  {
    chainId: 1,
    address: '0xa1afffe3f4d611d252010e3eaf6f4d77088b0cd7',
    name: 'reflect finance',
    symbol: 'RFI',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13189/thumb/reflect_finance_logo.png?1606098213'
  },
  {
    chainId: 1,
    address: '0x10bc518c32fbae5e38ecb50a612160571bd81e44',
    name: 'VeraOne',
    symbol: 'VRO',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11112/thumb/wsBaVF.png?1588639964'
  },
  {
    chainId: 1,
    address: '0x5b0751713b2527d7f002c0c4e2a37e1219610a6b',
    name: 'Ethorse',
    symbol: 'HORSE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2531/thumb/ethorse.png?1548125546'
  },
  {
    chainId: 1,
    address: '0x72b19558b05706708b612fbbe86b71446eafc002',
    name: 'Unicly Formula REVV',
    symbol: 'UREVV',
    decimals: 14,
    logoURI: 'https://assets.coingecko.com/coins/images/15678/thumb/urevv.jpg?1621499895'
  },
  {
    chainId: 1,
    address: '0x23ccc43365d9dd3882eab88f43d515208f832430',
    name: 'Midas Protocol',
    symbol: 'MAS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4287/thumb/Bestand_000.png?1607560518'
  },
  {
    chainId: 1,
    address: '0xfbe878ced08132bd8396988671b450793c44bc12',
    name: 'Fox Trading Token',
    symbol: 'FOXT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5182/thumb/foxtrading-logo.png?1547040619'
  },
  {
    chainId: 1,
    address: '0x793786e2dd4cc492ed366a94b88a3ff9ba5e7546',
    name: 'Axia',
    symbol: 'AXIAV3',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12906/thumb/axia_200x200.png?1613988058'
  },
  {
    chainId: 1,
    address: '0x0fcbc31c503b4a9ed90e87f8ff46c318a4a14260',
    name: 'Quantfury',
    symbol: 'QTF',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/14033/thumb/FtSOX9Vy_400x400.jpg?1613946524'
  },
  {
    chainId: 1,
    address: '0x191557728e4d8caa4ac94f86af842148c0fa8f7e',
    name: 'Ormeus Ecosystem',
    symbol: 'ECO',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/8923/thumb/logo_eco_low.png?1562902804'
  },
  {
    chainId: 1,
    address: '0xf3ae5d769e153ef72b4e3591ac004e89f48107a1',
    name: 'Deeper Network',
    symbol: 'DPR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14748/thumb/deeper.png?1618094356'
  },
  {
    chainId: 1,
    address: '0x0e2ef8aecb3c01ad5d596f1b67134e178199984d',
    name: 'LandBox',
    symbol: 'LAND',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14875/thumb/jmKvZDn7_400x400.jpg?1618826129'
  },
  {
    chainId: 1,
    address: '0x0e99cc0535bb6251f6679fa6e65d6d3b430e840b',
    name: 'Mirrored Facebook',
    symbol: 'MFB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14020/thumb/mirror_logo_transparent.png?1613718368'
  },
  {
    chainId: 1,
    address: '0x37611b28aca5673744161dc337128cfdd2657f69',
    name: 'Goat Coin',
    symbol: 'GOAT',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15525/thumb/qqked5P.png?1621126222'
  },
  {
    chainId: 1,
    address: '0x80bb277f4355a43cdbb86a82f9876c946476d9eb',
    name: 'DogDeFiCoin',
    symbol: 'DOGDEFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12912/thumb/dogdefi_logo.jpg?1603425386'
  },
  {
    chainId: 1,
    address: '0xc55c2175e90a46602fd42e931f62b3acc1a013ca',
    name: 'Mogul Productions',
    symbol: 'STARS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14975/thumb/STARS_LOGO_PNG.png?1619214520'
  },
  {
    chainId: 1,
    address: '0x2e2364966267b5d7d2ce6cd9a9b5bd19d9c7c6a9',
    name: 'Voice Token',
    symbol: 'VOICE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12926/thumb/OjCQtdL.png?1614780024'
  },
  {
    chainId: 1,
    address: '0xbe30f684d62c9f7883a75a29c162c332c0d98f23',
    name: 'Global Human Trust',
    symbol: 'GHT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12040/thumb/WechatIMG6648.png?1596700737'
  },
  {
    chainId: 1,
    address: '0xdb2f2bcce3efa95eda95a233af45f3e0d4f00e2a',
    name: 'Aegis',
    symbol: 'AGS',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12391/thumb/logo-3.png?1599540071'
  },
  {
    chainId: 1,
    address: '0x0fe629d1e84e171f8ff0c1ded2cc2221caa48a3f',
    name: 'NFTX Hashmasks Inde',
    symbol: 'MASK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13873/thumb/nftx.png?1612448203'
  },
  {
    chainId: 1,
    address: '0x63d0eea1d7c0d1e89d7e665708d7e8997c0a9ed6',
    name: 'Ethanol',
    symbol: 'ENOL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13214/thumb/hV_w1e5G_400x400.png?1606292732'
  },
  {
    chainId: 1,
    address: '0x7eaf9c89037e4814dc0d9952ac7f888c784548db',
    name: 'Royale',
    symbol: 'ROYA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13602/thumb/royale_logo.jpg?1610176118'
  },
  {
    chainId: 1,
    address: '0x996dc5dfc819408dd98cd92c9a76f64b0738dc3d',
    name: 'Skillchain',
    symbol: 'SKI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3780/thumb/SKI.png?1589121168'
  },
  {
    chainId: 1,
    address: '0xf3924df14812b3d3db29124aa9da4353ed5e04a3',
    name: 'Easticoin',
    symbol: 'ESTI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12988/thumb/EST2.png?1604093746'
  },
  {
    chainId: 1,
    address: '0x897abf83f0c44b86cb67ec56c006c00d56659517',
    name: 'Arteon',
    symbol: 'ARTEON',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14771/thumb/arteon_200x200.png?1618369416'
  },
  {
    chainId: 1,
    address: '0x7c3cf2d43b50e78e27a81293a43fcac45796fb54',
    name: 'Kong Defi',
    symbol: 'KONG',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14490/thumb/IMG_4643.png?1620025009'
  },
  {
    chainId: 1,
    address: '0xf1290473e210b2108a85237fbcd7b6eb42cc654f',
    name: 'HedgeTrade',
    symbol: 'HEDG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7196/thumb/to3Vj4EZ_400x400.jpg?1547043758'
  },
  {
    chainId: 1,
    address: '0xea3cb156745a8d281a5fc174186c976f2dd04c2e',
    name: 'Nobrainer Finance',
    symbol: 'BRAIN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12778/thumb/brain_logo.jpg?1602493938'
  },
  {
    chainId: 1,
    address: '0x67a9099f0008c35c61c00042cd9fb03684451097',
    name: 'Game Stars',
    symbol: 'GST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4075/thumb/game-stars.png?1548126796'
  },
  {
    chainId: 1,
    address: '0xeed3ae7b0f8b5b9bb8c035a9941382b1822671cd',
    name: 'EveryCoin',
    symbol: 'EVY',
    decimals: 12,
    logoURI: 'https://assets.coingecko.com/coins/images/5629/thumb/TutkUC1x_400x400_%281%29.jpg?1547447105'
  },
  {
    chainId: 1,
    address: '0x9ea1ae46c15a4164b74463bc26f8aa3b0eea2e6e',
    name: 'MU DANK',
    symbol: 'DANK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14423/thumb/photo_2021-03-31_16-05-34.jpg?1617359813'
  },
  {
    chainId: 1,
    address: '0xe477292f1b3268687a29376116b0ed27a9c76170',
    name: 'HEROcoin',
    symbol: 'PLAY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2221/thumb/herocoin.png?1547744781'
  },
  {
    chainId: 1,
    address: '0x8b6dd24bcb2d0aea92c3abd4eb11103a5db6d714',
    name: 'dXIOT',
    symbol: 'DXIOT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12850/thumb/dxiot.png?1603074874'
  },
  {
    chainId: 1,
    address: '0x7d8daff6d70cead12c6f077048552cf89130a2b1',
    name: 'ARCS',
    symbol: 'ARX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10068/thumb/arcs.png?1575426741'
  },
  {
    chainId: 1,
    address: '0x69948cc03f478b95283f7dbf1ce764d0fc7ec54c',
    name: 'Aave REN v1',
    symbol: 'AREN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12483/thumb/aREN.png?1600188492'
  },
  {
    chainId: 1,
    address: '0xc1eecf1f4af8eb9a2a19f6c26b434aa96ce859e1',
    name: 'SaveToken',
    symbol: 'SAVE',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/5141/thumb/5HqoBUuh_400x400.png?1578524644'
  },
  {
    chainId: 1,
    address: '0x0c3ef32f802967db75b9d49fe1e76620151ccb81',
    name: 'Whole Network',
    symbol: 'NODE',
    decimals: 5,
    logoURI:
      'https://assets.coingecko.com/coins/images/9522/thumb/61410314_394870017826322_8137973916426567680_n.png?1568604555'
  },
  {
    chainId: 1,
    address: '0x05919a3915462abbdf2cd3c5b42213cc8f596102',
    name: 'FinanceX Exchange T',
    symbol: 'FNXS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10368/thumb/6Rf6xXX.png?1578673739'
  },
  {
    chainId: 1,
    address: '0xea3983fc6d0fbbc41fb6f6091f68f3e08894dc06',
    name: 'Unido',
    symbol: 'UDO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14176/thumb/unido.jpg?1614792353'
  },
  {
    chainId: 1,
    address: '0xff44b5719f0b77a9951636fc5e69d3a1fc9e7d73',
    name: '4ART Coin',
    symbol: '4ART',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4690/thumb/0_%284%29.png?1595544690'
  },
  {
    chainId: 1,
    address: '0x041fdd6637ecfd96af8804278ac12660ac2d12c0',
    name: 'SwapDEX',
    symbol: 'SDX',
    decimals: 7,
    logoURI: 'https://assets.coingecko.com/coins/images/13717/thumb/sdx.png?1611116537'
  },
  {
    chainId: 1,
    address: '0x679fa6dc913acab6def33ec469fc6e421bc794f5',
    name: 'Bribe Protocol',
    symbol: 'BRIBE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14967/thumb/bribe.PNG?1619165925'
  },
  {
    chainId: 1,
    address: '0x2e1e15c44ffe4df6a0cb7371cd00d5028e571d14',
    name: 'Mettalex',
    symbol: 'MTLX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12730/thumb/nrEqFTEW_400x400.jpg?1602063980'
  },
  {
    chainId: 1,
    address: '0xe5aee163513119f4f750376c718766b40fa37a5f',
    name: 'Frozencoin Network',
    symbol: 'FZ',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/8660/thumb/TytBk3jpXDsoyetqedmH7u_OOTG1WE8jTNLCkeE80GkC3_O716EOPdHs6ZK337RgqOkcpJukBJjT0pdgOX5IXUp2FZu2vzQ2CMyR0jGsgjCPFAYVi1X1ocIBlxa7TKSaazQQEUFkVnTuzjaDHtpwf6EYNrVV-dtpKx6aT0c0Jqe8bVPtoZN9i93xJTgAyX3aQs4YVmPVUZtKfopqR.jpg?1560155321'
  },
  {
    chainId: 1,
    address: '0x7d36cce46dd2b0d28dde12a859c2ace4a21e3678',
    name: 'Combine finance',
    symbol: 'COMB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12592/thumb/combine_finance_logo.jpg?1602417055'
  },
  {
    chainId: 1,
    address: '0x63b8b7d4a3efd0735c4bffbd95b332a55e4eb851',
    name: 'DigiCol Token',
    symbol: 'DGCL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13519/thumb/DigiCol_Logo-01.png?1609372199'
  },
  {
    chainId: 1,
    address: '0x8052327f1baf94a9dc8b26b9100f211ee3774f54',
    name: 'A2DAO',
    symbol: 'ATD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14509/thumb/96YRRLDc_400x400.jpg?1616580742'
  },
  {
    chainId: 1,
    address: '0x3d371413dd5489f3a04c07c0c2ce369c20986ceb',
    name: 'YOUcash',
    symbol: 'YOUC',
    decimals: 10,
    logoURI: 'https://assets.coingecko.com/coins/images/11152/thumb/round-400x400.png?1589162715'
  },
  {
    chainId: 1,
    address: '0x0def8d8adde14c9ef7c2a986df3ea4bd65826767',
    name: 'DefiCliq',
    symbol: 'CLIQ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13242/thumb/deficliq_logo.png?1606660146'
  },
  {
    chainId: 1,
    address: '0x7bebd226154e865954a87650faefa8f485d36081',
    name: 'Zignaly',
    symbol: 'ZIG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14796/thumb/zignaly.jpg?1618496339'
  },
  {
    chainId: 1,
    address: '0x107c4504cd79c5d2696ea0030a8dd4e92601b82e',
    name: 'Bloom',
    symbol: 'BLT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2662/thumb/bloom.png?1547036854'
  },
  {
    chainId: 1,
    address: '0xdbf637f78624f896b92f801e81f6031b7865ed20',
    name: '3X Short BitMax Tok',
    symbol: 'BTMXBEAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10257/thumb/683JEXMN_400x400_%281%29.png?1576677290'
  },
  {
    chainId: 1,
    address: '0xd9a947789974bad9be77e45c2b327174a9c59d71',
    name: 'Ystar',
    symbol: 'YSR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11575/thumb/256_256.png?1600495476'
  },
  {
    chainId: 1,
    address: '0xb3104b4b9da82025e8b9f8fb28b3553ce2f67069',
    name: 'Bibox Token',
    symbol: 'BIX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1441/thumb/bibox-token.png?1547035519'
  },
  {
    chainId: 1,
    address: '0x9d1a62c2ad99019768b9126fda004a9952853f6e',
    name: '3X Long BNB Token',
    symbol: 'BNBBULL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10218/thumb/683JEXMN_400x400_%281%29.png?1576576413'
  },
  {
    chainId: 1,
    address: '0x94ca37d108e89775dc8ae65f51ae28c2d9599f9a',
    name: 'Cryptotipsfr',
    symbol: 'CRTS',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/10874/thumb/CRTS.png?1613661396'
  },
  {
    chainId: 1,
    address: '0x1f3f677ecc58f6a1f9e2cf410df4776a8546b5de',
    name: 'VNDC',
    symbol: 'VNDC',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/9670/thumb/vndc-gold-coin.png?1571032826'
  },
  {
    chainId: 1,
    address: '0xcc34366e3842ca1bd36c1f324d15257960fcc801',
    name: 'Bonpay',
    symbol: 'BON',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1314/thumb/bonpay.png?1547563439'
  },
  {
    chainId: 1,
    address: '0x925f2c11b99c1a4c46606898ee91ed3d450cfeda',
    name: 'Chow Chow Finance',
    symbol: 'CHOW',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14275/thumb/chow-logo.png?1620787242'
  },
  {
    chainId: 1,
    address: '0x202f1877e1db1120ca3e9a98c5d505e7f035c249',
    name: 'ZUZ Protocol',
    symbol: 'ZUZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14444/thumb/ZUZ_Icon2-100.png?1616115188'
  },
  {
    chainId: 1,
    address: '0x0e3cc2c4fb9252d17d07c67135e48536071735d9',
    name: 'ARTH',
    symbol: 'ARTH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13690/thumb/arth_logo.jpeg?1610956950'
  },
  {
    chainId: 1,
    address: '0xf8c595d070d104377f58715ce2e6c93e49a87f3c',
    name: 'DACC',
    symbol: 'DACC',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/4781/thumb/dacc-token.png?1547040122'
  },
  {
    chainId: 1,
    address: '0xe88f8313e61a97cec1871ee37fbbe2a8bf3ed1e4',
    name: 'Sora Validator Toke',
    symbol: 'VAL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13299/thumb/val-gold-256.png?1607242927'
  },
  {
    chainId: 1,
    address: '0x1412f6aa5adc77c620715bb2a020aa690b85f68a',
    name: 'MargiX',
    symbol: 'MGX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9865/thumb/MGX_Logo.png?1603614181'
  },
  {
    chainId: 1,
    address: '0x16558553e4647ca500c3718c56c356edb6f9b11c',
    name: 'Monkey King Token',
    symbol: 'MKT',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/12283/thumb/footerlogo.png?1598759253'
  },
  {
    chainId: 1,
    address: '0x170b275ced089fffaebfe927f445a350ed9160dc',
    name: 'OWNDATA',
    symbol: 'OWN',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/3903/thumb/owndata.png?1548330403'
  },
  {
    chainId: 1,
    address: '0xe63d6b308bce0f6193aec6b7e6eba005f41e36ab',
    name: 'Stone Token',
    symbol: 'STN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14593/thumb/stone-logo.png?1617164796'
  },
  {
    chainId: 1,
    address: '0x2c9023bbc572ff8dc1228c7858a280046ea8c9e5',
    name: 'VideoCoin',
    symbol: 'VID',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3376/thumb/Dark.png?1589435901'
  },
  {
    chainId: 1,
    address: '0x38405fa410c6eba342f9eb5ac66b2aaf6498c8e9',
    name: 'Vectoraic',
    symbol: 'VT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4170/thumb/vt.png?1547039433'
  },
  {
    chainId: 1,
    address: '0x5a98fcbea516cf06857215779fd812ca3bef1b32',
    name: 'Lido DAO',
    symbol: 'LDO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13573/thumb/Lido_DAO.png?1609873644'
  },
  {
    chainId: 1,
    address: '0x746dda2ea243400d5a63e0700f190ab79f06489e',
    name: 'BOSAGORA',
    symbol: 'BOA',
    decimals: 7,
    logoURI: 'https://assets.coingecko.com/coins/images/9202/thumb/boaLogo.png?1565231346'
  },
  {
    chainId: 1,
    address: '0xe63684bcf2987892cefb4caa79bd21b34e98a291',
    name: 'Chicken',
    symbol: 'KFC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12569/thumb/download.png?1600840301'
  },
  {
    chainId: 1,
    address: '0x68bb81b3f67f7aab5fd1390ecb0b8e1a806f2465',
    name: 'NFT Platform Index',
    symbol: 'NFTP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14543/thumb/nftp-light-circular.c54a6825.png?1616935648'
  },
  {
    chainId: 1,
    address: '0x28c8d01ff633ea9cd8fc6a451d7457889e698de6',
    name: 'Ethereum Gold',
    symbol: 'ETG',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/1034/thumb/ethereum-gold.png?1547394319'
  },
  {
    chainId: 1,
    address: '0x72c9fb7ed19d3ce51cea5c56b3e023cd918baadf',
    name: 'AGROLOT',
    symbol: 'AGLT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4464/thumb/aglt.png?1547039784'
  },
  {
    chainId: 1,
    address: '0xe5dada80aa6477e85d09747f2842f7993d0df71c',
    name: 'Dock',
    symbol: 'DOCK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3978/thumb/2675.png?1547039034'
  },
  {
    chainId: 1,
    address: '0x84c722e6f1363e8d5c6db3ea600bef9a006da824',
    name: 'Misbloc',
    symbol: 'MSB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13021/thumb/xREV4lXV.png?1604396822'
  },
  {
    chainId: 1,
    address: '0x14cfc7aeaa468e8c789785c39e0b753915aeb426',
    name: 'FrogDAO Dime',
    symbol: 'FDD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14576/thumb/fdd_logo.jpg?1617092745'
  },
  {
    chainId: 1,
    address: '0x5b71bee9d961b1b848f8485eec8d8787f80217f5',
    name: 'Bitforex Token',
    symbol: 'BF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5330/thumb/BitForex-Logo.png?1573808168'
  },
  {
    chainId: 1,
    address: '0xe0d95530820aafc51b1d98023aa1ff000b78d8b2',
    name: 'PressOne',
    symbol: 'PRS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2641/thumb/pressone.png?1547036817'
  },
  {
    chainId: 1,
    address: '0x40e45890dff79e7d533797d964e64a2c0121f49a',
    name: 'Smart Trade Coin',
    symbol: 'TRADE',
    decimals: 7,
    logoURI: 'https://assets.coingecko.com/coins/images/6829/thumb/small_trade_260d15db8a_400c35fc3e.png?1620782686'
  },
  {
    chainId: 1,
    address: '0xf7e04d8a32229b4ca63aa51eea9979c7287fea48',
    name: 'Beowulf',
    symbol: 'BWF',
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/12586/thumb/BWF.png?1600932145'
  },
  {
    chainId: 1,
    address: '0x6f0f28ceee6ae686ee0f939375674c01b156365a',
    name: 'Elena Protocol',
    symbol: 'ELENA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15078/thumb/elena.png?1619648619'
  },
  {
    chainId: 1,
    address: '0x685aea4f02e39e5a5bb7f7117e88db1151f38364',
    name: 'Shill',
    symbol: 'POSH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12548/thumb/alone.png?1600676559'
  },
  {
    chainId: 1,
    address: '0x0bfec35a1a3550deed3f6fc76dde7fc412729a91',
    name: 'xKNCa',
    symbol: 'XKNCA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13995/thumb/Token-4.png?1613545763'
  },
  {
    chainId: 1,
    address: '0x55a290f08bb4cae8dcf1ea5635a3fcfd4da60456',
    name: 'BITTO',
    symbol: 'BITTO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2814/thumb/BITTO.jpg?1547036986'
  },
  {
    chainId: 1,
    address: '0x99c6e435ec259a7e8d65e1955c9423db624ba54c',
    name: 'Finminity',
    symbol: 'FMT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14696/thumb/finminity.png?1617834914'
  },
  {
    chainId: 1,
    address: '0x749826f1041caf0ea856a4b3578ba327b18335f8',
    name: 'TIG Token',
    symbol: 'TIG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1903/thumb/tigereum.png?1548758748'
  },
  {
    chainId: 1,
    address: '0x1212b68d8555cf0b08f3371b926b3e1148b520f1',
    name: 'Efficient Transacti',
    symbol: 'ETT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9110/thumb/9bb9b46b4070efdf9713194ed98b4ec7.png?1564434751'
  },
  {
    chainId: 1,
    address: '0x6399c842dd2be3de30bf99bc7d1bbf6fa3650e70',
    name: 'Premia',
    symbol: 'PREMIA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13962/thumb/photo_2021-02-19_10-51-08.jpg?1613703321'
  },
  {
    chainId: 1,
    address: '0x41dbecc1cdc5517c6f76f6a6e836adbee2754de3',
    name: 'Medicalchain',
    symbol: 'MTN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2850/thumb/medicalchain.png?1547037019'
  },
  {
    chainId: 1,
    address: '0xef7a985e4ff9b5dccd6eddf58577486887288711',
    name: 'HOM Token',
    symbol: 'HOMT',
    decimals: 15,
    logoURI: 'https://assets.coingecko.com/coins/images/11597/thumb/HOM-Token-Logo.jpg?1591668252'
  },
  {
    chainId: 1,
    address: '0xaf9f549774ecedbd0966c52f250acc548d3f36e5',
    name: 'RioDeFi',
    symbol: 'RFUEL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12623/thumb/RFUEL_SQR.png?1602481093'
  },
  {
    chainId: 1,
    address: '0x78b7fada55a64dd895d8c8c35779dd8b67fa8a05',
    name: 'Atlant',
    symbol: 'ATL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1108/thumb/atlant.png?1547035089'
  },
  {
    chainId: 1,
    address: '0xe225aca29524bb65fd82c79a9602f3b4f9c6fe3f',
    name: 'Tratok',
    symbol: 'TRAT',
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/6791/thumb/tratok.png?1547043075'
  },
  {
    chainId: 1,
    address: '0xf6537fe0df7f0cc0985cf00792cc98249e73efa0',
    name: 'GIV Token',
    symbol: 'GIV',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/8996/thumb/giv.png?1596069222'
  },
  {
    chainId: 1,
    address: '0xac6fe9aa6b996d15f23e2e9a384fe64607bba7d5',
    name: 'Ramifi Protocol',
    symbol: 'RAM',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14602/thumb/76572462.png?1618003618'
  },
  {
    chainId: 1,
    address: '0x5d45aa01b73c971c65f3df409c9b3627b8fe2726',
    name: 'Timecoin Protocol',
    symbol: 'TMCN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13175/thumb/TimeCoin.png?1605877705'
  },
  {
    chainId: 1,
    address: '0x0329d23fc7b1b1e6cca57afa3f0090f1189069e8',
    name: 'LINK RSI Crossover ',
    symbol: 'LINKRSICO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10723/thumb/link_rsi_set.png?1582614765'
  },
  {
    chainId: 1,
    address: '0x6393e822874728f8afa7e1c9944e417d37ca5878',
    name: 'Cryptoindex com 100',
    symbol: 'CIX100',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6106/thumb/cix.png?1563780642'
  },
  {
    chainId: 1,
    address: '0x1977c795b5f52bf6f8150136b07822d1f00704f3',
    name: 'Mysterious Sound',
    symbol: 'MST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10757/thumb/icon01.png?1583150951'
  },
  {
    chainId: 1,
    address: '0x617aecb6137b5108d1e7d4918e3725c8cebdb848',
    name: 'sBNB',
    symbol: 'SBNB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8844/thumb/sBNB.png?1616148783'
  },
  {
    chainId: 1,
    address: '0x40fd72257597aa14c7231a7b1aaa29fce868f677',
    name: 'Sora',
    symbol: 'XOR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11093/thumb/sora_logo_cg_white.png?1588284194'
  },
  {
    chainId: 1,
    address: '0xe884cc2795b9c45beeac0607da9539fd571ccf85',
    name: 'Ultiledger',
    symbol: 'ULT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6747/thumb/Ultiledger.jpeg?1547042996'
  },
  {
    chainId: 1,
    address: '0xa0d440c6da37892dc06ee7930b2eede0634fd681',
    name: 'Masternet',
    symbol: 'MASH',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/5879/thumb/masternet.png?1547978307'
  },
  {
    chainId: 1,
    address: '0xf091cf09c51811819db705710e9634b8bf18f164',
    name: 'Couchain',
    symbol: 'COU',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4529/thumb/couchain.png?1547039825'
  },
  {
    chainId: 1,
    address: '0x2d0e95bd4795d7ace0da3c0ff7b706a5970eb9d3',
    name: 'All Sports',
    symbol: 'SOC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2697/thumb/all-sports.png?1547036904'
  },
  {
    chainId: 1,
    address: '0xb0280743b44bf7db4b6be482b2ba7b75e5da096c',
    name: 'Transcodium',
    symbol: 'TNS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2071/thumb/transcodium.png?1548759093'
  },
  {
    chainId: 1,
    address: '0x45080a6531d671ddff20db42f93792a489685e32',
    name: 'Finance Vote',
    symbol: 'FVT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13181/thumb/finance.png?1606015010'
  },
  {
    chainId: 1,
    address: '0x558ec3152e2eb2174905cd19aea4e34a23de9ad6',
    name: 'Bread',
    symbol: 'BRD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1440/thumb/bread.png?1547563238'
  },
  {
    chainId: 1,
    address: '0x9cea2ed9e47059260c97d697f82b8a14efa61ea5',
    name: 'Punk',
    symbol: 'PUNK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13704/thumb/Screenshot_2021-01-19_at_3.27.50_PM.png?1611041655'
  },
  {
    chainId: 1,
    address: '0xfd62247943f94c3910a4922af2c62c2d3fac2a8f',
    name: 'BTEcoin',
    symbol: 'BTE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10736/thumb/read.jpg?1582701685'
  },
  {
    chainId: 1,
    address: '0xa7de087329bfcda5639247f96140f9dabe3deed1',
    name: 'Statera',
    symbol: 'STA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11472/thumb/Statera.png?1590415353'
  },
  {
    chainId: 1,
    address: '0x3f09400313e83d53366147e3ea0e4e2279d80850',
    name: 'Kush Finance',
    symbol: 'KSEED',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12545/thumb/kush.finance-512.png?1600844515'
  },
  {
    chainId: 1,
    address: '0x05d3606d5c81eb9b7b18530995ec9b29da05faba',
    name: 'TomoChain ERC 20',
    symbol: 'TOMOE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12646/thumb/tomoe_logo.png?1601377449'
  },
  {
    chainId: 1,
    address: '0xe081b71ed098fbe1108ea48e235b74f122272e68',
    name: 'GOLD',
    symbol: 'GOLD',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/7118/thumb/rRwrrUW7_400x400.jpg?1547043615'
  },
  {
    chainId: 1,
    address: '0x0ebd5ec91680d3b0cedbb1d5bb61851154d3edb6',
    name: 'Token of Power',
    symbol: 'TOP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14623/thumb/top.jpeg?1617279944'
  },
  {
    chainId: 1,
    address: '0x3678d8cc9eb08875a3720f34c1c8d1e1b31f5a11',
    name: 'Obee Network',
    symbol: 'OBEE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12326/thumb/ObeeNetwork.png?1599099616'
  },
  {
    chainId: 1,
    address: '0xec681f28f4561c2a9534799aa38e0d36a83cf478',
    name: 'YVS Finance',
    symbol: 'YVS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13471/thumb/cu0LSzE.png?1608852718'
  },
  {
    chainId: 1,
    address: '0xa89ac6e529acf391cfbbd377f3ac9d93eae9664e',
    name: 'Keep4r',
    symbol: 'KP4R',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13006/thumb/kp4r.png?1604368813'
  },
  {
    chainId: 1,
    address: '0x7713bcda5106424ff6b0ae1e7fa05c3f4ae3ecb0',
    name: 'Grafsound',
    symbol: 'GSMT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12583/thumb/Logo200_200-GSMT.png?1600927635'
  },
  {
    chainId: 1,
    address: '0x7cda79830faf07ed696fe220566116951ced36a7',
    name: 'Maya Preferred',
    symbol: 'MAYP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8081/thumb/2u0OXhT9_400x400.png?1554426529'
  },
  {
    chainId: 1,
    address: '0x3a8cccb969a61532d1e6005e2ce12c200caece87',
    name: 'TitanSwap',
    symbol: 'TITAN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12606/thumb/nqGlQzdz_400x400.png?1601019805'
  },
  {
    chainId: 1,
    address: '0x43e6228b5bf22eab754486082ca91fdd8585521a',
    name: 'Dixt Finance',
    symbol: 'DIXT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13466/thumb/20201224_102613.png?1608794672'
  },
  {
    chainId: 1,
    address: '0x40d1f63b5d2048e67e9bedb1b4c2f1a9fb4b6817',
    name: 'Golden Goose',
    symbol: 'GOLD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7892/thumb/tsRcntSR_400x400.png?1611024068'
  },
  {
    chainId: 1,
    address: '0xe796d6ca1ceb1b022ece5296226bf784110031cd',
    name: 'Blind Boxes',
    symbol: 'BLES',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14537/thumb/BlindBoxes_logo_fuschia.png?1618300494'
  },
  {
    chainId: 1,
    address: '0x33e07f5055173cf8febede8b21b12d1e2b523205',
    name: 'Etherland',
    symbol: 'ELAND',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14432/thumb/eland.png?1616044253'
  },
  {
    chainId: 1,
    address: '0x13119e34e140097a507b07a5564bde1bc375d9e6',
    name: 'MoneyToken',
    symbol: 'IMT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2955/thumb/imt.png?1547037191'
  },
  {
    chainId: 1,
    address: '0x0cc9fccff81252f4bd8c5c6b359b14ae2ed851cf',
    name: 'InnovativeBioresear',
    symbol: 'INNBCL',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/7911/thumb/INNBCL.png?1551777826'
  },
  {
    chainId: 1,
    address: '0xe1b583dc66e0a24fd9af2dc665f6f5e48978e106',
    name: 'Mercurity Finance',
    symbol: 'MEE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14513/thumb/mee.PNG?1616633698'
  },
  {
    chainId: 1,
    address: '0x6fcb6408499a7c0f242e32d77eb51ffa1dd28a7e',
    name: 'HydraDX',
    symbol: 'XHDX',
    decimals: 12,
    logoURI: 'https://assets.coingecko.com/coins/images/13929/thumb/hydradx_logo.png?1612877025'
  },
  {
    chainId: 1,
    address: '0xbbff862d906e348e9946bfb2132ecb157da3d4b4',
    name: 'Sharder protocol',
    symbol: 'SS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2921/thumb/sharder-200px.png?1595305234'
  },
  {
    chainId: 1,
    address: '0x93dfaf57d986b9ca77df9376c50878e013d9c7c8',
    name: 'Unique One',
    symbol: 'RARE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13458/thumb/Logo_Unique.png?1612757355'
  },
  {
    chainId: 1,
    address: '0xffed56a180f23fd32bc6a1d8d3c09c283ab594a8',
    name: 'Freeliquid',
    symbol: 'FL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13698/thumb/freeliquid_logo.png?1610980336'
  },
  {
    chainId: 1,
    address: '0xe1aee98495365fc179699c1bb3e761fa716bee62',
    name: 'Bezant',
    symbol: 'BZNT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3825/thumb/bezant.png?1546240528'
  },
  {
    chainId: 1,
    address: '0x7e9d8f07a64e363e97a648904a89fb4cd5fb94cd',
    name: 'Forefront',
    symbol: 'FF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14659/thumb/N2kir6jx_400x400.jpg?1617608020'
  },
  {
    chainId: 1,
    address: '0x7dee45dff03ec7137979586ca20a2f4917bac9fa',
    name: 'DeFi Wizard',
    symbol: 'DWZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14132/thumb/logoonblack.png?1614593221'
  },
  {
    chainId: 1,
    address: '0xcfad57a67689809cda997f655802a119838c9cec',
    name: 'Benscoin',
    symbol: 'BSC',
    decimals: 8,
    logoURI:
      'https://assets.coingecko.com/coins/images/10393/thumb/Benscoin_Logo_200x200_%28CoinGecko%29.png?1594432611'
  },
  {
    chainId: 1,
    address: '0xd4342a57ecf2fe7ffa37c33cb8f63b1500e575e6',
    name: 'Apron',
    symbol: 'APN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14916/thumb/1_GOjoDhGzzpqnMPGpHGeWhg.png?1618998577'
  },
  {
    chainId: 1,
    address: '0x7c3e3eae4d893d11c61e74d5187c962ba5744a3b',
    name: 'KokoSwap',
    symbol: 'KOKO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15205/thumb/kokologo.png?1620097942'
  },
  {
    chainId: 1,
    address: '0x7788d759f21f53533051a9ae657fa05a1e068fc6',
    name: 'FLETA',
    symbol: 'FLETA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7865/thumb/d4UsZiCw_400x400.jpg?1563331507'
  },
  {
    chainId: 1,
    address: '0x6a532b08c654a1a86069b74c560d8fa0ff842218',
    name: 'Pikto Group',
    symbol: 'PKP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13105/thumb/a-11-e1604828151917-1024x799.png?1605235252'
  },
  {
    chainId: 1,
    address: '0x638155f4bd8f85d401da32498d8866ee39a150b8',
    name: 'Jurasaur',
    symbol: 'JREX',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11792/thumb/jura_logo.png?1594172306'
  },
  {
    chainId: 1,
    address: '0x6d52dfefb16bb9cdc78bfca09061e44574886626',
    name: 'CPUcoin',
    symbol: 'CPU',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9544/thumb/CPUcoin.png?1614827706'
  },
  {
    chainId: 1,
    address: '0x1c9922314ed1415c95b9fd453c3818fd41867d0b',
    name: 'Tower',
    symbol: 'TOWER',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14134/thumb/towertoken-icon.png?1615350405'
  },
  {
    chainId: 1,
    address: '0x08aa0ed0040736dd28d4c8b16ab453b368248d19',
    name: 'Cryptobuyer Token',
    symbol: 'XPT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10845/thumb/XPT.png?1584775672'
  },
  {
    chainId: 1,
    address: '0x8c9e4cf756b9d01d791b95bc2d0913ef2bf03784',
    name: 'AEROTOKEN',
    symbol: 'AET',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9772/thumb/sVM73x16_400x400.jpg?1571707385'
  },
  {
    chainId: 1,
    address: '0x69fa8e7f6bf1ca1fb0de61e1366f7412b827cc51',
    name: 'EnreachDAO',
    symbol: 'NRCH',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14694/thumb/enreachdao.jpg?1617813758'
  },
  {
    chainId: 1,
    address: '0x696acc2de564b48682d71d0847b3632f87c9a402',
    name: 'AurusGOLD',
    symbol: 'AWG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11594/thumb/AurusGOLD_%28AWG%29_Icon_200x200.png?1591664999'
  },
  {
    chainId: 1,
    address: '0xbbc455cb4f1b9e4bfc4b73970d360c8f032efee6',
    name: 'sLINK',
    symbol: 'SLINK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10456/thumb/sLINK.png?1616150356'
  },
  {
    chainId: 1,
    address: '0x3c76ef53be46ed2e9be224e8f0b92e8acbc24ea0',
    name: 'Bitsou',
    symbol: 'BTU',
    decimals: 3,
    logoURI: 'https://assets.coingecko.com/coins/images/8378/thumb/61B3F6DFA9584DDAA760E74B12D6FAD3.png?1557823748'
  },
  {
    chainId: 1,
    address: '0x2a039b1d9bbdccbb91be28691b730ca893e5e743',
    name: 'Rentible',
    symbol: 'RNB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15176/thumb/Rentible-Token-Logo.png?1620025850'
  },
  {
    chainId: 1,
    address: '0xe3278df3eb2085ba9b6899812a99a10f9ca5e0df',
    name: 'Tourist Token',
    symbol: 'TOTO',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/5271/thumb/tourist-token.png?1548759070'
  },
  {
    chainId: 1,
    address: '0x0b4bdc478791897274652dc15ef5c135cae61e60',
    name: 'DAEX',
    symbol: 'DAX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4063/thumb/daex.png?1547791412'
  },
  {
    chainId: 1,
    address: '0x37f04d2c3ae075fad5483bb918491f656b12bdb6',
    name: 'VestChain',
    symbol: 'VEST',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/6700/thumb/logo_200x200_%281%29.png?1547042908'
  },
  {
    chainId: 1,
    address: '0xe4ae84448db5cfe1daf1e6fb172b469c161cb85f',
    name: 'Utopia Genesis Foun',
    symbol: 'UOP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13428/thumb/logo_%2830%29.png?1608518506'
  },
  {
    chainId: 1,
    address: '0xa1c7d450130bb77c6a23ddfaecbc4a060215384b',
    name: 'RougeCoin',
    symbol: 'XRGE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14621/thumb/rougecoin.png?1617265377'
  },
  {
    chainId: 1,
    address: '0x20ae0ca9d42e6ffeb1188f341a7d63450452def6',
    name: 'CIPHER',
    symbol: 'CPR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10392/thumb/cipher-logo.png?1578891757'
  },
  {
    chainId: 1,
    address: '0x9c405acf8688afb61b3197421cdeec1a266c6839',
    name: 'DogeYield',
    symbol: 'DOGY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13734/thumb/dogy200px.png?1611266985'
  },
  {
    chainId: 1,
    address: '0x6e36556b3ee5aa28def2a8ec3dae30ec2b208739',
    name: 'BUILD Finance',
    symbol: 'BUILD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12380/thumb/build.PNG?1599463828'
  },
  {
    chainId: 1,
    address: '0xe25b0bba01dc5630312b6a21927e578061a13f55',
    name: 'ShipChain',
    symbol: 'SHIP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3997/thumb/shipchain.png?1548609236'
  },
  {
    chainId: 1,
    address: '0x24ddff6d8b8a42d835af3b440de91f3386554aa4',
    name: 'Iungo',
    symbol: 'ING',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1831/thumb/iungo.png?1547974844'
  },
  {
    chainId: 1,
    address: '0x6b40d317bc1de4b0938519ac707ae36464f49171',
    name: 'EVERY ORIGINAL',
    symbol: 'EVEO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8651/thumb/EVERY_ORIGINAL.png?1560148541'
  },
  {
    chainId: 1,
    address: '0x99295f1141d58a99e939f7be6bbe734916a875b8',
    name: 'LinkPool',
    symbol: 'LPL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14548/thumb/linkpool-logo-256x256.png?1616988567'
  },
  {
    chainId: 1,
    address: '0x9559aaa82d9649c7a7b220e7c461d2e74c9a3593',
    name: 'rETH',
    symbol: 'RETH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14452/thumb/rETH.png?1616660903'
  },
  {
    chainId: 1,
    address: '0x6673b317c45f490cd8342120a7c02e2ace7cbdd9',
    name: 'Dream21',
    symbol: 'DMC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9708/thumb/dmc.PNG?1570922145'
  },
  {
    chainId: 1,
    address: '0x9588fc24a9796fbd870951a2cd54c6f1f24b2e7c',
    name: 'Kronn',
    symbol: 'KREX',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/7363/thumb/Kronn.jpg?1547044048'
  },
  {
    chainId: 1,
    address: '0x25ce333b325f02c9720da526a01b5f5be889b4e3',
    name: 'BMJ Coin',
    symbol: 'BMJ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9939/thumb/logo_%2815%29.png?1573637099'
  },
  {
    chainId: 1,
    address: '0xd2df355c19471c8bd7d8a3aa27ff4e26a21b4076',
    name: 'sAAVE',
    symbol: 'SAAVE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13968/thumb/sAAVE.png?1616148653'
  },
  {
    chainId: 1,
    address: '0x9b53e429b0badd98ef7f01f03702986c516a5715',
    name: 'Hybrix',
    symbol: 'HY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11518/thumb/icon_%282%29.png?1590618414'
  },
  {
    chainId: 1,
    address: '0x06677dc4fe12d3ba3c7ccfd0df8cd45e4d4095bf',
    name: 'Work Quest',
    symbol: 'WQT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14662/thumb/workquest_logo.jpg?1617622192'
  },
  {
    chainId: 1,
    address: '0xa361718326c15715591c299427c62086f69923d9',
    name: 'Aave BUSD',
    symbol: 'ABUSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14245/thumb/aBUSD.346a65d7_-_Copy.png?1615528703'
  },
  {
    chainId: 1,
    address: '0x114a86d31b8cb3867040b48e7c17d5d04d886ce0',
    name: 'UPBTC Token',
    symbol: 'UPB',
    decimals: 8,
    logoURI:
      'https://assets.coingecko.com/coins/images/9118/thumb/iANVIh-nMDKdEB5N_OVBuhpiTZLTaKjuNvPYZUiN2vTYF5ZYBZkqfzdya67I2t1okaLgnoODfqkP_-GWnpDxmSu7IOLEBXkUlsmojeZpNoaN-wIYDpy25PLsC2I8OdJ1o6K5E-90uImq6HQTOy4jTbagjlvwvw8dbJF_Mhlotv1prhikpHxxGveU6S7QpiakG_0PE8K79H9muHT3--EBoA.jpg?1564470282'
  },
  {
    chainId: 1,
    address: '0x7495d89d347ae9ddc95942938b58e933ab3bf7d1',
    name: 'EsportsPro',
    symbol: 'ESPRO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14187/thumb/logo.jpg?1614830245'
  },
  {
    chainId: 1,
    address: '0xaec7e1f531bb09115103c53ba76829910ec48966',
    name: 'Blank',
    symbol: 'BLANK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14209/thumb/blank.png?1614940842'
  },
  {
    chainId: 1,
    address: '0xe5a3229ccb22b6484594973a03a3851dcd948756',
    name: 'Receive Access Ecos',
    symbol: 'RAE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9428/thumb/Copy_of_raetoken.png?1567290545'
  },
  {
    chainId: 1,
    address: '0x66e7ce35578a37209d01f99f3d2ff271f981f581',
    name: 'PactSwap',
    symbol: 'PACT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15265/thumb/pact.PNG?1620281262'
  },
  {
    chainId: 1,
    address: '0xc42209accc14029c1012fb5680d95fbd6036e2a0',
    name: 'PayPie',
    symbol: 'PPP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/999/thumb/paypie.png?1548330825'
  },
  {
    chainId: 1,
    address: '0xf3dcbc6d72a4e1892f7917b7c43b74131df8480e',
    name: 'Big Data Protocol',
    symbol: 'BDP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14222/thumb/logo_BDP_200.png?1615088501'
  },
  {
    chainId: 1,
    address: '0x7e8c149f70437eba6785f9059190a5b08abf03de',
    name: 'MiniBitcoin',
    symbol: 'MBTC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/7180/thumb/logo_brown.png?1547043731'
  },
  {
    chainId: 1,
    address: '0xb8c6ad2586bb71d518c2aaf510efe91f82022f58',
    name: 'Gomics',
    symbol: 'GOM',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/9506/thumb/69581895_377122223186787_4719544883591774208_n.png?1568184472'
  },
  {
    chainId: 1,
    address: '0x2ec75589856562646afe393455986cad26c4cc5f',
    name: 'Interop',
    symbol: 'TROP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13763/thumb/Interop.jpg?1611631842'
  },
  {
    chainId: 1,
    address: '0xc76fb75950536d98fa62ea968e1d6b45ffea2a55',
    name: 'Unit Protocol',
    symbol: 'COL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11862/thumb/Unit.png?1595580755'
  },
  {
    chainId: 1,
    address: '0x56015bbe3c01fe05bc30a8a9a9fd9a88917e7db3',
    name: 'Cat Token',
    symbol: 'CAT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12177/thumb/happy2-2.jpg?1619593488'
  },
  {
    chainId: 1,
    address: '0x2781246fe707bb15cee3e5ea354e2154a2877b16',
    name: 'ELYSIA',
    symbol: 'EL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10887/thumb/CeyRVXPY_400x400.jpg?1585559128'
  },
  {
    chainId: 1,
    address: '0xa31b1767e09f842ecfd4bc471fe44f830e3891aa',
    name: 'Roobee',
    symbol: 'ROOBEE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8791/thumb/Group_11.png?1580344629'
  },
  {
    chainId: 1,
    address: '0xfbcecb002177e530695b8976638fbd18d2038c3c',
    name: 'Belifex',
    symbol: 'BEFX',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9547/thumb/belifex.png?1586748867'
  },
  {
    chainId: 1,
    address: '0x5003b168b457b663c3c18ffcf5b6a24bee8f59c7',
    name: 'Musk',
    symbol: 'MUSK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6614/thumb/1aCM-fLB_400x400.jpg?1547042815'
  },
  {
    chainId: 1,
    address: '0xaa2409594dc0aef63c0f367e30f6b51149cbe613',
    name: 'Space Doge',
    symbol: 'SPACEDOGE',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14992/thumb/space_doge_logo.jpg?1619342675'
  },
  {
    chainId: 1,
    address: '0x5807ca447851c98569c567963b25b1c83d41bebc',
    name: 'RealToken 10024 100',
    symbol: 'REALTOKEN-10024-100',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11918/thumb/10024.png?1596077756'
  },
  {
    chainId: 1,
    address: '0x12513335ffd5dafc2334e98625d27c1ca84bff86',
    name: 'Amepay',
    symbol: 'AME',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13539/thumb/1dPdUxIy_400x400.png?1609596297'
  },
  {
    chainId: 1,
    address: '0xe530441f4f73bdb6dc2fa5af7c3fc5fd551ec838',
    name: 'GSENetwork',
    symbol: 'GSE',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/4776/thumb/gsenetwork.png?1547743285'
  },
  {
    chainId: 1,
    address: '0xf72fcd9dcf0190923fadd44811e240ef4533fc86',
    name: 'Mirrored ProShares ',
    symbol: 'MVIXY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13638/thumb/mirror_logo_transparent.png?1611564742'
  },
  {
    chainId: 1,
    address: '0x525794473f7ab5715c81d06d10f52d11cc052804',
    name: '12Ships',
    symbol: 'TSHP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9351/thumb/12ships.png?1566485390'
  },
  {
    chainId: 1,
    address: '0x41d5d79431a913c4ae7d69a668ecdfe5ff9dfb68',
    name: 'Inverse Finance',
    symbol: 'INV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14205/thumb/inverse_finance.jpg?1614921871'
  },
  {
    chainId: 1,
    address: '0x9ec251401eafb7e98f37a1d911c0aea02cb63a80',
    name: 'Bitcratic',
    symbol: 'BCT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9965/thumb/bitcraticlogo-logo-150-150.jpg?1574295058'
  },
  {
    chainId: 1,
    address: '0x84ba4aecfde39d69686a841bab434c32d179a169',
    name: 'Method Finance',
    symbol: 'MTHD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14619/thumb/mthd.PNG?1617262620'
  },
  {
    chainId: 1,
    address: '0x182f4c4c97cd1c24e1df8fc4c053e5c47bf53bef',
    name: 'keyTango',
    symbol: 'TANGO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14349/thumb/2Fv6IUqc_400x400.jpg?1615513660'
  },
  {
    chainId: 1,
    address: '0x868ab6c9e560ff70584b9770d1bd1b961ad09d82',
    name: 'Super Trip Chain',
    symbol: 'SUPT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/8706/thumb/supt.jpg?1560242340'
  },
  {
    chainId: 1,
    address: '0x122a86b5dff2d085afb49600b4cd7375d0d94a5f',
    name: 'Italian Lira',
    symbol: 'ITL',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/3656/thumb/italian-lira.png?1547974594'
  },
  {
    chainId: 1,
    address: '0x0fb843d37aa2a99db8d81af9fe2f0a6485c7c002',
    name: 'CPROP',
    symbol: 'CPROP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5466/thumb/CPROP-logo.png?1547041204'
  },
  {
    chainId: 1,
    address: '0xf406f7a9046793267bc276908778b29563323996',
    name: 'APY vision',
    symbol: 'VISION',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13288/thumb/apyvisionlogo200circle.png?1607059042'
  },
  {
    chainId: 1,
    address: '0xbcd9e216200369803ed059b7744f6fb4cf3887c7',
    name: 'Epic',
    symbol: 'EPIC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12670/thumb/Untitled-design-8.png?1601503498'
  },
  {
    chainId: 1,
    address: '0xd0d3ebcad6a20ce69bc3bc0e1ec964075425e533',
    name: 'Ethereum Stake',
    symbol: 'ETHYS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13328/thumb/eths_logo.png?1607494708'
  },
  {
    chainId: 1,
    address: '0xefcec6d87e3ce625c90865a49f2b7482963d73fe',
    name: 'Fetish Coin',
    symbol: 'FETISH',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/9174/thumb/J_GljyDh_400x400.jpg?1564992660'
  },
  {
    chainId: 1,
    address: '0xa31f7a32db329f270a0e6b59558823e64d8ef0a6',
    name: 'Q8E20 Token',
    symbol: 'Q8E20',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10427/thumb/F366Xq9.png?1579215048'
  },
  {
    chainId: 1,
    address: '0xceb286c9604c542d3cc08b41aa6c9675b078a832',
    name: 'Vortex DeFi',
    symbol: 'VTX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14120/thumb/vortex_defi.png?1614514332'
  },
  {
    chainId: 1,
    address: '0x173b1e935e653d0098ae692a37177ff132834595',
    name: 'Hara Token',
    symbol: 'HART',
    decimals: 1,
    logoURI: 'https://assets.coingecko.com/coins/images/5138/thumb/hara.jpg?1547040533'
  },
  {
    chainId: 1,
    address: '0x9f9913853f749b3fe6d6d4e16a1cc3c1656b6d51',
    name: 'BITToken',
    symbol: 'BITT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13783/thumb/BITT_Logo_256pixels.png?1611733961'
  },
  {
    chainId: 1,
    address: '0x8a854288a5976036a725879164ca3e91d30c6a1b',
    name: 'GET Protocol',
    symbol: 'GET',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1927/thumb/GET_Protocol.png?1552893230'
  },
  {
    chainId: 1,
    address: '0x01ff50f8b7f74e4f00580d9596cd3d0d6d6e326f',
    name: 'BnkToTheFuture',
    symbol: 'BFT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3468/thumb/bnktothefuture.png?1547351865'
  },
  {
    chainId: 1,
    address: '0x953e22945b416730bad05009af05b420e598e412',
    name: 'GameXCoin',
    symbol: 'GXC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5633/thumb/GameXCoin.png?1559025856'
  },
  {
    chainId: 1,
    address: '0x8b6c3b7c01d9db4393f9aa734750f36df1543e9a',
    name: 'Vid',
    symbol: 'VI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10978/thumb/VI.png?1614827801'
  },
  {
    chainId: 1,
    address: '0x226f7b842e0f0120b7e194d05432b3fd14773a9d',
    name: 'UNION Protocol Gove',
    symbol: 'UNN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13408/thumb/unn_finance.png?1608262290'
  },
  {
    chainId: 1,
    address: '0x951301a2bbce3d357785ba1e13ec8f42322252b8',
    name: 'Cifculation',
    symbol: 'CLC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/8678/thumb/logo_%2825%29.png?1560221363'
  },
  {
    chainId: 1,
    address: '0xf55a93b613d172b86c2ba3981a849dae2aecde2f',
    name: 'Your Futures Exchan',
    symbol: 'YFX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15654/thumb/yfx.PNG?1621478455'
  },
  {
    chainId: 1,
    address: '0xb89903dde3899f0280b99913168ee833a7896b93',
    name: 'AurusSILVER',
    symbol: 'AWS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14965/thumb/Aurus-AWS-outline-3x.png?1619164424'
  },
  {
    chainId: 1,
    address: '0x0d4b4da5fb1a7d55e85f8e22f728701ceb6e44c9',
    name: 'DigiMax',
    symbol: 'DGMT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11807/thumb/0053e154-964b-485a-9827-d3ef7015a9b9.png?1594375316'
  },
  {
    chainId: 1,
    address: '0x24700a297960e8477ce3ca6c58b70a7af3410398',
    name: 'Oasis City',
    symbol: 'OSC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7038/thumb/wJepb5N.png?1550038108'
  },
  {
    chainId: 1,
    address: '0x31c8eacbffdd875c74b94b077895bd78cf1e64a3',
    name: 'Radicle',
    symbol: 'RAD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14013/thumb/radicle.png?1614402918'
  },
  {
    chainId: 1,
    address: '0x622dffcc4e83c64ba959530a5a5580687a57581b',
    name: 'Cube',
    symbol: 'AUTO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3503/thumb/2559.png?1547038267'
  },
  {
    chainId: 1,
    address: '0x23b75bc7aaf28e2d6628c3f424b3882f8f072a3c',
    name: 'Vice Industry Token',
    symbol: 'VIT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2983/thumb/vit.png?1547037240'
  },
  {
    chainId: 1,
    address: '0xfee4dbe2751bf8d1b1b861aaf9664961f19ce91a',
    name: 'Q DeFi Rating   Gov',
    symbol: 'QDEFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13257/thumb/d2ty5tML.png?1606788567'
  },
  {
    chainId: 1,
    address: '0x4c1c4957d22d8f373aed54d0853b090666f6f9de',
    name: 'Silverway',
    symbol: 'SLV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8822/thumb/Silverway.png?1561629364'
  },
  {
    chainId: 1,
    address: '0x73d7e1dcfac94282dfda16c8f369b488580f7408',
    name: 'IDC Token',
    symbol: 'IT',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/7152/thumb/33748350_1995896493815379_2680945330924552192_n.png?1547043663'
  },
  {
    chainId: 1,
    address: '0x8b0e42f366ba502d787bb134478adfae966c8798',
    name: 'LABS Group',
    symbol: 'LABS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14401/thumb/LABS_logo_icon_COLOR-8.png?1615890483'
  },
  {
    chainId: 1,
    address: '0x557b933a7c2c45672b610f8954a3deb39a51a8ca',
    name: 'REVV',
    symbol: 'REVV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12373/thumb/Nxy8QwOU.png?1599385982'
  },
  {
    chainId: 1,
    address: '0xe1a0da60f8802b49cc36313bafade3f9e12342fd',
    name: 'MP4',
    symbol: 'MP4',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14092/thumb/Ko5uWOr8_400x400.png?1614222859'
  },
  {
    chainId: 1,
    address: '0x4dd672e77c795844fe3a464ef8ef0faae617c8fb',
    name: 'CONUN',
    symbol: 'CON',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7480/thumb/conun.png?1547716938'
  },
  {
    chainId: 1,
    address: '0x8dd4228605e467671941ffb4cae15cf7959c8d9d',
    name: 'Ziticoin',
    symbol: 'ZITI',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/15255/thumb/logo200x200_%284%29.png?1620267412'
  },
  {
    chainId: 1,
    address: '0x91e64f39c1fe14492e8fdf5a8b0f305bd218c8a1',
    name: 'Vodi X',
    symbol: 'VDX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8451/thumb/vodi-x.jpg?1558566350'
  },
  {
    chainId: 1,
    address: '0x8868ff4893113193313465a0ccc5f1bd370d7751',
    name: 'KVI',
    symbol: 'KVI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10794/thumb/kvi.PNG?1583721478'
  },
  {
    chainId: 1,
    address: '0x28cca76f6e8ec81e4550ecd761f899110b060e97',
    name: 'ArGo Token',
    symbol: 'ARGO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15477/thumb/argo.jpeg?1620995773'
  },
  {
    chainId: 1,
    address: '0x910524678c0b1b23ffb9285a81f99c29c11cbaed',
    name: 'Azuki',
    symbol: 'AZUKI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13091/thumb/bdUBSCo.png?1605169403'
  },
  {
    chainId: 1,
    address: '0xacf3d402e5e2c3edd5b8129e966017d293f12a4c',
    name: 'BoxAxis',
    symbol: 'BAXS',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/7732/thumb/MaUNF_dJFGQujn1_y5zYWG_U1NCglt4yBsjyftdpPr5H7LqTsu0XEqcKnnRWcjTUA0DsOLCr1Ekhe37__Wpx83X29R-nbs0UTxz5eElpzw7Mcl0y8-ReYLck8dNYAxJpXG1k48pA4RousepQFj7H9JKq09h5Z4EzBwnJf2P6qFWALryESUUmAOmtByKUD5WoCWI4DIlRorsWSbbl6.jpg?1550108268'
  },
  {
    chainId: 1,
    address: '0xad32a8e6220741182940c5abf610bde99e737b2d',
    name: 'PieDAO DOUGH v2',
    symbol: 'DOUGH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12693/thumb/DOUGH2v.png?1602655308'
  },
  {
    chainId: 1,
    address: '0x06e0feb0d74106c7ada8497754074d222ec6bcdf',
    name: 'Bitball',
    symbol: 'BTB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7039/thumb/BitBall_png_.png?1588640849'
  },
  {
    chainId: 1,
    address: '0x52d904eff2605463c2f0b338d34abc9b7c3e3b08',
    name: 'Bitpower',
    symbol: 'BPP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13492/thumb/Bitpower_%28200x200%29.png?1609134732'
  },
  {
    chainId: 1,
    address: '0x86965a86539e2446f9e72634cefca7983cc21a81',
    name: 'YFISCURITY',
    symbol: 'YFIS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12277/thumb/Logo_YFIS_.png?1598737945'
  },
  {
    chainId: 1,
    address: '0xaef4f02e31cdbf007f8d98da4ae365188a0e9ecc',
    name: 'The Famous Token',
    symbol: 'TFT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13847/thumb/android-icon-192x192_%281%29.png?1612336853'
  },
  {
    chainId: 1,
    address: '0xd8e3fb3b08eba982f2754988d70d57edc0055ae6',
    name: 'Zoracles',
    symbol: 'ZORA',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13255/thumb/zora.png?1606747018'
  },
  {
    chainId: 1,
    address: '0xbf15797bb5e47f6fb094a4abdb2cfc43f77179ef',
    name: 'Klondike Finance v2',
    symbol: 'KLONX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14508/thumb/WPQvZkDk_400x400.jpg?1616578143'
  },
  {
    chainId: 1,
    address: '0xb98d4c97425d9908e66e53a6fdf673acca0be986',
    name: 'Arcblock',
    symbol: 'ABT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2341/thumb/arcblock.png?1547036543'
  },
  {
    chainId: 1,
    address: '0x39795344cbcc76cc3fb94b9d1b15c23c2070c66d',
    name: 'Seigniorage Shares',
    symbol: 'SHARE',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/12306/thumb/logo_%281%29.png?1607658707'
  },
  {
    chainId: 1,
    address: '0x45128cb743951121fb70cb570c0784492732778a',
    name: 'Metawhale Gold',
    symbol: 'MWG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13809/thumb/MWG-gecko-light_1.png?1617276428'
  },
  {
    chainId: 1,
    address: '0xf0814d0e47f2390a8082c4a1bd819fdde50f9bfc',
    name: 'XPToken io',
    symbol: 'XPT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13184/thumb/Untitled-design-7.png?1606083893'
  },
  {
    chainId: 1,
    address: '0x6b785a0322126826d8226d77e173d75dafb84d11',
    name: 'Bankroll Vault',
    symbol: 'VLT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11638/thumb/vlt-200.png?1592272725'
  },
  {
    chainId: 1,
    address: '0x755be920943ea95e39ee2dc437b268917b580d6e',
    name: 'VersoView',
    symbol: 'VVT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13380/thumb/HkfxEtWh_400x400.jpg?1608031723'
  },
  {
    chainId: 1,
    address: '0x6c4522f0035bed2180b40f4c5d9dbaab64b41325',
    name: 'Passport Finance',
    symbol: 'PASS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12946/thumb/android-chrome-512x512.png?1604277448'
  },
  {
    chainId: 1,
    address: '0x419b8ed155180a8c9c64145e76dad49c0a4efb97',
    name: 'AltEstate Token',
    symbol: 'ALT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3076/thumb/AltEstate_Token.jpg?1547037426'
  },
  {
    chainId: 1,
    address: '0x39f8e6c7877478de0604fe693c6080511bc0a6da',
    name: 'xBNT',
    symbol: 'XBNTA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15033/thumb/Token_%282%29.png?1619502392'
  },
  {
    chainId: 1,
    address: '0x7b35ce522cb72e4077baeb96cb923a5529764a00',
    name: 'Impermax',
    symbol: 'IMX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15126/thumb/Copy_of_200px.png?1619761475'
  },
  {
    chainId: 1,
    address: '0x1baffbc23f2246040cce12f7a140c38c5dc73e46',
    name: 'Passive Income Bot',
    symbol: 'PIB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14755/thumb/R4WAZBgP_400x400.png?1618192898'
  },
  {
    chainId: 1,
    address: '0xa849eaae994fb86afa73382e9bd88c2b6b18dc71',
    name: 'MVL',
    symbol: 'MVL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3476/thumb/mass-vehicle-ledger.png?1547978299'
  },
  {
    chainId: 1,
    address: '0x763fa6806e1acf68130d2d0f0df754c93cc546b2',
    name: 'Lition',
    symbol: 'LIT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6580/thumb/Lition_ico.png?1547042787'
  },
  {
    chainId: 1,
    address: '0x5dc02ea99285e17656b8350722694c35154db1e8',
    name: 'Bonded Finance',
    symbol: 'BOND',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13222/thumb/bonded_finance_logo.png?1606318433'
  },
  {
    chainId: 1,
    address: '0x0e192d382a36de7011f795acc4391cd302003606',
    name: 'Futureswap',
    symbol: 'FST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14520/thumb/futureswap.jpeg?1616674209'
  },
  {
    chainId: 1,
    address: '0x27460aac4b005de72e2326bd8391c27fb41780f8',
    name: 'JustBet',
    symbol: 'WINR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12886/thumb/WINR.png?1603270935'
  },
  {
    chainId: 1,
    address: '0x837010619aeb2ae24141605afc8f66577f6fb2e7',
    name: 'zHEGIC',
    symbol: 'ZHEGIC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13074/thumb/zhegic_logo.png?1604903561'
  },
  {
    chainId: 1,
    address: '0xc38f1fb49acdf2f1213caf3319f6eb3ea2cb7527',
    name: 'Bitcoinus',
    symbol: 'BITS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6294/thumb/bitcoinus.png?1547042337'
  },
  {
    chainId: 1,
    address: '0x9556f8ee795d991ff371f547162d5efb2769425f',
    name: 'DMme',
    symbol: 'DMME',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9598/thumb/iyrIZf3N_400x400.png?1569383452'
  },
  {
    chainId: 1,
    address: '0x4161725d019690a3e0de50f6be67b07a86a9fae1',
    name: 'Token Pocket',
    symbol: 'TPT',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/7603/thumb/_E6sbg1g_400x400.jpg?1548828066'
  },
  {
    chainId: 1,
    address: '0xeb269732ab75a6fd61ea60b06fe994cd32a83549',
    name: 'USDx Stablecoin',
    symbol: 'USDX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9375/thumb/logo_USDx_256x256.png?1568695741'
  },
  {
    chainId: 1,
    address: '0x94804dc4948184ffd7355f62ccbb221c9765886f',
    name: 'Rage Fan',
    symbol: 'RAGE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14585/thumb/AcNx2x6P_400x400.jpg?1617153476'
  },
  {
    chainId: 1,
    address: '0xeb6985acd6d0cbff60b88032b0b29ac1d9d66a1b',
    name: 'Bitbook Gambling',
    symbol: 'BXK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8773/thumb/bitbook_token.png?1561082516'
  },
  {
    chainId: 1,
    address: '0xf7970499814654cd13cb7b6e7634a12a7a8a9abc',
    name: 'TOM Finance',
    symbol: 'TOM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13173/thumb/TOM_logo.png?1605857896'
  },
  {
    chainId: 1,
    address: '0x00000000441378008ea67f4284a57932b1c000a5',
    name: 'TrueGBP',
    symbol: 'TGBP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12724/thumb/TGBP.png?1602042166'
  },
  {
    chainId: 1,
    address: '0x07e3c70653548b04f0a75970c1f81b4cbbfb606f',
    name: 'Agrello',
    symbol: 'DLT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/900/thumb/delta_200x200.png?1616645398'
  },
  {
    chainId: 1,
    address: '0x9e5a64943f9f48463f07cc0578bbf9e2e67f0f61',
    name: 'Anonymous Coin',
    symbol: 'AMC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10014/thumb/BQ8dlAOd_400x400.png?1574760277'
  },
  {
    chainId: 1,
    address: '0x19131a8ae42e32c747c1ead318fadb98b0be45b7',
    name: 'Quality Tracing Cha',
    symbol: 'QTC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10012/thumb/YRgC0w_r_400x400.jpg?1574759712'
  },
  {
    chainId: 1,
    address: '0x2c5a9980b41861d91d30d0e0271d1c093452dca5',
    name: 'ETH 12 Day EMA Cros',
    symbol: 'ETH12EMACO',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/10614/thumb/eth-12-day-exponential-moving-average-set.png?1580971652'
  },
  {
    chainId: 1,
    address: '0x9ed8e7c9604790f7ec589f99b94361d8aab64e5e',
    name: 'Unistake',
    symbol: 'UNISTAKE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12813/thumb/unistake.png?1612346684'
  },
  {
    chainId: 1,
    address: '0xe8b251822d003a2b2466ee0e38391c2db2048739',
    name: 'rbase finance',
    symbol: 'RBASE',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13343/thumb/rbase_logo.jpg?1607619337'
  },
  {
    chainId: 1,
    address: '0xe5b998f63e7022664d3c36c56d1798cca7751573',
    name: 'Bor Protocol',
    symbol: 'BRP',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/15665/thumb/f.eu1.jwwb.nl_public_y_m_w_temp-tdnklykxgvgehwwdodye_gdyq90_image-15.png?1621491281'
  },
  {
    chainId: 1,
    address: '0xacfe45c352c902ae3a3f9b6bfe6ec994c5d791bf',
    name: 'megaBonk',
    symbol: 'MBONK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14996/thumb/Bonk_v2_gecko.png?1619391636'
  },
  {
    chainId: 1,
    address: '0x45245bc59219eeaaf6cd3f382e078a461ff9de7b',
    name: 'BANKEX',
    symbol: 'BKX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2199/thumb/cEG4Vgx.png?1547036488'
  },
  {
    chainId: 1,
    address: '0x88ef27e69108b2633f8e1c184cc37940a075cc02',
    name: 'Dego Finance',
    symbol: 'DEGO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12503/thumb/c185FKx.png?1600298167'
  },
  {
    chainId: 1,
    address: '0x853d955acef822db058eb8505911ed77f175b99e',
    name: 'Frax',
    symbol: 'FRAX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13422/thumb/frax_logo.png?1608476506'
  },
  {
    chainId: 1,
    address: '0x83e2be8d114f9661221384b3a50d24b96a5653f5',
    name: '0xcert',
    symbol: 'ZXC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4552/thumb/0xcert.png?1547039841'
  },
  {
    chainId: 1,
    address: '0x72e9d9038ce484ee986fea183f8d8df93f9ada13',
    name: 'SmartCredit Token',
    symbol: 'SMARTCREDIT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13036/thumb/smartcredit_logo_02_white_a-1.png?1604545479'
  },
  {
    chainId: 1,
    address: '0xb9ef770b6a5e12e45983c5d80545258aa38f3b78',
    name: '0chain',
    symbol: 'ZCN',
    decimals: 10,
    logoURI: 'https://assets.coingecko.com/coins/images/4934/thumb/0_Black-svg.png?1600757954'
  },
  {
    chainId: 1,
    address: '0xfca47962d45adfdfd1ab2d972315db4ce7ccf094',
    name: 'iXledger',
    symbol: 'IXT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/814/thumb/ixledger.png?1547034602'
  },
  {
    chainId: 1,
    address: '0x9b9087756eca997c5d595c840263001c9a26646d',
    name: 'DogeFi',
    symbol: 'DOGEFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12290/thumb/DOGEFI-Logo.png?1598868716'
  },
  {
    chainId: 1,
    address: '0x44b537b6f94c73a54f7bf8a9b68f8125da3c330b',
    name: 'Polkabase',
    symbol: 'PBASE',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13954/thumb/polkabase_logo.png?1613301180'
  },
  {
    chainId: 1,
    address: '0x1fa3bc860bf823d792f04f662f3aa3a500a68814',
    name: '1X Short Bitcoin To',
    symbol: 'HEDGE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10219/thumb/683JEXMN_400x400.png?1596706467'
  },
  {
    chainId: 1,
    address: '0x9043d4d51c9d2e31e3f169de4551e416970c27ef',
    name: 'Prime DAI',
    symbol: 'PDAI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12338/thumb/pdai-200px.png?1599136775'
  },
  {
    chainId: 1,
    address: '0x94f31ac896c9823d81cf9c2c93feceed4923218f',
    name: 'YFTether',
    symbol: 'YFTE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13683/thumb/yftether.png?1610858860'
  },
  {
    chainId: 1,
    address: '0xa143ac515dca260a46c742c7251ef3b268639593',
    name: 'Bulk Network',
    symbol: 'BULK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15266/thumb/Bulk_whale_round.png?1620285364'
  },
  {
    chainId: 1,
    address: '0xbea98c05eeae2f3bc8c3565db7551eb738c8ccab',
    name: 'Geyser',
    symbol: 'GYSR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12995/thumb/gey.png?1604235725'
  },
  {
    chainId: 1,
    address: '0x9534ad65fb398e27ac8f4251dae1780b989d136e',
    name: 'Vulcan Forged',
    symbol: 'PYR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14770/thumb/1617088937196.png?1619414736'
  },
  {
    chainId: 1,
    address: '0x4bd70556ae3f8a6ec6c4080a0c327b24325438f3',
    name: 'Hxro',
    symbol: 'HXRO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7805/thumb/hxro-squarelogo-1585089594129.png?1586221980'
  },
  {
    chainId: 1,
    address: '0xcbeaec699431857fdb4d37addbbdc20e132d4903',
    name: 'YOYOW',
    symbol: 'YOYOW',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1270/thumb/yoyow.png?1548761123'
  },
  {
    chainId: 1,
    address: '0x76974c7b79dc8a6a109fd71fd7ceb9e40eff5382',
    name: 'Dowcoin',
    symbol: 'DOW',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4750/thumb/dowcoin.png?1547223950'
  },
  {
    chainId: 1,
    address: '0x00d1793d7c3aae506257ba985b34c76aaf642557',
    name: 'Tacos',
    symbol: 'TACO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12108/thumb/photo_2020-08-12_05-50-46.jpg?1597217863'
  },
  {
    chainId: 1,
    address: '0xc8807f0f5ba3fa45ffbdc66928d71c5289249014',
    name: 'Ispolink',
    symbol: 'ISP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15283/thumb/isolink.PNG?1620352267'
  },
  {
    chainId: 1,
    address: '0x87b008e57f640d94ee44fd893f0323af933f9195',
    name: 'Coin Artist',
    symbol: 'COIN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12375/thumb/coin_artist_logo.png?1599403918'
  },
  {
    chainId: 1,
    address: '0x68037790a0229e9ce6eaa8a99ea92964106c4703',
    name: 'Parallel',
    symbol: 'PAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14153/thumb/par_round_200.png?1614670422'
  },
  {
    chainId: 1,
    address: '0x77c07555af5ffdc946fb47ce15ea68620e4e7170',
    name: 'Breezecoin',
    symbol: 'BRZE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6727/thumb/breezecoin.png?1547563369'
  },
  {
    chainId: 1,
    address: '0xc713e5e149d5d0715dcd1c156a020976e7e56b88',
    name: 'Aave MKR',
    symbol: 'AMKR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14248/thumb/aMKR.ac965178.png?1615528346'
  },
  {
    chainId: 1,
    address: '0x39c6b3e42d6a679d7d776778fe880bc9487c2eda',
    name: 'Aave KNC',
    symbol: 'AKNC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14250/thumb/aKNC.42bcd2e3.png?1615528613'
  },
  {
    chainId: 1,
    address: '0x4e352cf164e64adcbad318c3a1e222e9eba4ce42',
    name: 'MCDEX',
    symbol: 'MCB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11796/thumb/mcb.png?1594355515'
  },
  {
    chainId: 1,
    address: '0x695106ad73f506f9d0a9650a78019a93149ae07c',
    name: 'BNS Token',
    symbol: 'BNS',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11926/thumb/HS7eNJdt_400x400.jpg?1596170654'
  },
  {
    chainId: 1,
    address: '0xab51e836bdcbc7cc06d926c50d88328f1bb17148',
    name: 'World Credit Diamon',
    symbol: 'WCDC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7649/thumb/seHi91g1_400x400.jpg?1548930150'
  },
  {
    chainId: 1,
    address: '0x72630b1e3b42874bf335020ba0249e3e9e47bafc',
    name: 'Paypolitan Token',
    symbol: 'EPAN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13192/thumb/ava3.png?1606102032'
  },
  {
    chainId: 1,
    address: '0x2cad4991f62fc6fcd8ec219f37e7de52b688b75a',
    name: 'Schain Wallet',
    symbol: 'SCHA',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/12135/thumb/schain.png?1597462731'
  },
  {
    chainId: 1,
    address: '0xc7bba5b765581efb2cdd2679db5bea9ee79b201f',
    name: 'Gems',
    symbol: 'GEM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3945/thumb/Webp.net-resizeimage_%2817%29.jpg?1547038970'
  },
  {
    chainId: 1,
    address: '0xc7038ccf60e48c5b7119e55566a6ad9f2d66c7c2',
    name: '3X Long BiLira Toke',
    symbol: 'TRYBBULL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10449/thumb/683JEXMN_400x400.png?1579561683'
  },
  {
    chainId: 1,
    address: '0xa96f31f1c187c28980176c3a27ba7069f48abde4',
    name: 'Ethereum Gold Proje',
    symbol: 'ETGP',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/7337/thumb/llxg4bqE_400x400.jpg?1547044001'
  },
  {
    chainId: 1,
    address: '0x8578530205cecbe5db83f7f29ecfeec860c297c2',
    name: 'smARTOFGIVING',
    symbol: 'AOG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6050/thumb/logo_%286%29.png?1547042007'
  },
  {
    chainId: 1,
    address: '0x26ea1f595f6567b7050fbba24f6a66e19db4d560',
    name: 'APE Punk',
    symbol: 'APE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14510/thumb/ape-punk-shards.png?1616596505'
  },
  {
    chainId: 1,
    address: '0xf1f5de69c9c8d9be8a7b01773cc1166d4ec6ede2',
    name: 'Definitex',
    symbol: 'DFX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12370/thumb/dfx.jpg?1599360540'
  },
  {
    chainId: 1,
    address: '0x2e9c861713a8cbd4aca72a832f347b9520edbb90',
    name: 'Crypto Application ',
    symbol: 'CAPP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11082/thumb/capp.PNG?1588048135'
  },
  {
    chainId: 1,
    address: '0x29cbd0510eec0327992cd6006e63f9fa8e7f33b7',
    name: 'Tidal Finance',
    symbol: 'TIDAL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14460/thumb/Tidal-mono.png?1616233894'
  },
  {
    chainId: 1,
    address: '0x6c4b85cab20c13af72766025f0e17e0fe558a553',
    name: 'YFFII Finance',
    symbol: 'YFFII',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12398/thumb/4hWupcq.jpg?1599553239'
  },
  {
    chainId: 1,
    address: '0xbd1848e1491d4308ad18287a745dd4db2a4bd55b',
    name: 'Mochi Market',
    symbol: 'MOMA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14993/thumb/mochi.PNG?1619390399'
  },
  {
    chainId: 1,
    address: '0x3b58c52c03ca5eb619eba171091c86c34d603e5f',
    name: 'MCI Coin',
    symbol: 'MCI',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/12524/thumb/mcicoin-logo.png?1600471686'
  },
  {
    chainId: 1,
    address: '0xfa99a87b14b02e2240c79240c5a20f945ca5ef76',
    name: 'GG Token',
    symbol: 'GGTK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13666/thumb/ggblack200.png?1610811691'
  },
  {
    chainId: 1,
    address: '0x37f6f8eb409deb9feaf032c109a72319f665c79d',
    name: 'Gold Coin Reserve',
    symbol: 'GCR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13278/thumb/Logo_%2825%29.png?1606897839'
  },
  {
    chainId: 1,
    address: '0x46e9fe43470fafd690100c86037f9e566e24d480',
    name: 'EQUOS Origin',
    symbol: 'EQO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14868/thumb/EQO-Token-Icon.png?1618816238'
  },
  {
    chainId: 1,
    address: '0x6e9730ecffbed43fd876a264c982e254ef05a0de',
    name: 'Nord Finance',
    symbol: 'NORD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13630/thumb/nord.jpg?1610465136'
  },
  {
    chainId: 1,
    address: '0x75739d5944534115d7c54ee8c73f186d793bae02',
    name: 'Collective',
    symbol: 'CO2',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14542/thumb/the_collective_logo.png?1616912000'
  },
  {
    chainId: 1,
    address: '0xdb13025b219db5e4529f48b65ff009a26b6ae733',
    name: 'Ubricoin',
    symbol: 'UBN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8367/thumb/M8X-YrBf_400x400.jpg?1557803271'
  },
  {
    chainId: 1,
    address: '0x1d37986f252d0e349522ea6c3b98cb935495e63e',
    name: 'ChartEx',
    symbol: 'CHART',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12523/thumb/chartex.png?1600499406'
  },
  {
    chainId: 1,
    address: '0x462edaa6c1339f98bcb59582af782326603df5f2',
    name: 'NTerprise',
    symbol: 'NTER',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12654/thumb/nterPNG.PNG?1601467686'
  },
  {
    chainId: 1,
    address: '0x83f873388cd14b83a9f47fabde3c9850b5c74548',
    name: 'Zero Utility Token',
    symbol: 'ZUT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12706/thumb/logo.png?1605007113'
  },
  {
    chainId: 1,
    address: '0xeb58343b36c7528f23caae63a150240241310049',
    name: 'Nimbus',
    symbol: 'NBU',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/14517/thumb/EQMLlI52Dhm7DPe4QPt81MiqPTUa0X7YOZV9G1ki_OpZe6rtVUtmIn5XbQHeMHyuTkX4Y1qU-K8lAn4juL3KnVQLR7WeeJ_r6jI5nrjGgvt4DgMSAnuTrT15AREqbB0wUkosE8NVhjKpPUvvaALnpO0BtFUt4Bo9tsOTsfQDAEFrpH0uhVkEqJG_u09ehkX88hkYKJcm5RkvIgq.jpg?1616654587'
  },
  {
    chainId: 1,
    address: '0x0202be363b8a4820f3f4de7faf5224ff05943ab1',
    name: 'UniLend Finance',
    symbol: 'UFT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12819/thumb/UniLend_Finance_logo_PNG.png?1602748658'
  },
  {
    chainId: 1,
    address: '0x48c3399719b582dd63eb5aadf12a40b4c3f52fa2',
    name: 'StakeWise',
    symbol: 'SWISE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15044/thumb/stakewise200.png?1619520721'
  },
  {
    chainId: 1,
    address: '0x8888889213dd4da823ebdd1e235b09590633c150',
    name: 'Marblecoin',
    symbol: 'MBC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9770/thumb/logo_%2824%29.png?1571610155'
  },
  {
    chainId: 1,
    address: '0x075190c6130ea0a3a7e40802f1d77f4ea8f38fe2',
    name: 'nYFI',
    symbol: 'N0031',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12997/thumb/nest_protocol_logo.png?1604246163'
  },
  {
    chainId: 1,
    address: '0xa10ae543db5d967a73e9abcc69c81a18a7fc0a78',
    name: 'BLOCKCLOUT',
    symbol: 'CLOUT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11870/thumb/communityIcon_nys28lije4b51.png?1595505057'
  },
  {
    chainId: 1,
    address: '0xe5b826ca2ca02f09c1725e9bd98d9a8874c30532',
    name: 'ZEON Network',
    symbol: 'ZEON',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4247/thumb/XZqXYc2j_400x400.jpg?1547039580'
  },
  {
    chainId: 1,
    address: '0x2b89bf8ba858cd2fcee1fada378d5cd6936968be',
    name: 'Secret  ERC20 ',
    symbol: 'WSCRT',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/13767/thumb/Secret_S_Black_Coingecko.png?1611667298'
  },
  {
    chainId: 1,
    address: '0x37092dbf8c26da9cc805683b4cc0b942e92de2fd',
    name: 'Blue Eyes White Dog',
    symbol: 'BDOGE',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15436/thumb/256.jpg?1620800814'
  },
  {
    chainId: 1,
    address: '0x5c6af72cbd740b90528c8fe226125413b6bd7e5a',
    name: 'apeUSD SNX Syntheti',
    symbol: 'APEUSD-SNX-DEC21',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14397/thumb/apeusd.jpg?1615905291'
  },
  {
    chainId: 1,
    address: '0xaa602de53347579f86b996d2add74bb6f79462b2',
    name: 'Zipmex Token',
    symbol: 'ZMT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13866/thumb/zipmex.png?1612428520'
  },
  {
    chainId: 1,
    address: '0xd5db36e4dd196be70dbe5dfb4a912b0046fd245d',
    name: 'Dolphin Token',
    symbol: 'DHT',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15423/thumb/dolphin.png?1620786242'
  },
  {
    chainId: 1,
    address: '0x90de74265a416e1393a450752175aed98fe11517',
    name: 'Unlock Protocol',
    symbol: 'UDT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14545/thumb/unlock.jpg?1616948136'
  },
  {
    chainId: 1,
    address: '0xb8fa12f8409da31a4fc43d15c4c78c33d8213b9b',
    name: 'CaliCoin',
    symbol: 'CALI',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/14998/thumb/cropped-Logo-transparent-background-1.png?1619392357'
  },
  {
    chainId: 1,
    address: '0xa95592dcffa3c080b4b40e459c5f5692f67db7f8',
    name: 'Elysian',
    symbol: 'ELY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3558/thumb/elysian.png?1548124914'
  },
  {
    chainId: 1,
    address: '0x2b5016cea1c425f915e13727f7657025de3208fe',
    name: 'Tokemon',
    symbol: 'TKMN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13769/thumb/RDpa3fCw_400x400.png?1611697299'
  },
  {
    chainId: 1,
    address: '0xed0889f7e1c7c7267407222be277e1f1ef4d4892',
    name: 'Melalie',
    symbol: 'MEL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15028/thumb/melalie.PNG?1619494266'
  },
  {
    chainId: 1,
    address: '0x24aef3bf1a47561500f9430d74ed4097c47f51f2',
    name: 'Sparta',
    symbol: 'SPARTA',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/4172/thumb/CEYM9qS.png?1547039440'
  },
  {
    chainId: 1,
    address: '0x9f20ed5f919dc1c1695042542c13adcfc100dcab',
    name: 'Component',
    symbol: 'CMP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15048/thumb/Component.png?1619549859'
  },
  {
    chainId: 1,
    address: '0xab37e1358b639fd877f015027bb62d3ddaa7557e',
    name: 'Lien',
    symbol: 'LIEN',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12224/thumb/Lien.png?1598262819'
  },
  {
    chainId: 1,
    address: '0xf4b54874cd8a6c863e3a904c18fda964661ec363',
    name: 'DWS',
    symbol: 'DWS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4940/thumb/dws.png?1547224427'
  },
  {
    chainId: 1,
    address: '0x5d30ad9c6374bf925d0a75454fa327aacf778492',
    name: 'PERI Finance',
    symbol: 'PERI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15313/thumb/6xVEMS1.png?1620375905'
  },
  {
    chainId: 1,
    address: '0x336c21557185079f42f6ffc098ab572c87ae9435',
    name: 'BigBoys Industry',
    symbol: 'BBI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14069/thumb/Untitled-design-11-removebg-preview.png?1614146979'
  },
  {
    chainId: 1,
    address: '0x5cb3ce6d081fb00d5f6677d196f2d70010ea3f4a',
    name: 'Busy DAO',
    symbol: 'BUSY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14966/thumb/busy.PNG?1619165503'
  },
  {
    chainId: 1,
    address: '0x888888888889c00c67689029d7856aac1065ec11',
    name: 'Opium',
    symbol: 'OPIUM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13758/thumb/opium-token-black_%281%29.png?1611767960'
  },
  {
    chainId: 1,
    address: '0xdb33d49b5a41a97d296b7242a96ebd8ac77b3bb8',
    name: 'CY Finance',
    symbol: 'CYF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12579/thumb/logocyf200.jpg?1600915999'
  },
  {
    chainId: 1,
    address: '0x0000a1c00009a619684135b824ba02f7fbf3a572',
    name: 'AlchemyDAO',
    symbol: 'ALCH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14719/thumb/sbEW5W8.png?1617939648'
  },
  {
    chainId: 1,
    address: '0x1337def16f9b486faed0293eb623dc8395dfe46a',
    name: 'ARMOR',
    symbol: 'ARMOR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13748/thumb/armor.png?1611425846'
  },
  {
    chainId: 1,
    address: '0x18aa7c90d3ae4c5bb219d0a2813f441704084625',
    name: 'Decentralize Curren',
    symbol: 'DCA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7673/thumb/DCA.png?1573037394'
  },
  {
    chainId: 1,
    address: '0xaf5f584d79701d5bdc9ca045e66ae130b67a68ad',
    name: 'CryBet',
    symbol: 'CBT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10973/thumb/d4zbv0h.jpg?1586743861'
  },
  {
    chainId: 1,
    address: '0xd043f70ff0634167db1149b15e5668f5be52d72c',
    name: 'BigCash',
    symbol: 'BGC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14158/thumb/WHITELOGO.png?1614686463'
  },
  {
    chainId: 1,
    address: '0x4156d3342d5c385a87d264f90653733592000581',
    name: 'SALT',
    symbol: 'SALT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/962/thumb/salt.png?1548608746'
  },
  {
    chainId: 1,
    address: '0x336f646f87d9f6bc6ed42dd46e8b3fd9dbd15c22',
    name: 'Crystal Clear',
    symbol: 'CCT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/995/thumb/crystal-clear.png?1547034897'
  },
  {
    chainId: 1,
    address: '0x95a4492f028aa1fd432ea71146b433e7b4446611',
    name: 'APY Finance',
    symbol: 'APY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13041/thumb/1*AvkD-OLocausbxqUzezZ0A.png?1604577922'
  },
  {
    chainId: 1,
    address: '0x7f1f2d3dfa99678675ece1c243d3f7bc3746db5d',
    name: 'Tapmydata',
    symbol: 'TAP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13942/thumb/TMD_Icon.png?1617788995'
  },
  {
    chainId: 1,
    address: '0x76851a93977bea9264c32255b6457882035c7501',
    name: 'Fat Doge',
    symbol: 'FOGE',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15497/thumb/foge.png?1621057848'
  },
  {
    chainId: 1,
    address: '0xe6a9e1bec166f50eda336d02df2662d4eb8ab23c',
    name: 'Liquid Bank',
    symbol: 'LIQ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13040/thumb/xB3uT4eD_400x400.jpg?1604566798'
  },
  {
    chainId: 1,
    address: '0xf7461c8d8e469e9c41a9013dc09ba8abed66ef65',
    name: 'Cryptassist',
    symbol: 'CTAT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9240/thumb/1_wKJjfdZmgNH7rHwWRpCP_g.png?1565474383'
  },
  {
    chainId: 1,
    address: '0x17eb50fdd2995696ee82912a80a9766fcbb0ecca',
    name: 'Dogen Finance',
    symbol: 'DOGEN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15454/thumb/dogen_logo.png?1620887346'
  },
  {
    chainId: 1,
    address: '0x7afac1d878c66a47263dce57976c371ae2e74882',
    name: 'YFMoonBeam',
    symbol: 'YFMB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12682/thumb/yuqS66I.png?1601619725'
  },
  {
    chainId: 1,
    address: '0x6b9f031d718dded0d681c20cb754f97b3bb81b78',
    name: 'GEEQ',
    symbol: 'GEEQ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11953/thumb/GeeqLogoPNGTransparent-1.png?1596421769'
  },
  {
    chainId: 1,
    address: '0x66c0dded8433c9ea86c8cf91237b14e10b4d70b7',
    name: 'Mars',
    symbol: 'MARS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13654/thumb/MARS.png?1610575403'
  },
  {
    chainId: 1,
    address: '0xd291e7a03283640fdc51b121ac401383a46cc623',
    name: 'Rari Governance Tok',
    symbol: 'RGT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12900/thumb/Rari_Logo_Transparent.png?1613978014'
  },
  {
    chainId: 1,
    address: '0xa3ee21c306a700e682abcdfe9baa6a08f3820419',
    name: 'Creditcoin',
    symbol: 'CTC',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/10569/thumb/Zbta4ckfHezzaF3Rb4X0HiYsUWo1IzZ5RbXG17Qj-et6SMVcXMbD6gX0lnOts2B43Dz_jXErds_MhckZpd7uxjhjnkI8QsjGe6gu0TqU2i_4QJGn-get-GmwNL2IZtn9u0iFRzaGlk8jhT_BzXQLyNyC1Am2BRAeARyR89vHJezN82hytRA96tWHrcj33PNmdTh3wktEpEEgyh9.jpg?1580941163'
  },
  {
    chainId: 1,
    address: '0x536381a8628dbcc8c70ac9a30a7258442eab4c92',
    name: 'Pantos',
    symbol: 'PAN',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9639/thumb/Pantos-logo-compact.png?1619079883'
  },
  {
    chainId: 1,
    address: '0xf278c1ca969095ffddded020290cf8b5c424ace2',
    name: 'Ruff',
    symbol: 'RUFF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2726/thumb/ruff.png?1548608556'
  },
  {
    chainId: 1,
    address: '0x82f4ded9cec9b5750fbff5c2185aee35afc16587',
    name: 'DreamTeam',
    symbol: 'DREAM',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/1969/thumb/dtt.png?1547036249'
  },
  {
    chainId: 1,
    address: '0xd29f0b5b3f50b07fe9a9511f7d86f4f4bac3f8c4',
    name: 'Liquidity Network',
    symbol: 'LQD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3801/thumb/liquidity-network.png?1547975994'
  },
  {
    chainId: 1,
    address: '0x7a4d70528c0b8d376c206b0fb2c9db1d26315c2d',
    name: 'iShop Token',
    symbol: 'IST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10621/thumb/ishop.png?1581029119'
  },
  {
    chainId: 1,
    address: '0x92ece48522e1acbcda4aaa8c2fbf2aa9fb15d624',
    name: 'Rocki',
    symbol: 'ROCKS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13465/thumb/rocki_logo.png?1608786767'
  },
  {
    chainId: 1,
    address: '0x80ab141f324c3d6f2b18b030f1c4e95d4d658778',
    name: 'DEA',
    symbol: 'DEA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12825/thumb/dea_logo.jpg?1611136739'
  },
  {
    chainId: 1,
    address: '0x1c5db575e2ff833e46a2e9864c22f4b22e0b37c2',
    name: 'renZEC',
    symbol: 'RENZEC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11564/thumb/renZEC.png?1591186101'
  },
  {
    chainId: 1,
    address: '0x0452aed878805514e28fb5bd0b56bef92176e32a',
    name: 'BPOP',
    symbol: 'BPOP',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9701/thumb/bpop.PNG?1570916211'
  },
  {
    chainId: 1,
    address: '0xbd2ebe1ddf549e6e9079faa6182bcc33c21f13a3',
    name: 'Support Listing Coi',
    symbol: 'SLC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13475/thumb/logo_%28100%29.png?1608934185'
  },
  {
    chainId: 1,
    address: '0x26607ac599266b21d13c7acf7942c7701a8b699c',
    name: 'Power Index Pool To',
    symbol: 'PIPT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13281/thumb/pipt_logo.jpg?1606910642'
  },
  {
    chainId: 1,
    address: '0x0d5e2681d2aadc91f7da4146740180a2190f0c79',
    name: '3X Long Huobi Token',
    symbol: 'HTBULL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10239/thumb/683JEXMN_400x400_%281%29.png?1576633312'
  },
  {
    chainId: 1,
    address: '0x38c4102d11893351ced7ef187fcf43d33eb1abe6',
    name: 'Shrimp Finance',
    symbol: 'SHRIMP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12150/thumb/shrimp_logo.jpg?1597653144'
  },
  {
    chainId: 1,
    address: '0x20945ca1df56d237fd40036d47e866c7dccd2114',
    name: 'Nsure Network',
    symbol: 'NSURE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12948/thumb/Nsure_token.png?1603778876'
  },
  {
    chainId: 1,
    address: '0x4d75d9e37667a2d4677ec3d74bdd9049326ad8d6',
    name: 'NFT Wars',
    symbol: 'WAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14501/thumb/nft_wars.jpg?1616559078'
  },
  {
    chainId: 1,
    address: '0x3db99ab08006aefcc9600972eca8c202396b4300',
    name: 'Vinci',
    symbol: 'VINCI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9675/thumb/logo-icon.png?1570600569'
  },
  {
    chainId: 1,
    address: '0xf56b164efd3cfc02ba739b719b6526a6fa1ca32a',
    name: 'Curio Governance',
    symbol: 'CGT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13607/thumb/QLwpua7.png?1610273891'
  },
  {
    chainId: 1,
    address: '0x9f49ed43c90a540d1cf12f6170ace8d0b88a14e6',
    name: 'ETH RSI 60 40 Yield',
    symbol: 'ETHRSIAPY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11647/thumb/eth-rsi-40-60-ctoken-set.png?1592323952'
  },
  {
    chainId: 1,
    address: '0xeeee2a622330e6d2036691e983dee87330588603',
    name: 'Asko',
    symbol: 'ASKO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11773/thumb/Asko_Logo_%28200x200%29.png?1604706371'
  },
  {
    chainId: 1,
    address: '0xa4bdb11dc0a2bec88d24a3aa1e6bb17201112ebe',
    name: 'Stably USD',
    symbol: 'USDS',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/7596/thumb/Stably-Dollar_Mark_Standard.png?1615791425'
  },
  {
    chainId: 1,
    address: '0x283669123bd83da2536bb534e20512101c18e5d8',
    name: 'Bitpayer Token',
    symbol: 'BPT',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/11110/thumb/bitplayerlogo.png?1588639294'
  },
  {
    chainId: 1,
    address: '0xf2051511b9b121394fa75b8f7d4e7424337af687',
    name: 'DAOhaus',
    symbol: 'HAUS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14551/thumb/jN3kkqke_400x400.png?1616990048'
  },
  {
    chainId: 1,
    address: '0x228ba514309ffdf03a81a205a6d040e429d6e80c',
    name: 'Global Social Chain',
    symbol: 'GSC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4304/thumb/global-social-chain.png?1547742843'
  },
  {
    chainId: 1,
    address: '0xc770eefad204b5180df6a14ee197d99d808ee52d',
    name: 'FOX Token',
    symbol: 'FOX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9988/thumb/FOX.png?1574330622'
  },
  {
    chainId: 1,
    address: '0x464ebe77c293e473b48cfe96ddcf88fcf7bfdac0',
    name: 'KRYLL',
    symbol: 'KRL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2807/thumb/krl.png?1547036979'
  },
  {
    chainId: 1,
    address: '0x5a9bf6badcd24fe0d58e1087290c2fe2c728736a',
    name: 'Block 18',
    symbol: '18C',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7605/thumb/block_18.jpg?1548828772'
  },
  {
    chainId: 1,
    address: '0x9048c33c7bae0bbe9ad702b17b4453a83900d154',
    name: 'Energy Ledger',
    symbol: 'ELX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13648/thumb/ELX.png?1610531914'
  },
  {
    chainId: 1,
    address: '0x2216e873ea4282ebef7a02ac5aea220be6391a7c',
    name: 'smol',
    symbol: 'SMOL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12695/thumb/blockfolio-gecko-smol-clean-up.png?1601867705'
  },
  {
    chainId: 1,
    address: '0x115ec79f1de567ec68b7ae7eda501b406626478e',
    name: 'Carry',
    symbol: 'CRE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3994/thumb/Carry.png?1592402610'
  },
  {
    chainId: 1,
    address: '0x101cc05f4a51c0319f570d5e146a8c625198e636',
    name: 'Aave TUSD',
    symbol: 'ATUSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14317/thumb/aTUSD.08592b49.png?1615528207'
  },
  {
    chainId: 1,
    address: '0x048fe49be32adfc9ed68c37d32b5ec9df17b3603',
    name: 'Skrumble Network',
    symbol: 'SKM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3826/thumb/skrumble-network.png?1548609513'
  },
  {
    chainId: 1,
    address: '0x78a5b382b9a83fe042a4f7eb2399d563fda931c3',
    name: 'Black Diamond Ratin',
    symbol: 'HZT',
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/9700/thumb/hzt.PNG?1570915848'
  },
  {
    chainId: 1,
    address: '0x59c337ef937d0ba9cb1cc47d4e6ded632d22d623',
    name: 'Tchain',
    symbol: 'TCH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7328/thumb/Tchain_%EF%BC%88TCH%29.png?1568598678'
  },
  {
    chainId: 1,
    address: '0x67c597624b17b16fb77959217360b7cd18284253',
    name: 'Benchmark Protocol',
    symbol: 'MARK',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13212/thumb/benchmark_protocol.jpg?1606267583'
  },
  {
    chainId: 1,
    address: '0xb78b3320493a4efaa1028130c5ba26f0b6085ef8',
    name: 'Dracula Token',
    symbol: 'DRC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12758/thumb/dracula_protocol.png?1602316655'
  },
  {
    chainId: 1,
    address: '0x89551b940e2a8ed8eccf509935bac9213fe30584',
    name: 'DoDreamChain',
    symbol: 'DRM',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/10469/thumb/57414069_286321078958466_8377709696580059136_n.jpg?1579733292'
  },
  {
    chainId: 1,
    address: '0x83d6a2171f78e8ffe4dfe7680983effc49cff7a0',
    name: 'Bet Chips',
    symbol: 'BETC',
    decimals: 4,
    logoURI:
      'https://assets.coingecko.com/coins/images/13273/thumb/Whats-App-Image-2020-12-01-at-6-41-47-PM-removebg-preview-removebg-preview.png?1606888089'
  },
  {
    chainId: 1,
    address: '0x6149c26cd2f7b5ccdb32029af817123f6e37df5b',
    name: 'Launchpool',
    symbol: 'LPOOL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14041/thumb/dGUvV0HQ_400x400.jpg?1613976219'
  },
  {
    chainId: 1,
    address: '0x0c7d5ae016f806603cb1782bea29ac69471cab9c',
    name: 'Bifrost',
    symbol: 'BFC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4639/thumb/bifrost_32.png?1608520677'
  },
  {
    chainId: 1,
    address: '0xf50b5e535f62a56a9bd2d8e2434204e726c027fa',
    name: 'sFB',
    symbol: 'SFB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14949/thumb/sFB.png?1619129132'
  },
  {
    chainId: 1,
    address: '0x6589fe1271a0f29346796c6baf0cdf619e25e58e',
    name: 'Grain',
    symbol: 'GRAIN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13331/thumb/784594063499853904.png?1607531032'
  },
  {
    chainId: 1,
    address: '0x83984d6142934bb535793a82adb0a46ef0f66b6d',
    name: 'Remme',
    symbol: 'REM',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/2152/thumb/semme.png?1561622861'
  },
  {
    chainId: 1,
    address: '0xdb25f211ab05b1c97d595516f45794528a807ad8',
    name: 'STASIS EURO',
    symbol: 'EURS',
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/5164/thumb/EURS_300x300.png?1550571779'
  },
  {
    chainId: 1,
    address: '0xe4883bcb919386bb5f48ef59b7c31c1d93a51a57',
    name: 'Satopay Yield Token',
    symbol: 'SPY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12676/thumb/LseN1lD.png?1601531549'
  },
  {
    chainId: 1,
    address: '0xd375eed3549cbc8243358ef3bd6026e2c2dc8e53',
    name: 'CSC JACKPOT',
    symbol: 'CSCJ',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/9146/thumb/image.png?1564744978'
  },
  {
    chainId: 1,
    address: '0x990e081a7b7d3ccba26a2f49746a68cc4ff73280',
    name: 'KStarCoin',
    symbol: 'KSC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1630/thumb/ksc.png?1547035850'
  },
  {
    chainId: 1,
    address: '0x158079ee67fce2f58472a96584a73c7ab9ac95c1',
    name: 'cREP',
    symbol: 'CREP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10822/thumb/cbat.png?1584331275'
  },
  {
    chainId: 1,
    address: '0xe1ba0fb44ccb0d11b80f92f4f8ed94ca3ff51d00',
    name: 'Aave BAT v1',
    symbol: 'ABAT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11726/thumb/aBAT.png?1593086569'
  },
  {
    chainId: 1,
    address: '0x55eb5288c9b65037a4cd2289637f38a4f9db3a6b',
    name: 'KAWANGGAWA',
    symbol: 'KGW',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11815/thumb/f_HFXjBE_400x400.jpg?1594597195'
  },
  {
    chainId: 1,
    address: '0x05aaaa829afa407d83315cded1d45eb16025910c',
    name: 'Sp8de',
    symbol: 'SPX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2513/thumb/LSGJfuU.png?1547036622'
  },
  {
    chainId: 1,
    address: '0x03e4bdce611104289333f35c8177558b04cc99ff',
    name: 'Yield Stake Finance',
    symbol: 'YI12',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12708/thumb/yi12_logo.jpg?1601894672'
  },
  {
    chainId: 1,
    address: '0xd03b6ae96cae26b743a6207dcee7cbe60a425c70',
    name: 'UniDexBot',
    symbol: 'UNDB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12481/thumb/logo_128.png?1601814643'
  },
  {
    chainId: 1,
    address: '0xf8ed6c51762208ff26f8f3e4efd4e06af2da649c',
    name: 'Alldex Alliance',
    symbol: 'AXA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9438/thumb/alldex.png?1569122383'
  },
  {
    chainId: 1,
    address: '0xb9440022a095343b440d590fcd2d7a3794bd76c8',
    name: 'Saturn DAO Token',
    symbol: 'SATURN',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/3958/thumb/saturn.png?1547038986'
  },
  {
    chainId: 1,
    address: '0xb7cb1c96db6b22b0d3d9536e0108d062bd488f74',
    name: 'Waltonchain',
    symbol: 'WTC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1093/thumb/ggx6nnW.png?1604815811'
  },
  {
    chainId: 1,
    address: '0x62f8dbdea37a4ed40ff3d2631001e4490c13218f',
    name: 'Wall Street Baby',
    symbol: 'WSB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15042/thumb/Wall_Street_Baby_200x200_logo.png?1619517949'
  },
  {
    chainId: 1,
    address: '0x4922a015c4407f87432b179bb209e125432e4a2a',
    name: 'Tether Gold',
    symbol: 'XAUT',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/10481/thumb/tether-gold.png?1579946148'
  },
  {
    chainId: 1,
    address: '0x4df812f6064def1e5e029f1ca858777cc98d2d81',
    name: 'Xaurum',
    symbol: 'XAUR',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/461/thumb/xaurum.png?1548761197'
  },
  {
    chainId: 1,
    address: '0xa6e7dc135bdf4b3fee7183eab2e87c0bb9684783',
    name: 'BIGOCOIN',
    symbol: 'BIGO',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10564/thumb/Bigocoin_1200px.jpg?1597463586'
  },
  {
    chainId: 1,
    address: '0x51e00a95748dbd2a3f47bc5c3b3e7b3f0fea666c',
    name: 'DAOventures',
    symbol: 'DVG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13971/thumb/DAOventures.co_final_icon_200x200.png?1613388224'
  },
  {
    chainId: 1,
    address: '0xdaab5e695bb0e8ce8384ee56ba38fa8290618e52',
    name: 'CRDT',
    symbol: 'CRDT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11859/thumb/image_%281%29.png?1600937373'
  },
  {
    chainId: 1,
    address: '0xe0ad1806fd3e7edf6ff52fdb822432e847411033',
    name: 'OnX Finance',
    symbol: 'ONX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13445/thumb/onxlogo-1.png?1608629659'
  },
  {
    chainId: 1,
    address: '0xb05af453011d7ad68a92b0065ffd9d1277ed2741',
    name: 'Team Finance',
    symbol: 'TEAM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12480/thumb/team_token_logo.jpg?1600158847'
  },
  {
    chainId: 1,
    address: '0x8a9c4dfe8b9d8962b31e4e16f8321c44d48e246e',
    name: 'Name Change Token',
    symbol: 'NCT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13826/thumb/hashmasks-nct.png?1612879286'
  },
  {
    chainId: 1,
    address: '0xbaa2c10ea6409b58870b5c773c0ff429af7c6f20',
    name: 'BuyPay',
    symbol: 'WBPC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14154/thumb/NnUycReC_400x400.jpg?1614670926'
  },
  {
    chainId: 1,
    address: '0x454cb9d0845bb4a28462f98c21a4fafd16ceb25f',
    name: 'Yearn finance Infra',
    symbol: 'YLAB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12744/thumb/kKGUHNFn_400x400.jpg?1602193258'
  },
  {
    chainId: 1,
    address: '0xa499648fd0e80fd911972bbeb069e4c20e68bf22',
    name: 'Jenny Metaverse DAO',
    symbol: 'UJENNY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15453/thumb/ujenny.png?1620870247'
  },
  {
    chainId: 1,
    address: '0x2791bfd60d232150bff86b39b7146c0eaaa2ba81',
    name: 'BiFi',
    symbol: 'BIFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13671/thumb/ysYIu7Q.png?1610679337'
  },
  {
    chainId: 1,
    address: '0x48783486ddd7fa85eca6b0c4ae8920bc25dfbcd7',
    name: 'GoMoney2',
    symbol: 'GOM2',
    decimals: 0,
    logoURI:
      'https://assets.coingecko.com/coins/images/10374/thumb/lvAhDIqmH0fh6U3NIiYLmKETR3uUBcySAv-K28eW6CCFm-ODhCdId71Ug5c4TCoEtxsre30Efe08muctK0MlK-JPdAbxilzZ7dHyiBNOCvcc_9AmJIo09TRLaiAafgqcFKsxpNOON2D28oTLnVTaqwxWL8zKSzjbI6ChKTCJKOiM2mq7VhQRZYe93StR30mf2O7DnkqmGEbZ5_i.jpg?1578675305'
  },
  {
    chainId: 1,
    address: '0x62b9c7356a2dc64a1969e19c23e4f579f9810aa7',
    name: 'Convex CRV',
    symbol: 'CVXCRV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15586/thumb/convex-crv.png?1621255952'
  },
  {
    chainId: 1,
    address: '0x187eff9690e1f1a61d578c7c492296eaab82701a',
    name: 'Moar Finance',
    symbol: 'MOAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15057/thumb/moar.PNG?1619589388'
  },
  {
    chainId: 1,
    address: '0x38e4adb44ef08f22f5b5b76a8f0c2d0dcbe7dca1',
    name: 'PowerPool Concentra',
    symbol: 'CVP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12266/thumb/Powerpool.jpg?1598621373'
  },
  {
    chainId: 1,
    address: '0xff75ced57419bcaebe5f05254983b013b0646ef5',
    name: 'Cook',
    symbol: 'COOK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14603/thumb/Cook.png?1617210675'
  },
  {
    chainId: 1,
    address: '0x469eda64aed3a3ad6f868c44564291aa415cb1d9',
    name: 'Datamine FLUX',
    symbol: 'FLUX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11756/thumb/fluxres.png?1593748917'
  },
  {
    chainId: 1,
    address: '0x7c2e5b7ec572199d3841f6a38f7d4868bd0798f1',
    name: 'Havy',
    symbol: 'HAVY',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/6043/thumb/havy.png?1547041989'
  },
  {
    chainId: 1,
    address: '0x712db54daa836b53ef1ecbb9c6ba3b9efb073f40',
    name: 'Aave ENJ v1',
    symbol: 'AENJ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12484/thumb/aENJ.png?1600189443'
  },
  {
    chainId: 1,
    address: '0xe653967f0beb6e313c6350f20bca9a5f4c636eed',
    name: 'MISS',
    symbol: 'MISS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9798/thumb/ABUIABAEGAAg-trN6wUorOP-hQEwiAM4rwI.png?1571907729'
  },
  {
    chainId: 1,
    address: '0x796e47b85a0d759f300f1de96a3583004235d4d8',
    name: 'Electrum Dark',
    symbol: 'ELD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6469/thumb/logo_%2817%29.png?1547042683'
  },
  {
    chainId: 1,
    address: '0x975769557510167d25beed6e32806537173e292c',
    name: 'Derivex',
    symbol: 'DVX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11696/thumb/dvx.png?1592880992'
  },
  {
    chainId: 1,
    address: '0x70d2b7c19352bb76e4409858ff5746e500f2b67c',
    name: 'Pawtocol',
    symbol: 'UPI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12186/thumb/pawtocol.jpg?1597962008'
  },
  {
    chainId: 1,
    address: '0x5bc25f649fc4e26069ddf4cf4010f9f706c23831',
    name: 'DefiDollar',
    symbol: 'DUSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12333/thumb/defidollar_logo.png?1599116360'
  },
  {
    chainId: 1,
    address: '0x09e64c2b61a5f1690ee6fbed9baf5d6990f8dfd0',
    name: 'GROWTH DeFi',
    symbol: 'GRO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12239/thumb/growthdefi_logo.png?1598438196'
  },
  {
    chainId: 1,
    address: '0xb944b46bbd4ccca90c962ef225e2804e46691ccf',
    name: 'Decore',
    symbol: 'DCORE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12746/thumb/86jI-acy_400x400.png?1602208062'
  },
  {
    chainId: 1,
    address: '0xaffdb768e5f909b9a6ed110ad724b5e454670c08',
    name: 'Unicly Air Jordan 1',
    symbol: 'UJORD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15675/thumb/ujord.jpg?1621498536'
  },
  {
    chainId: 1,
    address: '0x23b608675a2b2fb1890d3abbd85c5775c51691d5',
    name: 'Unisocks',
    symbol: 'SOCKS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10717/thumb/qFrcoiM.png?1582525244'
  },
  {
    chainId: 1,
    address: '0x30c2a84aed6db30e31cf4d7059b1836c12c68068',
    name: 'Unicly Aavegotchi A',
    symbol: 'UGOTCHI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14901/thumb/uGOTCHI.jpg?1618936478'
  },
  {
    chainId: 1,
    address: '0xd1e06952708771f71e6dd18f06ee418f6e8fc564',
    name: 'GazeTV',
    symbol: 'GAZE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14717/thumb/Gaze_Icon_Positive.png?1617937326'
  },
  {
    chainId: 1,
    address: '0x05f9abf4b0c5661e83b92c056a8791d5ccd7ca52',
    name: 'JOOS Protocol',
    symbol: 'JOOS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7819/thumb/unaAbzXx_400x400.jpg?1550816726'
  },
  {
    chainId: 1,
    address: '0xdbdd6f355a37b94e6c7d32fef548e98a280b8df5',
    name: 'UniWhales',
    symbol: 'UWL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13166/thumb/uniwhale.png?1611967645'
  },
  {
    chainId: 1,
    address: '0x798d1be841a82a273720ce31c822c61a67a601c3',
    name: 'DIGG',
    symbol: 'DIGG',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13737/thumb/digg.PNG?1611292196'
  },
  {
    chainId: 1,
    address: '0x33811d4edbcaed10a685254eb5d3c4e4398520d2',
    name: 'YFE Money',
    symbol: 'YFE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12644/thumb/logo-round.png?1601373377'
  },
  {
    chainId: 1,
    address: '0x62dc4817588d53a056cbbd18231d91ffccd34b2a',
    name: 'DeHive',
    symbol: 'DHV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14926/thumb/logo_200x200.png?1619068048'
  },
  {
    chainId: 1,
    address: '0x6f40d4a6237c257fff2db00fa0510deeecd303eb',
    name: 'Instadapp',
    symbol: 'INST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14688/thumb/30hFM0-n_400x400.jpg?1617786420'
  },
  {
    chainId: 1,
    address: '0xa3e7833775a9cc022db2c72eb900378f90ce89b4',
    name: 'Malamute Finance',
    symbol: 'MALLY',
    decimals: 9,
    logoURI:
      'https://assets.coingecko.com/coins/images/15557/thumb/gcR86YQq1hlWKQLuct0NEgqJOzsUaLHxyWU9ywfG4Afib4__0kjH2JBGs5sHsg0IE4Qa9hSEwZgAmiXj6WHlmQuPpgEb1PxtG7_znZxxgR99qpv9vn6MuW5lJzeSFOmDdlQ3nVAaolnNHqnslwZFZn5sCLGyZxsqDubRmADY7EqRFBW8ncZPcU6j1X0TVJ6SwSn7kV-DOeq0ZAa.jpg?1621205259'
  },
  {
    chainId: 1,
    address: '0x1c9491865a1de77c5b6e19d2e6a5f1d7a6f2b25f',
    name: 'AntiMatter',
    symbol: 'MATTER',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14112/thumb/antimatter_icon.png?1614395992'
  },
  {
    chainId: 1,
    address: '0xcbfef8fdd706cde6f208460f2bf39aa9c785f05d',
    name: 'Kine Protocol',
    symbol: 'KINE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14339/thumb/kine.jpg?1615474456'
  },
  {
    chainId: 1,
    address: '0xf7269a10e85d4aa8282529516cf86847748da2bf',
    name: 'Candela Coin',
    symbol: 'CLA',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/13139/thumb/Easy_To_Use__13_-removebg-preview_bumlw2.png?1605591087'
  },
  {
    chainId: 1,
    address: '0x41c028a4c1f461ebfc3af91619b240004ebad216',
    name: 'TacoToken',
    symbol: 'TACO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14515/thumb/wnQfs2i.jpeg?1616645310'
  },
  {
    chainId: 1,
    address: '0x998ffe1e43facffb941dc337dd0468d52ba5b48a',
    name: 'Rupiah Token',
    symbol: 'IDRT',
    decimals: 2,
    logoURI:
      'https://assets.coingecko.com/coins/images/9441/thumb/57421944_1371636006308255_3647136573922738176_n.jpg?1567462531'
  },
  {
    chainId: 1,
    address: '0xcfe4f03c3afbb9857b29fc706180bf0044900d59',
    name: 'Golden Ratio Coin',
    symbol: 'GOLDR',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10363/thumb/original_GRO-768x768.png?1578612119'
  },
  {
    chainId: 1,
    address: '0x45af324f53a8d7da1752dad74adc1748126d7978',
    name: 'MyTVchain',
    symbol: 'MYTV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7026/thumb/MYTV-200x200.png?1585697417'
  },
  {
    chainId: 1,
    address: '0x308564dc5217c39386f5eae96545159e1d396661',
    name: 'HLP Token',
    symbol: 'HLP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12668/thumb/simbolo-hlp-token.png?1601467816'
  },
  {
    chainId: 1,
    address: '0x6523203bd28d399068acc14db6b7f31d9bf43f1a',
    name: 'Balloon Coin',
    symbol: 'BALO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11142/thumb/logo-2.png?1588918249'
  },
  {
    chainId: 1,
    address: '0xcd2828fc4d8e8a0ede91bb38cf64b1a81de65bf6',
    name: 'Oddz',
    symbol: 'ODDZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14421/thumb/img_circle_200x200.png?1617345151'
  },
  {
    chainId: 1,
    address: '0xe202873079913858f9ba8795ba957a4ad561ca24',
    name: 'Wifi Coin',
    symbol: 'WIFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13500/thumb/logo-wifi-1.png?1609195292'
  },
  {
    chainId: 1,
    address: '0x080eb7238031f97ff011e273d6cad5ad0c2de532',
    name: 'Kittoken',
    symbol: 'KIT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7495/thumb/hQ3j84k.png?1548561187'
  },
  {
    chainId: 1,
    address: '0x1fdab294eda5112b7d066ed8f2e4e562d5bcc664',
    name: 'SPICE',
    symbol: 'SPICE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13398/thumb/scifi_logo.png?1608160393'
  },
  {
    chainId: 1,
    address: '0x6e765d26388a17a6e86c49a8e41df3f58abcd337',
    name: 'Kangal',
    symbol: 'KANGAL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14241/thumb/logo-200.png?1621509021'
  },
  {
    chainId: 1,
    address: '0x6febdfc0a9d9502c45343fce0df08828def44795',
    name: '3X Short BNB Token',
    symbol: 'BNBBEAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10242/thumb/683JEXMN_400x400_%281%29.png?1576633870'
  },
  {
    chainId: 1,
    address: '0xf8c3527cc04340b208c854e985240c02f7b7793f',
    name: 'Frontier',
    symbol: 'FRONT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12479/thumb/frontier_logo.png?1600145472'
  },
  {
    chainId: 1,
    address: '0x04969cd041c0cafb6ac462bd65b536a5bdb3a670',
    name: 'Wrapped ECOMI',
    symbol: 'WOMI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14675/thumb/ecomi.jpg?1617689100'
  },
  {
    chainId: 1,
    address: '0x71fc860f7d3a592a4a98740e39db31d25db65ae8',
    name: 'Aave USDT v1',
    symbol: 'AUSDT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11725/thumb/aUSDT.png?1593082796'
  },
  {
    chainId: 1,
    address: '0xa3bed4e1c75d00fa6f4e5e6922db7261b5e9acd2',
    name: 'mStable Governance ',
    symbol: 'MTA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11846/thumb/mStable.png?1594950533'
  },
  {
    chainId: 1,
    address: '0x04b5e13000c6e9a3255dc057091f3e3eeee7b0f0',
    name: 'Unifund',
    symbol: 'IFUND',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12833/thumb/unifund_logo.png?1602859047'
  },
  {
    chainId: 1,
    address: '0xb1cd6e4153b2a390cf00a6556b0fc1458c4a5533',
    name: 'ETHBNT Relay',
    symbol: 'ETHBNT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10664/thumb/ETHBNT_Relay.png?1581486882'
  },
  {
    chainId: 1,
    address: '0x0a2a86bb0bee386a11291d5d01e89cdfb565df5d',
    name: 'Bitcoin Bull',
    symbol: 'BITB',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/7250/thumb/UbGPtAkD_400x400.jpg?1547043852'
  },
  {
    chainId: 1,
    address: '0x714b1fded61090a6c49eb0b4d088b8e5ebd64e61',
    name: 'To The Moon',
    symbol: 'TTM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11604/thumb/download_%2814%29.png?1591772757'
  },
  {
    chainId: 1,
    address: '0xd8ef149b4e1e8f050d52925f9c68d3a296e77227',
    name: 'Blucon',
    symbol: 'BEP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9918/thumb/f279b103b55c6.png?1573458436'
  },
  {
    chainId: 1,
    address: '0x68b1cadb8d5ab0c97fe9d9fbe0eb60acb329fe3f',
    name: 'Unicly Mystic Axies',
    symbol: 'UAXIE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14921/thumb/uaxie-mystic.png?1619055573'
  },
  {
    chainId: 1,
    address: '0xaac41ec512808d64625576eddd580e7ea40ef8b2',
    name: 'Gameswap',
    symbol: 'GSWAP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13026/thumb/gameswap.jpg?1604456704'
  },
  {
    chainId: 1,
    address: '0x35a18000230da775cac24873d00ff85bccded550',
    name: 'cUNI',
    symbol: 'CUNI',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12696/thumb/compound-uni.png?1601789718'
  },
  {
    chainId: 1,
    address: '0x2a3bff78b79a009976eea096a51a948a3dc00e34',
    name: 'Wilder World',
    symbol: 'WILD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15407/thumb/WWLogo_Gradient_Cirlce.png?1620743969'
  },
  {
    chainId: 1,
    address: '0xa685a61171bb30d4072b338c80cb7b2c865c873e',
    name: 'Aave MANA',
    symbol: 'AMANA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14267/thumb/aMANA.f89a1098.png?1615528680'
  },
  {
    chainId: 1,
    address: '0x220b71671b649c03714da9c621285943f3cbcdc6',
    name: 'TosDis',
    symbol: 'DIS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13745/thumb/Tosdis-black.png?1611379744'
  },
  {
    chainId: 1,
    address: '0x80c8c3dcfb854f9542567c8dac3f44d709ebc1de',
    name: 'Spaceswap MILK2',
    symbol: 'MILK2',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12771/thumb/PVXczB4M.png?1602482463'
  },
  {
    chainId: 1,
    address: '0xc2bd7a597391f31d2e36c2cb769fce0e5bd6d482',
    name: 'Anti Lockdown',
    symbol: 'FREE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15204/thumb/FREE.jpg?1620097136'
  },
  {
    chainId: 1,
    address: '0x81f09ed4b98b1c8e99b1fa838b72acb842afe94c',
    name: '3X Long PAX Gold To',
    symbol: 'PAXGBULL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10435/thumb/683JEXMN_400x400.png?1579276663'
  },
  {
    chainId: 1,
    address: '0x4fab740779c73aa3945a5cf6025bf1b0e7f6349c',
    name: 'Dirty Finance',
    symbol: 'DIRTY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15326/thumb/DIRTY-token-logo-200.png?1620514355'
  },
  {
    chainId: 1,
    address: '0xae353daeed8dcc7a9a12027f7e070c0a50b7b6a4',
    name: 'InnovaMinex',
    symbol: 'MINX',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/7116/thumb/innovaminex.jpg?1547043613'
  },
  {
    chainId: 1,
    address: '0xf29992d7b589a0a6bd2de7be29a97a6eb73eaf85',
    name: 'DMScript',
    symbol: 'DMST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11896/thumb/h0snSnDE_400x400.jpg?1595892384'
  },
  {
    chainId: 1,
    address: '0x64cdf819d3e75ac8ec217b3496d7ce167be42e80',
    name: 'InsurePal',
    symbol: 'IPL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1858/thumb/ipl.png?1547036172'
  },
  {
    chainId: 1,
    address: '0x26cf82e4ae43d31ea51e72b663d26e26a75af729',
    name: 'Moonbase',
    symbol: 'MBBASED',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12694/thumb/mb-logo.png?1601715131'
  },
  {
    chainId: 1,
    address: '0x537edd52ebcb9f48ff2f8a28c51fcdb9d6a6e0d4',
    name: 'Small Doge',
    symbol: 'SDOG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15377/thumb/small_dog.PNG?1620693455'
  },
  {
    chainId: 1,
    address: '0xbcd4b7de6fde81025f74426d43165a5b0d790fdd',
    name: 'SpiderDAO',
    symbol: 'SPDR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13378/thumb/spiderdao_logo.png?1608029180'
  },
  {
    chainId: 1,
    address: '0xe4f6d46c244bb7cf3e218cdfb5c35cf9a4d9c920',
    name: 'Donkey',
    symbol: 'DONK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15330/thumb/donkey_logo.jpg?1620549377'
  },
  {
    chainId: 1,
    address: '0x9f31fab2405dfba05a487ebce88f3abd26f1cba6',
    name: 'Agricultural Trade ',
    symbol: 'AAT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10261/thumb/logo_%2836%29.png?1576796028'
  },
  {
    chainId: 1,
    address: '0x226bb599a12c826476e3a771454697ea52e9e220',
    name: 'Propy',
    symbol: 'PRO',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/869/thumb/propy.png?1548332100'
  },
  {
    chainId: 1,
    address: '0xe2f2a5c287993345a840db3b0845fbc70f5935a5',
    name: 'mStable USD',
    symbol: 'MUSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11576/thumb/mStable_USD.png?1595591803'
  },
  {
    chainId: 1,
    address: '0x85f6eb2bd5a062f5f8560be93fb7147e16c81472',
    name: 'Franklin',
    symbol: 'FLY',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/14810/thumb/uC05I_Kc_400x400.jpg?1618539481'
  },
  {
    chainId: 1,
    address: '0x5caf454ba92e6f2c929df14667ee360ed9fd5b26',
    name: 'Dev Protocol',
    symbol: 'DEV',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/11560/thumb/Dev_Protocol__CoinGecko_Logo___Jan.18.2021_.png?1611021474'
  },
  {
    chainId: 1,
    address: '0x6a404a3386655bd8b63e584f2efd2e3fb60e70f8',
    name: 'Latex Chain',
    symbol: 'LXC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8700/thumb/read_%284%29.jpg?1560239246'
  },
  {
    chainId: 1,
    address: '0x8feef860e9fa9326ff9d7e0058f637be8579cc29',
    name: 'Timers',
    symbol: 'IPM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12649/thumb/logo200x200_%282%29.png?1601421807'
  },
  {
    chainId: 1,
    address: '0xbcd8756ea481608ea3dd5a555493305cf0a79640',
    name: 'Paparazzi',
    symbol: 'PAZZI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11558/thumb/jMJC4g9m_400x400.jpg?1591079702'
  },
  {
    chainId: 1,
    address: '0x790bfacae71576107c068f494c8a6302aea640cb',
    name: 'CryptoBossCoin',
    symbol: 'CBC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7114/thumb/eqIkj-ZZ_400x400.jpg?1549521587'
  },
  {
    chainId: 1,
    address: '0xd1517663883e2acc154178fb194e80e8bbc29730',
    name: 'Deipool',
    symbol: 'DIP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13186/thumb/deipool.png?1606085265'
  },
  {
    chainId: 1,
    address: '0xf1f508c7c9f0d1b15a76fba564eef2d956220cf7',
    name: 'Pepedex',
    symbol: 'PPDEX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13022/thumb/output-onlinepngtools-1.png?1604720841'
  },
  {
    chainId: 1,
    address: '0x73968b9a57c6e53d41345fd57a6e6ae27d6cdb2f',
    name: 'Stake DAO',
    symbol: 'SDT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13724/thumb/stakedao_logo.jpg?1611195011'
  },
  {
    chainId: 1,
    address: '0xca1207647ff814039530d7d35df0e1dd2e91fa84',
    name: 'dHEDGE DAO',
    symbol: 'DHT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12508/thumb/dht.png?1600752201'
  },
  {
    chainId: 1,
    address: '0x178bf8fd04b47d2de3ef3f6b3d112106375ad584',
    name: 'Unagii Tether USD',
    symbol: 'UUSDT',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/13778/thumb/uUSDT.png?1611729448'
  },
  {
    chainId: 1,
    address: '0xc4d586ef7be9ebe80bd5ee4fbd228fe2db5f2c4e',
    name: 'Papa Shiba',
    symbol: 'PHIBA',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15473/thumb/200x200_png.png?1620922532'
  },
  {
    chainId: 1,
    address: '0x6e8908cfa881c9f6f2c64d3436e7b80b1bf0093f',
    name: 'Bistroo',
    symbol: 'BIST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15645/thumb/bist.PNG?1621413969'
  },
  {
    chainId: 1,
    address: '0x8d2fab1ff34f1c545673a816f1438b02d0a2e32d',
    name: 'AISF',
    symbol: 'AGT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12859/thumb/AISF_token.jpg?1603095207'
  },
  {
    chainId: 1,
    address: '0x578301f78b7269261cea3c968f0643fb1a101e5e',
    name: 'HolderSwap',
    symbol: 'HFS',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/15332/thumb/200x200_dark.png?1620576541'
  },
  {
    chainId: 1,
    address: '0x8c18d6a985ef69744b9d57248a45c0861874f244',
    name: 'ClinTex CTi',
    symbol: 'CTI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13266/thumb/CTI.png?1606817542'
  },
  {
    chainId: 1,
    address: '0x1c95b093d6c236d3ef7c796fe33f9cc6b8606714',
    name: 'BOMB',
    symbol: 'BOMB',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/7713/thumb/Bomb-token.png?1549944422'
  },
  {
    chainId: 1,
    address: '0x60ca261e14f26e8daae8b1a7f8e783d64859126c',
    name: 'STONKS',
    symbol: 'STONK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13111/thumb/stonk_logo.png?1605278755'
  },
  {
    chainId: 1,
    address: '0x41bbedd7286daab5910a1f15d12cbda839852bd7',
    name: 'Mirrored Microsoft',
    symbol: 'MMSFT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13396/thumb/mirror_logo_transparent.png?1611564779'
  },
  {
    chainId: 1,
    address: '0xb05097849bca421a3f51b249ba6cca4af4b97cb9',
    name: 'Float Protocol  Flo',
    symbol: 'FLOAT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15502/thumb/FLOAT_LOGO-reduced_01.png?1621081152'
  },
  {
    chainId: 1,
    address: '0x77f39c61ddac121ca1b24fdea984fab734fdce8f',
    name: 'Shiba Launch',
    symbol: 'SHIBAL',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15480/thumb/shibal.jpg?1621008148'
  },
  {
    chainId: 1,
    address: '0x32c4adb9cf57f972bc375129de91c897b4f364f1',
    name: 'Flowchain',
    symbol: 'FLC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7446/thumb/logo_%2889%29.png?1597459811'
  },
  {
    chainId: 1,
    address: '0x3b73c1b2ea59835cbfcadade5462b6ab630d9890',
    name: 'Chainswap',
    symbol: 'TOKEN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14945/thumb/chainswap_logo.png?1619109717'
  },
  {
    chainId: 1,
    address: '0x35bd01fc9d6d5d81ca9e055db88dc49aa2c699a8',
    name: 'Friends With Benefi',
    symbol: 'FWB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14391/thumb/xRGEXmQN_400x400.png?1615868085'
  },
  {
    chainId: 1,
    address: '0x91d6f6e9026e43240ce6f06af6a4b33129ebde94',
    name: 'Rivex',
    symbol: 'RVX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11644/thumb/200px_logo_RX_3d-01.png?1602130114'
  },
  {
    chainId: 1,
    address: '0x613fa2a6e6daa70c659060e86ba1443d2679c9d7',
    name: 'ParkinGo',
    symbol: 'GOT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6034/thumb/GOToken.png?1547041981'
  },
  {
    chainId: 1,
    address: '0x544c42fbb96b39b21df61cf322b5edc285ee7429',
    name: 'InsurAce',
    symbol: 'INSUR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14226/thumb/insur.png?1615124622'
  },
  {
    chainId: 1,
    address: '0x0affa06e7fbe5bc9a764c979aa66e8256a631f02',
    name: 'Polybius',
    symbol: 'PLBT',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/766/thumb/polybius.png?1547034516'
  },
  {
    chainId: 1,
    address: '0x97941ff1962026955852e9609e202d1058bc0f48',
    name: 'Va Na Su',
    symbol: 'VNS',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12485/thumb/platform-img1.png?1600206964'
  },
  {
    chainId: 1,
    address: '0xe1c7e30c42c24582888c758984f6e382096786bd',
    name: 'Curate',
    symbol: 'XCUR',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13327/thumb/400x400_%281%29_%283%29_%282%29.png?1613998208'
  },
  {
    chainId: 1,
    address: '0x4b4eb5c44d50bfd44124688c6754633f7e258b01',
    name: 'SubGame',
    symbol: 'SGB',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/15422/thumb/logo_200_200.png?1620785105'
  },
  {
    chainId: 1,
    address: '0x03282f2d7834a97369cad58f888ada19eec46ab6',
    name: 'Globex',
    symbol: 'GEX',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/7391/thumb/globex.png?1592450726'
  },
  {
    chainId: 1,
    address: '0x574f84108a98c575794f75483d801d1d5dc861a5',
    name: 'Robotina',
    symbol: 'ROX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3153/thumb/rox.png?1519831400'
  },
  {
    chainId: 1,
    address: '0xc4d5545392f5fc57eba3af8981815669bb7e2a48',
    name: 'HEdpAY',
    symbol: 'HDP',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/7496/thumb/icon_hedpay.png?1555485710'
  },
  {
    chainId: 1,
    address: '0x33e18a092a93ff21ad04746c7da12e35d34dc7c4',
    name: 'Metaverse NFT Index',
    symbol: 'PLAY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14590/thumb/PLAY.png?1618308275'
  },
  {
    chainId: 1,
    address: '0xa487bf43cf3b10dffc97a9a744cbb7036965d3b9',
    name: 'Deri Protocol',
    symbol: 'DERI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13931/thumb/deri_logo.png?1612916502'
  },
  {
    chainId: 1,
    address: '0xc7283b66eb1eb5fb86327f08e1b5816b0720212b',
    name: 'Tribe',
    symbol: 'TRIBE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14575/thumb/tribe.PNG?1617487954'
  },
  {
    chainId: 1,
    address: '0xd5525d397898e5502075ea5e830d8914f6f0affe',
    name: 'Meme',
    symbol: 'MEME',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12140/thumb/meme-coin.jpeg?1619249191'
  },
  {
    chainId: 1,
    address: '0xf650c3d88d12db855b8bf7d11be6c55a4e07dcc9',
    name: 'cUSDT',
    symbol: 'CUSDT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11621/thumb/cUSDT.png?1592113270'
  },
  {
    chainId: 1,
    address: '0x9aeb50f542050172359a0e1a25a9933bc8c01259',
    name: 'OIN Finance',
    symbol: 'OIN',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12339/thumb/oin_finance_logo.jpg?1599137603'
  },
  {
    chainId: 1,
    address: '0xf6ea0e60cee427b90aa36f327ee61d1d4cabc49f',
    name: 'Unique Photo',
    symbol: 'FOTO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14274/thumb/iG1Pf7kL_400x400.png?1615238642'
  },
  {
    chainId: 1,
    address: '0xaa843f65872a25d6e9552ea0b360fb1d5e333124',
    name: 'Eco Value Coin',
    symbol: 'EVC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7868/thumb/temp_1524073947125.-325764774.png?1561602099'
  },
  {
    chainId: 1,
    address: '0x679badc551626e01b23ceecefbc9b877ea18fc46',
    name: 'Ccore',
    symbol: 'CCO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1287/thumb/LOGO_CCO.png?1547035312'
  },
  {
    chainId: 1,
    address: '0x2c2f7e7c5604d162d75641256b80f1bf6f4dc796',
    name: 'Polkarare',
    symbol: 'PRARE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15388/thumb/Image_from_iOS.png?1621145396'
  },
  {
    chainId: 1,
    address: '0xb3319f5d18bc0d84dd1b4825dcde5d5f7266d407',
    name: 'c0x',
    symbol: 'CZRX',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10644/thumb/czrx1.JPG?1581390510'
  },
  {
    chainId: 1,
    address: '0xb8647e90c0645152fccf4d9abb6b59eb4aa99052',
    name: 'KeyFi',
    symbol: 'KEYFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15098/thumb/keyfi_logo.jpg?1619690054'
  },
  {
    chainId: 1,
    address: '0xeb953eda0dc65e3246f43dc8fa13f35623bdd5ed',
    name: 'Rainicorn',
    symbol: 'RAINI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14491/thumb/raini-pixel-200.png?1616487155'
  },
  {
    chainId: 1,
    address: '0x9a0aba393aac4dfbff4333b06c407458002c6183',
    name: 'ACoconut',
    symbol: 'AC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12779/thumb/ac_logo.png?1602500084'
  },
  {
    chainId: 1,
    address: '0x6fce4a401b6b80ace52baaefe4421bd188e76f6f',
    name: 'Aave MANA v1',
    symbol: 'AMANA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11730/thumb/aMANA.png?1593084474'
  },
  {
    chainId: 1,
    address: '0xa49d7499271ae71cd8ab9ac515e6694c755d400c',
    name: 'Mute',
    symbol: 'MUTE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14331/thumb/MUTE.png?1617618967'
  },
  {
    chainId: 1,
    address: '0x5fb99a3593d7088e44377a1beb27739bd928f269',
    name: 'Unicly Chris McCann',
    symbol: 'UCM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14913/thumb/logo.png?1618995818'
  },
  {
    chainId: 1,
    address: '0xc52c326331e9ce41f04484d3b5e5648158028804',
    name: 'Unizen',
    symbol: 'ZCX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14830/thumb/7xjpHaG.png?1618564961'
  },
  {
    chainId: 1,
    address: '0xbdc5bac39dbe132b1e030e898ae3830017d7d969',
    name: 'Snovian Space',
    symbol: 'SNOV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1330/thumb/logo2.png?1547035355'
  },
  {
    chainId: 1,
    address: '0x2bc586ffbfa2d6e9c30d5ad95d546091db7607cf',
    name: 'Magic E stock',
    symbol: 'MSB',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/14194/thumb/magic_e_stock.PNG?1614848770'
  },
  {
    chainId: 1,
    address: '0x8a40c222996f9f3431f63bf80244c36822060f12',
    name: 'Finxflo',
    symbol: 'FXF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13916/thumb/raVh1Iw.png?1618978607'
  },
  {
    chainId: 1,
    address: '0xa06bc25b5805d5f8d82847d191cb4af5a3e873e0',
    name: 'Aave LINK',
    symbol: 'ALINK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14315/thumb/aLINK.412c6589.png?1615527827'
  },
  {
    chainId: 1,
    address: '0x1b4052d98fb1888c2bf3b8d3b930e0aff8a910df',
    name: 'Community Token',
    symbol: 'COM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12145/thumb/COKK-mq__400x400.png?1615441730'
  },
  {
    chainId: 1,
    address: '0x459086f2376525bdceba5bdda135e4e9d3fef5bf',
    name: 'renBCH',
    symbol: 'RENBCH',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11563/thumb/renBCH.png?1591185978'
  },
  {
    chainId: 1,
    address: '0x74232704659ef37c08995e386a2e26cc27a8d7b1',
    name: 'Strike',
    symbol: 'STRK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14607/thumb/Jw-36llq_400x400.jpg?1617243607'
  },
  {
    chainId: 1,
    address: '0x1a3496c18d558bd9c6c8f609e1b129f67ab08163',
    name: 'DEAPCOIN',
    symbol: 'DEP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10970/thumb/DEAPcoin_01.png?1586741677'
  },
  {
    chainId: 1,
    address: '0x275f5ad03be0fa221b4c6649b8aee09a42d9412a',
    name: 'Monavale',
    symbol: 'MONA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13298/thumb/monavale_logo.jpg?1607232721'
  },
  {
    chainId: 1,
    address: '0x26ce25148832c04f3d7f26f32478a9fe55197166',
    name: 'DexTools',
    symbol: 'DEXT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11603/thumb/dext.png?1605790188'
  },
  {
    chainId: 1,
    address: '0xf4d861575ecc9493420a3f5a14f85b13f0b50eb3',
    name: 'Fractal',
    symbol: 'FCL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14096/thumb/fractal_logo.png?1614264502'
  },
  {
    chainId: 1,
    address: '0x7ddc52c4de30e94be3a6a0a2b259b2850f421989',
    name: 'GoMining Token',
    symbol: 'GMT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15662/thumb/Logo_GMT_200x200.png?1621488989'
  },
  {
    chainId: 1,
    address: '0x809826cceab68c387726af962713b64cb5cb3cca',
    name: 'Nucleus Vision',
    symbol: 'NCASH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3135/thumb/nv.jpg?1547037544'
  },
  {
    chainId: 1,
    address: '0x88930072f583936f506ce1f1d5fe69290c2d6a2a',
    name: 'Civitas Protocol',
    symbol: 'CVT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13829/thumb/ctr.png?1616384860'
  },
  {
    chainId: 1,
    address: '0x3521c85c3000bff57eac04489eb05bbd3193a531',
    name: 'MetaWhale BTC',
    symbol: 'MWBTC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13926/thumb/mwBTC-gecko-light_1.png?1617276456'
  },
  {
    chainId: 1,
    address: '0xe17f017475a709de58e976081eb916081ff4c9d5',
    name: 'RMPL',
    symbol: 'RMPL',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/12122/thumb/rmpl_logo.jpg?1597298400'
  },
  {
    chainId: 1,
    address: '0x6e0dade58d2d89ebbe7afc384e3e4f15b70b14d8',
    name: 'QuiverX',
    symbol: 'QRX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12916/thumb/qrx_logo.png?1603550478'
  },
  {
    chainId: 1,
    address: '0xdf801468a808a32656d2ed2d2d80b72a129739f4',
    name: 'Somnium Space CUBEs',
    symbol: 'CUBE',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10687/thumb/CUBE_icon.png?1617026861'
  },
  {
    chainId: 1,
    address: '0xa5ddfca8b837ccd0cf80fe6c24e2a9018fb50dba',
    name: '3X Short BiLira Tok',
    symbol: 'TRYBBEAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10451/thumb/683JEXMN_400x400.png?1579561868'
  },
  {
    chainId: 1,
    address: '0x2c756e74b7309d785b5e2960ef262c4f14a87930',
    name: 'SPENDER X',
    symbol: 'SPDX',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/9259/thumb/spdx.PNG?1565650786'
  },
  {
    chainId: 1,
    address: '0x40986a85b4cfcdb054a6cbfb1210194fee51af88',
    name: 'UniFarm',
    symbol: 'UFARM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15247/thumb/ufarm.jpeg?1620218765'
  },
  {
    chainId: 1,
    address: '0x657b83a0336561c8f64389a6f5ade675c04b0c3b',
    name: 'Playcent',
    symbol: 'PCNT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14335/thumb/1_B5bFcgBld5poUj_c-_K1Jw.png?1615444831'
  },
  {
    chainId: 1,
    address: '0x2edf094db69d6dcd487f1b3db9febe2eec0dd4c5',
    name: 'ZeroSwap',
    symbol: 'ZEE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12861/thumb/zeroswap.jpg?1603111827'
  },
  {
    chainId: 1,
    address: '0xe8ff5c9c75deb346acac493c463c8950be03dfba',
    name: 'VIBE',
    symbol: 'VIBE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/948/thumb/vibe.png?1547034809'
  },
  {
    chainId: 1,
    address: '0x5a14fe6923e767a8238f29d3e1fb1a913975f0d7',
    name: 'PWay',
    symbol: 'PWAY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14377/thumb/PWay_sygnet_200x200_transparent.png?1615778567'
  },
  {
    chainId: 1,
    address: '0x6888a16ea9792c15a4dcf2f6c623d055c8ede792',
    name: 'Signal Token',
    symbol: 'SIG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3508/thumb/5906667d95a2d43d31378ce7_60x60logo.png?1547038276'
  },
  {
    chainId: 1,
    address: '0xfffffffff15abf397da76f1dcc1a1604f45126db',
    name: 'Falconswap',
    symbol: 'FSW',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12256/thumb/falconswap.png?1598534184'
  },
  {
    chainId: 1,
    address: '0x69bbe2fa02b4d90a944ff328663667dc32786385',
    name: 'Punk Basic',
    symbol: 'PUNK-BASIC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13991/thumb/icon-punk-basic-40.png?1613534206'
  },
  {
    chainId: 1,
    address: '0x86e6a4f512b1290c043970b04e0b570d4fc98291',
    name: 'IntelliShare',
    symbol: 'INE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7092/thumb/logo.png?1547043578'
  },
  {
    chainId: 1,
    address: '0xe9f3cb0229eb8d0aaf03ec84883950134ed20ddc',
    name: 'SLT',
    symbol: 'SLT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/6673/thumb/slt_token.jpg?1547042877'
  },
  {
    chainId: 1,
    address: '0x347c099f110ca6761779329d2879957b606b6ace',
    name: 'Joint Ventures',
    symbol: 'JOINT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3770/thumb/joint.png?1547038860'
  },
  {
    chainId: 1,
    address: '0xc3eb2622190c57429aac3901808994443b64b466',
    name: 'ORO',
    symbol: 'ORO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13114/thumb/oro_logo.png?1605338447'
  },
  {
    chainId: 1,
    address: '0xf3db7560e820834658b590c96234c333cd3d5e5e',
    name: 'CoinPoker',
    symbol: 'CHP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1808/thumb/coinpoker.jpg?1547036113'
  },
  {
    chainId: 1,
    address: '0x6de037ef9ad2725eb40118bb1702ebb27e4aeb24',
    name: 'Render Token',
    symbol: 'RNDR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11636/thumb/uTDd98ZN_400x400.jpg?1592200150'
  },
  {
    chainId: 1,
    address: '0xdd16ec0f66e54d453e6756713e533355989040e4',
    name: 'Tokenomy',
    symbol: 'TEN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2286/thumb/logo_%281%29.png?1604543144'
  },
  {
    chainId: 1,
    address: '0xdf7ff54aacacbff42dfe29dd6144a69b629f8c9e',
    name: 'Aave ZRX',
    symbol: 'AZRX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14264/thumb/aZRX.9be555a9.png?1615528286'
  },
  {
    chainId: 1,
    address: '0x1b2a76da77d03b7fc21189d9838f55bd849014af',
    name: 'DeFiScale',
    symbol: 'DFC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/14974/thumb/logo200x200-2.png?1619214186'
  },
  {
    chainId: 1,
    address: '0x7ca4408137eb639570f8e647d9bd7b7e8717514a',
    name: 'Alpaca City',
    symbol: 'ALPA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13070/thumb/alpaca_logo.png?1604895862'
  },
  {
    chainId: 1,
    address: '0xcca0c9c383076649604ee31b20248bc04fdf61ca',
    name: 'ASD',
    symbol: 'BTMX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5003/thumb/bitmax.png?1621310871'
  },
  {
    chainId: 1,
    address: '0x3c4030839708a20fd2fb379cf11810dde4888d93',
    name: 'IMSWallet',
    symbol: 'IMS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12173/thumb/wqtIArTS_400x400.jpg?1597804992'
  },
  {
    chainId: 1,
    address: '0xed0e2041bfb5a426e5ed426a73765624e08bbb75',
    name: 'FANZY',
    symbol: 'FX1',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11117/thumb/FX.png?1604900016'
  },
  {
    chainId: 1,
    address: '0xb6ee603933e024d8d53dde3faa0bf98fe2a3d6f1',
    name: 'DeFiat',
    symbol: 'DFT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12303/thumb/defiat.jpg?1598985049'
  },
  {
    chainId: 1,
    address: '0xb97faf860045483e0c7f08c56acb31333084a988',
    name: 'Vanilla Network',
    symbol: 'VNLA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13223/thumb/vanilla_network_logo.jpeg?1606352291'
  },
  {
    chainId: 1,
    address: '0xfe9a29ab92522d14fc65880d817214261d8479ae',
    name: 'Snowswap',
    symbol: 'SNOW',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12751/thumb/uQBJL3A.png?1602237225'
  },
  {
    chainId: 1,
    address: '0x7a5ce6abd131ea6b148a022cb76fc180ae3315a6',
    name: 'bAlpha',
    symbol: 'BALPHA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14224/thumb/logo_bAlpha_200.png?1615089190'
  },
  {
    chainId: 1,
    address: '0x2e6539edc3b76f1e21b71d214527faba875f70f3',
    name: 'Yearn Finance DOT',
    symbol: 'YFDOT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12791/thumb/logo_%2815%29.png?1602580328'
  },
  {
    chainId: 1,
    address: '0x7d529a5b3c41126760a0fa3c1a9652d8a7a07793',
    name: 'Narwhale',
    symbol: 'NAWA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14129/thumb/narwhale_logo.png?1614579819'
  },
  {
    chainId: 1,
    address: '0xd2877702675e6ceb975b4a1dff9fb7baf4c91ea9',
    name: 'Wrapped Terra',
    symbol: 'LUNA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13628/thumb/wluna.png?1610448334'
  },
  {
    chainId: 1,
    address: '0x54ee01beb60e745329e6a8711ad2d6cb213e38d7',
    name: 'DefiSocial',
    symbol: 'DFSOCIAL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13538/thumb/DEPI.png?1617093782'
  },
  {
    chainId: 1,
    address: '0xcb98f42221b2c251a4e74a1609722ee09f0cc08e',
    name: 'iDASH',
    symbol: 'IDASH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11806/thumb/iDASH.png?1616151258'
  },
  {
    chainId: 1,
    address: '0x7b0c06043468469967dba22d1af33d77d44056c8',
    name: 'Morpheus Network',
    symbol: 'MRPH',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/2379/thumb/MRPH_.png?1612252243'
  },
  {
    chainId: 1,
    address: '0x057fb10e3fec001a40e6b75d3a30b99e23e54107',
    name: '3X Short Algorand T',
    symbol: 'ALGOBEAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10216/thumb/683JEXMN_400x400_%281%29.png?1576575065'
  },
  {
    chainId: 1,
    address: '0xbc3ec4e491b835dce394a53e9a9a10ac19564839',
    name: 'Starbugs Shards',
    symbol: 'BUGS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12935/thumb/Starbugs.png?1603720230'
  },
  {
    chainId: 1,
    address: '0x51db5ad35c671a87207d88fc11d593ac0c8415bd',
    name: 'Moeda Loyalty Point',
    symbol: 'MDA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/895/thumb/moeda-loyalty-points.png?1547034720'
  },
  {
    chainId: 1,
    address: '0x5d3a4f62124498092ce665f865e0b38ff6f5fbea',
    name: 'Ideaology',
    symbol: 'IDEA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13938/thumb/idea_logo.png?1613022658'
  },
  {
    chainId: 1,
    address: '0x10e1e953ddba597011f8bfa806ab0cc3415a622b',
    name: '1X Short Ethereum T',
    symbol: 'ETHHEDGE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10220/thumb/683JEXMN_400x400_%281%29.png?1576577133'
  },
  {
    chainId: 1,
    address: '0xc690f7c7fcffa6a82b79fab7508c466fefdfc8c5',
    name: 'Lympo',
    symbol: 'LYM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2620/thumb/lympo_token.png?1547036775'
  },
  {
    chainId: 1,
    address: '0x4a6be56a211a4c4e0dd4474d524138933c17f3e3',
    name: 'Baby Shiba',
    symbol: 'BHIBA',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15426/thumb/NAFfUZrX_400x400.jpg?1620788063'
  },
  {
    chainId: 1,
    address: '0xea1ea0972fa092dd463f2968f9bb51cc4c981d71',
    name: 'Modefi',
    symbol: 'MOD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13980/thumb/modefi_logo.png?1613453111'
  },
  {
    chainId: 1,
    address: '0xb647a1d7633c6c4d434e22ee9756b36f2b219525',
    name: 'ETH 20 MA Crossover',
    symbol: 'ETH20MACOAPY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11648/thumb/eth-20-day-moving-average-ctoken-set.png?1592324078'
  },
  {
    chainId: 1,
    address: '0x6bffa07a1b0cebc474ce6833eaf2be6326252449',
    name: 'BAEPAY',
    symbol: 'BAEPAY',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/13101/thumb/baepay_logo.png?1605150696'
  },
  {
    chainId: 1,
    address: '0x540d3087b21f31f9a810385c94627a067cfd0b08',
    name: 'Crypto Candy',
    symbol: 'CANDY',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/13735/thumb/p5xV71o9q0ENOCA_0hi2IGq6Lg3CcdN2wBBfhXc51uSsFdaDYuDR97ampeNGXcdHU06PH1luOQ7o2w8V__Kj9rsxO02H6RDR-eEWwugtc1V-bPlgQPw_PLu1cDxWKeU7qD6Gj3WB3gh1ylF_2OVlv2PC0aKMxcez_oWpFA0WZ5wXX2ZOlIWJH1Qf026dR-QreHnFdO0fmgmMu_K.jpg?1611285084'
  },
  {
    chainId: 1,
    address: '0x018a6106cb540af1ae6da985361afd5e176d1c00',
    name: 'Wiz Coin',
    symbol: 'WIZ1',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14325/thumb/Untitled-design-13.png?1615428834'
  },
  {
    chainId: 1,
    address: '0x72b2b8e42a10d785abf85f3044223db8c9167bd6',
    name: 'Atlantic Coin',
    symbol: 'ATC',
    decimals: 10,
    logoURI: 'https://assets.coingecko.com/coins/images/9151/thumb/atc.PNG?1564732934'
  },
  {
    chainId: 1,
    address: '0x8cea63f6383c1c13633f179f1af70ef75701a979',
    name: 'Thorium',
    symbol: 'TORM',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/11582/thumb/90325333_103101068010096_631989748818247680_o.jpg?1591321493'
  },
  {
    chainId: 1,
    address: '0x06f3c323f0238c72bf35011071f2b5b7f43a054c',
    name: 'MASQ',
    symbol: 'MASQ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13699/thumb/MASQ_Logo_Blue_Solo_Transparent.png?1616661801'
  },
  {
    chainId: 1,
    address: '0xa20b44c001578e2c52665098ba62b00ebf8963cb',
    name: 'REAPit',
    symbol: 'REAP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14608/thumb/reapit.PNG?1617243938'
  },
  {
    chainId: 1,
    address: '0x26946ada5ecb57f3a1f91605050ce45c482c9eb1',
    name: 'BitcoinSoV',
    symbol: 'BSOV',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9205/thumb/bsov.png?1578020375'
  },
  {
    chainId: 1,
    address: '0xe277ac35f9d327a670c1a3f3eec80a83022431e4',
    name: 'PolypuX',
    symbol: 'PUX',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11954/thumb/puxlogoforcoingecko.png?1609516934'
  },
  {
    chainId: 1,
    address: '0xd938137e6d96c72e4a6085412ada2dad78ff89c4',
    name: 'Abulaba',
    symbol: 'AAA',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/4016/thumb/abulaba.png?1547039118'
  },
  {
    chainId: 1,
    address: '0x6d0f5149c502faf215c89ab306ec3e50b15e2892',
    name: 'Portion',
    symbol: 'PRT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13617/thumb/OKeO2FI.png?1610327038'
  },
  {
    chainId: 1,
    address: '0xd6940a1ffd9f3b025d1f1055abcfd9f7cda81ef9',
    name: 'YouForia',
    symbol: 'YFR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11132/thumb/6Won2ZF.png?1588903985'
  },
  {
    chainId: 1,
    address: '0x80ce3027a70e0a928d9268994e9b85d03bd4cdcf',
    name: 'Polkalokr',
    symbol: 'LKR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14692/thumb/coingeckoLogo.png?1617809622'
  },
  {
    chainId: 1,
    address: '0xed40834a13129509a89be39a9be9c0e96a0ddd71',
    name: 'Warp Finance',
    symbol: 'WARP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13910/thumb/601ed0ac35c687c6e07d17c2_warp_token.png?1612834360'
  },
  {
    chainId: 1,
    address: '0xb41380174d0b06181513a5677b60200b93b5efb4',
    name: 'Chainsquare',
    symbol: 'CHS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14858/thumb/chainsquare.png?1618804636'
  },
  {
    chainId: 1,
    address: '0xf17e9e4e32d842e4ddfbed5750a26f7bd77777ee',
    name: 'One Army Coin',
    symbol: 'OAC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14928/thumb/OAC-token-200-logo.png?1619068717'
  },
  {
    chainId: 1,
    address: '0xd433138d12beb9929ff6fd583dc83663eea6aaa5',
    name: 'Bitrue Coin',
    symbol: 'BTR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8873/thumb/BX9d22_m.jpg?1562297447'
  },
  {
    chainId: 1,
    address: '0x0b342c51d1592c41068d5d4b4da4a68c0a04d5a4',
    name: 'OneSwap DAO Token',
    symbol: 'ONES',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12552/thumb/logo.png?1600682344'
  },
  {
    chainId: 1,
    address: '0x62d75a2a10f755104bd1024d997141ce793cf585',
    name: '520',
    symbol: '520',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9758/thumb/logo_%2822%29.png?1571437829'
  },
  {
    chainId: 1,
    address: '0x5935ffc231e93ac04daa089c0f1b94d0fb2449de',
    name: 'Kanva',
    symbol: 'KNV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13138/thumb/5uoWII9M_400x400.png?1605592792'
  },
  {
    chainId: 1,
    address: '0xcf3c8be2e2c42331da80ef210e9b1b307c03d36a',
    name: 'BEPRO Network',
    symbol: 'BEPRO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10251/thumb/logo.png?1610592699'
  },
  {
    chainId: 1,
    address: '0x6c4c2cde0a4fb9c045e4ec04e3277d359a820ca6',
    name: 'Happy Birthday Coin',
    symbol: 'HBDC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12814/thumb/HBDC.png?1621665592'
  },
  {
    chainId: 1,
    address: '0x14ca41eecd7d81d5d13098586c0d2314eba285be',
    name: 'JUST NETWORK',
    symbol: 'JUS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10796/thumb/OPQ-hZVi_400x400.png?1583722516'
  },
  {
    chainId: 1,
    address: '0x889bc62e94bb6902d022bb82b38f7fcd637df28c',
    name: '1X Short OKB Token',
    symbol: 'OKBHEDGE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12057/thumb/683JEXMN_400x400.png?1596704656'
  },
  {
    chainId: 1,
    address: '0x00e150d741eda1d49d341189cae4c08a73a49c95',
    name: 'Infinitus Token',
    symbol: 'INF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4719/thumb/inf.png?1547040019'
  },
  {
    chainId: 1,
    address: '0xe96f2c381e267a96c29bbb8ab05ab7d3527b45ab',
    name: 'SatoExchange Token',
    symbol: 'SATX',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/8500/thumb/satx-logo.png?1559015585'
  },
  {
    chainId: 1,
    address: '0x705ee96c1c160842c92c1aecfcffccc9c412e3d9',
    name: 'ClearPoll',
    symbol: 'POLL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1050/thumb/clearpoll.png?1547034985'
  },
  {
    chainId: 1,
    address: '0xd71ecff9342a5ced620049e616c5035f1db98620',
    name: 'sEUR',
    symbol: 'SEUR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13637/thumb/sEUR.png?1610609277'
  },
  {
    chainId: 1,
    address: '0xe532a2a37b0707b4306b21b412d2e8c22f9824ec',
    name: 'EUP Chain',
    symbol: 'EUP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10264/thumb/euchain.PNG?1576813636'
  },
  {
    chainId: 1,
    address: '0x7ccc863ece2e15bc45ac0565d410da7a3340ad98',
    name: 'Dotify',
    symbol: 'DFY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14722/thumb/dotify_logo.png?1617944530'
  },
  {
    chainId: 1,
    address: '0xf680429328caaacabee69b7a9fdb21a71419c063',
    name: 'Butterfly Protocol',
    symbol: 'BFLY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13882/thumb/ButterflyProtocolNoText_sm.png?1612492535'
  },
  {
    chainId: 1,
    address: '0x6f2afbf4f5e5e804c5b954889d7bf3768a3c9a45',
    name: 'Hope Token',
    symbol: 'HOPE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15106/thumb/hope.PNG?1619733594'
  },
  {
    chainId: 1,
    address: '0xf51ebf9a26dbc02b13f8b3a9110dac47a4d62d78',
    name: 'APIX',
    symbol: 'APIX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10124/thumb/5sSKmtlA_400x400.png?1576126911'
  },
  {
    chainId: 1,
    address: '0xcb4e8cafeda995da5cedfda5205bd5664a12b848',
    name: 'Shabu Shabu',
    symbol: 'KOBE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13577/thumb/shabu_shabu_logo.jpg?1609901993'
  },
  {
    chainId: 1,
    address: '0xb6c4267c4877bb0d6b1685cfd85b0fbe82f105ec',
    name: 'Relevant',
    symbol: 'REL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11586/thumb/Relevant.png?1591390081'
  },
  {
    chainId: 1,
    address: '0x6710c63432a2de02954fc0f851db07146a6c0312',
    name: 'Smart MFG',
    symbol: 'MFG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1842/thumb/Smart_MFG_Cropped_Logo.png?1621422155'
  },
  {
    chainId: 1,
    address: '0x4fe83213d56308330ec302a8bd641f1d0113a4cc',
    name: 'NuCypher',
    symbol: 'NU',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3318/thumb/photo1198982838879365035.jpg?1547037916'
  },
  {
    chainId: 1,
    address: '0x4d07fdd4bae81ce2c8b1b76f4db2064c35d7851e',
    name: 'SWE Token',
    symbol: 'SWET',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8265/thumb/swet.png?1557127536'
  },
  {
    chainId: 1,
    address: '0xf8cc67e304f8e1a351ed83b4dbbe6b4076d51376',
    name: '1X Short Exchange T',
    symbol: 'EXCHHEDGE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11985/thumb/683JEXMN_400x400__1_.png?1596683946'
  },
  {
    chainId: 1,
    address: '0x26ff6d16549a00ba8b36ce3159b5277e6e798d18',
    name: 'Chihua Token',
    symbol: 'CHIHUA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15655/thumb/afYzlCv3_400x400.jpg?1621478922'
  },
  {
    chainId: 1,
    address: '0x066798d9ef0833ccc719076dab77199ecbd178b0',
    name: 'SakeToken',
    symbol: 'SAKE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12428/thumb/sake.png?1599777402'
  },
  {
    chainId: 1,
    address: '0xac2e58a06e6265f1cf5084ee58da68e5d75b49ca',
    name: 'ORS Group',
    symbol: 'ORS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3036/thumb/ors-group-logo.jpg?1547037349'
  },
  {
    chainId: 1,
    address: '0x4760e7a401558aa59639161454bb2a41a3c5a90b',
    name: 'Crowd One',
    symbol: 'CRD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10879/thumb/bottom_logo.png?1585473923'
  },
  {
    chainId: 1,
    address: '0x44bbb689aa13315ea5d56ebda2d263e53cb4b0b7',
    name: 'Kskin',
    symbol: 'KSK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14285/thumb/logo-kskin.png?1615273981'
  },
  {
    chainId: 1,
    address: '0x106d3c66d22d2dd0446df23d7f5960752994d600',
    name: 'Labra Finance',
    symbol: 'LABRA',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15024/thumb/labra-coingecko-200x200.png?1619488834'
  },
  {
    chainId: 1,
    address: '0xccac36423def6c19ee6b5679144e2976e309ca45',
    name: 'Hachiko Inu',
    symbol: 'HACHIKO',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/15340/thumb/Untitled-design-32-removebg-preview-2.png?1620598741'
  },
  {
    chainId: 1,
    address: '0x35b55c25731e9b05b1d8480ba39463d52c9d0211',
    name: 'Stonk Swap',
    symbol: 'STONK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11690/thumb/PNG-logo-circle-200.png?1618533501'
  },
  {
    chainId: 1,
    address: '0x0c572544a4ee47904d54aaa6a970af96b6f00e1b',
    name: 'Wasder',
    symbol: 'WAS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15374/thumb/wasderlogo200x200.png?1620691729'
  },
  {
    chainId: 1,
    address: '0x08389495d7456e1951ddf7c3a1314a4bfb646d8b',
    name: 'Crypterium',
    symbol: 'CRPT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1901/thumb/crypt.png?1547036205'
  },
  {
    chainId: 1,
    address: '0xf88951d7b676798705fd3a362ba5b1dbca2b233b',
    name: 'Piction Network',
    symbol: 'PXL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7870/thumb/4091.png?1562798644'
  },
  {
    chainId: 1,
    address: '0xf418588522d5dd018b425e472991e52ebbeeeeee',
    name: 'Ethereum Push Notif',
    symbol: 'PUSH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14769/thumb/epns_logo.jpg?1618330344'
  },
  {
    chainId: 1,
    address: '0xdc349913d53b446485e98b76800b6254f43df695',
    name: 'Bezoge Earth',
    symbol: 'BEZOGE',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15639/thumb/token-logo.png?1621408212'
  },
  {
    chainId: 1,
    address: '0x947aeb02304391f8fbe5b25d7d98d649b57b1788',
    name: 'Mandala Exchange To',
    symbol: 'MDX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13736/thumb/png-cmc.png?1611288725'
  },
  {
    chainId: 1,
    address: '0x84119cb33e8f590d75c2d6ea4e6b0741a7494eda',
    name: 'Giga Watt Token',
    symbol: 'WTT',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/907/thumb/wtt.png?1547034739'
  },
  {
    chainId: 1,
    address: '0x286bda1413a2df81731d4930ce2f862a35a609fe',
    name: 'Wabi',
    symbol: 'WABI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1338/thumb/Tael.png?1547035364'
  },
  {
    chainId: 1,
    address: '0xbddab785b306bcd9fb056da189615cc8ece1d823',
    name: 'Ebakus',
    symbol: 'EBK',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/9927/thumb/F9LHFTevrWW8hLadB7x6DBF_c_32iQtKEEmX5bkqI_N3bryFVfbrgIbj6dDFj7INHNR4Vx7VpHO2_ao9C6JZP-h51ICHZzmYbSsuotC2J3X2_gRAyNUdVt3M6q0Q6NSlhP0O24HNqL_I4bmI7xPWOR6iirprnNH9WaYsfU3RVevay2FgWgvum_2dL85xLCUXvD4nvXiPnbxeiRf18.jpg?1573464332'
  },
  {
    chainId: 1,
    address: '0xc888a0ab4831a29e6ca432babf52e353d23db3c2',
    name: 'FastSwap',
    symbol: 'FAST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13567/thumb/logo.png?1609834317'
  },
  {
    chainId: 1,
    address: '0x9135d92e3a34e2a94e4474b74b9dc2d51118eed5',
    name: 'Ulgen Hash Power',
    symbol: 'UHP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11897/thumb/download_%2816%29.png?1595892702'
  },
  {
    chainId: 1,
    address: '0x393fac0773c765c80dc887451377d553c46f83b1',
    name: 'RAKSUR',
    symbol: 'RAS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10790/thumb/rMKHG2G6_400x400.jpg?1585523542'
  },
  {
    chainId: 1,
    address: '0x4d31200e6d7854c2f664af7fc38a21600960f74d',
    name: 'Bit Financial',
    symbol: 'BFC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12822/thumb/BFC.png?1602752235'
  },
  {
    chainId: 1,
    address: '0x054d64b73d3d8a21af3d764efd76bcaa774f3bb2',
    name: 'Plasma Finance',
    symbol: 'PPAY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13340/thumb/Hi9sEGAD.png?1607586849'
  },
  {
    chainId: 1,
    address: '0x14dffd4f515d4c43493c6c512c78fbc59a8af254',
    name: 'Anti Fraud Chain',
    symbol: 'AFC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9448/thumb/logo-white.png?1567550853'
  },
  {
    chainId: 1,
    address: '0x592ef68c18f05a22c5890263dea5d952dd140d2a',
    name: '3X Long Exchange To',
    symbol: 'EXCHBULL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10436/thumb/683JEXMN_400x400.png?1579276837'
  },
  {
    chainId: 1,
    address: '0x1a7a8bd9106f2b8d977e08582dc7d24c723ab0db',
    name: 'AppCoins',
    symbol: 'APPC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1876/thumb/appcoins.png?1547036186'
  },
  {
    chainId: 1,
    address: '0x0000000000095413afc295d19edeb1ad7b71c952',
    name: 'Tokenlon',
    symbol: 'LON',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13454/thumb/lon_logo.png?1608701720'
  },
  {
    chainId: 1,
    address: '0x4c6ec08cf3fc987c6c4beb03184d335a2dfc4042',
    name: 'MurAll',
    symbol: 'PAINT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14103/thumb/paint_logo_200x200.png?1614325640'
  },
  {
    chainId: 1,
    address: '0xfa5e27893aee4805283d86e4283da64f8c72dd56',
    name: 'apeUSD UMA Syntheti',
    symbol: 'APEUSD-UMA-DEC21',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14400/thumb/apeusd.jpg?1615905279'
  },
  {
    chainId: 1,
    address: '0x062e3be6a7c56a395b1881a0cd69a4923ade4fa2',
    name: 'Bowl A Coin',
    symbol: 'BAC',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/10407/thumb/67889072_875141466188128_5648314299878211584_o.jpg?1578952952'
  },
  {
    chainId: 1,
    address: '0x91b7ed3b352aa3502f94e58eac930ae1f5b5ebcd',
    name: 'CNY Tether',
    symbol: 'CNYT',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/5251/thumb/cny-tether-logo.png?1547040741'
  },
  {
    chainId: 1,
    address: '0x16eccfdbb4ee1a85a33f3a9b21175cd7ae753db4',
    name: 'Router Protocol',
    symbol: 'ROUTE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13709/thumb/route_token_200x200-19.png?1611057698'
  },
  {
    chainId: 1,
    address: '0xaa8330fb2b4d5d07abfe7a72262752a8505c6b37',
    name: 'Polkacity',
    symbol: 'POLC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14066/thumb/vykih1Nq_400x400.png?1614130959'
  },
  {
    chainId: 1,
    address: '0xb5e88b229b18e748e3aa16a1c2bfefdfc8a5560d',
    name: 'Global DeFi',
    symbol: 'GDEFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15670/thumb/Global-Defi-logo-200x200-1.png?1621496499'
  },
  {
    chainId: 1,
    address: '0xdd0020b1d5ba47a54e2eb16800d73beb6546f91a',
    name: 'aXpire',
    symbol: 'AXPR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2682/thumb/axpire.png?1547036886'
  },
  {
    chainId: 1,
    address: '0xe0c6ce3e73029f201e5c0bedb97f67572a93711c',
    name: 'ETHplode',
    symbol: 'ETHPLO',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/8712/thumb/coingecko.png?1560246023'
  },
  {
    chainId: 1,
    address: '0xf263292e14d9d8ecd55b58dad1f1df825a874b7c',
    name: 'Educoin',
    symbol: 'EDU',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3256/thumb/educoin.jpg?1547037785'
  },
  {
    chainId: 1,
    address: '0x3212b29e33587a00fb1c83346f5dbfa69a458923',
    name: 'The Tokenized Bitco',
    symbol: 'IMBTC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10702/thumb/imbtc.png?1585124381'
  },
  {
    chainId: 1,
    address: '0xf0e3543744afced8042131582f2a19b6aeb82794',
    name: 'Variable Time Dolla',
    symbol: 'VTD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13608/thumb/vtd.jpg?1610273963'
  },
  {
    chainId: 1,
    address: '0x4b7ad3a56810032782afce12d7d27122bdb96eff',
    name: 'Sparkle Loyalty',
    symbol: 'SPRKL',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/7949/thumb/SparkleLoyalty_Icon.png?1597653289'
  },
  {
    chainId: 1,
    address: '0x5ecb025e51415dba9fd272c551076357cf4069f5',
    name: 'Crespo',
    symbol: 'CSO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9125/thumb/crespo.png?1564547729'
  },
  {
    chainId: 1,
    address: '0x2840ad41cf25ad58303ba24c416e79dce4161b4f',
    name: '1X Short BNB Token',
    symbol: 'BNBHEDGE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12062/thumb/683JEXMN_400x400.png?1596706881'
  },
  {
    chainId: 1,
    address: '0x1da87b114f35e1dc91f72bf57fc07a768ad40bb0',
    name: 'Equalizer',
    symbol: 'EQZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14741/thumb/X2p5mb2f_400x400.png?1618005664'
  },
  {
    chainId: 1,
    address: '0x3b5f11dbac1476af17957c6e5991f21c826743dd',
    name: 'BaconCoin',
    symbol: 'BAK',
    decimals: 8,
    logoURI:
      'https://assets.coingecko.com/coins/images/7320/thumb/48428794_293169174672799_1981223745984921600_n.png?1547043977'
  },
  {
    chainId: 1,
    address: '0xa8892bfc33fa44053a9e402b1839966f4fec74a4',
    name: 'Crypto User Base',
    symbol: 'CUB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11906/thumb/logo-200x200.png?1596074026'
  },
  {
    chainId: 1,
    address: '0x084da5a9c0e3f086532b98d8568432349b89d9df',
    name: 'FUUPAY',
    symbol: 'FPT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12089/thumb/cab76be67ea99.png?1597018998'
  },
  {
    chainId: 1,
    address: '0x3ab6ed69ef663bd986ee59205ccad8a20f98b4c2',
    name: 'Drep  new ',
    symbol: 'DREP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14578/thumb/KotgsCgS_400x400.jpg?1617094445'
  },
  {
    chainId: 1,
    address: '0x00059ae69c1622a7542edc15e8d17b060fe307b6',
    name: 'AmonD',
    symbol: 'AMON',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8952/thumb/AmonD_512x512.png?1563227436'
  },
  {
    chainId: 1,
    address: '0xfb5453340c03db5ade474b27e68b6a9c6b2823eb',
    name: 'Robot',
    symbol: 'ROBOT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13517/thumb/MF_Robot_200px.png?1609312481'
  },
  {
    chainId: 1,
    address: '0x15bda08c3afbf5955d6e9b235fd55a1fd0dbc829',
    name: 'Alpha Coin',
    symbol: 'APC',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/6725/thumb/alpha-coin.png?1547042955'
  },
  {
    chainId: 1,
    address: '0xfe5f141bf94fe84bc28ded0ab966c16b17490657',
    name: 'LibraToken',
    symbol: 'LBA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3673/thumb/libra-credit.png?1547975828'
  },
  {
    chainId: 1,
    address: '0xaac679720204aaa68b6c5000aa87d789a3ca0aa5',
    name: 'Huobi Bitcoin Cash',
    symbol: 'HBCH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14105/thumb/HBCh.png?1614330789'
  },
  {
    chainId: 1,
    address: '0xd06b25f67a17f12b41f615b34d87ecd716ff55a0',
    name: '3X Long Shitcoin In',
    symbol: 'BULLSHIT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10228/thumb/683JEXMN_400x400_%281%29.png?1576618335'
  },
  {
    chainId: 1,
    address: '0x8f8221afbb33998d8584a2b05749ba73c37a938a',
    name: 'Request',
    symbol: 'REQ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1031/thumb/Request_icon.png?1550832088'
  },
  {
    chainId: 1,
    address: '0x1321f1f1aa541a56c31682c57b80ecfccd9bb288',
    name: 'ARC Governance',
    symbol: 'ARCX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15652/thumb/ARCX.jpg?1621478114'
  },
  {
    chainId: 1,
    address: '0x8a6f3bf52a26a21531514e23016eeae8ba7e7018',
    name: 'Multiplier',
    symbol: 'MXX',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12119/thumb/MXXlogo.png?1597306184'
  },
  {
    chainId: 1,
    address: '0x5bc52b5367b637bec5f818e69208eb9f03c426ee',
    name: 'Panda Finance',
    symbol: 'PAND',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15579/thumb/logo-200_%285%29.png?1621239747'
  },
  {
    chainId: 1,
    address: '0x108d27f9c4b2a98c025c94c76ca78c6ce6c7a4eb',
    name: 'Metaprediction',
    symbol: 'METP',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/10997/thumb/81621194_148915083232429_7449717815403085824_n.jpg?1587026250'
  },
  {
    chainId: 1,
    address: '0x3d6f0dea3ac3c607b3998e6ce14b6350721752d9',
    name: 'Cardstarter',
    symbol: 'CARDS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14612/thumb/CARDSx200.png?1617252006'
  },
  {
    chainId: 1,
    address: '0x16980b3b4a3f9d89e33311b5aa8f80303e5ca4f8',
    name: 'KIRA Network',
    symbol: 'KEX',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/13232/thumb/kira_logo.jpg?1606570162'
  },
  {
    chainId: 1,
    address: '0x954b890704693af242613edef1b603825afcd708',
    name: 'Cardstack',
    symbol: 'CARD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3247/thumb/cardstack.png?1547037769'
  },
  {
    chainId: 1,
    address: '0x0a0db74ef8b4480cc29b7d68647727feeb1ea4ec',
    name: 'GLEX',
    symbol: 'GLEX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11609/thumb/1578901576974.png?1591777246'
  },
  {
    chainId: 1,
    address: '0x44ea84a85616f8e9cd719fc843de31d852ad7240',
    name: 'NO Trump Augur Pred',
    symbol: 'NTRUMP',
    decimals: 15,
    logoURI: 'https://assets.coingecko.com/coins/images/12597/thumb/nX10wsB.png?1600997655'
  },
  {
    chainId: 1,
    address: '0xabe580e7ee158da464b51ee1a83ac0289622e6be',
    name: 'Offshift',
    symbol: 'XFT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11977/thumb/CsBrPiA.png?1614570441'
  },
  {
    chainId: 1,
    address: '0xeeaa40b28a2d1b0b08f6f97bb1dd4b75316c6107',
    name: 'Govi',
    symbol: 'GOVI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13875/thumb/GOVI.png?1612451531'
  },
  {
    chainId: 1,
    address: '0xd0cb75298d5c1e3b277e3cd95c56b3caa81a99d3',
    name: 'HDT',
    symbol: 'HDT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11145/thumb/title.png?1588920310'
  },
  {
    chainId: 1,
    address: '0x59ad6061a0be82155e7acce9f0c37bf59f9c1e3c',
    name: 'Liquid Lottery RTC',
    symbol: 'LIQLO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13104/thumb/liqlo_logo.png?1605234382'
  },
  {
    chainId: 1,
    address: '0x4c14114c107d6374ec31981f5f6cc27a13e22f9a',
    name: 'SBank',
    symbol: 'STS',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/10279/thumb/74302797_109806420469006_618028571075543040_o.png?1576971737'
  },
  {
    chainId: 1,
    address: '0x9e46a38f5daabe8683e10793b06749eef7d733d1',
    name: 'PolySwarm',
    symbol: 'NCT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2843/thumb/polyswarm.png?1548331600'
  },
  {
    chainId: 1,
    address: '0x896e145568624a498c5a909187363ae947631503',
    name: 'WasabiX',
    symbol: 'WASABI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14522/thumb/WASABI.299ec8f7.png?1616721315'
  },
  {
    chainId: 1,
    address: '0x87210f1d3422ba75b6c40c63c78d79324dabcd55',
    name: 'EOS TRUST',
    symbol: 'EOST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8829/thumb/EOS_TRUST.png?1561955075'
  },
  {
    chainId: 1,
    address: '0x95172ccbe8344fecd73d0a30f54123652981bd6f',
    name: 'Meridian Network',
    symbol: 'LOCK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11687/thumb/LOCK_cropped.png?1599614902'
  },
  {
    chainId: 1,
    address: '0x0c93b616933b0cd03b201b29cd8a22681dd9e0d9',
    name: 'HollyGold',
    symbol: 'HGOLD',
    decimals: 8,
    logoURI:
      'https://assets.coingecko.com/coins/images/13652/thumb/QnfGCea3aO_Qb-Gd9n6MSFE_eBVAr87WPoxAzf0gKdcGOEL4K5J6A5cpGinzLvHclj_UHkCnw9XTlGoNYd0H8T_Ebe8voLS49MAZO2NIknCxytaYFtt5_u12RQg-kpmWHcETzAFlDgk9iNXAL-88fB_l-DRi8WEsgSJzXXjztKzQlpXuET6d4_98pykED6uyq1-4PMVg05P7tUN.jpg?1610574345'
  },
  {
    chainId: 1,
    address: '0x614fd8f06ce4d93aa2361b342c86554eb5cb39f1',
    name: 'Tianya Token',
    symbol: 'TYT',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/7568/thumb/images_%283%29.png?1548411643'
  },
  {
    chainId: 1,
    address: '0x09970aec766b6f3223aca9111555e99dc50ff13a',
    name: 'Levolution',
    symbol: 'LEVL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7370/thumb/L_Icon_Blue_200x200.png?1618983083'
  },
  {
    chainId: 1,
    address: '0x865377367054516e17014ccded1e7d814edc9ce4',
    name: 'Dola USD Stablecoin',
    symbol: 'DOLA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14287/thumb/anchor-logo-1-200x200.png?1615275005'
  },
  {
    chainId: 1,
    address: '0x8888801af4d980682e47f1a9036e589479e835c5',
    name: '88mph',
    symbol: 'MPH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13137/thumb/yfU-_Tcj_400x400.png?1605581509'
  },
  {
    chainId: 1,
    address: '0xf001937650bb4f62b57521824b2c20f5b91bea05',
    name: 'Taraxa',
    symbol: 'TARA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4372/thumb/CPuCDZX.jpg?1616391360'
  },
  {
    chainId: 1,
    address: '0x106238737b85b4220c7a7ddfd5c4caf8597281fe',
    name: 'BurnX',
    symbol: 'BURNX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15431/thumb/burnx-logo.png?1620797043'
  },
  {
    chainId: 1,
    address: '0x3e9e371f8d2e9fca315fb0a747533ced8a3fcbcb',
    name: 'BIXCPRO',
    symbol: 'BIXCPRO',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/7893/thumb/download.jpg?1551429825'
  },
  {
    chainId: 1,
    address: '0x4e3bddd468abfc6c88bc25daa5d894380ced5bc8',
    name: 'NSS Coin',
    symbol: 'NSS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9501/thumb/adddcf817f.png?1568108247'
  },
  {
    chainId: 1,
    address: '0xc77b230f31b517f1ef362e59c173c2be6540b5e8',
    name: 'VIDY',
    symbol: 'VIDY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5088/thumb/vidy-logo.jpg?1547040486'
  },
  {
    chainId: 1,
    address: '0x95d82ba91256db995c80bed690d0a779191af62b',
    name: 'AlrightCoin',
    symbol: 'ALC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11611/thumb/34B2E0C6-DDCB-4F48-B6BE-A2D9FB8734A3.png?1591842917'
  },
  {
    chainId: 1,
    address: '0x8aedb297fed4b6884b808ee61faf0837713670d0',
    name: 'Wrapped MarbleCards',
    symbol: 'WMC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9769/thumb/android-icon-192x192.png?1571609129'
  },
  {
    chainId: 1,
    address: '0x168e39f96a653ce0a456560687241b0b2936e5ff',
    name: '2Based Finance',
    symbol: '2BASED',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13439/thumb/2based.jpg?1608564864'
  },
  {
    chainId: 1,
    address: '0xa54c67bd320da4f9725a6f585b7635a0c09b122e',
    name: 'TimeMiner',
    symbol: 'TIME',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/12280/thumb/2WTMX74.png?1598739901'
  },
  {
    chainId: 1,
    address: '0x10633216e7e8281e33c86f02bf8e565a635d9770',
    name: 'Dvision Network',
    symbol: 'DVI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13020/thumb/WGAhHOLv_400x400.png?1604396086'
  },
  {
    chainId: 1,
    address: '0x5e6b6d9abad9093fdc861ea1600eba1b355cd940',
    name: 'IoT Chain',
    symbol: 'ITC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1304/thumb/iot-chain-logo.png?1547035331'
  },
  {
    chainId: 1,
    address: '0x2b591e99afe9f32eaa6214f7b7629768c40eeb39',
    name: 'HEX',
    symbol: 'HEX',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10103/thumb/HEX-logo.png?1575942673'
  },
  {
    chainId: 1,
    address: '0xbc4171f45ef0ef66e76f979df021a34b46dcc81d',
    name: 'Dora Factory',
    symbol: 'DORA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14478/thumb/dora_logo.jpg?1616410086'
  },
  {
    chainId: 1,
    address: '0xb9d99c33ea2d86ec5ec6b8a4dd816ebba64404af',
    name: 'K21',
    symbol: 'K21',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14466/thumb/k21-token.png?1616775801'
  },
  {
    chainId: 1,
    address: '0xbd434a09191d401da3283a5545bb3515d033b8c4',
    name: 'GoldFinX',
    symbol: 'GIX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13292/thumb/nDyBmnHW_400x400.jpg?1607063929'
  },
  {
    chainId: 1,
    address: '0x48dee19c81b89a9ab473361bae7a19210f2deaa4',
    name: '3X Short Shitcoin I',
    symbol: 'BEARSHIT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10206/thumb/683JEXMN_400x400_%281%29.png?1576534838'
  },
  {
    chainId: 1,
    address: '0xe7976c4efc60d9f4c200cc1bcef1a1e3b02c73e7',
    name: 'MAX Token',
    symbol: 'MAX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6454/thumb/MAX_Token.jpg?1547042651'
  },
  {
    chainId: 1,
    address: '0x9cec686ba6f07d6135b2091140c795166ef5b761',
    name: 'GivingToServices',
    symbol: 'SVCS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8763/thumb/SVCS_Ticker_logo_.png?1611590501'
  },
  {
    chainId: 1,
    address: '0x1cbb83ebcd552d5ebf8131ef8c9cd9d9bab342bc',
    name: 'Non Fungible Yearn',
    symbol: 'NFY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12766/thumb/NFY_logo.png?1602686886'
  },
  {
    chainId: 1,
    address: '0x6704b673c70de9bf74c8fba4b4bd748f0e2190e1',
    name: 'Ubex',
    symbol: 'UBEX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4163/thumb/ubex.png?1547039421'
  },
  {
    chainId: 1,
    address: '0x34364bee11607b1963d66bca665fde93fca666a8',
    name: 'YOU Chain',
    symbol: 'YOU',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4649/thumb/youchain-logo.jpg?1547039915'
  },
  {
    chainId: 1,
    address: '0x5b5a353fc217ebef77bc7686ea05a003ebdb7d1a',
    name: 'HiGameCoin',
    symbol: 'HGC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8333/thumb/hpc.png?1557546950'
  },
  {
    chainId: 1,
    address: '0x9dfc4b433d359024eb3e810d77d60fbe8b0d9b82',
    name: 'Dandy Dego',
    symbol: 'DANDY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12764/thumb/487ogltc_400x400.jpg?1602463048'
  },
  {
    chainId: 1,
    address: '0xd7d706ed3598a354a4adfce5d8c5383df99a4461',
    name: 'Dain Token',
    symbol: 'DAIN',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/14811/thumb/%C2%B4%C3%99%C3%80%C3%8E%C2%B7%C3%8E%C2%B0%C3%AD.png?1618541340'
  },
  {
    chainId: 1,
    address: '0xa870879e2872f9f2dc3a33933a9af3345b00fd14',
    name: 'B360',
    symbol: 'B360',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9357/thumb/b360.PNG?1566515470'
  },
  {
    chainId: 1,
    address: '0x7e32c8727cc19dd59a7a4d01b95ae1cbfc8f4c77',
    name: 'Aqua',
    symbol: 'AQUA',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13455/thumb/aqua10-white-round.png?1608712198'
  },
  {
    chainId: 1,
    address: '0x9c197c4b58527faaab67cb35e3145166b23d242e',
    name: 'HashNet BitEco',
    symbol: 'HNB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8126/thumb/GyEELaxS_400x400.jpg?1555226121'
  },
  {
    chainId: 1,
    address: '0xfe18be6b3bd88a2d2a7f928d00292e7a9963cfc6',
    name: 'sBTC',
    symbol: 'SBTC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8838/thumb/sBTC.png?1616149734'
  },
  {
    chainId: 1,
    address: '0xadb2437e6f65682b85f814fbc12fec0508a7b1d0',
    name: 'UniCrypt',
    symbol: 'UNCX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12871/thumb/unicrypt_logo.png?1603178739'
  },
  {
    chainId: 1,
    address: '0xc2e343118f937f88ee1fc3150cdc0d6f3d11bba7',
    name: 'Supertron',
    symbol: 'STRO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9326/thumb/LQOVF5S.png?1566267616'
  },
  {
    chainId: 1,
    address: '0x4092678e4e78230f46a1534c0fbc8fa39780892b',
    name: 'Odyssey',
    symbol: 'OCN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2559/thumb/ocn.png?1547036683'
  },
  {
    chainId: 1,
    address: '0xd26fb114401ec86887cd09f62eccd95fcf20b571',
    name: 'Bitcoin Platinums',
    symbol: 'BCP',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/8395/thumb/DzEn-8rWkAALlqK_%281%29.jpg?1557988160'
  },
  {
    chainId: 1,
    address: '0xc3d6dda603fc15fd4bf9303150fe11c7cd6059dc',
    name: 'Coweye',
    symbol: 'COW',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9137/thumb/coweye.PNG?1564630035'
  },
  {
    chainId: 1,
    address: '0x595832f8fc6bf59c85c527fec3740a1b7a361269',
    name: 'Power Ledger',
    symbol: 'POWR',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/1104/thumb/power-ledger.png?1547035082'
  },
  {
    chainId: 1,
    address: '0x9b8c184439245b7bb24a5b2ec51ec81c39589e8a',
    name: 'KIMEX',
    symbol: 'KMX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8062/thumb/XSsfMr8.png?1554083098'
  },
  { chainId: 1, address: '0x6a48ba795e3289b9151036e189e352e3ee2c7798', name: 'SXC Token', symbol: 'SXC', decimals: 18 },
  {
    chainId: 1,
    address: '0xb72c794effb775197287d767ca80c22ae9094cb5',
    name: 'Shrine Cloud Storag',
    symbol: 'SCDS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9858/thumb/1571122840.png?1572836207'
  },
  {
    chainId: 1,
    address: '0x8f12dfc7981de79a8a34070a732471f2d335eece',
    name: 'Crypto Excellence',
    symbol: 'CE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14809/thumb/ce.PNG?1618539068'
  },
  {
    chainId: 1,
    address: '0x3b78dc5736a49bd297dd2e4d62daa83d35a22749',
    name: 'Finswap',
    symbol: 'FNSP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12542/thumb/finswap-orange-200x200-1.png?1600663987'
  },
  {
    chainId: 1,
    address: '0xb04dfdb8271ed2d5e13858562c44a77d3ceb9e57',
    name: 'BuildUp',
    symbol: 'BUP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13206/thumb/BUP_-_LOGO.png?1606199462'
  },
  {
    chainId: 1,
    address: '0x8cd024cc8f73f5cd132005d1584403877b318c9d',
    name: 'Hash Pot',
    symbol: 'HPOT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11019/thumb/hashpot.PNG?1587128424'
  },
  {
    chainId: 1,
    address: '0xff5c25d2f40b47c4a37f989de933e26562ef0ac0',
    name: 'Kora Network',
    symbol: 'KNT',
    decimals: 16,
    logoURI: 'https://assets.coingecko.com/coins/images/2772/thumb/knt.png?1547036959'
  },
  {
    chainId: 1,
    address: '0x6682195e2a0048ce38b727a3711802d58244606e',
    name: 'BeautyK',
    symbol: 'BTKC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12189/thumb/logo_%2891%29.png?1597963518'
  },
  {
    chainId: 1,
    address: '0xb4bebd34f6daafd808f73de0d10235a92fbb6c3d',
    name: 'Yearn Ecosystem Tok',
    symbol: 'YETI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13332/thumb/yeti.png?1607563342'
  },
  {
    chainId: 1,
    address: '0x68e0a48d3bff6633a31d1d100b70f93c3859218b',
    name: 'Blaze DeFi',
    symbol: 'BNFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13390/thumb/photo_2020-12-16_15-23-59.jpg?1608108829'
  },
  {
    chainId: 1,
    address: '0x75f0038b8fbfccafe2ab9a51431658871ba5182c',
    name: '3X Long Cosmos Toke',
    symbol: 'ATOMBULL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10181/thumb/683JEXMN_400x400_%281%29.png?1576507613'
  },
  {
    chainId: 1,
    address: '0x27778e14ce36d3b85e1effeb43816a17bbb7088a',
    name: 'Lyfe Gold',
    symbol: 'LGOLD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14938/thumb/LGOLD-Logo-200.png?1619082558'
  },
  {
    chainId: 1,
    address: '0xee06a81a695750e71a662b51066f2c74cf4478a0',
    name: 'Decentral Games',
    symbol: 'DG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13267/thumb/Decentral_Games_Logo-1.png?1621652581'
  },
  {
    chainId: 1,
    address: '0x72a6344185b383035d6665c3f44a9dfcc73873c8',
    name: 'ROONEX',
    symbol: 'RNX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11125/thumb/logo_%281%29.jpg?1588736377'
  },
  {
    chainId: 1,
    address: '0xb9cb7905981198add8059114b3b7dc7042b52f7b',
    name: 'Tamy Token',
    symbol: 'TMT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12641/thumb/tamy_logo.png?1601369530'
  },
  {
    chainId: 1,
    address: '0x5f0e628b693018f639d10e4a4f59bd4d8b2b6b44',
    name: 'Whiteheart',
    symbol: 'WHITE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13484/thumb/whiteheart.png?1609076548'
  },
  {
    chainId: 1,
    address: '0xcbd55d4ffc43467142761a764763652b48b969ff',
    name: 'AstroTools',
    symbol: 'ASTRO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12345/thumb/AT-flat-purple_logo.png?1599190828'
  },
  {
    chainId: 1,
    address: '0xc3761eb917cd790b30dad99f6cc5b4ff93c4f9ea',
    name: 'ERC20',
    symbol: 'ERC20',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1141/thumb/erc20.png?1547035146'
  },
  {
    chainId: 1,
    address: '0x6425c6be902d692ae2db752b3c268afadb099d3b',
    name: 'Restart Energy',
    symbol: 'MWAT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3140/thumb/re.jpg?1547037554'
  },
  {
    chainId: 1,
    address: '0xb38f206615325306dddeb0794a6482486b6b78b8',
    name: '1X Short EOS Token',
    symbol: 'EOSHEDGE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10221/thumb/683JEXMN_400x400.png?1596706630'
  },
  {
    chainId: 1,
    address: '0xed64142f7d0a4d94ce0e7fe45d12f712fe360bd0',
    name: 'Cosplay Token',
    symbol: 'COT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2821/thumb/cot.PNG?1563431427'
  },
  {
    chainId: 1,
    address: '0xcd475371e39c0d94e82fccc9dd0ea710d0dc0c0b',
    name: 'THECASH',
    symbol: 'TCH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11851/thumb/thecash_logo_200.png?1595165242'
  },
  {
    chainId: 1,
    address: '0x355376d6471e09a4ffca8790f50da625630c5270',
    name: 'Libartysharetoken',
    symbol: 'LST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12990/thumb/lst_logo.png?1604148361'
  },
  {
    chainId: 1,
    address: '0x3c955e35b6da1ff623d38d750c85b3aed89a10c1',
    name: '3X Short LEO Token',
    symbol: 'LEOBEAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10327/thumb/683JEXMN_400x400_%281%29.png?1578050692'
  },
  {
    chainId: 1,
    address: '0x6f39297bc0c386355c77da3a0275c867b21b2454',
    name: 'GrandPa Fan',
    symbol: 'FYY',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9122/thumb/fyy.PNG?1564473811'
  },
  {
    chainId: 1,
    address: '0x584936357d68f5143f12e2e64f0089db93814dad',
    name: '3X Long Algorand To',
    symbol: 'ALGOBULL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10164/thumb/683JEXMN_400x400_%281%29.png?1576504676'
  },
  {
    chainId: 1,
    address: '0xe541504417670fb76b612b41b4392d967a1956c7',
    name: 'Bitsonic Token',
    symbol: 'BSC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9238/thumb/image.png?1604295837'
  },
  {
    chainId: 1,
    address: '0x455f7ef6d8bcfc35f9337e85aee1b0600a59fabe',
    name: 'Aloha',
    symbol: 'ALOHA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14048/thumb/aloha.png?1615527835'
  },
  {
    chainId: 1,
    address: '0x12b19d3e2ccc14da04fae33e63652ce469b3f2fd',
    name: 'GridPlus',
    symbol: 'GRID',
    decimals: 12,
    logoURI: 'https://assets.coingecko.com/coins/images/1106/thumb/grid.png?1547743150'
  },
  {
    chainId: 1,
    address: '0x60715e436c37444e29772c0d26a98ae1e8e1a989',
    name: 'Voltz',
    symbol: 'VOLTZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8020/thumb/voltz.png?1553478253'
  },
  {
    chainId: 1,
    address: '0x9235bda06b8807161b8fbb1e102cb654555b212f',
    name: 'Feellike',
    symbol: 'FLL',
    decimals: 3,
    logoURI: 'https://assets.coingecko.com/coins/images/12075/thumb/FLL_logo_200.png?1596751266'
  },
  {
    chainId: 1,
    address: '0x16c52ceece2ed57dad87319d91b5e3637d50afa4',
    name: 'Total Crypto Market',
    symbol: 'TCAP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14703/thumb/tcap.png?1617860242'
  },
  {
    chainId: 1,
    address: '0x3aef8e803bd9be47e69b9f36487748d30d940b96',
    name: 'Vesta',
    symbol: 'VESTA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12276/thumb/t693cWC.png?1598736747'
  },
  {
    chainId: 1,
    address: '0xe047705117eb07e712c3d684f5b18e74577e83ac',
    name: 'BitcashPay',
    symbol: 'BCP',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/14353/thumb/BCP-200x200.png?1616468499'
  },
  {
    chainId: 1,
    address: '0xd478161c952357f05f0292b56012cd8457f1cfbf',
    name: 'Polkamarkets',
    symbol: 'POLK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14084/thumb/polkamarkets.jpg?1614179979'
  },
  {
    chainId: 1,
    address: '0x72e364f2abdc788b7e918bc238b21f109cd634d7',
    name: 'Metaverse Index',
    symbol: 'MVI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14684/thumb/MVI_logo.png?1617776444'
  },
  {
    chainId: 1,
    address: '0x90f62b96a62801488b151ff3c65eac5fae21a962',
    name: 'GemSwap',
    symbol: 'GEM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12474/thumb/gem.png?1600124318'
  },
  {
    chainId: 1,
    address: '0xa1d0e215a23d7030842fc67ce582a6afa3ccab83',
    name: 'DFI money',
    symbol: 'YFII',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11902/thumb/YFII-logo.78631676.png?1598677348'
  },
  {
    chainId: 1,
    address: '0x72c14bc8e28f18e91a2e9551802d3fb2cb80e712',
    name: 'AMMYI Coin',
    symbol: 'AMI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14469/thumb/Untitled-design-25.png?1616380058'
  },
  {
    chainId: 1,
    address: '0x5c89736e9454200141b80c37eb28eaceca2ce8cb',
    name: 'Cherry Token',
    symbol: 'YT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/8321/thumb/cherry.png?1557480352'
  },
  {
    chainId: 1,
    address: '0xd5281bb2d1ee94866b03a0fccdd4e900c8cb5091',
    name: 'Husky',
    symbol: 'HUSKY',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14842/thumb/husky_logo.png?1618634383'
  },
  {
    chainId: 1,
    address: '0xc8ce75f643ecad864fc625902a6a07371f38320d',
    name: 'Bootleg NFT',
    symbol: 'BOOT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14455/thumb/bootleg_nft.jpg?1616156150'
  },
  {
    chainId: 1,
    address: '0xf5581dfefd8fb0e4aec526be659cfab1f8c781da',
    name: 'HOPR',
    symbol: 'HOPR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14061/thumb/Shared_HOPR_logo_512px.png?1614073468'
  },
  {
    chainId: 1,
    address: '0x81824663353a9d29b01b2de9dd9a2bb271d298cd',
    name: 'Bitcoin Volatility ',
    symbol: 'BVOL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11056/thumb/download_%2810%29.png?1587641855'
  },
  {
    chainId: 1,
    address: '0x3154da898943fc7151bc77f16e43c0c47b5e452d',
    name: 'STB Chain',
    symbol: 'STB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7458/thumb/QUezGZyH_400x400.jpg?1547546575'
  },
  {
    chainId: 1,
    address: '0x3dd66732113af9981a861cf489431533aeba33b8',
    name: 'Yearn Finance Netwo',
    symbol: 'YFN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12821/thumb/9v36orK.jpg?1603191959'
  },
  {
    chainId: 1,
    address: '0xdd436a0dce9244b36599ae7b22f0373b4e33992d',
    name: 'TrustUSD',
    symbol: 'TRUSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9811/thumb/TrustUSDlogo.png?1589631273'
  },
  {
    chainId: 1,
    address: '0x14cc8dfaf2258e1b8b2869300dba1b734dc0fe43',
    name: 'K Tune',
    symbol: 'KTT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13939/thumb/ktune.jpg?1613030462'
  },
  {
    chainId: 1,
    address: '0x9885ca101dfd8f23d364874f799554c52bfee820',
    name: '3X Long BitMax Toke',
    symbol: 'BTMXBULL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10305/thumb/683JEXMN_400x400.png?1577743562'
  },
  {
    chainId: 1,
    address: '0xa0471cdd5c0dc2614535fd7505b17a651a8f0dab',
    name: 'EasySwap',
    symbol: 'ESWA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11721/thumb/Easyswap.png?1593080991'
  },
  {
    chainId: 1,
    address: '0xfdc4a3fc36df16a78edcaf1b837d3acaaedb2cb4',
    name: 'Scifi Finance',
    symbol: 'SCIFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13868/thumb/scifi.png?1612433269'
  },
  {
    chainId: 1,
    address: '0x1ffe24629f1b3df74fc0f6e5d086f2fd09258ff2',
    name: 'VBT',
    symbol: 'VBT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8467/thumb/747suczn_400x400.jpg?1558766251'
  },
  {
    chainId: 1,
    address: '0x52ceefc5f5c64a6d3dc6e4fde5f2d7810bccde49',
    name: 'COINXCLUB',
    symbol: 'CPX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9284/thumb/logo1.jpg?1565766344'
  },
  {
    chainId: 1,
    address: '0xc962ad021a69d457564e985738c719ae3f79b707',
    name: 'IFX24',
    symbol: 'IFX24',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10444/thumb/lpFSaoD.png?1579475634'
  },
  {
    chainId: 1,
    address: '0x8a3d77e9d6968b780564936d15b09805827c21fa',
    name: 'Uniris',
    symbol: 'UCO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12330/thumb/e353ZVj.png?1599112996'
  },
  {
    chainId: 1,
    address: '0x8c4e7f814d40f8929f9112c5d09016f923d34472',
    name: 'XCELTOKEN PLUS',
    symbol: 'XLAB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8508/thumb/CXQJPNyO_400x400.jpg?1559030200'
  },
  {
    chainId: 1,
    address: '0xa9b1eb5908cfc3cdf91f9b8b3a74108598009096',
    name: 'Bounce',
    symbol: 'AUCTION',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13860/thumb/1_KtgpRIJzuwfHe0Rl0avP_g.jpeg?1612412025'
  },
  {
    chainId: 1,
    address: '0x887168120cb89fb06f3e74dc4af20d67df0977f6',
    name: 'Sekuritance',
    symbol: 'SKRT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15495/thumb/skrt.png?1621053881'
  },
  {
    chainId: 1,
    address: '0xe6b7743e2b9aa2d0a9b163c4e69186abb57817d9',
    name: 'Kuky Star',
    symbol: 'KUKY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7465/thumb/images_%283%29.jpeg?1547622295'
  },
  {
    chainId: 1,
    address: '0x8c3ee4f778e282b59d42d693a97b80b1ed80f4ee',
    name: 'SatoPay',
    symbol: 'STOP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12220/thumb/stop.png?1598241582'
  },
  {
    chainId: 1,
    address: '0x68909e586eeac8f47315e84b4c9788dd54ef65bb',
    name: 'EvenCoin',
    symbol: 'EVN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4634/thumb/evencoin.png?1548125647'
  },
  {
    chainId: 1,
    address: '0xc0ec8caec55f37d47fbfa595727418868a21fd48',
    name: 'EcoG9coin',
    symbol: 'EGC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/8038/thumb/Screenshot_2019-12-05_at_7.14.34_AM.png?1575501284'
  },
  {
    chainId: 1,
    address: '0x05ac103f68e05da35e78f6165b9082432fe64b58',
    name: 'eToro Euro',
    symbol: 'EURX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10522/thumb/4.png?1580510998'
  },
  {
    chainId: 1,
    address: '0xe7fb3559358a99df54466d0350e4ad6dc7093da3',
    name: 'Aicon',
    symbol: 'AICO',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12892/thumb/5920.png?1603333534'
  },
  {
    chainId: 1,
    address: '0xd811e485cb4ab1fad56233de4464fb5d1c9f3e99',
    name: 'Yearn Global',
    symbol: 'YG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13034/thumb/tyrQieZZ_400x400.png?1604539997'
  },
  {
    chainId: 1,
    address: '0xd9b312d77bc7bed9b9cecb56636300bed4fe5ce9',
    name: 'Gains',
    symbol: 'GAINS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14681/thumb/GAINS_Token_200x200.png?1617796172'
  },
  {
    chainId: 1,
    address: '0xe814aee960a85208c3db542c53e7d4a6c8d5f60f',
    name: 'Chronologic',
    symbol: 'DAY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/951/thumb/Chronologic-network.png?1547034815'
  },
  {
    chainId: 1,
    address: '0x9cb2f26a23b8d89973f08c957c4d7cdf75cd341c',
    name: 'Digital Rand',
    symbol: 'DZAR',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/9841/thumb/logo200_%281%29.png?1572577311'
  },
  {
    chainId: 1,
    address: '0x70efdc485a10210b056ef8e0a32993bc6529995e',
    name: 'Blaze Network',
    symbol: 'BLZN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12248/thumb/A8JOuPSJ_400x400.jpg?1598511402'
  },
  {
    chainId: 1,
    address: '0x951a1070ac39851dcc07b302230a68f81929a5f1',
    name: 'GT STAR Token',
    symbol: 'GTS',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11160/thumb/gts.PNG?1589268165'
  },
  {
    chainId: 1,
    address: '0x3155ba85d5f96b2d030a4966af206230e46849cb',
    name: 'THORChain  ERC20 ',
    symbol: 'RUNE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13677/thumb/IMG_20210123_132049_458.png?1612179252'
  },
  {
    chainId: 1,
    address: '0x9827f6e8df0ccc584ff7b37144de8bac7c446385',
    name: 'Kitcoin',
    symbol: 'KTC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8910/thumb/logo2.png?1562744333'
  },
  {
    chainId: 1,
    address: '0xb52bbd3d5bfa3836bf2b55fe3b7467219280bc2e',
    name: 'StiB',
    symbol: 'STI',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10059/thumb/200x200_%281%29.png?1576674116'
  },
  {
    chainId: 1,
    address: '0x56de8bc61346321d4f2211e3ac3c0a7f00db9b76',
    name: 'Renascent Finance',
    symbol: 'RENA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15667/thumb/photo_2021-05-21_17-31-35.png?1621825861'
  },
  {
    chainId: 1,
    address: '0xdb61354e9cf2217a29770e9811832b360a8daad3',
    name: '3X Long Litecoin To',
    symbol: 'LTCBULL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10227/thumb/683JEXMN_400x400_%281%29.png?1576618224'
  },
  {
    chainId: 1,
    address: '0xdad26bce7dcf59cd03a2455558e4dd73e1c07b66',
    name: 'Trade win',
    symbol: 'TWI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13060/thumb/Logo.png?1604821765'
  },
  {
    chainId: 1,
    address: '0x3c4a46f0c075a7f191a7459bb51eb1f81ac36f8a',
    name: '3X Short PAX Gold T',
    symbol: 'PAXGBEAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10432/thumb/683JEXMN_400x400.png?1579276381'
  },
  {
    chainId: 1,
    address: '0xe45fc4290fd3159588f532058592ea327d2e97d4',
    name: 'Alliance Cargo Dire',
    symbol: 'ACD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7694/thumb/1620803690979049473.png?1549612872'
  },
  {
    chainId: 1,
    address: '0x4f5f2eea4ed3485e5e23a39704d5fd9d0a423886',
    name: 'Torchain',
    symbol: 'TOR',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/8636/thumb/Z01mRB2BHvjsIsR95aZ4l8UTF87S5JVTgTvvdC5PVpEPaFXOoXuYwdKvEDiK3o5s-tx-o-S5KW1y2zgwn-9ftk6QUfNQsc_gnzrbvfbIgbm-_FYNoTG50PHc59XU32p6vihaXFYUnHZ9cMCHzGvq9fSqKRul9AEiUe0M9SkS65UNuL--P1ulJtc7r-rQTZpPZ3TS0VhXSGPsdwMglbhIHQDs_.jpg?1566612218'
  },
  {
    chainId: 1,
    address: '0x780116d91e5592e58a3b3c76a351571b39abcec6',
    name: 'Blockparty',
    symbol: 'BOXX',
    decimals: 15,
    logoURI: 'https://assets.coingecko.com/coins/images/5995/thumb/boxx-token.png?1547041938'
  },
  {
    chainId: 1,
    address: '0x9af15d7b8776fa296019979e70a5be53c714a7ec',
    name: 'Evolution Finance',
    symbol: 'EVN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13932/thumb/Frame_63_2.png?1612936435'
  },
  {
    chainId: 1,
    address: '0x6f4ee03ca6c942c9397d2ba5f8f83ea58f918f47',
    name: 'CashBackPro',
    symbol: 'CBP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12893/thumb/logo_%2818%29.png?1603766120'
  },
  {
    chainId: 1,
    address: '0xeed9e4f2450035d6426276a8aa2084966ee3b1bb',
    name: 'Steaks Finance',
    symbol: 'STEAK',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/12672/thumb/MtZuJ_Gq6mqy4BaGacnKNMKmviA1xeDWg2wUiPMqZ-1MzfSiTiAbEzfclWWsU4FF3QxYJcrs5ia_DSWAvdwNYbROJEkm-UK9mZRBPK_z61JciH4XhqMEDpYParjxnkEDqIoobaGaW9bOzPPN_YYHYcv0A1LxjnzdPZF47ZigVuAoOa46YZNFJ_IkM-e-Cuf_-XCpbS2EtbMYvCEhbSuzrI.jpg?1601518956'
  },
  {
    chainId: 1,
    address: '0x5cbb89b03534d82692b183882c2a2a9ff7fdeb44',
    name: 'BGT',
    symbol: 'BGT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6666/thumb/bgt.jpg?1547042870'
  },
  {
    chainId: 1,
    address: '0xcaeaf8381d4b20b43afa42061d6f80319a8881f6',
    name: 'R34P',
    symbol: 'R34P',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13393/thumb/r34p_logo.png?1608100330'
  },
  {
    chainId: 1,
    address: '0x5661c46e366570360064ae1a50a17a7a1a8f3236',
    name: 'Jllone',
    symbol: 'JLL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6890/thumb/jllone.png?1547043243'
  },
  {
    chainId: 1,
    address: '0x86807da5b92d31f67e128771cacb85f3579646ea',
    name: '3X Short TRX Token',
    symbol: 'TRXBEAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10234/thumb/683JEXMN_400x400_%281%29.png?1576618946'
  },
  {
    chainId: 1,
    address: '0x91af0fbb28aba7e31403cb457106ce79397fd4e6',
    name: 'Aergo',
    symbol: 'AERGO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4490/thumb/Aergo.png?1558084282'
  },
  {
    chainId: 1,
    address: '0xd67b1db49801b6f4c96a01a4f7964233150dc58b',
    name: 'Karatgold Coin',
    symbol: 'KBC',
    decimals: 7,
    logoURI: 'https://assets.coingecko.com/coins/images/4967/thumb/karatgold-coin.png?1547975141'
  },
  {
    chainId: 1,
    address: '0x02e88a689fdfb920e7aa6174fb7ab72add3c5694',
    name: '1X Short Bitcoin Ca',
    symbol: 'BCHHEDGE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10332/thumb/683JEXMN_400x400.png?1596707014'
  },
  {
    chainId: 1,
    address: '0x1dd80016e3d4ae146ee2ebb484e8edd92dacc4ce',
    name: 'Lead Token',
    symbol: 'LEAD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12384/thumb/lead.jpg?1599491466'
  },
  {
    chainId: 1,
    address: '0x33bfd20660eeaf952e8d5bc3236e1918701f17d0',
    name: 'RCCC',
    symbol: 'RCCC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6627/thumb/rccc_token.png?1547042828'
  },
  {
    chainId: 1,
    address: '0x9b62ec1453cea5dde760aaf662048ca6eeb66e7f',
    name: 'Consensus Cell Netw',
    symbol: 'ECELL',
    decimals: 2,
    logoURI:
      'https://assets.coingecko.com/coins/images/12624/thumb/98201030_131705818496610_9196703627136204800_n.jpg?1601283839'
  },
  {
    chainId: 1,
    address: '0x5a9f5992085e8a25a45716cb6f8ff5b57a05d332',
    name: 'Communication Devel',
    symbol: 'CDR',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/7552/thumb/Screenshot_2019-01-24_at_5.31.56_PM.png?1548322328'
  },
  {
    chainId: 1,
    address: '0x7723a1d7b939e0a49959363bf057ca57c7215e75',
    name: 'Naz coin',
    symbol: 'NAZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12908/thumb/naz.png?1603419513'
  },
  {
    chainId: 1,
    address: '0xbbff34e47e559ef680067a6b1c980639eeb64d24',
    name: 'Leverj Gluon',
    symbol: 'L2',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12950/thumb/Gluon256x256.png?1604048379'
  },
  {
    chainId: 1,
    address: '0xbd301be09eb78df47019aa833d29edc5d815d838',
    name: 'YFUEL',
    symbol: 'YFUEL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12369/thumb/Untitled-3-1.png?1600765495'
  },
  {
    chainId: 1,
    address: '0x4688a8b1f292fdab17e9a90c8bc379dc1dbd8713',
    name: 'Cover Protocol',
    symbol: 'COVER',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13563/thumb/1_eWBbDaqpxXqt7WYPSP4qSw.jpeg?1609822578'
  },
  {
    chainId: 1,
    address: '0xe1a4c5bbb704a92599fedb191f451e0d3a1ed842',
    name: 'PolkaDomain',
    symbol: 'NAME',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14832/thumb/name.png?1618580733'
  },
  {
    chainId: 1,
    address: '0x44a67c8570a61a28bafd0035042f2f0a73a64428',
    name: 'GermanCoin',
    symbol: 'GCX',
    decimals: 6,
    logoURI:
      'https://assets.coingecko.com/coins/images/11552/thumb/pNfx8Lc83ER33KaMGNgBpsN0fofGu0MwRO2eM3_1Q10nzOxqgFjM4Jbq2uHUiRlSJNyMzJi1guj4jyY-vH_00bGX2hOxE79RJX4n_6_mDp13h3GraWnxW0NvfXi1Oi3n8VwHnbX5Uf-c9K7dMJrmq5VQZPYUb2r7n_nud-D32uky7zZS4S55ilccvqocKtLMWnnNH5P5ABNuoP6a8Gd9f.jpg?1590998890'
  },
  {
    chainId: 1,
    address: '0xc82abb524257c8ee4790bfdefb452b2d6a395e21',
    name: '3X Short Midcap Ind',
    symbol: 'MIDBEAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10330/thumb/683JEXMN_400x400_%281%29.png?1578051961'
  },
  {
    chainId: 1,
    address: '0xa462d0e6bb788c7807b1b1c96992ce1f7069e195',
    name: 'Equus Mining Token',
    symbol: 'EQMT',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/12114/thumb/RYy8zR9jCnDE5Wqnnh-06q2UBuEq2NhsAaoeZhGLCy3q2zDcOOTTnaB8Tadw9-CO8IUQ34HwIPPFf4wG-7saZk1awoHQIaH9ZdHyKKeQth0GDewgEGbtgpNDxV2fxMbJB8CFpGljfF6LiLadmJsMmT0Gm0sZqzygRtxOAyTCMu5pVopFo5tz4I1R1NA-HDyjCBkXnxR6ovo0dbH.jpg?1597275175'
  },
  {
    chainId: 1,
    address: '0x8564653879a18c560e7c0ea0e084c516c62f5653',
    name: 'UpBots',
    symbol: 'UBXT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12476/thumb/UBXT.png?1600132967'
  },
  {
    chainId: 1,
    address: '0xacce88f5a63a5e65db9aa7303720be16b556e751',
    name: 'Coineal Token',
    symbol: 'NEAL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6712/thumb/coineal_token.png?1547042932'
  },
  {
    chainId: 1,
    address: '0xd0c4ad14f872bcdf7c3cc2bae99279ab5cdead30',
    name: 'EpaCoin',
    symbol: 'EPC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6698/thumb/nmNdY1f.png?1561599199'
  },
  {
    chainId: 1,
    address: '0xaf691508ba57d416f895e32a1616da1024e882d2',
    name: 'Pinknode',
    symbol: 'PNODE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15469/thumb/pnode.jpeg?1620916010'
  },
  {
    chainId: 1,
    address: '0x0077d27cb82ff12322987b225bfce0bb6e8931b4',
    name: 'Corra Finance',
    symbol: 'CORA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15684/thumb/corra.PNG?1621557324'
  },
  {
    chainId: 1,
    address: '0x5f9d86fa0454ffd6a59ccc485e689b0a832313db',
    name: 'XWC Dice Token',
    symbol: 'XDT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9189/thumb/xdt.PNG?1565000033'
  },
  {
    chainId: 1,
    address: '0x474021845c4643113458ea4414bdb7fb74a01a77',
    name: 'UnoRe',
    symbol: 'UNO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15073/thumb/UnoRe_logo_256.png?1619657040'
  },
  {
    chainId: 1,
    address: '0x5330a5805b9db68ebcf5247bbc9097163c1c2442',
    name: 'Exchange Payment Co',
    symbol: 'EXP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12516/thumb/exp_logo_03.png?1600382400'
  },
  {
    chainId: 1,
    address: '0x0778cc2e8bbad3d483e82371606d100cc8604522',
    name: 'LHCoin',
    symbol: 'LHCOIN',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/6709/thumb/lhcoin.jpg?1547042926'
  },
  {
    chainId: 1,
    address: '0x076641af1b8f06b7f8c92587156143c109002cbe',
    name: 'SoPay',
    symbol: 'SOP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6736/thumb/sopay.png?1548609877'
  },
  {
    chainId: 1,
    address: '0xde522a2778e4554707e6a8df36a4871ce9967bb5',
    name: 'FormulA',
    symbol: 'FML',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6878/thumb/formula_token.png?1547043223'
  },
  {
    chainId: 1,
    address: '0x1b980e05943de3db3a459c72325338d327b6f5a9',
    name: 'Bitgear',
    symbol: 'GEAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12127/thumb/bitgear_logo.png?1597377982'
  },
  {
    chainId: 1,
    address: '0x77e31d674a46d356b2e65892bde7758bb26238ca',
    name: 'HumanCoin',
    symbol: 'HMNC',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15079/thumb/HMNC.png?1619650086'
  },
  {
    chainId: 1,
    address: '0xfb9553afa2b5c19c5f8e5b8ee175fc01abd1555f',
    name: 'Hybrid Bank Cash',
    symbol: 'HBC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12489/thumb/hb_logo.png?1600208518'
  },
  {
    chainId: 1,
    address: '0x0dde6f6e345bfd23f3f419f0dfe04e93143b44fb',
    name: 'SOTA Finance',
    symbol: 'SOTA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14067/thumb/sota_logo.png?1614132527'
  },
  {
    chainId: 1,
    address: '0xe09fb60e8d6e7e1cebbe821bd5c3fc67a40f86bf',
    name: 'Pika',
    symbol: 'PIKA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14419/thumb/PIKA_%28White%29.jpg?1617007643'
  },
  {
    chainId: 1,
    address: '0xce593a29905951e8fc579bc092eca72577da575c',
    name: 'GROM',
    symbol: 'GR',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/13216/thumb/gr.png?1606278269'
  },
  {
    chainId: 1,
    address: '0x3204dcde0c50b7b2e606587663a0fe2ee8dfb6bf',
    name: 'Thore Exchange Toke',
    symbol: 'THEX',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/6915/thumb/photo_2018-11-18_17-49-32.jpg?1547043286'
  },
  {
    chainId: 1,
    address: '0x7a939bb714fd2a48ebeb1e495aa9aaa74ba9fa68',
    name: 'Electric Vehicle Zo',
    symbol: 'EVZ',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/9456/thumb/kLohzILUIln6mHFYOlecpWjINVIH-BVghP2vRTeuD0XteaQa7Lpn4sLcuPN4gHw8MU2pKWZCJRNwBmyyl1CYxplCLDcgSVihMC7vvfmkepY-_O_ImWBA27s4pKNlhcBnBYrc8y5WH0ZB2CjmqPh-32nPslrv329tqFWr2DAR8dl4R5LZGgeZ1ubCdtMoUua6gEL3umYShHBxrYLto.jpg?1567563510'
  },
  {
    chainId: 1,
    address: '0x2f7b88458f4e6d9abb19396b5a08b8ba7f3d4b20',
    name: 'Wave Platform',
    symbol: 'WAE',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/14695/thumb/WAVE-icons-2021-2-dark-wave.png?1618545330'
  },
  {
    chainId: 1,
    address: '0xbc8deee89f1cf4b661514185aa1ab780336c4c4a',
    name: 'Poker io',
    symbol: 'POK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6982/thumb/poker.io.png?1547043383'
  },
  {
    chainId: 1,
    address: '0xa5e99ad202bdd71d3518306cf4dd163261981af1',
    name: 'Community Chain',
    symbol: 'COMC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6948/thumb/download_%288%29.jpeg?1547043326'
  },
  {
    chainId: 1,
    address: '0x6be61833fc4381990e82d7d4a9f4c9b3f67ea941',
    name: 'Hotbit Token',
    symbol: 'HTB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5990/thumb/hotbit-token.png?1547041932'
  },
  {
    chainId: 1,
    address: '0x56a86d648c435dc707c8405b78e2ae8eb4e60ba4',
    name: 'StackOS',
    symbol: 'STACK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14989/thumb/stackos_logo.png?1619280732'
  },
  {
    chainId: 1,
    address: '0xdece0f6864c1511369ae2c30b90db9f5fe92832c',
    name: 'Disciplina',
    symbol: 'DSCP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2786/thumb/38657648.png?1619662653'
  },
  {
    chainId: 1,
    address: '0x4abb9cc67bd3da9eb966d1159a71a0e68bd15432',
    name: 'KelVPN',
    symbol: 'KEL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14851/thumb/logokelvpn200x200.png?1618797379'
  },
  {
    chainId: 1,
    address: '0xca176a8ac234446b2561293db7543e0cdadc6627',
    name: 'Hardware Chain',
    symbol: 'HDW',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/9157/thumb/hardwarechain.PNG?1564736937'
  },
  {
    chainId: 1,
    address: '0x7f3edcdd180dbe4819bd98fee8929b5cedb3adeb',
    name: 'xToken',
    symbol: 'XTK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14089/thumb/xToken.png?1614226407'
  },
  {
    chainId: 1,
    address: '0xbffe4fdcd397e7942fd7c9f99255e0aa34e4b3fb',
    name: 'Tranium',
    symbol: 'TRM',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/7161/thumb/Tranium.png?1547043680'
  },
  {
    chainId: 1,
    address: '0x56ee8c9bd1d445a3324ad83e86d8be309db8f85d',
    name: 'Solareum',
    symbol: 'SLRM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4268/thumb/Solareum-Mark-Final-01.png?1547039612'
  },
  {
    chainId: 1,
    address: '0xc7596f3fc97ae603e1d7ffa61e6efb7b6a59bed2',
    name: 'Yoo Ecology',
    symbol: 'YOO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11021/thumb/logo.1a3fe1d.png?1587160083'
  },
  {
    chainId: 1,
    address: '0x245392ee7ce736ec6a0908b67dc5d0a218230005',
    name: 'Yap Stone',
    symbol: 'YAP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8760/thumb/yap.PNG?1573118457'
  },
  {
    chainId: 1,
    address: '0x1287c0509df9a475ef178471ab2132b9dfd312b3',
    name: 'LADZ',
    symbol: 'LADZ',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/13315/thumb/ladz_logo.jpg?1607408640'
  },
  {
    chainId: 1,
    address: '0xd829664cdbf3195b2ce76047a65de29e7ed0a9a8',
    name: '3X Long Altcoin Ind',
    symbol: 'ALTBULL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10208/thumb/683JEXMN_400x400_%281%29.png?1576535126'
  },
  {
    chainId: 1,
    address: '0x4838903d6319e483ab82ae3f09a1ec36489a4193',
    name: 'Famous Coin',
    symbol: 'FAMOUS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14416/thumb/famouscoin.jpg?1615951801'
  },
  {
    chainId: 1,
    address: '0x280daacbd812725baf46e273b84426b1e10ab410',
    name: 'CBEX Token',
    symbol: 'CBEX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8195/thumb/cbex.png?1556322577'
  },
  {
    chainId: 1,
    address: '0x94d8db14831c2c08943798542c450df2844913e5',
    name: 'Zuplo',
    symbol: 'ZLP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13066/thumb/logo2x56_Mesa_de_trabajo_1.png?1604886987'
  },
  {
    chainId: 1,
    address: '0x6aca6de211ee17d38d05c7af583e43f9b1ec4c07',
    name: 'Uploadea',
    symbol: 'UPL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14642/thumb/logo-200_%282%29.png?1617486019'
  },
  {
    chainId: 1,
    address: '0x0d88ed6e74bbfd96b831231638b66c05571e824f',
    name: 'Aventus',
    symbol: 'AVT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/901/thumb/200x200_%28larger%29.png?1618547461'
  },
  {
    chainId: 1,
    address: '0x96d62cdcd1cc49cb6ee99c867cb8812bea86b9fa',
    name: 'Yearn Finance Proto',
    symbol: 'YFP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12363/thumb/yearn.jpg?1599309396'
  },
  {
    chainId: 1,
    address: '0x73a052500105205d34daf004eab301916da8190f',
    name: 'yTUSD',
    symbol: 'YTUSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14865/thumb/iearntusd_32.png?1618813131'
  },
  {
    chainId: 1,
    address: '0x12f649a9e821f90bb143089a6e56846945892ffb',
    name: 'Hyprr  Howdoo ',
    symbol: 'UDOO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3078/thumb/howdoo.png?1547744057'
  },
  {
    chainId: 1,
    address: '0x32054526df40fbb08b733abe256a8d21de58432d',
    name: 'Taurus Chain',
    symbol: 'TRT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11069/thumb/TRT.jpg?1587912808'
  },
  {
    chainId: 1,
    address: '0x3f4cd830543db25254ec0f05eac058d4d6e86166',
    name: 'Jindoge',
    symbol: 'JINDOGE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15417/thumb/JINDOGE.png?1620774122'
  },
  {
    chainId: 1,
    address: '0x6a625ddb2e2a2de0257c5129364dd80bb4afa3b9',
    name: 'IKOMP',
    symbol: 'IKOMP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14384/thumb/logo_ikomp1.png?1615789490'
  },
  {
    chainId: 1,
    address: '0xfee2fa52de307316d9d47ffe3781d4cba2c4f6fd',
    name: 'Arthur Chain',
    symbol: 'ARC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9364/thumb/download_%283%29.png?1566547313'
  },
  {
    chainId: 1,
    address: '0x94b593002a327f2a3f1b190c50d3bcc8b869b5f5',
    name: 'NewsTokens',
    symbol: 'NEWS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11088/thumb/AIIHHiVfrVww9F3s-croppedxzFZj-png.png?1598003688'
  },
  {
    chainId: 1,
    address: '0x08d967bb0134f2d07f7cfb6e246680c53927dd30',
    name: 'MATH',
    symbol: 'MATH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11335/thumb/2020-05-19-token-200.png?1589940590'
  },
  {
    chainId: 1,
    address: '0x2c644c3bbea053ed95a6bc04a94c9ce928ff9881',
    name: 'Lance Coin',
    symbol: 'LCE',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/14707/thumb/logo200x200_%282%29.png?1617873370'
  },
  {
    chainId: 1,
    address: '0xef7bce1be2ba032131326910a6c11e716daedbff',
    name: 'Spurt Plus',
    symbol: 'SPUP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14814/thumb/Untitled-design-30.png?1618543505'
  },
  {
    chainId: 1,
    address: '0xc0ba369c8db6eb3924965e5c4fd0b4c1b91e305f',
    name: 'DLP Duck Token',
    symbol: 'DUCK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13440/thumb/DLP_Duck_Token.png?1612840740'
  },
  {
    chainId: 1,
    address: '0xbb97e381f1d1e94ffa2a5844f6875e6146981009',
    name: 'WiBX',
    symbol: 'WBX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11009/thumb/40366569.png?1587104017'
  },
  {
    chainId: 1,
    address: '0xf3110b27f481f9ac3c1ba3c54de542accb2d913c',
    name: 'R64X',
    symbol: 'R64X',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15047/thumb/r64x.png?1619536164'
  },
  {
    chainId: 1,
    address: '0x8cce19943a01e78b7c277794fb081816f6151bab',
    name: '3X Long Tether Toke',
    symbol: 'USDTBULL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10207/thumb/683JEXMN_400x400_%281%29.png?1576535011'
  },
  {
    chainId: 1,
    address: '0x3d9233f15bb93c78a4f07b5c5f7a018630217cb3',
    name: 'Unicly Genesis Coll',
    symbol: 'UUNICLY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14903/thumb/Unicly_%28unicly%29.jpg?1618937278'
  },
  {
    chainId: 1,
    address: '0x6fc13eace26590b80cccab1ba5d51890577d83b2',
    name: 'Umbrella Network',
    symbol: 'UMB',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/13913/thumb/Umbrella_Network_Logo-Vertical_Version.png?1612836176'
  },
  {
    chainId: 1,
    address: '0x30634241d3a7fbcea55f0ba7df42dfd8cdd8b2cd',
    name: 'Grabity',
    symbol: 'GBT',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/8225/thumb/spaces_-LipZ5zidHYMsvWqinKQ_avatar-1608221625165_%281%29.png?1617345399'
  },
  {
    chainId: 1,
    address: '0x9e7ce36dbd1a9a6c6e80d08e38077745855edd3a',
    name: 'Bimcoin',
    symbol: 'BIM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10371/thumb/roundlogo.png?1578674498'
  },
  {
    chainId: 1,
    address: '0x1b7c4d4f226ccf3211b0f99c4fdfb84a2606bf8e',
    name: 'Orb V2',
    symbol: 'ORB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12686/thumb/favicon_%281%29.png?1601630265'
  },
  {
    chainId: 1,
    address: '0x18cc17a1eed37c02a77b0b96b7890c7730e2a2cf',
    name: 'oneLINK',
    symbol: 'ONELINK',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15102/thumb/logo_200_chainlink.png?1619731900'
  },
  {
    chainId: 1,
    address: '0xc25a3a3b969415c80451098fa907ec722572917f',
    name: 'LP sCurve',
    symbol: 'SCURVE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11899/thumb/Curvefi_sCrv_32.png?1595931870'
  },
  {
    chainId: 1,
    address: '0x42476f744292107e34519f9c357927074ea3f75d',
    name: 'Loom Network  NEW ',
    symbol: 'LOOM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14735/thumb/LOOM.png?1617967111'
  },
  {
    chainId: 1,
    address: '0xe8a1df958be379045e2b46a31a98b93a2ecdfded',
    name: 'EtherSportz',
    symbol: 'ESZ',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2890/thumb/ethersportz.png?1547037061'
  },
  {
    chainId: 1,
    address: '0xc834fa996fa3bec7aad3693af486ae53d8aa8b50',
    name: 'Convergence',
    symbol: 'CONV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14519/thumb/Convergence_Logo_%28Final%29.png?1616667041'
  },
  {
    chainId: 1,
    address: '0x50d2de5397d7c657c3d424634a2ddf4e0d73d789',
    name: 'Bliss',
    symbol: 'BLISS',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/13762/thumb/eD2mpsGhthNTkzYlFjNACI_Oy8IwC9kh7TISfep2ZcaRYoNiVFOwiUprqlrzwVTHRddCUULAEQGyPPjaTqG2ZLCPvCX5ycQF3TMrffadkJGDKe_wQ0N8QT2MO4xXMJ4c6PJgi2MG0WySER24ZbvahGPjtxBBPIb3fS21Seydik57qQyJMYB73il_L636mqaIT1gc9h75K5uT8UK.jpg?1611695377'
  },
  {
    chainId: 1,
    address: '0xc9dfcd0a1dd2d7bb6fd2ef91a16a6a1c4e9846dd',
    name: 'Hype Bet',
    symbol: 'HYPEBET',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13296/thumb/Hype-Bet-Token-Logo.png?1607074281'
  },
  {
    chainId: 1,
    address: '0xd3ec111e4e33c0a1c32e3ad0be976214d30dc37e',
    name: 'Universal Marketing',
    symbol: 'UMC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11092/thumb/unnamed.png?1588213494'
  },
  {
    chainId: 1,
    address: '0xc9df0ed2e9ef4357b51db171ff08297d10875280',
    name: 'Unicly Gone Studio ',
    symbol: 'UGONE',
    decimals: 17,
    logoURI: 'https://assets.coingecko.com/coins/images/15680/thumb/gone_studio.jpg?1621500252'
  },
  {
    chainId: 1,
    address: '0x6be7e93e45740c314c89a3be52473a0ddf2450fe',
    name: 'XCredit',
    symbol: 'XFYI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12979/thumb/xcredit_finance_logo.jpg?1604040780'
  },
  {
    chainId: 1,
    address: '0x3c7a68fb66e7a4a46b6925662818c08ee8ff4d8d',
    name: 'Tro Network',
    symbol: 'TRO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13290/thumb/logo_%2827%29.png?1607060648'
  },
  {
    chainId: 1,
    address: '0x6096d2460cf5177e40b515223428dc005ad35123',
    name: 'Precium',
    symbol: 'PCM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9490/thumb/pcm.PNG?1568015318'
  },
  {
    chainId: 1,
    address: '0x255aa6df07540cb5d3d297f0d0d4d84cb52bc8e6',
    name: 'Raiden Network Toke',
    symbol: 'RDN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1132/thumb/raiden-logo.jpg?1547035131'
  },
  {
    chainId: 1,
    address: '0x9d3e0892d11f19f5181d4a4c5d04187a9e0d7032',
    name: 'Dragonbit',
    symbol: 'DRGB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10123/thumb/drgb.PNG?1576125394'
  },
  {
    chainId: 1,
    address: '0x77c6e4a580c0dce4e5c7a17d0bc077188a83a059',
    name: 'Swerve fi USD',
    symbol: 'SWUSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12918/thumb/swerve.png?1603631063'
  },
  {
    chainId: 1,
    address: '0x3d658390460295fb963f54dc0899cfb1c30776df',
    name: 'Circuits of Value',
    symbol: 'COVAL',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/588/thumb/coval-logo.png?1599493950'
  },
  {
    chainId: 1,
    address: '0x355a458d555151d3b27f94227960ade1504e526a',
    name: 'StockChain',
    symbol: 'SCC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3577/thumb/stockchain.png?1548611100'
  },
  {
    chainId: 1,
    address: '0x7ab1fc79f319718690e9c883bac910f8e289ce8f',
    name: 'Microchain',
    symbol: 'MB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10115/thumb/mb.PNG?1575966408'
  },
  {
    chainId: 1,
    address: '0x76306f029f8f99effe509534037ba7030999e3cf',
    name: 'Acreage Coin',
    symbol: 'ACR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5100/thumb/acreage-coin.jpg?1547040494'
  },
  {
    chainId: 1,
    address: '0x4df76a9dab9bb8310e4ad3dc4336a8e26ed24ebb',
    name: 'SAPPCHAIN',
    symbol: 'SAPP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8729/thumb/v7iabEQ4_400x400.png?1560503503'
  },
  {
    chainId: 1,
    address: '0xe478d4f4a87d4d641af97ca0b5cc3db61e266357',
    name: 'LUCKY',
    symbol: 'LUCKY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11127/thumb/title-bg.png?1588737239'
  },
  {
    chainId: 1,
    address: '0xd91a6162f146ef85922d9a15ee6eb14a00344586',
    name: 'SESSIA',
    symbol: 'KICKS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8068/thumb/kicks-logo.png?1554123004'
  },
  {
    chainId: 1,
    address: '0xb5bb48567bfd0bfe9e4b08ef8b7f91556cc2a112',
    name: 'BankCoin BCash',
    symbol: 'BCASH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7692/thumb/bcash.png?1549865564'
  },
  {
    chainId: 1,
    address: '0xecb79a9b7559168174c41b153997bc462b6dfe4e',
    name: 'Nature',
    symbol: 'NAT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10403/thumb/logo_%281%29.png?1578951997'
  },
  {
    chainId: 1,
    address: '0x0d16450d347c12c086d6c94c76c5aaac35ea07e0',
    name: 'Digital Gold Token',
    symbol: 'GOLD',
    decimals: 3,
    logoURI: 'https://assets.coingecko.com/coins/images/9785/thumb/x_Kwc_Vg_400x400_%281%29.png?1571871690'
  },
  {
    chainId: 1,
    address: '0xbcfdaeb22ab6e10dfb99546e6240155edc1084f7',
    name: 'Genexi',
    symbol: 'GXI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7126/thumb/bR4FuOeq_400x400.jpg?1547043624'
  },
  {
    chainId: 1,
    address: '0x70e8de73ce538da2beed35d14187f6959a8eca96',
    name: 'XSGD',
    symbol: 'XSGD',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/12832/thumb/opiTgkh.png?1602856852'
  },
  {
    chainId: 1,
    address: '0xc278041fdd8249fe4c1aad1193876857eea3d68c',
    name: 'IdleTUSD  Best Yiel',
    symbol: 'IDLETUSDYIELD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11935/thumb/idletusdv3mMaxyield_32.png?1596263719'
  },
  {
    chainId: 1,
    address: '0x666a64f5567c3145fba7ca9ef73648cd4fa2008f',
    name: 'Energoncoin',
    symbol: 'TFG1',
    decimals: 8
  },
  {
    chainId: 1,
    address: '0x5edc1a266e8b2c5e8086d373725df0690af7e3ea',
    name: 'YottaChain',
    symbol: 'YTA',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/9197/thumb/lq0YNNPmLKW-aMKOZxJxgPgsISc6FfVwjZFwqA3rY_03jRf4BcLeNK4fR535U2UgBaSgSm4ZNrdaS7TsEygObg4W9wzL3rmOaMnG5byfjc2N7OsZRlI7S97jyFCnoFm3kd-qqCrgMQHoUz--jCS0q3kmcdfVWP_AP0SdQZjRJ5iGKyDLIkDRbHP45mfWDXLhe6F6BaJ65qAJddXjG.jpg?1565076600'
  },
  {
    chainId: 1,
    address: '0x9b1e1fc958b83e801d1342f9f9ba7da3a55ba1ef',
    name: 'Tronipay',
    symbol: 'TRP',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/8152/thumb/tronipay-logo.jpg?1555571959'
  },
  {
    chainId: 1,
    address: '0x618e75ac90b12c6049ba3b27f5d5f8651b0037f6',
    name: 'QASH',
    symbol: 'QASH',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/1220/thumb/qash.jpeg?1547035247'
  },
  {
    chainId: 1,
    address: '0x9bfedc30a3930b709c0fcb01c5c59733b64ac827',
    name: 'Facite',
    symbol: 'FIT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9225/thumb/fiy.PNG?1565233529'
  },
  {
    chainId: 1,
    address: '0x6fe355c62c6faf6946ce888ffaba9fd12355ae27',
    name: 'HashBX',
    symbol: 'HBX',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/7091/thumb/46489646-905693182887642-8012727636373536768-n.png?1550724892'
  },
  {
    chainId: 1,
    address: '0x43a96962254855f16b925556f9e97be436a43448',
    name: 'Hord',
    symbol: 'HORD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14972/thumb/Avatar_white.png?1619513849'
  },
  {
    chainId: 1,
    address: '0x672ef7e4fe230b5ca1466c5fdd40588d30fdf90a',
    name: 'Wolves of Wall Stre',
    symbol: 'WOWS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14365/thumb/wows_logo.png?1615704838'
  },
  {
    chainId: 1,
    address: '0x898bad2774eb97cf6b94605677f43b41871410b1',
    name: 'vEth2',
    symbol: 'VETH2',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15350/thumb/vEth2_200.png?1620625501'
  },
  {
    chainId: 1,
    address: '0x4b6628c5396e0740630e3c597cc47f2185002e43',
    name: 'Jemoo Community',
    symbol: 'JMC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10010/thumb/jmc.PNG?1574757559'
  },
  {
    chainId: 1,
    address: '0x7220e92d418e2eb59d0c25d195fa004bfd3afc42',
    name: 'Ad Flex Token',
    symbol: 'ADF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5620/thumb/ad-flex-token.png?1547041485'
  },
  {
    chainId: 1,
    address: '0x3d9ac8e7a9c9be11dfac1677dda901e28d44527f',
    name: 'Palace',
    symbol: 'PAA',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11141/thumb/da443cb2601f5fbdab9a2e176d62d6ce.png?1588911369'
  },
  {
    chainId: 1,
    address: '0x3a43a04d80f9881d88080bf9fa8bb720afb6c966',
    name: '3X Long Stellar Tok',
    symbol: 'XLMBULL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13334/thumb/assets_coingecko_com-ftt.jpg?1607566862'
  },
  {
    chainId: 1,
    address: '0xe81d72d14b1516e68ac3190a46c93302cc8ed60f',
    name: 'Coinlancer',
    symbol: 'CL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2445/thumb/coinlancer.png?1516072576'
  },
  {
    chainId: 1,
    address: '0x88acdd2a6425c3faae4bc9650fd7e27e0bebb7ab',
    name: 'Alchemist',
    symbol: 'MIST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14655/thumb/79158662.png?1617589045'
  },
  {
    chainId: 1,
    address: '0x624d520bab2e4ad83935fa503fb130614374e850',
    name: 'Smartshare',
    symbol: 'SSP',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/4642/thumb/smartshare.png?1548609894'
  },
  {
    chainId: 1,
    address: '0x884ddbb5dc6c2cef77d3e74c6ccca315797d655b',
    name: 'Carnomaly',
    symbol: 'CARR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14403/thumb/tcjOTKE3_400x400.png?1615895296'
  },
  {
    chainId: 1,
    address: '0xa38920c00d1a5303db538a3ea08da7a779e1f751',
    name: '3X Long TomoChain T',
    symbol: 'TOMOBULL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10170/thumb/683JEXMN_400x400_%281%29.png?1576505510'
  },
  {
    chainId: 1,
    address: '0x926be13b4d93f29ea254e4e518f33099e45d7f06',
    name: 'POC Chain',
    symbol: 'POCC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7718/thumb/logo_%281%29.png?1549956313'
  },
  {
    chainId: 1,
    address: '0xaa2ce7ae64066175e0b90497ce7d9c190c315db4',
    name: 'Suterusu',
    symbol: 'SUTER',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9830/thumb/p-NFlBlw_400x400.jpg?1572472860'
  },
  {
    chainId: 1,
    address: '0xce13abce0db5a8224616ef24d3979d466f19cf90',
    name: 'Rebit',
    symbol: 'KEYT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9891/thumb/rebit.PNG?1573119183'
  },
  {
    chainId: 1,
    address: '0x11613b1f840bb5a40f8866d857e24da126b79d73',
    name: 'Cappasity',
    symbol: 'CAPP',
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/1302/thumb/cappasity.png?1547564648'
  },
  {
    chainId: 1,
    address: '0xa2ef2757d2ed560c9e3758d1946d7bcccbd5a7fe',
    name: 'Adventure Token',
    symbol: 'TWA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13284/thumb/twa_logo.jpg?1606979012'
  },
  {
    chainId: 1,
    address: '0xe0e05c43c097b0982db6c9d626c4eb9e95c3b9ce',
    name: 'Unslashed Finance',
    symbol: 'USF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14177/thumb/Unslashed.jpeg?1614793365'
  },
  {
    chainId: 1,
    address: '0x3779d960261f882750b39c622527822c88c98e13',
    name: 'Bitget DeFi Token',
    symbol: 'BFT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11610/thumb/bitget-logo-1.png?1591831987'
  },
  {
    chainId: 1,
    address: '0x4ba012f6e411a1be55b98e9e62c3a4ceb16ec88b',
    name: 'Cybercoin',
    symbol: 'CBR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11927/thumb/ezDztuH.png?1596179588'
  },
  {
    chainId: 1,
    address: '0xc89b4a8a121dd3e726fe7515e703936cf83e3350',
    name: 'Kper Network',
    symbol: 'KPER',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12984/thumb/kper.png?1604066205'
  },
  {
    chainId: 1,
    address: '0x0e29e5abbb5fd88e28b2d355774e73bd47de3bcd',
    name: 'Hakka Finance',
    symbol: 'HAKKA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12163/thumb/Hakka-icon.png?1597746776'
  },
  {
    chainId: 1,
    address: '0x998b3b82bc9dba173990be7afb772788b5acb8bd',
    name: 'Banca',
    symbol: 'BANCA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2795/thumb/banca.png?1547036972'
  },
  {
    chainId: 1,
    address: '0x5ef227f7ce4e96c9ce90e32d4850545a6c5d099b',
    name: 'BLUECHIPS Token',
    symbol: 'BCHIP',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/7119/thumb/xIh2PBdp_400x400.jpg?1547043616'
  },
  {
    chainId: 1,
    address: '0x7d4b1d793239707445305d8d2456d2c735f6b25b',
    name: 'BlockSwap Network',
    symbol: 'CBSN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14781/thumb/bsn_dp_high.png?1618865566'
  },
  {
    chainId: 1,
    address: '0xc314b0e758d5ff74f63e307a86ebfe183c95767b',
    name: 'Adappter Token',
    symbol: 'ADP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14203/thumb/logo_on.png?1614909616'
  },
  {
    chainId: 1,
    address: '0xfc979087305a826c2b2a0056cfaba50aad3e6439',
    name: 'Dafi Protocol',
    symbol: 'DAFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14428/thumb/Dafi_Black_Icon.png?1616040406'
  },
  {
    chainId: 1,
    address: '0xbe6c8f2810ef39420d2dc2901b8414c8c45fee6d',
    name: 'Micromines',
    symbol: 'MICRO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7000/thumb/micromines.png?1548085033'
  },
  {
    chainId: 1,
    address: '0xce49c3c92b33a1653f34811a9d7e34502bf12b89',
    name: '3X Short Bitcoin SV',
    symbol: 'BSVBEAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10329/thumb/683JEXMN_400x400_%281%29.png?1578051844'
  },
  {
    chainId: 1,
    address: '0x98d8d146e644171cd47ff8588987b7bdeef72a87',
    name: 'Blockchain Exchange',
    symbol: 'BXA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7842/thumb/images.png?1551144999'
  },
  {
    chainId: 1,
    address: '0xa4f779074850d320b5553c9db5fc6a8ab15bd34a',
    name: 'YFIX finance',
    symbol: 'YFIX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12554/thumb/yfix-icon-200.png?1600739144'
  },
  {
    chainId: 1,
    address: '0xedadeb5faa413e6c8623461849dfd0b7c3790c32',
    name: 'Obortech',
    symbol: 'OBOT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14929/thumb/OBORTECH_200.png?1619070515'
  },
  {
    chainId: 1,
    address: '0xff8be4b22cedc440591dcb1e641eb2a0dd9d25a5',
    name: 'Uranus',
    symbol: 'URAC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4683/thumb/Leood48Z_400x400.jpg?1563118461'
  },
  {
    chainId: 1,
    address: '0x2ba592f78db6436527729929aaf6c908497cb200',
    name: 'Cream',
    symbol: 'CREAM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11976/thumb/Cream.png?1596593418'
  },
  {
    chainId: 1,
    address: '0x5b11aacb6bddb9ffab908fdce739bf4aed554327',
    name: 'TrueDeck',
    symbol: 'TDP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6567/thumb/truedeck.png?1548759129'
  },
  {
    chainId: 1,
    address: '0x68eb95dc9934e19b86687a10df8e364423240e94',
    name: '3X Long Bitcoin Tok',
    symbol: 'BULL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9992/thumb/683JEXMN_400x400.png?1574418750'
  },
  {
    chainId: 1,
    address: '0xddd460bbd9f79847ea08681563e8a9696867210c',
    name: 'Spendcoin',
    symbol: 'SPND',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6076/thumb/spend.png?1561607507'
  },
  {
    chainId: 1,
    address: '0x790baf0c914898c62163a61f150637d4bd180697',
    name: 'Nirvana',
    symbol: 'VANA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13541/thumb/vana.jpg?1609602820'
  },
  {
    chainId: 1,
    address: '0x174afe7a032b5a33a3270a9f6c30746e25708532',
    name: 'Humanscape',
    symbol: 'HUM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4243/thumb/Webp.net-resizeimage_%2836%29.png?1547039574'
  },
  {
    chainId: 1,
    address: '0x4b4b1d389d4f4e082b30f75c6319c0ce5acbd619',
    name: 'Heart Number',
    symbol: 'HTN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9002/thumb/htn%28200x200%29.png?1600757668'
  },
  {
    chainId: 1,
    address: '0x695db6508d66bf111d8d96adaffc5356b7f4581f',
    name: 'Coinbene Token',
    symbol: 'CONI',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/4772/thumb/CONI_LOGO.png?1612690472'
  },
  {
    chainId: 1,
    address: '0x741b0428efdf4372a8df6fb54b018db5e5ab7710',
    name: 'ARTX',
    symbol: 'ARTX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14652/thumb/logo_black_cmc.png?1617556500'
  },
  {
    chainId: 1,
    address: '0x3391bc034f2935ef0e1e41619445f998b2680d35',
    name: 'IdleUSDC  Risk Adju',
    symbol: 'IDLEUSDCSAFE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11930/thumb/idleusdc-safe.png?1596263257'
  },
  {
    chainId: 1,
    address: '0xaeaf14ce56b052dfc2a2eb0654136ecf87821fbf',
    name: 'blocsport one',
    symbol: 'BS1',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15708/thumb/blocsport.png?1621581414'
  },
  {
    chainId: 1,
    address: '0x5cc737a37a02a1b34ba8edf899aa6441765232a0',
    name: 'Burn Yield Burn',
    symbol: 'XYX',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13956/thumb/xyx_logo.png?1613351293'
  },
  {
    chainId: 1,
    address: '0x155040625d7ae3e9cada9a73e3e44f76d3ed1409',
    name: 'Revomon',
    symbol: 'REVO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14759/thumb/revomon.jpeg?1618243538'
  },
  {
    chainId: 1,
    address: '0x1ee1c46251b811075fd480fb64197f4e49c37ee0',
    name: 'IAI Token',
    symbol: 'IAI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14678/thumb/5ffd226db2d26.png?1617707349'
  },
  {
    chainId: 1,
    address: '0x4be10da47a07716af28ad199fbe020501bddd7af',
    name: 'XT com Token',
    symbol: 'XT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8391/thumb/XT_token.jpg?1557979399'
  },
  {
    chainId: 1,
    address: '0xa340f0937a8c00db11c83cc16cec12310160f0b6',
    name: '3X Short Ethereum C',
    symbol: 'ETCBEAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10328/thumb/683JEXMN_400x400_%281%29.png?1578051480'
  },
  {
    chainId: 1,
    address: '0x3b834a620751a811f65d8f599b3b72617a4418d0',
    name: '3X Short Cosmos Tok',
    symbol: 'ATOMBEAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10156/thumb/683JEXMN_400x400_%281%29.png?1576503839'
  },
  {
    chainId: 1,
    address: '0x9f0f1be08591ab7d990faf910b38ed5d60e4d5bf',
    name: 'MainCoin',
    symbol: 'MNC',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/7717/thumb/40946782_468400523662047_8336515617272102912_n.png?1549955793'
  },
  {
    chainId: 1,
    address: '0x974c98bc2e82fa18de92b7e697a1d9bd25682e80',
    name: '3X Long Ethereum Cl',
    symbol: 'ETCBULL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10326/thumb/683JEXMN_400x400_%281%29.png?1578050594'
  },
  {
    chainId: 1,
    address: '0x9b06d48e0529ecf05905ff52dd426ebec0ea3011',
    name: 'XSwap',
    symbol: 'XSP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12541/thumb/256x256_%282%29.png?1600645409'
  },
  {
    chainId: 1,
    address: '0xcba8162778e6a3eba60e1cf7c012b327340bd05d',
    name: 'SINOC',
    symbol: 'SINOC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8330/thumb/SinocLogo_Horiz.png?1557544864'
  },
  {
    chainId: 1,
    address: '0x63d958d765f5bd88efdbd8afd32445393b24907f',
    name: 'Acash Coin',
    symbol: 'ACA',
    decimals: 8,
    logoURI:
      'https://assets.coingecko.com/coins/images/9471/thumb/JxCQN9vthyjwGMcjPHzlYJwcu84LG-l3rFR3zgAOGd6BJTtxFVkmZt5PbUJytMa_RrZvYI5EeVm_vP89viF_hyFxwLGw6LlIkgSk32kGMnIy7o8zDkMBrO0wd-4zC--0Ulz9OUESJUOzy6cwH6xx7s5QbF0U3A3fVL9w4Q_jgcKJeM75iuaRMT8k6IhUq6GPiNe-vAyIjpQJI_407eFSeNyB5FVz7w4h5Lu10fLp5ti0mZZs.jpg?1575586540'
  },
  {
    chainId: 1,
    address: '0xde8607ccad60814c8d9cd6f5ce8e9031e4675c8f',
    name: 'Bit Storage Box Tok',
    symbol: 'BSBT',
    decimals: 8,
    logoURI:
      'https://assets.coingecko.com/coins/images/14136/thumb/A8QSwwsxJ0gl8Er4EF4whEcOqHLqfjWScTYHWKkeXbgoFQLQJ6de46yhxAd8kLvuj6MRQT8lloRdPoS_kqbKCXlw-mYMiqFp7j1CbhBScOzhHyZjbVgLpifjOYawUK1308Xl3aMYCZOHxfVrQZpDxbx-8KATNqX03u-CO5UlG8-bV8p6wKlBHWZdw3AoAt-o0gZCBQCynxwVd-d.jpg?1614639184'
  },
  {
    chainId: 1,
    address: '0x501e8726d06cdef66f3e0cb67f54924cca1cc894',
    name: 'BRMV Token',
    symbol: 'BRMV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14389/thumb/brmv-logo-256px.png?1615856173'
  },
  {
    chainId: 1,
    address: '0x32a7c02e79c4ea1008dd6564b35f131428673c41',
    name: 'Crust Network',
    symbol: 'CRU',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12549/thumb/sAB3KVzD_400x400.jpg?1600680411'
  },
  {
    chainId: 1,
    address: '0xe5bf6790d138b154f1df3db8d245be46a5d05ee4',
    name: 'Lyfe Land',
    symbol: 'LLAND',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14940/thumb/LLAND-Logo-200.png?1619083033'
  },
  {
    chainId: 1,
    address: '0xdecf7be29f8832e9c2ddf0388c9778b8ba76af43',
    name: 'BonusCloud',
    symbol: 'BXC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7411/thumb/ZezE6AH6_400x400.jpg?1547112132'
  },
  {
    chainId: 1,
    address: '0x59db60bd41bbc8ca4c1efee6ea2a97eae1e30cf5',
    name: '3X Long Midcap Inde',
    symbol: 'MIDBULL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10229/thumb/683JEXMN_400x400_%281%29.png?1576618503'
  },
  {
    chainId: 1,
    address: '0x51d3e4c0b2c83e62f5d517d250b3e856897d2052',
    name: 'Space Monkey',
    symbol: 'SPMK',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14776/thumb/ItWjW7D.png?1618372154'
  },
  {
    chainId: 1,
    address: '0x50de6856358cc35f3a9a57eaaa34bd4cb707d2cd',
    name: 'Razor Network',
    symbol: 'RAZOR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13797/thumb/icon.png?1611919354'
  },
  {
    chainId: 1,
    address: '0x4b7fb448df91c8ed973494f8c8c4f12daf3a8521',
    name: 'YD BTC JUN21',
    symbol: 'YD-BTC-JUN21',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/14500/thumb/UMA_square_red_logo.png?1616558980'
  },
  {
    chainId: 1,
    address: '0x64ea2c6104f1cf3035e28be0f781b6286d50934d',
    name: 'Satoshivision Coin',
    symbol: 'SVC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11150/thumb/coingecko-Logo.png?1595311196'
  },
  {
    chainId: 1,
    address: '0xb351da6ffebd5dddd1da037929fcf334d6b4a8d5',
    name: 'Flit Token',
    symbol: 'FLT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10273/thumb/Ss5vDXAO_400x400.jpg?1579730758'
  },
  {
    chainId: 1,
    address: '0xad5fe5b0b8ec8ff4565204990e4405b2da117d8e',
    name: 'TronClassic',
    symbol: 'TRXC',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/4626/thumb/trxc.png?1547039893'
  },
  {
    chainId: 1,
    address: '0x7c5d5100b339fe7d995a893af6cb496b9474373c',
    name: 'Loon Network',
    symbol: 'LOON',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/11673/thumb/ErUvUA2D4Q8hPWXxZBIuiI8MD0nrU2rDEY56d6EtVzJDTqlflAFXa2bgJ_kFwb8OtotfAvESvm-dik7d_SeJJh23f6RosUJ0AzU64QcKk5H3fu300KOJjFDFsntpRWARFKI5S4Cc8F3pcRPBkFIw6oPF58mKi83sa2DJPl-E5JMqmj6rJh5acb7Fvi80kfziWv3DHhhpg1YJYMP.jpg?1592536236'
  },
  {
    chainId: 1,
    address: '0x70968feaf13299d0dbf78f66860bab9dbe3856bc',
    name: 'Treelion',
    symbol: 'TRN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9630/thumb/yvXR4R-F_400x400.jpg?1570001020'
  },
  {
    chainId: 1,
    address: '0xc0bfeba72805f22dc18dde31467c5a55c16ff57b',
    name: 'The MetaONEz',
    symbol: 'META',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14367/thumb/metaonez.png?1615730788'
  },
  {
    chainId: 1,
    address: '0x6f063c0fcda0ea6dcc01d5a7cb3066ed4f90d1a8',
    name: 'EBSP Token',
    symbol: 'EBSP',
    decimals: 0,
    logoURI:
      'https://assets.coingecko.com/coins/images/7650/thumb/mwlDkYEKIpcWAP00hfCU7KPQti7jgbew1OKea9RGlGwpdkwOTWp7shOWG5EAsXpUonAo8ZnQoVYoWIz0Tju71QcYMesj8-4Qz_CAyxUy12MTknHGjwfpITWwDaRtHXeTDCCGMvPRPR5ebXultUTIQAtB1x9gtvA03QAi5RVg4GkfX6kB_r04Qx6w2lxuLuyUAqd4x6zaQo_1Hy8NV333I.jpg?1549001856'
  },
  {
    chainId: 1,
    address: '0xd0df3b1cf729a29b7404c40d61c750008e631ba7',
    name: 'Rug',
    symbol: 'RUG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12307/thumb/rug_token_logo.png?1599029152'
  },
  {
    chainId: 1,
    address: '0x4c133e081dfb5858e39cca74e69bf603d409e57a',
    name: '3X Long Bitcoin Cas',
    symbol: 'BCHBULL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10306/thumb/683JEXMN_400x400.png?1577743740'
  },
  {
    chainId: 1,
    address: '0x6c139349ee94ebaaff55ed52d382673c263b22d6',
    name: 'Upper Euro',
    symbol: 'EURU',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12353/thumb/UPPER-moeda-euro.png?1599260517'
  },
  {
    chainId: 1,
    address: '0x73484a262730d1d422610729e828346f9b2ff480',
    name: 'BRCP Token',
    symbol: 'BRCP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14388/thumb/brcp-500x500-verde.png?1615855378'
  },
  {
    chainId: 1,
    address: '0xd0c59798f986d333554688cd667033d469c2398e',
    name: 'Ymen Finance',
    symbol: 'YMEN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12452/thumb/logoymen.png?1603008881'
  },
  {
    chainId: 1,
    address: '0x65b678936c489a2639036755aa2107ec09569198',
    name: 'ITEN',
    symbol: 'ITEN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12314/thumb/logo-light_%281%29.png?1599084234'
  },
  {
    chainId: 1,
    address: '0x1cc0744c5106bb47a61c4e41f517cb6f1c49b547',
    name: 'Chalice Finance',
    symbol: 'CHAL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13432/thumb/chalice_logo.png?1608526094'
  },
  {
    chainId: 1,
    address: '0x1b6c5864375b34af3ff5bd2e5f40bc425b4a8d79',
    name: 'TopChain',
    symbol: 'TOPC',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/2547/thumb/topchain.png?1516372992'
  },
  {
    chainId: 1,
    address: '0xe6710e0cda178f3d921f456902707b0d4c4a332b',
    name: 'JULIEN',
    symbol: 'JULIEN',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/12954/thumb/julien_logo.jpg?1603792446'
  },
  {
    chainId: 1,
    address: '0x8a4491936a8e5a1662c8a755932b83dbe9634b0d',
    name: 'One Genesis',
    symbol: 'OG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9916/thumb/og.PNG?1573457290'
  },
  {
    chainId: 1,
    address: '0xf256f3ac4176126f55af7c6e25fa5f3cdf518b38',
    name: 'CEDARS',
    symbol: 'CEDS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12255/thumb/cedars.png?1598522772'
  },
  {
    chainId: 1,
    address: '0x829067d40a8d1233927891d9b3381d6aecee1e80',
    name: 'Ivy Mining',
    symbol: 'IVY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12756/thumb/ivymining.PNG?1602284897'
  },
  {
    chainId: 1,
    address: '0xc0e47007e084eef3ee58eb33d777b3b4ca98622f',
    name: 'StarDEX',
    symbol: 'XSTAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12500/thumb/token_icon.png?1600296899'
  },
  {
    chainId: 1,
    address: '0x773258b03c730f84af10dfcb1bfaa7487558b8ac',
    name: 'SEFI',
    symbol: 'SEFI',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/14601/thumb/Secret-Swap-Icon.jpg?1617197061'
  },
  {
    chainId: 1,
    address: '0xa5f8fc0921880cb7342368bd128eb8050442b1a1',
    name: 'Block Array',
    symbol: 'ARY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2645/thumb/block-array.png?1547350758'
  },
  {
    chainId: 1,
    address: '0x5dc60c4d5e75d22588fa17ffeb90a63e535efce0',
    name: 'dKargo',
    symbol: 'DKA',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/11875/thumb/bVD0g0dlmrEOPIkt943KZIBZ086eCshyY0jIQFti4zxYdOlFltU8tKa6uJlcA14HvNjX4bc7dxdMvlpoW5NFMND85oG4aiiCbFRhI6eowDfKEBY3BoSVY0IrBbA9SFGIxh_IYrkNC5uNdG-roZ0_TlGO3098now6Tbzga0p4IDqVk6lnaX3TuRC7pgnAYWZM15RD-uEIHr3O_3OoIIWP-.jpg?1595563347'
  },
  {
    chainId: 1,
    address: '0x3fe7940616e5bc47b0775a0dccf6237893353bb4',
    name: 'IdleDAI  Best Yield',
    symbol: 'IDLEDAIYIELD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11901/thumb/idledai_32.png?1595990312'
  },
  {
    chainId: 1,
    address: '0x23aeff664c1b2bba98422a0399586e96cc8a1c92',
    name: 'Fee Active Collater',
    symbol: 'FACT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12288/thumb/PfFTv2JB_200x200.jpg?1598826350'
  },
  {
    chainId: 1,
    address: '0x01c0987e88f778df6640787226bc96354e1a9766',
    name: 'UltrAlpha',
    symbol: 'UAT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9300/thumb/UltrAlpha.png?1565882277'
  },
  {
    chainId: 1,
    address: '0x6f0b09bfa87410ab993291ec5f8cda81f1d2acd9',
    name: 'Pilnette',
    symbol: 'PVG',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/12527/thumb/imgonline-com-ua-resize-ZHVjngZkE0gMhQ-removebg-preview.png?1600484009'
  },
  {
    chainId: 1,
    address: '0x175ab41e2cedf3919b2e4426c19851223cf51046',
    name: 'BaconSwap',
    symbol: 'BACON',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12336/thumb/bacon_swap_logo.png?1599133231'
  },
  {
    chainId: 1,
    address: '0xfd6c31bb6f05fc8db64f4b740ab758605c271fd8',
    name: 'Contracoin',
    symbol: 'CTCN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10801/thumb/Contracoin-symbol.png?1583881685'
  },
  {
    chainId: 1,
    address: '0x000146b820c5a0e08f4e71179a4b5db16e575e99',
    name: 'FairyLand',
    symbol: 'FLDT',
    decimals: 6,
    logoURI:
      'https://assets.coingecko.com/coins/images/9691/thumb/67813803_649780565517774_719766296094310400_n.jpg?1570832973'
  },
  {
    chainId: 1,
    address: '0xff19138b039d938db46bdda0067dc4ba132ec71c',
    name: 'Snetwork',
    symbol: 'SNET',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/5382/thumb/snetwork.png?1548609904'
  },
  {
    chainId: 1,
    address: '0x6b9f1f092e0b10015a4391a80cd3e6b6cefd1728',
    name: 'LuckySevenToken',
    symbol: 'LST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10553/thumb/lst7.png?1580944195'
  },
  {
    chainId: 1,
    address: '0x31141dc226c214d40b1f77feb532741d8f893c6f',
    name: 'Parallel network',
    symbol: 'PNC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7395/thumb/Screenshot_2019-01-09_at_4.03.20_PM.png?1547044101'
  },
  {
    chainId: 1,
    address: '0x91383a15c391c142b80045d8b4730c1c37ac0378',
    name: 'XStable',
    symbol: 'XST',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13765/thumb/xstable.png?1613026749'
  },
  {
    chainId: 1,
    address: '0x0405be40241a2f7822c33c5a3eee238bf2973ba3',
    name: 'Aelysir',
    symbol: 'AEL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12955/thumb/ael_logo.png?1603794538'
  },
  {
    chainId: 1,
    address: '0xa6312567e419e73951c451feaba07b6d74a0e8ce',
    name: 'SocketFinance',
    symbol: 'SFI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12962/thumb/SocketFinance_logo_256_256.png?1603854169'
  },
  {
    chainId: 1,
    address: '0xd32641191578ea9b208125ddd4ec5e7b84fcab4c',
    name: 'MDsquare',
    symbol: 'TMED',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/8558/thumb/bAdnHTWUIl4rFB4EfbH2L50BW7xi2T9ubci7v6xfYDipXkrG8Wb4LS8g6eAiMsSKqLJfV-FjH5TrYALqACw4MM2A9NfK_L6Ujnddhw1uEgL0hmVQnY96xZeL0C4kkFRJalreK9wUBzjZ4pRhZLdQf9h1drSTF7NE9moAdQ7KtuOvJqommn_q-TjQpF8GUB7J2Ejs7zIvhXXsUHGI1.jpg?1559198882'
  },
  {
    chainId: 1,
    address: '0xa4e9584daa093cb1205e17ba737c3fd015748087',
    name: 'FOREXCOIN',
    symbol: 'FOREX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6852/thumb/forex-logo.PNG?1547043174'
  },
  {
    chainId: 1,
    address: '0xdb144cd0f15ee40aac5602364b470d703d7e16b6',
    name: 'vSlice',
    symbol: 'VSL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/543/thumb/vslice.png?1547034221'
  },
  {
    chainId: 1,
    address: '0xbe393aa534f82c0ffac31bf06a23e283acb3352b',
    name: 'TokenAsset',
    symbol: 'NTB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13710/thumb/8284.png?1611097251'
  },
  {
    chainId: 1,
    address: '0xa6a840e50bcaa50da017b91a0d86b8b2d41156ee',
    name: 'EchoLink',
    symbol: 'EKO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2437/thumb/echolink.png?1547224598'
  },
  {
    chainId: 1,
    address: '0x28cb7e841ee97947a86b06fa4090c8451f64c0be',
    name: 'YF Link',
    symbol: 'YFL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12081/thumb/YFLink.png?1596987945'
  },
  {
    chainId: 1,
    address: '0x8a88f04e0c905054d2f33b26bb3a46d7091a039a',
    name: 'IGToken',
    symbol: 'IG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5434/thumb/igtoken.png?1547041141'
  },
  {
    chainId: 1,
    address: '0x0501e7a02c285b9b520fdbf1badc74ae931ad75d',
    name: 'Walnut finance',
    symbol: 'WTF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12301/thumb/photo_2020-08-31_04-24-32.jpg?1598968170'
  },
  {
    chainId: 1,
    address: '0xab5c04bbe42667610a2da07ac98ea9fa6e4a9514',
    name: 'IZEROIUM',
    symbol: 'IZER',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10777/thumb/256X256_%281%29.png?1583448004'
  },
  {
    chainId: 1,
    address: '0x174bfa6600bf90c885c7c01c7031389ed1461ab9',
    name: 'MGC Token',
    symbol: 'MGC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8469/thumb/mgc.png?1558768022'
  },
  {
    chainId: 1,
    address: '0x395768aeb16484e5785612a98e9408e4cc1269ec',
    name: 'Rush',
    symbol: 'RUC',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/12834/thumb/120030147_104370844760714_1920358536411869061_n.jpg?1602882889'
  },
  {
    chainId: 1,
    address: '0xb2c19ba4d5246d4c587a62f0dfe9f78083568455',
    name: 'CMDX',
    symbol: 'CMDX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9371/thumb/CMDX_Logo_Web_Preview.png?1566604497'
  },
  {
    chainId: 1,
    address: '0xe9e73e1ae76d17a16cc53e3e87a9a7da78834d37',
    name: 'Camp',
    symbol: 'CAMP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13338/thumb/CampGlobal_256256.png?1607576849'
  },
  {
    chainId: 1,
    address: '0x22b6c31c2beb8f2d0d5373146eed41ab9ede3caf',
    name: 'cocktailbar finance',
    symbol: 'COC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13121/thumb/cocktail_bar_logo.png?1605488103'
  },
  {
    chainId: 1,
    address: '0x41875c2332b0877cdfaa699b641402b7d4642c32',
    name: 'FUTURAX',
    symbol: 'FTXT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/5796/thumb/futurax.png?1547484031'
  },
  {
    chainId: 1,
    address: '0x86d3f38edaf7e7959e5d8e6aea5ad3187b78c346',
    name: 'MTI Finance',
    symbol: 'MTI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13061/thumb/mti.png?1605153678'
  },
  {
    chainId: 1,
    address: '0x9080e92296a176883aab1d7d1b7e50bc055b0caa',
    name: 'YFI Credits Group',
    symbol: 'YFICG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12932/thumb/70994628.png?1603697599'
  },
  {
    chainId: 1,
    address: '0x70debcdab2ef20be3d1dbff6a845e9ccb6e46930',
    name: 'BIKI',
    symbol: 'BIKI',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/8119/thumb/BiKi_icon.png?1581935375'
  },
  {
    chainId: 1,
    address: '0xc626e0619ac79afea9281c8eb9b1a9f9d3fab532',
    name: 'Freedom Reserve',
    symbol: 'FR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13342/thumb/J6uNL2FS_400x400.jpg?1607589046'
  },
  {
    chainId: 1,
    address: '0x3142dad33b1c6e1371d8627365f2ee2095eb6b37',
    name: 'Hauteclere Shards',
    symbol: 'HAUT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12815/thumb/astt.png?1602884781'
  },
  {
    chainId: 1,
    address: '0x43ae53b8c920c6c69f4abf393fcb68a6dd4cbab4',
    name: 'RiveMont',
    symbol: 'RVMT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13742/thumb/photo-2020-12-29-03-51-26.jpg?1611373571'
  },
  {
    chainId: 1,
    address: '0x0bf6261297198d91d4fa460242c69232146a5703',
    name: 'Libera',
    symbol: 'LIB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12211/thumb/19nmRSeR_400x400.jpg?1598234697'
  },
  {
    chainId: 1,
    address: '0xada62f7ccd6af6cacff04accbc4f56f3d4ffd4ef',
    name: 'PlayFuel',
    symbol: 'PLF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10313/thumb/BNXcW4kq_400x400.jpg?1577833532'
  },
  {
    chainId: 1,
    address: '0x69428bb4272e3181de9e3cab461e19b0131855c8',
    name: 'SYBC Coin',
    symbol: 'SYBC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12901/thumb/sybc.png?1603346599'
  },
  {
    chainId: 1,
    address: '0xeb7c20027172e5d143fb030d50f91cece2d1485d',
    name: 'eBitcoin',
    symbol: 'EBTC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/978/thumb/ebtc.png?1547034866'
  },
  {
    chainId: 1,
    address: '0x5872e64c3f93363822d2b1e4717be3398fdcea51',
    name: 'Unicly Hashmasks Co',
    symbol: 'UMASK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14902/thumb/uMask.png?1618936855'
  },
  {
    chainId: 1,
    address: '0xc2b58812c24020ea924c3d7c241c441605f12e75',
    name: 'Entherfound',
    symbol: 'ETF',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10728/thumb/Untitled-design.png?1582667621'
  },
  {
    chainId: 1,
    address: '0xbc41d05287498dec58129560de6bd1b8d4e3ac1d',
    name: '3X Short Tezos Toke',
    symbol: 'XTZBEAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10175/thumb/683JEXMN_400x400_%281%29.png?1576506808'
  },
  {
    chainId: 1,
    address: '0xa9fc65da36064ce545e87690e06f5de10c52c690',
    name: '3X Short Bitcoin Ca',
    symbol: 'BCHBEAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10268/thumb/683JEXMN_400x400_%281%29.png?1576835432'
  },
  {
    chainId: 1,
    address: '0x00b7db6b4431e345eee5cc23d21e8dbc1d5cada3',
    name: 'CyberTronchain',
    symbol: 'CTC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13391/thumb/zNdoAnW.png?1608095358'
  },
  {
    chainId: 1,
    address: '0xf0998faebc12188172310403814e0399f7af3f73',
    name: 'HavEtherToken',
    symbol: 'HET',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7744/thumb/IMG_20181219_111043.jpg?1550192325'
  },
  {
    chainId: 1,
    address: '0xbd05cee8741100010d8e93048a80ed77645ac7bf',
    name: 'Cyclops Treasure',
    symbol: 'CYTR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12698/thumb/Untitled-design-9-removebg-preview.png?1601855912'
  },
  {
    chainId: 1,
    address: '0xbc6819294c554f46bee6f5eb5260089a03e270a2',
    name: 'Defla',
    symbol: 'DEFLA',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13879/thumb/98GNpAw.png?1612491558'
  },
  {
    chainId: 1,
    address: '0x757703bd5b2c4bbcfde0be2c0b0e7c2f31fcf4e9',
    name: 'Zest Token',
    symbol: 'ZEST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6837/thumb/33_%281%29.jpg?1594437564'
  },
  {
    chainId: 1,
    address: '0x6aac8cb9861e42bf8259f5abdc6ae3ae89909e11',
    name: 'Bitcoin Red',
    symbol: 'BTCRED',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/1054/thumb/bitcoin-red.png?1547034993'
  },
  {
    chainId: 1,
    address: '0xd667d5a228cb09f190c4308cf89d39cab18a413c',
    name: 'GES',
    symbol: 'GES',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12910/thumb/ges-logo.jpg?1603422780'
  },
  {
    chainId: 1,
    address: '0x5ab793e36070f0fac928ea15826b0c1bc5365119',
    name: 'YUKI COIN',
    symbol: 'YUKI',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/5464/thumb/KoNqMvi.png?1547041201'
  },
  {
    chainId: 1,
    address: '0x646707246d7d5c2a86d7206f41ca8199ea9ced69',
    name: 'Porkchop',
    symbol: 'CHOP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12169/thumb/Porkchop.png?1597803275'
  },
  {
    chainId: 1,
    address: '0x324af2d5353f2dd138e234b359d30d67c64b1b20',
    name: 'Oracle System',
    symbol: 'ORC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12517/thumb/www.oraclesystem.io-home-org-logo.png?1600382654'
  },
  {
    chainId: 1,
    address: '0x36dcffe069a3f2878fab2a46d81e83d462d0cbf7',
    name: 'Tcbcoin',
    symbol: 'TCFX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12570/thumb/6MY5OAW.png?1600840730'
  },
  {
    chainId: 1,
    address: '0x7a8ca2f815a260660158a38c34ca321a3605ecfe',
    name: 'BIZZCOIN',
    symbol: 'BIZZ',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10979/thumb/bizzcoin.PNG?1586766076'
  },
  {
    chainId: 1,
    address: '0x256845e721c0c46d54e6afbd4fa3b52cb72353ea',
    name: 'UniDollar',
    symbol: 'UNIUSD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11637/thumb/Unidollar.png?1592272468'
  },
  {
    chainId: 1,
    address: '0x559efe1d7a54fb1c7f25a036952f8498cdf02edb',
    name: 'DOGG Token',
    symbol: 'DOGG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15611/thumb/Over-Page-0-1-256x.png?1621319887'
  },
  {
    chainId: 1,
    address: '0x8282df223ac402d04b2097d16f758af4f70e7db0',
    name: 'YFLink Synthetic',
    symbol: 'SYFL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13884/thumb/download_%281%29.png?1612493981'
  },
  {
    chainId: 1,
    address: '0x39fa206c1648944f92e8f7b626e1cbdf78d7e9db',
    name: 'DXY Finance',
    symbol: 'DXY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12839/thumb/dxy_finance.png?1602903489'
  },
  {
    chainId: 1,
    address: '0x8af785687ee8d75114b028997c9ca36b5cc67bc4',
    name: '3X Long OKB Token',
    symbol: 'OKBBULL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10431/thumb/683JEXMN_400x400.png?1579276257'
  },
  {
    chainId: 1,
    address: '0x2c000c0093de75a8fa2fccd3d97b314e20b431c3',
    name: 'Huobi Litecoin',
    symbol: 'HLTC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14110/thumb/HLTC.png?1614335967'
  },
  {
    chainId: 1,
    address: '0x883a158c9b28f8d626acfcfbe1028f49e70c9d75',
    name: 'CNG Casino',
    symbol: 'CNG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11573/thumb/cng_casino.png?1591230675'
  },
  {
    chainId: 1,
    address: '0x4e12eb8e506ccd1427f6b8f7faa3e88fb698eb28',
    name: 'Jack Token',
    symbol: 'JACK',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10991/thumb/Jacktoken200x200.png?1586938972'
  },
  {
    chainId: 1,
    address: '0x9be89d2a4cd102d8fecc6bf9da793be995c22541',
    name: 'Binance Wrapped BTC',
    symbol: 'BBTC',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/14867/thumb/binance-btc_32.png?1618814666'
  },
  {
    chainId: 1,
    address: '0x96cf107e446a6e528ffd045f4eb6dff3cdb6a666',
    name: '3XT',
    symbol: '3XT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13507/thumb/3XT.jpg?1609225380'
  },
  {
    chainId: 1,
    address: '0x016ee7373248a80bde1fd6baa001311d233b3cfa',
    name: '3X Short Bitcoin To',
    symbol: 'BEAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9991/thumb/683JEXMN_400x400.png?1574418639'
  },
  {
    chainId: 1,
    address: '0x4f6103bad230295bacf30f914fda7d4273b7f585',
    name: 'KI',
    symbol: 'XKI',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/15694/thumb/xki-color.png?1621569714'
  },
  {
    chainId: 1,
    address: '0x6417e8673dedd7a0471a87804bf85a559fd8bcc2',
    name: 'Aura Protocol',
    symbol: 'AURA',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13491/thumb/aura_protocol_logo.png?1609124742'
  },
  {
    chainId: 1,
    address: '0xa38b7ee9df79955b90cc4e2de90421f6baa83a3d',
    name: 'Monkey Coin',
    symbol: 'MC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8705/thumb/3bae7cca74462350.jpg?1621126725'
  },
  {
    chainId: 1,
    address: '0xe6f1966d04cfcb9cd1b1dc4e8256d8b501b11cba',
    name: 'SafeEarth',
    symbol: 'SAFEEARTH',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15449/thumb/SafeEarthLogo2000.png?1620833647'
  },
  {
    chainId: 1,
    address: '0x350a6a30c79df3600c4e0e67deab0a64b645e2c2',
    name: 'StrongHold',
    symbol: 'STRNG',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13540/thumb/strng.png?1609599778'
  },
  {
    chainId: 1,
    address: '0xd110bb8a24b100c37af7310416e685af807c1f10',
    name: 'VODA TOKEN',
    symbol: 'WDT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8534/thumb/wdt.png?1559115489'
  },
  {
    chainId: 1,
    address: '0x7f969c4d388ca0ae39a4fddb1a6f89878ca2fbf8',
    name: 'Global Game Coin',
    symbol: 'GGC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10301/thumb/ggcoin_200.png?1577690908'
  },
  {
    chainId: 1,
    address: '0xcae838187c1f813fc3bfae6387bf34aeacb5bef6',
    name: 'Commons Earth',
    symbol: 'COM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14087/thumb/commons-earth.png?1614214707'
  },
  {
    chainId: 1,
    address: '0xb4a677b0e363c3815d46326954a4e4d2b1ace357',
    name: 'THENODE',
    symbol: 'THE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10292/thumb/thenode_logo.png?1577334103'
  },
  {
    chainId: 1,
    address: '0x3f5be50e4651ee184109a0b1b71d344d12e8b603',
    name: 'RFYield Finance',
    symbol: 'RFY',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13591/thumb/3QVx5Fp.png?1609983766'
  },
  {
    chainId: 1,
    address: '0x0b66015bc42601d5986b540373b4e02d7383c7c1',
    name: 'Fission Cash',
    symbol: 'FCX',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13620/thumb/FCX-Logo-W-e1609294744561.png?1610333962'
  },
  {
    chainId: 1,
    address: '0x53352e7d6620cc931c0c9318166ae2a92c1a4666',
    name: 'AI Mining',
    symbol: 'AIM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13635/thumb/logo_-_2021-01-13T091008.612.png?1610500226'
  },
  {
    chainId: 1,
    address: '0xc2685307ef2b8842fbf3def432408c46bd0420fd',
    name: '3X Long LEO Token',
    symbol: 'LEOBULL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10231/thumb/683JEXMN_400x400_%281%29.png?1576618672'
  },
  {
    chainId: 1,
    address: '0xdbb2f12cb89af05516768c2c69a771d92a25d17c',
    name: 'Beast DAO',
    symbol: 'BEAST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13667/thumb/beastdao_logo.png?1610611281'
  },
  {
    chainId: 1,
    address: '0x3e1e15afd5d50b090adcc88160dd84a48ea1b80e',
    name: 'vSPY',
    symbol: 'VSPY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13944/thumb/itovault_logo.png?1613171346'
  },
  {
    chainId: 1,
    address: '0xd31a9d28d66a1f7e62b5565416ea14607690f788',
    name: 'HealthChainUS',
    symbol: 'HCUT',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/11868/thumb/60633927_588600498303961_3291974703035449344_n.png?1595496238'
  },
  {
    chainId: 1,
    address: '0x4726e9de74573255ea41e0d00b49b833c77a671e',
    name: 'USDEX',
    symbol: 'USDEX',
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/13746/thumb/Untitled-design-4.png?1611413797'
  },
  {
    chainId: 1,
    address: '0x592481a5f6b4f078cc303c2cde4337dfa2d76fa0',
    name: 'Sting',
    symbol: 'STN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13760/thumb/sting.png?1611612491'
  },
  {
    chainId: 1,
    address: '0x089a502032166e07ae83eb434c16790ca2fa4661',
    name: 'CURE farm',
    symbol: 'CURE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15381/thumb/cure.PNG?1620697586'
  },
  {
    chainId: 1,
    address: '0xb551d08d2189ef67b4788be2c35c0743693625ca',
    name: 'EAutocoin',
    symbol: 'ATO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7132/thumb/ato.png?1547043631'
  },
  {
    chainId: 1,
    address: '0xa1653cb37852249e4f18dfbc473a5ce3f88fa6ad',
    name: '3X Short TomoChain ',
    symbol: 'TOMOBEAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10165/thumb/683JEXMN_400x400_%281%29.png?1576504760'
  },
  {
    chainId: 1,
    address: '0xc0e6737a29de7a00e2f6011924eb257106cb082f',
    name: 'Glosfer Token',
    symbol: 'GLO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9237/thumb/ItBMqQKt_400x400.jpg?1565336364'
  },
  {
    chainId: 1,
    address: '0xa3ceac0aac5c5d868973e546ce4731ba90e873c2',
    name: 'Self Storage Coin',
    symbol: 'STOR',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/8159/thumb/stor33_2.png?1555897923'
  },
  {
    chainId: 1,
    address: '0x86eb791495be777db763142a2c547d1112554fb8',
    name: '3X Short Huobi Toke',
    symbol: 'HTBEAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10430/thumb/683JEXMN_400x400.png?1579276187'
  },
  {
    chainId: 1,
    address: '0x593114f03a0a575aece9ed675e52ed68d2172b8c',
    name: 'BidiPass',
    symbol: 'BDP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3890/thumb/AxiFAoHc_400x400.jpg?1567461770'
  },
  {
    chainId: 1,
    address: '0xdf276a2442ccbbdf0707427668434d6d130b75eb',
    name: 'DeFini',
    symbol: 'DFNI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14616/thumb/logo_-_2021-04-01T133425.308.png?1617255308'
  },
  {
    chainId: 1,
    address: '0xbbe5c81412bad19877300d63cc8d8264a154091e',
    name: 'Polar Chain',
    symbol: 'POLA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13818/thumb/download_%2820%29.png?1612051463'
  },
  {
    chainId: 1,
    address: '0x15c9dd08fb16331b9749a8d7d16bcd71c985f190',
    name: 'CC',
    symbol: 'CC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11593/thumb/CC_logo_200.png?1591599112'
  },
  {
    chainId: 1,
    address: '0x08130635368aa28b217a4dfb68e1bf8dc525621c',
    name: 'AfroDex',
    symbol: 'AFROX',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/10047/thumb/AfroDex_LOGO.png?1575243022'
  },
  {
    chainId: 1,
    address: '0xbb0a009ba1eb20c5062c790432f080f6597662af',
    name: 'Bitbot Protocol',
    symbol: 'BBP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13750/thumb/bitbot_protocol.png?1611477932'
  },
  {
    chainId: 1,
    address: '0xb3299d4bab93bf04d5b11bc49cd6dfad1f77d23f',
    name: '3X Short Cardano To',
    symbol: 'ADABEAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10232/thumb/683JEXMN_400x400_%281%29.png?1576618766'
  },
  {
    chainId: 1,
    address: '0x92e52a1a235d9a103d970901066ce910aacefd37',
    name: 'U CASH',
    symbol: 'UCASH',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/2901/thumb/ucash.png?1547037074'
  },
  {
    chainId: 1,
    address: '0xd8a8843b0a5aba6b030e92b3f4d669fad8a5be50',
    name: 'AfroDex Labs Token',
    symbol: 'AFDLT',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/9908/thumb/GOLDEN_TOKEN_4.png?1575868746'
  },
  {
    chainId: 1,
    address: '0x2344871f523cbb28a4f60045531184cf1f03ad24',
    name: 'RoBet Coin',
    symbol: 'ROBET',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6286/thumb/RoBet_Coin.png?1547042328'
  },
  {
    chainId: 1,
    address: '0xae697f994fc5ebc000f8e22ebffee04612f98a0d',
    name: 'LGCY Network',
    symbol: 'LGCY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12181/thumb/LGCY_network.jpg?1597926587'
  },
  {
    chainId: 1,
    address: '0x22314b3d1375548c969eaae65e43203b51f9e9e9',
    name: 'Philosafe Token',
    symbol: 'PLST',
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/8336/thumb/plst.png?1557603189'
  },
  {
    chainId: 1,
    address: '0xdff3d69a00759449f091561a0af99a218982bd7f',
    name: 'Our Pay',
    symbol: 'OUR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13936/thumb/Our_pay_logo.png?1612943572'
  },
  {
    chainId: 1,
    address: '0x3fa807b6f8d4c407e6e605368f4372d14658b38c',
    name: 'Rise Protocol',
    symbol: 'RISE',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13959/thumb/Uo19zBht_400x400.png?1613354499'
  },
  {
    chainId: 1,
    address: '0x9a96e767bfcce8e80370be00821ed5ba283d4a17',
    name: 'GOGO Finance',
    symbol: 'GOGO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13857/thumb/gogofinance.png?1612404853'
  },
  {
    chainId: 1,
    address: '0x6c3be406174349cfa4501654313d97e6a31072e1',
    name: 'CNNS',
    symbol: 'CNNS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8362/thumb/cnns.png?1557992686'
  },
  {
    chainId: 1,
    address: '0x2129ff6000b95a973236020bcd2b2006b0d8e019',
    name: 'MYX Network',
    symbol: 'MYX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12128/thumb/oKkmWEt.png?1597395102'
  },
  {
    chainId: 1,
    address: '0xe58c8df0088cf27b26c7d546a9835deacc29496c',
    name: '1X Short TRX Token',
    symbol: 'TRXHEDGE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12060/thumb/683JEXMN_400x400.png?1596705582'
  },
  {
    chainId: 1,
    address: '0xde9d41a01bb11a9f41e709242824e54c3917084e',
    name: 'Ignite',
    symbol: 'IGN',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13953/thumb/IGN_logo.png?1615436936'
  },
  {
    chainId: 1,
    address: '0x9355372396e3f6daf13359b7b607a3374cc638e0',
    name: 'WHALE',
    symbol: 'WHALE',
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/11797/thumb/WHALE.png?1595004706'
  },
  {
    chainId: 1,
    address: '0xe54b3458c47e44c37a267e7c633afef88287c294',
    name: 'Artfinity Token',
    symbol: 'AT',
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/8339/thumb/artfinity.png?1557604049'
  },
  {
    chainId: 1,
    address: '0x49bf0220c9ce17e52dcca3d217231746d676085b',
    name: 'Vixco',
    symbol: 'VIX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15405/thumb/vixco.png?1620811916'
  },
  {
    chainId: 1,
    address: '0x7ef1081ecc8b5b5b130656a41d4ce4f89dbbcc8c',
    name: 'Compounder',
    symbol: 'CP3R',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13182/thumb/compounder_logo.png?1606018434'
  },
  {
    chainId: 1,
    address: '0x7af89c8a06719271a96e62e290ea9ed192e73fc1',
    name: 'Gold Mining Members',
    symbol: 'GMM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11660/thumb/gmm_logo_token.png?1592432359'
  },
  {
    chainId: 1,
    address: '0x16987c021c14ca1045cd0afebb33c124a58bf16c',
    name: 'Voyager',
    symbol: 'VGR',
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/7381/thumb/PKQbxUtP_400x400.jpg?1547044079'
  },
  {
    chainId: 1,
    address: '0x21f54372c07b930b79c5c2d9bb0eaaca86c3b298',
    name: 'Banana Finance',
    symbol: 'BANANA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13419/thumb/bananacoin.png?1608446599'
  },
  {
    chainId: 1,
    address: '0xa3a3f076413a362bb0d69eea1dc5b0e79c831edc',
    name: 'Cocaine Cowboy Shar',
    symbol: 'COKE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12937/thumb/Cocaine_Cowboy.png?1603720411'
  },
  {
    chainId: 1,
    address: '0xc48b1ac1417db27c4e2c2ed3dae5a3d2fbb07dc5',
    name: 'StarBlock',
    symbol: 'STB',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/8668/thumb/starblock-logo-for-coinmarketcap.png?1560207677'
  },
  {
    chainId: 1,
    address: '0xb7e77aebbe0687d2eff24cc90c41a3b6ea74bdab',
    name: 'Project WITH',
    symbol: 'WIKEN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9182/thumb/2_0C4zaAE66q6I_k4uBmgqkw.png?1564995953'
  },
  {
    chainId: 1,
    address: '0x058349297672b6cc7ccb6e59a679c5add74a6898',
    name: 'Ethereum Vault',
    symbol: 'ETHV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13051/thumb/Tok425m.jpeg?1604654743'
  },
  {
    chainId: 1,
    address: '0xc175e77b04f2341517334ea3ed0b198a01a97383',
    name: '3X Long TRX Token',
    symbol: 'TRXBULL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10266/thumb/683JEXMN_400x400_%281%29.png?1576834617'
  },
  {
    chainId: 1,
    address: '0x1de6cb5b085ac84bd247b8176c781e913166aa6b',
    name: 'BrillianceX',
    symbol: 'BRILX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15574/thumb/rs_w_1200_cg_true.png?1621234563'
  },
  {
    chainId: 1,
    address: '0x9ffc3bcde7b68c46a6dc34f0718009925c1867cb',
    name: 'Huobi Polkadot',
    symbol: 'HDOT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14107/thumb/hdot.png?1614333466'
  },
  {
    chainId: 1,
    address: '0x9b4e2b4b13d125238aa0480dd42b4f6fc71b37cc',
    name: 'MyToken',
    symbol: 'MT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4109/thumb/2712.png?1547039296'
  },
  {
    chainId: 1,
    address: '0x47632da9227e322eda59f9e7691eacc6430ac87c',
    name: 'YFI Business',
    symbol: 'YFIB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12346/thumb/fkPIR2pc_400x400.jpg?1599192901'
  },
  {
    chainId: 1,
    address: '0x45f2ab0ca2116b2e1a70bf5e13293947b25d0272',
    name: 'Global Reserve Syst',
    symbol: 'GLOB',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10773/thumb/g20.png?1583379257'
  },
  {
    chainId: 1,
    address: '0x07150e919b4de5fd6a63de1f9384828396f25fdc',
    name: 'Base Protocol',
    symbol: 'BASE',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13265/thumb/200x200green.png?1607650121'
  },
  {
    chainId: 1,
    address: '0x3f8a2f7bcd70e7f7bdd3fbb079c11d073588dea2',
    name: 'FIRE',
    symbol: 'FIRE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11888/thumb/tYmI5eG.png?1597282794'
  },
  {
    chainId: 1,
    address: '0x63726dae7c57d25e90ec829ce9a5c745ffd984d3',
    name: 'Decentralized Oracl',
    symbol: 'DEOR',
    decimals: 10,
    logoURI: 'https://assets.coingecko.com/coins/images/14189/thumb/z-MqKNfF_400x400.jpg?1614837153'
  },
  {
    chainId: 1,
    address: '0xa456b515303b2ce344e9d2601f91270f8c2fea5e',
    name: 'Cornichon',
    symbol: 'CORN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13235/thumb/cornichon.png?1606629943'
  },
  {
    chainId: 1,
    address: '0x77fba179c79de5b7653f68b5039af940ada60ce0',
    name: 'Ampleforth Governan',
    symbol: 'FORTH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14917/thumb/photo_2021-04-22_00.00.03.jpeg?1619020835'
  },
  {
    chainId: 1,
    address: '0x8a8221628361fa25294a83a172dd4f0133207b37',
    name: 'Shadetech',
    symbol: 'SHD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14394/thumb/_MVDoFXx_400x400.jpg?1615878255'
  },
  {
    chainId: 1,
    address: '0x7031ab87dcc46818806ec07af46fa8c2ad2a2bfc',
    name: 'Tribute',
    symbol: 'TRBT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12463/thumb/Tribute_Coin-04.png?1600034100'
  },
  {
    chainId: 1,
    address: '0xfe39e6a32acd2af7955cb3d406ba2b55c901f247',
    name: 'ZBG Token',
    symbol: 'ZT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6566/thumb/ztcoin.jpg?1547042777'
  },
  {
    chainId: 1,
    address: '0x1296a923cd4f39116e0297d921fe25fe228552c6',
    name: 'Stoner Doge Finance',
    symbol: 'STOGE',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14896/thumb/Stoge-logo.png?1618916387'
  },
  {
    chainId: 1,
    address: '0x7f0c8b125040f707441cad9e5ed8a8408673b455',
    name: 'CSP DAO Network',
    symbol: 'NEBO',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13436/thumb/csp_dao.png?1608535699'
  },
  {
    chainId: 1,
    address: '0xada0a1202462085999652dc5310a7a9e2bf3ed42',
    name: 'CoinShares Gold and',
    symbol: 'CGI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14005/thumb/coinshares_gold.jpg?1613602163'
  },
  {
    chainId: 1,
    address: '0x6020da0f7c1857dbe4431ec92a15cc318d933eaa',
    name: 'En Tan Mo',
    symbol: 'ETM',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8652/thumb/En-tan-mo.jpg?1560148966'
  },
  {
    chainId: 1,
    address: '0x06af07097c9eeb7fd685c692751d5c66db49c215',
    name: 'Chai',
    symbol: 'CHAI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10147/thumb/CHAI.png?1576467289'
  },
  {
    chainId: 1,
    address: '0x8028a34ec63678bcb64325f805449beba98c28a8',
    name: 'Aggregator Network',
    symbol: 'AGGT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15520/thumb/aggt.PNG?1621119056'
  },
  {
    chainId: 1,
    address: '0x90b417ab462440cf59767bcf72d0d91ca42f21ed',
    name: '3X Short Altcoin In',
    symbol: 'ALTBEAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10199/thumb/683JEXMN_400x400_%281%29.png?1576533954'
  },
  {
    chainId: 1,
    address: '0x7aa6b33fb7f395ddbca7b7a33264a3c799fa626f',
    name: '3X Long Dogecoin To',
    symbol: 'DOGEBULL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10283/thumb/683JEXMN_400x400.png?1577007477'
  },
  {
    chainId: 1,
    address: '0x49614661737efbfc6a102efaeefdc8e197f7cc0e',
    name: 'Escroco Emerald',
    symbol: 'ESCE',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/1836/thumb/escorco_emerald.png?1547036149'
  },
  {
    chainId: 1,
    address: '0xdc47f2ba852669b178699449e50682d6ceaf8c07',
    name: 'Ston',
    symbol: 'STON',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13001/thumb/ston-v2-200x200.png?1604284194'
  },
  {
    chainId: 1,
    address: '0xaa6e8127831c9de45ae56bb1b0d4d4da6e5665bd',
    name: 'ETH 2x Flexible Lev',
    symbol: 'ETH2X-FLI',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14392/thumb/ETH2x-FLI_%281%29.png?1615875910'
  },
  {
    chainId: 1,
    address: '0x98e0438d3ee1404fea48e38e92853bb08cfa68bd',
    name: 'TVT',
    symbol: 'TVT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/8388/thumb/g8hXw4QX_400x400.jpg?1557973343'
  },
  {
    chainId: 1,
    address: '0xc4de189abf94c57f396bd4c52ab13b954febefd8',
    name: 'B20',
    symbol: 'B20',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13803/thumb/b20.png?1611996305'
  },
  {
    chainId: 1,
    address: '0x763f8b4fb067190e739ed6acb12f40f2f3890840',
    name: 'Digex',
    symbol: 'DIGEX',
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/12891/thumb/Digex_token.png?1603332770'
  },
  {
    chainId: 1,
    address: '0x6a6c2ada3ce053561c2fbc3ee211f23d9b8c520a',
    name: 'TONToken',
    symbol: 'TON',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12334/thumb/ton.jpg?1599128436'
  },
  {
    chainId: 1,
    address: '0xb4272071ecadd69d933adcd19ca99fe80664fc08',
    name: 'CryptoFranc',
    symbol: 'XCHF',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8465/thumb/WhuiuJBc_400x400.jpg?1558699947'
  },
  {
    chainId: 1,
    address: '0xc32cc5b70bee4bd54aa62b9aefb91346d18821c4',
    name: 'Iteration Syndicate',
    symbol: 'ITS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13297/thumb/1_LOssD4ENHv72I5e9PAsndA_%281%29.png?1607223580'
  },
  {
    chainId: 1,
    address: '0x61fd1c62551850d0c04c76fce614cbced0094498',
    name: 'IDK',
    symbol: 'IDK',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9301/thumb/idk-logo.png?1565861759'
  },
  {
    chainId: 1,
    address: '0xeeeeeeeee2af8d0e1940679860398308e0ef24d6',
    name: 'Ethverse',
    symbol: 'ETHV',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12076/thumb/logo_%2888%29.png?1596751504'
  },
  {
    chainId: 1,
    address: '0xb48b7e5bf6563b3e0a85055821a83deb8cfc12f6',
    name: 'NOVA',
    symbol: 'NOVA',
    decimals: 3,
    logoURI: 'https://assets.coingecko.com/coins/images/6545/thumb/N7d0x35q_400x400.jpg?1547042760'
  },
  {
    chainId: 1,
    address: '0xdf22da9a8c1d80095175ae601d182a734923f01a',
    name: 'BitpakcoinToken',
    symbol: 'BPAKC',
    decimals: 8,
    logoURI:
      'https://assets.coingecko.com/coins/images/8382/thumb/57207457-4e1a8480-6fe7-11e9-896b-8f650a4c9521.png?1557889389'
  },
  {
    chainId: 1,
    address: '0x31cbf205e26ba63296fdbd254a6b1be3ed28ce47',
    name: '3X Short Tether Gol',
    symbol: 'XAUTBEAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11980/thumb/683JEXMN_400x400__1_.png?1596618975'
  },
  {
    chainId: 1,
    address: '0x8064d9ae6cdf087b1bcd5bdf3531bd5d8c537a68',
    name: 'BoringDAO BTC',
    symbol: 'OBTC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13525/thumb/gWzm2dr.png?1609390713'
  },
  {
    chainId: 1,
    address: '0x39e743fee400a5d9b36f1167b70c10e8f06440e5',
    name: 'TNC Coin',
    symbol: 'TNC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11107/thumb/new-logo.png?1588570614'
  },
  {
    chainId: 1,
    address: '0xc9287623832668432099cef2ffdef3ced14f4315',
    name: '3X Long Tether Gold',
    symbol: 'XAUTBULL',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11979/thumb/683JEXMN_400x400__1_.png?1596618665'
  },
  {
    chainId: 1,
    address: '0x940bdcb99a0ee5fb008a606778ae87ed9789f257',
    name: 'JFIN Coin',
    symbol: 'JFIN',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13370/thumb/JFin-Coin-Logo.png?1607984823'
  },
  {
    chainId: 1,
    address: '0x3d3dd61b0f9a558759a21da42166042b114e12d5',
    name: '3X Short EOS Token',
    symbol: 'EOSBEAR',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10186/thumb/683JEXMN_400x400_%281%29.png?1576508730'
  },
  {
    chainId: 1,
    address: '0x5274891bec421b39d23760c04a6755ecb444797c',
    name: 'IdleUSDC  Yield ',
    symbol: 'IDLEUSDCYIELD',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11932/thumb/idleusdcv3_32.png?1596263673'
  },
  {
    chainId: 1,
    address: '0xca2796f9f61dc7b238aab043971e49c6164df375',
    name: 'Yggdrash',
    symbol: 'YEED',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3251/thumb/ygg-logo-green.png?1547037776'
  },
  {
    chainId: 1,
    address: '0xe2a05a52bb1582a8c436e972531cda45b45d7ff4',
    name: 'NMS Token',
    symbol: 'NMST',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8704/thumb/read_%283%29.png?1560241338'
  },
  {
    chainId: 1,
    address: '0x578b49c45961f98d8df92854b53f1641af0a5036',
    name: 'LINKA',
    symbol: 'LINKA',
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/9487/thumb/C2RRuVH6eVbXIDbew-0VJoGpPb_bMPjRjM9qlYud7tqtGgb1GskioTkIntKuugvXnX-Y3O54L-4zWeWWm_Fffo1kJiqJG5vE4Iu1FSvZ-N750y_tlB7yAGeYztAD-MDrwEPaF3Y9j6E94mPVyKuidqbxf9vBeR5r4GAya3friWZjMzpnJ_q9v0jiIBxEC5YAy1C0Zx6yqVoHJjiTW.jpg?1568013160'
  },
  {
    chainId: 1,
    address: '0xc18e7a03f8986798323658dd8645f93aa79ac5c9',
    name: 'American Akita',
    symbol: 'USKITA',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15433/thumb/1620756969-img-20210511-075113-925.png?1620798546'
  },
  {
    chainId: 1,
    address: '0x51bc0deaf7bbe82bc9006b0c3531668a4206d27f',
    name: 'RAKUN',
    symbol: 'RAKU',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10768/thumb/Rakun_Icon_Red_200x200.png?1583310899'
  },
  {
    chainId: 1,
    address: '0x1456688345527be1f37e9e627da0837d6f08c925',
    name: 'USDP Stablecoin',
    symbol: 'USDP',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13234/thumb/USDP.png?1606579598'
  },
  {
    chainId: 1,
    address: '0x9ab165d795019b6d8b3e971dda91071421305e5a',
    name: 'Aurora',
    symbol: 'AOA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4755/thumb/aurora-logo.png?1547040076'
  },
  {
    chainId: 1,
    address: '0x442bc47357919446eabc18c7211e57a13d983469',
    name: 'BeeChat',
    symbol: 'CHAT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14386/thumb/ezgif-6-b78b8423d870.png?1615803294'
  },
  {
    chainId: 1,
    address: '0x4cd988afbad37289baaf53c13e98e2bd46aaea8c',
    name: 'Key',
    symbol: 'KEY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4182/thumb/bihu-key-token.png?1547039459'
  },
  {
    chainId: 1,
    address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    name: 'WETH',
    symbol: 'WETH',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1547036627'
  },
  {
    chainId: 1,
    address: '0xa8006c4ca56f24d6836727d106349320db7fef82',
    name: 'Internxt',
    symbol: 'INXT',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/986/thumb/round.png?1602484873'
  },
  {
    chainId: 1,
    address: '0xb13de094cc5cee6c4cc0a3737bf0290166d9ca5d',
    name: 'GoWithMi',
    symbol: 'GMAT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7162/thumb/qXNbxVny_400x400.png?1564433322'
  },
  {
    chainId: 1,
    address: '0x122f96d596384885b54bccdddf2125018c421d83',
    name: 'Cubiex',
    symbol: 'CBIX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6161/thumb/Cubiex-ico.jpg?1547042188'
  },
  {
    chainId: 1,
    address: '0xc3589f56b6869824804a5ea29f2c9886af1b0fce',
    name: 'Honey',
    symbol: 'HNY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12895/thumb/hnys.png?1614100588'
  },
  {
    chainId: 1,
    address: '0xd123575d94a7ad9bff3ad037ae9d4d52f41a7518',
    name: 'MyWish',
    symbol: 'WISH',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/1267/thumb/mywish.jpg?1547035291'
  },
  {
    chainId: 1,
    address: '0xf1738912ae7439475712520797583ac784ea9033',
    name: 'Xion Global Token',
    symbol: 'XGT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14039/thumb/xgt.png?1613965612'
  },
  {
    chainId: 1,
    address: '0x377d552914e7a104bc22b4f3b6268ddc69615be7',
    name: 'NEXT',
    symbol: 'NEXT',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7014/thumb/NEXT.exchange_Logo.png?1569212613'
  },
  {
    chainId: 1,
    address: '0xe2dc070524a6e305ddb64d8513dc444b6a1ec845',
    name: 'Nash',
    symbol: 'NEX',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/3246/thumb/Nash-token_icon.png?1550821163'
  },
  {
    chainId: 1,
    address: '0x7825e833d495f3d1c28872415a4aee339d26ac88',
    name: 'Telos',
    symbol: 'TLOS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7588/thumb/TLOS_logo.png?1614668174'
  },
  {
    chainId: 1,
    address: '0x0f3adc247e91c3c50bc08721355a41037e89bc20',
    name: 'Anchor Protocol',
    symbol: 'ANC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14420/thumb/anchor_protocol_logo.jpg?1615965420'
  },
  {
    chainId: 1,
    address: '0x36b679bd64ed73dbfd88909cdcb892cb66bd4cbb',
    name: 'xMARK',
    symbol: 'XMARK',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14188/thumb/omPQ9-nF_400x400.jpg?1614830988'
  },
  {
    chainId: 1,
    address: '0x1350fbe8ce27762ec19134bf8fc405a427fe9bf1',
    name: 'Trips Community',
    symbol: 'TRIPS',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14648/thumb/trips_32.png?1617491133'
  },
  {
    chainId: 1,
    address: '0xfeea0bdd3d07eb6fe305938878c0cadbfa169042',
    name: '8Pay',
    symbol: '8PAY',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14667/thumb/8pay.jpeg?1617639682'
  },
  {
    chainId: 1,
    address: '0x6dc02164d75651758ac74435806093e421b64605',
    name: 'XAYA',
    symbol: 'CHI',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/2091/thumb/xaya_logo-1.png?1547036406'
  },
  {
    chainId: 1,
    address: '0xed0d5747a9ab03a75fbfec3228cd55848245b75d',
    name: 'e Money',
    symbol: 'NGM',
    decimals: 6,
    logoURI:
      'https://assets.coingecko.com/coins/images/13722/thumb/nx5m_kZJt3oz9UM0XhE-IEeJ8TGjX1831iNfLVWeMvHuYoyDGwN0vh9Wj8u5hCxe1u5Kw56zT5kqX_ia5u1hsMElA-ioKCvK2jUK4EdJMDUx4J1GOnjqIu30iXHrLfmdzqO2SyucZZZnutFVLIAkxWMcTbIo1GDPZ3dZSj2onSeEOnIY4GJKR9B6ByR1XUZrJxtpY9QjwoWLfPC.jpg?1611182880'
  },
  {
    chainId: 1,
    address: '0x71ba91dc68c6a206db0a6a92b4b1de3f9271432d',
    name: 'MobieCoin',
    symbol: 'MBX',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12874/thumb/MBX_token.jpg?1603186697'
  },
  {
    chainId: 1,
    address: '0x5a4623f305a8d7904ed68638af3b4328678eddbf',
    name: 'dART Insurance',
    symbol: 'DART',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14834/thumb/dart.PNG?1618611161'
  },
  {
    chainId: 1,
    address: '0x232fb065d9d24c34708eedbf03724f2e95abe768',
    name: 'Sheesha Finance',
    symbol: 'SHEESHA',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15105/thumb/sheesha.PNG?1619732386'
  },
  {
    chainId: 1,
    address: '0xfb62ae373aca027177d1c18ee0862817f9080d08',
    name: 'My DeFi Pet',
    symbol: 'DPET',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15321/thumb/mydefi.PNG?1620512455'
  },
  {
    chainId: 1,
    address: '0x92a42db88ed0f02c71d439e55962ca7cab0168b5',
    name: 'Tardigrades Finance',
    symbol: 'TRDG',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14371/thumb/trdg.jpeg?1620208444'
  },
  {
    chainId: 1,
    address: '0xa1ed0364d53394209d61ae8bfdb8ff50484d8c91',
    name: 'TeraBlock',
    symbol: 'TBC',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15622/thumb/tbc.png?1621345335'
  }
]

const web3 = new Web3()
export const checksumedCoingeckoList = coinGeckoListOfTokens.map(item => {
  return { ...item, address: web3.utils.toChecksumAddress(item.address) }
})
